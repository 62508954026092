<div
  class="header-menu-wrapper header-menu-wrapper-left py-lg-2"
  id="kt_header_menu_wrapper"
  *ngIf="false"
>
  <!--begin::Menu-->
  <div
    id="kt_header_menu"
    class="header-menu header-menu-mobile header-menu-layout-default"
    *ngIf="taxcasesservice.statisticcountersMain"
  >
    <!--begin::Nav-->
    <ul class="menu-nav">
      <li
        class="menu-item"
        [routerLinkActive]="'menu-item-active'"
        [routerLinkActiveOptions]="{ exact: true }"
        aria-haspopup="true"
      >
        <a
          [routerLink]="['/taxcases/entry']"
          class="menu-link"
          (click)="updateTitle('Entry Fälle')"
        >
          <span class="menu-text">Neue</span>&nbsp;<span
            class="badge badge-light"
            >{{ taxcasesservice.statisticcountersMain.entry }}</span
          >
        </a>
      </li>
      <li
        class="menu-item"
        [routerLinkActive]="'menu-item-active'"
        [routerLinkActiveOptions]="{ exact: true }"
        aria-haspopup="true"
      >
        <a
          [routerLink]="['/taxcases/my']"
          class="menu-link"
          (click)="updateTitle('Meine Fälle')"
        >
          <span class="menu-text"
            >Meine &nbsp;<span
              class="badge badge-light"
              *ngIf="taxcasesservice.myCases | async; let myc"
              >{{ myc.length }}</span
            >
          </span>
        </a>
      </li>
      <li
        class="menu-item"
        [routerLinkActive]="'menu-item-active'"
        [routerLinkActiveOptions]="{ exact: true }"
        aria-haspopup="true"
      >
        <a
          [routerLink]="['/taxcases/progress']"
          class="menu-link"
          (click)="updateTitle('inProgress Fälle')"
        >
          <span class="menu-text">In Bearbeitung</span> &nbsp;<span
            class="badge badge-light"
            >{{ taxcasesservice.statisticcountersMain.inProgress }}</span
          >
        </a>
      </li>
      <li
        class="menu-item"
        [routerLinkActive]="'menu-item-active'"
        [routerLinkActiveOptions]="{ exact: true }"
        aria-haspopup="true"
      >
        <a
          [routerLink]="['/taxcases/rejected']"
          class="menu-link"
          (click)="updateTitle('Rejected Fälle')"
        >
          <span class="menu-text">Abgelehnt </span> &nbsp;<span
            class="badge badge-light"
            >{{ taxcasesservice.statisticcountersMain.reject }}</span
          >
        </a>
      </li>
      <li
        class="menu-item"
        [routerLinkActive]="'menu-item-active'"
        [routerLinkActiveOptions]="{ exact: true }"
        aria-haspopup="true"
      >
        <a
          [routerLink]="['/taxcases/done']"
          class="menu-link"
          (click)="updateTitle('Fertig')"
        >
          <span class="menu-text">Fertig </span> &nbsp;<span
            class="badge badge-light"
            >{{ taxcasesservice.statisticcountersMain.done }}</span
          >
        </a>
      </li>
      <li
        class="menu-item"
        [routerLinkActive]="'menu-item-active'"
        [routerLinkActiveOptions]="{ exact: true }"
        aria-haspopup="true"
      >
        <a
          [routerLink]="['/taxcases/deleted']"
          class="menu-link"
          (click)="updateTitle('Gelöschte Fälle')"
        >
          <span class="menu-text">Gelöscht</span> &nbsp;<span
            class="badge badge-light"
            >{{ taxcasesservice.statisticcountersMain.deleted }}</span
          >
        </a>
      </li>
    </ul>
    <!--end::Nav-->
  </div>
  <!--end::Menu-->
</div>
