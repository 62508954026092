<ng-container *ngIf="previewOnly === false">
  <ng-container
    *ngIf="
      aisDocumentsConfigService.configDocuments$ | async as configDocuments;
      else loading
    "
  >
    <ais-instantsearch [config]="configDocuments">
      <ais-configure [(searchParameters)]="searchParameters"></ais-configure>
      <div class="d-flex flex-row">
        <div
          *ngIf="showFacetFilters || (advanceViewOption$ | async)"
          class="fflex-column offcanvas-mobile col-sm-3 pr-0"
        >
          <div class="card gutter-b">
            <div class="card-body">
              <ng-container *ngIf="!clientId && !taxcaseId">
                <!--
                                Only give these options when no facet has been
                                set through the component's input as it would
                                currently messes with the facets set through the
                                widget.
                            -->
                <div class="form-group mb-11">
                  <label class="font-size-h3 font-weight-bolder text-dark mb-7"
                    >Art der Datei</label
                  >
                  <app-ais-documents-refinement-list
                    attribute="contentType"
                    [merge]="[
                      { matcher: '^image', label: 'Bilder' },
                      { matcher: '^video', label: 'Videos' },
                      { matcher: '^audio', label: 'Audios' },
                      { matcher: '^application/pdf', label: 'PDF' },
                      { matcher: '.*', label: 'Andere' }
                    ]"
                  >
                  </app-ais-documents-refinement-list>
                </div>

                <div class="form-group mb-11">
                  <label class="font-size-h3 font-weight-bolder text-dark mb-7"
                    >Art des Inhalts</label
                  >
                  <app-ais-documents-refinement-list
                    [transformItems]="
                      AisDocumentsHelperService.refineClusterListItem
                    "
                    attribute="type"
                  >
                  </app-ais-documents-refinement-list>
                </div>
              </ng-container>

              <div
                class="form-group mb-11"
                *ngIf="!clientId && advanceViewOption$ | async"
              >
                <label class="font-size-h3 font-weight-bolder text-dark mb-7"
                  >ClientId</label
                >
                <app-ais-documents-refinement-list
                  [searchable]="true"
                  searchPlaceholder="ClientId..."
                  attribute="metadata.clientId"
                >
                </app-ais-documents-refinement-list>
              </div>

              <div class="form-group mb-11" *ngIf="!taxcaseId">
                <label class="font-size-h3 font-weight-bolder text-dark mb-7"
                  >Taxcase</label
                >
                <app-ais-documents-refinement-list
                  [searchable]="true"
                  searchPlaceholder="TaxcaseId..."
                  attribute="metadata.taxcaseid"
                >
                </app-ais-documents-refinement-list>
              </div>

              <div class="mb-11">
                <ais-stats></ais-stats>
              </div>

              <div class="mb-11">
                <app-ais-documents-clear-refinements>
                </app-ais-documents-clear-refinements>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-row-fluid col">
          <div class="card card-stretch gutter-b">
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Dokumente</span
                >
                <!-- <span class="text-muted mt-3 font-weight-bold font-size-sm">Dokumente im Überblick</span> -->
              </h3>
            </div>

            <div
              class="card-body pt-0 pb-3"
              *ngIf="activeViewOption$ | async as viewOption"
            >
              <div
                class="row gy-2 gx-3 align-items-center justify-content-between"
              >
                <div class="col-2">
                  <app-ais-documents-search-box
                    placeholder="Search..."
                  ></app-ais-documents-search-box>
                </div>
                <!-- <ais-voice-search></ais-voice-search> -->
                <div class="col-2 d-none d-md-block" *ngIf="!selectMode">
                  <select
                    class="viewOptionPicker"
                    [ngModel]="viewOption"
                    (ngModelChange)="activeViewOption = $event"
                  >
                    <option
                      *ngFor="let x of viewOptions"
                      [value]="x"
                      [selected]="viewOption === x"
                    >
                      {{ x }}
                    </option>
                  </select>
                </div>
                <div class="col-2 d-none d-md-block">
                  <ais-hits-per-page
                    [items]="pageHitsPerPageOptions"
                  ></ais-hits-per-page>
                </div>
                <div class="col-auto d-none d-xxl-block">
                  <app-ais-documents-pagination></app-ais-documents-pagination>
                </div>
              </div>

              <ais-hits>
                <ng-template let-hits="hits">
                  <ng-container *ngIf="!selectMode">
                    <app-ais-documents-collection-list
                      *ngIf="viewOption === ViewOption.list"
                      [hits]="hits"
                    >
                    </app-ais-documents-collection-list>
                    <app-ais-documents-icon-list
                      *ngIf="viewOption === ViewOption.icons"
                      [hits]="hits"
                    >
                    </app-ais-documents-icon-list>
                  </ng-container>
                  <app-ais-documents-table
                    *ngIf="viewOption === ViewOption.table || selectMode"
                    [hits]="hits"
                    [selectMode]="selectMode"
                    [selections]="selections"
                    (selectionsChange)="selectionsChange.next($event)"
                  >
                  </app-ais-documents-table>
                </ng-template>
              </ais-hits>
            </div>

            <div class="card-footer d-flex justify-content-between">
              <ais-hits-per-page [items]="pageHitsPerPageOptions">
              </ais-hits-per-page>
              <app-ais-documents-pagination></app-ais-documents-pagination>
            </div>
          </div>

          <ng-container *ngIf="selectMode === true">
            <ng-container *ngTemplateOutlet="selectionPreview"></ng-container>
          </ng-container>
        </div>
      </div>
    </ais-instantsearch>
  </ng-container>
  <ng-template #loading>loading</ng-template>
</ng-container>

<ng-container *ngIf="previewOnly === true">
  <ng-container *ngTemplateOutlet="selectionPreview"></ng-container>
</ng-container>

<ng-template #selectionPreview>
  <div class="card card-stretch gutter-b">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"
          >Auswahl Vorschau</span
        >
      </h3>
    </div>
    <div class="card-body">
      <ul class="selection-previews">
        <li *ngFor="let selection of selections">
          <app-document-preview-presenter
            [document]="selection"
          ></app-document-preview-presenter>
          <p>{{ selection.name }}</p>
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<div class="d-flex flex-row" *ngIf="isEmulator">
  <div class="col-lg-12">
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Emulator Documents</h3>
        </div>
      </div>
      <div
        class="card-body"
        *ngIf="documentsService.documentsClient$ | async as documents"
      >
        <ul>
          <li *ngFor="let document of documents" [title]="document | json">
            {{ document.uploaded.toDate().getHours() }}:{{
              document.uploaded.toDate().getMinutes()
            }}

            <strong>({{ document.type }})</strong>

            <a [routerLink]="'/documents/documentcenter/' + document.id">{{
              document.name
            }}</a>

            processed: {{ document.processed }} verified:
            {{ document.verified }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
