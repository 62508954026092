import { Component, OnInit } from '@angular/core';
import {
  TAXCASESTATUS,
  TAXCASESTATUS_INPROGRESS,
  TAXCASESTATUS_REJECT,
} from '@expresssteuer/models';
import { TaxcasesService } from '../../../../../app/services/data/taxcases.service';

@Component({
  selector: 'app-taxcases-archived',
  templateUrl: './taxcases-archived.component.html',
  styleUrls: ['./taxcases-archived.component.scss'],
})
export class TaxcasesArchivedComponent implements OnInit {
  TAXCASESTATUS = TAXCASESTATUS;
  TAXCASESTATUS_INPROGRESS = TAXCASESTATUS_INPROGRESS;
  TAXCASESTATUS_REJECT = TAXCASESTATUS_REJECT;

  constructor(public taxcasesservice: TaxcasesService) {}

  ngOnInit(): void {
    this.taxcasesservice.pagination.firstPage();
  }
}
