<ng-container *ngIf="state" [ngSwitch]="state">
  <mat-icon [matTooltip]="state" *ngSwitchCase="MESSAGESTATUS.QUENUE"
    >pending</mat-icon
  >
  <mat-icon [matTooltip]="state" *ngSwitchCase="MESSAGESTATUS.SENT"
    >done</mat-icon
  >
  <mat-icon [matTooltip]="state" *ngSwitchCase="MESSAGESTATUS.DELIVERED"
    >done_all</mat-icon
  >
  <mat-icon [matTooltip]="state" *ngSwitchCase="MESSAGESTATUS.UNDELIVERED"
    >pending</mat-icon
  >
  <mat-icon [matTooltip]="state" *ngSwitchCase="MESSAGESTATUS.RECIEVED"
    >done_all</mat-icon
  >
  <mat-icon [matTooltip]="state" *ngSwitchCase="MESSAGESTATUS.DROPED"
    >error</mat-icon
  >
  <mat-icon [matTooltip]="state" *ngSwitchCase="MESSAGESTATUS.READ" class="read"
    >done_all</mat-icon
  >
  <span *ngSwitchDefault [matTooltip]="state">{{ state }}</span>
</ng-container>
