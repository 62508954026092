import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EsuiSectionColumnScaffoldModule } from '../esui-section-column-scaffold/esui-section-column-scaffold.module';
import { TimestampModule } from '../pipes/timestamp/timestamp.module';
import { EsuiPartnersListComponent } from './esui-partners-list.component';

@NgModule({
  declarations: [EsuiPartnersListComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatDividerModule,
    MatTooltipModule,
    FormsModule,
    MatIconModule,
    TimestampModule,
    EsuiSectionColumnScaffoldModule,
  ],
  exports: [EsuiPartnersListComponent],
})
export class EsuiPartnersListModule {}
