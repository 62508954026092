<esui-section-column-scaffold
  [header]="{
    title: 'client',
    icon: client?.inMaintenanceMode ? 'warning' : null,
    tooltip: client?.inMaintenanceMode
      ? 'this client is currently in maintenance mode'
      : null
  }"
>
  <ng-container headerMenu>
    <ng-container *ngIf="mode && mode !== Mode.readonly">
      <esui-store-state-indicator
        *ngIf="mode === Mode.edit"
        [isSaving]="isSaving ?? false"
        [hasErrors]="erroneous ?? false"
        [hasChanges]="hasUnsavedChanges ?? false"
      ></esui-store-state-indicator>
      <ng-container *ngIf="mode === Mode.read">
        <button mat-button (click)="editClick.next()">edit</button>
      </ng-container>
      <ng-container
        *ngIf="mode === Mode.edit && hasUnsavedChanges && !isSaving"
      >
        <button mat-button (click)="saveClick.next()">save</button>
      </ng-container>
      <ng-container *ngIf="mode === Mode.edit && !isSaving">
        <button mat-button (click)="discardClick.next()">discard</button>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container content *ngIf="client">
    <h3></h3>

    <mat-form-field>
      <mat-label>first name</mat-label>
      <input
        matInput
        [disabled]="mode !== Mode.edit"
        [ngModel]="client.firstname"
        (ngModelChange)="partialWritableClientData.next({ firstname: $event })"
      />
      <span class="icon" matPrefix> F </span>
    </mat-form-field>

    <mat-form-field>
      <mat-label>last name</mat-label>
      <input
        matInput
        [disabled]="mode !== Mode.edit"
        [ngModel]="client.lastname"
        (ngModelChange)="partialWritableClientData.next({ lastname: $event })"
      />
      <span class="icon" matPrefix> L </span>
    </mat-form-field>

    <mat-form-field>
      <mat-label>birthdate</mat-label>
      <esui-date-string-input
        [disabled]="mode !== Mode.edit"
        [ngModel]="client.birthdate"
        (ngModelChange)="partialWritableClientData.next({ birthdate: $event })"
      ></esui-date-string-input>
      <mat-icon class="icon" matPrefix>cake</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>tax ID</mat-label>
      <input
        matInput
        esuiValidTaxId
        [disabled]="mode !== Mode.edit"
        [ngModel]="client.taxId"
        (ngModelChange)="partialWritableClientData.next({ taxId: $event })"
      />
      <span class="icon" matPrefix>#X</span>
    </mat-form-field>
    <!-- 
    <mat-form-field>
      <mat-label>taxnumber</mat-label>
      <input
        matInput
        [disabled]="mode !== Mode.edit"
        [ngModel]="client?.taxnumber"
        (ngModelChange)="partialWritableClientData.next({ taxnumber: $event })"
      />
      !-- TODO --
      <span class="icon" matPrefix>#N</span>
    </mat-form-field>

    <mat-form-field>
      <mat-label>finance department ID</mat-label>
      <input
        matInput
        esuiValidFinanceDepartmentIdValidator
        [disabled]="mode !== Mode.edit"
        [ngModel]="client?.financeDepartmentId"
        (ngModelChange)="
          partialWritableClientData.next({ financeDepartmentId: $event })
        "
      />
      !-- TODO --
      <span class="icon" matPrefix>#F</span>
    </mat-form-field>
 -->
    <mat-form-field>
      <mat-label>address</mat-label>
      <esui-address-input
        [disabled]="mode !== Mode.edit"
        [ngModel]="client.address"
        (ngModelChange)="partialWritableClientData.next({ address: $event })"
      ></esui-address-input>
      <mat-icon class="icon" matPrefix>
        <a
          target="_blank"
          [href]="
            client.address?.formatedAddress
              ? 'https://www.google.com/maps/place/' +
                client.address?.formatedAddress
              : ''
          "
        >
          pin_drop
        </a>
      </mat-icon>
    </mat-form-field>

    <mat-form-field
      [matTooltip]="mode === Mode.edit ? 'not editable' : ''"
      matTooltipPosition="above"
    >
      <mat-label>email</mat-label>
      <input matInput disabled type="email" [ngModel]="client.email" />
      <mat-icon class="icon" matPrefix>
        <a target="_blank" [href]="'mailto:' + client.email">email</a>
      </mat-icon>
      <mat-icon
        matSuffix
        *ngIf="client.emailVerified"
        [matTooltip]="(client.emailVerificationTimestamp | asDate | date) ?? ''"
        >verified</mat-icon
      >
      <mat-icon
        matSuffix
        *ngIf="!client.emailVerified"
        matTooltip="pending verification"
        >pending</mat-icon
      >
    </mat-form-field>

    <mat-form-field
      [matTooltip]="mode === Mode.edit ? 'not editable' : ''"
      matTooltipPosition="above"
    >
      <mat-label>phone</mat-label>
      <input matInput disabled type="tel" [ngModel]="client.mobile" />
      <mat-icon class="icon" matPrefix>
        <a target="_blank" [href]="'tel:' + client.mobile">phone</a>
      </mat-icon>
    </mat-form-field>

    <mat-form-field
      [matTooltip]="mode === Mode.edit ? 'not editable' : ''"
      matTooltipPosition="above"
    >
      <mat-label>ID</mat-label>
      <input matInput disabled type="text" [ngModel]="client.id" />
      <mat-icon class="icon" matPrefix>tag</mat-icon>
    </mat-form-field>

    <mat-form-field
      [matTooltip]="mode === Mode.edit ? 'not editable' : ''"
      matTooltipPosition="above"
    >
      <mat-label>language</mat-label>
      <input matInput disabled type="text" [ngModel]="client.language" />
      <mat-icon class="icon" matPrefix>language</mat-icon>
    </mat-form-field>

    <mat-form-field
      [matTooltip]="mode === Mode.edit ? 'not editable' : ''"
      matTooltipPosition="above"
    >
      <mat-label>created</mat-label>
      <esui-date-input disabled [ngModel]="client.created"></esui-date-input>
      <mat-icon class="icon" matPrefix>start</mat-icon>
    </mat-form-field>

    <mat-form-field
      [matTooltip]="mode === Mode.edit ? 'not editable' : ''"
      matTooltipPosition="above"
    >
      <mat-label>status</mat-label>
      <input matInput disabled type="text" [ngModel]="client.status" />
      <mat-icon class="icon" matPrefix>cached</mat-icon>
    </mat-form-field>
  </ng-container>
</esui-section-column-scaffold>
