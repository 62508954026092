import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractTimestamp } from '@expresssteuer/firebase-helper';
import { CLIENTSTATUS, DateString, ExpsAddress } from '@expresssteuer/models';

export enum Mode {
  edit = 'EDIT',
  read = 'READ',
  readonly = 'READONLY',
}

export interface WritableClientData {
  firstname: string;
  lastname: string;
  address: ExpsAddress;
  birthdate: DateString;
  taxId: string;
}

interface ClientViewModel {
  firstname?: string | null;
  lastname?: string | null;
  address?: ExpsAddress | null;
  birthdate?: DateString | null;
  taxId?: string | number | null;
  email?: string | null;
  emailVerified?: boolean | null;
  emailVerificationTimestamp?: AbstractTimestamp | null;
  mobile?: string | null;
  id?: string | null;
  language?: string | null;
  created?: AbstractTimestamp | null;
  status?: CLIENTSTATUS | null;
  inMaintenanceMode?: boolean | null;
}

@Component({
  selector: 'esui-client-editor',
  templateUrl: './client-editor.component.html',
  styleUrls: ['./client-editor.component.scss'],
})
export class ClientEditorComponent {
  @Input() client?: ClientViewModel | null;
  @Output() partialWritableClientData = new EventEmitter<
    Partial<WritableClientData>
  >();

  Mode = Mode;
  @Input() mode?: Mode;

  @Input() hasUnsavedChanges?: boolean | null;
  @Input() isSaving?: boolean | null;
  @Input() erroneous?: boolean | null;

  @Output() editClick = new EventEmitter<void>();
  @Output() saveClick = new EventEmitter<void>();
  @Output() discardClick = new EventEmitter<void>();
}
