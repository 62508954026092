import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EsuiDateValidatorDirective } from './esui-date-validator.directive';

@NgModule({
  declarations: [EsuiDateValidatorDirective],
  imports: [CommonModule],
  exports: [EsuiDateValidatorDirective],
})
export class EsuiDateValidatorModule {}
