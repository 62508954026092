import { SchemaProperty } from './vast';

export const VastPers1Schema: SchemaProperty = {
  title: 'VaSt_Pers1-4.xsd',
  $schema: 'http://json-schema.org/schema#',
  type: 'object',
  properties: {
    VaSt_Pers1: {
      description: 'Stammdaten',
      properties: {
        Inhaber: {
          type: 'object',
          properties: {
            NatPers: {
              type: 'object',
              properties: {
                AdrKette: {
                  type: 'object',
                  properties: {
                    AuslAdr: {
                      type: 'object',
                      properties: {
                        AdressErg: {
                          type: 'string',
                        },
                        AuslPlz: {
                          type: 'string',
                        },
                        HausNr: {
                          type: 'string',
                        },
                        HausNrZu: {
                          type: 'string',
                        },
                        Ort: {
                          type: 'string',
                        },
                        Str: {
                          type: 'string',
                        },
                      },
                    },
                    PostfAdr: {
                      type: 'object',
                      properties: {
                        Ort: {
                          type: 'string',
                        },
                        Plz: {
                          type: 'string',
                        },
                        Postfach: {
                          type: 'string',
                        },
                      },
                    },
                    StrAdr: {
                      type: 'object',
                      properties: {
                        AdressErg: {
                          type: 'string',
                        },
                        HausNr: {
                          type: 'string',
                        },
                        HausNrZu: {
                          type: 'string',
                        },
                        Ort: {
                          type: 'string',
                        },
                        Plz: {
                          type: 'string',
                        },
                        Str: {
                          type: 'string',
                        },
                        AuslAdr: {
                          type: 'object',
                          properties: {
                            AdressErg: {
                              type: 'string',
                            },
                            AuslPlz: {
                              type: 'string',
                            },
                            HausNr: {
                              type: 'string',
                            },
                            HausNrZu: {
                              type: 'string',
                            },
                            Ort: {
                              type: 'string',
                            },
                            Str: {
                              type: 'string',
                            },
                          },
                        },
                        PostfAdr: {
                          type: 'object',
                          properties: {
                            Ort: {
                              type: 'string',
                            },
                            Plz: {
                              type: 'string',
                            },
                            Postfach: {
                              type: 'string',
                            },
                          },
                        },
                        StrAdr: {
                          type: 'object',
                          properties: {
                            AdressErg: {
                              type: 'string',
                            },
                            HausNr: {
                              type: 'string',
                            },
                            HausNrZu: {
                              type: 'string',
                            },
                            Ort: {
                              type: 'string',
                            },
                            Plz: {
                              type: 'string',
                            },
                            Str: {
                              type: 'string',
                            },
                          },
                        },
                      },
                    },
                  },
                },
                BankKonto: {
                  type: 'object',
                  properties: {
                    BIC: {
                      type: 'string',
                    },
                    Blz: {
                      type: 'string',
                    },
                    IBAN: {
                      type: 'string',
                    },
                    Kontonummer: {
                      type: 'string',
                    },
                    NameInstitut: {
                      type: 'string',
                    },
                    Ort: {
                      type: 'string',
                    },
                  },
                },
                GebDat: {
                  type: 'string',
                },
                Titel: {
                  type: 'string',
                },
                Vorname: {
                  type: 'string',
                },
                Name: {
                  type: 'string',
                },
                NamensVorsatz: {
                  type: 'string',
                },
                NamensZusatz: {
                  type: 'string',
                },
                QuellHinweis: {
                  type: 'string',
                },
                SteuerIDs: {
                  type: 'object',
                  properties: {
                    PersIdNr: {
                      type: 'string',
                    },
                  },
                },
                NatPers: {
                  type: 'object',
                  properties: {
                    AdrKette: {
                      type: 'object',
                      properties: {
                        AuslAdr: {
                          type: 'object',
                          properties: {
                            AdressErg: {
                              type: 'string',
                            },
                            AuslPlz: {
                              type: 'string',
                            },
                            HausNr: {
                              type: 'string',
                            },
                            HausNrZu: {
                              type: 'string',
                            },
                            Ort: {
                              type: 'string',
                            },
                            Str: {
                              type: 'string',
                            },
                          },
                        },
                        PostfAdr: {
                          type: 'object',
                          properties: {
                            Ort: {
                              type: 'string',
                            },
                            Plz: {
                              type: 'string',
                            },
                            Postfach: {
                              type: 'string',
                            },
                          },
                        },
                        StrAdr: {
                          type: 'object',
                          properties: {
                            AdressErg: {
                              type: 'string',
                            },
                            HausNr: {
                              type: 'string',
                            },
                            HausNrZu: {
                              type: 'string',
                            },
                            Ort: {
                              type: 'string',
                            },
                            Plz: {
                              type: 'string',
                            },
                            Str: {
                              type: 'string',
                            },
                            AuslAdr: {
                              type: 'object',
                              properties: {
                                AdressErg: {
                                  type: 'string',
                                },
                                AuslPlz: {
                                  type: 'string',
                                },
                                HausNr: {
                                  type: 'string',
                                },
                                HausNrZu: {
                                  type: 'string',
                                },
                                Ort: {
                                  type: 'string',
                                },
                                Str: {
                                  type: 'string',
                                },
                              },
                            },
                            PostfAdr: {
                              type: 'object',
                              properties: {
                                Ort: {
                                  type: 'string',
                                },
                                Plz: {
                                  type: 'string',
                                },
                                Postfach: {
                                  type: 'string',
                                },
                              },
                            },
                            StrAdr: {
                              type: 'object',
                              properties: {
                                AdressErg: {
                                  type: 'string',
                                },
                                HausNr: {
                                  type: 'string',
                                },
                                HausNrZu: {
                                  type: 'string',
                                },
                                Ort: {
                                  type: 'string',
                                },
                                Plz: {
                                  type: 'string',
                                },
                                Str: {
                                  type: 'string',
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    BankKonto: {
                      type: 'object',
                      properties: {
                        BIC: {
                          type: 'string',
                        },
                        Blz: {
                          type: 'string',
                        },
                        IBAN: {
                          type: 'string',
                        },
                        Kontonummer: {
                          type: 'string',
                        },
                        NameInstitut: {
                          type: 'string',
                        },
                        Ort: {
                          type: 'string',
                        },
                      },
                    },
                    GebDat: {
                      type: 'string',
                    },
                    Titel: {
                      type: 'string',
                    },
                    Vorname: {
                      type: 'string',
                    },
                    Name: {
                      type: 'string',
                    },
                    NamensVorsatz: {
                      type: 'string',
                    },
                    NamensZusatz: {
                      type: 'string',
                    },
                    QuellHinweis: {
                      type: 'string',
                    },
                    SteuerIDs: {
                      type: 'object',
                      properties: {
                        PersIdNr: {
                          type: 'string',
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        Vertreter: {
          type: 'object',
          properties: {
            NNatPers: {
              type: 'object',
              properties: {
                AdrKette: {
                  type: 'object',
                  properties: {
                    AuslAdr: {
                      type: 'object',
                      properties: {
                        AdressErg: {
                          type: 'string',
                        },
                        AuslPlz: {
                          type: 'string',
                        },
                        HausNr: {
                          type: 'string',
                        },
                        HausNrZu: {
                          type: 'string',
                        },
                        Ort: {
                          type: 'string',
                        },
                        Str: {
                          type: 'string',
                        },
                      },
                    },
                    GrossempfAdr: {
                      type: 'object',
                      properties: {
                        Ort: {
                          type: 'string',
                        },
                        Plz: {
                          type: 'string',
                        },
                      },
                    },
                    PostfAdr: {
                      type: 'object',
                      properties: {
                        Ort: {
                          type: 'string',
                        },
                        Plz: {
                          type: 'string',
                        },
                        Postfach: {
                          type: 'string',
                        },
                      },
                    },
                    StrAdr: {
                      type: 'object',
                      properties: {
                        AdressErg: {
                          type: 'string',
                        },
                        HausNr: {
                          type: 'string',
                        },
                        HausNrZu: {
                          type: 'string',
                        },
                        Ort: {
                          type: 'string',
                        },
                        Plz: {
                          type: 'string',
                        },
                        Str: {
                          type: 'string',
                        },
                        AuslAdr: {
                          type: 'object',
                          properties: {
                            AdressErg: {
                              type: 'string',
                            },
                            AuslPlz: {
                              type: 'string',
                            },
                            HausNr: {
                              type: 'string',
                            },
                            HausNrZu: {
                              type: 'string',
                            },
                            Ort: {
                              type: 'string',
                            },
                            Str: {
                              type: 'string',
                            },
                          },
                        },
                        GrossempfAdr: {
                          type: 'object',
                          properties: {
                            Ort: {
                              type: 'string',
                            },
                            Plz: {
                              type: 'string',
                            },
                          },
                        },
                        PostfAdr: {
                          type: 'object',
                          properties: {
                            Ort: {
                              type: 'string',
                            },
                            Plz: {
                              type: 'string',
                            },
                            Postfach: {
                              type: 'string',
                            },
                          },
                        },
                        StrAdr: {
                          type: 'object',
                          properties: {
                            AdressErg: {
                              type: 'string',
                            },
                            HausNr: {
                              type: 'string',
                            },
                            HausNrZu: {
                              type: 'string',
                            },
                            Ort: {
                              type: 'string',
                            },
                            Plz: {
                              type: 'string',
                            },
                            Str: {
                              type: 'string',
                            },
                          },
                        },
                      },
                    },
                  },
                },
                Firmenname: {
                  type: 'string',
                },
              },
            },
            NatPers: {
              type: 'object',
              properties: {
                AdrKette: {
                  type: 'object',
                  properties: {
                    AuslAdr: {
                      type: 'object',
                      properties: {
                        AdressErg: {
                          type: 'string',
                        },
                        AuslPlz: {
                          type: 'string',
                        },
                        HausNr: {
                          type: 'string',
                        },
                        HausNrZu: {
                          type: 'string',
                        },
                        Ort: {
                          type: 'string',
                        },
                        Str: {
                          type: 'string',
                        },
                      },
                    },
                    PostfAdr: {
                      type: 'object',
                      properties: {
                        Ort: {
                          type: 'string',
                        },
                        Plz: {
                          type: 'string',
                        },
                        Postfach: {
                          type: 'string',
                        },
                      },
                    },
                    StrAdr: {
                      type: 'object',
                      properties: {
                        AdressErg: {
                          type: 'string',
                        },
                        HausNr: {
                          type: 'string',
                        },
                        HausNrZu: {
                          type: 'string',
                        },
                        Ort: {
                          type: 'string',
                        },
                        Plz: {
                          type: 'string',
                        },
                        Str: {
                          type: 'string',
                        },
                        AuslAdr: {
                          type: 'object',
                          properties: {
                            AdressErg: {
                              type: 'string',
                            },
                            AuslPlz: {
                              type: 'string',
                            },
                            HausNr: {
                              type: 'string',
                            },
                            HausNrZu: {
                              type: 'string',
                            },
                            Ort: {
                              type: 'string',
                            },
                            Str: {
                              type: 'string',
                            },
                          },
                        },
                        PostfAdr: {
                          type: 'object',
                          properties: {
                            Ort: {
                              type: 'string',
                            },
                            Plz: {
                              type: 'string',
                            },
                            Postfach: {
                              type: 'string',
                            },
                          },
                        },
                        StrAdr: {
                          type: 'object',
                          properties: {
                            AdressErg: {
                              type: 'string',
                            },
                            HausNr: {
                              type: 'string',
                            },
                            HausNrZu: {
                              type: 'string',
                            },
                            Ort: {
                              type: 'string',
                            },
                            Plz: {
                              type: 'string',
                            },
                            Str: {
                              type: 'string',
                            },
                          },
                        },
                      },
                    },
                  },
                },
                Titel: {
                  type: 'string',
                },
                Vorname: {
                  type: 'string',
                },
                Name: {
                  type: 'string',
                },
                NamensVorsatz: {
                  type: 'string',
                },
                NamensZusatz: {
                  type: 'string',
                },
                NNatPers: {
                  type: 'object',
                  properties: {
                    AdrKette: {
                      type: 'object',
                      properties: {
                        AuslAdr: {
                          type: 'object',
                          properties: {
                            AdressErg: {
                              type: 'string',
                            },
                            AuslPlz: {
                              type: 'string',
                            },
                            HausNr: {
                              type: 'string',
                            },
                            HausNrZu: {
                              type: 'string',
                            },
                            Ort: {
                              type: 'string',
                            },
                            Str: {
                              type: 'string',
                            },
                          },
                        },
                        GrossempfAdr: {
                          type: 'object',
                          properties: {
                            Ort: {
                              type: 'string',
                            },
                            Plz: {
                              type: 'string',
                            },
                          },
                        },
                        PostfAdr: {
                          type: 'object',
                          properties: {
                            Ort: {
                              type: 'string',
                            },
                            Plz: {
                              type: 'string',
                            },
                            Postfach: {
                              type: 'string',
                            },
                          },
                        },
                        StrAdr: {
                          type: 'object',
                          properties: {
                            AdressErg: {
                              type: 'string',
                            },
                            HausNr: {
                              type: 'string',
                            },
                            HausNrZu: {
                              type: 'string',
                            },
                            Ort: {
                              type: 'string',
                            },
                            Plz: {
                              type: 'string',
                            },
                            Str: {
                              type: 'string',
                            },
                            AuslAdr: {
                              type: 'object',
                              properties: {
                                AdressErg: {
                                  type: 'string',
                                },
                                AuslPlz: {
                                  type: 'string',
                                },
                                HausNr: {
                                  type: 'string',
                                },
                                HausNrZu: {
                                  type: 'string',
                                },
                                Ort: {
                                  type: 'string',
                                },
                                Str: {
                                  type: 'string',
                                },
                              },
                            },
                            GrossempfAdr: {
                              type: 'object',
                              properties: {
                                Ort: {
                                  type: 'string',
                                },
                                Plz: {
                                  type: 'string',
                                },
                              },
                            },
                            PostfAdr: {
                              type: 'object',
                              properties: {
                                Ort: {
                                  type: 'string',
                                },
                                Plz: {
                                  type: 'string',
                                },
                                Postfach: {
                                  type: 'string',
                                },
                              },
                            },
                            StrAdr: {
                              type: 'object',
                              properties: {
                                AdressErg: {
                                  type: 'string',
                                },
                                HausNr: {
                                  type: 'string',
                                },
                                HausNrZu: {
                                  type: 'string',
                                },
                                Ort: {
                                  type: 'string',
                                },
                                Plz: {
                                  type: 'string',
                                },
                                Str: {
                                  type: 'string',
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    Firmenname: {
                      type: 'string',
                    },
                  },
                },
                NatPers: {
                  type: 'object',
                  properties: {
                    AdrKette: {
                      type: 'object',
                      properties: {
                        AuslAdr: {
                          type: 'object',
                          properties: {
                            AdressErg: {
                              type: 'string',
                            },
                            AuslPlz: {
                              type: 'string',
                            },
                            HausNr: {
                              type: 'string',
                            },
                            HausNrZu: {
                              type: 'string',
                            },
                            Ort: {
                              type: 'string',
                            },
                            Str: {
                              type: 'string',
                            },
                          },
                        },
                        PostfAdr: {
                          type: 'object',
                          properties: {
                            Ort: {
                              type: 'string',
                            },
                            Plz: {
                              type: 'string',
                            },
                            Postfach: {
                              type: 'string',
                            },
                          },
                        },
                        StrAdr: {
                          type: 'object',
                          properties: {
                            AdressErg: {
                              type: 'string',
                            },
                            HausNr: {
                              type: 'string',
                            },
                            HausNrZu: {
                              type: 'string',
                            },
                            Ort: {
                              type: 'string',
                            },
                            Plz: {
                              type: 'string',
                            },
                            Str: {
                              type: 'string',
                            },
                            AuslAdr: {
                              type: 'object',
                              properties: {
                                AdressErg: {
                                  type: 'string',
                                },
                                AuslPlz: {
                                  type: 'string',
                                },
                                HausNr: {
                                  type: 'string',
                                },
                                HausNrZu: {
                                  type: 'string',
                                },
                                Ort: {
                                  type: 'string',
                                },
                                Str: {
                                  type: 'string',
                                },
                              },
                            },
                            PostfAdr: {
                              type: 'object',
                              properties: {
                                Ort: {
                                  type: 'string',
                                },
                                Plz: {
                                  type: 'string',
                                },
                                Postfach: {
                                  type: 'string',
                                },
                              },
                            },
                            StrAdr: {
                              type: 'object',
                              properties: {
                                AdressErg: {
                                  type: 'string',
                                },
                                HausNr: {
                                  type: 'string',
                                },
                                HausNrZu: {
                                  type: 'string',
                                },
                                Ort: {
                                  type: 'string',
                                },
                                Plz: {
                                  type: 'string',
                                },
                                Str: {
                                  type: 'string',
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    Titel: {
                      type: 'string',
                    },
                    Vorname: {
                      type: 'string',
                    },
                    Name: {
                      type: 'string',
                    },
                    NamensVorsatz: {
                      type: 'string',
                    },
                    NamensZusatz: {
                      type: 'string',
                    },
                  },
                },
              },
            },
          },
        },
        version: {
          type: 'string',
          enum: ['4'],
        },
      },
      required: ['version'],

      type: 'object',
    },
  },
  required: ['VaSt_Pers1'],
};
