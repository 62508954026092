<!-- TODO refactor style and layout -->
<div id="kt_header" class="header header-fixed">
  <div
    class="header-wrapper rounded-top-xl d-flex flex-grow-1 align-items-center"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-end justify-content-lg-between flex-wrap"
    >
      <!-- <button (click)="navigateBack()" class="btn">back</button> -->
    </div>
  </div>
</div>

<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <ng-container *ngIf="initialLoadState === State.success">
    <div
      class="subheader py-2 py-lg-4 subheader-transparent"
      id="kt_subheader"
      *ngIf="docHelper"
    >
      <div
        class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      >
        <div class="d-flex align-items-center flex-wrap mr-2">
          <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
            Dokument bearbeiten
          </h5>
          <div
            class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"
          ></div>
        </div>
        <app-status-indicator [status]="this.syncState"></app-status-indicator>
      </div>
    </div>

    <div class="d-flex flex-column-fluid">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-8">
            <div
              class="card card-custom card-stretch"
              id="kt_page_stretched_card"
            >
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">Dokument</h3>
                </div>
              </div>
              <div class="card-body">
                <div class="card-scroll" *ngIf="document">
                  <esui-document-viewer
                    class="documentViewer"
                    [downloadUrl]="document.file.filepath | getDownloadURL"
                    [contentType]="document.contentType"
                    [showNavigation]="true"
                    [showDownloadButton]="true"
                    [source]="document.source"
                  ></esui-document-viewer>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card card-custom">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">Eigenschaften <small></small></h3>
                </div>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label>Name:</label>
                  <input
                    type="text"
                    name="docName"
                    class="form-control"
                    [ngModel]="docHelper.proxy.name?.value$ | async"
                    (blur)="docHelper.proxy.name?.update($event.target.value)"
                  />
                </div>
                <div>
                  <label>Beschreibung:</label>
                  <p>{{ docHelper.proxy.description?.value$ | async }}</p>
                </div>
                <div class="form-group row">
                  <label class="col-lg-6 col-form-label text-left"
                    >Trigger AI</label
                  >
                  <div class="col-lg-3">
                    <span class="switch switch-sm switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          name="needsProcessing"
                          [ngModel]="
                            docHelper.proxy.needProcessing?.value$ | async
                          "
                          (ngModelChange)="
                            docHelper.proxy.needProcessing?.update($event)
                          "
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>

                  <div class="col-lg-3" title="Processed">
                    <app-control-document-safesearch
                      [safeSearch]="
                        (docHelper.proxy.processed?.value$ | async)
                          ? 'UNLIKELY'
                          : ''
                      "
                    >
                    </app-control-document-safesearch>
                  </div>
                </div>
              </div>
            </div>

            <!-- #region Metadata -->
            <div class="card card-custom mt-2" *ngIf="document">
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">Info</h3>
                </div>
              </div>
              <div class="card-body">
                <b>Metadata</b>
                <div class="d-flex">
                  <div class="col-4">
                    <span class="text-muted">ID:</span>
                  </div>
                  <div class="col-8">
                    <span>{{ document.id }}</span>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="col-4">
                    <span class="text-muted">Erstellt:</span>
                  </div>
                  <div class="col-8">
                    <span>{{
                      document.uploaded.toDate() | date : 'dd.MM.yyyy HH:mm'
                    }}</span>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="col-4">
                    <span class="text-muted">Geändert:</span>
                  </div>
                  <div class="col-8">
                    <span>{{
                      document.modified.toDate() | date : 'dd.MM.yyyy HH:mm'
                    }}</span>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="col-4">
                    <span class="text-muted">Größe:</span>
                  </div>
                  <div class="col-8">
                    <span>{{ document.size | number }}</span>
                  </div>
                </div>
                <a
                  class="d-flex"
                  [routerLink]="['/client', document.metadata.clientId]"
                >
                  <div class="col-4">
                    <span class="text-muted">Kunde:</span>
                  </div>
                  <div class="col-8">
                    <span
                      >{{ document.metadata.client?.firstname }}
                      {{ document.metadata.client?.lastname }}</span
                    >
                  </div>
                </a>
                <a
                  class="d-flex"
                  [routerLink]="[
                    '/client',
                    document.metadata.clientId,
                    '/taxcase',
                    document.metadata.taxcaseid
                  ]"
                >
                  <div class="col-4">
                    <span class="text-muted">Steuerfall:</span>
                  </div>
                  <div class="col-8">
                    <span>{{ document.metadata.taxcaseid }}</span>
                  </div>
                </a>
                <div class="d-flex">
                  <div class="col-4">
                    <span class="text-muted">Dokumenttyp:</span>
                  </div>
                  <div class="col-8">
                    <span>{{ document.type }}</span>
                  </div>
                </div>

                <div *ngIf="document.parsedFields && document.type">
                  <b>ParsedFields für {{ document.type }}</b>
                  <div
                    class="d-flex"
                    *ngFor="let parsedField of document.parsedFields | keyvalue"
                  >
                    <div class="col-4">
                      <span class="text-muted">{{ parsedField.key }}</span>
                    </div>
                    <div
                      class="col-8"
                      *ngIf="
                        this.isArray(document.parsedFields[parsedField.key])
                      "
                    >
                      <div
                        *ngFor="
                          let arrayValue of document.parsedFields[
                            parsedField.key
                          ]
                        "
                      >
                        <p>
                          <span *ngFor="let property of arrayValue | keyvalue">
                            {{ property.key }} : {{ property.value }}<br />
                          </span>
                        </p>
                      </div>
                    </div>

                    <div
                      class="col-8"
                      *ngIf="
                        !this.isArray(document.parsedFields[parsedField.key])
                      "
                    >
                      <span>{{
                        document.parsedFields[parsedField.key]?.toString() || ''
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-4 col-form-label text-left">
                    <strong> Verifiziert </strong>
                  </label>
                  <div class="col-8">
                    <span class="switch switch-sm switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          name="verified"
                          [ngModel]="docHelper.proxy.verified?.value$ | async"
                          (ngModelChange)="
                            docHelper.proxy.verified?.update($event)
                          "
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- #endregion Metadata -->

            <div
              class="card card-custom mt-2"
              *ngIf="document.metadata?.mldata as mlData"
            >
              <div class="card-header">
                <div class="card-title">
                  <h3 class="card-label">AI Data <small></small></h3>
                </div>
              </div>
              <div class="card-body">
                <div *ngIf="mlData.predictions as predictions">
                  <b>Predictions</b>
                  <div class="d-flex">
                    <div class="col-4">
                      <span class="text-muted">Type:</span>
                    </div>
                    <div class="col-8">
                      <div *ngFor="let typeSuggestion of predictions.type">
                        <span> {{ typeSuggestion.suggestion }}</span>
                        <span class="float-right">
                          {{ typeSuggestion.score | percent : '1.0-2' }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <b>Field Predictions for {{ document.type }}</b>
                  <div
                    class="d-flex"
                    *ngFor="
                      let field of predictions.fields
                        | scorePredictions : document.type
                        | keyvalue
                    "
                  >
                    <div class="col-4">
                      <span class="text-muted">{{ field.key }}:</span>
                    </div>
                    <div class="col-8">
                      <div *ngFor="let fieldSuggestion of field.value">
                        <span>
                          {{ fieldSuggestion.suggestion | suggestionString }}
                        </span>

                        <span class="float-right">
                          {{ fieldSuggestion.score | percent }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="mlData.labelAnnotations">
                  <b>Labels</b>
                  <div class="d-flex" *ngFor="let l of mlData.labelAnnotations">
                    <div class="col-4">
                      <span class="text-muted">{{ l.description }}</span>
                    </div>
                    <div class="col-8">
                      <span>{{ l.score | percent }}</span>
                    </div>
                  </div>
                </div>

                <div *ngIf="mlData.safeSearchAnnotation">
                  <div class="d-flex">
                    <div class="col-4">
                      <span class="text-muted">Erwachseneninhalt?:</span>
                    </div>
                    <div class="col-8">
                      <span>
                        <app-control-document-safesearch
                          [safeSearch]="mlData.safeSearchAnnotation.adult"
                        >
                        </app-control-document-safesearch>
                      </span>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="col-4">
                      <span class="text-muted">Medizinisch?:</span>
                    </div>
                    <div class="col-8">
                      <span>
                        <app-control-document-safesearch
                          [safeSearch]="mlData.safeSearchAnnotation.medical"
                        >
                        </app-control-document-safesearch>
                      </span>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="col-4">
                      <span class="text-muted">Nacktheit?:</span>
                    </div>
                    <div class="col-8">
                      <span>{{
                        mlData.safeSearchAnnotation.nsfwConfidence | percent
                      }}</span>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="col-4">
                      <span class="text-muted">Rassistisch?:</span>
                    </div>
                    <div class="col-8">
                      <span>
                        <app-control-document-safesearch
                          [safeSearch]="mlData.safeSearchAnnotation.racy"
                        >
                        </app-control-document-safesearch>
                      </span>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="col-4">
                      <span class="text-muted">Fälschung?:</span>
                    </div>
                    <div class="col-8">
                      <span>
                        <app-control-document-safesearch
                          [safeSearch]="mlData.safeSearchAnnotation.spoof"
                        >
                        </app-control-document-safesearch>
                      </span>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="col-4">
                      <span class="text-muted">Gewalt?:</span>
                    </div>
                    <div class="col-8">
                      <span>
                        <app-control-document-safesearch
                          [safeSearch]="mlData.safeSearchAnnotation.violence"
                        >
                        </app-control-document-safesearch>
                      </span>
                    </div>
                  </div>
                </div>

                <div *ngIf="mlData.ocrText as ocrText">
                  <p>
                    <b>OCR</b>
                  </p>
                  <pre>{{ ocrText }}</pre>
                </div>
                <div *ngIf="mlData.pdfText as pdfText">
                  <p>
                    <b>PDF Parse</b>
                  </p>
                  <pre>{{ pdfText }}</pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="initialLoadState === State.danger">
    <h4>Document not found</h4>
    <app-status-indicator
      [status]="this.initialLoadState"
    ></app-status-indicator>
  </ng-container>

  <ng-container *ngIf="initialLoadState === State.pending">
    <h4>Loading document</h4>
    <app-status-indicator
      [status]="this.initialLoadState"
    ></app-status-indicator>
  </ng-container>
</div>
