import { Component, Input } from '@angular/core';
import { DIRECTION, Message } from '@expresssteuer/models';

@Component({
  selector: 'esui-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent {
  DIRECTION = DIRECTION;
  @Input() messages?: Message[] | null = [];

  @Input() lastUnreadMessageId?: string;
}
