import { Component, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  BinaryDocument,
  DOCUMENTTYPE,
  Documenttype,
} from '@expresssteuer/models';

@Component({
  selector: 'app-document-inline-preview',
  templateUrl: './inline-document-preview.component.html',
  styleUrls: ['./inline-document-preview.component.scss'],
})
export class DocumentInlinePreviewComponent {
  public document: BinaryDocument = new BinaryDocument();
  private _id: string;
  private types = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];

  constructor(private db: AngularFirestore) {}

  getType(): string {
    return Documenttype.getLabel(this.document.type as DOCUMENTTYPE);
  }

  getVerified(): boolean {
    return this.document.verified;
  }
  isImage(): boolean {
    return this.document.contentType !== 'application/pdf';
  }

  getDownloadPath(): string {
    return this.document.file?.filepath;
  }

  @Input()
  set documentId(val: string) {
    console.log('set id', val);

    if (val && this._id !== val) {
      console.log('loading document with id', val);

      this._id = val;
      this.db
        .collection('documentcenter')
        .doc(this._id)
        .get()
        .toPromise()
        .then((ref) => {
          this.document = ref.data() as BinaryDocument;
          console.log('document loaded', this.document.name);
        })
        .catch((err) => {
          console.error('document preview failed');
          console.error(err);
        });
    }
  }
}
