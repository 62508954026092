import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { FloatingTabBarTabComponent } from './components/floating-tab-bar-tab/floating-tab-bar-tab.component';
import { FloatingTabBarComponent } from './components/floating-tab-bar/floating-tab-bar.component';

@NgModule({
  declarations: [FloatingTabBarComponent, FloatingTabBarTabComponent],
  imports: [CommonModule, MatButtonModule, MatCardModule],
  exports: [FloatingTabBarComponent, FloatingTabBarTabComponent],
})
export class EsFloatingTabBarModule {}
