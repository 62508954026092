import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  IbanUpdateInput,
  IbanUpdateOutput,
} from '@expresssteuer/client-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class IbanUpdate {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: IbanUpdateInput) {
    console.log('Calling client-ibanUpdate');
    return this.afFunctions.httpsCallable<IbanUpdateInput, IbanUpdateOutput>(
      'client-ibanUpdate'
    )(input);
  }
}
