<div class="expenses">
  <div class="expenses__header">
    <div class="client">{{ name }}</div>
    <div>
      <div class="cost" matTooltip="Pendlerpauschale">
        <div>{{ commutingCost }}</div>
        <div class="currency">EUR</div>
        <mat-icon aria-hidden="false" aria-label="Is Optimization"
          >commute</mat-icon
        >
      </div>
      <div class="cost" matTooltip="Homeoffice-Pauschale">
        <div>{{ homeofficeCost }}</div>
        <div class="currency">EUR</div>
        <mat-icon aria-hidden="false" aria-label="Is Optimization"
          >home</mat-icon
        >
      </div>
    </div>
  </div>
  <div class="expenses-title">Ausgaben</div>
  <div
    class="expenses__item"
    *ngFor="let expense of additionalExpenses; first as isFirst"
    [ngClass]="{ 'expenses__item--first': isFirst }"
  >
    <div class="expenses__info">
      <div class="expenses__description">{{ expense.description }}</div>
      <div class="expenses__category">
        {{ expense.category }} | {{ expense.subcategory }}
      </div>
    </div>
    <div class="expenses__optimize">
      <mat-icon
        class="expenses__optimize-icon"
        *ngIf="expense.type === OPTIMIZATION_TYPE"
        aria-hidden="false"
        aria-label="Is Optimization"
        matTooltip="Optimierung"
        >star</mat-icon
      >
    </div>
    <div class="expenses__value">
      <span class="expenses__value-amount">{{ expense.value }}</span>
      <span class="currency">EUR</span>
    </div>
    <div class="expenses__actions">EDIT | REMOVE</div>
  </div>
  <div *ngIf="!additionalExpenses?.length">keine Ausgaben eingetragen</div>
</div>
