<mat-card>
  <h1 esuiCardKey>Taxcase optimieren</h1>
  <mat-card-header>
    <mat-card-title>
      <!-- <esui-store-state-indicator
        [hasOnlineChangedAfterLocalChanged]="
          !!taxcaseHasOnlineChangedAfterLocalChanged
        "
        [isSaving]="!!taxcaseIsSaving"
        [isLoading]="!!taxcaseIsLoading"
        [hasChanges]="!!taxcaseHasChanges"
        [hasErrors]="!!taxcaseHasErrors"
      ></esui-store-state-indicator> -->
    </mat-card-title>
  </mat-card-header>
  <mat-card-content> </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="submitTaskClick.next()">
      Optimieren beenden
    </button>
  </mat-card-actions>
</mat-card>
