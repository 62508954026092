import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import { BehaviorSubject, isObservable, Observable, Subscription } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

export type ValidationErrors$<TValidationErrors extends ValidationErrors> =
  | TValidationErrors
  | null
  | Promise<TValidationErrors | null>
  | Observable<TValidationErrors | null>;

/**
 * Directive to use a specific `ValidationErrors$` value as a validation error.
 */
@Directive({
  selector: '[esuiValidateWith] , [esuiError]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: EsuiValidateWithValueValidatorDirective,
      multi: true,
    },
  ],
})
export class EsuiValidateWithValueValidatorDirective<
  TValidationErrors extends ValidationErrors
> implements AsyncValidator, OnInit, OnDestroy
{
  private control?: AbstractControl;
  private subscription?: Subscription;

  private validatorValuesReceived$ = new BehaviorSubject<
    | Promise<TValidationErrors | null>
    | Observable<TValidationErrors | null>
    | undefined
  >(undefined);

  private validateWith$ = this.validatorValuesReceived$.pipe(
    filter(
      (
        e
      ): e is
        | Promise<TValidationErrors | null>
        | Observable<TValidationErrors | null> => e !== undefined
    ),
    switchMap((e) => e)
  );

  @Input() set esuiValidateWith(val: ValidationErrors$<TValidationErrors>) {
    if (isObservable(val)) {
      this.validatorValuesReceived$.next(val);
      return;
    }
    this.validatorValuesReceived$.next(Promise.resolve(val) as any);
  }
  /**
   * alias for `esuiValidateWith`
   */
  @Input() set esuiError(val: ValidationErrors$<TValidationErrors>) {
    this.esuiValidateWith = val;
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    this.control = control; // keep track of the control to revalidate it once `validateWith$` changes
    return this.validateWith$.pipe(take(1));
  }

  ngOnInit() {
    this.subscription = this.validateWith$.subscribe(() => {
      // retrigger validation when validateWith$ changes
      this.control?.updateValueAndValidity();
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
  }
}
