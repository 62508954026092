import { Component, EventEmitter, Input, Output } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  TaxAddition,
  TaxDeduction,
  TaxDeductionType,
} from '@expresssteuer/models';

@Component({
  selector: 'esui-deduction-table',
  templateUrl: './esui-deduction-table.component.html',
  styleUrls: ['./esui-deduction-table.component.scss'],
})
export class EsuiDeductionTableComponent {
  TaxDeductionTypeValues = Object.keys(TaxDeductionType);

  TaxDeductionType = TaxDeductionType;

  displayColumns = ['value', 'type', 'delete'];

  @Input() availableTypes?: Record<string, string>;
  @Input() deductions?: TaxDeduction[] | TaxAddition[] | null;
  @Input()
  disabled = false;
  @Output() deductionsChange = new EventEmitter<TaxDeduction[]>();
  change(deductions: TaxDeduction[]) {
    this.deductionsChange.next(deductions);
  }
  addDeduction() {
    console.log('addDeduction');
    const newDeductions = [
      ...(this.deductions ?? []),
      TaxDeduction.getTemplate(),
    ];
    this.change(newDeductions);
  }

  removeDeduction(index: number) {
    const newDeductions = (this.deductions ?? []).filter(
      (_v, i) => i !== index
    );
    this.change(newDeductions);
  }

  partialDeductionChange(index: number, change: Partial<TaxDeduction>) {
    const newDeductions = (this.deductions ?? []).map((v, i) =>
      i !== index ? v : { ...v, ...change }
    );
    this.change(newDeductions);
  }

  /**
   * Track deductions by its index and total deductiona length
   */
  trackDeductions(index: number, _deduction: TaxDeduction) {
    const iterableDiffer = this as any; // as DefaultIterableDiffer
    return `${index}_${iterableDiffer.length}`;
  }
}
