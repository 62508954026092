import { Component, Input } from '@angular/core';
import { Client } from '@expresssteuer/models';

@Component({
  selector: 'esui-client-inline',
  templateUrl: './client-inline.component.html',
  styleUrls: ['./client-inline.component.scss'],
})
export class ClientInlineComponent {
  @Input() client?: Client;
}
