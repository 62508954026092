import { DOCUMENTTYPE, SpecificDocument } from '@expresssteuer/models';
import maxBy from 'lodash/maxBy';
import { compareTwoStrings } from 'string-similarity';
import { transliterate } from 'transliteration';
import { getPermutations } from './get-permutations';

export interface IdentifyingProofValidation {
  isTypeCorrect: boolean;
  isNameCorrect: boolean;
  isValidExpirationDate: boolean;
}

export const identifyingDocumentTypes = [
  DOCUMENTTYPE.identitycard,
  DOCUMENTTYPE.driver_licence,
  DOCUMENTTYPE.passport,
  DOCUMENTTYPE.residentPermit,
] as const;

export type IdentifyingDocumentType = (typeof identifyingDocumentTypes)[number];

export type IdentifyingDocument = SpecificDocument<IdentifyingDocumentType>;

export const validateIdentifyingProof = (
  actual: {
    type?: DOCUMENTTYPE | null;
    firstname?: string | null;
    lastname?: string | null;
    expirationDate?: string | null;
  },
  expected: { firstname?: string | null; lastname?: string | null }
): IdentifyingProofValidation => {
  const minScore = 0.8;
  const firstnameScore =
    actual.firstname != null && expected.firstname != null
      ? getBestMatch(actual.firstname, expected.firstname)?.score
      : undefined;
  const lastnameScore =
    actual.lastname != null && expected.lastname != null
      ? getBestMatch(actual.lastname, expected.lastname)?.score
      : undefined;

  return {
    isTypeCorrect:
      actual.type != null &&
      [
        DOCUMENTTYPE.identitycard,
        DOCUMENTTYPE.passport,
        DOCUMENTTYPE.driver_licence,
        DOCUMENTTYPE.residentPermit,
      ].includes(actual.type),
    isNameCorrect:
      firstnameScore != null &&
      lastnameScore != null &&
      firstnameScore > minScore &&
      lastnameScore > minScore,
    isValidExpirationDate: checkExpirationDate(actual.expirationDate),
  };
};

interface PairWithScore {
  extractionMatch: string;
  searchMatch: string;
  score: number;
}

const getBestMatch = (
  actual: string,
  expected: string
): PairWithScore | undefined => {
  const actualWords = getComparableNameSpellingVarieties(actual);
  const expectedWords = getComparableNameSpellingVarieties(expected);

  const actualPerms = getPermutations(actualWords);
  const expectedPerms = getPermutations(expectedWords);

  const pairs = actualPerms.flatMap((extractionMatch) =>
    expectedPerms.map((searchMatch) => ({
      extractionMatch,
      searchMatch,
      score: compareTwoStrings(extractionMatch, searchMatch),
    }))
  );
  const bestScorePair = maxBy(pairs, ({ score }) => score);

  return bestScorePair;
};

/**
 * Retrieves an array of comparable spelling varieties for the given text.
 *
 * @param text - The input text.
 * @returns An array of comparable spelling varieties.
 */
const getComparableNameSpellingVarieties = (text: string): string[] => {
  const lower = text.toLowerCase();

  /**
   * Converts a string into an array of words with different split rules
   */
  const asWords = (i: string) => {
    const noneEmptyPredicate = (word: string) => word.length !== 0;
    const removeNonLetters = (word: string) => word.replace(/[^a-zA-Z]/g, '');

    return [
      // uniques from:
      ...new Set([
        ...i
          .split(/\s+/) // split all whitespace
          .filter(noneEmptyPredicate)
          .map(removeNonLetters),
        ...i
          .split(/[\s+-_]/) // split at whitespace and dashes
          .filter(noneEmptyPredicate)
          .map(removeNonLetters),
      ]),
    ];
  };

  const germanUmlautReplacements: [string, string][] = [
    ['ä', 'ae'],
    ['ö', 'oe'],
    ['ü', 'ue'],
  ];

  const withoutGermanUmlauts = asWords(
    transliterate(lower, {
      // ie. converting 'ä' to 'ae'
      replace: germanUmlautReplacements,
    })
  );
  const withGermanUmlauts = asWords(
    transliterate(lower, {
      // ie. converting 'ä' to 'a'
      replace: [],
    })
  );

  const unique = [
    // uniques from:
    ...new Set([lower, ...withoutGermanUmlauts, ...withGermanUmlauts]),
  ];

  return unique;
};

/**
 * Checks if the expiration date is in the future. It has one day tolerance, so it can handle time zone issues.
 * @param expirationDate ISO date string, e.g. "2023-01-01"
 * @returns
 */
const checkExpirationDate = (expirationDate?: string | null): boolean => {
  if (expirationDate == null) return false;
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return expirationDate > yesterday.toISOString();
};
