import { InjectionToken } from '@angular/core';

export const HREF_BUILDER = new InjectionToken<HrefBuilder>('HREF_BUILDER');

interface TaskIslandHrefBuilder {
  classic?: {
    task?: (taskRef?: string) => string | null;
    client?: (clientId?: string) => string | null;
    taxcase?: (clientId?: string) => string | null;
  };
  crm?: (clientId?: string) => string | null;
}

interface CrmHrefBuilder {
  client?: (clientId?: string) => string | null;
  taxcase?: (clientId?: string, taxcaseId?: string) => string | null;
}

export interface HrefBuilder {
  taskIsland?: TaskIslandHrefBuilder;
  crm?: CrmHrefBuilder;
}
