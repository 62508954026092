<div class="dropdown dropdown-inline" *ngIf="userservice.users$">
  <a
    class="btn btn-clean btn-hover-light-primary btn-md btn-icon"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <i class="ki ki-bold-more-ver"></i>
  </a>
  <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
    <!-- REJECTED STATE-->
    <ul
      class="navi navi-hover"
      *ngIf="getCurrentTaxCaseStatus() === TAXCASESTATUS.ENTRY"
    >
      <li class="navi-header pb-1">
        <span class="text-primary text-uppercase font-weight-bold font-size-sm"
          >Akzeptieren</span
        >
      </li>
      <li class="navi-item dropdown-submenu">
        <a
          class="navi-link dropdown-toggle"
          data-toggle="dropdown"
          role="button"
        >
          <span class="navi-text">Akzeptieren und zuweisen</span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" (click)="acceptAndAssignToMe()"
              ><b>Mir zuweisen</b></a
            >
          </li>

          <li *ngIf="userservice.users$ | async; let users">
            <a
              class="dropdown-item"
              *ngFor="let user of users"
              (click)="acceptAndAssignToSomeone(user)"
            >
              {{ user.displayName }}</a
            >
          </li>
        </ul>
      </li>
      <li class="navi-header pb-1">
        <span class="text-primary text-uppercase font-weight-bold font-size-sm"
          >Ablehnen</span
        >
      </li>
      <li
        class="navi-item"
        [swal]="{
          title: 'Ablehnen ohne Info?',
          text: 'Fall wird abgelehnt ohne Infos',
          type: 'warning',
          showCancelButton: true
        }"
        (showCancelButton)="(true)"
        (focusCancel)="(true)"
        (confirm)="decline('Abgelehnt', false, TAXCASESTATUS_REJECT.OTHER)"
      >
        <a class="navi-link">
          <span class="navi-text"> Ablehnen ohne Info </span>
        </a>
      </li>
      <li class="navi-item dropdown-submenu">
        <a
          class="navi-link dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="navi-text">Ablehnen und Informieren</span>
        </a>
        <ul class="dropdown-menu">
          <li
            [swal]="{
              title: 'Doppelter Eintrag',
              text: 'Fall ist doppelt',
              type: 'warning',
              showCancelButton: true
            }"
            (showCancelButton)="(true)"
            (focusCancel)="(true)"
            (confirm)="
              decline('Doppelter Eintrag', false, TAXCASESTATUS_REJECT.DOUBLE)
            "
          >
            <a class="dropdown-item"> doppelter Eintrag </a>
          </li>
          <li
            [swal]="{
              title: 'Ungültig Fall?',
              text: 'Fall ist ungültig',
              type: 'warning',
              showCancelButton: true
            }"
            (showCancelButton)="(true)"
            (focusCancel)="(true)"
            (confirm)="
              decline('Daten ungültig', false, TAXCASESTATUS_REJECT.ERROR)
            "
          >
            <a class="dropdown-item"> Ungültig </a>
          </li>

          <li
            [swal]="{
              title: 'Testfall?',
              text: 'Fall ist ein Testfall',
              type: 'warning',
              showCancelButton: true
            }"
            (showCancelButton)="(true)"
            (focusCancel)="(true)"
            (confirm)="
              decline('Daten ungültig', false, TAXCASESTATUS_REJECT.TEST)
            "
          >
            <a class="dropdown-item"> Testfall </a>
          </li>

          <li
            [swal]="{
              title: 'Betrug?',
              text: 'Fall ist ein Betrug',
              type: 'warning',
              showCancelButton: true
            }"
            (showCancelButton)="(true)"
            (focusCancel)="(true)"
            (confirm)="
              decline('Daten ungültig', false, TAXCASESTATUS_REJECT.FRAUD)
            "
          >
            <a class="dropdown-item"> Betrug </a>
          </li>

          <li
            [swal]="{
              title: 'Keine Antwort?',
              text: 'Kunde hat mehrmalig nicht geantwortet?',
              type: 'warning',
              showCancelButton: true
            }"
            (showCancelButton)="(true)"
            (focusCancel)="(true)"
            (confirm)="
              decline(
                'Kunde reagiert nicht',
                false,
                TAXCASESTATUS_REJECT.NORESPONSE
              )
            "
          >
            <a class="dropdown-item"> Keine Antwort </a>
          </li>

          <li
            [swal]="{
              title: 'Nicht attraktiv?',
              text: 'Fall ist ein nicht attraktiv',
              type: 'warning',
              showCancelButton: true
            }"
            (showCancelButton)="(true)"
            (focusCancel)="(true)"
            (confirm)="
              decline(
                'Daten ungültig',
                false,
                TAXCASESTATUS_REJECT.UNATTRACTIVE
              )
            "
          >
            <a class="dropdown-item"> Nicht attraktiv </a>
          </li>

          <li class="dropdown-divider"></li>

          <li
            [swal]="{
              title: 'Kunde hat wiederrufen?',
              text: 'Kunde hat wiederrufen',
              type: 'warning',
              showCancelButton: true
            }"
            (showCancelButton)="(true)"
            (focusCancel)="(true)"
            (confirm)="
              decline(
                'Kunde hat wiederrufen',
                false,
                TAXCASESTATUS_REJECT.CUSTOMERDECLINED
              )
            "
          >
            <a class="dropdown-item"> Kunde hat abgelehnt </a>
          </li>
        </ul>
      </li>
    </ul>

    <!-- PROGRESS TAXOFFIC-->
    <ul
      class="navi navi-hover"
      *ngIf="getCurrentTaxCaseStatus() === TAXCASESTATUS.INPROGRESS"
    >
      <li class="navi-header pb-1">
        <span class="text-primary text-uppercase font-weight-bold font-size-sm"
          >Finanzamt</span
        >
      </li>
      <li class="navi-item" (click)="sendToTaxoffice()">
        <a class="navi-link">
          <span class="navi-text">Testfall ans Finanzamt senden</span>
        </a>
      </li>
      <li
        class="navi-item"
        [swal]="{
          title: 'Fall ans Finanzamt übermitteln',
          text: 'Dadurch wird der Fall an das Finanzamt übermittelt',
          type: 'warning',
          showCancelButton: true
        }"
        (showCancelButton)="(true)"
        (focusCancel)="(true)"
        (confirm)="sendToTaxoffice(true)"
      >
        <a
          class="navi-link"
          [ngClass]="{
            disabled:
              getCurrentTaxCaseSubStatus() !==
              TAXCASESTATUS_INPROGRESS.SENTTOTAXOFFICE
          }"
        >
          <span class="navi-text">Fall ans Finanzamt einreichen</span>
        </a>
      </li>

      <li
        class="navi-item"
        *ngIf="
          getCurrentTaxCaseSubStatus() ===
          TAXCASESTATUS_INPROGRESS.SENTTOTAXOFFICE
        "
        [swal]="{
          title: 'Fall vom Finanzamt erhalten',
          text: 'Der Fall erhält den Status vom Finanzamt erhalten',
          type: 'warning',
          showCancelButton: true
        }"
        (showCancelButton)="(true)"
        (focusCancel)="(true)"
        (confirm)="
          changeStatus(
            TAXCASESTATUS.INPROGRESS,
            TAXCASESTATUS_INPROGRESS.RETURNEDFROMTAXOFFICE
          )
        "
      >
        <a class="navi-link">
          <span class="navi-text">Bescheid erhalten</span>
        </a>
      </li>
    </ul>

    <!-- PROGRESS TAXOFFICE RETURNED-->
    <ul
      class="navi navi-hover"
      *ngIf="
        getCurrentTaxCaseStatus() === TAXCASESTATUS.INPROGRESS &&
        (getCurrentTaxCaseSubStatus() ===
          TAXCASESTATUS_INPROGRESS.RETURNEDFROMTAXOFFICE ||
          getCurrentTaxCaseSubStatus() === TAXCASESTATUS_INPROGRESS.SEPA)
      "
    >
      <li class="navi-header pb-1">
        <span class="text-primary text-uppercase font-weight-bold font-size-sm"
          >FINANZEN</span
        >
      </li>
      <li class="navi-item" (click)="getSepaExport()">
        <a class="navi-link">
          <span class="navi-text">SEPA Herrunterladen</span>
        </a>
      </li>
    </ul>

    <!-- REASIGN-->
    <ul class="navi navi-hover">
      <li class="navi-header pb-1">
        <span class="text-primary text-uppercase font-weight-bold font-size-sm"
          >Allgemein</span
        >
      </li>
      <li class="navi-item" (click)="changeStatus(TAXCASESTATUS.DELETED, null)">
        <a class="navi-link">
          <span class="navi-text">Fall Löschen</span>
        </a>
      </li>
      <li class="navi-item dropdown-submenu">
        <a
          class="navi-link dropdown-toggle"
          data-toggle="dropdown"
          role="button"
        >
          <span class="navi-text">Fall zuweisen</span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" (click)="assignToMe()"
              ><b>Mir zuweisen</b></a
            >
          </li>

          <li *ngIf="userservice.users$ | async; let users">
            <a
              class="dropdown-item"
              *ngFor="let user of users"
              (click)="assignToSomeone(user)"
            >
              {{ user.displayName }}</a
            >
          </li>
        </ul>
      </li>
    </ul>

    <!-- ADMIN-->
    <ul class="navi navi-hover">
      <li class="navi-header pb-1">
        <span class="text-primary text-uppercase font-weight-bold font-size-sm"
          >ADMIN</span
        >
      </li>
      <li class="navi-item dropdown-submenu">
        <a
          class="navi-link dropdown-toggle"
          data-toggle="dropdown"
          role="button"
        >
          <span class="navi-text">Spezialstatus</span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a
              class="dropdown-item"
              (click)="
                changeStatus(
                  TAXCASESTATUS.INPROGRESS,
                  TAXCASESTATUS_INPROGRESS.READYTOSEND
                )
              "
              ><b>Bereit</b></a
            >
          </li>
          <li>
            <a
              class="dropdown-item"
              (click)="
                changeStatus(
                  TAXCASESTATUS.INPROGRESS,
                  TAXCASESTATUS_INPROGRESS.READYTOSENDWITHERROR
                )
              "
              ><b>Bereit mit Fehler</b></a
            >
          </li>
        </ul>
      </li>
    </ul>

    <!-- DEBUG
        <ul class="navi navi-hover">
            <li class="navi-header pb-1">
                <span class="text-primary text-uppercase font-weight-bold font-size-sm">Debug</span>
            </li>
            <li class="navi-item" (click)="changeStatus(TAXCASESTATUS.ARCHIVED, null)">
                <a class="navi-link">
                    <span class="navi-text">Infos</span>
                </a>
            </li>

        </ul>
-->
  </div>
</div>

<!--
<span class="btn btn-default btn-icon btn-sm mr-2" *ngIf="currentStatus === TAXCASESTATUS.INPROGRESS && currentSubStatus === TAXCASESTATUS_INPROGRESS.SEPA" data-toggle="tooltip" (click)="setSepaDate()" title="" data-original-title="SEPA Bezahldatum setzen">
    <i class="fa fas fa-calendar-day icon-sm"></i>
</span>
-->
