import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Campaign } from '@expresssteuer/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CampaignsService {
  public campaigns$: Observable<Campaign[]>;
  public currentCampain: Campaign | null;

  constructor(private db: AngularFirestore, router: Router) {
    this.campaigns$ = db.collection<Campaign>('campaigns').valueChanges();
    console.log('Kampagne init');
  }

  public async load(id: string): Promise<Campaign> {
    const campaignSnap = await this.db
      .collection<Campaign>('campaigns')
      .doc(id)
      .get()
      .toPromise();

    if (campaignSnap.exists) {
      this.currentCampain = campaignSnap.data() as Campaign;
      return this.currentCampain;
    }
  }

  public async delete(id: string): Promise<void> {
    await this.db
      .collection<Campaign>('campaigns')
      .doc(id)
      .delete()
      .catch((e) => console.error('campaign delete failed', e));
  }

  public async new(): Promise<string> {
    const newCampaign: Campaign = Campaign.getTemplate();
    newCampaign.id = this.db.createId();
    this.currentCampain = newCampaign;
    return newCampaign.id;
  }

  public async update(): Promise<void> {
    await this.db
      .collection<Campaign>('campaigns')
      .doc(this.currentCampain.id)
      .set(this.currentCampain)
      .catch((e) => console.error('could not update campaign', e));
  }
}
