import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pureMethod',
  pure: true,
})
export class PureMethodPipe implements PipeTransform {
  transform(method: undefined, args: any): undefined;
  transform<TReturn, TArgs extends any[]>(
    method: (...args: TArgs) => TReturn,
    options: { args: TArgs; thisArg?: any }
  ): TReturn;
  transform<TReturn, TArgs extends any[]>(
    method: ((...args: TArgs) => TReturn) | undefined,
    options: { args: TArgs; thisArg?: any }
  ): TReturn | undefined {
    if (method == null) return undefined;
    if (options.thisArg) {
      return method.bind(options.thisArg)(...options.args);
    }
    return method(...options.args);
  }
}
