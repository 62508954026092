import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NumberInputModule } from '../form-inputs/number-input/number-input.module';
import { TextInputModule } from '../form-inputs/text-input/text-input.module';
import { EsuiVmRequestComponent } from './esui-vm-request.component';

@NgModule({
  declarations: [EsuiVmRequestComponent],
  imports: [
    CommonModule,
    MatCardModule,
    NumberInputModule,
    TextInputModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [EsuiVmRequestComponent],
})
export class EsuiVmRequestModule {}
