import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { EsuiTaxadvisorComponent } from './taxadvisor.component';

@NgModule({
  declarations: [EsuiTaxadvisorComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
  ],
  exports: [EsuiTaxadvisorComponent],
})
export class EsuiTaxadvisorModule {}
