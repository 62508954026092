import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MARITALSTATUS } from '@expresssteuer/models';

@Component({
  selector: 'app-taxclass',
  templateUrl: './taxclass.component.html',
  styleUrls: ['./taxclass.component.scss'],
})
export class ControlTaxclassComponent implements OnInit {
  MARITALSTATUS = MARITALSTATUS;
  maritalstatusValue: MARITALSTATUS;
  taxClassValue: number;

  @Output()
  public maritalStatusChange = new EventEmitter<MARITALSTATUS>();
  @Output()
  public taxClassChange = new EventEmitter<number>();

  @Input()
  get maritalstatus(): MARITALSTATUS {
    return this.maritalstatusValue;
  }

  set maritalstatus(val: MARITALSTATUS) {
    this.maritalstatusValue = val;
    this.maritalStatusChange.emit(this.maritalstatusValue);
  }

  @Input()
  get taxclass(): number {
    return this.taxClassValue;
  }

  set taxclass(val: number) {
    if (val) {
      this.taxClassValue = val;
      this.taxClassChange.emit(this.taxClassValue);
    }
  }

  constructor() {}

  ngOnInit(): void {}
}
