export interface ManualTriggerInput {
  clientId: string;
}

export abstract class ManualTriggerInput {
  public static guard(input: unknown): input is ManualTriggerInput {
    const i = input as Partial<ManualTriggerInput> | null;
    return !!i?.clientId && typeof i.clientId === 'string';
  }
}

export type ManualTriggerOutput = void;
