<table
  class="mat-table"
  [ngClass]="{
    'mat-elevation-z8': elevated
  }"
>
  <ng-container *ngFor="let item of displayItems">
    <!-- Header Text if defined-->

    <tr class="mat-row" *ngIf="item.options?.headerText">
      <th class="mat-cell" colspan="2">
        <strong>{{ item.options?.headerText }}</strong>
      </th>
    </tr>

    <!-- Item Value-->

    <tr
      class="mat-row"
      [ngClass]="{
        bold: item.options?.bold
      }"
    >
      <td
        class="mat-cell"
        id="{{ item.id }}_label"
        [ngClass]="{
          doubleTopBorder: item.options?.doubleTopBorder
        }"
      >
        {{ item.label }}
      </td>

      <td
        class="mat-cell"
        style="text-align: right"
        id="{{ item.id }}_value"
        [ngClass]="{
          doubleTopBorder: item.options?.doubleTopBorder
        }"
      >
        {{ item.value }}
      </td>
    </tr>
  </ng-container>
</table>
