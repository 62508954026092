import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flyoutright',
  templateUrl: './flyoutright.component.html',
  styleUrls: ['./flyoutright.component.scss'],
})
export class FlyoutrightComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
