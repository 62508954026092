import { Component, forwardRef, Inject, OnInit, Optional } from '@angular/core';
import {
  BaseWidget,
  NgAisIndex,
  NgAisInstantSearch,
} from 'angular-instantsearch';
import { connectSearchBox } from 'instantsearch.js/es/connectors';

@Component({
  selector: 'app-ais-documents-search-box',
  templateUrl: './ais-documents-search-box.component.html',
  styleUrls: ['./ais-documents-search-box.component.scss'],
})
export class AisDocumentsSearchBoxComponent
  extends BaseWidget
  implements OnInit
{
  public state: {
    query: string;
    refine: (value: string) => void;
    clear: () => void;
    isSearchStalled: boolean;
    widgetParams: object;
  };
  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('AisDocumentsSearchBoxComponent');
  }
  ngOnInit() {
    this.createWidget(connectSearchBox, {
      // instance options
    });
    super.ngOnInit();
  }
}
