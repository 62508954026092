<div
  class="table-responsive"
  *ngIf="
    AisDocumentsHelperService.clusterHitsByDocumenttype(hits) as clusteredHits
  "
>
  <table class="table table-m table-hover tableFixHead">
    <ng-container
      *ngFor="
        let clusteredHitsKV of clusteredHits | keyvalue;
        trackBy: AisDocumentsHelperService.trackByKey
      "
    >
      <thead>
        <tr>
          <th colspan="6">
            {{
              AisDocumentsHelperService.mapClusterToLabel(clusteredHitsKV.key)
            }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          app-ais-documents-table-item
          *ngFor="
            let hit of clusteredHitsKV.value;
            let i = index;
            trackBy: AisDocumentsHelperService.trackByObjectID
          "
          [disabled]="selectMode"
          [hit]="hit"
          [class.selected]="isSelected(hit)"
          (click)="toggleSelection(hit)"
        ></tr>
      </tbody>
    </ng-container>
  </table>
</div>
