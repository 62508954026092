<div class="content d-flex flex-column flex-column-fluid">
  <div class="subheader subheader-transparent"></div>

  <div class="d-flex flex-column-fluid">
    <div class="container-fluid">
      <div class="d-flex flex-row">
        <div class="flex-row-auto">
          <div class="card card-custom card-stretch">
            <div class="card-body">Seite konnte nicht gefunden werden</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
