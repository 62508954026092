<mat-form-field>
  <mat-label>IBAN</mat-label>
  <input
    matInput
    type="text"
    [ngModel]="bank?.IBAN"
    (ngModelChange)="partialUpdate({ IBAN: $event })"
  />
</mat-form-field>

<!-- 
  TODO

  validated: boolean = false;
  valid?: boolean;

  bankCode: string = '';
  bankName: string = '';
  bankBIC: string = '';
  bankPLZ: string = '';
  bankCity: string = '';

 -->
