import { Pipe, PipeTransform } from '@angular/core';
import {
  BinaryDocumentClassificationFields,
  DOCUMENTTYPE,
  getFieldSuggestions,
} from '@expresssteuer/models';

@Pipe({
  name: 'scorePredictions',
})
export class ScorePredictionsPipe implements PipeTransform {
  transform(
    value: BinaryDocumentClassificationFields,
    type: DOCUMENTTYPE
  ): unknown {
    return getFieldSuggestions(value, type);
  }
}
