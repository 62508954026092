export function jsonToYaml(json: Record<string, unknown>): string {
  //using inner function to not expose recursion arguments
  function buildYaml(
    obj: Record<string, unknown>,
    indent: number = 0,
    arrayFirst = false
  ): string {
    let yaml = '';
    const keys = Object.keys(obj);
    for (const [i, key] of keys.entries()) {
      const value = obj[key];
      if (Array.isArray(value)) {
        yaml += `${' '.repeat(indent)}${key}:\n`;
        for (const item of value) {
          yaml += `${' '.repeat(indent + 2)}- ${buildYaml(
            item,
            indent + 4,
            true
          )}`;
        }
      } else if (typeof value === 'object' && value !== null) {
        if (Object.keys(value).length >= 1)
          yaml += `${' '.repeat(indent)}${key}:\n${buildYaml(
            value as Record<string, unknown>,
            indent + 2
          )}`;
      } else if (value !== undefined) {
        yaml += `${' '.repeat(
          arrayFirst && i === 0 ? 0 : indent
        )}${key}: <b>${value}</b>\n`;
      }
    }
    return yaml;
  }

  return buildYaml(json);
}
