import { v4 } from 'uuid';
import { BankTransactionLine } from '@expresssteuer/banking-models';
import { Timestamp } from '../helpers/timestamp';

/**
 * DE : Steuerbescheid
 * A TaxNotice is the return from the taxoffice showing the exact return value
 * This document will be created when we send it to the taxoffice
 * This document will be reflected in the taxcase
 * @collection clients/{clientId}/taxnotices/{clientid_taxyear}
 */
export abstract class TaxNotice {
  /**
   * Generated the ID for a taxnotice.
   * We use this to identify the taxnotice document
   * @param clientId
   * @param taxyear
   * @returns
   */
  static getId(clientId: string, taxyear: number | string): string {
    return `${clientId}_${taxyear}`;
  }

  /**
   *
   * @param type type of taxnotice: initial or change
   * @param clientId clientId of taxnotice
   * @param taxYear year for taxnotice
   * @returns id for initial taxnotice (One per client per year) or change (multiple)
   */
  static getIdForType(
    type: TaxnoticeType,
    clientId: string,
    taxYear: number | string
  ): string {
    if (type === TaxnoticeType.INITIAL) {
      return `${clientId}_${taxYear}`;
    }
    if (type === TaxnoticeType.CHANGE || type === TaxnoticeType.PREPAID) {
      return `${clientId}_${taxYear}_${v4()}`;
    } else {
      throw new Error(`type ${type} not implemented`);
    }
  }

  /**
   * Calculates the real payout from the TaxOffice
   * based on the calculated taxreturn - all deductions
   * @param taxNotice
   */
  static payout(taxNotice: TaxNotice): number {
    const deductions = taxNotice.deductions.reduce((acc, d) => {
      return acc + d.value;
    }, 0);
    return (taxNotice.actualTaxReturn ?? 0) - deductions;
  }

  /**
   * Is document already verified and does not need a task
   * Requirements might be expanded in future
   */
  static isVerified(taxNotice: TaxNotice): boolean {
    return taxNotice.verified;
  }

  static getTemplate(
    input: Partial<TaxNotice> & {
      taxYear: number;
      clientId: string;
      taxNoticeReturnDate: Timestamp | null;
      metadata: TaxNotice['metadata'];
    }
  ): TaxNotice {
    const bescheid: TaxNotice = {
      id: this.getId(input.clientId, input.taxYear),
      deductions: [],
      transactionLines: [],
      verified: false,
      appealedStatus: AppealStatus.NOT_APPEALED,
      taxnoticeType: TaxnoticeType.INITIAL,
      manualProcessDone: false,
      wrongPayoutAccount: null,
      ...input,
    };
    return bescheid;
  }

  /**
   * The id should be allways
   * clientId_taxYear for type INITIAL
   *  When dealing with change taxnotices there can be multiple per taxyear and customer
   */
  abstract id: string;

  /**
   * The calculated return from us
   * this is an optional value
   */
  abstract expectedTaxReturn?: number;
  /**
   * Declared tax return for the year by taxNotice
   * This is the value we use to calculate our mage from
   * This value - deductions = is the real payout
   */
  abstract actualTaxReturn?: number;
  /**
   * Amount of return or payback stated by taxnotice
   * includes all deductions and other additions stated in the document
   */
  abstract transferAmount?: number;
  /**
   * was customer informed and received this taxnotice
   * especially relevant if backpayer
   */
  abstract sentToCustomer?: boolean;
  /**
   * The taxcase year
   */
  abstract taxYear: number;
  /**
   * The client id
   */
  abstract clientId: string;

  /**
   * the associated taxacase for the taxYear
   */
  abstract taxCaseId?: string | null;

  /**
   * All deductions from the taxreturn
   */
  abstract deductions: TaxDeduction[];

  /**
   * All additions from other taxnotice returns which may offset negative amount of this one
   */
  abstract additions?: TaxAddition[] | null;

  /**
   * We assign the corresponding bank transactions to the taxnotice
   */
  abstract transactionLines: BankTransactionLine[];

  /**
   * @Andi This is a hack dont blame me :-)
   * possible transactions predicted.
   * Might be removed in the future.
   * This is helpful until the process of connecting Bank Transactions and Tax Notices is clear
   */
  abstract transactionLinesPredicted?: BankTransactionLine[];

  /**
   * The TaxNotice is verified by an agent.
   * This is the trigger to go into invoicing
   */
  abstract verified: boolean;

  abstract metadata: {
    created: Timestamp | null;
  };

  /**
   * The official return date stated on the TaxNotice return
   */
  abstract taxNoticeReturnDate: Timestamp | null;

  abstract binaryDocumentRef?: string;

  /**
   * The text from the "Bescheid" as a string; such as OCR or parsedPdf
   */
  abstract asText?: string;

  /**
   * Indicates whether an appeal process for this taxnotice is running
   */
  abstract appealedStatus: AppealStatus;
  /**
   * what type of taxnotice is it = INITIAL, CHANGE, PREPAID
   */
  abstract taxnoticeType: TaxnoticeType;
  /**
   *  if team has done their manual process
   */
  abstract manualProcessDone: boolean;
  /**
   * if taxnotice was issued by taxoffice because customer missed deadline -> we might not have taxcase
   */
  abstract isPredictionTaxnotice?: boolean | null;
  /**
   * does taxnotice state a IBAN where money was sent to instead of us
   */
  abstract receivingIban?: string | null;
  /**
   * bank name stated as recieving on taxnotice
   */
  abstract receivingBankName?: string | null;
  /**
   * is this taxnotice handled by us and paid to customer
   */
  abstract processedByPayout?: boolean | null;
  /**
   * amount of taxnotice return processed by payout process
   */
  abstract amountProcessedByPayout?: number | null;
  /**
   * indicates whether the taxnotices states a diffrent bank account than we set wen sending the taxcase to finance office
   * Propably it got send to customer directly
   * null | undefined indicates we don't know yet if this is the case
   */
  abstract wrongPayoutAccount?: boolean | null;
}

export enum TaxDeductionType {
  /**
   * DE: Steuerschulden
   */
  TaxDepts = 'taxDepts',
  /**
   * DE: Falschberechnung
   */
  WrongCalculation = 'wrongCalculation',
  /**
   * DE: Pensionsanspruch
   */
  PensionExpenditure = 'pensionExpenditure',
  /**
   * DE: Alleinerziehend
   */
  SingleParenting = 'singleParenting',
  /**
   * DE: Verpflegungsmehraufwendungen
   */
  AdditionalExpenses = 'additionalExpenses',
  /**
   * DE: Pendlerpauschale
   */
  CommuterAllowance = 'commutingAllowance',
}

export enum TaxAdditionType {
  /**
   * DE: Aufrechnung
   */
  ByOtherTaxnotice = 'BY_OTHER_TAXNOTICE',
  InterestCharges = 'INTEREST_CHARGES',
  LatePaymentPenalties = 'LATE_PAYMENT_PENALTIES',
  LateCharges = 'LATE_CHARGES',
  Other = 'OTHER',
  SameTaxnotice = 'SAME_TAXNOTICE',
}

export enum AppealStatus {
  NOT_APPEALED = 'not appealed',
  APPEAL_REQUIRED = 'appeal required',
  APPEAL_CONFIRM = 'appeal confirmation required',
  APPEALED_IN_PROCESS = 'appeal in process',
  APPEALED_DONE = 'appeal done',
  APPEAL_NOT_REQUIRED = 'appeal not needed',
}

export enum TaxnoticeType {
  INITIAL = 'initial',
  CHANGE = 'change',
  PREPAID = 'prepaid',
}

/**
 * Deductions why our calculation might be wrong.
 * Even if the calculation is correct, we might have to deduct something
 * like a pensionExpenditure or a singleParenting we did not know.
 * Or the client has not payed his last taxes so the taxoffice is only paying out the difference
 */
export class TaxDeduction {
  type: TaxDeductionType | string = TaxDeductionType.TaxDepts;
  value: number = 0;

  static getTemplate(): TaxDeduction {
    return {
      type: TaxDeductionType.TaxDepts,
      value: 0,
    };
  }
}

export abstract class TaxAddition {
  abstract type: TaxAdditionType | string;
  abstract value: number;

  static getTemplate(): TaxAddition {
    return {
      type: TaxAdditionType.ByOtherTaxnotice,
      value: 0,
    };
  }
}
