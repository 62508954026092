<esui-taxcase-comment
  *ngFor="let taxcaseComment of taxcaseComments ?? []"
  [taxcaseComment]="taxcaseComment"
></esui-taxcase-comment>

<div *ngIf="editable">
  <mat-form-field>
    <mat-label>new comment</mat-label>
    <input
      matInput
      [ngModel]="newComment"
      (ngModelChange)="newCommentChange.next($event)"
      autocomplete="off"
    />
    <button
      matSuffix
      mat-icon-button
      aria-label="send"
      (click)="newCommentSendClick.next()"
      [disabled]="!newComment"
    >
      <mat-icon>send</mat-icon>
    </button>
  </mat-form-field>
</div>
