<div class="btn-group btn-group-lg" role="group">
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="offsite === 0"
    (click)="offsite = 0"
  >
    Nie
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="offsite === 38"
    (click)="offsite = 38"
  >
    1 Tag/Woche pro 3 Monate
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="offsite === 68"
    (click)="offsite = 68"
  >
    1 Tag alle 2 Wochen
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="offsite === 158"
    (click)="offsite = 158"
  >
    3 Tage/Woche
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="offsite === 225"
    (click)="offsite = 225"
  >
    Jeden Tag
  </button>
</div>
