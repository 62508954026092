import { Component, Input } from '@angular/core';
import {
  AbstractValueAccessor,
  makeProvider,
} from '../abstract-value-accessor';

@Component({
  selector: 'esui-button-toggle-group',
  templateUrl: './button-toggle-group.component.html',
  styleUrls: ['./button-toggle-group.component.scss'],
  providers: [...makeProvider(ButtonToggleGroupComponent)],
})
export class ButtonToggleGroupComponent<
  TData = any
> extends AbstractValueAccessor<TData | null> {
  @Input() options: {
    value: TData;
    key: string;
    label: string;
    icon?: string;
  }[] = [];

  @Input() vertical = false;

  get empty(): boolean {
    return !this.value;
  }

  controlType = 'ButtonToggleGroupComponent';

  protected data = null;

  idByKey({ key }: any) {
    return key;
  }

  onContainerClick(event: MouseEvent): void {}
}
