import { string, ZodType } from 'zod';

interface Timestamp {
  toDate: () => Date;
}

function isTimestamp(val: unknown) {
  return typeof (val as any).toDate === 'function';
}

type ValidISODate = `${ValidISOYear}-${ValidISOMonth}-${ValidISODay}`;
type ValidISOYear = `${ValidYearDigits}`;
type ValidYearDigits = `${number}${number}${number}${number}`;
type ValidISOMonth = `${number}${number}`;
type ValidISODay = `${number}${number}`;

export type DateString = ValidISODate;

const DateStringRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
export const DateStringZod = string().regex(
  DateStringRegex
) as ZodType<DateString>;

export function isValidDateString(data: unknown): data is DateString {
  return typeof data === 'string' && isNaN(+new Date(data)) === false;
}

export function fromDate(data: Date): DateString {
  return data.toISOString().substring(0, 10) as DateString;
}

export function fromTimestamp(data: Timestamp): DateString {
  return fromDate(data.toDate());
}

export type Dateable = string | Date | Timestamp;

export function asDateString<TF>(data: unknown, fallback: TF): DateString | TF;
export function asDateString(data: unknown): DateString | undefined;
export function asDateString(
  data: Dateable | undefined | null
): DateString | undefined;
export function asDateString<TF>(
  data: Dateable | undefined | null,
  fallback: TF
): DateString | TF;
export function asDateString<TF>(
  data: unknown | Dateable | undefined | null,
  fallback?: TF
): DateString | TF | undefined {
  try {
    if (isValidDateString(data)) {
      return data;
    }
    if (data instanceof Date) {
      return fromDate(data);
    }
    if (isTimestamp(data)) {
      return fromTimestamp(data as any);
    }
    throw new Error('asDateString: data is not Dateable');
  } catch (error) {
    return fallback;
  }
}
