import { max, mean, min } from 'lodash';
import { Timestamp } from '../../helpers/timestamp';
import {
  BinaryDocument,
  BinaryDocumentClassificationFields,
  DOCUMENTTYPE,
} from '../document';
import {
  DocumentTypeFields,
  isDocumentTypeFieldsKey,
} from '../specific-documents';
import { getFieldSuggestions } from './field-suggestions';

export function mergeFieldsWithPredictions(
  binaryDocument: BinaryDocument<string, any>,
  documenttype: DOCUMENTTYPE
) {
  const oldParsedFieldValues = binaryDocument?.parsedFields as any;

  // merge parsedFields
  const newParsedFieldTypes = DocumentTypeFields[documenttype];
  const mergedParsedFieldValues: Record<string | number, any> = {};
  const fieldSuggestions = getFieldSuggestions(
    binaryDocument.metadata?.mldata?.predictions?.fields,
    documenttype
  );
  (
    Object.keys(
      newParsedFieldTypes
    ) as (keyof BinaryDocumentClassificationFields)[]
  ).forEach((fieldKey) => {
    const fieldSuggestion = fieldSuggestions[fieldKey];

    let predictedValue = null;
    if (fieldSuggestion && fieldSuggestion.length > 0) {
      if (fieldSuggestion.length >= 1) {
        predictedValue = fieldSuggestion[0].suggestion;
      }
    }

    mergedParsedFieldValues[fieldKey] =
      oldParsedFieldValues?.[fieldKey] || predictedValue;
  });
  return mergedParsedFieldValues;
}

/**
 * TODO: name stuff
 */
export function getScoreForFieldsBasedOnType(
  binaryDocumentClassificationFields:
    | BinaryDocumentClassificationFields
    | undefined,
  documenttype: DOCUMENTTYPE
) {
  const scores = Object.values(
    mergePredictionsWithParsedFields(
      binaryDocumentClassificationFields,
      documenttype
    )
  ).map((field) => {
    return field.predictedScore;
  });

  const scoring = {
    min: min(scores),
    max: max(scores),
    mean: mean(scores),
  };

  return scoring;
}

/**
 *
 * @param binaryDocument TODO: calculate on actual parsed fields
 * @param documenttype
 * @returns
 */
export function mergePredictionsWithParsedFields(
  binaryDocumentClassificationFields:
    | BinaryDocumentClassificationFields
    | undefined,
  documenttype: DOCUMENTTYPE
): Record<string, todo> {
  const fieldsForType = isDocumentTypeFieldsKey(documenttype)
    ? DocumentTypeFields[documenttype]
    : undefined;
  const fieldsPredictionsAndScores: Record<string, todo> = {};

  const fieldSuggestions = getFieldSuggestions(
    binaryDocumentClassificationFields,
    documenttype
  );
  (
    Object.keys(
      fieldsForType ?? {}
    ) as (keyof BinaryDocumentClassificationFields)[]
  ).forEach((fieldKey) => {
    const fieldSuggestion = fieldSuggestions[fieldKey];

    let predictedScore = 0;
    let predictedValue = null;
    if (fieldSuggestion && fieldSuggestion.length > 0) {
      if (fieldSuggestion.length >= 1) {
        predictedScore = fieldSuggestion[0].score || 0;
        predictedValue = fieldSuggestion[0].suggestion || null;
      }
    }

    fieldsPredictionsAndScores[fieldKey] = { predictedScore, predictedValue };
  });
  return fieldsPredictionsAndScores;
}

interface todo {
  predictedScore: number;
  predictedValue: string | number | number[] | Timestamp | null;
}
