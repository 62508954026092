import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@expresssteuer/models';

@Component({
  selector: 'app-user-switcher',
  templateUrl: './user-switcher.component.html',
  styleUrls: ['./user-switcher.component.scss'],
})
export class UserSwitcherComponent {
  @Input()
  allUsers: User[];
  @Input()
  currentUser: User;

  @Output()
  valueChange: EventEmitter<User>;

  constructor() {
    this.valueChange = new EventEmitter<User>();
  }
}
