import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  ValidateIbanInput,
  ValidateIbanOutput,
} from '@expresssteuer/iban-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class ValidateIban {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: ValidateIbanInput) {
    console.log('Calling iban-validateIban');
    return this.afFunctions.httpsCallable<
      ValidateIbanInput,
      ValidateIbanOutput
    >('iban-validateIban')(input);
  }
}
