import { IProfession } from '../../interfaces/job';
import { ADDITIONALEXPENSECATEGORY } from '../additionalexpenses/additionalexpenses';
import { AdditionalExpense, IAdditionalExpense } from '../taxform';

export enum FORMTYPE {
  'YESNO' = 'YesNo',
  'SIMPLE' = 'simple',
  'DROPDOWN' = 'dropdown',
}

export class Profession implements IProfession {
  public static getTemplate(): Profession {
    return {
      labelDe: '',
      professiongroup: '',
      value: '',
    };
  }
  labelDe: string = '';
  professiongroup: string = '';
  value: string = '';
}
export interface IProfessionQuestion {
  descriptionDe: string;
  labelDe: string;
  order: number;
  formtype: FORMTYPE;
  expenseType: ADDITIONALEXPENSECATEGORY;
  expenseLabelDe: string;
  valueDefault: number;
}

export class ProfessionQuestion implements IProfessionQuestion {
  descriptionDe: string = '';
  labelDe: string = '';
  order: number = 0;
  formtype: FORMTYPE = FORMTYPE.SIMPLE;
  expenseType: ADDITIONALEXPENSECATEGORY = ADDITIONALEXPENSECATEGORY.advertise;
  expenseLabelDe: string = '';
  valueDefault: number = 0;
}

export interface IDropdownQuestionItem {
  id: string;
  labelDe: string;
  value: number;
}

export class DropdownQuestionItem implements IDropdownQuestionItem {
  id: string = '';
  labelDe: string = '';
  value: number = 0;

  constructor(id: string, labelDe: string, value: number) {
    this.id = id;
    this.labelDe = labelDe;
    this.value = value;
  }
}

export interface IMinMax {
  valueMin: number;
  valueMax: number;
}

export interface IDropdownQuestion extends IProfessionQuestion {
  options: IDropdownQuestionItem[];
}

export interface IYesNoQuestion extends IProfessionQuestion, IMinMax {
  valueTrueQuestion: string;
}

export interface ISimpleQuestion extends IProfessionQuestion, IMinMax {}

export class BaseQuestion implements IProfessionQuestion {
  descriptionDe: string = '';
  labelDe: string = '';
  order: number = 0;
  formtype: FORMTYPE = FORMTYPE.SIMPLE;
  expenseType: ADDITIONALEXPENSECATEGORY;
  expenseLabelDe: string = '';
  valueDefault: number = 0;

  constructor(
    formtype: FORMTYPE,
    labelDe: string,
    descriptionDe: string,
    expenseType: ADDITIONALEXPENSECATEGORY,
    expenseLabelDe: string,
    defaultValue: number
  ) {
    this.formtype = formtype;
    this.labelDe = labelDe;
    this.descriptionDe = descriptionDe;
    this.valueDefault = defaultValue;
    this.expenseType = expenseType;
    this.expenseLabelDe = expenseLabelDe;
  }
}
export class SimpleQuestion extends BaseQuestion {
  valueMin: number = 0;
  valueMax: number = 0;

  constructor(
    labelDe: string,
    descriptionDe: string,
    expenseType: ADDITIONALEXPENSECATEGORY,
    expenseLabelDe: string,
    defaultValue: number,
    minValue?: number,
    maxValue?: number
  ) {
    super(
      FORMTYPE.SIMPLE,
      labelDe,
      descriptionDe,
      expenseType,
      expenseLabelDe,
      defaultValue
    );
    this.valueMin = minValue || 0;
    this.valueMax = maxValue || 0;
  }
}

export class YesNoQuestion extends BaseQuestion {
  override formtype: FORMTYPE = FORMTYPE.YESNO;
  valueTrueQuestion: string = '';

  constructor(
    labelDe: string,
    descriptionDe: string,
    expenseType: ADDITIONALEXPENSECATEGORY,
    expenseLabelDe: string,
    defaultValue: number,
    _minValue?: number,
    _maxValue?: number,
    valueTrueQuestion?: string
  ) {
    super(
      FORMTYPE.SIMPLE,
      labelDe,
      descriptionDe,
      expenseType,
      expenseLabelDe,
      defaultValue
    );
    this.labelDe = labelDe;
    this.descriptionDe = descriptionDe;
    this.valueDefault = defaultValue || 0;
    this.valueTrueQuestion = valueTrueQuestion || '';
  }
}
export class DropdownQuestion extends BaseQuestion {
  options: DropdownQuestionItem[] = [];
  constructor(
    labelDe: string,
    descriptionDe: string,
    expenseType: ADDITIONALEXPENSECATEGORY,
    expenseLabelDe: string,
    defaultValue: number,
    _options?: DropdownQuestionItem[]
  ) {
    super(
      FORMTYPE.SIMPLE,
      labelDe,
      descriptionDe,
      expenseType,
      expenseLabelDe,
      defaultValue
    );
  }
}

export interface IProfessionGroup {
  id: string;
  name: string;
  expenses: IAdditionalExpense[];
  questions: IProfessionQuestion[];
}

export class ProfessionGroup implements IProfessionGroup {
  id: string = '';
  name: string = '';
  expenses: AdditionalExpense[] = [];
  questions: ProfessionQuestion[] = [];

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}
