import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-status-in-progress-taxoffice-send',
  templateUrl: './button-status-in-progress-taxoffice-send.component.html',
  styleUrls: ['./button-status-in-progress-taxoffice-send.component.scss'],
})
export class ButtonStatusInProgressTaxofficeSendComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
