import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FileDownloaderService } from '../../file-downloader.service';
import { PDF_WORKER_SRC } from './pdf-worker-src.token';

@Component({
  selector: 'esui-pdf-viewer-2',
  templateUrl: './pdf-viewer-2.component.html',
  styleUrls: ['./pdf-viewer-2.component.scss'],
})
export class PdfViewerLocalComponent {
  @Input()
  public downloadUrl = '';

  @Input()
  public showNavigation = true;

  @Input()
  public showDownloadButton = false;

  @Input()
  public fileName?: string;

  getFileName(url: string, fileName?: string) {
    return (
      fileName ?? (new URL(url).pathname.split('/').at(-1) || 'unknown.pdf')
    );
  }

  @Input()
  useInternalState = true;

  @Input() zoom = 1;
  @Output() zoomChange = new EventEmitter<number>();

  @Input() rotation = 0;
  @Output() rotationChange = new EventEmitter<number>();

  public zoomBy(amount: number) {
    const newZoom = this.zoom + amount;
    this.zoomChange.next(newZoom);

    if (this.useInternalState) {
      this.zoom = newZoom;
    }
  }

  public rotate() {
    const newRotation = this.rotation - 90;
    this.rotationChange.next(newRotation);

    if (this.useInternalState) {
      this.rotation = newRotation;
    }
  }

  public downloadFile(downloadUrl: string, fileName?: string) {
    return this.fileDownloaderService.downloadResource(downloadUrl, fileName);
  }

  constructor(
    @Inject(DOCUMENT) documentRef: Document,
    private fileDownloaderService: FileDownloaderService,
    @Optional() @Inject(PDF_WORKER_SRC) pdfWorkerSrc?: string
  ) {
    const defaultPdfWorkerSrc = undefined; // '/assets/ui-components/pdf.worker.js'; // TODO include in assets of library and generically import library assets in consuming apps - penging Angular v15

    const windowRef = documentRef.defaultView;

    if (defaultPdfWorkerSrc || pdfWorkerSrc) {
      (windowRef as any).pdfWorkerSrc = pdfWorkerSrc ?? defaultPdfWorkerSrc;
    }
  }
}
