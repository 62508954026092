import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'esui-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent {
  @Input() users: any[] = [];

  @Output() ngModelChange = new EventEmitter<any[]>();
  @Input() ngModel: any[] = [];

  @Input() filter?: string | null;
  @Output() filterChange = new EventEmitter<string>();

  @Input() showFilter = false;
}
