import {
  TAXCASESTATUS,
  TaxCaseStatus,
  TAXCASESTATUS_INPROGRESS,
  TAXCASESTATUS_REJECT,
} from './taxcase';

export class TaxCaseStatusMatrix {
  public static NEW: TaxCaseStatus[] = [
    { current: TAXCASESTATUS.ENTRY, substatus: null },
  ];
  public static INPROGRESS: TaxCaseStatus[] = [
    { current: TAXCASESTATUS.INPROGRESS, substatus: null },
    {
      current: TAXCASESTATUS.INPROGRESS,
      substatus: TAXCASESTATUS_INPROGRESS.APPEALED,
    },
    {
      current: TAXCASESTATUS.INPROGRESS,
      substatus: TAXCASESTATUS_INPROGRESS.ELSTER_PROD_PREPARE,
    },
    // {
    //   current: TAXCASESTATUS.INPROGRESS,
    //   substatus: TAXCASESTATUS_INPROGRESS.ELSTER_TEST_COMPLETE,
    // },
    {
      current: TAXCASESTATUS.INPROGRESS,
      substatus: TAXCASESTATUS_INPROGRESS.ELSTER_TEST_PREPARE,
    },
    // {
    //   current: TAXCASESTATUS.INPROGRESS,
    //   substatus: TAXCASESTATUS_INPROGRESS.MONEYRECIEVED,
    // },
    {
      current: TAXCASESTATUS.INPROGRESS,
      substatus: TAXCASESTATUS_INPROGRESS.PAID,
    },
    // {
    //   current: TAXCASESTATUS.INPROGRESS,
    //   substatus: TAXCASESTATUS_INPROGRESS.PAYMENTDECLINED,
    // },
    // {
    //   current: TAXCASESTATUS.INPROGRESS,
    //   substatus: TAXCASESTATUS_INPROGRESS.PAYMENTNOTPOSSIBLE,
    // },
    {
      current: TAXCASESTATUS.INPROGRESS,
      substatus: TAXCASESTATUS_INPROGRESS.QUESTIONSFROMTAXOFFICE,
    },
    {
      current: TAXCASESTATUS.INPROGRESS,
      substatus: TAXCASESTATUS_INPROGRESS.READYTOSEND,
    },
    {
      current: TAXCASESTATUS.INPROGRESS,
      substatus: TAXCASESTATUS_INPROGRESS.READYTOSENDWITHERROR,
    },
    {
      current: TAXCASESTATUS.INPROGRESS,
      substatus: TAXCASESTATUS_INPROGRESS.RETURNEDFROMTAXOFFICE,
    },
    // {
    //   current: TAXCASESTATUS.INPROGRESS,
    //   substatus: TAXCASESTATUS_INPROGRESS.SENTTOTAXOFFICE,
    // },
    // {
    //   current: TAXCASESTATUS.INPROGRESS,
    //   substatus: TAXCASESTATUS_INPROGRESS.SEPAPROCESSED,
    // },
    {
      current: TAXCASESTATUS.INPROGRESS,
      substatus: TAXCASESTATUS_INPROGRESS.WAITINGDATA,
    },
    {
      current: TAXCASESTATUS.INPROGRESS,
      substatus: TAXCASESTATUS_INPROGRESS.WAITINGFORDATARETRIEVAL,
    },
    {
      current: TAXCASESTATUS.INPROGRESS,
      substatus: TAXCASESTATUS_INPROGRESS.WAITINGFORDATARETRIEVAL_RETURNED,
    },
    {
      current: TAXCASESTATUS.INPROGRESS,
      substatus: TAXCASESTATUS_INPROGRESS.WAITINGFORDATARETRIEVAL_SENT,
    },
    // {
    //   current: TAXCASESTATUS.INPROGRESS,
    //   substatus: TAXCASESTATUS_INPROGRESS.WAITINGFORMONEY,
    // },
    // {
    //   current: TAXCASESTATUS.INPROGRESS,
    //   substatus: TAXCASESTATUS_INPROGRESS.WAITINGFORTAXID,
    // },
    {
      current: TAXCASESTATUS.INPROGRESS,
      substatus: TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_APPROVAL_PREPARE,
    },

    {
      current: TAXCASESTATUS.INPROGRESS,
      substatus: TAXCASESTATUS_INPROGRESS.READY_FOR_CLIENT_APPROVAL,
    },

    /** REJECTED */
    {
      current: TAXCASESTATUS.REJECT,
      substatus: TAXCASESTATUS_REJECT.CUSTOMERDECLINED,
    },
    {
      current: TAXCASESTATUS.REJECT,
      substatus: TAXCASESTATUS_REJECT.CUSTOMERDECLINED,
    },
  ];
  public static REJECT: TaxCaseStatus[] = [
    {
      current: TAXCASESTATUS.REJECT,
      substatus: TAXCASESTATUS_REJECT.CUSTOMERDECLINED,
    },

    { current: TAXCASESTATUS.REJECT, substatus: TAXCASESTATUS_REJECT.DOUBLE },
    { current: TAXCASESTATUS.REJECT, substatus: TAXCASESTATUS_REJECT.ERROR },
    { current: TAXCASESTATUS.REJECT, substatus: TAXCASESTATUS_REJECT.FRAUD },
    {
      current: TAXCASESTATUS.REJECT,
      substatus: TAXCASESTATUS_REJECT.NORESPONSE,
    },
    { current: TAXCASESTATUS.REJECT, substatus: TAXCASESTATUS_REJECT.OTHER },
    { current: TAXCASESTATUS.REJECT, substatus: TAXCASESTATUS_REJECT.TEST },
    {
      current: TAXCASESTATUS.REJECT,
      substatus: TAXCASESTATUS_REJECT.UNATTRACTIVE,
    },
    {
      current: TAXCASESTATUS.REJECT,
      substatus: TAXCASESTATUS_REJECT.WRONG_TARGETGROUP,
    },
  ];
  public static DONE: TaxCaseStatus[] = [
    { current: TAXCASESTATUS.DONE, substatus: null },
  ];

  /**
   * Return all possible stati for a specific status
   * @param status
   * @returns
   */
  public static getPossibleStati(
    status: TaxCaseStatus
  ): TaxCaseStatusMatrixResult {
    let allowedTaxCaseStatus: TaxCaseStatus[] = [];
    let prefered: TaxCaseStatus[] = [];

    //** ENTRY */
    switch (status.current) {
      case TAXCASESTATUS.ENTRY:
        allowedTaxCaseStatus = [
          ...TaxCaseStatusMatrix.NEW,
          {
            current: TAXCASESTATUS.INPROGRESS,
            substatus: TAXCASESTATUS_INPROGRESS.WAITINGDATA,
          },
          {
            current: TAXCASESTATUS.INPROGRESS,
            substatus: TAXCASESTATUS_INPROGRESS.WAITINGFORDATARETRIEVAL,
          },
        ];

        prefered = [
          {
            current: TAXCASESTATUS.INPROGRESS,
            substatus: TAXCASESTATUS_INPROGRESS.WAITINGDATA,
          },
          {
            current: TAXCASESTATUS.INPROGRESS,
            substatus: TAXCASESTATUS_INPROGRESS.WAITINGFORDATARETRIEVAL,
          },
          {
            current: TAXCASESTATUS.REJECT,
            substatus: TAXCASESTATUS_REJECT.DOUBLE,
          },
        ];
        break;
      case TAXCASESTATUS.INPROGRESS:
        allowedTaxCaseStatus = [
          ...TaxCaseStatusMatrix.INPROGRESS,
          ...TaxCaseStatusMatrix.REJECT,
          ...TaxCaseStatusMatrix.DONE,
        ];

        prefered = [
          {
            current: TAXCASESTATUS.INPROGRESS,
            substatus: TAXCASESTATUS_INPROGRESS.WAITINGDATA,
          },
          {
            current: TAXCASESTATUS.INPROGRESS,
            substatus: TAXCASESTATUS_INPROGRESS.ELSTER_TEST_PREPARE,
          },
        ];

        switch (status.substatus) {
          case TAXCASESTATUS_INPROGRESS.WAITINGDATA:
            prefered.push({
              current: TAXCASESTATUS.INPROGRESS,
              substatus: TAXCASESTATUS_INPROGRESS.ELSTER_TEST_PREPARE,
            });
            break;
          case TAXCASESTATUS_INPROGRESS.ELSTER_PROD_PREPARE:
            prefered.push({
              current: TAXCASESTATUS.INPROGRESS,
              substatus: TAXCASESTATUS_INPROGRESS.READYTOSENDWITHERROR,
            });
            break;
          case TAXCASESTATUS_INPROGRESS.ELSTER_TEST_COMPLETE:
            prefered.push({
              current: TAXCASESTATUS.INPROGRESS,
              substatus: TAXCASESTATUS_INPROGRESS.ELSTER_TEST_PREPARE,
            });
            prefered.push({
              current: TAXCASESTATUS.INPROGRESS,
              substatus: TAXCASESTATUS_INPROGRESS.READYTOSEND,
            });
            break;

          case TAXCASESTATUS_INPROGRESS.READYTOSEND:
            prefered.push({
              current: TAXCASESTATUS.INPROGRESS,
              substatus:
                TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_APPROVAL_PREPARE,
            });
            prefered.push({
              current: TAXCASESTATUS.INPROGRESS,
              substatus: TAXCASESTATUS_INPROGRESS.READY_FOR_CLIENT_APPROVAL,
            });
            break;
        }

        break;
      case TAXCASESTATUS.REJECT:
        allowedTaxCaseStatus = [
          ...TaxCaseStatusMatrix.NEW,
          ...TaxCaseStatusMatrix.REJECT,
        ];

        prefered = [
          {
            current: TAXCASESTATUS.ENTRY,
            substatus: null,
          },
        ];
        break;
      case TAXCASESTATUS.DONE:
        allowedTaxCaseStatus = [
          {
            current: TAXCASESTATUS.ENTRY,
            substatus: null,
          },
          {
            current: TAXCASESTATUS.DONE,
            substatus: TAXCASESTATUS_INPROGRESS.PAID,
          },
          {
            current: TAXCASESTATUS.INPROGRESS,
            substatus: TAXCASESTATUS_INPROGRESS.PAID,
          },
        ];

        prefered = [
          {
            current: TAXCASESTATUS.DONE,
            substatus: TAXCASESTATUS_INPROGRESS.PAID,
          },
          {
            current: TAXCASESTATUS.ENTRY,
            substatus: null,
          },
        ];
        break;
    }

    const statusResult: TaxCaseStatusMatrixResult =
      new TaxCaseStatusMatrixResult();

    statusResult.taxcaseStatus = allowedTaxCaseStatus;

    allowedTaxCaseStatus.forEach((s) =>
      !statusResult.status.includes(s.current)
        ? statusResult.status.push(s.current)
        : null
    );

    allowedTaxCaseStatus
      .filter((s) => s.substatus && s.current === TAXCASESTATUS.INPROGRESS)
      .forEach((s) =>
        !statusResult.substatus_progress.includes(s.substatus as any)
          ? statusResult.substatus_progress.push(
              s.substatus as TAXCASESTATUS_INPROGRESS
            )
          : null
      );

    allowedTaxCaseStatus
      .filter((s) => s.substatus && s.current === TAXCASESTATUS.REJECT)
      .forEach((s) =>
        !statusResult.substatus_reject.includes(s.substatus as any)
          ? statusResult.substatus_reject.push(
              s.substatus as TAXCASESTATUS_REJECT
            )
          : null
      );

    statusResult.prefered = prefered;

    return statusResult;
  }
}

export class TaxCaseStatusMatrixResult {
  status: TAXCASESTATUS[] = [];
  substatus_progress: TAXCASESTATUS_INPROGRESS[] = [];
  substatus_reject: TAXCASESTATUS_REJECT[] = [];
  taxcaseStatus: TaxCaseStatus[] = [];

  /** Prefered next status */
  prefered: TaxCaseStatus[] = [];

  getSubstatusForStatus(
    status: TAXCASESTATUS
  ): TAXCASESTATUS_INPROGRESS[] | TAXCASESTATUS_REJECT[] {
    switch (status) {
      case TAXCASESTATUS.INPROGRESS:
        return this.substatus_progress as TAXCASESTATUS_INPROGRESS[];
      case TAXCASESTATUS.REJECT:
        return this.substatus_reject as TAXCASESTATUS_REJECT[];
    }
    return [];
  }
}
