<ng-container *ngIf="isLoading"> loading document </ng-container>
<ng-container *ngIf="!isLoading && hasErrors">
  error loading document
</ng-container>

<ng-container *ngIf="!isLoading && !hasErrors">
  <div
    class="frame"
    *ngIf="
      !isLoading && !hasErrors && contentType && downloadUrl;
      else missingInputs
    "
  >
    <div class="source" *ngIf="source">
      <mat-icon inline="true">lightbulb</mat-icon> This document is
      <ng-container [ngSwitch]="source">
        <ng-container *ngSwitchCase="DOCUMENTSOURCE.FINANCEDEPARTMENT"
          >from the financial department</ng-container
        >
        <ng-container *ngSwitchCase="DOCUMENTSOURCE.CLIENT"
          >from the client</ng-container
        >
        <ng-container *ngSwitchCase="DOCUMENTSOURCE.SYSTEM"
          >from us</ng-container
        >
        <ng-container *ngSwitchDefault>from an unknown source</ng-container>
      </ng-container>
    </div>

    <div [ngSwitch]="type">
      <esui-image-viewer
        [downloadUrl]="downloadUrl"
        *ngSwitchCase="TYPE.IMAGE"
      ></esui-image-viewer>
      <ng-container *ngSwitchCase="TYPE.PDF">
        <esui-pdf-viewer-2
          [downloadUrl]="downloadUrl"
          [showNavigation]="showNavigation"
          [showDownloadButton]="showDownloadButton"
          [fileName]="fileName"
        ></esui-pdf-viewer-2>
      </ng-container>
      <esui-audio-viewer
        [downloadUrl]="downloadUrl"
        *ngSwitchCase="TYPE.AUDIO"
      ></esui-audio-viewer>
      <div *ngSwitchDefault>
        <p>
          Currently {{ contentType }} is not supported, please contact IT if you
          need this data type
        </p>
      </div>
    </div>
  </div>
  <ng-template #missingInputs>
    <p *ngIf="!downloadUrl">no URL</p>
    <p *ngIf="!contentType">no content type</p>
  </ng-template>
</ng-container>
