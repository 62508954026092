import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchClient } from 'algoliasearch';

@Component({
  selector: 'esui-esui-search-panel',
  templateUrl: './esui-search-panel.component.html',
  styleUrls: ['./esui-search-panel.component.scss'],
})
export class EsuiSearchPanelComponent {
  @Input() indexName?: string | null;
  @Input() searchClient?: SearchClient | null;

  @Input() clientId?: string | null;
  @Output() clientIdChange = new EventEmitter<string | null | undefined>();
}
