import { NotificationChannels } from './notification';

export class BaseMessageTemplate {
  public id? = '';
  public message = '';
  public name? = '';
  public subject? = '';
  public actionLink? = '';

  public usage?: {
    email: boolean;
    whatsapp: boolean;
    push?: boolean;
  } = {
    email: false,
    whatsapp: false,
    push: false,
  };
  public deepLink? = '';
  public notificationChannel?: NotificationChannels = undefined;
}

export class WhatsappTemplate extends BaseMessageTemplate {}

export class EmailTemplate extends BaseMessageTemplate {}

export class PushNotificationTemplate extends BaseMessageTemplate {
  public override deepLink? = '';
  public override notificationChannel?: NotificationChannels = undefined;
}
