import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import { DateString, isValidDateString } from '@expresssteuer/models';
import {
  AbstractValueAccessor,
  makeProvider,
} from '../abstract-value-accessor';

function dateStringValidation(
  control: AbstractControl<DateString | null>
): ValidationErrors | null {
  if (control.value == null) {
    return null;
  }
  if (isValidDateString(control.value)) {
    return null;
  }
  return { TypeError: true };
}

interface InternalDate {
  day: string;
  month: string;
  year: string;
}

type ExternalDate = DateString;

@Component({
  selector: 'esui-date-string-input',
  templateUrl: './date-string-input.component.html',
  styleUrls: ['./date-string-input.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useValue: dateStringValidation,
      multi: true,
    },
    ...makeProvider(DateStringInputComponent),
  ],
})
export class DateStringInputComponent extends AbstractValueAccessor<DateString | null> {
  protected data: DateString | null = null;

  @Input() viewMode = false;

  get empty(): boolean {
    return !this.value;
  }

  controlType = 'DateStringInputComponent';

  get showSubPlaceholders() {
    if (this.focused) {
      return true;
    }
    if (this.empty) {
      return false;
    }
    return true;
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elementRef.nativeElement.querySelector('input')?.focus();
    }
  }

  get date() {
    return externalToDate(this.value);
  }

  #proxyCache: InternalDate | null = null;

  set valueProxy(val: InternalDate | null) {
    this.#proxyCache = val;
    this.value = internalToExternal(val);
  }
  get valueProxy(): InternalDate {
    return (this.#proxyCache ?? {
      day: '',
      month: '',
      year: '',
    }) as InternalDate;
  }

  setPartial(change: Partial<{ day: string; month: string; year: string }>) {
    this.valueProxy = { ...this.valueProxy, ...change };
  }

  @Input()
  override get value(): ExternalDate | null {
    return super.value;
  }
  override set value(v: ExternalDate | null) {
    this.#proxyCache = externalToInternal(v);
    super.value = v;
  }

  override writeValue(value: ExternalDate) {
    super.writeValue(value);
    if (!this.value) {
      return (this.#proxyCache = null);
    }
    try {
      return (this.#proxyCache = externalToInternal(value));
    } catch (error) {
      console.log('date get proxy error');
    }
    return (this.#proxyCache = null);
  }
}

function externalToInternal(v: ExternalDate | null) {
  const splits = v?.split('-');
  return v && splits?.length === 3
    ? {
        day: splits[2],
        month: splits[1],
        year: splits[0],
      }
    : null;
}

function internalToExternal(v: InternalDate | null) {
  const year = ('0000' + (v?.year ?? '')).slice(-4);
  const month = ('00' + (v?.month ?? '')).slice(-2);
  const day = ('00' + (v?.day ?? '')).slice(-2);
  return v ? (`${year}-${month}-${day}` as DateString) : null;
}

function externalToDate(v: ExternalDate | null) {
  if (!v) {
    return null;
  }
  if (isValidDateString(v)) {
    return new Date(v);
  }
  return null;
}
