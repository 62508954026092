import { Component, Input } from '@angular/core';
import { SmartTask } from '@expresssteuer/models';

@Component({
  selector: 'esui-no-task-interaction',
  templateUrl: './esui-no-task-interaction.component.html',
  styleUrls: ['./esui-no-task-interaction.component.scss'],
})
export class EsuiNoTaskInteractionComponent {
  @Input() task?: SmartTask | null;
}
