import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { BinaryDocument } from '@expresssteuer/models';
import { distinctUntilChanged, map, shareReplay, tap } from 'rxjs/operators';

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;

type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[]
];

type Paths<T, D extends number = 4> = [D] extends [never]
  ? never
  : T extends object
  ? {
      [K in keyof T]-?: K extends string | number
        ? `${K}` | Join<K, Paths<T[K], Prev[D]>>
        : never;
    }[keyof T]
  : '';

@Injectable({
  providedIn: 'root',
})
export class DocumentsV2Service {
  constructor(private db: AngularFirestore) {}

  /**
   * Get a PropertyBindingHelper for a document in a collection.
   */
  public getDocument(docId: string, collectionPath) {
    console.log(`getDocument ${docId} from: ${collectionPath}`);

    const afDoc = this.db.collection(collectionPath).doc<BinaryDocument>(docId);
    return new DocumentBindingHelper(afDoc);
  }
}

/**
 * A handy way to read updates from a document in Firebase and update it.
 * @example
 * ```
 * // binding a document's `name` property to an input field:
 * <input [ngModel]='(docHelper.proxy.name?.value$|async)'
                            (ngModelChange)="docHelper.proxy.name?.update($event)">
 * ```
 */
export class DocumentBindingHelper {
  /**
   * @param afDoc the document to listen to
   */
  constructor(public afDoc: AngularFirestoreDocument<BinaryDocument>) {}

  /**
   * Watch for snapshot changes.
   */
  readonly snapshot$ = this.afDoc.snapshotChanges().pipe(
    tap((e) => {
      // console.log('snapshot$:', e)
    }),
    shareReplay()
  );

  /**
   * Watch for metadata changes.
   */
  readonly metadata$ = this.snapshot$.pipe(
    map((snapshot) => {
      return snapshot.payload.metadata;
    }),
    tap((e) => {
      // console.log('metadata$:', e)
    }),
    shareReplay()
  );

  /**
   * Watch for changes.
   */
  readonly value$ = this.snapshot$.pipe(
    map((snapshot) => {
      return snapshot.payload.data();
    }),
    shareReplay()
  );
  // readonly value$ = this.afDoc.valueChanges().pipe(
  //   shareReplay()
  // );

  /**
   * Trigger a change.
   */
  update(value) {
    return this.afDoc.update(value);
  }

  /**
   * Trigger or watch for changes for a specific property.
   * @param propPath the path to the property in the document
   */
  forProperty(propPath: Paths<BinaryDocument>) {
    return new PropertyBindingHelper(this, propPath);
  }

  /**
   * Convenient helper to access PropertyBindingHelpers for 1st level children of documents.
   */
  proxy: {
    readonly [key in keyof BinaryDocument]: PropertyBindingHelper;
  } = new Proxy({} as any, {
    get: (target, prop: keyof BinaryDocument, receiver) => {
      return this.forProperty(prop);
    },
  });
}

/**
 * A handy way to read updates from a property in a Firebase document and update it.
 */
export class PropertyBindingHelper {
  /**
   * @param documentBindingHelper of the document to listen from and write to
   * @param propPath the path to the property in the document
   */
  constructor(
    private documentBindingHelper: DocumentBindingHelper,
    private propPath: Paths<BinaryDocument>
  ) {}

  /**
   * Watch for changes.
   */
  readonly value$ = this.documentBindingHelper.value$.pipe(
    map((value) => {
      return this.propPath
        .split('.')
        .reduce((a, v) => (a ? a[v] : undefined), value);
    }),
    distinctUntilChanged(),
    shareReplay()
  );

  /**
   * Trigger a change.
   */
  update(value) {
    return this.documentBindingHelper.afDoc.update({
      [this.propPath]: value,
    });
  }
}
