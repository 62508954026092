import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GetBankInfo } from '@expresssteuer/iban-api-angular';
import { Bank } from '@expresssteuer/banking-models';

@Component({
  selector: 'app-control-iban-validator',
  templateUrl: './control-iban-validator.component.html',
  styleUrls: ['./control-iban-validator.component.scss'],
})
export class ControlIbanValidatorComponent implements OnInit {
  constructor(
    //private ibanservice: OpenibanService,
    private ibanService: GetBankInfo
  ) {}
  isValid = false;
  private bankvalue: Bank = Bank.getTemplate();

  @Output()
  bankChange = new EventEmitter<Bank>();

  set bank(val) {
    this.bankvalue = val;
    if (!val.valid) {
      this.validate();
    }
    this.bankChange.emit(this.bankvalue);
  }

  @Input()
  get bank() {
    return this.bankvalue;
  }

  ngOnInit(): void {
    this.isValid = this.bank.bankBIC !== '' && this.bank.bankName !== '';
  }

  public async validate(): Promise<void> {
    if (this.bank) {
      //const result = this.ibanService.validate(this.bank.IBAN);

      if (this.bank.IBAN.length > 5) {
        try {
          const bankResponse = await this.ibanService
            .call({
              iban: this.bank.IBAN,
            })
            .toPromise()
            .catch((e) => {
              console.error('failed to validate IBAN', e);
              return null;
            });

          if (bankResponse && bankResponse.isValid === true) {
            this.bank.valid = true;
            this.bank.validated = true;
            this.bank.IBAN = bankResponse.bank.IBAN;
            this.bank.bankBIC = '';
            this.bank.bankCity = bankResponse.bank.bankCity;
            this.bank.bankCode = bankResponse.bank.bankCode;
            this.bank.bankName = bankResponse.bank.bankName;
            this.bank.bankPLZ = bankResponse.bank.bankPLZ;
            this.isValid = true;
            this.bankChange.emit(this.bank);
            return;
          } else {
            this.isValid = false;
            return;
          }
        } catch (err) {
          this.isValid = false;
          return;
        }
      } else {
        this.isValid = false;
        return;
      }
    } else {
      this.isValid = false;
      return;
    }
  }
}
