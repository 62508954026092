<esui-store-state-indicator
  [isSaving]="status?.isSaving ?? false"
  [isLoading]="status?.isLoading ?? false"
  [hasChanges]="status?.hasChanges ?? false"
  [hasErrors]="status?.hasErrors ?? false"
></esui-store-state-indicator>

<ng-content></ng-content>

<button mat-icon-button [disabled]="true" (click)="saveClick.next()">
  <mat-icon>save</mat-icon>
</button>
