import { Component } from '@angular/core';
import { buildMeta } from '../../../../build-meta/build-meta';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  buildMeta = buildMeta;
}
