import { Timestamp } from '../helpers/timestamp';

export class Version {
  public major: number = 0;
  public minor: number = 0;
  public patch: number = 0;
}

/**
 * Information from migration and last status
 */
export class FirestoreMigration {
  /**
   * Version nbr from @expresssteuer/models-global
   */
  public modelsVersion?: Version = { major: 0, minor: 0, patch: 0 };
  /**
   * Last migration run at
   */
  public updated?: Timestamp;

  /**
   * Defines if the model is valid
   */
  public isValid: boolean = true;
}
