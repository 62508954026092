import { CommonModule, DatePipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  EsuiIbanValidatorModule,
  EsuiTaxIdValidatorModule,
} from '@expresssteuer/angular-validators';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsStoreStateIndicatorModule } from '../es-store-state-indicator/es-store-state-indicator.module';
import { EsuiDocumentViewerModule } from '../esui-document-viewer/esui-document-viewer.module';
import { DateInputModule } from '../form-inputs/date-input/date-input.module';
import { NumberInputModule } from '../form-inputs/number-input/number-input.module';
import { TextInputModule } from '../form-inputs/text-input/text-input.module';
import { DocumentTypeSelectComponent } from './components/document-type-select/document-type-select.component';
import { ParsedFieldsInputComponent } from './components/parsed-fields-input/parsed-fields-input.component';
import { DocumentClassifierComponent } from './document-classifier.component';

@NgModule({
  declarations: [
    DocumentClassifierComponent,
    DocumentTypeSelectComponent,
    ParsedFieldsInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    EsuiDocumentViewerModule,
    MatTooltipModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatListModule,
    MatSlideToggleModule,
    EsCardKeyModule,
    DateInputModule,
    TextInputModule,
    NumberInputModule,
    MatInputModule,
    EsStoreStateIndicatorModule,
    AngularFireStorageModule,
    EsuiIbanValidatorModule,
    EsuiTaxIdValidatorModule,
  ],
  exports: [
    DocumentClassifierComponent,
    DocumentTypeSelectComponent,
    ParsedFieldsInputComponent,
  ],
  providers: [PercentPipe, DatePipe],
})
export class EsDocumentClassifierModule {}
