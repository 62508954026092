export interface VIbanDetails {
  vibans: VIban[];
}

export interface VIban {
  /**
   * Requested virtual IBAN
   * @example DE43100700001212000014
   */
  vIban: string;

  /**
   * IBAN of the bank account
   * @example DE43100700001212000014
   */
  iban?: string;

  /**
   * Local account number
   * @example 12345678
   */
  account?: string;

  /**
   * - FREE: Virtual IBAN not in use, can be allocated to a customer
   * - ALLOCATED: Virtual IBAN allocated to a customer, but not yet activated
   * - REVIEW: Virtual IBAN initially free, but transactions were received. It must be activated
   * - ACTIVATED: Virtual IBAN fully activated, can receive transactions
   *
   * @example ACTIVATED
   */
  status: 'FREE' | 'ALLOCATED' | 'REVIEW' | 'ACTIVATED';

  /**
   * Reference assigned to a virtual IBAN and used to link it with a customer
   * @example ID35635637
   */
  customerReference?: string;
}
