import { Timestamp } from '../helpers/timestamp';

export enum GOVERNENTALAIDTYPE {
  'UNEMPLOYMENT' = 'unemployment', // Arbeitslosengeld
  'SICKNESS' = 'sickness', // Krankengeld
  'LIFEANNUITY' = 'lifeAnnuity', // Leibrente
  'PARENTAL' = 'parental', // Elterngeld
  'MOTHER' = 'mother', // Muttergeld
  'ALIMONY' = 'alimony', // Unterhalt
  'INSOLVENCY' = 'insolvency', // Insolvenzgeld
  'SHORTTIMEWORK' = 'shorttimework', // Kurzarbeitsgeld
  'COALAID' = 'coalaid', // Anpassungsgeld nach dem Kohleausstiegsgesetz
}

/**
 * Object Describes anGovermental aid
 * * valid expense types
 *  - unemployment
 *  - sickness
 *  - lifeAnnuity
 *  - parental
 *  - mother
 *  - alimony
 *  - insolvency
 *  - shorttimework
 */
export interface IGovermentalAid {
  category: GOVERNENTALAIDTYPE;
  description: string;
  value: number | null;
  startDate: Timestamp | null;
  endDate: Timestamp | null;
  active: boolean;
}
