import { VaSt_FSA } from './vast-fsa';
import { VaSt_GDB } from './vast-gdb';
import { VaSt_KRV } from './vast-krv';
import { VaSt_LErsL } from './vast-lersl';
import { VaSt_LStB } from './vast-lstb';
import { VaSt_Pers1 } from './vast-pers1';
import { VaSt_Pers2 } from './vast-pers2';
import { VaSt_RBM } from './vast-rbm';
import { VaSt_RIE } from './vast-rie';
import { VaSt_RUE } from './vast-rue';
import { VaSt_VWL } from './vast-vwl';
import { VaSt_ZUS } from './vast-zus';

import { VastFsaSchema } from './vast-fsa.schema';
import { VastGdbSchema } from './vast-gdb.schema';
import { VastKrvSchema } from './vast-krv.schema';
import { VastLerslSchema } from './vast-lersl.schema';
import { VastLstbSchema } from './vast-lstb.schema';
import { VastPers1Schema } from './vast-pers1.schema';
import { VastPers2Schema } from './vast-pers2.schema';
import { VastRbmSchema } from './vast-rbm.schema';
import { VastRieSchema } from './vast-rie.schema';
import { VastRueSchema } from './vast-rue.schema';
import { VastVwlSchema } from './vast-vwl.schema';
import { VastZusSchema } from './vast-zus.schema';

export enum VastTypes {
  vastFsa = 'VaSt_FSA',
  vastGdb = 'VaSt_GDB',
  vastKrv = 'VaSt_KRV',
  vastLersL = 'VaSt_LErsL',
  vastLstB = 'VaSt_LStB',
  vastPers1 = 'VaSt_Pers1',
  vastPers2 = 'VaSt_Pers2',
  vastRbm = 'VaSt_RBM',
  vastRie = 'VaSt_RIE',
  vastRue = 'VaSt_RUE',
  vastVwl = 'VaSt_VWL',
  vastZus = 'VaSt_ZUS',
}
export type VastDocumentResponse = Partial<{
  [VastTypes.vastFsa]: VaSt_FSA[];
  [VastTypes.vastGdb]: VaSt_GDB[];
  [VastTypes.vastKrv]: VaSt_KRV[];
  [VastTypes.vastLersL]: VaSt_LErsL[];
  [VastTypes.vastLstB]: VaSt_LStB[];
  [VastTypes.vastPers1]: VaSt_Pers1[];
  [VastTypes.vastPers2]: VaSt_Pers2[];
  [VastTypes.vastRbm]: VaSt_RBM[];
  [VastTypes.vastRie]: VaSt_RIE[];
  [VastTypes.vastRue]: VaSt_RUE[];
  [VastTypes.vastVwl]: VaSt_VWL[];
  [VastTypes.vastZus]: VaSt_ZUS[];
}>;

export const VAST_TYPE_MAP: Record<VastTypes, string> = {
  [VastTypes.vastFsa]: 'Freistellungsauftrag',
  [VastTypes.vastGdb]: 'Grad der Behinderung',
  [VastTypes.vastKrv]:
    'Bescheinigung zur Basiskrankenversicherung und gesetzlichen Pflegeversicherung',
  [VastTypes.vastLersL]: 'Lohnersatzleistungen',
  [VastTypes.vastLstB]: 'Lohnsteuerbescheinigung',
  [VastTypes.vastPers1]: 'Stammdaten',
  [VastTypes.vastPers2]: 'Religionszugehörigkeit',
  [VastTypes.vastRbm]: 'Rentenbezugsmitteilung',
  [VastTypes.vastRie]: 'Riester-Rente',
  [VastTypes.vastRue]: 'Basisversorgung (Rürup-Rente)',
  [VastTypes.vastVwl]: 'Vermögensbildungsbescheinigung',
  [VastTypes.vastZus]: 'Zuschüsse',
};

export const VastSchemas: Record<VastTypes, SchemaProperty> = {
  [VastTypes.vastFsa]: VastFsaSchema,
  [VastTypes.vastGdb]: VastGdbSchema,
  [VastTypes.vastKrv]: VastKrvSchema,
  [VastTypes.vastLersL]: VastLerslSchema,
  [VastTypes.vastLstB]: VastLstbSchema,
  [VastTypes.vastPers1]: VastPers1Schema,
  [VastTypes.vastPers2]: VastPers2Schema,
  [VastTypes.vastRbm]: VastRbmSchema,
  [VastTypes.vastRie]: VastRieSchema,
  [VastTypes.vastRue]: VastRueSchema,
  [VastTypes.vastVwl]: VastVwlSchema,
  [VastTypes.vastZus]: VastZusSchema,
};

export interface SchemaProperty {
  description?: string;
  title?: string;
  $schema?: string;
  type: 'object' | 'array' | 'string' | 'number' | 'integer' | 'boolean';
  properties?: Record<string, SchemaProperty>;
  items?: SchemaProperty;
  enum?: string[];
  required?: string[];
  stringifier?: string;
}
