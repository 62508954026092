<mat-toolbar>
  <mat-icon>person</mat-icon>
  <span>Taxcases</span>
  <span class="spacer"></span>
  <!-- <button mat-icon-button aria-label="save" (click)="saveClick.next()">
    <mat-icon>save</mat-icon>
  </button> -->
</mat-toolbar>

<mat-selection-list
  [multiple]="false"
  [ngModel]="[selectedTaxcase]"
  (ngModelChange)="selectedTaxcaseChange.next($event?.[0])"
>
  <ng-container *ngFor="let taxcase of taxcases; trackBy: trackById">
    <mat-list-option [value]="taxcase">
      {{ taxcase.client.displayName }} {{ taxcase.year }}
      {{ taxcase.metadata.status.current }}
    </mat-list-option>
    <mat-divider></mat-divider>
  </ng-container>
</mat-selection-list>
