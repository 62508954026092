import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ais-documents-pagination',
  templateUrl: './ais-documents-pagination.component.html',
  styleUrls: ['./ais-documents-pagination.component.scss'],
})
export class AisDocumentsPaginationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
