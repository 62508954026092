import { Component, Input } from '@angular/core';
import { DIRECTION, Message } from '@expresssteuer/models';

@Component({
  selector: 'esui-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
  DIRECTION = DIRECTION;
  @Input() message?: Message | null;
}
