import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { HotkeyModule as Angular2HotkeyModule } from 'angular2-hotkeys';
import { HotkeysCheatsheetComponent } from './components/hotkeys-cheatsheet/hotkeys-cheatsheet.component';
import { HotkeysQuery } from './hotkeys.query';
import { HotkeysService } from './hotkeys.service';
import { HotkeysStore } from './hotkeys.store';

/**
 * see `Angular2HotkeyModule` for reference
 */
export interface EsHotkeyOptions {
  /**
   * Disable the cheat sheet popover dialog? Default: false
   */
  disableCheatSheet?: boolean;
  /**
   * Key combination to trigger the cheat sheet. Default: '?'
   */
  cheatSheetHotkey?: string;
  /**
   * Use also ESC for closing the cheat sheet. Default: false
   */
  cheatSheetCloseEsc?: boolean;
  /**
   * Description for the ESC key for closing the cheat sheet (if enabed). Default: 'Hide this help menu'
   */
  cheatSheetCloseEscDescription?: string;
  /**
   * Description for the cheat sheet hot key in the cheat sheet. Default: 'Show / hide this help menu'
   */
  cheatSheetDescription?: string;
}

@NgModule({
  declarations: [HotkeysCheatsheetComponent],
  imports: [
    CommonModule,
    Angular2HotkeyModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    MatAutocompleteModule,
  ],
  exports: [HotkeysCheatsheetComponent],
})
export class EsHotkeysModule {
  static forRoot(options: EsHotkeyOptions = {}) {
    return {
      ngModule: EsHotkeysModule,
      providers: [
        HotkeysService,
        HotkeysQuery,
        HotkeysStore,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(Angular2HotkeyModule.forRoot(options).providers as any),
      ],
    };
  }
}
