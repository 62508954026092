import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IAdditionalExpense,
  JobGroup,
  TaxcaseComment,
} from '@expresssteuer/models';

@Component({
  selector: 'esui-jobgroup-optimize',
  templateUrl: './esui-jobgroup-optimize.component.html',
  styleUrls: ['./esui-jobgroup-optimize.component.scss'],
})
export class EsuiJobgroupOptimizeComponent {
  @Input() clientAdditionalExpenses?: IAdditionalExpense[] | null;
  @Input() clientJobGroups?: JobGroup[] | null;
  @Input() clientName?: string | null;
  @Input() clientCommutingCost?: number | null;
  @Input() clientHomeofficeCost?: number | null;

  @Input() hasPartner?: boolean;
  @Input() partnerAdditionalExpenses?: IAdditionalExpense[] | null;
  @Input() partnerJobGroups?: JobGroup[] | null;
  @Input() partnerName?: string | null;
  @Input() partnerCommutingCost?: number | null;
  @Input() partnerHomeofficeCost?: number | null;

  @Input() taxcaseComments?: TaxcaseComment[] | null;

  @Input() hasOnlineChangedAfterLocalChanged?: boolean | null;
  @Input() isSaving?: boolean | null;
  @Input() isLoading?: boolean | null;
  @Input() hasChanges?: boolean | null;
  @Input() hasErrors?: boolean | null;

  @Output() addExpenseClicked = new EventEmitter<IAdditionalExpense>();
  @Output() saveOptimizationClicked = new EventEmitter<void>();
  @Output() finishOptimizationClicked = new EventEmitter<void>();
}
