import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsStoreStateIndicatorModule } from '../es-store-state-indicator/es-store-state-indicator.module';
import { AddressInputModule } from '../form-inputs/address-input/address-input.module';
import { FixFinanceOfficeIdComponent } from './fix-finance-office-id.component';

@NgModule({
  declarations: [FixFinanceOfficeIdComponent],
  imports: [
    CommonModule,
    AddressInputModule,
    FormsModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    EsStoreStateIndicatorModule,
    EsCardKeyModule,
    AddressInputModule,
    MatInputModule,
    MatTooltipModule,
    ClipboardModule,
  ],
  exports: [FixFinanceOfficeIdComponent],
})
export class EsFixFinanceOfficeIdModule {}
