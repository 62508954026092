import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { EsuiDebugService } from './esui-debug.service';

export const LOGGER_META = new InjectionToken<LoggerMeta>('LOGGER_META');
export const LOGGER_LEVEL = new InjectionToken<LoggingLevel>('LOGGER_LEVEL');
export const LOGGER_CONFIG = new InjectionToken<LoggerConfig>('LOGGER_CONFIG');
export const SENTRY = new InjectionToken<SentryLike>('SENTRY');

export interface LoggerMeta {
  scopeLabel?: string;
}

export interface LoggerConfig {
  showScopeLabels?: boolean;
  showOwnerNames?: boolean;
  showTimeStamps?: boolean;
  sentryLoggingLevel?: LoggingLevel;
}

interface LoggerEvent {
  message: unknown;
  scopeLabel?: string;
  ownerNames?: string;
  timestamp?: string;
}

export enum LoggingLevel {
  Debug = 'debug',
  Info = 'info',
  Warnings = 'warnings',
  Errors = 'errors',
  None = 'none',
}

export type SentryLike = {
  captureMessage: (
    message: string,
    options: {
      level: SentryLikeLevel;
      tags: Record<string, unknown>;
      extra?: Record<string, unknown>;
    }
  ) => void;
  addBreadcrumb: (options: {
    message: string;
    level: SentryLikeLevel;
    category: 'console';
    data?: Record<string, unknown>;
  }) => void;
};

type SentryLikeLevel = 'error' | 'warning' | 'info' | 'debug';

export function providersForDedicatedLogger(loggerMeta: LoggerMeta) {
  return [
    {
      provide: LOGGER_META,
      useValue: loggerMeta,
    },
    EsuiLoggerService,
  ];
}

/**
 * @example ```
 *  \@Component({
 *     template: '',
 *     providers: [...providersForDedicatedLogger({scopeLabel:MyComponent.name})],
 *   })
 *   export class MyComponent {
 *     constructor(private logger: EsuiLoggerService) {
 *       logger.registerOwner(this);
 *     }
 *   }
 * ```
 */
@Injectable({
  providedIn: 'root',
})
export class EsuiLoggerService {
  private config: LoggerConfig = {
    showScopeLabels: true,
    showOwnerNames: true,
    showTimeStamps: false,
    sentryLoggingLevel: LoggingLevel.None,
  };

  private meta: LoggerMeta = {};

  private get level() {
    return this.debugService?.currentLevel || LoggingLevel.None;
  }

  private owners: Set<Record<keyof unknown, unknown>> = new Set();

  #colorCache = new Map<string, string>();
  private getColorFor(input: string): string {
    if (this.#colorCache.has(input)) {
      return this.#colorCache.get(input) as string;
    }
    const color = this.intToRGB(this.hashCode(input));
    this.#colorCache.set(input, color);
    return color;
  }

  private get ownerNames() {
    return [...this.owners]
      .map((owner) => Object.getPrototypeOf(owner)?.constructor?.name)
      .filter((e) => !!e);
  }

  private createLoggerEvent(
    userMessage: unknown = '',
    level = LoggingLevel.Debug,
    ...optionalParams: unknown[]
  ) {
    const loggerEvent: LoggerEvent = {
      message: userMessage,
    };

    if (this.config.showScopeLabels && !!this.meta.scopeLabel) {
      loggerEvent.scopeLabel = this.meta.scopeLabel;
    }
    const ownerNames = this.ownerNames;
    if (this.config.showOwnerNames && ownerNames.length > 0) {
      const ownerNamesString = ownerNames.join(',');
      loggerEvent.ownerNames = ownerNamesString;
    }

    if (this.config.showTimeStamps) {
      const date = new Date();
      loggerEvent.timestamp =
        date.toLocaleString('de-DE', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }) + `.${date.getMilliseconds()}`;
    }

    const shouldLogToConsole = this.shouldLog(level, this.level);
    const shouldLogToSentry = this.shouldLog(
      level,
      this.config.sentryLoggingLevel ?? LoggingLevel.None
    );
    if (shouldLogToConsole) {
      this.printConsoleLog(loggerEvent, level, ...optionalParams);
    }
    if (this.sentry && shouldLogToSentry) {
      this.captureSentryMessage(loggerEvent, level, ...optionalParams);
    }
    if (this.sentry && !shouldLogToConsole && !shouldLogToSentry) {
      this.addSentryBreadcrump(loggerEvent, level, ...optionalParams);
    }
  }

  private printConsoleLog(
    loggerEvent: LoggerEvent,
    level: LoggingLevel,
    ...optionalParams: unknown[]
  ) {
    const output = [];

    const messages = [];
    const styles = [];

    if (loggerEvent.scopeLabel) {
      messages.push(`%c${loggerEvent.scopeLabel}`);
      styles.push(
        this.getChipStyleWithColor(this.getColorFor(loggerEvent.scopeLabel))
      );
    }
    if (loggerEvent.ownerNames) {
      messages.push(`%c${loggerEvent.ownerNames}`);
      styles.push(
        this.getChipStyleWithColor(this.getColorFor(loggerEvent.ownerNames))
      );
    }

    if (loggerEvent.timestamp) {
      messages.push(`%c${loggerEvent.timestamp}`);
      styles.push(this.getPlainStyle());
    }

    if (typeof loggerEvent.message === 'string') {
      // is userMessage is a string add it to our message
      messages.push(`%c${loggerEvent.message}`);
      styles.push(this.getPlainStyle());
    }

    output.push(messages.join(' '));
    output.push(...styles);

    if (typeof loggerEvent.message !== 'string') {
      // if userMessage is NOT a string add as a param
      output.push(loggerEvent.message);
    }

    output.push(...optionalParams);

    switch (level) {
      case LoggingLevel.Errors:
        // eslint-disable-next-line no-console
        console.error(...output);
        break;
      case LoggingLevel.Warnings:
        // eslint-disable-next-line no-console
        console.warn(...output);
        break;
      case LoggingLevel.Info:
        // eslint-disable-next-line no-console
        // eslint-disable-next-line no-restricted-syntax
        console.info(...output);
        break;
      default:
        // eslint-disable-next-line no-console
        // eslint-disable-next-line no-restricted-syntax
        console.debug(...output);
    }
  }

  private shouldLog(checkLevel: LoggingLevel, configLevel: LoggingLevel) {
    if (configLevel === LoggingLevel.None) {
      return false;
    } else if (configLevel === LoggingLevel.Errors) {
      return checkLevel === LoggingLevel.Errors;
    } else if (configLevel === LoggingLevel.Warnings) {
      return (
        checkLevel === LoggingLevel.Errors ||
        checkLevel === LoggingLevel.Warnings
      );
    } else if (configLevel === LoggingLevel.Info) {
      return (
        checkLevel === LoggingLevel.Errors ||
        checkLevel === LoggingLevel.Warnings ||
        checkLevel === LoggingLevel.Info
      );
    } else {
      return true;
    }
  }

  private hashCode(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      // eslint-disable-next-line no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  private intToRGB(i: number) {
    const max = parseInt('FFFFFF', 16);
    const color = (Math.abs(i) % max).toString(16);
    return color;
  }

  private getChipStyleWithColor(color: string) {
    return `color: #ffffff; background-color:#${color}; border-radius:2ex; padding: 0 0.5ex; margin-right:1em`;
  }

  private getPlainStyle() {
    return ``;
  }

  private captureSentryMessage(
    loggerEvent: LoggerEvent,
    level: LoggingLevel,
    ...optionalParams: unknown[]
  ) {
    const messageLevel = this.convertToSentryLoggingLevel(level);
    if (messageLevel === null) {
      return;
    }

    let sentryMessage = '';
    if (typeof loggerEvent.message === 'string') {
      sentryMessage = `${sentryMessage} ${loggerEvent.message}`;
    } else {
      sentryMessage = `${sentryMessage} unknown`;
      optionalParams.push(loggerEvent.message);
    }

    this.sentry?.captureMessage(sentryMessage, {
      level: messageLevel,
      tags: {
        owner: loggerEvent.ownerNames,
        scope: loggerEvent.scopeLabel,
      },
      extra: { ...optionalParams },
    });
  }

  private addSentryBreadcrump(
    loggerEvent: LoggerEvent,
    level: LoggingLevel,
    ...optionalParams: unknown[]
  ) {
    const messageLevel = this.convertToSentryLoggingLevel(level);
    if (messageLevel === null) {
      return;
    }

    this.sentry?.addBreadcrumb({
      message: `[${loggerEvent.ownerNames}] ${loggerEvent.message}`,
      level: messageLevel,
      category: 'console',
      data: { ...optionalParams },
    });
  }

  private convertToSentryLoggingLevel(
    level: LoggingLevel
  ): SentryLikeLevel | null {
    switch (level) {
      case LoggingLevel.Errors:
        return 'error';
      case LoggingLevel.Warnings:
        return 'warning';
      case LoggingLevel.Info:
        return 'info';
      case LoggingLevel.Debug:
        return 'debug';
      default:
        return null;
    }
  }

  constructor(
    @Optional()
    @Inject(LOGGER_META)
    loggerMeta: LoggerMeta,
    @Optional()
    @Inject(LOGGER_CONFIG)
    loggerConfig: LoggerConfig,
    @Optional()
    private debugService?: EsuiDebugService,
    @Optional()
    @Inject(SENTRY)
    private sentry?: SentryLike | null
  ) {
    if (loggerMeta) {
      this.meta = { ...this.meta, ...loggerMeta };
    }

    if (loggerConfig) {
      this.config = { ...this.config, ...loggerConfig };
    }
  }

  registerOwner(obj: Record<keyof unknown, unknown>) {
    this.owners.add(obj);
  }

  getNewInstance(
    withOwner?: Record<keyof unknown, unknown>
  ): EsuiLoggerService {
    const newService = new EsuiLoggerService(
      this.meta,
      this.config,
      this.debugService,
      this.sentry
    );
    if (withOwner) {
      newService.registerOwner(withOwner);
    }
    return newService;
  }

  error(message: unknown, ...optionalParams: unknown[]) {
    this.createLoggerEvent(message, LoggingLevel.Errors, ...optionalParams);
  }

  warn(message: unknown, ...optionalParams: unknown[]) {
    this.createLoggerEvent(message, LoggingLevel.Warnings, ...optionalParams);
  }

  info(message: unknown, ...optionalParams: unknown[]) {
    this.createLoggerEvent(message, LoggingLevel.Info, ...optionalParams);
  }

  debug(message: unknown, ...optionalParams: unknown[]) {
    this.createLoggerEvent(message, LoggingLevel.Debug, ...optionalParams);
  }
}
