import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BinaryDocument, DOCUMENTTYPE, SmartTask } from '@expresssteuer/models';
import { SearchClient } from 'algoliasearch';

@Component({
  selector: 'esui-document-client-assigner',
  templateUrl: './esui-document-client-assigner.component.html',
  styleUrls: ['./esui-document-client-assigner.component.scss'],
})
export class EsuiDocumentClientAssignerComponent {
  @Input() task?: SmartTask | null;
  @Input() document?: BinaryDocument<
    DOCUMENTTYPE,
    Record<string | number, unknown> | undefined
  > | null;
  @Input() downloadUrl?: string | null;
  @Input() hasOnlineChangedAfterLocalChanged?: boolean | null;
  @Input() isSaving?: boolean | null;
  @Input() isLoading?: boolean | null;
  @Input() hasChanges?: boolean | null;
  @Input() hasErrors?: boolean | null;

  @Input() indexName?: string | null;
  @Input() searchClient?: SearchClient | null;

  @Input() clientId?: string | null;
  @Output() clientIdChange = new EventEmitter<string | null | undefined>();
  @Output() saveClick = new EventEmitter<void>();
  @Output() discardClick = new EventEmitter<void>();
}
