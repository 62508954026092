import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EsuiValidFinanceDepartmentIdValidatorDirective } from './esui-finance-department-id-validator.directive';

@NgModule({
  declarations: [EsuiValidFinanceDepartmentIdValidatorDirective],
  imports: [CommonModule],
  exports: [EsuiValidFinanceDepartmentIdValidatorDirective],
})
export class EsuiFinanceDepartmentIdValidatorModule {}
