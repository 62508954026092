import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EsAnchorMenuModule } from '../es-anchor-menu/es-anchor-menu.module';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsStatsForNerdsModule } from '../es-stats-for-nerds/es-stats-for-nerds.module';
import { EsuiSectionColumnScaffoldModule } from '../esui-section-column-scaffold/esui-section-column-scaffold.module';
import { TaskCardComponent } from './components/task-card/task-card.component';
import { TaskListItemComponent } from './components/task-list-item/task-list-item.component';
import { TaskListComponent } from './components/task-list/task-list.component';

@NgModule({
  declarations: [TaskListComponent, TaskListItemComponent, TaskCardComponent],
  imports: [
    CommonModule,
    MatDividerModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    EsCardKeyModule,
    EsStatsForNerdsModule,
    MatTooltipModule,
    MatChipsModule,
    EsAnchorMenuModule,
    EsuiSectionColumnScaffoldModule,
  ],
  exports: [TaskListComponent, TaskListItemComponent, TaskCardComponent],
})
export class EsTasksModule {}
