import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EsStoreStateIndicatorModule } from '../es-store-state-indicator/es-store-state-indicator.module';
import { EsuiSectionColumnScaffoldModule } from '../esui-section-column-scaffold/esui-section-column-scaffold.module';
import { DateStringInputModule } from '../form-inputs/date-string-input/date-string-input.module';
import { EsuiPartnerComponent } from './esui-partner.component';

@NgModule({
  declarations: [EsuiPartnerComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    EsStoreStateIndicatorModule,
    MatTooltipModule,
    EsuiSectionColumnScaffoldModule,
    DateStringInputModule,
    MatButtonModule,
  ],
  exports: [EsuiPartnerComponent],
})
export class EsuiPartnerModule {}
