<esui-section-column-scaffold
  [header]="{
    title: 'taxcases'
  }"
>
  <ng-container content>
    <mat-selection-list
      [multiple]="false"
      [(ngModel)]="ngModel"
      (ngModelChange)="ngModelChange.next($event)"
      [ngClass]="{ 'horizontal-list': horizontal }"
    >
      <ng-container *ngFor="let taxcase of taxcases; trackBy: trackById">
        <mat-list-option [value]="taxcase" @animateDestroy>
          <span mat-list-icon class="year-icon">
            {{ taxcase.year }}
          </span>
          <span
            mat-list-icon
            class="status-indicator"
            [ngClass]="statusMap[taxcase.metadata.status.current]"
            [matTooltip]="taxcase.metadata.status.current"
          >
          </span>

          <span matLine>{{ taxcase.metadata.status.substatus ?? '-' }}</span>
          <pre matLine>{{ taxcase.id }}</pre>
        </mat-list-option>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-selection-list>
  </ng-container>
</esui-section-column-scaffold>
