<div *ngIf="document" class="text-center">
  <a
    target="_blank"
    [routerLink]="['/', 'documents', 'documentcenter', document.id]"
    *ngIf="isImage()"
  >
    <img
      [src]="getDownloadPath() | getDownloadURL"
      [ngClass]="{
        'border border-success': getVerified(),
        '': !getVerified()
      }"
      class="cursor-pointer"
    />
  </a>
  <a
    target="_blank"
    [routerLink]="['/', 'documents', 'documentcenter', document.id]"
    *ngIf="!isImage()"
  >
    <img
      src="https://via.placeholder.com/300/?text={{ getType() }}"
      [ngClass]="{
        'border border-success': getVerified(),
        '': !getVerified()
      }"
      class="cursor-pointer"
    />
  </a>
</div>
