import { SchemaProperty } from './vast';

export const VastRbmSchema: SchemaProperty = {
  title: 'VaSt_RBM-202201.xsd',
  $schema: 'http://json-schema.org/schema#',

  type: 'object',
  properties: {
    VaSt_RBM: {
      type: 'object',
      description: 'Rentenbezugsmitteilung',
      properties: {
        Eingangsdatum: {
          description:
            'Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung',
          type: 'string',
        },
        LeistungsEmpfaenger: {
          type: 'object',
          properties: {
            IdNr: {
              description: 'Identifikationsnummer',
              type: 'string',
            },
            Titel: {
              description: 'Titel',
              type: 'string',
            },
            Vorname: {
              description: 'Vorname',
              type: 'string',
            },
            NamensVorsatz: {
              description: 'Namensvorsatz',
              type: 'string',
            },
            Name: {
              description: 'Name',
              type: 'string',
            },
            NamensZusatz: {
              description: 'Namenszusatz',
              type: 'string',
            },
            Blockname: {
              description: 'Name',
              type: 'string',
            },
          },
        },
        Mitteilung: {
          type: 'object',
          properties: {
            Zuflussjahr: {
              description: 'Zuflussjahr',
              type: 'string',
            },
            Ordnungsmerkmal: {
              description: 'Vom Datenübermittler verwendetes Ordnungsmerkmal',
              type: 'string',
            },
            MitteilungsPflichtigerName: {
              description: 'Renten-/Leistungserbringer',
              type: 'string',
            },
            VertragsNr: {
              description: 'Vertragsnummer',
              type: 'string',
            },
            Auszahlungsform: {
              description: 'Auszahlungsform',
              type: 'string',
              enum: ['01', '02', '03', '04', '05'],
            },
            Leistung: {
              type: 'object',
              properties: {
                Anpassungsbetrag: {
                  description: 'Rentenanpassungsbetrag',
                  type: 'number',
                  stringifier: 'currency',
                },
                BetragEinbehalt: {
                  description:
                    'Steuerabzugsbetrag auf Grund einer Anordnung nach § 50a Absatz 7 EStG',
                  type: 'number',
                  stringifier: 'currency',
                },
                Waehrung: {
                  description: 'Währung',
                  type: 'string',
                },
                Beginn: {
                  description: 'Beginn der Rente/Leistung',
                  type: 'string',
                },
                Ende: {
                  description: 'Ende der Rente/Leistung',
                  type: 'string',
                },
                Teilleistung: {
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {
                      Grundlage: {
                        description: 'Rechtsgrundlage',
                        type: 'string',
                        enum: [
                          '01',
                          '02',
                          '03',
                          '04',
                          '04a',
                          '05',
                          '06',
                          '07',
                          '12a',
                          '08',
                          '12b',
                          '10',
                          '11',
                          '12c',
                          '12d',
                          '14',
                          '15',
                          '18',
                          '16',
                          '17',
                        ],
                      },
                      Betrag: {
                        description: 'Renten-/Leistungsbetrag',
                        type: 'number',
                        stringifier: 'currency',
                      },
                      AbfindungKleinbetragsrente: {
                        description: 'Abfindung einer Kleinbetragsrente',
                        type: 'string',
                        enum: ['true'],
                      },
                      Rentenart: {
                        description: 'Rentenart',
                        type: 'string',
                        enum: ['01', '02', '03', '04', '09', '99'],
                      },
                      EnthalteneNachzahlung: {
                        description:
                          'Im Renten-/Leistungsbetrag enthaltene Nachzahlungen für mehrere vorangegangene Kalenderjahre',
                        type: 'number',
                        stringifier: 'currency',
                      },
                      Geburtsdatum: {
                        description:
                          'Geburtsdatum des Erblassers bei Garantiezeitrenten',
                        type: 'string',
                      },
                      Beginn: {
                        description: 'Beginn der Rente des Erblassers',
                        type: 'string',
                      },
                      VersFB: {
                        type: 'object',
                        properties: {
                          BMG: {
                            description:
                              'Bemessungsgrundlage für den Versorgungsfreibetrag',
                            type: 'number',
                            stringifier: 'currency',
                          },
                          Jahr: {
                            description:
                              'maßgebendes Kalenderjahr des Versorgungsbeginns',
                            type: 'string',
                          },
                          Beginn: {
                            description:
                              'bei unterjähriger Zahlung: Erster Monat, für den Versorgungsbezüge gezahlt wurden',
                            type: 'string',
                          },
                          Ende: {
                            description:
                              'bei unterjähriger Zahlung: Letzter Monat, für den Versorgungsbezüge gezahlt wurden',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            Vorzeitraum: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  Beginn: {
                    description: 'Beginn einer vorhergehenden Rente/Leistung',
                    type: 'string',
                  },
                  Ende: {
                    description: 'Ende einer vorhergehenden Rente/Leistung',
                    type: 'string',
                  },
                },
              },
            },
            Krankenversicherung: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  Beitragsart: {
                    description: 'Beitragsart zur Kranken-/Pflegeversicherung',
                    type: 'string',
                    enum: ['01', '03', '04', '06', '02', '05'],
                  },
                  Beginn: {
                    description:
                      'erster Monat des Zeitraums, für den die Beiträge/Zuschüsse zur Kranken-/Pflegeversicherung geleistet/erstattet wurden',
                    type: 'string',
                  },
                  Ende: {
                    description:
                      'letzter Monat des Zeitraums, für den die Beiträge/Zuschüsse zur Kranken-/Pflegeversicherung geleistet/erstattet wurden',
                    type: 'string',
                  },
                  Jahr: {
                    description:
                      'Kalenderjahr, für das Beiträge/Zuschüsse zur Kranken-/Pflegeversicherung geleistet/erstattet wurden',
                    type: 'string',
                  },
                  Betrag: {
                    description:
                      'Höhe der geleisteten/erstatteten Beiträge/Zuschüsse zur Kranken-/Pflegeversicherung',
                    type: 'number',
                    stringifier: 'currency',
                  },
                },
              },
            },
          },
        },
        version: {
          type: 'string',
          enum: ['202201'],
        },
      },
      required: ['version'],
    },
  },
  required: ['VaSt_RBM'],
};
