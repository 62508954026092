import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { EsuiSelectMultiYearChipsComponent } from './esui-select-multi-year-chips.component';

@NgModule({
  declarations: [EsuiSelectMultiYearChipsComponent],
  imports: [CommonModule, MatChipsModule, MatSelectModule, MatIconModule],
  exports: [EsuiSelectMultiYearChipsComponent],
})
export class EsuiSelectMultiYearChipsModule {}
