import { Timestamp } from '../helpers/timestamp';
import { IAddress } from './address';
import { DISABILITY } from './iTaxform';
// tslint:disable: object-literal-sort-keys

export interface IChildCalculation {
  disability: number; // reduktion durch Behinderung
}

export enum CHILDSITUATION {
  'sharedParent' = 'sharedParent',
  'singleParent' = 'singleParent',
  'noParent' = 'noParent',
}

export enum CHILDRELATION {
  ownOrAdopted = 1,
  care = 2,
  grandOrStep = 3,
}

export interface IChildExpenses {
  hasChildcareCost: boolean;
  hasSchoolfees: boolean;

  childcare: number | null; // Kinderbetreungskosten
  schoolfees: number | null; // Schulgeld
}

export enum OCCUPATION {
  'STUDENT' = 'student',
  'FIRST_EDUCATION' = 'first_education',
  'SECOND_EDUCATION' = 'second_education',
  'EMPLOYED' = 'employed',
  'UNEMPLOYED' = 'unemployed',
}

export interface IChildOccupation {
  status: OCCUPATION | null;
  startdate: Timestamp | null;
  enddate: Timestamp | null;
  description: string;
  isWorking: boolean; // Arbeitet das Kind zusätzlich?
  workingHours: number | null;
}

export interface IChild {
  firstname: string;
  lastname: string;
  situation: CHILDSITUATION;
  // sameHousehold: boolean;   // lebt im selben Haushalt
  birthdate: Timestamp | null;
  hasDisability: boolean;
  disability: DISABILITY;
  address: IAddress;
  calculation: IChildCalculation;
  expenses: IChildExpenses;

  occupation: IChildOccupation[];

  /**
   * Information about the childs other parent
   */
  relationshipOtherPerson?: RelationshipOtherPerson;
}

export type RelationshipOtherPerson =
  | {
      firstname: string;
      lastname: string;
      relationship: CHILDRELATION;
      unknown?: false;
    }
  | {
      unknown: true;
      firstname?: undefined;
      lastname?: undefined;
      relationship?: undefined;
    };
