import { google } from '@google-cloud/vision/build/protos/protos';
import { ClientShort } from '../client/client';
import { Message } from '../communication/message';
import { Timestamp } from '../helpers/timestamp';
import { Tag } from '../tag/tag';
import {
  ContentTaggable,
  FullfillableByContentTags,
} from '../taxoffice/content-tag-handle';
import {
  ContentTagEntry,
  ExtractedDocument,
} from '../taxoffice/taxoffice-letter';
import {
  ContentTags,
  ProviderContentTags,
} from '../taxoffice/taxoffice-letter-content-tags';
import { ParsedFieldKey, TypeOfParsedField } from './specific-documents';

export enum DOCUMENTTYPE {
  /**
   * German: Identitätskarte
   */
  'identitycard' = 'identitycard',
  /**
   * German: Reisepass
   */
  'passport' = 'passport',

  /**
   * German: Identitätskarte Rückseite
   */
  'identitycardBackside' = 'identitycardBackside',
  /**
   * German: Aufenthaltsgenehmigung
   */
  'residentPermit' = 'residentPermit',
  /**
   * German: Aufenthaltsgenehmigung Rückseite
   */
  'residentPermitBackside' = 'residentPermitBackside',
  /**
   * German: Rentenbezugsmitteilung
   */
  'pensionWithdrawalNotice' = 'pensionwithdrawalnotice',
  /**
   * German: Krankenversicherung
   */
  'healthInsurance' = 'healthinsurance',
  /**
   * German: Bescheinigung ueber Lohnersatzleistungen
   */
  'certificateWageReplacemenBenefits' = 'certificatewagereplacemenbenefits',
  /**
   * German: Unterschrift digitalisiert
   */
  'signature' = 'signature',
  /**
   * German: Gehaltsabrechnung
   */
  'wagestatement' = 'wagestatement',
  /**
   * German: Lohnsteuerscheinigung
   */
  'incometaxstatement' = 'incometaxstatement',
  /**
   * Stammdaten : Vom Finanzamt übermittelte stammdaten
   */
  'masterdata' = 'masterdata',
  /**
   * DE : Religionszugehörigkeit
   */
  'religiousConfession' = 'religiousConfession',
  /**
   * DE : Vermoegensbildungsbescheinigung
   */
  'assetCertification' = 'assetcertification',
  /**
   * Auslage : beispielsweise ein Beleg aus einem Restaurant für ein Geschäftsessen
   */
  'expense' = 'expense',
  /**
   * Generated PDF sent to the taxoffice via Elster
   */
  'taxoffice' = 'taxoffice',
  /**
   * Generated PDF for Elster testing
   */
  'testtaxoffice' = 'testtaxoffice',
  /**
   * Beleg : Beispielsweisee ein Ausdruck eines EC Beleges von der Tankstelle
   */
  'receipt' = 'receipt',
  /**
   * German: Steuer Vollmacht
   */
  'representationAuthorization' = 'representationAuthorization',
  /**
    German: Rechnung
     */
  'invoice' = 'invoice',
  /**
   * German: Steuerbescheid
   */
  'taxstatement' = 'taxstatement',
  /**
   * German: Aenderungsbescheid
   */
  'changeTaxStatement' = 'changeTaxStatement',
  /**
   * German Vorauszahlungsbescheid
   */
  'prepaidTaxStatement' = 'prepaidTaxStatement',
  /**
   * German Schätzungsbescheid
   */
  'predictedTaxStatement' = 'predictedTaxStatement',
  /**
   * German Zinsbescheid
   */
  'interestChargeTaxStatement' = 'interestChargeTaxStatement',
  /**
   * German: Riester
   */
  'riester' = 'riester',
  /**
   * German: Rürup
   */
  'ruerup' = 'ruerup',
  /**
   * German: Führerschein
   */
  'driver_licence' = 'driver_licence',
  'driver_licence_backside' = 'driver_licence_backside',
  /**
   * German: Behindertenausweis
   */
  'handicappedId' = 'handicappedId',
  'handicappedIdBackside' = 'handicappedIdBackside',
  'plainTaxId' = 'plainTaxId',
  /**
   * German: Brief vom Finanzamt mit Nachfragen
   */
  'taxoffice_request' = 'taxoffice_request',
  /**
   * German: Generelles schreiben vom Finanzamt (kein Bescheid, keine Nachfrage)
   */
  'taxoffice_letter' = 'taxoffice_letter',
  /**
   * German: Brief vom Finanzamt mit Informationen über den bearbeiteten Fall
   * ODER Information für einen Steuerberater
   */
  'taxoffice_information' = 'taxoffice_information',
  /**
   * German: Dokument vom Finanzamt mit der Erinnerung an eine Nachfrage und der deadline
   */
  'taxoffice_reminder' = 'taxoffice_reminder',
  /**
   * German: Bankverbindung
   */
  'bankaccount' = 'bankaccount',
  /**
   * VMDB File. German: Kapitalertraege mit Freistellungsauftrag
   */
  'capitalGainsWithTaxExemption' = 'capitalGainsWithTaxExemption',
  /**
   * VMDB File. German: Zuschuesse und Erstattungen von Behoerden / oeffentlichen Stellen
   */
  'subsidiesFromAuthorities' = 'subsidiesFromAuthorities',
  /**
   * Used for all audio files (e.g. voice messages), can later be splitted in other types if needed
   */
  'vmaProof' = 'vmaProof',
  /**
   * Uploaded receipt of an expense uploaded by user
   */
  'uploadedReceipt' = 'uploadedReceipt',
  /**
   * Uploaded receipt of an expense uploaded by user which was classified as irrelevant
   */
  'irrelevantUploadedReceipt' = 'irrelevantUploadedReceipt',
  /**
   * German Nebenkostenabrechnung
   */
  'annualServiceChargeStatement' = 'annualServiceChargeStatement',

  'audio' = 'audio',
  'video' = 'video',
  'other' = 'other',
}
export class Documenttype {
  private static documentTypeLabels: { [key in DOCUMENTTYPE]: string } = {
    annualServiceChargeStatement: 'Nebenkosten Abrechnung',
    identitycard: 'Ausweis Vorderseite',
    passport: 'Reisepass Vorderseite',
    identitycardBackside: 'Ausweis/Reisepass Rückseite',
    residentPermit: 'Aufenthaltsgenehmigung/Duldung',
    residentPermitBackside: 'Aufenthaltsgenehmigung/Duldung Rückseite',
    pensionwithdrawalnotice: 'Rentenbezugsmitteilung',
    healthinsurance: 'Krankenversicherung',
    certificatewagereplacemenbenefits:
      'Bescheinigung über Lohnersatzleistungen',
    signature: 'Unterschrift',
    incometaxstatement: 'Lohnsteuerscheinigung',
    wagestatement: 'Gehaltsabrechnung',
    masterdata: 'Stammdaten (vom Finanzamt)',
    religiousConfession: 'Religionszugehörigkeit',
    assetcertification: 'Vermögensbildungsbescheinigung',
    expense: 'Auslage',
    taxoffice: 'Elsterübermittlung PDF (final)',
    testtaxoffice: 'Elsterübermittlung PDF Test',
    receipt: 'Beleg',
    representationAuthorization: 'Kundenvollmacht (in Steuerangelegenheiten)',
    invoice: 'Rechnung',
    taxstatement: 'Steuerbescheid',
    changeTaxStatement: 'Aenderungsbescheid',
    prepaidTaxStatement: 'Vorauszahlungsbescheid',
    predictedTaxStatement: 'Schaetzungsbescheid',
    interestChargeTaxStatement: 'Zinsbescheid',
    riester: 'Riester',
    ruerup: 'Rürup',
    driver_licence: 'Führerschein',
    driver_licence_backside: 'Führerschein Rückseite',
    handicappedId: 'Behidertenausweis',
    handicappedIdBackside: 'Behidertenausweis Rückseite',
    plainTaxId: 'Steuer ID',
    taxoffice_request: 'Nachfrage vom Finanzamt',
    taxoffice_information: 'Information vom Finanzamt',
    taxoffice_reminder: 'Erinnerung an eine Nachfrage vom Finanzamt',
    taxoffice_letter:
      'Generelles schreiben vom Finanzamt (kein Bescheid, keine Nachfrage)',
    bankaccount: 'Bankverbindung',
    capitalGainsWithTaxExemption: 'Kapitalerträge mit Freistellungsauftrag',
    subsidiesFromAuthorities:
      'Zuschüsse und Erstattungen von Behörden / öffentlichen Stellen',
    vmaProof: 'Beleg für VMA',
    uploadedReceipt: 'Hochgeladene Belege für Ausgaben',
    irrelevantUploadedReceipt: 'Irrelevante Belege für Ausgaben',
    audio: 'Audio',
    video: 'Video',
    other: 'anderes Dokument (nicht benötigt)',
  };

  public static getLabels(): { label: string; value: DOCUMENTTYPE }[] {
    return Object.entries(this.documentTypeLabels).map(([value, label]) => ({
      value: value as DOCUMENTTYPE,
      label: label as string,
    }));
  }
  public static getLabel(type: DOCUMENTTYPE): string {
    return this.documentTypeLabels[type];
  }
}

export function isDOCUMENTTYPE(input: any): input is DOCUMENTTYPE {
  return Object.values(DOCUMENTTYPE).includes(input);
}

/**
 * @deprecated This is the original document from crm v2. use BinaryDocument instead
 * @class BinaryDocument
 * */
export class DocumentOld {
  public static getTemplate(): DocumentOld {
    return {
      name: '',
      type: DOCUMENTTYPE.other,
      size: 0,
      contentType: '',
      fullPath: '',
      md5Hash: '',
      created: null,
      url: '',
      tags: [],
      mldata: MachineLearningContent.getTemplate(),
      parocessing: MachineLearningContent.getTemplate(), // only for back compatibility
      file: null,
    };
  }

  name = '';
  type?: DOCUMENTTYPE = DOCUMENTTYPE.other;
  size = 0;
  contentType = '';
  fullPath = '';
  md5Hash = '';
  created: Timestamp | null = null;
  url = '';
  tags: Tag[] = [];
  mldata: MachineLearningContent | null = null; // TODO refactor the name
  parocessing?: MachineLearningContent | null = null;
  file?: File | null = null;
}
// DEPRECATED
export interface IDocument {
  name: string;
  type: DOCUMENTTYPE;
  description: string;
  partner: boolean | null;
  doctype: string;
  metadata: any[];
}
// DEPRECATED
export class MachineLearningContent {
  public static getTemplate(): MachineLearningContent {
    return {
      processed: false,
      language: 'de',
      json: '',
      imageProperties: '',
      ocrText: '',
      quality: '',
      adult: '',
      racy: '',
      violence: '',
    };
  }

  processed = false;
  language = '';
  json = '';
  imageProperties = '';
  ocrText = '';
  quality = '';
  adult = '';
  racy = '';
  violence = '';
}

export class BinaryDocumentMetadataTypes {
  public static getTemplate(): BinaryDocumentMetadataTypes {
    return {
      identitycard: false,
      signature: false,
      wagestatement: false,
      expense: false,
      taxoffice: false,
      testtaxoffice: false,
      receipt: false,
      other: false,
      healthInsurance: false,
      taxstatement: false,
      changeTaxStatement: false,
      predictedTaxStatement: false,
      prepaidTaxStatement: false,
      interestChargeTaxStatement: false,
      handicappedId: false,
      invoice: false,
      religiousConfession: false,
      masterdata: false,
      riester_ruerup: false,
      assetCertification: false,
      pensionWithdrawalNotice: false,
      certificateWageReplacemenBenefits: false,
      capitalGainsWithTaxExemption: false,
      subsidiesFromAuthorities: false,
    };
  }
  identitycard = false;
  signature = false;
  wagestatement = false;
  expense = false;
  taxoffice = false;
  testtaxoffice = false;
  taxstatement = false;
  changeTaxStatement = false;
  prepaidTaxStatement = false;
  predictedTaxStatement = false;
  interestChargeTaxStatement = false;
  handicappedId = false;
  invoice = false;
  receipt = false;
  other = false;
  healthInsurance = false;
  religiousConfession = false;
  masterdata = false;
  riester_ruerup = false;
  assetCertification = false;
  pensionWithdrawalNotice = false;
  certificateWageReplacemenBenefits = false;
  capitalGainsWithTaxExemption = false;
  subsidiesFromAuthorities = false;
}

export enum LIKELIHOOD {
  UNKNOWN,
  VERY_UNLIKELY,
  UNLIKELY,
  POSSIBLE,
  LIKELY,
  VERY_LIKELY,
}

export enum MODEL {
  AUTOML_NL,
  VISIONAI,
}

/**
 * A label with a score, optional is the used AI model
 */
export class ScoredLabel {
  label: string = '';
  score: number = 0;
  model?: MODEL;
}

export class BinaryDocumentMetadataML {
  public static getTemplate(): BinaryDocumentMetadataML {
    return {
      updated: null,
      processed: false,
      language: 'de',
      json: '',
      imageProperties: '',
      ocrText: '',
      quality: '',
      labelAnnotations: [],
      logoAnnotations: [],
      textAnnotations: [],
      objectAnnotations: [],
      labels: [],
    };
  }

  updated: Timestamp | null = null;
  /**
   * @deprecated use binarydocument.processed instead
   * */
  processed = false;
  language = '';
  json = '';
  imageProperties = '';
  ocrText = '';
  quality = '';
  faces?: google.cloud.vision.v1.IFaceAnnotation[] | null;
  safeSearchAnnotation?: google.cloud.vision.v1.ISafeSearchAnnotation | null;
  labelAnnotations?: google.cloud.vision.v1.IEntityAnnotation[] | null = [];
  logoAnnotations?: google.cloud.vision.v1.IEntityAnnotation[] | null;
  textAnnotations?: google.cloud.vision.v1.IEntityAnnotation[] | null;
  objectAnnotations?:
    | google.cloud.vision.v1.ILocalizedObjectAnnotation[]
    | null;
  labels?: ScoredLabel[];
  predictions?: DocumentPredictions;
  pdfText?: string;
}

/**
 * @example
 * ```
 * {
 *   firstname: [
 *     {
 *       suggestion: 'Eric',
 *       forDocumenttypes: [
 *         {
 *           type: DOCUMENTTYPE.identitycard,
 *           score: 0.9,
 *         },
 *       ],
 *     },
 *   ],
 * };
 * ```
 */
export type BinaryDocumentClassificationFields = {
  [key in ParsedFieldKey]?: {
    suggestion: TypeOfParsedField<key>;
    forDocumenttypes?: {
      /**
       * If no type is given this score is assumed to be valid for all document types
       */
      type?: DOCUMENTTYPE;
      score?: number;
      source?: unknown;
    }[];
  }[];
};
export interface DocumentPredictions {
  type: DocumentTypePrediction[];
  fields: BinaryDocumentClassificationFields;
}
export interface DocumentTypePrediction {
  suggestion: DOCUMENTTYPE;
  score: number;
}

export enum BINARYDOCUMENTSTATUS {
  NEW = 'new', // New Document just uplaoded
  /**
   * @deprecated use binarydocument.processed instead
   * */
  PROCESSED = 'processed', // Machine learning processing happened
  ARCHIVED = 'archived', // File is ignored in CRM
}

export class BinaryDocumentMetadata {
  public static getTemplate(): BinaryDocumentMetadata {
    return {
      messageId: '',
      clientId: '',
      clientUploadDocumentRef: null,
    };
  }
  message?: Message | null;
  messageId: string | null = ''; // if uploaded by Message => Message Reference
  client?: ClientShort | null;
  clientId = '';
  taxcaseid?: string;
  mldata?: BinaryDocumentMetadataML | null;
  arbitraryData?: any;
  /**
   * link to a clientUploadDocument used to update it if this binary doc is verified
   */
  clientUploadDocumentRef?: string | null;
  /**
   * @deprecated use only BinaryDocument.type
   */
  type?: BinaryDocumentMetadataTypes | null;
}

export class BinaryFile {
  public static getTemplate(): BinaryFile {
    return {
      filename: '',
      filepath: '',
      downloadUrl: '',
    };
  }

  filename = '';
  filepath = '';
  bucket? = '';
  downloadUrl? = '';
  file?: File | null;
}

/**
 * The Source where the document came from
 */
export enum DOCUMENTSOURCE {
  /**
   * Customer has sent the document
   */
  CLIENT,
  /**
   * We generated the document
   */
  SYSTEM,
  /**
   * Official Document from the finance department
   */
  FINANCEDEPARTMENT,
  /**
   * Unspecified document should not be used
   */
  OTHER,
}

export class BinaryDocumentLink {
  public static getTemplate(): BinaryDocumentLink {
    return {
      id: '',
    };
  }
  id = '';
  name?: string = '';
  link?: string = '';
}

export class BinaryDocument<
  TType = string,
  TParsedFields extends Record<string | number, unknown> | undefined = undefined
> implements FullfillableByContentTags, ContentTaggable
{
  /**
   * Generates a template of BinaryDocument
   * @param processing If the document should run throught the ML processing.
   * */
  public static getTemplate<
    TType = DOCUMENTTYPE,
    TParsedFields extends Record<string, unknown> | undefined = undefined
  >(processing = true): BinaryDocument<TType, TParsedFields> {
    return {
      id: '',
      state: BINARYDOCUMENTSTATUS.NEW,
      name: '',
      size: 0,
      contentType: '',
      description: '',
      metadata: BinaryDocumentMetadata.getTemplate(),
      uploaded: Timestamp.now(), // TODO remove initialization
      modified: Timestamp.now(), // TODO remove initialization
      needProcessing: processing,
      processed: false,
      verified: false,
      ignored: false,
      unreadable: false,
      source: null,
      revisionSafe: false,
    };
  }

  id = '';

  state: BINARYDOCUMENTSTATUS = BINARYDOCUMENTSTATUS.NEW;

  source?: DOCUMENTSOURCE | null;

  name = '';
  size = 0;
  contentType = 'image/png';
  description = '';

  file?: BinaryFile;
  file_original?: BinaryFile;

  type?: TType | null;
  parsedFields?: TParsedFields;

  metadata: BinaryDocumentMetadata = BinaryDocumentMetadata.getTemplate();

  uploaded: Timestamp | null = null;
  modified: Timestamp | null = null;

  /**
   * If thumbnails are available
   * here are the bucket paths
   */
  thumbnails?: {
    /**
     * path to the file in full size but with reduced quality
     */
    large: string;
    /**
     * 800*X
     */
    medium: string;
    /**
     * 200*X
     */
    thumb: string;
  };

  /**
   * If the document need to be processed by ML.
   **/
  needProcessing = true;
  /**
   * If the document has been processed with ML
   **/
  processed = false;

  /**
   * If the document cant be read by human or machine
   * */
  unreadable = false;

  /**
   * Ignore file, because it is not a relevant tax document
   * */
  ignored = false;

  /**
   * Verified by human or tec but with 100% trustability
   **/
  verified = false;

  uploadedThroughWonderland?: boolean;
  enforceClassification?: boolean;

  /**
   * If the document is hosted on revision safe storage
   */
  revisionSafe?: boolean = false;
  containedUniqueTags?: Partial<{
    [key in ContentTags | ProviderContentTags]: Record<number, true>;
  }> | null = null;
  contentTags?: ContentTagEntry[] | null = null;
  fullFilled?: boolean = false;
  taggingComplete?: boolean = false;
  visionOCRStructured?: ExtractedDocument | null = null;
}

export class UploadDocument implements IDocument {
  public static getTemplate(): UploadDocument {
    return {
      name: '',
      type: DOCUMENTTYPE.other,
      description: '',
      partner: null,
      doctype: 'image/png',
      metadata: [],
    };
  }
  name = '';
  type: DOCUMENTTYPE = DOCUMENTTYPE.other;
  description = '';
  partner: boolean | null = null;
  doctype = 'image/png';
  metadata: any[] = [];
}

export interface AlgoliaDocument
  extends Omit<BinaryDocument, 'uploaded' | 'modified'> {
  objectID: string;
  uploadedMilis: string;
  uploaded: string;
  modified: string;
}

export function isClassified(document: BinaryDocument) {
  return !!document.type && document.verified === true; // TODO validate parsedFields
}

export function wasUploadedThroughWonderland(document: BinaryDocument) {
  return document.uploadedThroughWonderland === true;
}
