import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Message, MessageReplySuggestion } from '@expresssteuer/models';

@Component({
  selector: 'esui-message-composer',
  templateUrl: './message-composer.component.html',
  styleUrls: ['./message-composer.component.scss'],
  animations: [
    trigger('slideInOutA', [
      transition(':enter', [
        style({
          transform: 'translateY(-100%)',
          opacity: 0,
        }),
        animate(
          '200ms ease-in',
          style({
            transform: 'translateY(0%)',
            opacity: 1,
          })
        ),
      ]),
      transition(':leave', [
        animate(
          '200ms ease-in',
          style({
            transform: 'translateY(-100%)',
            opacity: 0,
          })
        ),
      ]),
    ]),
    trigger('slideInOutB', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [
        animate(
          '200ms ease-in',
          style({
            transform: 'translateY(100%)',
          })
        ),
      ]),
    ]),
  ],
})
export class MessageComposerComponent {
  @Input() showSuggestions?: boolean | null = true;
  @Input() showEditor?: boolean | null = false;

  @Input() suggestions?: MessageReplySuggestion[] | null = [];
  @Output() suggestionSelected = new EventEmitter<MessageReplySuggestion>();

  @Input() message?: Message | null;
  @Output() messageChange = new EventEmitter<Message>();
  @Output() messagePartialChange = new EventEmitter<Partial<Message>>();

  @Output() sendMessageClick = new EventEmitter<void>();
  @Output() noSuggestionMatchesClick = new EventEmitter<void>();
  @Output() cancelClick = new EventEmitter<void>();

  trackByMessage(index: number, suggestion: MessageReplySuggestion) {
    return `${index}-${suggestion.sourceMessage.message}-${suggestion.sourceMessage.id}-${suggestion.suggestedMessage.message}-${suggestion.suggestedMessage.id}`;
  }

  messageContentChangeHandler(messageContent: string) {
    this.messagePartialChange.next({
      message: messageContent,
    });

    if (!this.message) {
      return;
    }
    this.messageChange.next({
      ...this.message,
      message: messageContent,
    });
  }
}
