/* eslint-disable */

export interface VaSt_ZUS {
  VaSt_ZUS: VaSt_ZUS_CType;
}

export interface VaSt_ZUS_CType {
  /**
   * Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung
   */
  Eingangsdatum?: string;
  BetroffenerFall?: BetroffenerFallM2062355356_CType;
  Behoerde?: BehoerdeM2062355356_CType;
  /**
   * @maxItems 264
   */
  Zahlungsdaten?:
    | ZahlungsdatenM2062355356_CType
    | ZahlungsdatenM2062355356_CType[];
  version: string;
}

export interface BetroffenerFallM2062355356_CType {
  /**
   * Jahr der Zahlung
   */
  Meldejahr?: string;
  BetroffenePerson?: BetroffenePerson_1957128839_CType;
}

export interface BetroffenePerson_1957128839_CType {
  NatPerson?: NatPersonM1974801976_CType;
}

export interface NatPersonM1974801976_CType {
  /**
   * Identifikationsnummer
   */
  IdNr?: string;
  /**
   * Nachname
   */
  Name?: string;
  /**
   * Vorname
   */
  Vorname?: string;
  /**
   * Name
   */
  Blockname?: string;
  /**
   * Namensvorsatz
   */
  NamensVorsatz?: string;
  /**
   * Namenszusatz
   */
  NamensZusatz?: string;
  /**
   * Titel
   */
  Titel?: string;
}

export interface BehoerdeM2062355356_CType {
  /**
   * Name der Behörde / öffentlichen Stelle
   */
  Name?: string;
}

export interface ZahlungsdatenM2062355356_CType {
  /**
   * Beginn des Zeitraums, für den die Zahlung erfolgte
   */
  Beginn?: string;
  /**
   * Ende des Zeitraums, für den die Zahlung erfolgte
   */
  Ende?: string;
  /**
   * Zahlungsart
   */
  Zahlungsart?:
    | '01'
    | '21'
    | '02'
    | '22'
    | '03'
    | '23'
    | '04'
    | '24'
    | '05'
    | '25'
    | '06'
    | '26'
    | '07'
    | '27'
    | '08'
    | '28'
    | '09'
    | '29'
    | '10'
    | '30';
  /**
   * Höhe der erhaltenen / zurückgeforderten Zuschüsse / Erstattungen
   */
  Betrag?: string;
  /**
   * Währung der erhaltenen / zurückgeforderten Zuschüsse / Erstattungen
   */
  Waehrung?: string;
}
