import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { EsuiFirestorePaginatorModule } from '../esui-firestore-paginator/esui-firestore-paginator.module';
import { TimestampModule } from '../pipes/timestamp/timestamp.module';
import { EsuiTaxcasesListComponent } from './esui-taxcases-list.component';

@NgModule({
  declarations: [EsuiTaxcasesListComponent],
  imports: [
    CommonModule,
    MatTableModule,
    TimestampModule,
    EsuiFirestorePaginatorModule,
    MatSortModule,
    TimestampModule,
  ],
  exports: [EsuiTaxcasesListComponent],
})
export class EsuiTaxcasesListModule {}
