import { Component, Input } from '@angular/core';
import {
  BescheidDataValue,
  DataTaxnotice,
  mergeAllTaxnoticeParsedValuesText,
} from '@expresssteuer/taxnotices-models';

@Component({
  selector: 'esui-data-taxnotice-detail',
  templateUrl: './esui-data-taxnotice-detail.component.html',
  styleUrls: ['./esui-data-taxnotice-detail.component.scss'],
})
export class EsuiDataTaxnoticeDetailComponent {
  private _taxNotice: DataTaxnotice | null = null;
  private taxNoticeHeaders: string[] | null = null;
  private taxnoticeRequiredDataFieldIds: string[] = [];
  @Input()
  toShowParsedValueIds: string[] = [
    '0680000850',
    '0680000890',
    '0680000891',
    '0680000861',
    '0680000863',
    '0680000864',
    '0680000865',
    '0680000867',
    '0680000868',
    '0680000869',
    '0680000871',
    '0680000872',
    '0680000873',
    '0680000875',
    '0680000876',
    '0680000879',
    '0680000881',
    '0680000882',
    '0680000883',
    '0680000885',
    '0680000886',
    '0701010001',
    '0701010002',
    '0701010004',
    '0701010005',
    '0701010006',
    '0701020005',
    '0701020006',
    '0702010004',
    '0702010005',
    '0702010006',
    '0702010007',
    '0702020005',
    '0702020010',
    '0702020011',
    '0703010004',
    '0703010005',
    '0703010006',
    '0703020005',
    '0703020010',
    '0704010004',
    '0704010005',
    '0704010006',
    '0704010007',
    '0650000654',
  ];

  @Input()
  set taxNotice(value: DataTaxnotice | null) {
    this._taxNotice = value;
    this.taxNoticeHeaders = Object.keys(value?.parsedHeaderData ?? {});
  }
  get taxNotice() {
    return this._taxNotice;
  }

  mergeTextOfParsedTaxNoticeValues(taxNotice: DataTaxnotice): string {
    return mergeAllTaxnoticeParsedValuesText(taxNotice.parsedDataPoints);
  }

  filterByDatafields(taxNotice: DataTaxnotice): BescheidDataValue[] {
    return taxNotice.parsedDataPoints.filter((dataPoint) => {
      return (
        this.toShowParsedValueIds.includes(dataPoint.fullId) &&
        dataPoint.parsedValue !== 0
      );
    });
  }

  /**
   * filters taxnoticepoints which contain taxnotice text and 0 Values
   * in datataxnotices range 0120000100- 0120000fff is reserved for text
   * see elster bescheidwertnummern https://www.elster.de/elsterweb/entwickler
   * @param taxNotice
   */
  filterZeroAndTextValues(taxNotice: DataTaxnotice): BescheidDataValue[] {
    return taxNotice.parsedDataPoints.filter((dataPoint) => {
      return (
        (dataPoint.parsedValue !== 0 && dataPoint.fullId > '0120000999') ||
        dataPoint.fullId < '0120000100'
      );
    });
  }

  getSpecificParsedValue(taxNotice: DataTaxnotice, fieldId: string): string {
    const value =
      taxNotice.parsedDataPoints.find(
        (dataPoint) => dataPoint.fullId === fieldId
      )?.rawValue ?? 'unknown';
    return `${value}`;
  }
}
