import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TaxcaseComment } from '@expresssteuer/models';

@Component({
  selector: 'esui-taxcase-comments',
  templateUrl: './taxcase-comments.component.html',
  styleUrls: ['./taxcase-comments.component.scss'],
})
export class TaxcaseCommentsComponent {
  @Input()
  taxcaseComments?: TaxcaseComment[] | null;

  @Input()
  editable = false;

  @Input()
  newComment?: string;

  @Output()
  newCommentChange = new EventEmitter<string>();

  @Output()
  newCommentSendClick = new EventEmitter<void>();
}
