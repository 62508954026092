<div
  [ngClass]="{
    outbound: message.direction === DIRECTION.OUTBOUND,
    inbound: message.direction === DIRECTION.INBOUND
  }"
  *ngIf="message as message"
>
  <div class="msg-header">
    <span>{{ message.from?.displayName }}</span>
    <span class="subject" *ngIf="message.subject">
      ::
      {{
        message.subject.length > 35
          ? (message.subject | slice : 0 : 35) + '...'
          : message.subject
      }}</span
    >
  </div>
  <div class="msg-body">
    <div class="msg-cards">
      <mat-card class="msg-card">
        <mat-card-header> </mat-card-header>
        <mat-card-content class="msg-content" [innerHtml]="message.message">
        </mat-card-content>
        <mat-card-footer class="date" *ngIf="message.metadata?.created">
          <ng-container *ngIf="message.direction === DIRECTION.OUTBOUND">
            sent at
          </ng-container>
          <ng-container *ngIf="message.direction === DIRECTION.INBOUND">
            received at
          </ng-container>
          {{ message.metadata?.created?.toDate() | date : 'hh:mm dd.MM.YY' }}
          <!-- TODO replace toDate with eg. pipe-->
        </mat-card-footer>
      </mat-card>
      <mat-card
        class="msg-card msg-attachment"
        *ngFor="let attachment of message.attachments ?? []"
      >
        <esui-document-viewer
          mat-card-image
          [downloadUrl]="attachment.downloadPath"
          [contentType]="attachment.contentType"
          frameborder="0"
        ></esui-document-viewer>
      </mat-card>
    </div>
    <esui-message-state
      *ngIf="message.state"
      class="msg-status"
      [state]="message.state"
    ></esui-message-state>
  </div>
</div>
