import { Timestamp } from '@expresssteuer/models';
import { BescheidDataValue } from './data-taxnotice';

export abstract class ArchivedRawDataTaxnotice {
  static getTemplate(
    taxId: string,
    taxNumber: string,
    rawData: string,
    taxAdvisorId: string,
    createdAt: Timestamp,
    parsedFields: BescheidDataValue[],
    overwrite: Partial<ArchivedRawDataTaxnotice>
  ): ArchivedRawDataTaxnotice {
    return {
      ...overwrite,
      taxId,
      createdAt,
      taxNumber,
      rawData,
      taxAdvisorId,
      dataTaxnoticePath: null,
      parsedFields,
    };
  }
  abstract taxId: string;
  abstract createdAt: Timestamp;
  abstract taxNumber: string;
  abstract rawData: string;
  abstract taxAdvisorId: string;
  /**
   * Path to the taxnotice created by consuming this raw data document
   */
  abstract dataTaxnoticePath: string | null;
  abstract parsedFields: BescheidDataValue[];
}
