import { Timestamp } from '../helpers/timestamp';
import { Person } from './person';

export class Partner extends Person {
  marriedSince: Timestamp | null = null;
  divorcedSince: Timestamp | null = null;

  constructor() {
    super();
  }
}
