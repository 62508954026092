<div class="modal-dialog" role="document">
  <div class="modal-content">
    <!--begin::Form-->
    <form id="kt_inbox_compose_form">
      <!--begin::Header-->
      <div
        class="d-flex align-items-center justify-content-between py-5 pl-8 pr-5 border-bottom"
      >
        <h5 class="font-weight-bold m-0">Nachricht</h5>
        <div class="radio-inline">
          <label class="radio">
            <input
              type="radio"
              name="mtype"
              (click)="messagetype = MESSAGETYPE.SMS"
            />
            <span></span>SMS</label
          >
          <label class="radio">
            <input
              type="radio"
              checked="checked"
              name="mtype"
              (click)="messagetype = MESSAGETYPE.WHATSAPP"
            />
            <span></span>WhatsApp</label
          >
          <label class="radio">
            <input
              type="radio"
              name="mtype"
              (click)="messagetype = MESSAGETYPE.EMAIL"
            />
            <span></span>Email</label
          >
          <label *ngIf="false" class="radio">
            <input
              type="radio"
              name="mtype"
              (click)="this.messagetype = MESSAGETYPE.PUSH"
            />
            <span></span>Push Notification</label
          >
        </div>
        <div class="d-flex ml-2">
          <span class="btn btn-clean btn-sm btn-icon mr-2">
            <i class="flaticon2-arrow-1 icon-1x"></i>
          </span>
          <span class="btn btn-clean btn-sm btn-icon" data-dismiss="modal">
            <i class="ki ki-close icon-1x"></i>
          </span>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="d-block">
        <!--begin::To-->
        <div
          class="d-flex align-items-center border-bottom inbox-to px-8 min-h-45px"
        >
          <div class="text-dark-50 w-50px">To:</div>
          <div class="d-flex align-items-center flex-grow-1">
            <input
              type="text"
              class="form-control border-0 input_compose_to"
              name="compose_to"
              value=""
            />
          </div>
          <div class="ml-2" *ngIf="false">
            <span
              class="text-muted font-weight-bold cursor-pointer text-hover-primary mr-2"
              data-inbox="cc-show"
              >Cc</span
            >
            <span
              class="text-muted font-weight-bold cursor-pointer text-hover-primary"
              data-inbox="bcc-show"
              >Bcc</span
            >
          </div>
        </div>

        <!--end::To-->

        <!--begin::CC-->
        <div
          class="d-none align-items-center border-bottom inbox-to-cc pl-8 pr-5 min-h-45px"
          *ngIf="false"
        >
          <div class="text-dark-50 w-50px">Cc:</div>
          <div class="flex-grow-1">
            <input
              type="text"
              class="form-control border-0 input_compose_cc"
              name="compose_cc"
              value=""
            />
          </div>
          <span class="btn btn-clean btn-xs btn-icon" data-inbox="cc-hide">
            <i class="la la-close"></i>
          </span>
        </div>
        <!--end::CC-->

        <!--begin::BCC-->
        <div
          class="d-none align-items-center border-bottom inbox-to-bcc pl-8 pr-5 min-h-45px"
          *ngIf="false"
        >
          <div class="text-dark-50 w-50px">Bcc:</div>
          <div class="flex-grow-1">
            <input
              type="text"
              class="form-control border-0 input_compose_bcc"
              name="compose_bcc"
              value=""
            />
          </div>
          <span class="btn btn-clean btn-xs btn-icon" data-inbox="bcc-hide">
            <i class="la la-close"></i>
          </span>
        </div>
        <!--end::BCC-->

        <!--begin::Fall-->
        <div
          class="d-flex align-items-center border-bottom inbox-to px-8 min-h-45px"
        >
          <div class="text-dark-50 w-50px">Fall:</div>
          <div class="d-flex align-items-center flex-grow-1">
            <select
              class="form-control border-0 px-8"
              name="taxcase"
              id="taxcase"
              [(ngModel)]="currentTaxCase"
              (change)="validate()"
            >
              <option [ngValue]="emptyCase">Keiner</option>
              <option *ngFor="let tc of currentTaxCases" [ngValue]="tc">
                {{ tc.year }} - {{ tc.id }} -
                {{ tc.metadata.status.current }}({{
                  tc.metadata.status.substatus
                }})
              </option>
            </select>
          </div>
        </div>
        <!--end::Fall-->

        <!--begin::TaxCase-->
        <div
          class="d-flex align-items-center border-bottom inbox-to px-8 min-h-45px"
          *ngIf="templatesservice.templates | async; let templates"
        >
          <div class="text-dark-50 w-50px">Template:</div>
          <div class="d-flex align-items-center flex-grow-1">
            <select
              class="form-control border-0 px-8"
              name="template"
              [(ngModel)]="currentTemplate"
              (change)="validate()"
            >
              <option (click)="setTemplate(null)">Ohne Template</option>
              <option *ngFor="let template of templates" [ngValue]="template">
                {{ template.name }}
              </option>
            </select>
          </div>
        </div>
        <!--end::TaxCase-->

        <!--begin::Subject-->
        <div
          class="border-bottom"
          *ngIf="
            messagetype === MESSAGETYPE.EMAIL ||
            messagetype === MESSAGETYPE.PUSH
          "
        >
          <input
            class="form-control border-0 px-8 min-h-45px input_compose_subject"
            id="compose_subject"
            name="compose_subject"
            placeholder="Subject"
          />
        </div>
        <!--end::Subject-->

        <!--begin::ActionLink-->
        <div class="border-bottom" *ngIf="messagetype === MESSAGETYPE.PUSH">
          <input
            class="form-control border-0 px-8 min-h-45px input_compose_action_link"
            id="compose_action_link"
            name="compose_action_link"
            placeholder="Action Link e.g. myaccount/todos"
          />
        </div>
        <!--end::ActionLink-->

        <!--begin::Message-->

        <div
          id="kt_inbox_compose_editor"
          class="border-0 ql-container ql-snow"
          style="min-height: 250px"
        >
          <div
            class="ql-editor ql-blank px-8"
            data-gramm="false"
            contenteditable="true"
            data-placeholder="Type message..."
          >
            <p><br /></p>
          </div>
          <div class="ql-clipboard" contenteditable="true" tabindex="-1"></div>
          <div class="ql-tooltip ql-hidden">
            <a
              class="ql-preview"
              rel="noopener noreferrer"
              target="_blank"
              href="about:blank"
            ></a
            ><input
              type="text"
              data-formula="e=mc^2"
              data-link="https://quilljs.com"
              data-video="Embed URL"
            /><a class="ql-action"></a><a class="ql-remove"></a>
          </div>
        </div>
        <!--end::Message-->

        <!--begin::Attachments-->
        <div
          class="dropzone dropzone-multi px-8 py-4"
          id="kt_inbox_compose_attachments"
          style="display: none"
        >
          <div class="dropzone-items"></div>
          <div class="dz-default dz-message">
            <button class="dz-button" type="button">
              Drop files here to upload
            </button>
          </div>
        </div>
        <!--end::Attachments-->
      </div>
      <!--end::Body-->

      <!--begin::Footer-->
      <div
        class="d-flex align-items-center justify-content-between py-5 pl-8 pr-5 border-top"
      >
        <!--begin::Actions-->
        <div class="d-flex align-items-center mr-3">
          <!--begin::Send-->
          <div class="btn-group mr-4">
            <a
              class="btn btn-primary font-weight-bold px-6"
              (click)="sendMessage()"
            >
              Send
            </a>
          </div>
          <div class="btn-group mr-4">
            <a class="btn font-weight-bold px-6" (click)="resetComposer()">
              reset
            </a>
          </div>
          <!--end::Send-->

          <!--begin::Other-->
          <span
            class="btn btn-icon btn-sm btn-clean mr-2 dz-clickable"
            id="kt_inbox_compose_attachments_select"
          >
            <i class="flaticon2-clip-symbol"></i>
          </span>
          <span class="btn btn-icon btn-sm btn-clean">
            <i class="flaticon2-pin"></i>
          </span>
          <!--end::Other-->
        </div>
        <!--end::Actions-->

        <!--begin::Toolbar-->
        <div class="d-flex align-items-center">
          <span
            class="btn btn-icon btn-sm btn-clean mr-2"
            data-toggle="tooltip"
            title=""
            data-original-title="More actions"
          >
            <i class="flaticon2-settings"></i>
          </span>
          <span
            class="btn btn-icon btn-sm btn-clean"
            data-inbox="dismiss"
            data-toggle="tooltip"
            title=""
            data-original-title="Dismiss reply"
          >
            <i class="flaticon2-rubbish-bin-delete-button"></i>
          </span>
        </div>
        <!--end::Toolbar-->
      </div>
      <!--end::Footer-->
    </form>
    <!--end::Form-->
  </div>
</div>
