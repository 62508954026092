import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { TaxcasesService } from '../../../../app/services/data/taxcases.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(
    public taxcasesservice: TaxcasesService,
    public security: AngularFireAuth
  ) {}

  ngOnInit(): void {}
}
