<div class="btn-group">
  <button
    class="btn btn-light-danger font-weight-bold btn-lg dropdown-toggle"
    type="button"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    X
  </button>
  <div class="dropdown-menu">
    <a
      class="dropdown-item"
      (click)="decline('Abgelehnt ohne Grund und keine Kundeninfo', false)"
      >Ablehnen, Kunden nicht Informieren</a
    >
    <div class="dropdown-divider"></div>
    <a class="dropdown-item" (click)="decline('Doppelter Eintrag', false)"
      >Ablehnen doppelter Eintrag</a
    >
    <a class="dropdown-item" (click)="decline('Daten ungültig', false)"
      >Ablehnen Ungültig</a
    >
    <a class="dropdown-item" (click)="decline('Kunde hat wiederrufen', false)"
      >Kunde hat abgelehnt</a
    >
  </div>
</div>
