import { Component, Input } from '@angular/core';

@Component({
  selector: 'esui-calculation',
  templateUrl: './calculation.component.html',
  styleUrls: ['./calculation.component.scss'],
})
export class CalculationComponent {
  @Input()
  taxRefund?: number | null;
}
