import { PercentPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BinaryDocument,
  DOCUMENTTYPE,
  DocumentTypeFields,
  Documenttype,
  SerializedField,
  SerializedFieldType,
  SmartTask,
  getFieldSuggestions,
  getScoreForFieldsBasedOnType,
  isDOCUMENTTYPE,
} from '@expresssteuer/models';

@Component({
  selector: 'esui-document-classifier',
  templateUrl: './document-classifier.component.html',
  styleUrls: ['./document-classifier.component.scss'],
})
export class DocumentClassifierComponent {
  constructor(public percentPipe: PercentPipe) {}
  SerializedFieldType = SerializedFieldType;

  @Input() task?: SmartTask | null;
  @Input() document?: BinaryDocument<
    DOCUMENTTYPE,
    Record<string | number, unknown> | undefined
  > | null;
  @Input() downloadUrl?: string | null;
  @Input() hasOnlineChangedAfterLocalChanged?: boolean | null;
  @Input() isSaving?: boolean | null;
  @Input() isLoading?: boolean | null;
  @Input() hasChanges?: boolean | null;
  @Input() hasErrors?: boolean | null;

  @Output() ignoreClick = new EventEmitter();
  @Output() unreadableClick = new EventEmitter();
  @Output() correctClick = new EventEmitter<boolean>();
  @Output() documentTypeSelect = new EventEmitter<DOCUMENTTYPE>();
  @Output() verifyAndSaveClick = new EventEmitter<void>();

  @Output() saveClick = new EventEmitter<void>();
  @Output() discardClick = new EventEmitter<void>();

  @Output() parsedFieldChange = new EventEmitter<{
    key: string;
    value: unknown;
  }>();

  get typeSuggestions(): (TypeItem & { score?: number })[] {
    const typeSuggestions = this.document?.metadata.mldata?.predictions?.type;
    if (!typeSuggestions) {
      return this.typelist;
    }

    return this.typelist
      .map((entry) => {
        const score =
          typeSuggestions.find((ts) => ts.suggestion === entry.value)?.score ||
          0;
        const label = `${entry.label} (${this.percentPipe.transform(
          score,
          '1.0-2'
        )})`;

        return {
          ...entry,
          score,
          label,
        };
      })
      .sort((a, b) => {
        return a.score - b.score;
      });
  }

  get fields(): { [key in string | number]: SerializedField } | undefined {
    if (!this.document?.type || !isDOCUMENTTYPE(this.document?.type)) {
      return;
    }
    return DocumentTypeFields[this.document.type];
  }

  /**
   * Suggestions for the `paredFields` sorted by the current `this.document.type`.
   */
  get fieldSuggestions() {
    if (!this.document?.type) {
      return {};
    }
    return getFieldSuggestions(
      this.document?.metadata.mldata?.predictions?.fields,
      this.document?.type
    );
  }

  get meanScores(): number {
    if (!this.document?.type) {
      return 0;
    }
    return getScoreForFieldsBasedOnType(
      this.document?.metadata.mldata?.predictions?.fields,
      this.document?.type
    ).mean;
  }

  private readonly typelist: TypeItem[] = Documenttype.getLabels();

  // TODO: clean this up. not everything is used anymore since it is outsourced to document-type-select
  trackByTypeItem(index: number, item: TypeItem) {
    return index + item.value;
  }
}

export class TypeItem {
  label = '';
  value: DOCUMENTTYPE = DOCUMENTTYPE.other;
}
