import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'googleMapsLink' })
export class GoogleMapsLinkPipe implements PipeTransform {
  transform(address: string | undefined) {
    if (address) {
      return 'https://www.google.com/maps/place/' + encodeURIComponent(address);
    } else {
      return undefined;
    }
  }
}
