<div *ngIf="jobGroup" class="jobgroup">
  <div class="jobgroup__title">
    <span>
      {{ jobGroup.labelDE }}
    </span>
    <mat-icon
      class="jobgroup__icon"
      aria-hidden="false"
      aria-label="Optimization"
      >star</mat-icon
    >
  </div>
  <div class="proposed-expenses-list">
    <div
      class="proposed-expense"
      *ngFor="let expense of jobGroup.expenses"
      (click)="addExpenseClicked.next(expense)"
    >
      <div class="proposed-expense__description">
        {{ expense.description }}
      </div>
      <div class="proposed-expense__value">
        {{ expense.value }}<span class="currency">EUR</span>
      </div>
      <div class="proposed-expense__action">ADD</div>
    </div>
    <div *ngIf="!jobGroup.expenses?.length">keine Optimierungen verfügbar</div>
  </div>
</div>
