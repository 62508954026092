import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TAXCASESTATUS, TaxCase } from '@expresssteuer/models';

@Component({
  selector: 'esui-taxcase-list',
  templateUrl: './esui-taxcase-list.component.html',
  styleUrls: ['./esui-taxcase-list.component.scss'],
  animations: [
    trigger('animateDestroy', [
      state(
        'void',
        style({ transform: 'scaleY(0)', transformOrigin: 'top', height: 0 })
      ),
      transition('* => void', animate('500ms ease')),
    ]),
  ],
})
export class TaxcaseListComponent {
  TAXCASESTATUS = TAXCASESTATUS;
  statusMap: Record<TAXCASESTATUS, string> = {
    [TAXCASESTATUS.ENTRY]: 'start' as const,
    [TAXCASESTATUS.DELETED]: 'bad-end' as const,
    [TAXCASESTATUS.REJECT]: 'bad-end' as const,
    [TAXCASESTATUS.INPROGRESS]: 'ongoing' as const,
    [TAXCASESTATUS.DONE]: 'good-end' as const,
    [TAXCASESTATUS.VIRTUAL_MY]: 'unknown' as const,
  };

  @Input() taxcases: TaxCase[] = [];
  @Input() horizontal?: boolean | null = false;

  @Output() ngModelChange = new EventEmitter<TaxCase[]>();
  @Input() ngModel?: TaxCase[] | null = [];

  trackById(index: number, { id }: { id: string }) {
    return id;
  }
}
