<div class="input-group">
  <input
    type="text"
    class="form-control"
    placeholder="DE00 0000 1111 22222 3333 44"
    [(ngModel)]="bank.IBAN"
    [(value)]="bank.IBAN"
    (keyup)="bank.valid = false"
    (focusout)="validate()"
  />

  <div class="input-group-append">
    <span class="input-group-text">
      <span class="label label-xl label-dot label-success" *ngIf="isValid">
      </span>
      <span class="label label-xl label-dot label-danger" *ngIf="!isValid">
      </span>
    </span>
  </div>
</div>
<span class="bank-detail form-text text-muted"
  >{{ bank.bankName }} {{ bank.bankBIC }} {{ bank.bankCity }}
  {{ bank.bankPLZ }}</span
>
