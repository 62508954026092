<esui-section-column-scaffold
  [header]="{
    title: 'tasks'
  }"
>
  <ng-container content>
    <mat-selection-list
      [multiple]="false"
      [(ngModel)]="ngModel"
      (ngModelChange)="ngModelChange.next($event)"
    >
      <ng-container *ngFor="let task of tasks; trackBy: trackByRef">
        <mat-list-option [value]="task" @animateDestroy>
          <esui-task-list-item
            [task]="task"
            [users]="usersPerTasks?.get(task)"
          ></esui-task-list-item>
        </mat-list-option>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-selection-list>
  </ng-container>
</esui-section-column-scaffold>
