/* eslint-disable */

export interface VaSt_LErsL {
  VaSt_LErsL: VaSt_LErsL_CType;
}

export interface VaSt_LErsL_CType {
  /**
   * Jahr der Zahlung/Erstattung
   */
  Zuflussjahr?: string;
  /**
   * Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung
   */
  Eingangsdatum?: string;
  LeistungsEmpfaenger?: LeistungsEmpfaengerM1956975938_CType;
  /**
   * @maxItems 11
   */
  Leistung?: LeistungM1956975938_CType | LeistungM1956975938_CType[];
  version: string;
}

export interface LeistungsEmpfaengerM1956975938_CType {
  /**
   * Identifikationsnummer
   */
  IdNr?: string;
}

export interface LeistungM1956975938_CType {
  /**
   * Leistungsbetrag
   */
  Betrag?: string;
  /**
   * Art der Leistung
   */
  Art?: string;
  /**
   * @maxItems 200
   */
  LeistungsPflichtiger?:
    | LeistungsPflichtiger_147305219_CType
    | LeistungsPflichtiger_147305219_CType[];
}

export interface LeistungsPflichtiger_147305219_CType {
  /**
   * Leistungserbringer
   */
  Name?: string;
  /**
   * @maxItems 183
   */
  Zeitraum?: ZeitraumM1481411950_CType | ZeitraumM1481411950_CType[];
}

export interface ZeitraumM1481411950_CType {
  /**
   * Beginn
   */
  Beginn?: string;
  /**
   * Ende
   */
  Ende?: string;
}
