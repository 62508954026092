import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsStoreStateIndicatorModule } from '../es-store-state-indicator/es-store-state-indicator.module';
import { EsuiJobgroupOptimizeCurrentExpensesComponent } from './esui-jobgroup-optimize-current-expenses/esui-jobgroup-optimize-current-expenses.component';
import { EsuiJobgroupOptimizeProposedExpensesComponent } from './esui-jobgroup-optimize-proposed-expenses/esui-jobgroup-optimize-proposed-expenses.component';
import { EsuiJobgroupOptimizeComponent } from './esui-jobgroup-optimize.component';

@NgModule({
  declarations: [
    EsuiJobgroupOptimizeComponent,
    EsuiJobgroupOptimizeCurrentExpensesComponent,
    EsuiJobgroupOptimizeProposedExpensesComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    EsCardKeyModule,
    EsStoreStateIndicatorModule,
    MatTooltipModule,
  ],
  exports: [
    EsuiJobgroupOptimizeComponent,
    EsuiJobgroupOptimizeProposedExpensesComponent,
  ],
})
export class EsuiJobgroupOptimizeModule {}
