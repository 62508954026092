import { Component, Inject, Input } from '@angular/core';
import {
  HrefBuilder,
  HREF_BUILDER,
} from '../href-builder/href-builder.provider';

export interface TasksOnScreen {
  [userId: string]: {
    user?: {
      displayName?: string | null;
      email?: string | null;
      photoUrl?: string | null;
    };
    sessions: {
      [userSessionId: string]: {
        taskIds?: string[];
      };
    };
  };
}

@Component({
  selector: 'esui-user-tasks-activity',
  templateUrl: './esui-user-tasks-activity.component.html',
  styleUrls: ['./esui-user-tasks-activity.component.scss'],
})
export class EsuiUserTasksActivityComponent {
  @Input() tasksOnScreen?: TasksOnScreen | null;

  trackByStringified(val: any): string {
    return JSON.stringify(val);
  }

  /**
   * Create a color for a hash
   * Based on https://stackoverflow.com/a/16348977
   */
  colorFor(val: string) {
    let hash = 0;
    for (let i = 0; i < val.length; i++) {
      hash = val.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += ('00' + value.toString(16)).substring(2);
    }
    return colour;
  }

  taskInTaskIslandUrl(taskRef: string): string | null | undefined {
    return this.builder.taskIsland?.classic?.task?.(taskRef);
  }

  constructor(@Inject(HREF_BUILDER) private builder: HrefBuilder) {}
}
