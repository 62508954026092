<div
  *ngIf="
    taxcase &&
    taxcase.taxform &&
    taxcase.taxform.current &&
    taxcase.taxform.current.calculation
  "
  data-toggle="popover"
  title="Kunden Berechnung (todo)"
  data-html="true"
>
  <span
    class="label label-inline label-square label-light-dark"
    *ngIf="taxcase.taxform.current.calculation.offer.taxRefund > 10"
    >{{
      taxcase.taxform.current.calculation.offer.taxRefund | currency : 'EUR'
    }}</span
  >
  <span
    class="label label-inline label-square label-light-danger"
    *ngIf="taxcase.taxform.current.calculation.offer.taxRefund <= 0"
    >{{
      taxcase.taxform.current.calculation.offer.taxRefund | currency : 'EUR'
    }}</span
  >
</div>
