<!--begin::Card-->
<div class="card card-custom">
  <!--begin::Header-->
  <div class="card-header flex-wrap border-0 pt-6 pb-0">
    <div class="card-title">
      <h3 class="card-label">
        Kundenliste
        <span class="d-block text-muted pt-2 font-size-sm"
          >Alle aktiven Kunden</span
        >
      </h3>
    </div>
    <div class="card-toolbar"></div>
  </div>
  <!--end::Header-->

  <!--begin::Body-->
  <div class="card-body">
    <!--begin: Datatable-->
    <div
      class="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded"
      id="kt_datatable"
    >
      <table class="datatable-table" style="display: block">
        <thead class="datatable-head">
          <tr class="datatable-row" style="left: 0px">
            <th></th>
            <th
              data-field="OrderID"
              class="datatable-cell datatable-cell-sort datatable-cell-sorted"
              data-sort="desc"
            >
              <span style="width: 250px"
                >Kunde<i class="flaticon2-arrow-down"></i
              ></span>
            </th>
            <th data-field="Country" class="datatable-cell datatable-cell-sort">
              <span style="width: 250px">Kontakt</span>
            </th>
            <th
              data-field="ShipDate"
              class="datatable-cell datatable-cell-sort"
            >
              <span style="width: 100px">Alter</span>
            </th>
            <th
              data-field="CompanyName"
              class="datatable-cell datatable-cell-sort"
            >
              <span style="width: 162px">Fälle im System</span>
            </th>
            <th data-field="Status" class="datatable-cell datatable-cell-sort">
              <span style="width: 80px">Status</span>
            </th>
            <th
              data-field="Actions"
              data-autohide-disabled="false"
              class="datatable-cell datatable-cell-sort"
            >
              <span style="width: 130px"></span>
            </th>
          </tr>
        </thead>
        <tbody class="datatable-body" *ngIf="clients$ | async; let clients">
          <tr
            data-row="0"
            class="datatable-row"
            style="left: 0px"
            *ngFor="let client of clients"
          >
            <td>
              <span class="label label-square label-success mr-2">{{
                client.lastname | slice : 0 : 1
              }}</span>
            </td>
            <td class="datatable-cell-sorted datatable-cell">
              <span style="width: 250px">
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-40 symbol-sm flex-shrink-0"></div>
                  <div class="ml-4">
                    <div
                      class="text-dark-75 font-weight-bolder font-size-lg mb-0 text-hover-primary"
                      [routerLink]="['/client', client.id]"
                    >
                      {{ client.lastname }} {{ client.firstname }}
                    </div>
                    <a
                      href="/client/{{ client.id }}"
                      class="text-muted font-weight-bold text-hover-primary"
                      >{{ client.addressClient.formatedAddress }}
                    </a>
                  </div>
                </div>
              </span>
            </td>
            <td data-field="Country" aria-label="China" class="datatable-cell">
              <span style="width: 250px">
                <div class="font-weight-bolder font-size-lg mb-0">
                  {{ client.email }}
                </div>
                <!-- <div
                  class="font-weight-bold text-muted"
                  *ngIf="toDate(client.created)"
                >
                  
                </div> -->
              </span>
            </td>
            <td
              data-field="ShipDate"
              aria-label="6/25/2016"
              class="datatable-cell"
            >
              <span style="width: 100px" *ngIf="client.birthdate">
                <!-- <div
                  class="font-weight-bolder text-primary mb-0"
                  *ngIf="toDate(client.birthdate)"
                >
                  
                </div> -->
              </span>

              <span style="width: 100px">
                <div
                  class="font-weight-bolder text-primary mb-0"
                  *ngIf="client.metadata?.created"
                >
                  {{ client.metadata.created.toDate() | timeago }}
                </div>
                <div class="text-muted">--</div>
              </span>
            </td>
            <td data-field="CompanyName" class="datatable-cell">
              <span style="width: 162px">
                <div class="font-weight-bold text-muted">
                  <a
                    [routerLink]="[
                      '/client/',
                      client.id,
                      '/taxcase/',
                      tc.caseId,
                      'information'
                    ]"
                    class="btn btn-sm mr-2 py-2 px-1 my-1"
                    [ngClass]="{
                      'btn-warning': tc.status
                        ? tc.status.current === 'reject'
                        : false,
                      'btn-primary': tc.status
                        ? tc.status.current === 'inProgress'
                        : true,
                      'btn-success': tc.status
                        ? tc.status.current === 'done'
                        : false
                    }"
                    *ngFor="let tc of client.taxForms"
                    >{{ tc.taxYear }}</a
                  >
                </div>
              </span>
            </td>
            <td data-field="Status" aria-label="4" class="datatable-cell">
              <span style="width: 80px"
                ><span
                  class="label label-lg font-weight-bold label-light-success label-inline"
                  >{{ client.status }}</span
                ></span
              >
            </td>
            <td
              data-field="Actions"
              data-autohide-disabled="false"
              aria-label="null"
              class="datatable-cell"
            >
              <span style="overflow: visible; position: relative; width: 130px">
                <a
                  id="row_{{ client.id }}"
                  [routerLink]="['/client/', client.id, '/information']"
                  class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  title="Edit details"
                >
                  <span class="svg-icon svg-icon-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                        ></path>
                        <path
                          d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </a>
                <a
                  [swal]="{
                    title: 'Diesen Kunden löschen?',
                    text:
                      'Der Kunde ' +
                      client.firstname +
                      ' ' +
                      client.lastname +
                      ' und all dazugehörenden Steuerformulare werden als gelöscht markiert.',
                    type: 'warning',
                    showCancelButton: true
                  }"
                  (showCancelButton)="(true)"
                  (focusCancel)="(true)"
                  (confirm)="deleteCustomer(client)"
                  class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                  title="Delete"
                >
                  <span class="svg-icon svg-icon-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                        ></path>
                        <path
                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                          fill="#000000"
                          opacity="0.3"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <!--
                        <div class="datatable-pager datatable-paging-loaded">
                            <ul class="datatable-pager-nav">
                                <li><a title="First"
                                        class="datatable-pager-link datatable-pager-link-first datatable-pager-link-disabled"
                                        data-page="1" disabled="disabled"><i class="flaticon2-fast-back"></i></a></li>
                                <li><a title="Previous"
                                        class="datatable-pager-link datatable-pager-link-prev datatable-pager-link-disabled"
                                        data-page="1" disabled="disabled"><i class="flaticon2-back"></i></a></li>
                                <li style=""></li>
                                <li style="display: none;"><input type="text" class="datatable-pager-input form-control"
                                        title="Page number"></li>
                                <li><a class="datatable-pager-link datatable-pager-link-number datatable-pager-link-active"
                                        data-page="1" title="1">1</a></li>
                                <li><a class="datatable-pager-link datatable-pager-link-number" data-page="2"
                                        title="2">2</a></li>
                                <li><a class="datatable-pager-link datatable-pager-link-number" data-page="3"
                                        title="3">3</a></li>
                                <li><a class="datatable-pager-link datatable-pager-link-number" data-page="4"
                                        title="4">4</a></li>
                                <li><a class="datatable-pager-link datatable-pager-link-number" data-page="5"
                                        title="5">5</a></li>
                                <li></li>
                                <li><a title="Next" class="datatable-pager-link datatable-pager-link-next"
                                        data-page="2"><i class="flaticon2-next"></i></a></li>
                                <li><a title="Last" class="datatable-pager-link datatable-pager-link-last"
                                        data-page="35"><i class="flaticon2-fast-next"></i></a></li>
                            </ul>
                            <div class="datatable-pager-info">
                                <div class="dropdown bootstrap-select datatable-pager-size" style="width: 60px;"><select
                                        class="selectpicker datatable-pager-size" title="Select page size"
                                        data-width="60px" data-container="body" data-selected="10">
                                        <option class="bs-title-option" value=""></option>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select><button type="button" tabindex="-1" class="btn dropdown-toggle btn-light"
                                        data-toggle="dropdown" role="combobox" aria-owns="bs-select-1"
                                        aria-haspopup="listbox" aria-expanded="false" title="Select page size">
                                        <div class="filter-option">
                                            <div class="filter-option-inner">
                                                <div class="filter-option-inner-inner">10</div>
                                            </div>
                                        </div>
                                    </button>
                                    <div class="dropdown-menu ">
                                        <div class="inner show" role="listbox" id="bs-select-1" tabindex="-1">
                                            <ul class="dropdown-menu inner show" role="presentation"></ul>
                                        </div>
                                    </div>
                                </div><span class="datatable-pager-detail">Showing 1 - 10 of 350</span>
                            </div>
                        </div>
                    </div>
                    -->
      <!--end: Datatable-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Card-->
</div>
