<div
  class="dropzone"
  appDropzone
  (hovered)="toggleHover($event)"
  (dropped)="onDrop($event)"
  [class.hovering]="isHovering"
>
  <h3>Dokument hier fallenlassen für Upload</h3>
</div>

<h3 *ngIf="files && files.length > 0">Dokumente</h3>

<div *ngFor="let file of files">
  <app-upload-task [file]="file"></app-upload-task>
</div>
