<table
  mat-table
  [dataSource]="list$ ?? []"
  matSort
  matSortActive="created"
  matSortDisableClear
  matSortDirection="desc"
  #sort="matSort"
  (matSortChange)="handleSortChange($event)"
  [matSortDirection]="sort?.direction ?? ''"
  [matSortActive]="sort?.active ?? ''"
>
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let row">{{ row.id }}</td>
  </ng-container>
  <ng-container matColumnDef="year">
    <th mat-header-cell *matHeaderCellDef>year</th>
    <td mat-cell *matCellDef="let row">{{ row.year }}</td>
  </ng-container>
  <ng-container matColumnDef="generator">
    <th mat-header-cell *matHeaderCellDef>generator</th>
    <td mat-cell *matCellDef="let row">
      {{ row.taxform.current.taxForm.generator }}
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>status</th>
    <td mat-cell *matCellDef="let row">{{ row.metadata.status.current }}</td>
  </ng-container>
  <ng-container matColumnDef="substatus">
    <th mat-header-cell *matHeaderCellDef>substatus</th>
    <td mat-cell *matCellDef="let row">
      {{ row.metadata.status.substatus ?? '--' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="displayName">
    <th mat-header-cell *matHeaderCellDef>displayName</th>
    <td mat-cell *matCellDef="let row">
      {{ row.taxform.current.taxForm.information.firstname }},
      {{ row.taxform.current.taxForm.information.lastname }}
    </td>
  </ng-container>

  <ng-container matColumnDef="expectedTaxReturn">
    <th mat-header-cell *matHeaderCellDef>expectedTaxReturn</th>
    <td mat-cell *matCellDef="let row">
      {{ row.taxform.current.expectedTaxReturn ?? '--' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="metadata.created">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
      created
    </th>
    <td mat-cell *matCellDef="let row">
      {{ row.metadata.created | asDate | date : 'medium' }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">no data</td>
  </tr>
</table>

<esui-firestore-paginator
  [pageSize]="pageSize"
  (pageSizeChange)="pageSizeChange.next($event)"
  (nextClick)="nextClick.next($event)"
  (previousClick)="previousClick.next($event)"
  (startClick)="startClick.next($event)"
>
</esui-firestore-paginator>
