<div
  class="btn-group btn-group-lg"
  role="group"
  aria-label="Large button group"
>
  <button
    type="button"
    class="btn btn-outline-secondary font-weight-bold"
    [class.active]="disability === '25-30'"
    (click)="disability = '25-30'"
  >
    25
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary font-weight-bold"
    [class.active]="disability === '35-40'"
    (click)="disability = '35-40'"
  >
    35
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary font-weight-bold"
    [class.active]="disability === '45-50'"
    (click)="disability = '45-50'"
  >
    45
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary font-weight-bold"
    [class.active]="disability === '55-60'"
    (click)="disability = '55-60'"
  >
    55
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary font-weight-bold"
    [class.active]="disability === '65-70'"
    (click)="disability = '65-70'"
  >
    65
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary font-weight-bold"
    [class.active]="disability === '75-80'"
    (click)="disability = '75-80'"
  >
    75
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary font-weight-bold"
    [class.active]="disability === '85-90'"
    (click)="disability = '85-90'"
  >
    85
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary font-weight-bold"
    [class.active]="disability === '95-100'"
    (click)="disability = '95-100'"
  >
    95-100
  </button>
</div>
