import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  TaxCase,
  TAXCASESTATUS,
  TAXCASESTATUS_REJECT,
} from '@expresssteuer/models';
import { AuthenticationService } from '../../../../../app/services/security/authentication.service';

@Component({
  selector: 'app-button-status-entry-decline',
  templateUrl: './button-status-entry-decline.component.html',
  styleUrls: ['./button-status-entry-decline.component.scss'],
})
export class ButtonStatusEntryDeclineComponent implements OnInit {
  constructor(private security: AuthenticationService) {}

  taxCaseValue: TaxCase;
  TAXCASESTATUS_REJECT = TAXCASESTATUS_REJECT;

  @Output()
  taxCaseChange = new EventEmitter<TaxCase>();

  @Input()
  get taxcase(): TaxCase {
    return this.taxCaseValue;
  }

  set taxcase(val: TaxCase) {
    this.taxCaseValue = val;
    this.taxCaseChange.emit(this.taxCaseValue);
  }

  public decline(
    reason: string = '',
    informClient: boolean = true,
    reject: TAXCASESTATUS_REJECT | null = null
  ) {
    this.taxcase.metadata.status.current = TAXCASESTATUS.REJECT;
    this.taxcase.metadata.status.substatus = reject;

    // this.taxcase.metadata.status.history.push(history);
  }

  ngOnInit(): void {}
}
