import { Component, Input } from '@angular/core';
import { EXPENSETYPE, IAdditionalExpense } from '@expresssteuer/models';

@Component({
  selector: 'esui-jobgroup-optimize-current-expenses',
  templateUrl: './esui-jobgroup-optimize-current-expenses.component.html',
  styleUrls: ['./esui-jobgroup-optimize-current-expenses.component.scss'],
})
export class EsuiJobgroupOptimizeCurrentExpensesComponent {
  OPTIMIZATION_TYPE = EXPENSETYPE.OPTIMIZATION;

  @Input() additionalExpenses?: IAdditionalExpense[] | null;
  @Input() name?: string | null;
  @Input() commutingCost?: number | null;
  @Input() homeofficeCost?: number | null;
}
