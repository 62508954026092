import { Component, EventEmitter, Output } from '@angular/core';
import { TAXCASESTATUS } from '@expresssteuer/models';
import { TaxcasesService } from '../../../../app/services/data/taxcases.service';

@Component({
  selector: 'app-taxcase-pagination',
  templateUrl: './taxcase-pagination.component.html',
  styleUrls: ['./taxcase-pagination.component.scss'],
})
export class TaxcasePaginationComponent {
  constructor(public taxcaseservice: TaxcasesService) {
    this.currentPage = 1;
  }

  // count
  private _total: number = 255;
  public get total(): number {
    return this._total;
  }
  public set total(val: number) {
    this._total = val;
  }

  private maxpages = 20;
  public getPages(): number[] {
    const pages: number[] = [];
    const availablePages = Math.ceil(this.records / 20) + 1;

    const start =
      this.taxcaseservice.pagination.currentPage > this.maxpages / 2 + 1
        ? this.taxcaseservice.pagination.currentPage - this.maxpages / 2
        : 1;
    const end =
      start + this.maxpages > availablePages
        ? availablePages
        : start + this.maxpages;

    if (start) {
      for (let index = start; index < end; index++) {
        pages.push(index);
      }
    } else {
      for (
        let index = this.taxcaseservice.pagination.currentPage - this.maxpages;
        index < availablePages;
        index++
      ) {
        pages.push(index);
      }
    }

    return pages;
  }

  public get records(): number {
    try {
      switch (this.taxcaseservice.currentStatus) {
        case TAXCASESTATUS.ENTRY:
          return this.taxcaseservice.statisticcountersMain.entry;
        case TAXCASESTATUS.DONE:
          return this.taxcaseservice.statisticcountersMain.done;
        case TAXCASESTATUS.INPROGRESS:
          if (this.taxcaseservice.currentSubStatus === null) {
            return this.taxcaseservice.statisticcountersMain.inProgress;
          }
        case TAXCASESTATUS.REJECT:
          if (this.taxcaseservice.currentSubStatus === null) {
            return this.taxcaseservice.statisticcountersMain.reject;
          }
      }
      const stat =
        this.taxcaseservice.statisticcountersSub[
          this.taxcaseservice.currentSubStatus
        ];
      return stat;
    } catch (err) {
      return 1;
    }
  }

  // records per page
  private _recordsPerPage: number = 20;
  public get recordsPerPage(): number {
    return this._recordsPerPage;
  }
  public set recordsPerPage(val: number) {
    this._recordsPerPage = val;
  }

  @Output()
  public goto = new EventEmitter<number>();
  public gotoPage(page: number): void {
    this.goto.emit(page);
  }

  @Output()
  public next = new EventEmitter<boolean>();
  public nextPage() {
    this.next.emit();
  }
  @Output()
  public previous = new EventEmitter<boolean>();
  public previousPage() {
    this.previous.emit();
  }

  @Output()
  public first = new EventEmitter<boolean>();
  public firstPage() {
    this.first.emit();
  }

  // start and endpoint
  private _startAt: number = 0;
  public get currentStartAt(): number {
    return this._startAt;
  }

  private _endAt: number = 20;
  public get currentEndAt(): number {
    return this._endAt;
  }

  // current page
  private _currentPage: number = 1;
  public set currentPage(val: number) {
    this._currentPage = val;

    // const se: StartEnd = new StartEnd(this._startAt, this._endAt);
  }
  public get currentPage(): number {
    return this._currentPage;
  }

  public get pages(): number {
    return Math.ceil(this._total / this._recordsPerPage);
  }

  public get pagesToRender(): number[] {
    const returnPages: number[] = [];

    for (let p = 0; p < this.pages; p++) {
      returnPages.push(p);
    }

    return returnPages;
  }
}
