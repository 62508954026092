<mat-form-field>
  <mat-label>Dokumentenart</mat-label>
  <mat-select
    [ngModel]="documentType"
    (ngModelChange)="documentTypeSelect.next($event)"
    name="documenttype"
  >
    <mat-option
      *ngFor="let option of typeSuggestions; trackBy: trackByTypeItem"
      [value]="option.value"
    >
      {{ option.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
