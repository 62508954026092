import { SchemaProperty } from './vast';

export const VastRueSchema: SchemaProperty = {
  title: 'VaSt_RUE-202201.xsd',
  $schema: 'http://json-schema.org/schema#',

  type: 'object',
  properties: {
    VaSt_RUE: {
      type: 'object',
      description: 'Basisversorgung (Rürup-Rente)',
      properties: {
        Eingangsdatum: {
          description:
            'Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung',
          type: 'string',
        },
        BetroffenerFall: {
          type: 'object',
          properties: {
            Meldejahr: {
              description: 'Meldejahr',
              type: 'string',
            },
            NatPerson: {
              type: 'object',
              properties: {
                IdNr: {
                  description: 'Identifikationsnummer',
                  type: 'string',
                },
                Titel: {
                  description: 'Titel',
                  type: 'string',
                },
                Vorname: {
                  description: 'Vorname',
                  type: 'string',
                },
                NamensVorsatz: {
                  description: 'Namensvorsatz',
                  type: 'string',
                },
                Name: {
                  description: 'Name',
                  type: 'string',
                },
                NamensZusatz: {
                  description: 'Namenszusatz',
                  type: 'string',
                },
                Blockname: {
                  description: 'Name',
                  type: 'string',
                },
              },
            },
          },
        },
        Versicherung: {
          type: 'object',
          properties: {
            Name: {
              description: 'Name der Versicherung',
              type: 'string',
            },
          },
        },
        Vertragsdaten: {
          type: 'object',
          properties: {
            EinwilligDat: {
              description: 'Einwilligungsdatum (zur Datenübermittlung)',
              type: 'string',
            },
            VertragsNr: {
              description: 'Vertragsnummer',
              type: 'string',
            },
            ZertifiNr: {
              description: 'Zertifizierungsnummer',
              type: 'string',
            },
            Beitragsdaten: {
              type: 'object',
              properties: {
                Werte: {
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {
                      ZahlJahr: {
                        description: 'Zahljahr',
                        type: 'string',
                      },
                      Betrag: {
                        description: 'Höhe der geleisteten Beiträge',
                        type: 'number',
                        stringifier: 'currency',
                      },
                      Waehrung: {
                        description: 'Währung',
                        type: 'string',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        version: {
          type: 'string',
          enum: ['202201'],
        },
      },
      required: ['version'],
    },
  },
  required: ['VaSt_RUE'],
};
