import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TaxAdvisor } from '@expresssteuer/models';

@Component({
  selector: 'esui-taxadvisor',
  templateUrl: './taxadvisor.component.html',
  styleUrls: ['./taxadvisor.component.scss'],
})
export class EsuiTaxadvisorComponent {
  @Input()
  /**
   * If set, allows to select and show the taxAdvisor
   */
  taxadvisors?: TaxAdvisor[] | null;

  @Input()
  label = 'Steuerberatung';

  /**
   * If disabled taxadvisors can be selected
   */
  @Input()
  disableInactive = true;

  @Input()
  taxAdvisorId?: string;
  @Output()
  taxAdvisorIdChange = new EventEmitter<string>();
}
