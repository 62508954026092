import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateString } from '@expresssteuer/models';
import { Mode } from '../es-client/components/client-editor/client-editor.component';

export interface PartnerUpdateInputData {
  firstName: string;
  lastName: string;
  birthdate?: DateString;
  // taxId?: string;
}

export interface Partner {
  partnerId?: string | null | undefined;
  firstName?: string;
  lastName?: string;
  years?: string[];
  birthdate?: DateString | null;
  taxId?: number;
}

@Component({
  selector: 'esui-partner',
  templateUrl: './esui-partner.component.html',
  styleUrls: ['./esui-partner.component.scss'],
})
export class EsuiPartnerComponent {
  @Input() partner?: Partner | null | undefined;
  @Output() partialPartnerUpdateInputData = new EventEmitter<
    Partial<PartnerUpdateInputData>
  >();

  Mode = Mode;
  @Input() mode?: Mode;
  @Input() hasUnsavedChanges?: boolean | null;
  @Input() isSaving?: boolean | null;
  @Input() erroneous?: boolean | null;
  @Output() editClick = new EventEmitter<void>();
  @Output() saveClick = new EventEmitter<void>();
  @Output() discardClick = new EventEmitter<void>();
}
