export class Bank {
  validated = false;
  valid?: boolean;
  IBAN = '';
  bankCode = '';
  bankName = '';
  bankBIC = '';
  bankPLZ = '';
  bankCity = '';

  public static getTemplate(): Bank {
    return {
      validated: false,
      valid: false,
      IBAN: '',
      bankCode: '',
      bankName: '',
      bankBIC: '',
      bankPLZ: '',
      bankCity: '',
    };
  }
}

export function maskIban(iban: string) {
  return iban.length < 8
    ? Array(8).join('*')
    : iban.substring(0, 2) +
        Array(iban.length - 5).join('*') +
        iban.substring(iban.length - 3);
}
