import { Pipe, PipeTransform } from '@angular/core';

type Nil = null | undefined;

@Pipe({
  name: 'isNil',
})
export class IsNilPipe implements PipeTransform {
  transform<T>(value?: T | null): value is Nil {
    return value === null || value === undefined;
  }
}
