import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, defer, Observable, Subject } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';
import { OnlineStatusService } from './online-status.service';

/**
 * TODO WIP: not yet tested as it requires the `database`.
 * ```
// refactor to https://firebase.google.com/docs/firestore/solutions/presence#web_2
userStatusFirestoreRef.onSnapshot(function(doc) {
    var isOnline = doc.data().state == 'online';
    // ... use isOnline
});
 * ```
 */
@Injectable()
export class FirebaseOnlineStatusService implements OnlineStatusService {
  constructor(private afs: AngularFirestore) {}

  private subscriptionCount$ = new BehaviorSubject<number>(0);

  private subject$ = new Subject<boolean>();
  private connectedRef = this.afs.firestore.app
    .database()
    .ref('.info/connected');

  private callback?: ReturnType<
    FirebaseOnlineStatusService['connectedRef']['on']
  >;

  public connectionState$: Observable<boolean> = this.subject$.pipe(
    (source$) => {
      return defer(() => {
        this.subscriptionCount$.next(this.subscriptionCount$.getValue() + 1);
        this.onCountUpdate();
        return source$;
      }).pipe(
        finalize(() => {
          this.subscriptionCount$.next(this.subscriptionCount$.getValue() - 1);
          this.onCountUpdate();
        })
      );
    },
    shareReplay(1)
  );

  private onCountUpdate() {
    const count = this.subscriptionCount$.getValue();
    if (count === 1) {
      this.onStart();
    }
    if (count === 0) {
      this.onEnd();
    }
  }

  private onStart() {
    this.callback = this.connectedRef.on(
      'value',
      (snap: { val: () => boolean }) => {
        if (snap.val() === true) {
          console.log('connected');
          this.subject$.next(true);
        } else {
          console.log('not connected');
          this.subject$.next(false);
        }
      }
    );
  }

  private onEnd() {
    if (this.callback) {
      this.connectedRef.off('value', this.callback);
    }
  }
}
