import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAdditionalExpense, JobGroup } from '@expresssteuer/models';

@Component({
  selector: 'esui-jobgroup-optimize-proposed-expenses',
  templateUrl: './esui-jobgroup-optimize-proposed-expenses.component.html',
  styleUrls: ['./esui-jobgroup-optimize-proposed-expenses.component.scss'],
})
export class EsuiJobgroupOptimizeProposedExpensesComponent {
  @Input() jobGroup?: JobGroup | null;
  @Output() addExpenseClicked = new EventEmitter<IAdditionalExpense>();
}
