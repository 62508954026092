<div class="header">
  <mat-icon>tag</mat-icon>
  <h2>
    <code>{{ clientId }}</code>
  </h2>
  <span class="spacer"></span>
  <esui-anchor-menu [clientId]="clientId"></esui-anchor-menu>
</div>

<ng-container>
  <div class="area">
    <esui-action-box [status]="nameData?.status">
      <mat-form-field>
        <mat-label>first name</mat-label>
        <esui-text-input
          [ngModel]="nameData?.firstname"
          (ngModelChange)="nameDataPartChange({ firstname: $event })"
        ></esui-text-input>
      </mat-form-field>

      <mat-form-field>
        <mat-label>last name</mat-label>
        <esui-text-input
          [ngModel]="nameData?.lastname"
          (ngModelChange)="nameDataPartChange({ lastname: $event })"
        ></esui-text-input>
      </mat-form-field>
    </esui-action-box>
  </div>

  <div class="area">
    <esui-action-box [status]="birthdateData?.status">
      <mat-form-field>
        <mat-label>birthdate</mat-label>
        <esui-date-input
          [ngModel]="birthdateData?.birthdate"
          (ngModelChange)="birthdateDataPartChange({ birthdate: $event })"
        ></esui-date-input>
      </mat-form-field>
    </esui-action-box>
  </div>

  <div class="area">
    <esui-action-box [status]="addressData?.status">
      <mat-form-field>
        <mat-label>home address</mat-label>
        <esui-address-input
          [ngModel]="addressData?.address"
          (ngModelChange)="addressDataPartChange({ address: $event })"
          #el="ngModel"
        ></esui-address-input>
        <mat-error>Please provide all values</mat-error>
      </mat-form-field>

      <mat-checkbox
        color="primary"
        [ngModel]="addressData?.isCurrent ?? false"
        (ngModelChange)="addressDataPartChange({ isCurrent: $event })"
        >is current</mat-checkbox
      >
      <mat-form-field>
        <mat-label>years</mat-label>
        <mat-checkbox
          color="primary"
          matSuffix
          [ngModel]="!!addressData?.allYears"
          (ngModelChange)="addressDataPartChange({ allYears: $event })"
          labelPosition="before"
        >
          all
        </mat-checkbox>

        <mat-select
          [disabled]="!availableTaxcaseYears"
          *ngIf="!addressData?.allYears"
          [ngModel]="addressData?.forYears"
          (ngModelChange)="addressDataPartChange({ forYears: $event })"
          multiple
        >
          <mat-option
            *ngFor="let year of availableTaxcaseYears"
            [value]="year"
            >{{ year }}</mat-option
          >
        </mat-select>

        <esui-text-input
          disabled
          *ngIf="addressData?.allYears"
          ngModel="all"
        ></esui-text-input>
      </mat-form-field>
    </esui-action-box>
  </div>

  <div class="area">
    <esui-action-box [status]="taxIdData?.status">
      <mat-form-field>
        <mat-label>tax ID</mat-label>
        <esui-text-input
          esuiValidTaxId
          [ngModel]="taxIdData?.taxId"
          (ngModelChange)="taxIdDataPartChange({ taxId: $event })"
        ></esui-text-input>
      </mat-form-field>
    </esui-action-box>
  </div>

  <div class="area">
    <esui-action-box [status]="taxnumberData?.status">
      <mat-form-field>
        <mat-label>tax number</mat-label>
        <esui-text-input
          [ngModel]="taxnumberData?.taxnumber"
          (ngModelChange)="taxnumberDataPartChange({ taxnumber: $event })"
        ></esui-text-input>
      </mat-form-field>
    </esui-action-box>
  </div>

  <div class="area">
    <esui-action-box [status]="financeDepartmentIdData?.status">
      <mat-form-field>
        <mat-label>financial department ID</mat-label>
        <esui-text-input
          esuiValidFinanceDepartmentIdValidator
          [ngModel]="financeDepartmentIdData?.financeDepartmentId"
          (ngModelChange)="
            financeDepartmentIdDataPartChange({
              financeDepartmentId: $event
            })
          "
        ></esui-text-input>
      </mat-form-field>
    </esui-action-box>
  </div>

  <!-- TODO -->
  <!-- <div class="area">
      <esui-action-box>
        <mat-form-field>
          <mat-label>taxadvisory</mat-label>
          <esui-text-input>
            
          </esui-text-input>
        </mat-form-field>
      </esui-action-box>
    </div> -->
</ng-container>
