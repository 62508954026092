import { number as numberZod, string as stringZod } from 'zod';

export class TaxnumberHelper {
  /**
   * removes 0 at place 4
   * @param taxNumber 13 digit taxnumber
   * @returns unified 12 digit taxnumber
   */
  convertThirteenToUnifiedTwelve(taxNumber: string): string {
    if (taxNumber.length === 13) {
      return taxNumber.slice(0, 4) + taxNumber.slice(5, taxNumber.length);
    } else {
      throw new Error('taxnumber length not 13');
    }
  }

  convertThirteenToLocal(taxNumber: string, taxOfficeId: string) {
    const shortenedTaxNumber = this.convertThirteenToUnifiedTwelve(taxNumber);
    if (shortenedTaxNumber.slice(0, 2) === taxOfficeId.slice(0, 2)) {
      return shortenedTaxNumber.slice(2, shortenedTaxNumber.length);
    }
    if (shortenedTaxNumber.slice(0, 1) === taxOfficeId.slice(0, 1)) {
      return shortenedTaxNumber.slice(1, shortenedTaxNumber.length);
    }
    throw new Error('cannot convert taxnumber with given properties');
  }

  validityCheck(taxNumber: string) {
    return taxNumber.length > 8;
  }

  validityCheckUniqueTaxNumber(
    taxNumber: string | unknown,
    forFinanceDepId?: { financeDepartmentId: string | number }
  ) {
    const inputIsOk =
      typeof taxNumber === 'string' || typeof taxNumber === 'number';
    if (!inputIsOk) {
      return false;
    }
    const taxnumberAsStr =
      typeof taxNumber === 'number' ? taxNumber.toString() : taxNumber;

    const sanitizedTaxNumber = taxnumberAsStr.replace('/', '');
    const lengthValid = sanitizedTaxNumber.length === 13;
    let financeOfficeIdMatch = true;
    if (forFinanceDepId) {
      financeOfficeIdMatch =
        `${forFinanceDepId.financeDepartmentId}` === taxnumberAsStr.slice(0, 4);
    }
    return lengthValid && financeOfficeIdMatch;
  }
}

export const TaxNumberZod = stringZod().or(numberZod());
// .pipe(
// TODO
// customZod<string | number>((val) => {
//   if (!(typeof val === 'string' || typeof val === 'number')) {
//     return false;
//   }
//   return validateFinanceDepartmentId(val);
// })
// );
