import { Component, Input } from '@angular/core';

@Component({
  selector: 'esui-tax-calculation',
  templateUrl: './tax-calculation.component.html',
  styleUrls: ['./tax-calculation.component.scss'],
})
export class TaxCalculationComponent {
  public columns = ['labelDe', 'paid', 'calculated'];

  @Input() year?: string | null;

  @Input() elevated?: boolean | null = true;

  @Input()
  public rows: TaxCalculationRow[] | undefined | null;
  @Input()
  public expectedReturn: number | undefined | null;
}

export interface TaxCalculationRow {
  id: string;
  labelDe: string;
  paid?: number;
  calculated?: number;
}
