import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  RequestDeletionInput,
  RequestDeletionOutput,
} from '@expresssteuer/client-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class RequestDeletion {
  constructor(public afFunctions: AngularFireFunctions) {}

  /**
   * Marks the client to be deleted. Due to some restriction to data retention we cannot directly delete all data we have of a client. Depending on the status of theirs taxcases different data hast to be deleted or marked for deletion after a specific time frame.
   *
   * @api summary request account deletion
   * @api consumer mobileapp
   * @api input zod:RequestDeletionInputZod:@expresssteuer/client-api-interfaces
   */
  call(input: RequestDeletionInput) {
    console.log('Calling client-requestDeletion');
    return this.afFunctions.httpsCallable<
      RequestDeletionInput,
      RequestDeletionOutput
    >('client-requestDeletion')(input);
  }
}
