import uniq from 'lodash/uniq';

/**
 *
 * @param words array of words
 * @returns array of all possible permutations joined together
 * @example
 * getJoins(["mark", "bernd", "carl"]) => [
  'carl',   'markcarl',  'berndcarl',
  'markberndcarl', 'bernd',   'markbernd',
  'carlbernd',  'markcarlbernd', 'berndmarkcarl',
  'mark',   'berndmark',  'carlmark',
  'berndcarlmark', 'carlmarkbernd', 'carlberndmark'
]
 */
export const getPermutations = (words: string[]): string[] => {
  if (words.length <= 1) return words;
  const joins: string[] = [];
  for (let nextIndex = 0; nextIndex < words.length; nextIndex += 1) {
    const nextWords = [
      ...words.slice(0, nextIndex),
      ...words.slice(nextIndex + 1),
    ];
    const subJoins = getPermutations(nextWords);
    subJoins.forEach((subJoin) =>
      joins.push(subJoin, `${words[nextIndex]}${subJoin}`)
    );
  }
  return uniq(joins);
};
