import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

export type IDocumentViewerDialogData = {
  title: string;
  id?: string;
  pdfViewerData: {
    downloadUrl: string;
    contentType: string;
  };
  total: number;
  position?: number;
  hasPrev?: boolean;
  hasNext?: boolean;
  goPrev?: () => void;
  goNext?: () => void;
  docIsFulfillingActiveContentTagHandle: boolean;
  toggleDocFulfillingActiveContentTagHandle?: () => void;
};

@Component({
  selector: 'esui-document-viewer-dialog',
  templateUrl: './esui-document-viewer-dialog.component.html',
  styleUrls: ['./esui-document-viewer-dialog.component.scss'],
})
export class EsuiDocumentViewerDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData$: Observable<IDocumentViewerDialogData>
  ) {}
}
