import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FinishActivityIndicatorStatus } from '@expresssteuer/models';

@Component({
  selector: 'esui-state-engine-indicator',
  templateUrl: './state-engine-indicator.component.html',
  styleUrls: ['./state-engine-indicator.component.scss'],
})
export class StateEngineIndicatorComponent {
  FinishActivityIndicatorStatus = FinishActivityIndicatorStatus;

  @Input() lastTriggered?: Date;
  @Input() lastFinished?: Date;
  @Input() isIdle?: boolean;
  @Input() status?: FinishActivityIndicatorStatus;
  @Input() error?: unknown;

  get icon() {
    if (this.isIdle === false) {
      return 'autorenew';
    }

    if (this.status === FinishActivityIndicatorStatus.Success) {
      return 'circle';
    }
    if (this.status === FinishActivityIndicatorStatus.Abort) {
      return 'replay';
    }
    if (this.status === FinishActivityIndicatorStatus.Failure) {
      return 'error';
    }

    return 'radio_button_unchecked';
  }

  get tooltip(): string {
    if (this.isIdle) {
      return `finished at ${this.formatDate(this.lastFinished) ?? 'unknown'}`;
    }

    if (this.status === FinishActivityIndicatorStatus.Abort) {
      return `aborted and re-triggered at ${
        this.formatDate(this.lastTriggered) ?? 'unknown'
      }`;
    }

    return `started at ${this.formatDate(this.lastTriggered) ?? 'unknown'}`;
  }

  get errorMessage(): string | null {
    if (!this.error) {
      return null;
    }
    if (typeof this.error === 'string') {
      try {
        const parsedError = JSON.parse(this.error);
        return parsedError?.message || this.error;
      } catch (error) {
        return this.error;
      }
    }
    return JSON.stringify(this.error);
  }

  private formatDate(date?: Date): string | null {
    return this.datePipe.transform(date, 'yyyy-MM-dd hh:mm:ss');
  }

  constructor(private datePipe: DatePipe) {}
}
