import { SchemaProperty } from './vast';

export const VastZusSchema: SchemaProperty = {
  title: 'VaSt_ZUS-202201.xsd',
  $schema: 'http://json-schema.org/schema#',

  type: 'object',
  properties: {
    VaSt_ZUS: {
      type: 'object',
      description: 'Zuschüsse',
      properties: {
        Eingangsdatum: {
          description:
            'Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung',
          type: 'string',
        },
        BetroffenerFall: {
          type: 'object',
          properties: {
            Meldejahr: {
              description: 'Jahr der Zahlung',
              type: 'string',
            },
            BetroffenePerson: {
              type: 'object',
              properties: {
                NatPerson: {
                  type: 'object',
                  properties: {
                    IdNr: {
                      description: 'Identifikationsnummer',
                      type: 'string',
                    },
                    Name: {
                      description: 'Nachname',
                      type: 'string',
                    },
                    Vorname: {
                      description: 'Vorname',
                      type: 'string',
                    },
                    Blockname: {
                      description: 'Name',
                      type: 'string',
                    },
                    NamensVorsatz: {
                      description: 'Namensvorsatz',
                      type: 'string',
                    },
                    NamensZusatz: {
                      description: 'Namenszusatz',
                      type: 'string',
                    },
                    Titel: {
                      description: 'Titel',
                      type: 'string',
                    },
                  },
                },
              },
            },
          },
        },
        Behoerde: {
          type: 'object',
          properties: {
            Name: {
              description: 'Name der Behörde / öffentlichen Stelle',
              type: 'string',
            },
          },
        },
        Zahlungsdaten: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              Beginn: {
                description:
                  'Beginn des Zeitraums, für den die Zahlung erfolgte',
                type: 'string',
              },
              Ende: {
                description: 'Ende des Zeitraums, für den die Zahlung erfolgte',
                type: 'string',
              },
              Zahlungsart: {
                description: 'Zahlungsart',
                type: 'string',
                enum: [
                  '01',
                  '21',
                  '02',
                  '22',
                  '03',
                  '23',
                  '04',
                  '24',
                  '05',
                  '25',
                  '06',
                  '26',
                  '07',
                  '27',
                  '08',
                  '28',
                  '09',
                  '29',
                  '10',
                  '30',
                ],
              },
              Betrag: {
                description:
                  'Höhe der erhaltenen / zurückgeforderten Zuschüsse / Erstattungen',
                type: 'number',
              },
              Waehrung: {
                description:
                  'Währung der erhaltenen / zurückgeforderten Zuschüsse / Erstattungen',
                type: 'string',
              },
            },
          },
        },
        version: {
          type: 'string',
          enum: ['202201'],
        },
      },
      required: ['version'],
    },
  },
  required: ['VaSt_ZUS'],
};
