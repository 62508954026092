<div class="row">
  <div class="col-12">
    <div
      class="alert alert-secondary alert-notice alert-light-primary fade ml-5 mt-5 mb-5 float-left"
      [ngClass]="{
        show: busy && busy.isBusy,
        'alert-primary': busy && busy.isError
      }"
      role="alert"
    >
      <div class="alert-text" *ngIf="busy && busy.statusmessage">
        {{ busy.statusmessage }}
      </div>
    </div>

    <button
      id="saveAndBackButton"
      type="button"
      class="btn btn-primary mt-5 mb-5 mr-2 float-right"
      (click)="dosave()"
    >
      Speichern
    </button>

    <a
      (click)="back()"
      class="btn btn-default font-weight-bold btn-sm px-3 font-size-base mt-5 mb-5 mr-5 float-right"
    >
      Zurück
    </a>
  </div>
</div>
