import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Bank } from '@expresssteuer/banking-models';

@Component({
  selector: 'esui-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss'],
})
export class BankComponent {
  @Input() bank?: Bank;

  @Output() bankPartialChange = new EventEmitter<Partial<Bank>>();
  @Output() bankChange = new EventEmitter<Bank>();

  partialUpdate(change: Partial<Bank>) {
    const merged = { ...(this.bank ?? Bank.getTemplate()), ...change };
    this.bankChange.next(merged);
    this.bankPartialChange.next(change);
  }
}
