import { Pipe, PipeTransform } from '@angular/core';
import { isAbstractTimestamp } from '@expresssteuer/firebase-helper';
import { Timestamp } from '@expresssteuer/models';

@Pipe({
  name: 'suggestionString',
})
export class SuggestionStringPipe implements PipeTransform {
  transform(
    value: string | number | null | Timestamp,
    ...args: unknown[]
  ): string {
    if (!value) {
      return '';
    }
    if (isAbstractTimestamp(value)) {
      return value.toDate().toLocaleDateString();
    }
    return `${value}`;
  }
}
