import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  BinaryDocument,
  Client,
  ContentTagHandle,
  TaxofficeRequest,
  WithId,
  WithRef,
} from '@expresssteuer/models';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import {
  HREF_BUILDER,
  HrefBuilder,
} from '../href-builder/href-builder.provider';

export interface IContentTagHandleUpdateEvent {
  id: string;
  fulfilled?: boolean;
  addFulfillingBinaryDocRef?: string;
  removeFulfillingBinaryDocRef?: string;
}

@Component({
  selector: 'esui-taxoffice-assign-docs-to-request',
  templateUrl: './esui-taxoffice-assign-docs-to-request.component.html',
  styleUrls: ['./esui-taxoffice-assign-docs-to-request.component.scss'],
})
export class EsuiTaxofficeAssignDocsToRequestComponent implements OnChanges {
  @Input() request?: WithId<TaxofficeRequest>;
  @Input() clientForRequest?: WithId<Client> | null;
  @Input() contentTagHandles?: WithId<ContentTagHandle>[];
  @Input() activeContentTagHandle?: WithId<ContentTagHandle> | null;
  @Input() binaryDocuments?: WithRef<BinaryDocument>[];
  @Input() docsNotFulfillingActiveContentTagHandle?: WithRef<BinaryDocument>[];
  @Input()
  requestDocument?: {
    contentType: string;
    downloadUrl: string;
  };
  contentTagHandlesSorted: WithId<ContentTagHandle>[] = [];
  hideRequestDocument = true; // local state. could be handled in container
  downloadFileCount = 0;

  @Output() downloadDocsZipClicked = new EventEmitter<void>();
  @Output() contentTagHandleClicked = new EventEmitter<string>();
  @Output() showDocClicked = new EventEmitter<{
    contentTagHandleId: string;
    binaryDocumentRef: string;
  }>();
  @Output() contentTagHandleFulfilledChange =
    new EventEmitter<IContentTagHandleUpdateEvent>();
  @Output() addDocToContentTagHandle =
    new EventEmitter<IContentTagHandleUpdateEvent>();
  @Output() removeDocFromContentTagHandle =
    new EventEmitter<IContentTagHandleUpdateEvent>();
  @Output() resetSentStatus = new EventEmitter<void>();
  @Output() assignOperator = new EventEmitter<void>();

  constructor(
    @Inject(HREF_BUILDER) private builder: HrefBuilder,
    private datePipe: DatePipe
  ) {}

  crmLink?: string = undefined;

  trackContentTagHandle = (index: number, item: ContentTagHandle) => {
    `${item.tag}_${item.year}`;
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.request) {
      const request: WithId<TaxofficeRequest> | undefined =
        changes.request.currentValue;
      this.crmLink = request
        ? this.builder.crm?.client?.(request.clientId) ?? undefined
        : undefined;
    }
    if (changes.contentTagHandles) {
      const contentTagHandles = changes.contentTagHandles.currentValue;
      this.contentTagHandlesSorted = orderBy<WithId<ContentTagHandle>>(
        contentTagHandles ?? [],
        ['year', 'tag'],
        ['desc', 'asc']
      );
      this.downloadFileCount = getDownloadFileCount(contentTagHandles);
    }
  }

  sortDesc(arr?: string[] | null) {
    if (arr == null) return [];
    return orderBy(arr, (e) => e, 'asc');
  }

  getFormattedBinaryDocRef(
    ref: string,
    binaryDocuments?: WithRef<BinaryDocument<string, undefined>>[]
  ): string {
    const binaryDocument =
      binaryDocuments?.find((doc) => doc.ref === ref) ?? null;
    if (binaryDocument == null) return `not found (${ref})`;
    return `${binaryDocument.type} (${this.datePipe.transform(
      binaryDocument.uploaded?.toDate(),
      'yyyy-MM-dd hh:mm:ss'
    )})`;
  }

  getUniqueContentTags(doc: BinaryDocument): string[] {
    const uniqueTags = doc?.containedUniqueTags;
    if (uniqueTags == null) return [];
    const tagMap = Object.entries(uniqueTags).map((entry) => {
      return { tag: entry[0], years: Object.keys(entry[1]).join('|') };
    });
    return tagMap.map((entry) => `${entry.tag}-${entry.years}`);
  }
}

export function getDownloadFileCount(
  contentTagHandles: WithId<ContentTagHandle>[]
): number {
  const fulfillingRefs = contentTagHandles.flatMap(
    ({ fulfillingBinaryDocRefs }) => fulfillingBinaryDocRefs ?? []
  );
  return uniq(fulfillingRefs).length;
}
