import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { buildMeta } from '../../../build-meta/build-meta';
import { NotificationService } from './notification.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private notificationService: NotificationService,
    private afAuth: AngularFireAuth,
    private zone: NgZone
  ) {}

  private lastError: Error;

  async handleError(error: Error) {
    // this.zone.run(() => {
    if (error.message !== this.lastError?.message) {
      this.lastError = error;
      this.notificationService.warning(
        `Diese Seite enthält einen Fehler`,
        `Hier klicken um einen Fehlerbericht zu senden : ${error.message}`,
        await this.generateClickUpFormLink({
          name: error.name,
          message: error.message,
          stack: error.stack,
        })
      );
    }

    console.error(error);
  }
  async generateClickUpFormLink(specifics?: Error) {
    const clickUpLink =
      'https://forms.clickup.com/f/2a0k4-2201/UTT4ZDSNNLUR6JTNQE?';
    const report = generateCrashReport(specifics);
    const errorMessage = ` ${report.specifics.name}:${report.specifics.message}:
    ${report.specifics.stack}
  `;
    let email: string;
    try {
      const user = await this.afAuth.currentUser;
      email = user.email;
    } catch (e) {
      email = '';
    }
    const paramsObject = {
      Email: email,
      URL: report.meta.location,
      Fehlermeldung: errorMessage,
      meta: JSON.stringify({ versions: report.meta.versions }),
    };
    const params = new URLSearchParams(paramsObject);
    return clickUpLink + params.toString();
  }
}

function generateCrashReport(specifics?: Error) {
  const error = new Error('Crash Report Service');
  const report = {
    meta: {
      stack: error.stack,
      location: window.location.href,
      versions: {
        crm: buildMeta.commitHash,
        //   app: 'TODO app version/hash',
        //   functions: 'TODO functions version/hash',
      },
      // user: 'TODO',
      // cookies: 'TODO'

      timestamp: new Date().toISOString(),
    },
    specifics,
  };

  return report;
}
