import { Client, ClientShort } from '../client/client';
import { BinaryDocument, DocumentOld } from '../document/document';
import { FirestoreMigration } from '../firestore/firestoreMigration';
import { TemplateEngine } from '../helpers/templateengine';
import { Timestamp } from '../helpers/timestamp';
import { SmartTaskOnAClient } from '../smart-task/smart-task';
import { TaxCase } from '../taxcase/taxcase';
import { TaxForm } from '../taxform/taxform';
import { Tenant } from '../tenant/tenant';
import { User } from '../user/user';
import { MessageAttachment } from './messageAttachment';
import { NotificationChannels } from './notification';
import { SendGridNotification } from './notifications/sendgridnotification';
import { TwilioGridNotification } from './notifications/twilionotification';
import { BaseMessageTemplate } from './templates';

export enum MESSAGETYPE {
  EMAIL = 'email',
  SMS = 'sms',
  WHATSAPP = 'whatsapp',
  PUSH = 'push',
  INTERCOM = 'intercom',
}

export enum MESSAGESTATUS {
  /**
   * Message is in the quenue to be sent
   */
  QUENUE = 'quenue',
  /**
   * Message is sent but not recieved
   */
  SENT = 'sent',
  /**
   * Message has been deliverend to the target number
   */
  DELIVERED = 'delivered',
  /**
   * Message is undelivered (example device is turned off)
   */
  UNDELIVERED = 'undelivered',
  /**
   * There was an error preventing sending
   */
  FAILED = 'failed',
  /**
   * Incomming messages reviceved
   */
  RECIEVED = 'recieved',
  /**
   * Message has been dropped by our system.
   * Example, if you try to send a WA to a deavtivated client, the message gets dropped
   */
  DROPED = 'droped',
  /**
   * If the client does not prevent it, the message has been read by the client
   */
  READ = 'read',
}

export enum STATUSINBOUND {
  QUENUE = 'quenue',
  SENT = 'sent',
  DELIVERED = 'delivered',
  RECIEVED = 'recieved',
  DROPED = 'droped',
  READ = 'read',
}
export enum DIRECTION {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
  NONE = '',
}

export enum TEMPLATE {
  'DE_EXP_Welcome' = 'd-3cda61835ff24fdc936a0e1708a689fa',
  'DE_IGBAU_Welcome' = 'd-72620ba244df42f183376fe2c0c5ffeb',
  'DE_TALEA_Welcome' = 'd-72620ba244df42f183376fe2c0c5ffeb',
  'DE_EXP_SUPPORT_MESSAGE' = 'd-ec07ce7523e442c995adafc85b51fcc5',
  'DE_EXP_MISSING_LOHNSTEUERBESCHEID' = 'd-e1e097a9e3db462286368056a25b3fd3',
}

export enum TEMPLATELANGUAGE {
  'DE' = 'de',
  'EN' = 'en',
}
export enum TEMPLATEPARTNER {
  'EXP' = 'exp',
  'IGBAU' = 'igbau',
  'TALEA' = 'talea',
}

export enum TEMPLATEFUNCTION {
  'WELCOME' = 'welcome',
  'MISSINGDOCUMENTS' = 'missingdocuments',
  'MISSINGDOCUMENTS_IDENTITY' = 'missingdocuments_identity',
  'MISSINGDOCUMENTS_WAGE' = 'missingdocuments_wage',
  'PASSWORD_INITIAL' = 'passwort_initial',
  'REVIEW' = 'review',
}

export enum EXPMOBILE {
  Primary = '+4940299960190',
}

export enum EXPEMAIL {
  Primary = 'fragen@expresssteuer.de',
}

/**
 * @deprecated Use BaseMessageTemplate instead @see BaseMessageTemplate
 * **/
export interface Template {
  taxcase?: TaxCase | null;
  taxform?: TaxForm | null;
  client?: ClientShort | null;
  other?: any | null;
  subject?: string | null;
  message?: string | null;
  greating?: string | null;
}

/**
 * @deprecated Use BaseMessageTemplate instead @see BaseMessageTemplate
 * **/
export class SendGridTemplate {
  id = '';
  dynamicTemplateData?: Template;
}

export class MessageMetadata {
  public static getTemplate(template?: BaseMessageTemplate): MessageMetadata {
    return {
      taxcaseId: '',
      taskId: '',
      clientId: '',
      created: null,
      createdBy: null,
      read: false,
      readAt: null,
      readBy: null,
      deleted: false,
      deletedAt: null,
      deletedBy: null,
      keyAccount: null,
      templateId: template?.id ?? null,
    };
  }

  /**
   * EMAIL Headers
   */
  public messageHeader?: string;
  /**
   * EMAIL Message Unique ID from Sendgrid
   */
  public messageId?: string;
  /**
   * EMAIL Is Message a reply
   */
  public isReply?: boolean;
  /**
   * EMAIL The Reply ID
   */
  public replyId?: string;

  /**
   * EMAIL Message was recieved as HTML
   */
  public isHTML?: boolean;

  /**
   * If we recieve a message and can not assign a client, we flag it as unregistered
   */
  public unregistered?: boolean; // If we can not assign the message to any case or client

  /**
   * If we can extract the taxCaseId we save it here
   */
  public taxcaseId = '';
  /**
   * TODO: If we implement the task system we allow tasks to be mapped with messages
   */
  public taskId = '';
  /**
   * The id of our client in database (doubles with from if ClientShort)
   */
  public clientId = '';

  /**
   * The original Email Adresses
   */
  public fromEmail?: string = '';
  /**
   *  Original Email sent to
   */
  public toEmail?: string = '';
  /**
   * Original Mobile Number
   */
  public fromMobile?: string = '';
  /**
   * Original Mobile Number
   */
  public toMobile?: string = '';

  public created: Timestamp | null = null;
  public createdBy: User | ClientShort | null = null;

  public read = false;
  public readAt: Timestamp | null = null; // time when has been read in CRM
  public readBy: User | null = null; //The User who read the email
  public deleted = false;
  public deletedAt: Timestamp | null = null;
  public deletedBy: User | null = null;
  public keyAccount: User | null = null; // Who is internally the key account => will be added to Client
  public error?: any = null;
  public tenant?: Tenant | null;
  /**
   * Template id according to /statics/message_templates/twilio_message_templates
   */
  public templateId?: string | null;
}
/**
 * How often a tasks in a cluster have been send (lowest/highest value in cluster)
 */
export interface SentCount {
  /**
   * lowest sentCount of all tasks in cluster
   */
  low: number;
  /**
   * highest sentCount of all tasks in cluster
   */
  high: number;
}
export class MessageOptions {
  public static getTemplate(): MessageOptions {
    return {
      sendGridTemplate: null,
      sid: '',
      parent_sid: '',
      content_type: '',
      uri: '',
    };
  }
  public sendGridTemplate: SendGridTemplate | null = null;
  public sid?: string = '';
  public parent_sid?: string = '';
  public content_type?: string = '';
  public uri?: string = '';
}

export class BotAction {
  public static getTemplate(): BotAction {
    return {
      message: '',
      intent: '',
      confidence: 0,
      sentimentScore: 0,
      sentimentMagnitude: 0,
    };
  }
  message = '';
  intent = '';
  confidence = 0;
  sentimentScore = 0;
  sentimentMagnitude = 0;
}

export interface MessageBuildingBlock {
  rendered: string;
  tasks: SmartTaskOnAClient[];
}
export class MessageConfig {
  /**
   * If needen in the template to build
   **/
  client?: Client | ClientShort;
  /**
   * If needen in the template to build
   **/
  taxCase?: TaxCase;

  /**
   * If we send messages we could
   */
  taxCases?: TaxCase[];

  /**
   * If needen in the template to build
   **/
  tasks?: SmartTaskOnAClient[];

  /**
   * messageBuildingBlocks that should be in one cluster
   */
  clusteredMessageBuildingBlocks?: Record<string, MessageBuildingBlock[]>;

  /**
   * all messageBuildingBlocks flattened
   */
  messageBuildingBlocks?: MessageBuildingBlock[];

  /**
   * How often a tasks in a cluster have been send (lowest/highest value in cluster)
   */
  sentCount?: SentCount;

  /**
   * The current User.
   * If you wannt to check for the KeyAccount, use client.metadata.keyAccount
   * */
  user?: User;
  /**
   * If set this will be the sender
   * If empty, Benedikt will be the User
   * */
  from?: User;

  /**
   * Not implemented yet.
   * In future use, you can send attachments from dataCenter
   * */
  attachments?: BinaryDocument[];

  /**
   * If set, use this subject as template
   * Will overwrite template if set
   * */
  subject?: string;
  /**
   * If set, use this message as template
   * Will overwrite template if set
   * */
  message?: string;

  /**
   * An app link
   */
  appLink?: string;
  /**
   * used to send push notifications that redirect users to a specific view
   */
  deepLink?: string;
  /**
   * channel to send push notifications to
   */
  notificationChannel?: NotificationChannels;
}

export class Message {
  /**
   * Automatically generates a Message Template for sending out
   * If no template is defined and no subject/message, returns an emty messages
   * @param template The template to build the message
   * @param config Addition configurations needed for the Template to be build
   * */
  public static getTemplate(
    type?: MESSAGETYPE,
    template?: BaseMessageTemplate,
    config?: MessageConfig
  ): Message {
    const msg: Message = {
      id: '',
      from: null,
      to: null,
      type: type ? type : MESSAGETYPE.WHATSAPP,
      message: '',
      messageAbstract: '',
      state: MESSAGESTATUS.QUENUE,
      direction: DIRECTION.OUTBOUND,
      attachments: [],
      options: MessageOptions.getTemplate(),
      metadata: MessageMetadata.getTemplate(template),
      reply: null,
    };

    const templateEngine = new TemplateEngine<Record<string, unknown>>();

    if (template) {
      msg.subject = template.subject
        ? templateEngine.replace(template.subject, config as any)
        : '';
      msg.message = templateEngine.replace(template.message, config as any);
      msg.deepLink = template.deepLink;
      msg.notificationChannel = template.notificationChannel;
    }

    /** If subject is defined, we overwrite the template var **/
    if (config?.subject) {
      msg.subject = templateEngine.replace(config.subject, config as any);
    }
    /** If message is defined, we overwrite the template var **/
    if (config?.message) {
      msg.message = templateEngine.replace(config.message, config as any);
    }

    /** If subject is undefined, we initialize it to empty string
     *  TODO: Refactor send method to work also with undefined value
     * **/
    if (!msg.subject) {
      msg.subject = '';
    }

    msg.from = config?.user ? config.user : null;

    if (!msg.from) {
      msg.from = User.getSystemUser();
      msg.metadata.fromEmail = EXPEMAIL.Primary;
      msg.metadata.fromMobile = EXPMOBILE.Primary;
    }

    msg.to = config?.client ? Client.getClientShort(config.client) : undefined;

    msg.metadata.clientId =
      config?.client && config.client.id ? config.client.id : '';
    msg.metadata.taxcaseId = config?.taxCase ? config.taxCase.id : '';

    msg.metadata.toEmail = config?.client ? config.client.email : '';
    msg.metadata.toMobile = config?.client ? config.client.mobile : '';

    if (config?.client) {
      if (config.client instanceof Client) {
        msg.metadata.keyAccount =
          config?.client && config.client.metadata?.keyAccount
            ? config.client.metadata?.keyAccount
            : null;
      }
    }

    if (!msg.to) {
      msg.to = null;
    }
    if (config?.deepLink) {
      msg.deepLink = config.deepLink;
    }
    if (config?.notificationChannel) {
      msg.notificationChannel = config.notificationChannel;
    }
    return msg;
  }

  /**
   * Return a basic message without template+
   * */
  public static getBasic(
    subject: string,
    message: string,
    type: MESSAGETYPE
  ): Message {
    const msg: Message = Message.getTemplate(type);
    msg.subject = subject;
    msg.message = message;

    return msg;
  }

  public static validate(msg: Message): boolean {
    switch (msg.type) {
      case MESSAGETYPE.EMAIL:
        if (msg.metadata?.toEmail && msg.metadata?.toEmail.length < 3) {
          return false;
        }
        break;
      case MESSAGETYPE.WHATSAPP:
      case MESSAGETYPE.SMS:
        if (msg.metadata?.toMobile && msg.metadata?.toMobile.length < 8) {
          return false;
        }
        break;
    }

    return true;
  }

  public static getSentiment(sentiment: {
    magnitude: number;
    score: number;
  }):
    | 'excited'
    | 'very-satisfied'
    | 'satisfied'
    | 'neutral'
    | 'dissatisfied'
    | 'very-dissatisfied' {
    if (sentiment.magnitude <= 0.7) {
      return 'neutral';
    }

    if (sentiment.score > 0.8) {
      return 'excited';
    } else if (sentiment.score > 0.6) {
      return 'very-satisfied';
    } else if (sentiment.score > 0.4) {
      return 'satisfied';
    } else if (sentiment.score > 0.2) {
      return 'neutral';
    } else if (sentiment.score > -0.3) {
      return 'dissatisfied';
    } else {
      return 'very-dissatisfied';
    }
  }

  public id = '';
  /**
   * Declare the type to be sent out
   */
  public migration?: FirestoreMigration;

  public messageTypeId?: string = '';
  /**
   * INBOUND from client or mobile/email OUTBOUND the user who was sending
   */
  public from: User | ClientShort | null = null;

  /**
   * INBOUND the user who was sending OUTBOUND from client or mobile/email
   */
  public to?: User | ClientShort | null = null; // Bei Inbound Messages gibt es kein TO

  /**
   * Senc CC to an adddress only for outbound emails
   */
  public cc?: string | null = null;
  /**
   * Senc CBC to an adddress only for outbound emails
   */
  public bcc?: string | null = null;

  /**
   * The Message Type to send
   */
  public type: MESSAGETYPE = MESSAGETYPE.EMAIL;

  /**
   * The Subject of the Message (email only)
   */
  public subject?: string = '';
  /**
   * The Message Content (check the size limits)
   */
  public message = '';
  /**
   * Short version of the message for preview
   * @deprecated We do not use it
   */
  public messageAbstract = ''; // Shortened version of the message

  /**
   * State of the message if sent/recieved
   */
  public state: MESSAGESTATUS = MESSAGESTATUS.QUENUE;
  /**
   * Is the message to be sent or a recieved message
   */
  public direction: DIRECTION = DIRECTION.NONE;
  /**
   * Attachments to be send to the client
   */
  public attachments: MessageAttachment[] = [];

  /**
   * Special options for Sendgrid to mactch reply
   */
  public options: MessageOptions = MessageOptions.getTemplate();
  public metadata: MessageMetadata = MessageMetadata.getTemplate();

  /**
   * Contains the notifications from twilio and or sendgrid
   **/
  public notifications?: (TwilioGridNotification | SendGridNotification)[];

  /**
   * The Action and intent the NLP AI has delivered
   */
  //public botAction?: BotAction;
  public intent?: any;
  /**
   * array of replies from this message
   * @deprecated We do not use it right now
   */
  public reply: Message[] | null = null;

  /**
   * If defined, a deep link into the app (only https://app.expresssteuer.de/ {{appLink})
   */
  public appLink?: string | null;
  /*
   * used for push notifications to send a user to s specific view in app
   */
  public deepLink?: string | null;
  /**
   * channel on which this message will be sent
   */
  public notificationChannel?: NotificationChannels | null;
}

export interface MessageReplySuggestion {
  suggestedMessage: Message;
  sourceMessage: Message;
}

/**
 * @deprecated OldMessages
 */
export class MessageOld {
  id = '';
  templateId = '';
  caseId: string | null = null; // The caseId to link messages to the correct case
  isRead = false;
  direction = ''; // inbound // outbound
  state = ''; // new, sent
  createdByName = ''; // UserName
  createdById = ''; // internal UserID
  createdByImage = '';
  createdAt: Timestamp | null = null;
  type = ''; // note, message,
  subtype = ''; // note = empty, email = E-Mail, sms, whatsapp
  fromId = '';
  fromName = '';
  fromEmail = '';
  fromImage = '';
  to = ''; // email, or if whatsapp mobile number
  subject = '';
  message = '';
  sid = '';
  attachment: DocumentOld[] = [];
  outboundDocs: string[] = [];
  solved = false;

  // tslint:disable-next-line: variable-name
  _messageID?: string = '';
  // tslint:disable-next-line: variable-name
  _headers?: string = '';
  // tslint:disable-next-line: variable-name
  _reply?: boolean = false;
  // tslint:disable-next-line: variable-name
  _replyId?: string = '';
  // tslint:disable-next-line: variable-name
  _unregistered?: boolean = false;
  taxform: TaxForm | null = null;
  //  references: [] // attachments, documents, whatever

  public static getTemplate(): MessageOld {
    return {
      id: '',
      templateId: '',
      caseId: '',
      isRead: false,
      direction: 'outbound',
      state: 'new',
      createdByName: '',
      createdByImage: '',
      createdById: '',
      createdAt: null,
      type: 'message',
      fromId: '',
      fromName: '',
      fromEmail: '',
      fromImage: '',
      to: '',
      subtype: '',
      subject: '',
      message: '',
      sid: '',
      attachment: [],
      outboundDocs: [],
      solved: false,
      taxform: null,
      _messageID: '',
      _headers: '',
      _reply: false,
      _unregistered: false,
    };
  }
}
