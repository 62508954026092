<mat-card>
  <span esuiCardKey>Iban Payout No Match</span>
  <mat-card-header>
    <mat-card-title>Payout Iban - Invoicing</mat-card-title>
    <mat-card-subtitle>generate Invoiceline or dismiss</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="focus">
      <div class="documentViewer">
        <esui-skeleton-placeholder
          [skeletonBuildInput]="[]"
          [watchResult]="!!document"
          [bigBoxMode]="true"
        >
          <ng-container *ngIf="document">
            <esui-document-viewer
              [contentType]="document.contentType"
              [downloadUrl]="document.downloadUrl"
              [isLoading]="documentStoreStateIndicator?.isSaving"
              [hasErrors]="documentStoreStateIndicator?.hasErrors"
            ></esui-document-viewer>
          </ng-container>
        </esui-skeleton-placeholder>
      </div>
      <div class="data">
        <esui-skeleton-placeholder
          [skeletonBuildInput]="[[0, 1, 0], [0], [3], [3]]"
          [watchResult]="!!taxnotice && !!elsterDoc && !!taxcase"
          [bigBoxMode]="false"
        >
          <ng-container *ngIf="taxnotice && elsterDoc && taxcase">
            <div>
              <span
                ><h2>Taxyear {{ taxnotice.taxYear }}</h2></span
              >
            </div>
            <div>
              <h3>
                <u>{{ taxnotice.taxnoticeType }}</u>
                Taxnotice stated at
                {{
                  taxnotice.taxNoticeReturnDate?.toDate() | date : 'dd.MM.yyyy'
                }}
              </h3>
            </div>
            <div class="dataContainer">
              <mat-form-field class="minimalFormField readable">
                <mat-label>Payback Sum</mat-label>
                <input
                  matInput
                  type="number"
                  [ngModel]="taxnotice.actualTaxReturn"
                  [disabled]="true"
                />
                <span matSuffix>€&nbsp;</span>
              </mat-form-field>
              <div class="multiplier"><span>X</span></div>
              <mat-form-field class="minimalFormField readable">
                <mat-label>Marge</mat-label>
                <input
                  matInput
                  type="number"
                  [ngModel]="
                    (taxcase?.taxform?.current?.calculation?.campaign?.marge
                      ?.percent ??
                      0) *
                    100
                  "
                  [disabled]="true"
                />
                <span matSuffix>%&nbsp;</span>
              </mat-form-field>
              <div class="multiplier"><span>=</span></div>
              <mat-form-field class="bankDataWarn readable">
                <mat-label>Outstanding Payment</mat-label>
                <input
                  matInput
                  type="number"
                  [ngModel]="
                    taxcase?.taxform?.current?.calculation?.offer?.gross
                      ?.customerCost
                  "
                  [disabled]="true"
                />
                <span matSuffix>€&nbsp;</span>
              </mat-form-field>
              <div class="break"></div>
              <h2>Payout Information</h2>
              <div class="break"></div>
              <div class="bankData">
                <h3>Transfer Bank</h3>
                <p>
                  Iban:
                  {{
                    elsterDoc?.taxcase?.taxform?.current?.client
                      ?.transferBankAccount?.IBAN
                  }}
                  <br />
                  Bank:
                  {{
                    elsterDoc?.taxcase?.taxform?.current?.client
                      ?.transferBankAccount?.bankName
                  }}
                </p>
              </div>
              <div class="bankData">
                <h3>Taxnotice Payout Bank</h3>
                <p>
                  Iban:
                  <a class="bankDataWarn">{{ taxnotice.receivingIban }}</a>
                  <br />
                  Bank:
                  <a class="bankDataWarn">{{ taxnotice.receivingBankName }}</a>
                </p>
              </div>
              <mat-form-field class="fullWidth" appearance="fill">
                <mat-label>Reasoning</mat-label>
                <textarea
                  [ngModel]="comment"
                  (ngModelChange)="commentChange.next($event)"
                  matInput
                  placeholder="Please state if you are not generating invoice line"
                ></textarea>
              </mat-form-field>
            </div>
          </ng-container>
        </esui-skeleton-placeholder>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions class="buttonContainer">
    <button
      type="button"
      mat-raised-button
      color="primary"
      [disabled]="!taxnotice"
      (click)="generateInvoiceLine.next()"
    >
      Generate Invoicing Line
    </button>
    <button
      type="button"
      mat-raised-button
      color="warn"
      [disabled]="!taxnotice"
      (click)="markAsCorrectPayout.next()"
    >
      PayoutInfo Correct
    </button>
    <button
      type="button"
      mat-raised-button
      color="warn"
      [disabled]="!taxnotice"
      (click)="markAsPayed.next()"
    >
      Already Paid
    </button>
    <div class="errorMessage" *ngIf="lastError">
      Error:
      {{ lastError }}
    </div>
  </mat-card-actions>
</mat-card>
