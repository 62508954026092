/* eslint-disable */

export interface VaSt_RUE {
  VaSt_RUE: VaSt_RUE_CType;
}

export interface VaSt_RUE_CType {
  /**
   * Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung
   */
  Eingangsdatum?: string;
  BetroffenerFall?: BetroffenerFallM2062363058_CType;
  Versicherung?: VersicherungM2062363058_CType;
  Vertragsdaten?: VertragsdatenM2062363058_CType;
  version: string;
}

export interface BetroffenerFallM2062363058_CType {
  /**
   * Meldejahr
   */
  Meldejahr?: string;
  NatPerson?: NatPerson_1957121137_CType;
}

export interface NatPerson_1957121137_CType {
  /**
   * Identifikationsnummer
   */
  IdNr?: string;
  /**
   * Titel
   */
  Titel?: string;
  /**
   * Vorname
   */
  Vorname?: string;
  /**
   * Namensvorsatz
   */
  NamensVorsatz?: string;
  /**
   * Name
   */
  Name?: string;
  /**
   * Namenszusatz
   */
  NamensZusatz?: string;
  /**
   * Name
   */
  Blockname?: string;
}
/**
 * undefined
 * Name der Versicherung
 */
export interface VersicherungM2062363058_CType {
  /**
   * Name der Versicherung
   */
  Name?: string;
}

export interface VertragsdatenM2062363058_CType {
  /**
   * Einwilligungsdatum (zur Datenübermittlung)
   */
  EinwilligDat?: string;
  /**
   * Vertragsnummer
   */
  VertragsNr?: string;
  /**
   * Zertifizierungsnummer
   */
  ZertifiNr?: string;
  Beitragsdaten?: BeitragsdatenM1713590628_CType;
}
export interface BeitragsdatenM1713590628_CType {
  /**
   * @maxItems 99
   */
  Werte?: WerteM1232185231_CType | WerteM1232185231_CType[];
}

export interface WerteM1232185231_CType {
  /**
   * Zahljahr
   */
  ZahlJahr?: string;
  /**
   * Währung
   */
  Waehrung?: string;
  /**
   * Höhe der geleisteten Beiträge
   */
  Betrag?: string;
}
