import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'esui-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input()
  diameter = 50;

  #bar = false;
  @Input()
  set bar(val: BooleanInput) {
    this.#bar = coerceBooleanProperty(val);
  }
  get bar() {
    return this.#bar;
  }
}
