import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-status-in-progress-taxoffice-test',
  templateUrl: './button-status-in-progress-taxoffice-test.component.html',
  styleUrls: ['./button-status-in-progress-taxoffice-test.component.scss'],
})
export class ButtonStatusInProgressTaxofficeTestComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
