/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitter, Injectable, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Timestamp } from '@angular/fire/firestore';
import {
  BinaryDocument,
  Client,
  ClientShort,
  DIRECTION,
  InboxSender,
  MESSAGETYPE,
  Message,
  MessageConfig,
  NotificationChannels,
  TaxCase,
  Template,
} from '@expresssteuer/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../security/authentication.service';

// eslint-disable-next-line no-var
declare var $: any;

export class MessageOpen {
  public type: MESSAGETYPE = MESSAGETYPE.WHATSAPP;
  public clientId = '';
  public taxcaseId = '';
  public templateId = '';
}

export class Messanger {
  type: MESSAGETYPE = MESSAGETYPE.WHATSAPP;
  templateId = '';
  open = false;
  subject = '';
  message = '';
  client: Client | ClientShort;
  taxcase: TaxCase;
}

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  localMessagesInboxNewMessageFilteredDesc$: Observable<InboxSender[]>;

  private _messageLimit = 10;
  private _sortDirection: 'asc' | 'desc' = 'desc';
  private _keyAcoountAuthId;

  constructor(
    private db: AngularFirestore,
    private afMessaging: AngularFireMessaging,
    private security: AuthenticationService
  ) {}

  public get messagesInboxFiltered$(): Observable<InboxSender[]> {
    if (!this.localMessagesInboxMessageFiltered$) {
      this.localMessagesInboxMessageFiltered$ = this.db
        .collection<InboxSender>('messagecenter_inbox', (q) =>
          q
            .limit(this.limit)
            .orderBy('lastUnreadMessage.recieved', this.sortDirection)
        )
        .valueChanges({ idField: 'id' });
    }
    return this.localMessagesInboxMessageFiltered$;
  }
  public set messagesInboxFiltered$(val: Observable<InboxSender[]>) {
    this.localMessagesInboxMessageFiltered$ = val;
  }

  public get keyAccountId(): string {
    return this._keyAcoountAuthId;
  }

  public set keyAccountId(value: string) {
    this._keyAcoountAuthId = value;
  }

  public get limit(): number {
    return this._messageLimit;
  }
  /**
   * set the max messages
   */
  public set limit(value: number) {
    if (this.limit === value) {
      return;
    }
    this._messageLimit = value;
    this.localMessagesInboxNewMessageFiltered$ = null;
  }

  /**
   * set the ordering of messages
   */
  public set sortDirection(value: 'asc' | 'desc') {
    if (this._sortDirection === value) {
      return;
    }
    this._sortDirection = value;
    this.localMessagesInboxNewMessageFiltered$ = null;
  }

  public get sortDirection(): 'asc' | 'desc' {
    return this._sortDirection;
  }

  /**
   * Get all messages and filter to only one message per client. Practically a list of clients with new messages
   */
  public get messagesInboxNewFiltered$(): Observable<InboxSender[]> {
    this.localMessagesInboxNewMessageFiltered$ = this.db
      .collection<InboxSender>('messagecenter_inbox', (ref) =>
        ref
          .orderBy('lastUnreadMessage.recieved', this._sortDirection)
          .limit(this._messageLimit)
      )
      .valueChanges({ idField: 'id' });

    return this.localMessagesInboxNewMessageFiltered$;
  }

  public set messagesInboxNewFiltered$(val: Observable<InboxSender[]>) {
    this.localMessagesInboxNewMessageFiltered$ = val;
  }

  /**
   * Get all messages for keyAccount && s.keyAccount.authId === filterUser?.authId
   */
  public get messagesInboxKeyAccount$(): Observable<InboxSender[]> {
    this.localMessagesInboxNewMessageFiltered$ = this.db
      .collection<InboxSender>('messagecenter_inbox', (ref) =>
        ref
          .where('keyAccount.authId', '==', this._keyAcoountAuthId)
          .orderBy('lastUnreadMessage.recieved', this._sortDirection)
          .limit(this._messageLimit)
      )
      .valueChanges({ idField: 'id' });

    return this.localMessagesInboxNewMessageFiltered$;
  }

  public set messagesInboxKeyAccount$(val: Observable<InboxSender[]>) {
    this.localMessagesInboxNewMessageFiltered$ = val;
  }

  public get clientsRead$(): Observable<Message[]> {
    if (!this.localClientsRead$) {
      this.localClientsRead$ = this.db
        .collection<Message>('messagecenter', (ref) =>
          ref
            .where('metadata.deleted', '==', false)
            .where('direction', '==', 'inbound')
            .where('metadata.read', '==', true)
            .orderBy('metadata.readAt', 'desc')
            .limit(200)
        )
        .valueChanges({ idField: 'id' })
        .pipe(map(this.getOneMessagePerClient));
    }
    return this.localClientsRead$;
  }
  public set clientsRead$(val: Observable<Message[]>) {
    this.localClientsRead$ = val;
  }

  public get messagesReadFiltered$(): Observable<Message[]> {
    if (!this.localMessagesReadMessageFiltered$) {
      this.localMessagesReadMessageFiltered$ = this.db
        .collection<Message>('messagecenter', (ref) =>
          ref
            .where('metadata.deleted', '==', false)
            .where('direction', '==', 'inbound')
            .where('metadata.read', '==', true)
            .orderBy('metadata.readAt', 'desc')
            .limit(200)
        )
        .valueChanges({ idField: 'id' });
    }
    return this.localMessagesReadMessageFiltered$;
  }
  public set messagesReadFiltered$(val: Observable<Message[]>) {
    this.localMessagesReadMessageFiltered$ = val;
  }

  public get messagesMyMessageFiltered$(): Observable<Message[]> {
    if (!this.localMessagesMyMessageFiltered$) {
      this.localMessagesMyMessageFiltered$ = this.db
        .collection<Message>('messagecenter', (ref) =>
          ref
            .where('from.authId', '==', this.security.user.authId)
            .orderBy('metadata.created', 'desc')
            .limit(100)
        )
        .valueChanges({ idField: 'id' });
    }
    return this.localMessagesMyMessageFiltered$;
  }
  public set messagesMyMessageFiltered$(val: Observable<Message[]>) {
    this.localMessagesMyMessageFiltered$ = val;
  }

  public get messagesSentFiltered$(): Observable<Message[]> {
    if (!this.localMessagesSentMessageFiltered$) {
      const senMessagesCenter = this.db
        .collection<Message>('messagecenter', (ref) =>
          ref
            .where('direction', '==', 'outbound')
            .where('metadata.deleted', '==', false)
            .orderBy('metadata.created', 'desc')
            .limit(100)
        )
        .valueChanges({ idField: 'id' });

      /*
      const senMessagesOutbox = this.db.collection<Message>('messagecenter', ref =>
      ref.where('metadata.deleted', '==', false)
      .where('direction', '==', 'outbound')
      .orderBy('metadata.created', 'desc')
      .limit(100))
      .valueChanges();

      const combinedList = combineLatest<any[]>(senMessagesCenter, senMessagesOutbox).pipe(
        map(arr => arr.reduce((acc, cur) => acc.concat(cur).sort((a, b) => {
              if (a.metadata.created < b.metadata.created) {
                return 1;
              }
              if (a.metadata.created > b.metadata.created) {
                return -1;
              }
              return 0;
            }))
        ),
      );
      */

      this.localMessagesSentMessageFiltered$ = senMessagesCenter;
    }
    return this.localMessagesSentMessageFiltered$;
  }
  public set messagesSentFiltered$(val: Observable<Message[]>) {
    this.localMessagesSentMessageFiltered$ = val;
  }

  public get messagesDeletedFiltered$(): Observable<Message[]> {
    if (!this.localMessagesDeletedMessageFiltered$) {
      this.localMessagesDeletedMessageFiltered$ = this.db
        .collection<Message>('messagecenter', (ref) =>
          ref
            .where('metadata.deleted', '==', true)
            .orderBy('metadata.deletedAt', 'desc')
            .limit(100)
        )
        .valueChanges({ idField: 'id' });
    }
    return this.localMessagesDeletedMessageFiltered$;
  }
  public set messagesDeletedFiltered$(val: Observable<Message[]>) {
    this.localMessagesDeletedMessageFiltered$ = val;
  }

  public set currentMessage(val: Message) {
    if (val !== this.message) {
      this.message = val;
      this.loadAttachments(this.message);
    }

    if (this.message) {
      this.message.subject = '';
    }
  }

  public get currentMessage(): Message {
    return this.message;
  }

  public localClientsRead$: Observable<Message[]>;
  public localMessagesInboxMessageFiltered$: Observable<InboxSender[]>;
  public localMessagesReadMessageFiltered$: Observable<Message[]>;
  public localMessagesMyMessageFiltered$: Observable<Message[]>;
  public localMessagesOutboxMessageFiltered$: Observable<Message[]>;
  public localMessagesSentMessageFiltered$: Observable<Message[]>;
  public localMessagesDeletedMessageFiltered$: Observable<Message[]>;

  public localMessagesInboxNewMessageFiltered$: Observable<InboxSender[]>;
  public attachments: Observable<BinaryDocument[]>;

  public messanger: Messanger = new Messanger();

  // current
  private message: Message | null;
  MESSAGETYPE = MESSAGETYPE;

  @Output()
  public eventOpen = new EventEmitter<MessageOpen>();

  public getTaxcaseMessages(clientId: string): Observable<Message[]> {
    return this.db
      .collection<Message>('messagecenter', (ref) =>
        ref
          .where('metadata.clientId', '==', clientId)
          .orderBy('metadata.created', 'desc')
          .limit(100)
      )
      .valueChanges({ idField: 'id' });
  }

  public async loadAttachments(message: Message): Promise<void> {
    console.log('loading attachments for ', message.id);
    const idList = message.attachments.map((m) => m.id);
    this.attachments = this.db
      .collection<BinaryDocument>('documentcenter', (q) =>
        q.where('id', 'in', idList)
      )
      .valueChanges();
  }

  public openMessanger(
    type: MESSAGETYPE = MESSAGETYPE.WHATSAPP,
    to: Client | ClientShort | null = null,
    subject: string = '',
    message: string = '',
    templateid: string = '',
    taxcase: TaxCase | null = null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _documents: BinaryDocument[] = []
  ) {
    console.log('open messanger');

    this.messanger.type = type;
    this.messanger.client = to;
    this.messanger.subject = subject;
    this.messanger.message = message;
    this.messanger.taxcase = taxcase;
    this.messanger.open = true;
    this.messanger.templateId = templateid;

    $('#kt_inbox_compose').modal('show');

    const open: MessageOpen = {
      type,
      clientId: to ? to.id : '',
      taxcaseId: taxcase ? taxcase.id : '',
      templateId: templateid,
    };
    this.eventOpen.emit(open);

    // if (to !== null) {
    //   $('.tagify__input').html(to.firstname + ' ' + to.lastname);
    // }
  }

  public reply(message: Message): void {
    console.error('not implemented', message.id);
  }

  public view(message: Message): void {
    console.log('view', message.id);
    $('#messageviewer').modal('show');
  }

  public async load(messageId: string): Promise<Message> {
    const messageRef = await this.db
      .collection<Message>('messagecenter')
      .doc(messageId)
      .get()
      .toPromise();

    const message = messageRef.data() as Message;
    this.currentMessage = message;
    this.loadAttachments(this.currentMessage);
    return message;
  }

  public async send(
    type: MESSAGETYPE,
    to: Client | string,
    message: string,
    subject: string | null = null,
    taxcaseId: string | null = null,
    template: Template | null = null,
    closeAfterSend: boolean = true,
    actionLink: string | null = null,
    notificationChannel: NotificationChannels | null = null
  ): Promise<void> {
    const taxCaseRef = taxcaseId
      ? await this.db
          .collection<TaxCase>('taxcases')
          .doc(taxcaseId)
          .get()
          .toPromise()
      : undefined;

    let client = typeof to === 'object' ? to : null;

    if (!client) {
      const clientRef = await this.db
        .collection<Client>('clients')
        .doc(to as string)
        .get()
        .toPromise();
      client = clientRef.exists ? (clientRef.data() as Client) : undefined;
    }

    const config: MessageConfig = {
      user: this.security.user,
      taxCase: taxCaseRef ? (taxCaseRef.data() as TaxCase) : undefined,
      client,
      subject,
      message,
      from: this.security.user,
    };

    const m: Message = Message.getTemplate(type, undefined, config);

    switch (type) {
      case MESSAGETYPE.EMAIL:
        m.metadata.fromEmail = 'fragen@expresssteuer.de';
        m.options.sendGridTemplate = null;

        if (to instanceof Object) {
          m.metadata.toEmail = to.email;
        } else {
          m.metadata.toEmail = to;
        }

        m.subject = subject;
        break;
      case MESSAGETYPE.WHATSAPP:
      case MESSAGETYPE.SMS:
        m.metadata.fromMobile = '+4940299960190';

        if (to instanceof Client) {
          m.metadata.toMobile = to.mobile;
          m.metadata.clientId = to.id;
        } else {
          m.metadata.toMobile = to;
        }

        if (to instanceof Object) {
          m.metadata.toMobile = to.mobile;
        } else {
          m.metadata.toMobile = to;
        }

        m.messageAbstract = m.message.substr(0, 255);
        break;
      case MESSAGETYPE.PUSH:
        if (typeof actionLink === 'string') {
          m.deepLink = actionLink;
        }
        if (typeof notificationChannel === 'string') {
          m.notificationChannel = notificationChannel;
        }
        break;
      default:
        console.error('Message Type unknown');
    }
    try {
      console.log('sending message', message);

      m.direction = DIRECTION.OUTBOUND;
      this.db
        .collection('messagecenter')
        .add(m)
        .then(() => {
          if (closeAfterSend) {
            $('#kt_inbox_compose').modal('hide');
          }
          return void 0;
        })
        .catch((err) => {
          console.error(err);
          throw err;
        });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  public async update(message: Message): Promise<void> {
    await this.db
      .collection('messagecenter')
      .ref.doc(message.id)
      .update(message);
  }

  public async updateRead(message: Message) {
    if (message.metadata.read) {
      message.metadata.readAt = Timestamp.fromDate(new Date());
      message.metadata.readBy = this.security.user;
    } else {
      message.metadata.readAt = null;
      message.metadata.readBy = null;
    }

    await this.update(message);
  }

  public async delete(message: Message) {
    if (message.metadata.deleted) {
      message.metadata.deletedAt = Timestamp.fromDate(new Date());
      message.metadata.deletedBy = this.security.user;
    } else {
      message.metadata.deletedAt = null;
      message.metadata.deletedBy = null;
    }

    await this.update(message);
  }

  private getClientShortFromObject(client: Client) {
    const aClientShort = ClientShort.getTemplate();
    aClientShort.displayName = client.firstname + ' ' + client.lastname;
    aClientShort.firstname = client.firstname;
    aClientShort.lastname = client.lastname;
    aClientShort.email = client.email;
    aClientShort.mobile = client.mobile;
    aClientShort.id = client.id;
    aClientShort.userId = client.id;
    return aClientShort;
  }

  private getOneMessagePerClient(messages: Message[]) {
    return messages.filter(
      (v, i, a) =>
        a.findIndex((t) => {
          if (t.from && v.from) {
            if (t.from.displayName && v.from.displayName) {
              return t.from.displayName === v.from.displayName;
            }
            if (
              t.from instanceof ClientShort &&
              v.from instanceof ClientShort
            ) {
              if (t.from.firstname && v.from.firstname) {
                const tFullName = t.from.firstname + t.from.lastname;
                const vFullName = v.from.firstname + v.from.lastname;
                return tFullName === vFullName;
              }
            }
          }

          if (t.to && v.to) {
            if (t.to.displayName && v.to.displayName) {
              return t.to.displayName === v.to.displayName;
            }
            if (t.to instanceof ClientShort && v.to instanceof ClientShort) {
              if (t.to.firstname && v.to.firstname) {
                const tFullName = t.to.firstname + t.to.lastname;
                const vFullName = v.to.firstname + v.to.lastname;
                return tFullName === vFullName;
              }
            }
          }

          if (t.metadata && v.metadata) {
            if (t.metadata.fromMobile && v.metadata.fromMobile) {
              return t.metadata.fromMobile === v.metadata.fromMobile;
            }
            if (t.metadata.toMobile && v.metadata.toMobile) {
              return t.metadata.toMobile === v.metadata.toMobile;
            }
            if (t.metadata.fromEmail && v.metadata.fromEmail) {
              return t.metadata.fromEmail === v.metadata.fromEmail;
            }
            if (t.metadata.toEmail && v.metadata.toEmail) {
              return t.metadata.toEmail === v.metadata.toEmail;
            }
          }

          return false;
        }) === i
    );
  }
}
