<div class="row">
  <div class="col ellipsis" *ngIf="message.type == MESSAGETYPE.WHATSAPP">
    <span
      *ngIf="message.message.length > 0"
      data-container="body"
      data-toggle="popover"
      data-placement="top"
      attr.data-content="{{ message.message }}"
      attr.message-id="{{ message.id }}"
      >{{ message.message }}</span
    >
    <span *ngIf="message.message.length == 0">...</span>
  </div>
  <div class="col ellipsis" *ngIf="message.type == MESSAGETYPE.EMAIL">
    <span
      *ngIf="message.message.length > 0"
      data-container="body"
      data-toggle="popover"
      data-placement="top"
      attr.data-content="<p><b>{{ message.subject }}</b></p> {{
        message.message
      }}"
      attr.message-id="{{ message.id }}"
    >
      <p>
        <b>{{ message.subject }}</b>
      </p>
      <p>{{ message.message }}</p>
    </span>
    <span *ngIf="message.message.length == 0">...</span>
  </div>
</div>

<div class="row">
  <div class="col">
    <app-control-message-twilio-status
      [status]="message.state"
    ></app-control-message-twilio-status>
  </div>
</div>
