import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaxCase } from '@expresssteuer/models';

@Component({
  selector: 'app-control-client-return-value',
  templateUrl: './control-client-return-value.component.html',
  styleUrls: ['./control-client-return-value.component.scss'],
})
export class ControlClientReturnValueComponent implements OnInit {
  constructor() {}

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  public change = new EventEmitter<TaxCase>();

  private aTaxcase: TaxCase = null;

  @Input()
  get taxcase(): TaxCase {
    return this.aTaxcase;
  }

  set taxcase(val: TaxCase | null) {
    this.aTaxcase = val;

    this.change.emit(this.aTaxcase);
  }

  ngOnInit(): void {}
}
