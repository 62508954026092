import { DOCUMENTTYPE, DateString } from '@expresssteuer/models';
import {
  Answer as AnswerBase,
  AnswerFeedback as AnswerFeedbackBase,
  ClientTodo as ClientTodoBase,
  ClientTodoType,
  TodoMatcherMetadata as TodoMatcherMetadatasBase,
} from '../../../api-interfaces/src/index';

const matcherId = 'partner-identification-document' as const;

export class TodoMatcherMetadata implements TodoMatcherMetadatasBase {
  matcherId = matcherId;
  input: { partnerId: string };

  constructor(input: { partnerId: string }) {
    this.input = input;
  }
}

export enum TodoError {
  BAD_FILE_TYPE = 'bad_file_type',
  NANONETS_ERROR = 'nanonets_error',
}

export function isTodo(todo: unknown): todo is ClientTodo {
  const t = todo as Partial<ClientTodo> | null;
  return (
    t?.todoMatcherMetadata?.matcherId === matcherId &&
    typeof t.todoMatcherMetadata.input.partnerId === 'string' &&
    !!t.todoMatcherMetadata.input.partnerId
  );
}

export function isAnswer(todo: unknown, answer: unknown): answer is Answer {
  const p = (answer as Answer | null)?.payload || null;
  const validPayload = () =>
    [p?.frontDocumentRef, p?.backDocumentRef].some(
      (e) => e == null || (e && typeof e === 'string')
    );
  return isTodo(todo) && validPayload();
}

export function isFeedback(
  todo: unknown,
  feedback: unknown
): feedback is AnswerFeedback {
  const data = (feedback as AnswerFeedback | null)?.data;
  return isTodo(todo) && data != null; // TODO check all properties of front here
}

export interface PageData {
  errorId: TodoError | null;
  score?: number;

  type: DOCUMENTTYPE | null;
  /**
   * The document type is correct
   */
  isTypeCorrect: boolean | null;
  /**
   * The expiration date is valid
   */
  isValidExpirationDate: boolean | null;
  expirationDate: DateString | null;
  /**
   * The name is correct
   */
  isNameCorrect: boolean | null;

  firstname: string | null;
  lastname: string | null;
}

export interface FeedbackData {
  front: PageData | null;
}

export type AnswerFeedback = AnswerFeedbackBase & {
  data: { front: PageData | null };
};

export interface AnswerPayload {
  /**
   * Front Side of identification document
   */
  frontDocumentRef?: string | null;
  frontDocumentUploadRef?: string | null;
  /**
   * Back Side of identification document
   */
  backDocumentRef?: string | null;
  backDocumentUploadRef?: string | null;
}

export type Answer = AnswerBase & {
  payload: AnswerPayload;
};

export type ClientTodo = ClientTodoBase & {
  type: ClientTodoType.PartnerIdentifyingDocument;
  todoMatcherMetadata: TodoMatcherMetadata;
};
