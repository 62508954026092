import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-presenter',
  templateUrl: './search-presenter.component.html',
  styleUrls: ['./search-presenter.component.scss'],
})
export class SearchPresenterComponent implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  keyup = new EventEmitter<KeyboardEvent>();

  @Input()
  value = '';

  @Output()
  clear = new EventEmitter<void>();

  @Input()
  placeholder = 'Search...';

  constructor() {}

  ngOnInit(): void {}
}
