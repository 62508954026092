import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import {
  EsuiFinanceDepartmentIdValidatorModule,
  EsuiTaxIdValidatorModule,
} from '@expresssteuer/angular-validators';
import { EsAnchorMenuModule } from '../es-anchor-menu/es-anchor-menu.module';
import { AddressInputModule } from '../form-inputs/address-input/address-input.module';
import { DateInputModule } from '../form-inputs/date-input/date-input.module';
import { TextInputModule } from '../form-inputs/text-input/text-input.module';
import { ActionBoxModule } from './action-box/action-box.module';
import { EsuiClientActionsComponent } from './esui-client-actions.component';

@NgModule({
  declarations: [EsuiClientActionsComponent],
  imports: [
    CommonModule,
    AddressInputModule,
    TextInputModule,
    DateInputModule,
    MatFormFieldModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    ActionBoxModule,
    EsAnchorMenuModule,
    EsuiTaxIdValidatorModule,
    EsuiFinanceDepartmentIdValidatorModule,
  ],
  exports: [EsuiClientActionsComponent],
})
export class EsuiClientActionsModule {}
