import { Injectable } from '@angular/core';
import {
  HotkeysService as Angular2HotkeysService,
  Hotkey,
} from 'angular2-hotkeys';
import { BehaviorSubject, Observable, Subject, Subscriber } from 'rxjs';
import { skip } from 'rxjs/operators';
import { HotkeysStore } from './hotkeys.store';

@Injectable()
export class HotkeysService {
  constructor(
    private angular2hotkeysService: Angular2HotkeysService,
    private hotkeysStore: HotkeysStore
  ) {}

  public setSearch(val?: string | null) {
    this.hotkeysStore.update((e) => ({ ...e, search: val }));
  }

  public toggleCheatsheet() {
    this.angular2hotkeysService.cheatSheetToggle.next(undefined);
  }

  /**
   * Creates a new Hotkey for Mousetrap binding
   * (see `Angular2HotkeysService.add` and `Hotkey` for reference)
   * @param combo mousetrap key binding
   * @param description description for the help menu
   * @param action the type of event to listen for (for mousetrap)
   * @param allowIn an array of tag names to allow this combo in ('INPUT', 'SELECT', and/or 'TEXTAREA')
   * @param persistent if true, the binding is preserved upon route changes
   * @param preventBubbling
   * @param specificEvent
   */
  public register({
    combo,
    allowIn,
    description,
    action,
    persistent,
    preventBubbling,
    specificEvent,
  }: {
    combo: string | string[];
    allowIn?: string[];
    description?: string;
    action?: string;
    persistent?: boolean;
    preventBubbling?: boolean;
    specificEvent?: string;
  }) {
    const subject$ = new Subject<{
      event: KeyboardEvent;
      combo: string;
    } | null>();

    const hotkey = new Hotkey(
      combo,
      (event: KeyboardEvent, combo: string) => {
        subject$.next({ event, combo });
        if (preventBubbling === true) {
          return false;
        }
        return event;
      },
      allowIn,
      description,
      action,
      persistent
    );

    this.angular2hotkeysService.add(hotkey, specificEvent);
    this.hotkeysStore.addHotkey(hotkey, subject$);

    const { observable$, counter$ } = this.subscriberCount(subject$);
    // remove and complete when no subscribers left:
    counter$.pipe(skip(1)).subscribe((counter) => {
      if (counter < 1) {
        this.angular2hotkeysService.remove(hotkey, specificEvent);
        this.hotkeysStore.removeHotkey(hotkey);
        subject$.complete();
        counter$.complete();
      }
    });

    return observable$;
  }

  /**
   * Spy on the number of active subscribers on the returned observable$.
   * @returns the wrapped observable$ and a counter$ observable that represents the number of active subscribers
   */
  private subscriberCount<T>(sourceObservable: Observable<T>) {
    const counter$ = new BehaviorSubject(0);
    const observable$ = new Observable((subscriber: Subscriber<T>) => {
      const subscription = sourceObservable.subscribe(subscriber);
      counter$.next(counter$.value + 1);
      return () => {
        subscription.unsubscribe();
        counter$.next(counter$.value - 1);
      };
    });
    return { observable$, counter$ };
  }

  public fireEventFor(hotkey: Hotkey) {
    const { hotkeys } = this.hotkeysStore.getValue();
    hotkeys.get(hotkey)?.next(null);
  }
}
