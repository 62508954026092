import { TaxCase } from '@expresssteuer/models';
import { DisplayItem } from '../../esui-generic-list/esui-generic-list.component';

export function convertFlagsToDisplayItems(
  taxcase: TaxCase | null | undefined
): DisplayItem[] {
  if (!taxcase) {
    return [];
  }
  if (!taxcase.extractionFlags || taxcase.extractionFlags.length === 0) {
    return [new DisplayItem('empty', '✅ keine Probleme', '', 'string')];
  }

  return taxcase.extractionFlags.map(
    (flag) => new DisplayItem(flag, '❌ ' + flag, '', 'string')
  );
}
