import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DOCUMENTSOURCE, TaxNotice } from '@expresssteuer/models';
import { StoreStateIndicatorModel } from '../es-store-state-indicator/store-state-indicator.interface';

@Component({
  selector: 'esui-taxnotice-manual-process',
  templateUrl: './esui-taxnotice-manual-process.component.html',
  styleUrls: ['./esui-taxnotice-manual-process.component.scss'],
})
export class EsuiTaxnoticeManualProcessComponent {
  columnsToDisplay = [
    'Year',
    'Date',
    'Return Value',
    'Type',
    'Verified',
    'Appeal Status',
  ];
  @Input() taxNotice?: TaxNotice | null;
  @Input() taxNoticeDocument?: {
    contentType: string;
    downloadUrl: string;
    source?: DOCUMENTSOURCE | null;
  } | null;
  @Input()
  documentStoreStateIndicator?: Partial<StoreStateIndicatorModel> | null;
  @Input() sameYearTaxnotices?: TaxNotice[] | null;

  @Output() manualProcessDone = new EventEmitter<void>();

  calculateReturnDeviation() {
    return (
      (Math.abs(
        (this.taxNotice?.expectedTaxReturn ?? 0) -
          (this.taxNotice?.actualTaxReturn ?? 0)
      ) /
        (this.taxNotice?.actualTaxReturn ?? 0)) *
      100
    );
  }
}
