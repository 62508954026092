/* eslint-disable */

export interface VaSt_VWL {
  VaSt_VWL: VaSt_VWL_CType;
}

export interface VaSt_VWL_CType {
  /**
   * Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung
   */
  Eingangsdatum?: string;
  /**
   * Name des Anbieters
   */
  Anbieter?: string;
  /**
   * Meldejahr
   */
  Meldejahr?: string;
  BetroffenePerson?: BetroffenePersonM2062359145_CType;
  Vertrag?: VertragM2062359145_CType;
  version: string;
}

export interface BetroffenePersonM2062359145_CType {
  /**
   * Identifikationsnummer
   */
  IdNr?: string;
  /**
   * Name
   */
  Name?: string;
  /**
   * Vorname
   */
  Vorname?: string;
  /**
   * Name
   */
  Blockname?: string;
  /**
   * Namensvorsatz
   */
  NamensVorsatz?: string;
  /**
   * Namenszusatz
   */
  NamensZusatz?: string;
  /**
   * Titel
   */
  Titel?: string;
  /**
   * Geburtsdatum
   */
  GebDat?: string;
}

export interface VertragM2062359145_CType {
  /**
   * Einwilligungsdatum (zur Datenübermittlung)
   */
  EinwilligDat?: string;
  /**
   * Art der Anlage
   */
  AnlageArt?: '1' | '2' | '3' | '4' | '8';
  /**
   * Institutsschlüssel (IFAS)
   */
  InstitutsSchluessel?: string;
  /**
   * Vertragsnummer
   */
  VertragsNr?: string;
  /**
   * Vermögenswirksame Leistungen
   */
  Betrag?: string;
  /**
   * Ende der Sperrfrist
   */
  EndeSperrfrist?: string;
}
