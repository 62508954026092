import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OFFSITE } from '@expresssteuer/models';

@Component({
  selector: 'app-control-job-offsite-days',
  templateUrl: './control-job-offsite-days.component.html',
  styleUrls: ['./control-job-offsite-days.component.scss'],
})
export class ControlJobOffsiteDaysComponent implements OnInit {
  OFFSITE: OFFSITE;

  workingDaysOffsiteValue: OFFSITE;

  @Output()
  offsiteChange = new EventEmitter<OFFSITE>();

  @Input()
  get offsite(): OFFSITE {
    return this.workingDaysOffsiteValue;
  }

  set offsite(val: OFFSITE) {
    this.workingDaysOffsiteValue = val;
    this.offsiteChange.emit(this.workingDaysOffsiteValue);
  }

  ngOnInit(): void {}
}
