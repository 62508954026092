import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ExpsAddress } from '@expresssteuer/models';

@Component({
  selector: 'app-google-address',
  templateUrl: './google-address.component.html',
  styleUrls: ['./google-address.component.scss'],
})
export class ControlGoogleAddressComponent {
  addressValue: ExpsAddress = ExpsAddress.getTemplate();

  @ViewChild('addressInput') addressInput: any;

  @Output()
  addressChange = new EventEmitter<ExpsAddress>();

  @Input()
  get address(): ExpsAddress {
    return this.addressValue;
  }

  set address(val: ExpsAddress) {
    this.addressValue = val;
    this.addressChange.emit(this.addressValue);
  }

  @Input()
  showStreetNumberToggle = false;
  showStreetNumber = false;

  constructor(private ref: ChangeDetectorRef) {}

  public placeChangedAddress(placesAddress: any) {
    this.ref.detectChanges();

    const newAddress = ExpsAddress.getFromGooglePlaceResult(placesAddress);

    this.address = newAddress;
  }
}
