<mat-card>
  <mat-card-header>
    <mat-card-title>Client Todo Help </mat-card-title>
    <mat-card-subtitle>
      Todo Type:
      {{ todo?.todoMatcherMetadata?.matcherId ?? 'no matcher id found' }}
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div class="overview-and-answer-container">
      <div class="overview-container">
        <div class="submitted-answers-container">
          <h3>Submitted Answers</h3>
          <ng-container *ngIf="answers.length === 0">
            Error: I could not find any answers. This should not happen. Please
            contact the IT and provide the URL.
          </ng-container>
          <mat-selection-list
            [multiple]="false"
            (selectionChange)="makeAnswerActive.next($event.options[0]?.value)"
          >
            <mat-list-option
              *ngFor="let answer of answers"
              [value]="answer.id"
              [selected]="answer.id === activeAnswerId"
            >
              Answer from
              {{ answer.createdAt | asDate | date : 'dd.MM.YYYY HH:mm:SS' }}
              <ng-container *ngIf="answer.ready === false"
                >(Draft)</ng-container
              >
            </mat-list-option>
          </mat-selection-list>
        </div>
        <div>
          <h3>Client</h3>
          <p>ID: {{ client?.id ?? 'not found' }}</p>
          <p>Name: {{ client?.firstname }} {{ client?.lastname }}</p>
        </div>
      </div>
      <div class="answer-payload">
        <h3>Answer Details</h3>
        <ng-container *ngIf="!activeAnswerId; else answerDetailContent">
          Please choose an answer in the list above
        </ng-container>
        <ng-template #answerDetailContent>
          <ng-container
            *ngIf="
              todo?.todoMatcherMetadata?.matcherId ===
                'client-taxid-identification' ||
              todo?.todoMatcherMetadata?.matcherId ===
                'partner-taxid-identification'
            "
          >
            <esui-client-taxid-proof-answer
              class="grid-h-100"
              [documentViewerTemplate]="documentViewerTemplate"
              [answerPayload]="selectedAnswerPayload"
            ></esui-client-taxid-proof-answer>
          </ng-container>
          <ng-container
            *ngIf="
              todo?.todoMatcherMetadata?.matcherId ===
                'client-identification-document' ||
              todo?.todoMatcherMetadata?.matcherId ===
                'partner-identification-document'
            "
          >
            <esui-client-identifying-proof-answer
              class="grid-h-100"
              [documentViewerTemplate]="documentViewerTemplate"
              [answerPayload]="selectedAnswerPayload"
            ></esui-client-identifying-proof-answer>
          </ng-container>
          <ng-container
            *ngIf="todo?.todoMatcherMetadata?.matcherId === 'vma-proof'"
          >
            <esui-vma-proof-answer
              class="grid-h-100"
              [documentViewerTemplate]="documentViewerTemplate"
              [answerPayload]="selectedAnswerPayload"
            ></esui-vma-proof-answer>
          </ng-container>
        </ng-template>
      </div>
    </div>
    <div class="feedback-container">
      <h3>Feedback Form</h3>
      <ng-container
        *ngIf="
          todo?.todoMatcherMetadata?.matcherId ===
            'client-taxid-identification' ||
          todo?.todoMatcherMetadata?.matcherId ===
            'partner-taxid-identification'
        "
      >
        <div
          *ngIf="
            todo?.todoMatcherMetadata?.matcherId ===
            'partner-taxid-identification'
          "
        >
          Für den Partner
        </div>
        <esui-client-taxid-proof-feedback-form
          [validationResult]="validationResult"
          (validationResultChange)="validationResultChange.next($event)"
        ></esui-client-taxid-proof-feedback-form>
      </ng-container>
      <ng-container
        *ngIf="
          todo?.todoMatcherMetadata?.matcherId ===
            'client-identification-document' ||
          todo?.todoMatcherMetadata?.matcherId ===
            'partner-identification-document'
        "
      >
        <esui-client-identifying-proof-feedback-form
          [firstnameExpected]="
            todo?.todoMatcherMetadata?.matcherId ===
            'partner-identification-document'
              ? partner?.firstname
              : client?.firstname
          "
          [lastnameExpected]="
            todo?.todoMatcherMetadata?.matcherId ===
            'partner-identification-document'
              ? partner?.lastname
              : client?.lastname
          "
          [validationResult]="validationResult"
          (validationResultChange)="validationResultChange.next($event)"
        ></esui-client-identifying-proof-feedback-form>
      </ng-container>
      <ng-container
        *ngIf="todo?.todoMatcherMetadata?.matcherId === 'vma-proof'"
      >
        <esui-vma-proof-feedback-form
          [validationResult]="validationResult"
          (validationResultChange)="validationResultChange.next($event)"
        ></esui-vma-proof-feedback-form>
      </ng-container>
      <div class="submit-button-container">
        <button
          mat-raised-button
          color="primary"
          (click)="submitValidationResult.next()"
          [disabled]="!activeAnswerId || submitDisabled || !activeAnswerIsReady"
        >
          Submit manual Feedback for the Answer
        </button>
        <div *ngIf="!activeAnswerIsReady" class="submit-disabled-hint">
          Hint: Cannot submit feedback for a draft answer
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button
      mat-raised-button
      color="primary"
      (click)="submitValidationResult.next()"
      [disabled]="!activeAnswerId || submitDisabled || !activeAnswerIsReady"
    >
      Submit manual Feedback for the Answer
    </button>
  </mat-card-actions>
</mat-card>
