import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsStatsForNerdsModule } from '../es-stats-for-nerds/es-stats-for-nerds.module';
import { ButtonToggleGroupModule } from '../form-inputs/button-toggle-group/button-toggle-group.module';
import { PermissionInputModule } from '../form-inputs/permission-input/permission-input.module';
import { ClaimsEditorComponent } from './components/claims-editor/claims-editor.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { UserIconMenuComponent } from './components/user-icon-menu/user-icon-menu.component';
import { UserIconComponent } from './components/user-icon/user-icon.component';
import { UserListItemComponent } from './components/user-list-item/user-list-item.component';
import { UserListComponent } from './components/user-list/user-list.component';

@NgModule({
  declarations: [
    UserCardComponent,
    UserIconComponent,
    UserIconMenuComponent,
    UserListComponent,
    UserListItemComponent,
    ClaimsEditorComponent,
  ],
  imports: [
    PermissionInputModule,
    ButtonToggleGroupModule,
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatTableModule,
    MatButtonModule,
    FormsModule,
    EsCardKeyModule,
    EsStatsForNerdsModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
  ],
  exports: [
    UserCardComponent,
    UserIconComponent,
    UserIconMenuComponent,
    UserListComponent,
    UserListItemComponent,
    ClaimsEditorComponent,
  ],
})
export class EsUserModule {}
