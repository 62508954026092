import { DOCUMENTTYPE } from '../document/document';
import { TaxofficeLetter } from './taxoffice-letter';

export abstract class TaxofficeInformation extends TaxofficeLetter {
  documentType: DOCUMENTTYPE.taxoffice_information =
    DOCUMENTTYPE.taxoffice_information;
  /**
   * list of all content block generated for this request
   */
  abstract generatedBlocksIds: string[] | null;
  /**
   * list of blocks that were marked as completed
   */
  abstract completedBlockIds: string[] | null;
}
