import {
  ClientTodo as ClientTodoBase,
  ClientTodoType,
  TodoMatcherMetadata as TodoMatcherMetadatasBase,
} from '../../../api-interfaces/src/index';

const matcherId = 'one-touch' as const;

export class TodoMatcherMetadata implements TodoMatcherMetadatasBase {
  matcherId = matcherId;
  input: {
    year: number;
    taxcaseId: string;
  };

  constructor(input: { year: number; taxcaseId: string }) {
    this.input = { ...input };
  }
}

export function isTodo(todo: unknown): todo is ClientTodo {
  return (
    (todo as Partial<ClientTodo> | null)?.todoMatcherMetadata?.matcherId ===
    matcherId
  );
}

export type ClientTodo = ClientTodoBase & {
  type: ClientTodoType.OneTouch;
  todoMatcherMetadata: TodoMatcherMetadata;
};
