import {
  custom as customZod,
  number as numberZod,
  string as stringZod,
} from 'zod';

export function validateFinanceDepartmentId(
  financeDepartmentId: number | string
) {
  const financeDepartmentIdString = financeDepartmentId.toString();
  const isValid = !!financeDepartmentIdString.match(/^[0-9]{4}$/);
  return isValid;
}

export const FinanceDepartmentIdZod = stringZod()
  .or(numberZod())
  .pipe(
    customZod<string | number>((val) => {
      if (!(typeof val === 'string' || typeof val === 'number')) {
        return false;
      }
      return validateFinanceDepartmentId(val);
    })
  );
