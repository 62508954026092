<ng-container *ngIf="!viewMode">
  <div
    role="group"
    class="group"
    [ngClass]="{
      showSubPlaceholders: showSubPlaceholders
    }"
  >
    <input
      type="text"
      [placeholder]="showSubPlaceholders ? 'street' : ''"
      class="street"
      matInput
      [ngModel]="value.street.longName"
      (ngModelChange)="setPart({ street: $event })"
      [disabled]="disabled"
      [required]="required"
      (focus)="onFocusIn($event)"
      (focusout)="onFocusOut($event)"
      #street
    />
    <input
      type="text"
      [placeholder]="showSubPlaceholders ? 'no.' : ''"
      class="nbr"
      matInput
      [ngModel]="value.nbr.longName"
      (ngModelChange)="setPart({ nbr: $event })"
      [disabled]="disabled"
      [required]="required"
      (focus)="onFocusIn($event)"
      (focusout)="onFocusOut($event)"
      #nbr
    />
    <input
      type="text"
      [placeholder]="showSubPlaceholders ? 'zip' : ''"
      class="zip"
      matInput
      [ngModel]="value.zipCode.longName"
      (ngModelChange)="setPart({ zip: $event })"
      [disabled]="disabled"
      [required]="required"
      (focus)="onFocusIn($event)"
      (focusout)="onFocusOut($event)"
      #zip
    />
    <input
      type="text"
      [placeholder]="showSubPlaceholders ? 'city' : ''"
      class="city"
      matInput
      [ngModel]="value.city.longName"
      (ngModelChange)="setPart({ city: $event })"
      [disabled]="disabled"
      [required]="required"
      (focus)="onFocusIn($event)"
      (focusout)="onFocusOut($event)"
      #city
    />
  </div>
</ng-container>
<ng-container *ngIf="viewMode">
  <ng-container
    *ngIf="
      value.street.longName &&
      value.nbr.longName &&
      value.zipCode.longName &&
      value.city.longName
    "
  >
    {{ value.street.longName }} {{ value.nbr.longName }},
    {{ value.zipCode.longName }} {{ value.city.longName }}
  </ng-container>
</ng-container>
