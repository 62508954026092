import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TaxCase } from '@expresssteuer/models';
import { Subject } from 'rxjs';
import { TaxCalculationRow } from '../esui-final-taxcase-review-v2/components/tax-calculation.component';
import { convertFlagsToDisplayItems } from '../esui-final-taxcase-review-v2/helper/flag-display-converter';
import { DisplayItem } from '../esui-generic-list/esui-generic-list.component';

@Component({
  selector: 'esui-taxcase-detail',
  templateUrl: './esui-taxcase-detail.component.html',
  styleUrls: ['./esui-taxcase-detail.component.scss'],
})
export class EsuiTaxcaseDetailComponent implements OnInit, OnDestroy {
  // TODO expose mat-tab-group state?

  @ViewChild('frame', { static: true }) private frameEl!: ElementRef;

  convertFlagsToDisplayItems = convertFlagsToDisplayItems;

  @Input()
  mode?: 'auto' | 'small' | 'large' | null = 'small';

  @Input()
  taxcase?: TaxCase | null | undefined;
  @Input()
  taxCalculationExpectedReturn?: number | null | undefined;
  @Input()
  taxCalculationRows?: TaxCalculationRow[] | null | undefined;
  @Input()
  taxStatementItems?: DisplayItem[] | null | undefined;
  @Input()
  plausibilityCheckReportSuccessItems?: DisplayItem[] | null | undefined;
  @Input()
  plausibilityCheckReportErrorItems?: DisplayItem[] | null | undefined;

  private resizeObserver?: ResizeObserver;
  private destroyed$ = new Subject<void>();

  hostWidth$ = new Subject<number>();

  ngOnInit() {
    const hostElement = this.frameEl.nativeElement;
    this.hostWidth$.next(hostElement.offsetWidth);
    this.resizeObserver = new ResizeObserver(() => {
      this.ngZone.run(() => {
        this.hostWidth$.next(hostElement.offsetWidth);
      });
    });
    this.resizeObserver.observe(hostElement);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.resizeObserver?.disconnect();
  }

  constructor(private ngZone: NgZone) {}
}
