import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { EsuiDocumentUploaderModule } from '../esui-document-uploader/esui-document-uploader.module';
import { EsuiClientFacingTaskViewerComponent } from './esui-client-facing-task-viewer.component';

@NgModule({
  declarations: [EsuiClientFacingTaskViewerComponent],
  imports: [
    CommonModule,
    CdkAccordionModule,
    EsuiDocumentUploaderModule,
    MatIconModule,
    MatDividerModule,
  ],
  exports: [EsuiClientFacingTaskViewerComponent],
})
export class EsuiClientFacingTaskViewerModule {}
