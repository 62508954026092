<ng-container *ngIf="task">
  <mat-card>
    <span esuiCardKey>document classifier</span>
    <mat-card-header>
      <mat-card-title> </mat-card-title>
      <mat-card-subtitle>
        <esui-store-state-indicator
          [hasOnlineChangedAfterLocalChanged]="
            !!hasOnlineChangedAfterLocalChanged
          "
          [isSaving]="!!isSaving"
          [isLoading]="!!isLoading"
          [hasChanges]="!!hasChanges"
          [hasErrors]="!!hasErrors"
        ></esui-store-state-indicator>
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <div class="focus" *ngIf="document && downloadUrl">
        <div class="documentViewer">
          <esui-document-viewer
            [downloadUrl]="downloadUrl"
            [contentType]="document.contentType"
            [source]="document.source"
          ></esui-document-viewer>
        </div>
        <div class="metaViewer">
          <h2>Klassifizierung</h2>
          <!-- TODO: -->
          <!-- <button mat-button (click)="ignoreClick.next()" disabled>ignore</button> -->
          <!-- TODO: -->
          <!-- <button mat-button (click)="unreadableClick.next()" disabled>
          unreadable
        </button> -->
          <esui-document-type-select
            [documentType]="document.type"
            [metadata]="document.metadata"
            (documentTypeSelect)="documentTypeSelect.next($event)"
          ></esui-document-type-select>

          <esui-parsed-fields-input
            *ngIf="!!document.parsedFields"
            (fieldValueChange)="parsedFieldChange.next($event)"
            [fieldsToDisplay]="fields"
            [fieldValues]="document.parsedFields!"
            [suggestions]="fieldSuggestions"
          ></esui-parsed-fields-input>

          <h4>
            Field Mean Score:
            <span [ngClass]="{ green: meanScores >= 0.95 }">
              {{ meanScores | percent : '1.0-2' }}
            </span>
          </h4>
          <mat-slide-toggle
            [ngModel]="document.verified"
            (ngModelChange)="correctClick.next($event)"
            >verified</mat-slide-toggle
          >
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="verifyAndSaveClick.next()"
        [disabled]="isSaving === true || isLoading === true"
      >
        verify and save
      </button>
      <button
        type="button"
        mat-raised-button
        color="accent"
        (click)="saveClick.next()"
        [disabled]="
          hasChanges !== true || isSaving === true || isLoading === true
        "
      >
        save
      </button>
      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="discardClick.next()"
        [disabled]="
          hasChanges !== true || isSaving === true || isLoading === true
        "
      >
        discard
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>
