import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DOCUMENTSOURCE, TaxCase, TaxcaseComment } from '@expresssteuer/models';

@Component({
  selector: 'esui-final-taxcase-review',
  templateUrl: './final-taxcase-review.component.html',
  styleUrls: ['./final-taxcase-review.component.scss'],
})
export class FinalTaxcaseReviewComponent {
  @Input()
  taxcase?: TaxCase | null;
  @Input() documentDownloadUrl?: string | null;
  @Input() documentContentType?: string | null;
  @Input() documentSource?: DOCUMENTSOURCE | null;
  @Input()
  documentHasOnlineChangedAfterLocalChanged?: boolean | null;
  @Input()
  documentIsSaving?: boolean | null;
  @Input()
  documentIsLoading?: boolean | null;
  @Input()
  documentHasChanges?: boolean | null;
  @Input()
  documentHasErrors?: boolean | null;

  @Output()
  submitTaskClick = new EventEmitter<void>();
  @Output()
  moreWorkNeededClick = new EventEmitter<void>();

  @Input() expectedTaxReturn?: number | null;

  @Input()
  comment?: string | null;
  @Output()
  commentChange = new EventEmitter<string>();
  @Output()
  showCommentClick = new EventEmitter<void>();
  @Input()
  showComment?: boolean | null;

  @Input()
  taxcaseComments?: TaxcaseComment[] | null;

  @Input()
  hasOnlineChangedAfterLocalChanged?: boolean | null;
  @Input()
  isSaving?: boolean | null;
  @Input()
  isLoading?: boolean | null;
  @Input()
  hasChanges?: boolean | null;
  @Input()
  hasErrors?: boolean | null;
}
