import { TaxCase } from '@expresssteuer/models';
import { CalculationStatement } from '@expresssteuer/taxcalculator-elster-interfaces';
import { DisplayItem } from '../../esui-generic-list/esui-generic-list.component';

export function convertCalculationStatementToDisplayItems(
  statement: CalculationStatement,
  options?: { showTaxcalculation?: boolean; showTaxReductions?: boolean }
): DisplayItem[] {
  const output: DisplayItem[] = [];

  if (options?.showTaxcalculation) {
    output.push(
      new DisplayItem(
        'incomeTax',
        'Einkommensteuer',
        statement.incomeTax,
        'currency',
        {
          headerText: 'Steuerberechnung',
        }
      ),
      new DisplayItem(
        'solidaritySurcharge',
        'Solidaritätszuschlag',
        statement.solidaritySurcharge,
        'currency'
      ),
      new DisplayItem(
        'churchTax',
        'Kirchensteuer',
        statement.churchTax,
        'currency'
      )
    );
  }

  output.push(
    new DisplayItem(
      'grossSalaryPerson_a',
      'Arbeitslohn Kunde',
      statement.grossSalary.person_a,
      'currency',
      {
        headerText: 'Anlage N Kunde',
      }
    )
  );
  output.push(
    new DisplayItem(
      'commuterFlatPerson_a',
      'Entfernungspauschale Kunde',
      statement.commuterFlatPerson_a.total,
      'currency'
    )
  );
  output.push(
    new DisplayItem(
      'incomeRelatedExpenses',
      'Summe Werbungskosten Kunde',
      statement.incomeRelatedExpenses_a,
      'currency'
    )
  );
  output.push(
    new DisplayItem(
      'grossSalaryPerson_b',
      'Arbeitslohn Ehepartner',
      statement.grossSalary.person_b,
      'currency',
      {
        headerText: 'Anlage N Ehepartner',
      }
    )
  );
  output.push(
    new DisplayItem(
      'commuterFlatPerson_b',
      'Entfernungspauschale Ehepartner',
      statement.commuterFlatPerson_b.total,
      'currency'
    )
  );
  output.push(
    new DisplayItem(
      'incomeRelatedExpenses',
      'Summe Werbungskosten Ehepartner',
      statement.incomeRelatedExpenses_b,
      'currency'
    )
  );
  output.push(
    new DisplayItem(
      'singleParentChildrensReleaf',
      'Entlastungsbetrag für Alleinerziehende',
      statement.singleParentChildrensReleaf,
      'currency',
      {
        doubleTopBorder: true,
      }
    )
  );
  output.push(
    new DisplayItem(
      'totalIncome',
      'Gesamtbetrag der Einkünfte',
      statement.totalIncome,
      'currency'
    )
  );
  output.push(
    new DisplayItem(
      'insuranceRetirement',
      'Rentenversicherungen',
      statement.insuranceRetirement,
      'currency',
      {
        headerText: 'Sonderausgaben',
      }
    )
  );
  output.push(
    new DisplayItem(
      'appliableInsurrances',
      'weitere Versicherungen (ggf. Basisabsicherung)',
      statement.appliableInsurrances,
      'currency'
    )
  );
  output.push(
    new DisplayItem(
      'specialIssues',
      'Sonderausgaben',
      statement.specialIssues,
      'currency'
    )
  );
  output.push(
    new DisplayItem(
      'extraordinaryCost',
      'außergewöhnliche Belastungen',
      statement.extraordinaryCost,
      'currency'
    )
  );
  output.push(
    new DisplayItem(
      'taxableIncome',
      'zu versteuerndes Einkommen',
      statement.taxableIncome.optimalValue,
      'currency'
    )
  );
  if (options?.showTaxReductions) {
    output.push(
      new DisplayItem(
        'reductionCraftmen',
        'Handwerkerleistungen',
        statement.reductionCraftmen,
        'currency',
        {
          headerText: 'Steuerermäßigungen',
        }
      ),
      new DisplayItem(
        'reductionHousehold',
        'haushaltsnahe Dienstleistungen',
        statement.reductionHousehold,
        'currency'
      )
    );
  }

  return output;
}
export function convertTaxcaseToDisplayItems(taxcase: TaxCase): DisplayItem[] {
  const output: DisplayItem[] = [
    new DisplayItem(
      'maritalStatus',
      'Status',
      taxcase.taxform.current.taxForm.information.maritalStatus,
      'string',
      {
        headerText: 'Weitere Angaben',
      }
    ),
    new DisplayItem(
      'taxclass',
      'Steuerklasse',
      taxcase.taxform.current.taxForm.jobs
        .map((job) => job.taxClass)
        .join(', '),
      'string'
    ),
    new DisplayItem(
      'restriction',
      'Großbuchstabe',
      taxcase.taxform.current.taxForm.jobs
        .map((job) => job.restriction)
        .flat()
        .join(', '),
      'string'
    ),
    new DisplayItem(
      'govermentalAids',
      'Lohnersatzleistungen',
      taxcase.taxform.current.taxForm.information.govermentalAids.reduce(
        (sum, aid) => sum + (aid.value || 0),
        0
      ) +
        taxcase.taxform.current.taxForm.partner.govermentalAids.reduce(
          (sum, aid) => sum + (aid.value || 0),
          0
        ),

      'currency'
    ),

    new DisplayItem(
      'children',
      'Kinder',
      taxcase.taxform.current.taxForm.childrens.length.toString(),
      'string'
    ),
  ];
  return output;
}
