/* eslint-disable @typescript-eslint/ban-types */
//TODO maybe use type-fest

import { Timestamp } from '../helpers/timestamp';

export interface iDisplayName {
  displayName: string;
}

export interface iFirestoreModelMetadata {
  created: UserTime;
  updated: UserTime | undefined;
}

export class Metadata implements iFirestoreModelMetadata {
  public created: UserTime = new UserTime();
  public updated: UserTime | undefined;
}

export class UserTime {
  public uid: string = '';
  public timestamp: Timestamp | null = null;
}

export class FirestoreModel {
  public id: string = '';
  public data(): object {
    const mydata = Object.assign({}, this);
    const myobj = this.fixProps(JSON.parse(JSON.stringify(mydata)));

    return myobj;
  }

  constructor(model?: object) {
    if (model) {
      Object.assign(this, model);
      /* console.log('data', data); */
    }
  }

  private fixProps(obj: any): any {
    for (const k in obj) {
      if (typeof obj[k] == 'object' && obj[k] !== null) {
        if (obj[k]['_nanoseconds'] && obj[k]['_seconds']) {
          console.log('TIMESTAMP', obj[k]);
        }
        return this.fixProps(obj[k]);
      } else {
        console.log(k);
      }
    }

    return obj;
  }
}
