import {
  Compiler,
  EnvironmentInjector,
  Injectable,
  Injector,
  NgModuleRef,
  Type,
  ViewContainerRef,
} from '@angular/core';

@Injectable()
export class ComponentLoader {
  constructor(private compiler: Compiler, private injector: Injector) {}

  loadComponentt(
    vcr: ViewContainerRef,
    moduleRef: EnvironmentInjector | NgModuleRef<unknown>,
    component: Type<unknown>
  ) {
    vcr.clear();
    return vcr.createComponent(component, {
      environmentInjector: moduleRef,
      injector: this.injector,
    });
  }

  /**
   * Lazy load a component from a module.
   * (This compiles the module on the fly)
   * @example
   * ```
     await this.componentLoader.loadComponentFromModule(
            import(
              './../task-actions/document-classifier/document-classifier.module'
            ),
            (mod) => mod.DocumentClassifierModule,
            (ngModule) => ngModule.entry
          )
        ).component
   * ```
   */
  async loadComponentFromModule<
    TMod,
    TNgModule extends Type<unknown>,
    TComponent extends Type<unknown>
  >(
    module$: Promise<TMod>,
    ngModuleSelector: (mod: TMod) => TNgModule,
    componentSelector: (ngModule: TNgModule) => TComponent
  ) {
    const mod = await module$;
    const lazyModule = ngModuleSelector(mod);
    const component = componentSelector(lazyModule);
    const moduleFactory = await this.compiler.compileModuleAsync(lazyModule);
    const moduleRef = moduleFactory.create(this.injector);
    return { moduleRef, component };
  }
}
