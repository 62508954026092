import { Bank } from '@expresssteuer/banking-models';
import { MarketingIds } from '@expresssteuer/tracking-types';
import { TaxCalculationStatus } from '../calculation/status';
import { Client } from '../client/client';
import { UploadDocument } from '../document/document';
import { Timestamp } from '../helpers/timestamp';
import { DISABILITY, PRODUCTTYPE, STATUS } from '../interfaces/iTaxform';
import { Gender, IPerson } from '../person/person';
import { IAdditionalExpense } from './additionalexpenses/additionalexpenses';
import { ExpsAddress } from './address/address';
import { Child } from './child/child';
import { GovermentalAid } from './govermentalaid/govermentalaid';
import { Job } from './job/job';
import { TaxOfficeDeadline } from './taxofficedeadline/taxofficedeadline';
import { TaxReturn } from './taxreturn/returnperson';
import { Worksettings } from './worksettings/worksettings';

export enum REGULARYWORKINGTIME {
  'EIGHT' = 'eight',
  'TWELVE' = 'twelve',
  'FOURTEEN' = 'fourteen',
}

export enum MARITALSTATUS {
  'single' = 'single',
  'married' = 'married',
  'divorced' = 'divorced',
}

export type TaxFormGenerator =
  | 'app'
  | `andorraSignup`
  | 'andorra'
  | 'retention'
  | 'copy'
  | 'retool'
  | 'crm'
  | 'backend'
  | 'mobileAppAndroidSignup'
  | 'mobileAppIOSSignup';

export enum LeadGenerator {
  TikTok = 'TikTok',
  Facebook = 'Facebook',
}

/**
 * - 0 Employees domiciled abroad (taxfree)
 * - 1 singles
 * - 2 single parents
 * - 3 married (higher income)
 * - 4 married (50/50)
 * - 5 married (lower income)
 * - 6 multiple jobs
 */
export type TaxClass = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export class TaxFormMetadata {
  static getTemplate(): TaxFormMetadata {
    return {
      checkoutComplete: false,
      paymentSessionId: '',
      currentPage: '',
      lead: false,
      pageHistory: [],
      campaign: {
        id: '',
        promoCode: '',
      },
      campaignOriginal: {
        id: '',
        promoCode: '',
      },
    };
  }
  /**
   * The url params when the app has been started
   */
  urlParams?: string;
  referrer?: string;
  checkoutComplete = false;
  currentPage = '';
  lead?: boolean = false;
  leadGenerator?: LeadGenerator;
  paymentSessionId?: string = '';
  campaign = {
    id: '',
    promoCode: '',
  };
  campaignOriginal = {
    id: '',
    promoCode: '',
  };
  /**
   * Friends code is the guid of the client
   */
  friends?: {
    friendid: string;
  };

  referralCodeUsedForSignup?: string;
  pageHistory?: string[] = [];
  remoteConfig?: any = null;
  taxoffice? = {
    return: 0,
  };
  /**
   * Marketing IDs which were active when the taxform was created
   */
  marketingIds?: MarketingIds;
  userAgent?: string;
}

/**
 * Allows the import of the TaxForms
 *
 */
export class Processing {
  inmportSilent = false;
}

export abstract class UnionMembership {
  abstract name: string;
}

export class InformationPerson {
  /** Personal Data **/
  gender?: Gender = Gender.unspecified;
  firstname: string = '';
  lastname: string = '';
  birthdate: Timestamp | null = null;
  email: string = '';
  mobile: string = '';
  signature: string = '';
  /** Religion **/
  inChurch = false;
  religion: string = '';

  /** UI TaxForm related **/
  hasGovermentalAid? = false;
  /** TODO : TYPO in property. This property is different in client/partner **/
  hasGovernentalAid? = false;

  hasFederation? = false;
  hasPension? = false;
  hasOtherIncomes = false;
  hasDisability = false;

  /** Tax Related **/
  taxClass: TaxClass = 1; // Steuerklasse 1-6
  workSettings = Worksettings.getTemplate();
  multipleJobs = false;
  isSelfEmployed = false;

  disability: DISABILITY = DISABILITY['<25'];
  additionalExpenses: IAdditionalExpense[] = [];
  govermentalAids: GovermentalAid[] = [];

  unionMemberships?: UnionMembership[];

  /** @deprecated count it by yourself out of jobs :-) **/
  jobsCount = 0;

  isUsingPrivateConnectionForWork? = false;
}
export class InformationClient extends InformationPerson implements IPerson {
  static getTemplate(): InformationClient {
    return {
      yearOfReimbursement: (new Date().getFullYear() - 1).toString(),
      maritalStatus: MARITALSTATUS.single,
      divorceDate: null,
      freetext: '',
      taxClass: 1, // Steuerklasse 1-5
      firstname: '',
      lastname: '',
      birthdate: null,
      email: '',
      mobile: '',
      bank: {
        IBAN: 'DE',
        validated: false,
        bankBIC: '',
        bankCode: '',
        bankName: '',
        bankCity: '',
        bankPLZ: '',
      },
      inChurch: false,
      religion: '',
      multipleJobs: false,
      isSelfEmployed: false,
      jobsCount: 1,
      allowEmployerToSendWageTax: false,
      hasChildren: false,
      hasDisability: false,
      hasFederation: false,
      hasPension: false,
      hasGovernentalAid: false,
      workSettings: {
        hasRegularyWorkingAtSameLocation: false,
        regularyWorkingTime: REGULARYWORKINGTIME.EIGHT,
      },
      disability: DISABILITY['<25'],
      hasOtherIncomes: false,
      hasRelocation: false,
      flatSpaceSQM: 50,
      addressClient: ExpsAddress.getTemplate(),
      addressCurrent: ExpsAddress.getTemplate(),
      additionalExpenses: [],
      govermentalAids: [],
      signature: '',
      hasHousingCosts: false,
      isUsingPrivateConnectionForWork: false,
    };
  }
  freetext = '';

  yearOfReimbursement: string = (new Date().getFullYear() - 1).toString();
  maritalStatus: MARITALSTATUS = MARITALSTATUS.single;
  divorceDate: Timestamp | null = null;
  bank: Bank = Bank.getTemplate();

  allowEmployerToSendWageTax = false;
  hasChildren = false;

  /**
   * Client has relocaten in the taxyear
   */
  hasRelocation = false;
  /**
   * Client space of his flat in the taxyear.
   */
  flatSpaceSQM: number | null = 55;

  /**
   * The address the client resides at the tax year
   * @example If this is the taxform of 2017 and we are now in 2021. addressClient describes the address in 2017.
   */
  addressClient: ExpsAddress = ExpsAddress.getTemplate();
  /**
   * The address the client resides now
   * @example If this is the taxform of 2017 and we are now in 2021. addressCurrent describes the address in 2021.
   * This address is unused in a later stage. If the taxform has been transformed into a taxcase, the address will be taken from client.address
   * @see Client

   */
  addressCurrent: ExpsAddress = ExpsAddress.getTemplate();

  hasHousingCosts? = false;

  /**
   * apply client data to taxform information object (in place)
   * @param taxform to apply the data on
   * @param client to read data from
   */
  public static applyClientDataToTaxformInformation(
    taxform: TaxForm,
    client: Client
  ): void {
    taxform.information.firstname = client.firstname;
    taxform.information.lastname = client.lastname;
    taxform.information.email = client.email;
    taxform.information.addressClient = client.addressClient;
    taxform.information.addressCurrent = client.addressClient;
    taxform.information.birthdate = client.birthdate ?? null;
  }
}

export class InformationPartner extends InformationPerson implements IPerson {
  static getTemplate(): InformationPartner {
    return {
      inChurch: false,
      religion: '',
      isSelfEmployed: false,
      jobsCount: 1,
      taxClass: 1,
      workSettings: {
        hasRegularyWorkingAtSameLocation: false,
        regularyWorkingTime: REGULARYWORKINGTIME['EIGHT'],
      },
      marriedSince: null,
      birthdate: null,
      firstname: '',
      lastname: '',
      email: '',
      mobile: '',
      multipleJobs: false,
      hasGovermentalAid: false,
      hasFederation: false,
      hasPension: false,
      hasOtherIncomes: false,
      hasDisability: false,
      disability: DISABILITY['<25'],
      additionalExpenses: [],
      govermentalAids: [],
      signature: '',
      isUsingPrivateConnectionForWork: false,
    };
  }

  partnerId?: string;
  marriedSince: Timestamp | null = null;
}

export class TaxForm {
  static getTemplate(): TaxForm {
    return {
      id: '',
      language: '',
      isTest: false,
      generator: 'app',
      metadata: TaxFormMetadata.getTemplate(),
      version: '0.0.0',
      status: STATUS.NEW,
      openYears: [],
      uid: '', // User ID
      productType: PRODUCTTYPE.ONSUCCESS,
      domain: 'app.expresssteuer.com',
      calculation: {
        isCalculated: false,
        errorCount: 0,
      },
      created: null,
      lastUpdate: null,
      creator: '',
      taxReturn: TaxReturn.getTemplate(),
      information: InformationClient.getTemplate(),
      relocation: {
        relocatedAt: null, // on relocation we store the Relocation Date
        relocationAddress: ExpsAddress.getTemplate(),
      },
      jobs: [Job.getTemplate(false)],
      childrens: [],
      partner: InformationPartner.getTemplate(),
      documents: [],
      taxofficeDeadline: TaxOfficeDeadline.getTemplate(),
    };
  }

  static isReadonly(taxForm: { status: STATUS | string }) {
    return [
      STATUS.READY,
      STATUS.NEWCASECREATED,
      STATUS.GARBAGE,
      STATUS.ALREADYSUBMITTED,
    ].includes(taxForm.status as any);
  }

  id = '';
  /** The versionnumber in the form. Is used to track if changes in the taxform happened and the taxform should be reinitialized **/
  version = '0.0.0';
  /** The Users Language **/
  language?: string = '';
  /** If this is a testcase and can be identified and deleted afterwards **/
  isTest = false;

  /** Generator which generated this taxform
   *  - app: Client created the taxform in App
   *  - retention : Taxform was created by applying vollmachtsdaten to exisitng taxcase
   *  - copy: Not really used yet/Already deprecated
   *
   * Optional because missing in old taxforms
   */
  generator?: TaxFormGenerator = 'app';
  metadata: TaxFormMetadata = TaxFormMetadata.getTemplate();
  processing?: Processing;
  status: STATUS | string = STATUS.NEW;
  openYears: number[] = [];
  /** The type of product. Has an impact on the process of creating the taxcase **/
  productType: PRODUCTTYPE = PRODUCTTYPE.ONSUCCESS;
  /** The Users ID **/
  uid = '';
  /** The domain the user came from and has filled out the form **/
  domain = 'app.expresssteuer.com';
  calculation: TaxCalculationStatus = TaxCalculationStatus.getTemplate();
  created: Timestamp | null = null;
  lastUpdate: Timestamp | null = null;
  creator?: string;
  taxReturn: TaxReturn = TaxReturn.getTemplate();
  /** Information about the client **/
  information = InformationClient.getTemplate();
  /** If married, contains the data about the partner **/
  partner: InformationPartner = InformationPartner.getTemplate();
  /** If the client relocated within the taxyear **/
  relocation!: {
    relocatedAt: Timestamp | null; // on relocation we store the Relocation Date
    relocationAddress: ExpsAddress;
  };
  jobs: Job[] = [];
  childrens: Child[] = [];
  /** The documents the client has provided initialy. This is a hack to import the documents afterwards
   * @deprecated
   * **/
  documents: UploadDocument[] = [];
  taxofficeDeadline: TaxOfficeDeadline = TaxOfficeDeadline.getTemplate();
}

export {
  ADDITIONALEXPENSECATEGORY,
  ADDITIONALEXPENSESUBCATEGORY,
  AdditionalExpense,
  EXPENSETYPE,
  IAdditionalExpense,
} from './additionalexpenses/additionalexpenses';
export { AdditionalExpenseRiester } from './additionalexpenses/riester';
export { AdditionalExpenseRurup } from './additionalexpenses/rurup';
export { AddressOptional, ExpsAddress } from './address/address';
export {
  Child,
  ChildCalculation,
  ChildExpenses,
  ChildOccupation,
} from './child/child';
export { GovermentalAid } from './govermentalaid/govermentalaid';
export { JobCalculation } from './job/calculation/jobcalculation';
export {
  Job,
  JobWageStatementValues,
  OFFSITETYPE,
  OffsiteDay,
  Worksetup,
} from './job/job';
export { MealAllowanceOffsiteDays } from './offsiteDays/offsiteDays';
export {
  BaseQuestion,
  DropdownQuestion,
  DropdownQuestionItem,
  FORMTYPE,
  IDropdownQuestion,
  IDropdownQuestionItem,
  IMinMax,
  IProfessionGroup,
  IProfessionQuestion,
  ISimpleQuestion,
  IYesNoQuestion,
  Profession,
  ProfessionGroup,
  ProfessionQuestion,
  SimpleQuestion,
  YesNoQuestion,
} from './profession/profession';
export { TaxReturn } from './taxreturn/returnperson';
export { TaxReturnPerson } from './taxreturn/returntaxform';
