<app-search-presenter
  *ngIf="!!searchable"
  #search
  (keyup)="searchKeyup(search.value)"
  (clear)="searchClear()"
  [value]="searchValue"
  [placeholder]="searchPlaceholder"
></app-search-presenter>

<ul class="list-group list-group-flush">
  <li
    class="list-group-item d-flex justify-content-between align-items-center form-check"
    *ngFor="let item of transformItems ? transformItems(items) : items"
  >
    <label class="form-check-label text-truncate" [attr.for]="item.value">
      <input
        class="form-check-input"
        type="checkbox"
        [attr.value]="item.value"
        [attr.id]="item.value"
        (click)="refine(item.value)"
        [checked]="item.isRefined"
      />
      {{ item.label }}
    </label>
    <span class="badge badge-primary badge-pill">{{ item.count }}</span>
  </li>
</ul>
