import { Component } from '@angular/core';
import { AisDocumentsCollectionListItemComponent } from '../ais-documents-collection-list-item/ais-documents-collection-list-item.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-ais-documents-table-item]',
  templateUrl: './ais-documents-table-item.component.html',
  styleUrls: ['./ais-documents-table-item.component.scss'],
})
export class AisDocumentsTableItemComponent extends AisDocumentsCollectionListItemComponent {}
