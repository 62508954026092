import { Timestamp } from '../helpers/timestamp';
import { ContentTagEntry, ExtractedDocument } from './taxoffice-letter';
import {
  ContentTags,
  ProviderContentTags,
} from './taxoffice-letter-content-tags';

/**
 * @description ID is hash of tag,parent reference, payload(textBlock)
 * For each content tag on a document, a handle is generated
 * This handle needs to be fullFilled by a payload (raw data)
 * OR/AND a document (binaryDcRef)
 */
export abstract class ContentTagHandle {
  /**
   * year of the content tag and therefore its handle
   */
  abstract year: string | null;
  /**
   * unsure if needed to quickly indicate wheter this requires or provides info
   * should also be evaluated by tag itself
   */
  abstract type: 'provider' | 'requester';
  /**
   * Tag of this Handle
   */
  abstract tag: ContentTags | ProviderContentTags;
  /**
   * payload in form of a string
   */
  abstract payload: string | null;
  /**
   * document reference to binary doc
   */
  abstract binaryDocRef: string | null;
  /**
   * whether the document is fullfilled or not
   */
  abstract fulfilled: boolean;
  /**
   * reference to parent document
   */
  abstract parentDocumentRef: string;
  /**
   * deadline on when this request should be fullfilled (if type is request)
   * OR until when the information needs to be processed (if type is provider)
   */
  abstract deadline: Timestamp | null;
  /**
   * client reference
   */
  abstract clientId: string | null;
  /**
   * binary docs that fulfill this handle
   */
  abstract fulfillingBinaryDocRefs: string[] | null;
  /**
   * text based answer for this content tag handle
   */
  abstract fulfillingTextsResponse: string[] | null;

  /**
   * Create a new handle
   * @param tag the tag type this handle is for
   * @param parentDocumentRef parent which this was generated from
   * @param type whether tag is requester or provider
   * @param deadLine when the parents deadline is reached
   **/
  static getTemplate(
    parentDocumentRef: string,
    tag: ContentTags,
    type: 'provider' | 'requester',
    deadLine: Timestamp | null,
    clientId: string
  ): ContentTagHandle {
    return {
      payload: null,
      binaryDocRef: null,
      parentDocumentRef: parentDocumentRef,
      tag: tag,
      deadline: deadLine,
      fulfilled: false,
      type: type,
      clientId: clientId,
      year: null,
      fulfillingBinaryDocRefs: null,
      fulfillingTextsResponse: null,
    };
  }
}

export interface FullfillableByContentTags {
  fullFilled?: boolean;
}

export interface ContentTaggable {
  visionOCRStructured?: ExtractedDocument | null;
  /**
   * whether content tags where extracted
   */
  taggingComplete?: boolean;
  /**
   * content which is contained in this letter
   * maps Content Tag to text block which contains the content
   */
  contentTags?: ContentTagEntry[] | null;
  /*
   * contains all tags uniquely per year
   * All the work to make firestore work
   * @example
   * {HAUSHALTSNAHE_DIENSTLEISTUNG: {2021:true,2020:true}}
   */
  containedUniqueTags?: Partial<{
    [key in ContentTags | ProviderContentTags]: Record<number, true>;
  }> | null;
}

export function ContentTaggableGuard(
  obj: any
): obj is FullfillableByContentTags {
  return (
    'containedUniqueTags' in obj &&
    'contentTags' in obj &&
    'visionOCRStructured' in obj &&
    'taggingComplete' in obj
  );
}

export function FullfillableByContentTagsGuard(
  obj: any
): obj is FullfillableByContentTags {
  return 'fullFilled' in obj;
}
