import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DOCUMENTSOURCE,
  Elster,
  TaxCase,
  TaxNotice,
} from '@expresssteuer/models';
import { StoreStateIndicatorModel } from '../es-store-state-indicator/store-state-indicator.interface';

@Component({
  selector: 'esui-payout-nomatch',
  templateUrl: './esui-payout-nomatch.component.html',
  styleUrls: ['./esui-payout-nomatch.component.scss'],
})
export class EsuiPayoutNomatchComponent {
  @Input()
  comment?: string | null;
  @Input()
  taxnotice?: TaxNotice | null;

  @Input()
  elsterDoc?: Elster | null;

  @Input()
  taxcase?: TaxCase | null;

  @Input()
  documentStoreStateIndicator?: Partial<StoreStateIndicatorModel> | null;

  @Input() document?: {
    contentType: string;
    downloadUrl: string;
    source?: DOCUMENTSOURCE | null;
  } | null;

  @Input()
  taxNoticeStoreStateIndicator?: Partial<StoreStateIndicatorModel> | null;

  @Input()
  lastError?: string | null;

  @Output()
  generateInvoiceLine = new EventEmitter<void>();

  @Output()
  markAsPayed = new EventEmitter<void>();

  @Output()
  markAsCorrectPayout = new EventEmitter<void>();

  @Output()
  commentChange = new EventEmitter<string>();
}
