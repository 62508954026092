export interface IGoogleMapsReturn {
  home: { text: string; value: number };
  relocation: { text: string; value: number };
}

export interface IJobCalculation {
  wage: {
    partial: {
      wage: number;
    };
    fullyear: {
      wage: number;
    };
    daysWorked: number;
  };
  taxable: {
    partial: {
      taxableIncome: number; // zu versteuerndes Einkommen gearbeitet
      payedIncomeTax: number; // zu bezahlende Einkommenssteuer gearbeitet
    };
    fullyear: {
      taxableIncome: number; // zu versteuerndes Einkommen volles jahr
      payedIncomeTax: number; // bezahlte Einkommenssteuer volles Jahr
      payedIncomeTaxWorkdays: number; // bezahlte Einkommenssteuer volles Jahr auf Arbeitstage (/365 * arbeitstage)
      solidarityTax: number;
      churchTax: number;
    };
  };
  insurances: {
    partial: {
      health: number;
      pension: number;
      unemployment: number;
      nursing: number;
    };
    fullyear: {
      health: number;
      pension: number;
      unemployment: number;
      nursing: number;
    };
  };
  commuter: {
    daysWorked: {
      home: {
        value: number;
      };
      relocation: {
        value: number;
      };
    };
    distance: {
      home: {
        value: number;
      };
      relocation: {
        value: number;
      };
    };
    calculation: {
      return: number;
    };
  };
}

export enum JOBSTATUS {
  'employed' = 'employed',
  'unemployed' = 'unemployed',
  'selfemployed' = 'selfemployed',
  'minijob' = 'minijob',
  'parental_leave' = 'parental_leave',
}

export enum OFFSITE {
  'NEVER' = 0,
  'EVERYDAY' = 225,
  'THREE_FOUR_WEEK' = 158,
  'ONE_TWO_WEEK' = 68,
  'ONE_THREE_MONTH' = 38,
}

export enum HOMEOFFICE {
  'NEVER' = 0,
  'EVERYDAY' = 225,
  'THREE_FOUR_WEEK' = 158,
  'ONE_TWO_WEEK' = 68,
  'ONE_THREE_MONTH' = 38,
}

export interface IWorksetup {
  offsite: OFFSITE | null;
  homeoffice: HOMEOFFICE | null;
  hasMoneyFromEmployer: boolean;
}

export interface IProfession {
  labelDe: string;
  professiongroup: string;
  value: string;
}
