import { BinaryDocumentClassificationFields, DOCUMENTTYPE } from '../document';
import { ParsedFieldKey, TypeOfParsedField } from '../specific-documents';

export type FieldSuggestions = {
  [key in ParsedFieldKey]?: {
    suggestion: TypeOfParsedField<key>;
    score: number;
  }[];
};
/**
 * Suggestions for fields based on type and predictions
 * Sorted ascending by score -> first suggestion has highest score
 */
export function getFieldSuggestions(
  binaryDocumentClassificationFields:
    | BinaryDocumentClassificationFields
    | undefined,
  type?: DOCUMENTTYPE
): FieldSuggestions {
  if (!binaryDocumentClassificationFields) {
    return {};
  }

  const fieldKeys = Object.keys(
    binaryDocumentClassificationFields
  ) as (keyof BinaryDocumentClassificationFields)[];

  return fieldKeys.reduce((acc, fieldKey) => {
    acc[fieldKey] =
      binaryDocumentClassificationFields[fieldKey]
        ?.map((entry) => {
          const score =
            entry.forDocumenttypes?.find((e) => !e.type || e.type === type)
              ?.score || 0;

          return {
            ...entry,
            score,
          };
        })
        ?.sort((a, b) => {
          return b.score - a.score;
        }) || [];
    return acc;
  }, {} as any) as FieldSuggestions;
}
