<ng-container *ngIf="client">
  <span [matTooltip]="'status: ' + client.status">
    <ng-container *ngIf="client.displayName; else fallbackName">
      {{ client.displayName }}
    </ng-container>
    <ng-template #fallbackName>
      {{ client.firstname }} {{ client.lastname }}
    </ng-template>
  </span>
</ng-container>
