import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownInputComponent } from './dropdown-input.component';

@NgModule({
  declarations: [DropdownInputComponent],
  imports: [CommonModule, FormsModule],
  exports: [DropdownInputComponent],
})
export class DropdownInputModule {}
