import { Component, Input, OnInit } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { ACTIVITYTYPE, Task } from '@expresssteuer/models';
import { TasksService } from '../../../../app/services/data/tasks.service';
import { TaxcasesService } from '../../../../app/services/data/taxcases.service';

declare const Quill: any;

@Component({
  selector: 'app-note-modal-editor',
  templateUrl: './note-modal-editor.component.html',
  styleUrls: ['./note-modal-editor.component.scss'],
})
export class NoteModalEditorComponent implements OnInit {
  public note?: Task = undefined;

  public message?: string = undefined;

  @Input()
  public taxcaseId?: string = undefined;

  constructor(
    public taskservice: TasksService,
    private taxcasesservice: TaxcasesService
  ) {}

  private quill?: any = undefined;

  ngOnInit(): void {
    this.quill = new Quill('#kt_note_editor', {
      modules: {
        toolbar: [
          [
            {
              header: [1, 2, false],
            },
          ],
          ['bold', 'italic', 'underline'],
        ],
      },
      placeholder: 'Type your text here...',
      theme: 'snow', // or 'bubble'
    });
  }

  public add() {
    const task: Task = {
      id: '',
      reminder: null,
      subject: '',
      taskmetadata: {
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        assignedAt: Timestamp.now(),
        assignedTo: null,
        clientId: this.taxcasesservice.currentTaxCase.client.id,
        createdBy: this.taxcasesservice.security.user,
        taxcaseId: this.taxcasesservice.currentTaxCase.id,
        updatedBy: this.taxcasesservice.security.user,
        isDone: false,
        deleted: false,
        isDoneAt: null,
      },
      type: ACTIVITYTYPE.NOTE,
      message: this.quill?.root.innerHTML ?? 'n.a.',
    };

    //task.message = this.quill.root.innerHTML;

    console.log(task);

    if (task.message.length > 0) {
      console.log('add task note');
      this.taskservice.addNote(task, this.taxcasesservice.currentTaxCase);
    }
  }
}
