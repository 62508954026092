import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EsuiIbanValidatorDirective } from './esui-iban-validator.directive';

@NgModule({
  declarations: [EsuiIbanValidatorDirective],
  imports: [CommonModule],
  exports: [EsuiIbanValidatorDirective],
})
export class EsuiIbanValidatorModule {}
