import { DOCUMENTTYPE } from '../document/document';
import { Timestamp } from '../helpers/timestamp';
import {
  ContentTaggable,
  FullfillableByContentTags,
} from './content-tag-handle';
import {
  ContentTags,
  ProviderContentTags,
} from './taxoffice-letter-content-tags';

/**
 * @description do not use this class as db object
 * use reminder, request, information classes
 */
export abstract class TaxofficeLetter
  implements FullfillableByContentTags, ContentTaggable
{
  abstract documentType:
    | DOCUMENTTYPE.taxoffice_information
    | DOCUMENTTYPE.taxoffice_request
    | DOCUMENTTYPE.taxoffice_reminder;
  /**
   * associated client this letter is for
   */
  abstract clientId: string;
  /**
   * date the letter was issued
   */
  abstract creationDate: Timestamp;
  /**
   * date wer receiving the letter
   */
  abstract receivingDate: Timestamp;
  /**
   * content which is contained in this letter
   * maps Content Tag to text block which contains the content
   */
  abstract contentTags: ContentTagEntry[] | null;
  /**
   * OCR Structured by any ocr AI
   */
  abstract visionOCRStructured: ExtractedDocument | null;
  /**
   * Taxcases which this letter affects
   */
  abstract associatedTaxcases: string[] | null;
  /**
   * whether all content in the letter was fullFilled
   */
  abstract fullFilled: boolean;
  /**
   * whether this letter is for a taxadvisor and not a client
   */
  abstract forTaxAdvisor: boolean;
  /**
   * associated taxadvisor
   */
  abstract taxAdvisorId: string | null;
  /**
   * binaryDocumentReference to original letter
   */
  abstract binaryDocRef: string;
  /**
   * whether the document content was checked
   */
  abstract verified: boolean;
  /**
   * contains all tags uniquely per year
   * All the work to make firestore work
   * @example
   * {HAUSHALTSNAHE_DIENSTLEISTUNG: {2021:true,2020:true}}
   */
  abstract containedUniqueTags: UniqueContentTagMap;
  /**
   * deutsch: Aktenzeichen used to match related letters
   */
  abstract letterReference: string | null;
}

export type UniqueContentTagMap = Partial<{
  [key in ContentTags | ProviderContentTags]: Record<number, true>;
}> | null;

export interface ContentTagEntry {
  /**
   * one content tag inside a letter can adress multiple taxcases
   */
  associatedTaxcasesRefs: string[];
  /**
   * years which this tag does apply for
   */
  affectedYears: {
    [key: number | string]: boolean;
  };
  /**
   * the text, that contains the content tag
   */
  textBlock: string;
  /**
   * the content tag for this textBlock
   */
  contentTag: ContentTags | ProviderContentTags;
  /**
   * text to boundingbox mapping
   */
  textToBlockMapping: { text?: string; boundingBox?: BoundingBox }[];
}

export interface BoundingBox {
  x: number;
  y: number;
  width: number;
  height: number;
}
export interface TextSegment {
  text: string;
  boundingBox: BoundingBox | null;
}
export interface TextBlock {
  text: string;
  boundingBox: BoundingBox | null;
  TextSegments: TextSegment[];
}
export interface Page {
  blocks: TextBlock[];
  dimensions: {
    width: number;
    height: number;
    unit: string;
  };
}

export type ExtractedDocument = {
  pages: Page[];
};
