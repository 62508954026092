import { Timestamp } from '../helpers/timestamp';

export enum ClientNotificationType {
  message,
  vmdbImport,
  overdue,
}

export enum ClientNotificationLevel {
  info,
  warning,
  error,
}

export class ClientNotification {
  public type: ClientNotificationType = ClientNotificationType.vmdbImport;
  public level: ClientNotificationLevel = ClientNotificationLevel.info;

  /**
   * Optional if this notification belongs to a taxcase
   */
  public taxCaseId?: string | null = null;
  public created: Timestamp | null = null;
  public message: string = '';
  /**
   * optional : allow to link to a specific place
   */
  public actionLink: string = '';
}
