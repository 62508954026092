import { DatePipe, PercentPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isAbstractTimestamp } from '@expresssteuer/firebase-helper';
import {
  CommonField,
  FieldSuggestions,
  ParsedFieldKey,
  SerializedField,
  SerializedFieldType,
} from '@expresssteuer/models';
import { AutocompleteOptions } from '../../../form-inputs/abstract-value-accessor';

@Component({
  selector: 'esui-parsed-fields-input',
  templateUrl: './parsed-fields-input.component.html',
  styleUrls: ['./parsed-fields-input.component.scss'],
})
export class ParsedFieldsInputComponent {
  constructor(public percentPipe: PercentPipe, public datePipe: DatePipe) {}
  SerializedFieldType = SerializedFieldType;

  @Input() fieldsToDisplay:
    | { [key in string | number]: SerializedField }
    | undefined;
  @Input() fieldValues?: Record<string, unknown> = {};
  @Output() fieldValueChange = new EventEmitter<{
    key: string;
    value: unknown;
  }>();

  @Input() suggestions?: FieldSuggestions;

  getAutocompleteOptionsFromSuggestionsForKey(
    key: string
  ): AutocompleteOptions[] {
    return (this.suggestions?.[key as ParsedFieldKey] || []).map(
      (suggestion) => {
        if (isAbstractTimestamp(suggestion.suggestion)) {
          const date = suggestion.suggestion.toDate();
          const datestring = this.datePipe.transform(date, 'mediumDate');
          return {
            value: suggestion.suggestion,
            label: `${datestring} (${this.suggestionScore(
              suggestion.score || 0
            )})`,
          };
        }

        if (
          typeof suggestion.suggestion === 'number' &&
          CommonField[key as ParsedFieldKey].type === SerializedFieldType.factor
        ) {
          return {
            value: suggestion.suggestion,
            label: `${suggestion.suggestion * 100}% (${this.suggestionScore(
              suggestion.score || 0
            )})`,
          };
        }

        //default for string & normal number
        return {
          value: suggestion.suggestion,
          label: `${suggestion.suggestion} (${this.suggestionScore(
            suggestion.score || 0
          )})`,
        };
      }
    );
  }
  private suggestionScore(score: number): string {
    return this.percentPipe.transform(score, '1.0-2') || '0%';
  }
}
