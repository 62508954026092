import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TaxcaseEditorComponent } from './taxcase-editor.component';

@NgModule({
  declarations: [TaxcaseEditorComponent],
  imports: [CommonModule, MatToolbarModule, MatIconModule, MatButtonModule],
  exports: [TaxcaseEditorComponent],
})
export class EsTaxcaseEditorModule {}
