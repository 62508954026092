<div class="btn-group btn-group-lg" role="group">
  <button
    *ngFor="let option of options"
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="value === option.value"
    (click)="value = option.value"
  >
    {{ option.display }}
  </button>
</div>
