<esui-skeleton-placeholder
  [watchResult]="!!taxNotice"
  [bigBoxMode]="false"
  [skeletonBuildInput]="[
    [1, 1, 1],
    [3, 3, 3]
  ]"
>
  <ng-container *ngIf="taxNotice">
    <h2>Taxnotice Header Info</h2>
    <div class="taxnotice-header">
      <div class="tupel-fields">
        <mat-form-field>
          <input
            [disabled]="true"
            matInput
            placeholder="Year"
            [ngModel]="taxNotice.parsedHeaderData.year"
          />
        </mat-form-field>
        <mat-form-field>
          <input
            [disabled]="true"
            matInput
            placeholder="Date (DDMMYYYY)"
            [ngModel]="
              getSpecificParsedValue
                | pureMethod
                  : { args: [taxNotice, '0680000852'], thisArg: this }
            "
          />
        </mat-form-field>
      </div>

      <div class="tupel-fields">
        <mat-form-field>
          <input
            [disabled]="true"
            matInput
            placeholder="Amount Expected"
            [ngModel]="taxNotice.expectedAmount | currency : 'EUR'"
          />
        </mat-form-field>
        <mat-form-field>
          <input
            [disabled]="true"
            matInput
            placeholder="Refund Amount"
            [ngModel]="taxNotice.refundAmount | currency : 'EUR'"
          />
        </mat-form-field>
      </div>
      <div class="tupel-fields">
        <mat-form-field>
          <input
            [disabled]="true"
            matInput
            placeholder="Iban on Taxnotice"
            [ngModel]="taxNotice.payoutIban"
          />
        </mat-form-field>

        <mat-form-field>
          <input
            [disabled]="true"
            matInput
            placeholder="Iban on Elster"
            [ngModel]="taxNotice.taxcaseProvidedIban"
          />
        </mat-form-field>
      </div>
      <div class="tupel-fields">
        <mat-form-field>
          <input
            [disabled]="true"
            matInput
            placeholder="TaxId"
            [ngModel]="taxNotice.taxId"
          />
        </mat-form-field>
        <mat-form-field>
          <input
            [disabled]="true"
            matInput
            placeholder="TaxNumber"
            [ngModel]="taxNotice.taxNumber"
          />
        </mat-form-field>
      </div>
      <mat-form-field>
        <input
          [disabled]="true"
          matInput
          placeholder="Is wrong Payout Iban?"
          [ngModel]="taxNotice.wrongPayoutAccount ?? false"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          [disabled]="true"
          matInput
          placeholder="Taxcase ID"
          [ngModel]="taxNotice.taxcaseId ?? ' unknown'"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          [disabled]="true"
          matInput
          placeholder="Finance Office Number"
          [ngModel]="taxNotice.parsedHeaderData.financeOfficeNumber"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          [disabled]="true"
          matInput
          placeholder="Transfer Amount"
          [ngModel]="taxNotice.transferAmount | currency : 'EUR'"
        />
      </mat-form-field>
    </div>
    <h2>Taxnotice Data Fields</h2>
    <div class="taxnotice-datafields">
      <ng-container
        *ngFor="
          let dataField of filterByDatafields
            | pureMethod : { args: [taxNotice], thisArg: this }
        "
      >
        <div class="parsed-value-entry">
          <p class="taxnotice-descriptions">
            {{ dataField.mappedDescription }}
          </p>
          <mat-form-field>
            <input
              [disabled]="true"
              matInput
              placeholder="Value"
              [ngModel]="dataField.parsedValue"
            />
          </mat-form-field>
          <p class="taxnotice-descriptions">Field ID: {{ dataField.fullId }}</p>
        </div>
      </ng-container>
    </div>
    <div class="taxnotice-datafields">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> All taxnotice data fields</mat-panel-title>
          <mat-panel-description>
            Click here to show all Fields
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-container
          *ngFor="
            let dataField of filterZeroAndTextValues
              | pureMethod : { args: [taxNotice], thisArg: this }
          "
        >
          <div class="parsed-value-entry">
            <p class="taxnotice-descriptions">
              {{ dataField.mappedDescription }}
            </p>
            <mat-form-field>
              <input
                [disabled]="true"
                matInput
                placeholder="Value"
                [ngModel]="dataField.parsedValue"
              />
            </mat-form-field>
            <p class="taxnotice-descriptions">
              Field ID: {{ dataField.fullId }}
            </p>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </div>
    <h2>Taxnotice Text</h2>
    <div class="taxnotice-text">
      <mat-form-field class="example-full-width">
        <mat-label>Content</mat-label>
        <textarea
          [rows]="10"
          matInput
          [value]="
            mergeTextOfParsedTaxNoticeValues
              | pureMethod : { args: [taxNotice], thisArg: this }
          "
        ></textarea>
      </mat-form-field>
    </div>
  </ng-container>
</esui-skeleton-placeholder>
