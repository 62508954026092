import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { EsUserModule } from '../es-user/es-user.module';
import { EsuiUserTasksActivityComponent } from './esui-user-tasks-activity.component';

@NgModule({
  declarations: [EsuiUserTasksActivityComponent],
  imports: [CommonModule, EsUserModule, MatChipsModule, MatIconModule],
  exports: [EsuiUserTasksActivityComponent],
})
export class EsuiUserTasksActivityModule {}
