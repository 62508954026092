export type WithId<T> = T & { id: string };

/**
 * Better type safety for passing id's to a function by
 * only allowing the specific type with id to be passed
 * @example const {id}:{id:string} // will allow any object to be passed with an id
 * const a:ById<TaxNotice> // will only allow taxnotices with id to be passed
 * TODO adapt with ts 4.9 to satisfies type
 */
export type ById<T> = WithId<Partial<T>>;
