<mat-card *ngIf="user">
  <mat-card-header (click)="toggle()">
    <img *ngIf="user.photoUrl" mat-card-avatar [src]="user.photoUrl" />
    <mat-icon *ngIf="!user.photoUrl" mat-card-avatar>account_circle</mat-icon>
    <mat-card-title *ngIf="open">{{ user.displayName }}</mat-card-title>
    <mat-card-subtitle *ngIf="open">{{ user.email }}</mat-card-subtitle>
  </mat-card-header>
  <ng-container>
    <mat-card-content *ngIf="open">
      <ng-content select="[content]"></ng-content>
    </mat-card-content>
    <mat-card-actions *ngIf="open">
      <ng-content select="[actions]"></ng-content>
    </mat-card-actions>
  </ng-container>
</mat-card>
