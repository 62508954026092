<mat-icon [ngClass]="{ loading: isIdle === false }" [matTooltip]="tooltip">
  {{ icon }}</mat-icon
>

<p
  *ngIf="
    error &&
    status === FinishActivityIndicatorStatus.Failure &&
    errorMessage as em
  "
>
  {{ em }}
</p>
