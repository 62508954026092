<!--begin::Header-->
<div id="kt_header" class="header header-fixed">
  <!--begin::Header Wrapper-->
  <div
    class="header-wrapper rounded-top-xl d-flex flex-grow-1 align-items-center"
  >
    <!--begin::Container-->
    <div
      class="container-fluid d-flex align-items-center justify-content-end justify-content-lg-between flex-wrap"
    >
      <!--begin::Menu Wrapper-->
      <!--end::Toolbar-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Header Wrapper-->
</div>
<!--end::Header-->
<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container-fluid">
      <div class="d-flex flex-row" *ngIf="senders; let mList">
        <div
          class="flex-row-auto offcanvas-mobile w-350px w-xl-400px offcanvas-mobile-on"
        >
          <div class="row mt-7">
            <div class="col-6 pr-0">
              <app-user-switcher
                [allUsers]="allUsers"
                [currentUser]="filterUser"
                (valueChange)="filterUserChange($event)"
                onchange="$('#cardKeyAccountAccordion div').attr('aria-expanded') === 'false' ? $('#cardKeyAccountAccordion div').click() : ''"
              ></app-user-switcher>
            </div>
            <div class="col-4">
              <select
                class="form-control form-control-lg form-control-solid bg-white"
                #myLimiter
                type="text"
                name="limit"
                (change)="limit = myLimiter.value"
              >
                <option
                  *ngFor="let l of limits"
                  [selected]="l === limit"
                  [value]="l"
                >
                  {{ l }}
                </option>
              </select>
            </div>
            <div class="col-2 pl-0">
              <button
                class="btn btn-changeOrder btn-outline-secondary"
                (click)="changeOrder()"
              >
                <span
                  *ngIf="messagingservice.sortDirection === 'desc'"
                  class="svg-icon svg-icon-primary svg-icon-2x"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="11"
                        y="5"
                        width="2"
                        height="14"
                        rx="1"
                      />
                      <path
                        d="M6.70710678,18.7071068 C6.31658249,19.0976311 5.68341751,19.0976311 5.29289322,18.7071068 C4.90236893,18.3165825 4.90236893,17.6834175 5.29289322,17.2928932 L11.2928932,11.2928932 C11.6714722,10.9143143 12.2810586,10.9010687 12.6757246,11.2628459 L18.6757246,16.7628459 C19.0828436,17.1360383 19.1103465,17.7686056 18.7371541,18.1757246 C18.3639617,18.5828436 17.7313944,18.6103465 17.3242754,18.2371541 L12.0300757,13.3841378 L6.70710678,18.7071068 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        transform="translate(12.000003, 14.999999) scale(1, -1) translate(-12.000003, -14.999999) "
                      />
                    </g></svg
                ></span>
                <span
                  *ngIf="messagingservice.sortDirection !== 'desc'"
                  class="svg-icon svg-icon-primary svg-icon-2x"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="11"
                        y="5"
                        width="2"
                        height="14"
                        rx="1"
                      />
                      <path
                        d="M6.70710678,12.7071068 C6.31658249,13.0976311 5.68341751,13.0976311 5.29289322,12.7071068 C4.90236893,12.3165825 4.90236893,11.6834175 5.29289322,11.2928932 L11.2928932,5.29289322 C11.6714722,4.91431428 12.2810586,4.90106866 12.6757246,5.26284586 L18.6757246,10.7628459 C19.0828436,11.1360383 19.1103465,11.7686056 18.7371541,12.1757246 C18.3639617,12.5828436 17.7313944,12.6103465 17.3242754,12.2371541 L12.0300757,7.38413782 L6.70710678,12.7071068 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                    </g></svg
                ></span>
              </button>
            </div>
          </div>

          <div
            class="accordion accordion-solid accordion-toggle-plus"
            id="accordionExample6"
          >
            <div class="card m-0">
              <div
                class="card-header"
                id="cardKeyAccountAccordion"
                (click)="loadKeyAccountMessages()"
              >
                <div
                  class="card-title"
                  data-toggle="collapse"
                  data-target="#collapseOne6"
                >
                  Nachrichten von {{ filterUser?.displayName }}
                  <span class="label label-square ml-3" *ngIf="false">{{
                    mList.length
                  }}</span>
                </div>
              </div>
              <div
                id="collapseOne6"
                class="collapse show"
                data-parent="#accordionExample6"
              >
                <div class="card-body">
                  <div *ngFor="let s of mList">
                    <app-message-user-composer
                      [sender]="s"
                      (click)="setMessage(s)"
                    ></app-message-user-composer>
                  </div>
                  <div *ngIf="mList.length === 0" class="text-center p-8">
                    Keine Nachrichten vorhanden
                  </div>
                </div>
              </div>
            </div>

            <div class="card m-0">
              <div
                class="card-header"
                id="headingThree6"
                (click)="loadOpenMessages()"
              >
                <div
                  class="card-title collapsed"
                  data-toggle="collapse"
                  data-target="#collapseThree6"
                >
                  Offene Nachrichten
                  <span class="label label-square ml-3" *ngIf="false">{{
                    mList.length
                  }}</span>
                </div>
              </div>
              <div
                id="collapseThree6"
                class="collapse"
                data-parent="#accordionExample6"
              >
                <div class="card-body">
                  <div *ngFor="let s of mList">
                    <app-message-user-composer
                      [sender]="s"
                      (click)="setMessage(s)"
                    ></app-message-user-composer>
                  </div>
                  <div *ngIf="mList.length === 0" class="text-center p-8">
                    Keine Nachrichten vorhanden
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="offcanvas-mobile-overlay"></div>
        <div
          class="flex-row-fluid ml-lg-8 mt-7"
          id="kt_chat_content"
          *ngIf="selectedMessage"
        >
          <div class="card card-custom card-sticky">
            <div class="card-header align-items-center px-4 py-3">
              <div class="text-left flex-grow-1">
                <div class="btn-group">
                  <button
                    (click)="markAllRead()"
                    onclick="this.blur()"
                    class="btn btn-icon btn-light btn-hover-primary btn-sm"
                    title="Als erledigt markieren"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <i class="flaticon2-check-mark"></i>
                    </span>
                  </button>
                  <button
                    (click)="reply()"
                    onclick="this.blur()"
                    class="btn btn-icon btn-light btn-hover-primary btn-sm"
                    title="Antworten"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <i class="flaticon2-reply"></i>
                    </span>
                  </button>
                </div>
              </div>
              <div class="text-center flex-grow-1">
                <div class="text-dark-75 font-weight-bold font-size-h5">
                  <a
                    class="text-dark text-hover-info"
                    [routerLink]="['/client', selectedClient.customerId]"
                    *ngIf="selectedClient"
                  >
                    {{ selectedClient.displayName }}
                    <small>id:{{ selectedMessage.id }}</small>
                  </a>
                </div>
              </div>
              <div class="text-right flex-grow-1" *ngIf="!selectedClient"></div>
              <div class="text-right flex-grow-1" *ngIf="selectedClient">
                <button
                  *ngIf="selectedMessage.keyAccount"
                  [swal]="{
                    title: 'Zuweisen',
                    text: 'Kunde ist schon zugwiesen, willst du dir den Kunden trotzdem zuweisen ?',
                    type: 'warning',
                    showCancelButton: true
                  }"
                  (showCancelButton)="(true)"
                  (focusCancel)="(true)"
                  (confirm)="assignKeyAccount()"
                  class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                  title="Mich als Keyaccount überschreiben"
                >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                          d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        ></path>
                        <path
                          d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </button>
                <button
                  *ngIf="!selectedMessage.keyAccount"
                  (click)="assignKeyAccount()"
                  class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
                  title="Mich als Keyaccount zuweisen"
                >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                          d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        ></path>
                        <path
                          d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div
                class="messages"
                *ngIf="selectedClientCommunication | async; let mList"
              >
                <div
                  class="d-flex flex-column mb-5 {{
                    m.direction === 'inbound'
                      ? 'align-items-start'
                      : 'align-items-end'
                  }} "
                  *ngFor="let m of mList"
                >
                  <div
                    class="d-flex align-items-center"
                    *ngIf="m.direction === 'inbound'"
                  >
                    <span class="mr-2">
                      <app-message-type
                        [type]="m.type"
                        (click)="debug(m)"
                      ></app-message-type>
                    </span>
                    <span class="text-muted font-size-sm">
                      {{
                        m.metadata?.created?.toDate()
                          | date : 'dd.MM.yyyy HH:mm'
                      }}
                      ({{ m.metadata?.created?.toDate() | timeago }})
                      {{ m | sentiment }}
                    </span>
                  </div>
                  <div
                    class="d-flex align-items-center"
                    *ngIf="m.direction === 'outbound'"
                  >
                    <div>
                      <a
                        class="text-dark-75 text-hover-primary font-weight-bold font-size-h6"
                        *ngIf="m.from"
                      >
                        {{
                          m.from.displayName
                            ? m.from.displayName
                            : m.from.firstname + ' ' + m.from.lastname
                        }}
                      </a>
                      <span class="text-muted font-size-sm">
                        {{
                          m.metadata?.created?.toDate()
                            | date : 'dd.MM.yyyy HH:mm'
                        }}
                        ({{ m.metadata?.created?.toDate() | timeago }})
                      </span>
                      <span class="ml-2">
                        <app-message-type
                          [type]="m.type"
                          (click)="debug(m)"
                        ></app-message-type>
                      </span>
                    </div>
                  </div>

                  <div
                    attr.messageId="{{ m.id }}"
                    class="mt-2 rounded p-5 bg-light font-weight-bold font-size-lg text-left col-10 col-redered-message"
                    [style]="
                      m.direction === 'outbound'
                        ? 'background-color: #3bbcf4 !important;color:#fff'
                        : ''
                    "
                    [innerHTML]="renderMessage(m)"
                  ></div>
                  <div
                    class="mt-2 rounded p-5 bg-light font-weight-bold font-size-lg text-left col-10"
                    [style]="
                      m.direction === 'outbound'
                        ? 'background-color: #3bbcf4 !important;color:#fff'
                        : ''
                    "
                    *ngFor="let doc of m.attachments"
                  >
                    <app-document-inline-preview
                      class="mt-2 rounded p-5 bg-light font-weight-bold font-size-lg text-left col-10"
                      [style]="
                        m.direction === 'outbound'
                          ? 'background-color: #3bbcf4 !important;color:#fff'
                          : ''
                      "
                      *ngFor="let doc of m.attachments"
                      [documentId]="doc.id"
                    ></app-document-inline-preview>
                  </div>
                  <span
                    class="text-muted font-size-sm"
                    *ngIf="m.direction === 'inbound' && m.metadata?.read"
                  >
                    read by {{ m.metadata.readBy?.displayName }}</span
                  >
                  <app-message-status
                    class="font-size-sm"
                    *ngIf="m.direction === 'outbound'"
                    [state]="m.state"
                  ></app-message-status>
                </div>
                <!--end::Messages-->
                <div class="ps__rail-x" style="left: 0px; bottom: -301px"></div>
                <div
                  class="ps__rail-y"
                  style="top: 301px; height: 309px; right: -2px"
                ></div>
              </div>

              <div class="card-footer align-items-center">
                <div
                  class="d-flex align-items-center justify-content-between mt-5"
                >
                  <div
                    class="d-flex align-items-center justify-content-between mt-5"
                  >
                    <!-- <div
                      class="font-size-lg mr-2"
                      *ngIf="
                        selectedMessage.botAction &&
                        selectedMessage.botAction.intent !==
                          'Default Fallback Intent' &&
                        selectedMessage.botAction.confidence >= 0.5
                      "
                    >
                      <small class="d-block mb-3">
                        Bot sicherheit :
                        {{ selectedMessage.botAction.confidence | percent }}
                        Intent: {{ selectedMessage.botAction.intent }}
                      </small>
                      <div class="botAnswer mb-5 p-0">
                        {{ selectedMessage.botAction.message }}
                      </div>

                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
  <!--end::Content-->
</div>
