import { Component } from '@angular/core';

import { EventEmitter, Input, Output } from '@angular/core';
import { Sort as MaterialSort } from '@angular/material/sort';
import { Documenttype, TaxCase, Timestamp } from '@expresssteuer/models';
import { Observable } from 'rxjs';
import { EsuiLoggerService } from '../esui-debug/services/esui-logger.service';

interface Item extends TaxCase {
  'metadata.created': Timestamp | null;
}

interface Sort {
  /** The id of the column being sorted. */
  active: string;
  /** The sort direction. */
  direction: 'asc' | 'desc' | '';
}

@Component({
  selector: 'esui-taxcases-list',
  templateUrl: './esui-taxcases-list.component.html',
  styleUrls: ['./esui-taxcases-list.component.scss'],
  providers: [EsuiLoggerService],
})
export class EsuiTaxcasesListComponent {
  @Input() sort?: Sort | null;
  @Output() sortChange = new EventEmitter<Sort>();

  @Input() pageSize?: number | null;
  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() nextClick = new EventEmitter<void>();
  @Output() previousClick = new EventEmitter<void>();
  @Output() startClick = new EventEmitter<void>();

  /**
   * @note pass as an observable input as a requirement for
   * Angular CDK Tables.
   */
  @Input() list$?: Observable<Item[]>;

  displayedColumns = [
    'id' as const,
    'metadata.created' as const,
    'displayName' as const,
    'expectedTaxReturn' as const,
    'year' as const,
    'generator' as const,
    'status' as const,
    'substatus' as const,
  ];

  Documenttype = Documenttype;

  handleSortChange(sort: MaterialSort) {
    if (!this.displayedColumns.includes(sort.active as any)) {
      this.logger.warn('sorting emitted an unknown active column');
    }

    this.sortChange.next(sort as Sort);
  }

  constructor(private logger: EsuiLoggerService) {
    logger.registerOwner(this);
  }
}
