<mat-card>
  <span esuiCardKey>Taxnotice Manual Process</span>
  <mat-card-header>
    <mat-card-title> </mat-card-title>
    <mat-card-subtitle> </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="focus" *ngIf="true">
      <div class="documentViewer">
        <esui-skeleton-placeholder
          [skeletonBuildInput]="[]"
          [watchResult]="!!taxNoticeDocument"
          [bigBoxMode]="true"
        >
          <ng-container *ngIf="taxNoticeDocument">
            <esui-document-viewer
              [contentType]="taxNoticeDocument.contentType"
              [downloadUrl]="taxNoticeDocument.downloadUrl"
              [source]="taxNoticeDocument.source"
              [isLoading]="documentStoreStateIndicator?.isSaving"
              [hasErrors]="documentStoreStateIndicator?.hasErrors"
              [showNavigation]="true"
              [showDownloadButton]="true"
            ></esui-document-viewer>
          </ng-container>
        </esui-skeleton-placeholder>
      </div>
      <div class="metaViewer">
        <esui-skeleton-placeholder
          [skeletonBuildInput]="[
            [0, 1, 1, 2],
            [0, 3]
          ]"
          [watchResult]="!!taxNotice && !!sameYearTaxnotices"
          [bigBoxMode]="false"
        >
          <h3>Current Taxnotice</h3>
          <ng-container *ngIf="taxNotice">
            <div class="list">
              <div class="overviewList">
                <div class="nameEntry">Amount expected:</div>
                <div class="resultEntry">
                  {{ taxNotice?.expectedTaxReturn ?? 0 | number : '1.2-2' }} EUR
                </div>
                <div class="nameEntry">Amount of Return:</div>
                <div
                  class="resultEntry"
                  [ngStyle]="{
                    color:
                      (taxNotice.actualTaxReturn ?? 0) < 0 ? 'red' : 'green'
                  }"
                >
                  {{ taxNotice.actualTaxReturn ?? 0 | number : '1.2-2' }} EUR
                </div>
                <div class="nameEntry">Deviation of Calculation:</div>
                <div
                  class="resultEntry"
                  [ngStyle]="{
                    color: calculateReturnDeviation() > 10 ? 'red' : 'green'
                  }"
                >
                  {{ calculateReturnDeviation() | number : '1.0-0' }} %
                </div>
                <div class="nameEntry">Year:</div>
                <div class="resultEntry">{{ taxNotice.taxYear }}</div>
              </div>
            </div>

            <ng-container *ngIf="sameYearTaxnotices">
              <h3>Taxnotices of {{ taxNotice.taxYear }}</h3>
              <table mat-table [dataSource]="sameYearTaxnotices">
                <ng-container matColumnDef="Year">
                  <th mat-header-cell *matHeaderCellDef>Year</th>
                  <td mat-cell *matCellDef="let taxnotice">
                    {{ taxnotice.taxYear }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Date">
                  <th mat-header-cell *matHeaderCellDef>Date</th>
                  <td mat-cell *matCellDef="let taxnotice">
                    <ng-container
                      *ngIf="taxnotice.taxNoticeReturnDate; else noDate"
                    >
                      {{
                        taxnotice.taxNoticeReturnDate.toDate()
                          | date : 'shortDate'
                      }}
                    </ng-container>
                    <ng-template #noDate> -- </ng-template>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Return Value">
                  <th mat-header-cell *matHeaderCellDef>Return Value</th>
                  <td mat-cell *matCellDef="let taxnotice">
                    {{ taxnotice.actualTaxReturn | currency : 'EUR' }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Type">
                  <th mat-header-cell *matHeaderCellDef>Type</th>
                  <td mat-cell *matCellDef="let taxnotice">
                    {{ taxnotice.taxnoticeType }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Verified">
                  <th mat-header-cell *matHeaderCellDef>Verified</th>
                  <td mat-cell *matCellDef="let taxnotice">
                    {{ taxnotice.verified }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Appeal Status">
                  <th mat-header-cell *matHeaderCellDef>Appeal Status</th>
                  <td mat-cell *matCellDef="let taxnotice">
                    {{ taxnotice.appealedStatus }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr
                  mat-row
                  *matRowDef="let taxnotice; columns: columnsToDisplay"
                ></tr>
              </table>
            </ng-container>
          </ng-container>
        </esui-skeleton-placeholder>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button
      type="button"
      mat-raised-button
      color="primary"
      (click)="manualProcessDone.next()"
    >
      Manual Process Done
    </button>
  </mat-card-actions>
</mat-card>
