<mat-card>
  <span esuiCardKey>final review</span>

  <mat-card-header>
    <mat-card-title> </mat-card-title>
    <mat-card-subtitle>
      taxcase status:
      <esui-store-state-indicator
        [hasOnlineChangedAfterLocalChanged]="
          !!hasOnlineChangedAfterLocalChanged
        "
        [isSaving]="!!isSaving"
        [isLoading]="!!isLoading"
        [hasChanges]="!!hasChanges"
        [hasErrors]="!!hasErrors"
      ></esui-store-state-indicator>
      document status:
      <esui-store-state-indicator
        [hasOnlineChangedAfterLocalChanged]="
          !!documentHasOnlineChangedAfterLocalChanged
        "
        [isSaving]="!!documentIsSaving"
        [isLoading]="!!documentIsLoading"
        [hasChanges]="!!documentHasChanges"
        [hasErrors]="!!documentHasErrors"
      ></esui-store-state-indicator>
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div class="focus">
      <div class="documentViewer">
        <esui-document-viewer
          mat-card-image
          *ngIf="documentDownloadUrl"
          [downloadUrl]="documentDownloadUrl"
          [contentType]="documentContentType"
          [source]="documentSource"
        ></esui-document-viewer>
      </div>

      <div class="metaViewer">
        <esui-taxcase-short [taxcase]="taxcase"></esui-taxcase-short>
        <esui-calculation
          [taxRefund]="taxcase?.taxform?.current?.expectedTaxReturn"
        ></esui-calculation>
        <esui-taxcase-comments
          [taxcaseComments]="taxcaseComments"
        ></esui-taxcase-comments>
      </div>
    </div>
  </mat-card-content>

  <mat-card-actions>
    <button mat-raised-button (click)="submitTaskClick.next()">
      send to customer
    </button>
    <button mat-button (click)="showCommentClick.next()">
      more work needed
    </button>
    <div class="comment" *ngIf="showComment">
      <mat-form-field>
        <mat-label>Reason</mat-label>
        <input
          matInput
          [ngModel]="comment"
          (ngModelChange)="commentChange.next($event)"
        />
        <button
          matSuffix
          mat-icon-button
          aria-label="send"
          (click)="moreWorkNeededClick.next()"
          [disabled]="!comment"
        >
          <mat-icon>send</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </mat-card-actions>
</mat-card>
