import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsStoreStateIndicatorModule } from '../es-store-state-indicator/es-store-state-indicator.module';
import { EsuiDocumentViewerModule } from '../esui-document-viewer/esui-document-viewer.module';
import { EsuiSkeletonPlaceholderModule } from '../esui-skeleton-placeholder/esui-skeleton-placeholder.module';
import { DateInputModule } from '../form-inputs/date-input/date-input.module';
import { EsuiTaxnoticeInformBackpayerComponent } from './esui-taxnotice-inform-backpayer.component';

@NgModule({
  declarations: [EsuiTaxnoticeInformBackpayerComponent],
  imports: [
    CommonModule,
    FormsModule,
    EsuiDocumentViewerModule,
    EsStoreStateIndicatorModule,
    MatCardModule,
    MatListModule,
    MatDividerModule,
    EsCardKeyModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    DateInputModule,
    MatTableModule,
    MatGridListModule,
    EsuiSkeletonPlaceholderModule,
  ],
  exports: [EsuiTaxnoticeInformBackpayerComponent],
})
export class EsuiTaxnoticeInformBackpayerModule {}
