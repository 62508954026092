import { Component, EventEmitter, Output } from '@angular/core';
import { Client } from '@expresssteuer/models';
import { ClientInlineComponent } from '../client-inline/client-inline.component';

@Component({
  selector: 'esui-client-editor-x',
  templateUrl: './client-editor-x.component.html',
  styleUrls: ['./client-editor-x.component.scss'],
})
export class ClientEditorXComponent extends ClientInlineComponent {
  @Output()
  clientPartialChange = new EventEmitter<Partial<Client>>();

  @Output()
  clientChange = new EventEmitter<Client>();

  @Output()
  saveClick = new EventEmitter<void>();

  partialUpdate(change: Partial<Client>) {
    const merged = {
      ...(this.client ?? Client.getTemplate()),
      ...change,
    };
    this.clientChange.next(merged);
    this.clientPartialChange.next(change);
  }
}
