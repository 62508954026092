import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientFileUploadState } from '@expresssteuer/models';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
export interface TextingTranslationInputDocumentUploader {
  uploadDescription: string;
  uploadZoneText: string;
  commentSectionLabel: string;
  disclaimerTextConfirmUpload: string;
  disclaimerTextNotAvailable: string;
  remarkSendNotificationText: string;
  remarkSendErrorNotificationText: string;
  explanationTextId: string | null;
}
@Component({
  selector: 'esui-document-uploader',
  templateUrl: './esui-document-uploader.component.html',
  styleUrls: ['./esui-document-uploader.component.scss'],
})
export class EsuiDocumentUploaderComponent {
  constructor(private _snackBar: MatSnackBar) {}
  /**
   * Inputs for content text
   */
  @Input()
  translationContent?: TextingTranslationInputDocumentUploader;
  /**
   * Data inputs
   */
  @Input()
  clientDocumentUploadStates?: ClientFileUploadState[];

  @Input()
  multiUpload: boolean | undefined = false;

  @Input()
  fileNameShown = true;

  @Input()
  enableRemark = true;

  @Input()
  userMarkedUploadAsDone: boolean | undefined = false;

  @Input()
  userMarkedAsNotAvailable = false;

  @Input()
  isMandatory = false;

  @Input()
  remarkInput = '';

  @Input()
  public set remarkSentSuccessfully(remarkSentSuccessfully: boolean | null) {
    if (remarkSentSuccessfully !== null) {
      if (remarkSentSuccessfully) {
        //TODO Replace Snackbar
        this._snackBar.open(
          this.translationContent?.remarkSendNotificationText ?? '',
          undefined,
          {
            duration: 2000,
          }
        );
        this.remarkInput = '';
      } else {
        this._snackBar.open(
          this.translationContent?.remarkSendErrorNotificationText ?? '',
          undefined,
          {
            duration: 2000,
          }
        );
      }
    }
  }

  @Output()
  fileSelected = new EventEmitter<File>();

  @Output()
  fileMarkedForDeletion = new EventEmitter<string>();

  @Output()
  clientDocumentUploadStateChange = new EventEmitter<ClientFileUploadState>();
  @Output()
  clientDocumentUploadPartialStateChange = new EventEmitter<
    Partial<ClientFileUploadState> & { id: string }
  >();

  @Output()
  userMarkedUploadAsDoneChange = new EventEmitter<boolean>();

  @Output()
  userMarkedAsNotAvailableChange = new EventEmitter<boolean>();

  @Output()
  newRemark = new EventEmitter<string>();

  partialClientDocumentUploadStateChange(
    change: Partial<ClientFileUploadState> & { id: string }
  ) {
    const matching = this.clientDocumentUploadStates?.filter(
      (e) => e.id === change.id
    );
    if (matching && matching.length > 0) {
      const merged = {
        ...change,
        ...matching[0],
      };
      this.clientDocumentUploadStateChange.next(merged);
      this.clientDocumentUploadPartialStateChange.next(change);
    }
  }

  addFile(event: NgxDropzoneChangeEvent) {
    this.fileSelected.next(event.addedFiles[0]);
  }

  onFileDeleteClick(doc: ClientFileUploadState) {
    this.fileMarkedForDeletion.next(doc.id);
  }

  allDocumentsSubmitted(event: MatCheckboxChange) {
    if (event.checked) {
      this.userMarkedUploadAsDoneChange.next(true);
    }
  }

  sendRemark() {
    console.log(`sending remark: ${this.remarkInput}`);
    this.newRemark.next(this.remarkInput);
  }
}
