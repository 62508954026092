import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFireAuthModule,
  USE_EMULATOR as USE_AUTH_EMULATOR,
} from '@angular/fire/compat/auth';
import {
  AngularFirestoreModule,
  SETTINGS,
} from '@angular/fire/compat/firestore';
import {
  AngularFireFunctionsModule,
  REGION,
} from '@angular/fire/compat/functions';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgAisModule } from 'angular-instantsearch';
import { NgxMaskModule } from 'ngx-mask';
import { TimeagoModule } from 'ngx-timeago';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AisDocumentsModule } from './modules/ais-documents/ais-documents.module';
import { GlobalErrorHandler } from './services/helpers/global-error-handler';
import { CardTaxcasesComponent } from './shared/card-taxcases/card-taxcases.component';
import { RowTaxcaseComponent } from './shared/card-taxcases/row-taxcase/row-taxcase.component';
import { TaxcasePaginationComponent } from './shared/card-taxcases/taxcase-pagination/taxcase-pagination.component';
import { ControlJobOffsiteDaysComponent } from './shared/components/control-job-offsite-days/control-job-offsite-days.component';
import { ControlJobUnionselectComponent } from './shared/components/control-job-unionselect/control-job-unionselect.component';
import { ControlMessagingUsernameComponent } from './shared/components/control-messaging-username/control-messaging-username.component';
import { ControlDisabilityselectComponent } from './shared/components/disabilityselect/disabilityselect.component';
import { DocumentCategoryComponent } from './shared/components/documents/document-preview/document-category/document-category.component';
import { DocumentPreviewComponent } from './shared/components/documents/document-preview/document-preview.component';
import { ControlReligionselectComponent } from './shared/components/religionselect/religionselect.component';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { ControlActionbarTaxcaseComponent } from './shared/controls/control-actionbar-taxcase/control-actionbar-taxcase.component';
import { ControlClientInfosComponent } from './shared/controls/control-client-infos/control-client-infos.component';
import { ControlMessageMessagePreviewComponent } from './shared/controls/control-message-message-preview/control-message-message-preview.component';
import { ControlMessageMessageactionsDropdownComponent } from './shared/controls/control-message-messageactions-dropdown/control-message-messageactions-dropdown.component';
import { ControlMessageTwilioStatusComponent } from './shared/controls/control-message-twilio-status/control-message-twilio-status.component';
import { ControlUserDisplayComponent } from './shared/controls/control-user-display/control-user-display.component';
import { StatusDisplayComponent } from './shared/controls/status-display/status-display.component';
import { HeaderMobileComponent } from './shared/layout/headermobile/header-mobile.component';
import { NoteModalEditorComponent } from './shared/modals/note-modal-editor/note-modal-editor.component';
import { SepaSetDateComponent } from './shared/modals/sepa-set-date/sepa-set-date.component';
import { PartnerLogoPipe } from './shared/pipes/partner-logo.pipe';
import { TrustedPipePipe } from './shared/pipes/TrustedPipe';
import { ButtonStatusEntryAcceptComponent } from './shared/status/buttons/button-status-entry-accept/button-status-entry-accept.component';
import { ButtonStatusEntryDeclineComponent } from './shared/status/buttons/button-status-entry-decline/button-status-entry-decline.component';
import { ButtonStatusInProgressTaxofficeSendComponent } from './shared/status/buttons/button-status-in-progress-taxoffice-send/button-status-in-progress-taxoffice-send.component';
import { ButtonStatusInProgressTaxofficeTestComponent } from './shared/status/buttons/button-status-in-progress-taxoffice-test/button-status-in-progress-taxoffice-test.component';
import { StatusbuttonsListComponent } from './shared/status/statusbuttons-list/statusbuttons-list.component';
import { ClientlistComponent } from './views/pages/clients/clientlist/clientlist.component';
import { ClientnavigationComponent } from './views/pages/clients/clientnavigation/clientnavigation.component';
import { ClientsNewComponent } from './views/pages/clients/clients-new/clients-new.component';
import { ClientsComponent } from './views/pages/clients/clients.component';
import { MessageComposerComponent } from './views/pages/messaging-v2/components/message-composer/message-composer.component';
import { MessageStatusComponent } from './views/pages/messaging-v2/components/message-status/message-status.component';
import { MessageTypeComponent } from './views/pages/messaging-v2/components/message-type/message-type.component';
import { MessageUserComponent } from './views/pages/messaging-v2/components/message-user/message-user-composer.component';
import { StatusBubbleComponent } from './views/pages/messaging-v2/components/status-bubble/status-bubble.component';
import { UserSwitcherComponent } from './views/pages/messaging-v2/components/user-switcher/user-switcher.component';
import { MessagingV2Component } from './views/pages/messaging-v2/messaging-v2.component';
import { SentimentPipe } from './views/pages/messaging-v2/sentiment.pipe';
import { NotFoundComponent } from './views/pages/not-found/not-found.component';
import { SearchComponent } from './views/pages/search/search.component';
import { DashboardNavigationComponent } from './views/pages/taxcases/dashboard-navigation/dashboard-navigation.component';
import { DashboardComponent } from './views/pages/taxcases/dashboard.component';
import { TaxcasesArchivedComponent } from './views/pages/taxcases/taxcases-archived/taxcases-archived.component';
import { TaxcasesDoneComponent } from './views/pages/taxcases/taxcases-done/taxcases-done.component';
import { TaxcasesEntryComponent } from './views/pages/taxcases/taxcases-entry/taxcases-entry.component';
import { TaxcasesInProgressComponent } from './views/pages/taxcases/taxcases-in-progress/taxcases-in-progress.component';
import { TaxcasesMyCasesComponent } from './views/pages/taxcases/taxcases-my-cases/taxcases-my-cases.component';
import { TaxcasesRejectedComponent } from './views/pages/taxcases/taxcases-rejected/taxcases-rejected.component';

registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ClientsComponent,
    ClientlistComponent,
    ClientnavigationComponent,
    PartnerLogoPipe,
    TrustedPipePipe,
    ControlReligionselectComponent,
    ControlDisabilityselectComponent,
    DashboardNavigationComponent,
    ControlJobUnionselectComponent,
    ControlJobOffsiteDaysComponent,
    ControlMessagingUsernameComponent,
    DocumentPreviewComponent,
    MessageComposerComponent,
    CardTaxcasesComponent,
    RowTaxcaseComponent,
    ButtonStatusInProgressTaxofficeTestComponent,
    ButtonStatusInProgressTaxofficeSendComponent,
    ButtonStatusEntryAcceptComponent,
    ButtonStatusEntryDeclineComponent,
    TaxcasesInProgressComponent,
    TaxcasesMyCasesComponent,
    TaxcasesRejectedComponent,
    TaxcasesEntryComponent,
    TaxcasesArchivedComponent,
    StatusDisplayComponent,
    StatusbuttonsListComponent,
    HeaderMobileComponent,
    ControlUserDisplayComponent,
    ControlActionbarTaxcaseComponent,
    DocumentCategoryComponent,
    ControlMessageMessagePreviewComponent,
    ControlMessageTwilioStatusComponent,
    ControlMessageMessageactionsDropdownComponent,
    ControlClientInfosComponent,
    TaxcasePaginationComponent,
    NoteModalEditorComponent,
    TaxcasesDoneComponent,
    ClientsNewComponent,
    SearchComponent,
    SepaSetDateComponent,
    MessagingV2Component,
    StatusBubbleComponent,
    UserSwitcherComponent,
    MessageTypeComponent,
    MessageStatusComponent,

    NotFoundComponent,
    MessageUserComponent,
    SentimentPipe,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireMessagingModule,
    SharedComponentsModule,
    BrowserAnimationsModule,
    FormsModule,
    TimeagoModule.forRoot(),
    NgxMaskModule.forRoot(),
    HttpClientModule,
    NgAisModule.forRoot(),
    AisDocumentsModule,
  ],
  providers: [
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: REGION, useValue: 'europe-west3' },
    {
      provide: SETTINGS,
      useValue: environment.emulator
        ? {
            host: 'localhost:8080',
            ssl: false,
          }
        : undefined,
    },
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.emulator ? ['http://localhost:9099'] : undefined,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
