import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import { Timestamp } from '@angular/fire/firestore';
import { BinaryDocument, BinaryFile } from '@expresssteuer/models';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { TaxcasesService } from '../../../../../app/services/data/taxcases.service';

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss'],
})
export class UploadTaskComponent implements OnInit {
  @Input() file: File;

  task: AngularFireUploadTask;

  percentage: Observable<number | undefined>;
  snapshot: Observable<any>;
  downloadURL: string;

  constructor(
    private taxcasesservice: TaxcasesService,
    private storage: AngularFireStorage,
    private db: AngularFirestore
  ) {}

  ngOnInit() {
    this.startUpload();
  }

  startUpload() {
    const document: BinaryDocument = BinaryDocument.getTemplate();
    document.name = this.file.name;

    // The storage path
    const path = `documents/${Date.now()}_${this.file.name}`;

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, this.file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize(async () => {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        document.contentType = this.file.type;
        document.uploaded = Timestamp.now();
        document.metadata.client = this.taxcasesservice.currentTaxCase.client;
        document.metadata.taxcaseid = this.taxcasesservice.currentTaxCase.id;
        document.metadata.clientId =
          this.taxcasesservice.currentTaxCase.client.id;
        document.file = BinaryFile.getTemplate();
        document.file.downloadUrl = this.downloadURL;
        document.file.filepath = path;
        document.file.filename = this.file.name;
        document.size = (await this.task).totalBytes;
        document.modified = Timestamp.now();

        this.db.collection('documentcenter').add(document);
      })
    );
  }

  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }
}
