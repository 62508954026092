import { formatCurrency } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'esui-generic-list',
  templateUrl: './esui-generic-list.component.html',
  styleUrls: ['./esui-generic-list.component.scss'],
})
export class EsuiGenericListComponent {
  @Input()
  displayItems: DisplayItem[] | undefined | null;

  @Input()
  elevated?: boolean | null = true;
}

export class DisplayItem {
  _id = '';
  _label = '';
  _value: number | string | Date | boolean = 0;
  _type: 'number' | 'string' | 'date' | 'boolean' | 'currency' = 'currency';
  _options?: DisplayOptions;

  constructor(
    id: string,
    label: string,
    value: number | string | Date | boolean,
    type: 'number' | 'string' | 'date' | 'boolean' | 'currency',
    options?: DisplayOptions
  ) {
    this._id = id;
    this._label = label;
    this._value = value;
    this._type = type;
    this._options = options;
  }

  get id() {
    return this._id;
  }

  get label(): string {
    return this._label;
  }

  get options(): DisplayOptions | undefined {
    return this._options;
  }

  get value(): string {
    let v = '';

    if (this._value) {
      switch (this._type) {
        case 'currency':
          v = formatCurrency(this._value as number, 'de-DE', '€');
          break;
        case 'number':
          v = (this._value as number).toFixed(2);
          break;
        case 'boolean':
          v = this._value ? 'Ja' : 'Nein';
          break;
        case 'date':
          v = new Date(this._value as Date).toLocaleDateString();
          break;
        default:
          v = this._value as string;
          break;
      }
    }

    return v;
  }
}

export interface DisplayOptions {
  bold?: boolean;
  indent?: boolean;
  doubleTopBorder?: boolean;
  headerText?: string;
}
