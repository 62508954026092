import { Component, Input } from '@angular/core';
import { StoreStateIndicatorModel } from './store-state-indicator.interface';

@Component({
  selector: 'esui-store-state-indicator',
  templateUrl: './store-state-indicator.component.html',
  styleUrls: ['./store-state-indicator.component.scss'],
})
export class StoreStateIndicatorComponent implements StoreStateIndicatorModel {
  @Input() hasOnlineChangedAfterLocalChanged = false;
  @Input() isSaving = false;
  @Input() isLoading = false;
  @Input() hasChanges = false;
  @Input() hasErrors = false;

  get syncTooltip() {
    const tooltips = [];
    if (this.hasOnlineChangedAfterLocalChanged) {
      tooltips.push('the system found a new version online');
    }
    if (this.isLoading) {
      tooltips.push('loading');
    }
    if (this.isSaving) {
      tooltips.push('saving');
    } else {
      if (this.hasChanges) {
        tooltips.push('unsaved');
      } else {
        tooltips.push('saved');
      }
    }
    return tooltips.join(', ');
  }
}
