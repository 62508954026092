import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  TAXCASESTATUS,
  TaxCaseStatus,
  TAXCASESTATUS_INPROGRESS,
  TAXCASESTATUS_REJECT,
} from '@expresssteuer/models';

@Component({
  selector: 'app-status-display',
  templateUrl: './status-display.component.html',
  styleUrls: ['./status-display.component.scss'],
})
export class StatusDisplayComponent implements OnInit {
  TAXCASESTATUS = TAXCASESTATUS;
  TAXCASESTATUS_INPROGRESS = TAXCASESTATUS_INPROGRESS;
  TAXCASESTATUS_REJECT = TAXCASESTATUS_REJECT;

  constructor() {}

  public label = 'new';
  public colorClass = 'symbol-light-success';
  public statusMessage = '';

  private localStatus: TaxCaseStatus;

  @Output()
  Change = new EventEmitter<TaxCaseStatus>();

  @Input()
  get status(): TaxCaseStatus {
    return this.localStatus;
  }

  set status(val: TaxCaseStatus) {
    this.localStatus = val;

    switch (val.current) {
      case TAXCASESTATUS.ENTRY:
        this.label = 'Neu';
        this.colorClass = 'symbol-light-dark';
        this.statusMessage = 'Neuer Steuerfall';
        break;
      case TAXCASESTATUS.INPROGRESS:
        this.label = 'Be';
        this.colorClass = 'symbol-light-danger';
        this.statusMessage = 'In Bearbeitung';
        break;
      case TAXCASESTATUS.REJECT:
        this.label = 'X';
        this.colorClass = 'symbol-danger';
        this.statusMessage = 'Abgelehnt';
        break;
      default:
        this.label = val.current;
        this.colorClass = '';
        this.statusMessage = 'Unbekannter Status : ' + val.current;
    }
  }

  ngOnInit(): void {}
}
