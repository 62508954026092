import { Component, Input } from '@angular/core';

@Component({
  selector: 'esui-audio-viewer',
  templateUrl: './audio-viewer.component.html',
  styleUrls: ['./audio-viewer.component.scss'],
})
export class AudioViewerComponent {
  @Input()
  public downloadUrl!: string;
}
