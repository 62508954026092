import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sepa-set-date',
  templateUrl: './sepa-set-date.component.html',
  styleUrls: ['./sepa-set-date.component.scss'],
})
export class SepaSetDateComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  private paymnt: any;

  public set payment(val: any) {
    this.paymnt = val;
  }

  public get payment(): any {
    return this.paymnt;
  }

  public set() {}
}
