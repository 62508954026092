import { Component, Inject } from '@angular/core';
import {
  OnlineStatusService,
  ONLINE_STATUS_SERVICE,
} from './online-status.service';

@Component({
  selector: 'esui-online-status',
  templateUrl: './online-status.component.html',
  styleUrls: ['./online-status.component.scss'],
})
export class OnlineStatusComponent {
  constructor(
    @Inject(ONLINE_STATUS_SERVICE)
    public onlineStatusService: OnlineStatusService
  ) {}
}
