<svg
  xmlns="http://www.w3.org/2000/svg"
  id="Layer_1"
  data-name="Layer 1"
  viewBox="0 0 1821.66 244.58"
>
  <path
    d="M385.05,176.26c26.3,0,47.18-15.73,47.18-49.36,0-33.89-20.88-49.62-47.18-49.62S335.7,93.55,335.7,126.9c0,32.81,23.05,49.36,49.35,49.36M311,59.39h24.13v32.8c9.5-21.15,28.75-35.52,57.77-35.52,38.78,0,63.17,29,63.17,70.23,0,40.95-24.39,70-63.17,70-29.84,0-48.81-14.91-57.77-35.52v83.24H311Z"
  />
  <path
    d="M500.85,59.39h76.2V80H506V194.15H481.87V78.09c0-12.74,6.24-18.7,19-18.7"
  />
  <path
    d="M694.75,114.16c-1.64-24.4-18.18-38.51-42.31-38.51-22.23,0-39,14.11-40.94,38.51ZM652.44,56.67c39.87,0,65.9,24.14,65.9,65.9v8.67H611.23c.81,26.31,14.64,46.37,42.57,46.37,23.05,0,36.6-11.93,41.21-30.64h23.87c-5.42,24.94-23.33,49.89-64.81,49.89-43.93,0-66.17-32-66.17-71.86,0-43.65,26-68.33,64.54-68.33"
  />
  <path
    d="M743,144.53h24.13c0,22.24,14.64,33.35,39.6,33.35,19,0,28.74-6,28.74-19.8,0-9.21-5.69-14.63-19.8-18.43l-36.06-10.58c-15.18-4.34-31.19-14.1-31.19-35.52,0-23.59,19.53-36.88,51.52-36.88,34.71,0,56.41,16,56.41,45.29H832.21c0-16.28-11.38-26.58-32-26.58-17.35,0-27.93,7.6-27.93,17.63,0,8.95,6,14.37,19.26,18.17L823,120.12c22.78,6.51,36.61,17.63,36.61,38.51,0,24.4-18.44,38.23-53.15,38.23-40.13,0-63.45-19-63.45-52.33"
  />
  <path
    d="M882.09,144.53h24.14c0,22.24,14.64,33.35,39.59,33.35,19,0,28.74-6,28.74-19.8,0-9.21-5.69-14.63-19.79-18.43l-36.06-10.58c-15.19-4.34-31.19-14.1-31.19-35.52,0-23.59,19.53-36.88,51.52-36.88,34.71,0,56.4,16,56.4,45.29H971.31c0-16.28-11.39-26.58-32-26.58C922,75.38,911.38,83,911.38,93c0,8.95,6,14.37,19.25,18.17l31.45,8.94c22.79,6.51,36.62,17.63,36.62,38.51,0,24.4-18.44,38.23-53.15,38.23-40.13,0-63.46-19-63.46-52.33"
  />
  <path
    d="M1026.34,100.33C1026.34,40.13,1059.7,0,1117.45,0c46.37,0,77.56,27.12,83.79,71.31H1176.3c-4.35-31.72-25.22-50.16-58-50.16-43.65,0-67,32-67,79.73,0,41.21,17.9,75.1,65.89,75.1,42,0,64.27-23,62.91-65.62h-74v-19h82.16c11.66,0,17.09,5.41,17.09,16v86.77h-24.68V155.92c-5.42,16.54-23.32,41.21-68.33,41.21-58,0-86-41.75-86-96.8"
  />
  <path
    d="M1261.45,59.39h76.21V80h-71.05V194.15h-24.14V78.09c0-12.74,6.24-18.7,19-18.7"
  />
  <path
    d="M1417.1,176.26c30.65,0,47.46-22.24,47.46-49.63,0-27.12-16.81-49.35-47.46-49.35-30.9,0-47.72,22.23-47.72,49.35,0,27.39,16.82,49.63,47.72,49.63m0-119.59c42.85,0,71.32,30.1,71.32,70,0,40.14-28.47,70.23-71.32,70.23-43.11,0-71.59-30.09-71.59-70.23,0-39.86,28.48-70,71.59-70"
  />
  <path
    d="M1512.82,140.19V59.38h24.4v78.37c0,27.66,10.31,38.5,33.36,38.5,29.55,0,47.73-23.31,47.73-59.65V59.38h23.85V194.15h-23.85V157.27c-7.33,24.41-26.31,39.59-55.87,39.59-31.18,0-49.62-20.33-49.62-56.67"
  />
  <path
    d="M1750.61,176.26c26.31,0,47.18-15.73,47.18-49.36,0-33.89-20.87-49.62-47.18-49.62s-49.35,16.27-49.35,49.62c0,32.81,23,49.36,49.35,49.36m-74-116.87h24.13v32.8c9.5-21.15,28.75-35.52,57.77-35.52,38.78,0,63.18,29,63.18,70.23,0,40.95-24.4,70-63.18,70-29.83,0-48.81-14.91-57.77-35.52v83.24h-24.13Z"
  />
  <polygon
    points="0 2.98 0 194.15 132.87 194.15 132.87 173.01 24.68 173.01 24.68 108.74 119.31 108.74 119.31 88.13 24.68 88.13 24.68 24.14 132.87 24.14 132.87 2.98 0 2.98"
  />
  <polygon
    points="200.94 126.36 151.59 59.38 178.98 59.38 219.92 116.33 260.87 59.38 288.25 59.38 239.17 126.36 288.25 194.15 260.87 194.15 219.92 137.21 178.98 194.15 151.59 194.15 200.94 126.36"
  />
</svg>
