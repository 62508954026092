import { TASKTYPE } from '@expresssteuer/models';

export abstract class TaskHelper {
  static taskTypeToIcon(type: string | TASKTYPE): string {
    switch (type) {
      case TASKTYPE.DOCUMENT_CLASSIFICATION:
      case TASKTYPE.TAXCASE_OPTIMIZATION:
        return 'document_scanner';
      case TASKTYPE.DOCUMENT_REQUEST:
        return 'upload_file';
      case TASKTYPE.INFORMATION_REQUEST:
        return 'contact_support'; // call_to_action contact_phone ?
      case TASKTYPE.FINAL_TAXCASE_REVIEW:
        return 'send';
      case TASKTYPE.JOBGROUP_ASSIGNMENT:
        return 'work_outline';
      case TASKTYPE.JOBGROUP_OPTIMIZATION:
        return 'star';
      case TASKTYPE.CLIENT_TODO_HELP_GENERIC:
      case TASKTYPE.CLIENT_TODO_HELP_IDENTIFYINGDOCUMENT:
      case TASKTYPE.CLIENT_TODO_HELP_TAXID:
      case TASKTYPE.FAILING_VM_REQUEST:
      case TASKTYPE.CLIENT_TODO_HELP_VMA_PROOF:
        return 'live_help';
      // <-NEW-TASK-PLACEHOLDER-MARKER::taskTypeToIcon->
      default:
        return 'task';
    }
  }

  static taskTypeToLabel(type: string | TASKTYPE): string {
    switch (type) {
      case TASKTYPE.DOCUMENT_CLASSIFICATION:
        return 'Document classification';
      case TASKTYPE.DOCUMENT_REQUEST:
        return 'Document request';
      case TASKTYPE.INFORMATION_REQUEST:
        return 'Information request';
      case TASKTYPE.FINAL_TAXCASE_REVIEW:
        return 'Final taxcase review';
      case TASKTYPE.FIX_FINANCE_DEPARTMENT_ID:
        return 'Fix finance office ID';
      case TASKTYPE.DOCUMENT_TO_CLIENT_ASSIGNMENT:
        return 'assignment';
      case TASKTYPE.TAXNOTICE_VERIFICATION:
        return 'taxnotice verification';
      case TASKTYPE.TAXNOTCE_APPEAL:
        return 'taxnotice appeal';
      case TASKTYPE.TAXNOTCE_SUBMIT_APPEAL:
        return 'taxnotice submit appeal';
      case TASKTYPE.TAXNOTICE_MANUAL_PROCESS:
        return 'taxnotice manual process';
      case TASKTYPE.JOBGROUP_ASSIGNMENT:
        return 'jobgroup assignment';
      case TASKTYPE.JOBGROUP_OPTIMIZATION:
        return 'jobgroup expense optimization';
      case TASKTYPE.PAYOUT_IBAN_NO_MATCH:
        return 'payout account no match verification';
      case TASKTYPE.PAYOUT_BATCH_MERGER:
        return 'match taxnotice against incomming transaction';
      case TASKTYPE.CLIENT_TODO_HELP_GENERIC:
        return 'client todo help (generic)';
      case TASKTYPE.CLIENT_TODO_HELP_IDENTIFYINGDOCUMENT:
        return 'client todo help (identifying-document)';
      case TASKTYPE.CLIENT_TODO_HELP_TAXID:
        return 'client todo help (tax-id)';
      case TASKTYPE.CLIENT_TODO_HELP_VMA_PROOF:
        return 'client todo help (vma-proof)';
      case TASKTYPE.FAILING_VM_REQUEST:
        return 'failing vm request';
      case TASKTYPE.TAXCASE_OPTIMIZATION:
        return 'taxcase';
      // <-NEW-TASK-PLACEHOLDER-MARKER::taskTypeToLabel->
      default:
        return 'Task';
    }
  }

  static taskTypeToDescription(type: string | TASKTYPE): string {
    switch (type) {
      case TASKTYPE.DOCUMENT_CLASSIFICATION:
        return 'A document was received that needs to be verified.';
      case TASKTYPE.DOCUMENT_REQUEST:
        return 'A document is needed.';
      case TASKTYPE.INFORMATION_REQUEST:
        return 'Some information is needed.';
      case TASKTYPE.FINAL_TAXCASE_REVIEW:
        return 'A taxcase needs to be reviewed and send to the tax office';
      case TASKTYPE.FIX_FINANCE_DEPARTMENT_ID:
        return 'A taxsetting has faulty or missing finance office information';
      case TASKTYPE.DOCUMENT_TO_CLIENT_ASSIGNMENT:
        return 'A document needs to be assigned to a client';
      case TASKTYPE.TAXNOTICE_VERIFICATION:
        return 'verify content of taxnotice';
      case TASKTYPE.TAXNOTCE_APPEAL:
        return 'decide wether to appeal or not';
      case TASKTYPE.TAXNOTCE_SUBMIT_APPEAL:
        return 'submit appeal';
      case TASKTYPE.TAXNOTICE_MANUAL_PROCESS:
        return 'accept when manual processes are done';
      case TASKTYPE.JOBGROUP_ASSIGNMENT:
        return 'assign jobgroup';
      case TASKTYPE.JOBGROUP_OPTIMIZATION:
        return 'optimize jobgroup expenses';
      case TASKTYPE.PAYOUT_IBAN_NO_MATCH:
        return 'generate invoice if payout was directly sent to customer';
      case TASKTYPE.PAYOUT_BATCH_MERGER:
        return 'match taxnotices to transactions for customer payout';
      case TASKTYPE.EXTRACT_TAXOFFICE_REQUEST_CONTENT:
        return 'extract tax office request content';
      case TASKTYPE.TAXNOTICE_INFORM_BACKPAYER:
        return 'inform backpayer about the sad truth';
      case TASKTYPE.CLIENT_TODO_HELP_GENERIC:
        return 'A client needs help with a generic todo';
      case TASKTYPE.CLIENT_TODO_HELP_IDENTIFYINGDOCUMENT:
        return 'A client needs help with its identifying-document todo';
      case TASKTYPE.CLIENT_TODO_HELP_TAXID:
        return 'A client needs help with its tax-id todo';
      case TASKTYPE.CLIENT_TODO_HELP_VMA_PROOF:
        return 'A client needs help with its vma-proof todo';
      case TASKTYPE.FAILING_VM_REQUEST:
        return 'A client needs help with its failng VM request';
      case TASKTYPE.TAXCASE_OPTIMIZATION:
        return 'Optimierung';
      // <-NEW-TASK-PLACEHOLDER-MARKER::taskTypeToDescription->
      default:
        return 'This task needs some general attention.';
    }
  }
}
