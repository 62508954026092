import { Component, Input, OnInit } from '@angular/core';

export enum State {
  danger = 'danger',
  warning = 'warning',
  pending = 'pending',
  success = 'success',
}

@Component({
  selector: 'app-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
})
export class StatusIndicatorComponent implements OnInit {
  @Input() status: State;

  constructor() {}

  ngOnInit(): void {}
}
