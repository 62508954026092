<ng-container *ngIf="todo">
  <ng-container
    *ngIf="{
      status:
        (todo.acceptedAnswers?.length ?? 0) > 0
          ? 'accepted'
          : todo.active
          ? 'active'
          : 'inactive'
    } as todoData"
  >
    <mat-list>
      <mat-list-item>
        {{ todo.type }}
      </mat-list-item>

      <ng-container [ngSwitch]="todoData.status">
        <ng-container *ngSwitchCase="'accepted'">
          <mat-list-item>
            <div matLine>accepted</div>
            <div matLine>since {{ todo.activeLastSetAt | asDate | date }}</div>
          </mat-list-item>
        </ng-container>
        <ng-container *ngSwitchCase="'active'">
          <mat-list-item>
            <div matLine class="counter">
              <span matTooltip="drafts">
                <mat-icon mat-list-icon> edit_note </mat-icon>
                <span>{{ todo.draftAnswers.length }}</span>
              </span>
              <span matTooltip="processing">
                <mat-icon mat-list-icon> hourglass_top </mat-icon>
                <span>{{ todo.processingAnswers.length }}</span>
              </span>
              <span matTooltip="faulty">
                <mat-icon mat-list-icon> error </mat-icon>
                <span>{{ todo.faultyAnswers.length }}</span>
              </span>
            </div>
          </mat-list-item>

          <ng-container *ngIf="todo.helpNeeded">
            <mat-list-item>
              <div matLine>
                <span [matTooltip]="todo.escalatedBy ?? ''">help needed</span>
              </div>
              <div matLine>
                <span>since {{ todo.lastEscalatedAt | asDate | date }}</span>
              </div>
            </mat-list-item>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'inactive'">
          <mat-list-item>
            <div matLine>inactive</div>
            <div matLine>since {{ todo.activeLastSetAt | asDate | date }}</div>
          </mat-list-item>
        </ng-container>
      </ng-container>
    </mat-list>
  </ng-container>
</ng-container>
