import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgLetModule } from 'ng-let';
import { CalculationComponent } from './calculation.component';

@NgModule({
  declarations: [CalculationComponent],
  imports: [
    CommonModule,
    NgLetModule,
    MatListModule,
    MatIconModule,
    MatTooltipModule,
  ],
  exports: [CalculationComponent],
})
export class EsCalculationModule {}
