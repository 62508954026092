import { Timestamp } from '../helpers/timestamp';

export class ChecklistItem {
  public static getTemplate(): ChecklistItem {
    return {
      lastCheck: null,
      completed: null,
    };
  }
  lastCheck: Timestamp | null = null;
  completed: Timestamp | null = null;
  value?: boolean | number | string | Timestamp;
  note?: string;
}
