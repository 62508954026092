import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EsLogoModule } from '../es-logo/es-logo.module';
import { EsuiPixarComponent } from './esui-pixar.component';

@NgModule({
  declarations: [EsuiPixarComponent],
  imports: [CommonModule, EsLogoModule],
  exports: [EsuiPixarComponent],
})
export class EsuiPixarModule {}
