<header class="header" [matTooltip]="header?.tooltip ?? ''">
  <mat-icon *ngIf="header?.icon">{{ header?.icon }}</mat-icon>

  <div>
    <h1 *ngIf="header?.title">{{ header?.title }}</h1>
    <ng-content select="[header]"></ng-content>
  </div>

  <span class="header-menu">
    <ng-content select="[headerMenu]"></ng-content>
  </span>
</header>

<section>
  <ng-content select="[content]"></ng-content>
</section>
