import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  PhoneVerifyPhoneInput,
  PhoneVerifyPhoneOutput,
} from '@expresssteuer/client-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class PhoneVerifyPhone {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: PhoneVerifyPhoneInput) {
    console.log('Calling client-phoneVerifyPhone');
    return this.afFunctions.httpsCallable<
      PhoneVerifyPhoneInput,
      PhoneVerifyPhoneOutput
    >('client-phoneVerifyPhone')(input);
  }
}
