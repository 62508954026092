import { Timestamp } from '../helpers/timestamp';

export class StatusHistory {
  rejected: Timestamp | null = null; // wird nicht genutzt
  entry: Timestamp | null = null; // neue
  waiting: Timestamp | null = null; // In Progress, Daten, Unterlagen fehlen fehlen
  paid: Timestamp | null = null; // Complete, bevor abgesendet and Fa
  sent: Timestamp | null = null; // Ans Finanzamt übermittelt
  received: Timestamp | null = null; // Prozess der Datenbfrage durch das FA gestartet und wartet auf Antwort
  finished: Timestamp | null = null; // Bescheid erhalten und SEPA eingezogen

  public static getTemplate(): StatusHistory {
    return {
      rejected: null,
      entry: Timestamp.now(), //TODO remove initialization
      waiting: null,
      paid: null,
      sent: null,
      received: null,
      finished: null,
    };
  }
}
