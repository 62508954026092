<mat-form-field *ngIf="showFilter" class="search" appearance="fill">
  <mat-label>search</mat-label>
  <input
    matInput
    [ngModel]="filter"
    (ngModelChange)="filterChange.next($event)"
    type="search"
  />
  <mat-icon matSuffix>search</mat-icon>
</mat-form-field>

<mat-selection-list
  class="list"
  [multiple]="false"
  [(ngModel)]="ngModel"
  (ngModelChange)="ngModelChange.next($event)"
>
  <ng-container *ngFor="let user of users">
    <mat-list-option [value]="user">
      <esui-user-list-item [user]="user"></esui-user-list-item>
    </mat-list-option>
    <mat-divider></mat-divider>
  </ng-container>
</mat-selection-list>

<esui-stats-for-nerds [data]="users" label="users"></esui-stats-for-nerds>
