<!--
  For some reason, `mat-error` and `mat-hint` must be a
  first level child of `mat-form-field`. Clustering it
  inside `ng-container` doe snot work. Therefore, the
  `mat-form-field` and `mat-label` are redundant here.
 -->

<ng-container *ngIf="fieldsToDisplay">
  <h2>Fields:</h2>
  <ng-container *ngFor="let field of fieldsToDisplay | keyvalue; let i = index">
    <ng-container [ngSwitch]="field.value.type">
      <ng-container *ngSwitchCase="SerializedFieldType.string">
        <mat-form-field appearance="standard">
          <mat-label>{{ field.key }}</mat-label>
          <esui-text-input
            [ngModel]="fieldValues?.[field.key]"
            #el="ngModel"
            (ngModelChange)="
              fieldValueChange.next({ key: field.key, value: $event })
            "
            [autocompleteOptions]="
              getAutocompleteOptionsFromSuggestionsForKey(field.key)
            "
          ></esui-text-input>
        </mat-form-field>
      </ng-container>
      <ng-container *ngSwitchCase="SerializedFieldType.number">
        <mat-form-field appearance="standard">
          <mat-label>{{ field.key }}</mat-label>
          <esui-number-input
            [ngModel]="fieldValues?.[field.key]"
            #el="ngModel"
            (ngModelChange)="
              fieldValueChange.next({ key: field.key, value: $event })
            "
            [autocompleteOptions]="
              getAutocompleteOptionsFromSuggestionsForKey(field.key)
            "
          ></esui-number-input>
        </mat-form-field>
      </ng-container>
      <ng-container *ngSwitchCase="SerializedFieldType.factor">
        <mat-form-field appearance="standard">
          <mat-label>{{ field.key }}</mat-label>
          <esui-number-input
            [ngModel]="$any(fieldValues?.[field.key])*100"
            #el="ngModel"
            (ngModelChange)="
              fieldValueChange.next({ key: field.key, value: $event / 100 })
            "
            [autocompleteOptions]="
              getAutocompleteOptionsFromSuggestionsForKey(field.key)
            "
          ></esui-number-input>
        </mat-form-field>
      </ng-container>
      <ng-container *ngSwitchCase="SerializedFieldType.timestamp">
        <mat-form-field appearance="standard">
          <mat-label>{{ field.key }}</mat-label>
          <esui-date-input
            [ngModel]="fieldValues?.[field.key]"
            #el="ngModel"
            (ngModelChange)="
              fieldValueChange.next({ key: field.key, value: $event })
            "
            [autocompleteOptions]="
              getAutocompleteOptionsFromSuggestionsForKey(field.key)
            "
          ></esui-date-input>
        </mat-form-field>
      </ng-container>
      <!-- TODO replace with real IBAN component: -->
      <ng-container *ngSwitchCase="SerializedFieldType.iban">
        <mat-form-field appearance="standard">
          <mat-label>{{ field.key }}</mat-label>
          <esui-text-input
            [ngModel]="fieldValues?.[field.key]"
            #el="ngModel"
            (ngModelChange)="
              fieldValueChange.next({ key: field.key, value: $event })
            "
            [autocompleteOptions]="
              getAutocompleteOptionsFromSuggestionsForKey(field.key)
            "
            esuiIban
          ></esui-text-input>
          <mat-hint
            *ngIf="
              field.value.type === SerializedFieldType.iban &&
              el?.status === 'PENDING'
            "
            >validating</mat-hint
          >
          <mat-error
            *ngIf="
              field.value.type === SerializedFieldType.iban &&
              el.errors?.invalidIban
            "
          >
            invalid IBAN
          </mat-error>
        </mat-form-field>
      </ng-container>
      <ng-container *ngSwitchCase="SerializedFieldType.taxId">
        <mat-form-field appearance="standard">
          <mat-label>{{ field.key }}</mat-label>
          <esui-number-input
            [ngModel]="fieldValues?.[field.key]"
            #el="ngModel"
            (ngModelChange)="
              fieldValueChange.next({ key: field.key, value: $event })
            "
            [autocompleteOptions]="
              getAutocompleteOptionsFromSuggestionsForKey(field.key)
            "
            esuiValidTaxId
          ></esui-number-input>
          <mat-error
            *ngIf="
              field.value.type === SerializedFieldType.taxId &&
              el.errors?.invalidTaxId
            "
          >
            Bitte gebe eine gültige Steuer ID ein
          </mat-error>
        </mat-form-field>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <mat-form-field appearance="standard">
          <mat-label>{{ field.key }}</mat-label>
          <esui-text-input
            [disabled]="true"
            [ngModel]="fieldValues?.[field.key] | json"
          ></esui-text-input>
          <mat-hint>
            unsupported
            {{ field.value.optional ? 'optional' : 'mandatory' }} type: "{{
              field.value.type
            }}"
          </mat-hint>
        </mat-form-field>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
