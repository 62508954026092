<!--
  <pre>
     {{ taxcase | json }} 
  </pre>
-->
<div *ngIf="taxcase" class="list">
  <mat-list [ngClass]="{ 'horizontal-list': horizontal }">
    <mat-list-item
      *ngIf="{
        maritalStatus:
          taxcase?.taxform?.current?.taxForm?.information?.maritalStatus
      } as data"
    >
      <mat-icon mat-list-icon matTooltip="marital status">{{
        data.maritalStatus === MARITALSTATUS.divorced
          ? 'safety_divider'
          : data.maritalStatus === MARITALSTATUS.single
          ? 'person_outline'
          : 'people_outline'
      }}</mat-icon>
      {{ data.maritalStatus || '-' }}
    </mat-list-item>
    <mat-list-item>
      <mat-icon mat-list-icon matTooltip="children">escalator_warning</mat-icon>
      {{ taxcase?.taxform?.current?.taxForm?.childrens?.length || 'none' }}
    </mat-list-item>
    <mat-list-item>
      <mat-icon mat-list-icon matTooltip="short time">timelapse</mat-icon
      >{{ hasShorttime ? 'short time' : 'no short time' }}
    </mat-list-item>
    <mat-list-item>
      <mat-icon mat-list-icon matTooltip="total wage">functions</mat-icon>
      {{ totalWage | currency : 'EUR' }}
    </mat-list-item>
    <!-- <mat-list-item
    *ngFor="let job of taxcase?.taxform?.current?.taxForm?.jobs ?? []"
  >
    <h3 matLine>{{ job.profession.labelDe || '-' }}</h3>
    <p matLine>shortTime: {{ job.worksetup.shortTime?.shortTime || '-' }}</p>
    <p matLine>wage: {{ job.wagestatement.wage ?? '-' }}</p>
  </mat-list-item> -->
  </mat-list>
</div>
