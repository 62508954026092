<ng-container *ngIf="taxofficeLetter?.contentTags">
  <mat-selection-list multiple class="list-container" #selected>
    <mat-list-item [disabled]="true">
      <div class="list-section">
        <div>CONTENT TAG</div>
        <div>Text</div>
        <div>Taxcases</div>
        <div>Years</div>
      </div>
    </mat-list-item>
    <mat-list-option
      *ngFor="
        let contentTag of taxofficeLetter?.contentTags ?? [];
        let i = index
      "
      [value]="i"
    >
      <div class="list-section">
        <div class="list-entry list-entry-content-tag">
          {{ contentTag.contentTag }}
        </div>

        <div class="list-entry list-entry-text">
          <div>{{ contentTag.textBlock }}</div>
        </div>

        <div class="list-entry list-entry-taxcases">
          <div *ngFor="let taxcase of contentTag.associatedTaxcasesRefs">
            {{ taxcase }}
          </div>
        </div>
        <div class="list-entry list-entry-affected-years">
          <div *ngFor="let year of contentTag.affectedYears | keyvalue">
            {{ year.key }}
          </div>
        </div>
      </div>
    </mat-list-option>
    <div class="delete-button">
      <button
        mat-mini-fab
        color="warn"
        aria-label="Example icon button with a filter list icon"
        (click)="deleteSelectedContentTagIndizes(selected)"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </mat-selection-list>
</ng-container>
