import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaxCase } from '@expresssteuer/models';

declare var $: any;
@Component({
  selector: 'app-control-user-display',
  templateUrl: './control-user-display.component.html',
  styleUrls: ['./control-user-display.component.scss'],
})
export class ControlUserDisplayComponent implements OnInit {
  constructor() {}

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  public change = new EventEmitter<TaxCase>();

  private aTaxcase: TaxCase = null;
  public signature = '';
  public displayName = '';

  @Input()
  get taxcase(): TaxCase {
    return this.aTaxcase;
  }

  set taxcase(val: TaxCase | null) {
    // if (val) {
    //   if (val.displayName) {
    //     const firstname = val.displayName.split(' ')[0];
    //     const lastname = val.displayName.split(' ')[1];
    //     this.signature = firstname.substr(0, 1).toUpperCase() + '.' + lastname.substr(0, 1).toUpperCase();
    //   }else{
    //     this.signature = '';
    //   }
    // }
    this.aTaxcase = val;

    this.change.emit(this.aTaxcase);
  }

  ngOnInit(): void {}
}
