<div
  class="header-menu-wrapper header-menu-wrapper-left py-lg-2"
  id="kt_header_menu_wrapper"
>
  <!--begin::Menu-->
  <div
    id="kt_header_menu"
    class="header-menu header-menu-mobile header-menu-layout-default"
  >
    <!--begin::Nav-->
    <ul class="menu-nav">
      <li class="menu-item menu-item-active" aria-haspopup="true">
        <a href="index.html" class="menu-link">
          <span class="menu-text">Kundenliste</span>
        </a>
      </li>
      <li
        class="menu-item menu-item-submenu menu-item-rel"
        data-menu-toggle="click"
        aria-haspopup="true"
      >
        <a href="javascript:;" class="menu-link menu-toggle">
          <span class="menu-text">Deaktiviert/Gelöschte Kunden</span>
          <span class="menu-desc"></span>
          <i class="menu-arrow"></i>
        </a>
      </li>
    </ul>
    <!--end::Nav-->
  </div>
  <!--end::Menu-->
</div>
