<ng-container *ngIf="user">
  <div class="icon">
    <img
      *ngIf="user.photoUrl"
      mat-list-icon
      alt="user avatar"
      [src]="user.photoUrl"
    />
    <mat-icon mat-list-icon *ngIf="!user.photoUrl">account_circle</mat-icon>
  </div>
  <div class="text">
    <div mat-line>{{ user.displayName }}</div>
  </div>
</ng-container>
