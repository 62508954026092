import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EsuiTaxIdValidatorDirective } from './esui-tax-id-validator.directive';

@NgModule({
  declarations: [EsuiTaxIdValidatorDirective],
  imports: [CommonModule],
  exports: [EsuiTaxIdValidatorDirective],
})
export class EsuiTaxIdValidatorModule {}
