import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-control-customer-tagify',
  templateUrl: './control-customer-tagify.component.html',
  styleUrls: ['./control-customer-tagify.component.scss'],
})
export class ControlCustomerTagifyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
