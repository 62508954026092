<div class="container">
  <esui-skeleton-placeholder
    [bigBoxMode]="true"
    [watchResult]="!!taxnoticeList"
    [skeletonBuildInput]="[]"
  >
    <ng-container *ngIf="taxnoticeList"
      ><div>
        <table
          mat-table
          [dataSource]="taxnoticeList"
          class="overview-table mat-elevation-z8"
          aria-describedby="list of data-taxnotices"
        >
          <ng-container matColumnDef="Year">
            <th mat-header-cell *matHeaderCellDef>Year</th>
            <td mat-cell *matCellDef="let element">{{ element.year }}</td>
          </ng-container>
          <ng-container matColumnDef="Refund Amount">
            <th mat-header-cell *matHeaderCellDef>Expected / Refund Amount</th>
            <td mat-cell *matCellDef="let element">
              {{ element.expectedAmount | currency : 'EUR' }} /
              {{ element.refundAmount | currency : 'EUR' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Transfer Amount">
            <th mat-header-cell *matHeaderCellDef>Transfer Amount</th>
            <td mat-cell *matCellDef="let element">
              {{ element.transferAmount | currency : 'EUR' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Payout Info">
            <th mat-header-cell *matHeaderCellDef>
              Payout Info (ours/taxnotice)
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="iban-field">
                <p>{{ element.taxcaseProvidedIban ?? 'unknown' }}</p>
                <p>{{ element.payoutIban ?? 'unknown' }}</p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="Wrong Payout?">
            <th mat-header-cell *matHeaderCellDef>Wrong Payout Iban?</th>
            <td mat-cell *matCellDef="let element">
              <div class="center-cell">
                <p>{{ element.wrongPayoutAccount ?? 'unknown' }}</p>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="Detail">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-stroked-button
                color="primary"
                (click)="taxnoticeDetailSelected.next(element.id)"
              >
                Details
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="Recalculate">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-stroked-button
                color="warn"
                (click)="recalculateSelected.next(element.id)"
              >
                Recalculate
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </ng-container>
  </esui-skeleton-placeholder>
</div>
