import { Component, OnInit } from '@angular/core';

declare var KTLayoutSearchInline: any;
declare var KTLayoutSearch: any;
declare var KTLayoutHeaderMenu: any;

@Component({
  selector: 'app-asideright',
  templateUrl: './asideright.component.html',
  styleUrls: ['./asideright.component.scss'],
})
export class AsiderightComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    // Init Search Dropdown For Desktop Mode
    KTLayoutSearchInline().init('kt_quick_search_inline');

    // Init Search Dropdown For Tablet & Mobile Mode
    KTLayoutSearch().init('kt_quick_search_dropdown');

    KTLayoutHeaderMenu.init('kt_header_menu', 'kt_header_menu_wrapper');
  }
}
