import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  RelinkAuthInput,
  RelinkAuthOutput,
} from '@expresssteuer/client-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class RelinkAuth {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: RelinkAuthInput) {
    console.log('Calling client-relinkAuth');
    return this.afFunctions.httpsCallable<RelinkAuthInput, RelinkAuthOutput>(
      'client-relinkAuth'
    )(input);
  }
}
