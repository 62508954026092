export interface CallData {
  answered_at: number | null;
  archived: boolean;
  asset: any;
  call_uuid: string;
  comments: any[];
  cost: string;
  direct_link: string;
  direction: string;
  duration: number;
  ended_at: number;
  hangup_cause: any;
  id: number;
  missed_call_reason: string;
  number: {
    availability_status: string;
    country: string;
    digits: string;
    direct_link: string;
    id: number;
    is_ivr: boolean;
    live_recording_activated: boolean;
    messages: {
      callback_later: string;
      closed: string;
      ivr: string;
      voicemail: string;
      waiting: string;
      welcome: string;
    };
    name: string;
    open: boolean;
    time_zone: string;
  };
  raw_digits: string;
  recording: any;
  started_at: number;
  status: string;
  tags: any[];
  teams: any[];
  voicemail: any;
}

export interface CallEndedEvent {
  data: CallData;
  event: string;
  resource: string;
  timestamp: number;
  token: string;
}
