<div class="d-flex justify-content-between align-items-center flex-wrap">
  <div class="d-flex flex-wrp py-2 mr-3">
    <a
      (click)="firstPage()"
      class="btn btn-icon btn-sm btn-light-success mr-2 my-1"
      ><i class="ki ki-bold-double-arrow-back icon-xs"></i
    ></a>
    <a
      (click)="previousPage()"
      class="btn btn-icon btn-sm btn-light-success mr-2 my-1"
      ><i class="ki ki-bold-arrow-back icon-xs"></i
    ></a>

    <!-- <a *ngFor="let r of getPages()" (click)="gotoPage(r)" class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1" [ngClass]="{'btn-hover-primary active': taxcaseservice.pagination.currentPage === r}">{{r}}</a> -->
    <!-- btn-hover-primary active -->
    <a
      (click)="nextPage()"
      class="btn btn-icon btn-sm btn-light-success mr-2 my-1"
      ><i class="ki ki-bold-arrow-next icon-xs"></i
    ></a>
  </div>
  <div class="d-flex align-items-center py-3">
    <!--
        <div class="d-flex align-items-center">
            <div class="mr-2 text-muted">Loading...</div>
           <div class="spinner spinner-success mr-10"></div>
        </div>
        -->

    <!--
        <select class="form-control form-control-sm text-success font-weight-bold mr-4 border-0 bg-light-primary" style="width: 75px;">
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
        </select>
        <span class="text-muted">Displaying {{recordsPerPage}} of {{total}} records</span>
        -->
  </div>
</div>
