<mat-chip-list
  #chipList
  aria-label="Tax year selection"
  className="select-multi-year-chips-container"
>
  <div class="select-multi-year-chips-chip-container">
    <mat-chip
      *ngFor="let year of value"
      [removable]="true"
      (removed)="remove(year)"
    >
      {{ year }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
  </div>
  <div class="select-multi-year-chips-select-container">
    <mat-form-field>
      <mat-label>Jahr hinzufügen</mat-label>
      <mat-select [value]="2022">
        <mat-option
          *ngFor="let year of allYears"
          [value]="year"
          (click)="add(year)"
          >{{ year }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <!-- mat select with options -->
</mat-chip-list>
