<table class="table table-vertical-center">
  <thead>
    <tr>
      <th id="year" class="p-0 text-center" style="width: 20%">Steuerfall</th>
      <th id="status" class="p-0 text-left" style="min-width: 10%">Status</th>
      <th id="last-update" class="p-0 text-center" style="min-width: 10%">
        Anlege Datum
      </th>
      <th id="actions" class="p-0 text-center" style="min-width: 30%">
        Actions
      </th>
    </tr>
  </thead>
  <tbody>
    <tr class="" *ngFor="let approval of approvals">
      <td class="text-center" headers="year">
        <span class="font-weight-bolder font-size-sm text-dark">{{
          approval.id
        }}</span>
      </td>
      <td class="text-left" headers="status">
        {{ approval.status }}
      </td>
      <td class="text-center" headers="last-update">
        <span class="font-weight-bolder d-block">
          {{ approval.requestCreatedAt.toDate() | date : 'dd.MM.yyyy HH:mm' }}
        </span>
      </td>

      <td class="text-center" headers="actions">
        <button
          (click)="openDocumentClicked.emit(approval.binDocRef)"
          type="button"
          class="btn btn-secondary"
        >
          Vordruck
        </button>
      </td>
    </tr>
  </tbody>
</table>
