import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  PhoneChangePhoneNumberInput,
  PhoneChangePhoneNumberOutput,
} from '@expresssteuer/client-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class PhoneChangePhoneNumber {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: PhoneChangePhoneNumberInput) {
    console.log('Calling client-phoneChangePhoneNumber');
    return this.afFunctions.httpsCallable<
      PhoneChangePhoneNumberInput,
      PhoneChangePhoneNumberOutput
    >('client-phoneChangePhoneNumber')(input);
  }
}
