<mat-expansion-panel
  [ngClass]="{
    transparentCard: transparentCard
  }"
  hideToggle
  expanded="true"
>
  <mat-expansion-panel-header>
    <mat-panel-title>Task History</mat-panel-title>
    <mat-panel-description> </mat-panel-description>
  </mat-expansion-panel-header>
  <esui-task-history [taskHistory]="taskHistory"></esui-task-history>
</mat-expansion-panel>
