import { Directive, Input } from '@angular/core';
import { AbstractKeyValueAccessor } from '../abstract-key-value-accesor';

/**
 * Helper to implement a Dropdown with
 * key-value `options`.
 */
@Directive()
export abstract class DropdownInputDirective<
  TKey,
  TValue
> extends AbstractKeyValueAccessor<TKey, TValue> {
  #viewMode = false;
  @Input() set viewMode(val: boolean) {
    this.#viewMode = val;
  }
  get viewMode(): boolean {
    return this.#viewMode;
  }

  set valueProxy(val: TValue) {
    this.value = this.typeCast(val);
  }
  get valueProxy(): TValue {
    return this.value;
  }

  abstract typeCast(val: any): TValue;

  protected getKeyByValue(val: TValue) {
    for (const [key, value] of this.options.entries()) {
      if (value === val) {
        return key;
      }
    }
    return undefined;
  }

  get activeKey() {
    return this.getKeyByValue(this.value);
  }
}
