import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TaxNotice } from '@expresssteuer/models';
import { StoreStateIndicatorModel } from '../es-store-state-indicator/store-state-indicator.interface';

@Component({
  selector: 'esui-taxnotice-inform-backpayer',
  templateUrl: './esui-taxnotice-inform-backpayer.component.html',
  styleUrls: ['./esui-taxnotice-inform-backpayer.component.scss'],
})
export class EsuiTaxnoticeInformBackpayerComponent {
  @Input()
  taxnoticeDocumentStoreStateIndicator?: Partial<StoreStateIndicatorModel> | null;
  @Input() taxnoticeDocument?: {
    contentType: string;
    downloadUrl: string;
  } | null;
  @Input() taxNotice?: TaxNotice | null;
  @Output() clientInformed = new EventEmitter<void>();
  @Output() taxNoticeChange = new EventEmitter<TaxNotice>();
  @Output() taxNoticePartialChange = new EventEmitter<Partial<TaxNotice>>();

  partialTaxnoticeChange<K extends keyof TaxNotice>(
    key: K,
    value: TaxNotice[K]
  ): void;
  partialTaxnoticeChange(change: Partial<TaxNotice>): void;
  partialTaxnoticeChange<K extends keyof TaxNotice>(
    a: Partial<TaxNotice> | K,
    b?: TaxNotice[K]
  ): void {
    const change =
      typeof a === 'string'
        ? {
            [a]: b,
          }
        : a;

    const merged = { ...this.taxNotice, ...change };
    this.taxNoticePartialChange.next(change);
    this.taxNoticeChange.next(merged as any);
  }
}
