import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsNilPipe } from './is-nil.pipe';
import { IsNotNilPipe } from './is-not-nil.pipe';

@NgModule({
  declarations: [IsNilPipe, IsNotNilPipe],
  exports: [IsNilPipe, IsNotNilPipe],
  imports: [CommonModule],
})
export class IsNilModule {}
