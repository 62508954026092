import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { EsDocumentClassifierModule } from '../es-document-classifier/es-document-classifier.module';
import { DateInputModule } from '../form-inputs/date-input/date-input.module';
import { TimestampModule } from '../pipes/timestamp/timestamp.module';
import { EsuiClientIdentifyingProofAnswerComponent } from './esui-client-identifying-proof-answer/esui-client-identifying-proof-answer.component';
import { EsuiClientIdentifyingProofFeedbackFormComponent } from './esui-client-identifying-proof-feedback-form/esui-client-identifying-proof-feedback-form.component';
import { EsuiClientTaxidProofAnswerComponent } from './esui-client-taxid-proof-answer/esui-client-taxid-proof-answer.component';
import { EsuiClientTaxidProofFeedbackFormComponent } from './esui-client-taxid-proof-feedback-form/esui-client-taxid-proof-feedback-form.component';
import { EsuiClientTodoHelpTaskComponent } from './esui-client-todo-help-task.component';
import { EsuiVmaProofAnswerComponent } from './esui-vma-proof-answer/esui-vma-proof-answer.component';
import { EsuiVmaProofFeedbackFormComponent } from './esui-vma-proof-feedback-form/esui-vma-proof-feedback-form.component';

@NgModule({
  declarations: [
    EsuiClientTodoHelpTaskComponent,
    EsuiClientIdentifyingProofAnswerComponent,
    EsuiClientIdentifyingProofFeedbackFormComponent,
    EsuiClientTaxidProofFeedbackFormComponent,
    EsuiClientTaxidProofAnswerComponent,
    EsuiVmaProofFeedbackFormComponent,
    EsuiVmaProofAnswerComponent,
  ],
  imports: [
    CommonModule,
    EsDocumentClassifierModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatListModule,
    TimestampModule,
    DateInputModule,
  ],
  exports: [EsuiClientTodoHelpTaskComponent],
  providers: [TimestampModule],
})
export class EsuiClientTodoHelpTaskModule {}
