/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  MARITALSTATUS,
  TAXCASESTATUS,
  TAXCASESTATUS_INPROGRESS,
  TAXCASESTATUS_REJECT,
  User,
} from '@expresssteuer/models';
import { SearchClient } from 'algoliasearch';
import { simple } from 'instantsearch.js/es/lib/stateMappings';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TaxcasesService } from '../../../app/services/data/taxcases.service';
import { UsersService } from '../../../app/services/data/users.service';
import { AlgoliaService } from '../../../app/services/search/algolia.service';
import { AuthenticationService } from '../../../app/services/security/authentication.service';
import { environment } from '../../../environments/environment';

declare let $: any;

@Component({
  selector: 'app-card-taxcases',
  templateUrl: './card-taxcases.component.html',
  styleUrls: ['./card-taxcases.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardTaxcasesComponent implements OnInit {
  constructor(
    public taxcasesservice: TaxcasesService,
    public algoliaService: AlgoliaService,
    public auth: AuthenticationService,
    public userservice: UsersService,
    public db: AngularFirestore
  ) {}

  public pageLimits: { label: string; value: number; default?: boolean }[] = [
    { label: '20 pro seite', value: 20 },
    { label: '50 pro seite', value: 50, default: true },
    { label: '100 pro seite', value: 100 },
    { label: '200 pro seite', value: 200 },
    { label: '500 pro seite', value: 500 },
    { label: '1000 pro seite', value: 1000 },
  ];

  private query = '';

  TAXCASESTATUS = TAXCASESTATUS;
  TAXCASESTATUS_REJECT = TAXCASESTATUS_REJECT;
  TAXCASESTATUS_INPROGRESS = TAXCASESTATUS_INPROGRESS;
  MARITALSTATUS = MARITALSTATUS;

  @Input()
  title = 'Steuerfälle';
  @Input()
  header = 'Kundensteuerfälle';

  public configTaxcases$: Observable<{
    indexName: string;
    searchClient: SearchClient;
  }>;

  public get searchParameters() {
    return {
      query: this.query,
    };
  }

  ngOnInit(): void {
    this.configTaxcases$ = this.algoliaService.searchClient$.pipe(
      map((searchClient) => {
        console.log('piping');
        return {
          indexName: environment.algolia.indexNames.taxcases,
          searchClient: searchClient,
          routing: {
            stateMapping: simple(),
          },
        };
      })
    );
  }

  public assignToSomeone(searchcase: any, user: User): void {
    console.log('assign to taxcase', searchcase.taxcaseid);
    const assigned = { metadata: { assigned: user } };
    this.db
      .collection('taxcases')
      .doc(searchcase.taxcaseid)
      .set(assigned, { merge: true });
  }

  public assignToMe(searchcase: any): void {
    console.log('assign to taxcase', searchcase.taxcaseid);
    const assigned = { metadata: { assigned: this.auth.user } };
    this.db
      .collection('taxcases')
      .doc(searchcase.taxcaseid)
      .set(assigned, { merge: true });
  }

  public toggleCountForMyCases() {
    $('#filterMyCases .ais-ToggleRefinement-count').toggle();
  }

  public onQuery($event) {
    const searchQuery = $event.target.value;

    if (searchQuery.length > 3) {
      this.query = searchQuery;
    }

    if (searchQuery.length === 0) {
      this.query = '';
    }
  }
}
