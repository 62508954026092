import { SchemaProperty } from './vast';

export const VastFsaSchema: SchemaProperty = {
  title: 'VaSt_FSA-202201.xsd',
  $schema: 'http://json-schema.org/schema#',

  type: 'object',
  properties: {
    VaSt_FSA: {
      type: 'object',
      description: 'Freistellungsauftrag',
      properties: {
        Eingangsdatum: {
          description:
            'Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung',
          type: 'string',
        },
        Meldejahr: {
          description: 'Meldejahr',
          type: 'string',
        },
        Betrag: {
          description: 'Betrag',
          type: 'integer',
          stringifier: 'currency-eur',
        },
        BetroffenePerson: {
          type: 'object',
          properties: {
            IdNr: {
              description: 'Identifikationsnummer',
              type: 'string',
            },
            Vorname: {
              description: 'Vorname',
              type: 'string',
            },
            Name: {
              description: 'Name',
              type: 'string',
            },
            Blockname: {
              description: 'Name',
              type: 'string',
            },
            NamensVorsatz: {
              description: 'Namensvorsatz',
              type: 'string',
            },
            NamensZusatz: {
              description: 'Namenszusatz',
              type: 'string',
            },
            Titel: {
              description: 'Titel',
              type: 'string',
            },
            Ehegatte: {
              type: 'object',
              properties: {
                IdNr: {
                  description: 'Identifikationsnummer',
                  type: 'string',
                },
                Vorname: {
                  description: 'Vorname',
                  type: 'string',
                },
                Name: {
                  description: 'Name',
                  type: 'string',
                },
                Blockname: {
                  description: 'Name',
                  type: 'string',
                },
                NamensVorsatz: {
                  description: 'Namensvorsatz',
                  type: 'string',
                },
                NamensZusatz: {
                  description: 'Namenszusatz',
                  type: 'string',
                },
                Titel: {
                  description: 'Titel',
                  type: 'string',
                },
              },
            },
          },
        },
        Institut: {
          type: 'object',
          properties: {
            BLZ: {
              description: 'BLZ',
              type: 'string',
            },
            BIC: {
              description: 'BIC',
              type: 'string',
            },
            Name: {
              description: 'Name',
              type: 'string',
            },
          },
        },
        version: {
          type: 'string',
          enum: ['202201'],
        },
      },
      required: ['version'],
    },
  },
  required: ['VaSt_FSA'],
};
