import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExpsAddress, Timestamp } from '@expresssteuer/models';

type DataStatus = {
  isSaving?: boolean | null;
  isLoading?: boolean | null;
  hasChanges?: boolean | null;
  hasErrors?: boolean | null;
};

type NameData = {
  firstname: string;
  lastname: string;
  status?: DataStatus;
};

type AddressData = {
  forYears: string[];
  allYears: boolean;
  isCurrent: boolean;
  address: ExpsAddress;
  status?: DataStatus;
};

type TaxIdData = { taxId: string; status?: DataStatus };

type TaxnumberData = { taxnumber: string; status?: DataStatus };

type financeDepartmentIdData = {
  financeDepartmentId: string;
  status?: DataStatus;
};

type BirthdateData = { birthdate: Timestamp; status?: DataStatus };

// type TaxadvisoryData = { status?: DataStatus }; // TODO

@Component({
  selector: 'esui-client-actions',
  templateUrl: './esui-client-actions.component.html',
  styleUrls: ['./esui-client-actions.component.scss'],
})
export class EsuiClientActionsComponent {
  @Input() clientId?: string;

  @Input() availableTaxcaseYears?: string[] | null;

  @Input() addressData?: AddressData | null;
  @Output() addressDataChange = new EventEmitter<AddressData>();
  addressDataPartChange(change: Partial<AddressData>) {
    const template: AddressData = {
      address: ExpsAddress.getTemplate(),
      forYears: [],
      allYears: false,
      isCurrent: true,
    };
    this.addressDataChange.next({
      ...(this.addressData ?? template),
      ...change,
    });
  }

  @Input() nameData?: NameData | null;
  @Output() nameDataChange = new EventEmitter<NameData>();
  nameDataPartChange(change: Partial<NameData>) {
    const template: NameData = {
      firstname: '',
      lastname: '',
    };
    this.nameDataChange.next({ ...(this.nameData ?? template), ...change });
  }

  @Input() taxIdData?: TaxIdData | null;
  @Output() taxIdDataChange = new EventEmitter<TaxIdData>();
  taxIdDataPartChange(change: Partial<TaxIdData>) {
    const template: TaxIdData = {
      taxId: '',
    };
    this.taxIdDataChange.next({ ...(this.taxIdData ?? template), ...change });
  }

  @Input() taxnumberData?: TaxnumberData | null;
  @Output() taxnumberDataChange = new EventEmitter<TaxnumberData>();
  taxnumberDataPartChange(change: Partial<TaxnumberData>) {
    const template: TaxnumberData = {
      taxnumber: '',
    };
    this.taxnumberDataChange.next({
      ...(this.taxnumberData ?? template),
      ...change,
    });
  }

  @Input() financeDepartmentIdData?: financeDepartmentIdData | null;
  @Output() financeDepartmentIdDataChange =
    new EventEmitter<financeDepartmentIdData>();
  financeDepartmentIdDataPartChange(change: Partial<financeDepartmentIdData>) {
    const template: financeDepartmentIdData = {
      financeDepartmentId: '',
    };
    this.financeDepartmentIdDataChange.next({
      ...(this.financeDepartmentIdData ?? template),
      ...change,
    });
  }

  @Input() birthdateData?: BirthdateData | null;
  @Output() birthdateDataChange = new EventEmitter<BirthdateData>();
  birthdateDataPartChange(change: Partial<BirthdateData>) {
    const template: BirthdateData = {
      birthdate: Timestamp.fromDate(new Date()),
    };
    this.birthdateDataChange.next({
      ...(this.birthdateData ?? template),
      ...change,
    });
  }

  // @Input() taxadvisoryData?: TaxadvisoryData | null;
  // @Output() taxadvisoryDataChange = new EventEmitter<TaxadvisoryData>();
  // taxadvisoryDataPartChange(change: Partial<TaxadvisoryData>) {
  //   const template: TaxadvisoryData = {}; // TODO
  //   this.taxadvisoryDataChange.next({
  //     ...(this.taxadvisoryData ?? template),
  //     ...change,
  //   });
  // }
}
