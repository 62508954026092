import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Client, SmartTask } from '@expresssteuer/models';
import { TaskHelper } from '../../task-helper';

@Component({
  selector: 'esui-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss'],
})
export class TaskCardComponent {
  @Input() task?: SmartTask;
  @Input() pathToTask?: string;
  @Input() client?: Client;

  @Output() doneStateClick = new EventEmitter<void>();
  @Output() escalateLevelChange = new EventEmitter<number>();

  @Input() transparentCard = false;

  escalateVerificationNeeded = true;
  escalate() {
    if (!this.task) {
      return;
    }
    if (this.escalateVerificationNeeded) {
      this.escalateVerificationNeeded = false;
      return;
    }
    this.escalateVerificationNeeded = true;
    this.escalateLevelChange.next(1);
  }

  cancelEscalate() {
    this.escalateVerificationNeeded = true;
  }

  get typeDescription() {
    return TaskHelper.taskTypeToDescription(this.task?.type || '');
  }

  get typeLabel() {
    return TaskHelper.taskTypeToLabel(this.task?.type || '');
  }
}
