import { Timestamp } from '../helpers/timestamp';
import { TaskMatcherMetadata } from '../smart-task/smart-task';

export enum CLIENTFACINGTASKTYPE {
  test,
} // TODO

export abstract class ClientFacingTask<
  TTaskMatcherMetadata extends TaskMatcherMetadata = TaskMatcherMetadata
> {
  /**
   * The unique id for the task
   */
  abstract id: string;

  abstract clientId: string;

  /**
   * The tax years this task is related to
   */
  abstract years: number[];

  /**
   * The taxcases this task is related to
   */
  abstract taxcaseIds: string[];

  /**
   * The creation Date for the task
   */
  abstract createdAt: Timestamp;

  /**
   * An object to uniquely identify this task (eg. to match with incoming documents)
   * aka. "Composite Primary Key" for a Task
   */
  abstract readonly taskMatcherMetadata: TTaskMatcherMetadata;

  /**
   * Document paths in bucket referenced by this task
   */
  abstract referencedDocuments: string[];

  /**
   * When set to true, it indicates a "soft" delete
   */
  abstract deactivated: boolean;

  /**
   * The time deactivated was last set
   */
  abstract deactivatedChangedAt?: Timestamp | null;

  /**
   * The specific kind of task
   */
  abstract type: CLIENTFACINGTASKTYPE;

  /**
   * When set to true, the client indicated he/she is not able
   * to provide the requested information.
   */
  abstract noneExistent: boolean;

  /**
   * Allow to upload more than one document for this task
   */
  abstract allowMultiUpload?: boolean;

  /**
   * Client can provide info, that he uploaded all documents he has for this task
   */
  abstract allDocsUploaded?: boolean;
}
