import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TaxCase, TAXCASESTATUS, User } from '@expresssteuer/models';
import { AuthenticationService } from '../../../../../app/services/security/authentication.service';

@Component({
  selector: 'app-button-status-entry-accept',
  templateUrl: './button-status-entry-accept.component.html',
  styleUrls: ['./button-status-entry-accept.component.scss'],
})
export class ButtonStatusEntryAcceptComponent implements OnInit {
  constructor(private security: AuthenticationService) {}

  taxCaseValue: TaxCase;

  @Output()
  taxCaseChange = new EventEmitter<TaxCase>();

  @Input()
  get taxcase(): TaxCase {
    return this.taxCaseValue;
  }

  set taxcase(val: TaxCase) {
    this.taxCaseValue = val;
    this.taxCaseChange.emit(this.taxCaseValue);
  }

  public accept(): void {
    if (this.taxcase) {
      this.taxcase.metadata.status.current = TAXCASESTATUS.INPROGRESS;
      // this.taxcase.metadata.status.substatus = TAXCASESTATUS_INPROGRESS.WAITING;

      // historyItem.substatus = TAXCASESTATUS_INPROGRESS.WAITING;

      // this.taxcase.metadata.status.history.push();
    }
  }

  public acceptAndAssignToMe(): void {
    this.taxcase.metadata.assigned = this.security.user;
    this.taxcase.metadata.updated = this.security.user;

    this.accept();
  }
  public acceptAndAssignToSomeone(user: User): void {
    this.taxcase.metadata.assigned = user;
    this.taxcase.metadata.updated = user;

    this.accept();
  }

  ngOnInit(): void {}
}
