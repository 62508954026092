<esui-skeleton-placeholder
  [skeletonBuildInput]="[[3], [0, 2, 2]]"
  [watchResult]="!!taxNotice"
  [bigBoxMode]="false"
>
  <ng-container *ngIf="taxNotice">
    <div class="returnValues">
      <mat-form-field>
        <mat-label>tax year</mat-label>
        <input
          matInput
          type="text"
          [ngModel]="taxNotice.taxYear"
          [disabled]="disabled || !taxYearEditable"
          (ngModelChange)="partialChange({ taxYear: $event })"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>notice date</mat-label>
        <esui-date-input
          [disabled]="disabled"
          type="date"
          [ngModel]="taxNotice.taxNoticeReturnDate"
        ></esui-date-input>
      </mat-form-field>
    </div>

    <div class="returnValues">
      <mat-form-field>
        <mat-label>calculated tax return</mat-label>
        <!--
        Note we are using type="string" in order to be able to cut
        the decimal points from the calculation; this is ok as its read only
      -->
        <input
          [disabled]="true"
          matInput
          placeholder="Amount"
          [ngModel]="taxNotice.expectedTaxReturn | number : '1.2-2'"
          type="string"
          class="example-right-align"
        />
        <span matPrefix>€&nbsp;</span>
      </mat-form-field>

      <mat-form-field>
        <mat-label>actual tax return</mat-label>
        <input
          matInput
          type="number"
          [disabled]="disabled"
          [ngModel]="taxNotice.actualTaxReturn"
          (ngModelChange)="partialChange({ actualTaxReturn: $event })"
        />
        <span matPrefix>€&nbsp;</span>
      </mat-form-field>
    </div>
    <div class="returnValues">
      <mat-form-field>
        <mat-label>Recieving Iban</mat-label>
        <input
          matInput
          type="text"
          [disabled]="disabled"
          [ngModel]="taxNotice.receivingIban"
          (ngModelChange)="partialChange({ receivingIban: $event })"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Recieving Bank</mat-label>
        <input
          matInput
          type="text"
          [disabled]="disabled"
          [ngModel]="taxNotice.receivingBankName"
          (ngModelChange)="partialChange({ receivingBankName: $event })"
        />
      </mat-form-field>
    </div>

    <h3>Deductions</h3>
    <esui-deduction-table
      [deductions]="taxNotice.deductions"
      [disabled]="disabled"
      [availableTypes]="taxnoticeDeductionEnum"
      (deductionsChange)="partialChange({ deductions: $event })"
    >
    </esui-deduction-table>
    <h3>Additions</h3>
    <esui-deduction-table
      [deductions]="taxNotice.additions"
      [disabled]="disabled"
      [availableTypes]="taxnoticeAdditionEnum"
      (deductionsChange)="partialChange({ additions: $event })"
    >
    </esui-deduction-table>
    <h3>Resulting Payout Amount</h3>
    <div class="center-result">
      <mat-form-field>
        <mat-label>Transfer Amount</mat-label>
        <input
          matInput
          type="text"
          [disabled]="true"
          [ngModel]="
            calculateTaxnoticeTransferAmount() | currency : 'EUR' : true
          "
        />
      </mat-form-field>
    </div>
  </ng-container>
</esui-skeleton-placeholder>
