import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BusyManager } from '../../../helpers/busymanager';

@Component({
  selector: 'app-goback-and-save',
  templateUrl: './goback-and-save.component.html',
  styleUrls: ['./goback-and-save.component.scss'],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
})
export class GobackAndSaveComponent {
  @Input() busy: BusyManager;

  @Output() save = new EventEmitter<boolean>();
  @Output() calc = new EventEmitter<boolean>();

  public location: Location;
  constructor(location: Location, private router: Router) {
    this.location = location;
  }

  private _showCalc = false;

  @Input()
  public set showCalc(val: boolean) {
    this._showCalc = val;
  }

  public get showCalc(): boolean {
    return this._showCalc;
  }

  public dosave(): void {
    this.save.emit(true);
  }

  public doCalc(): void {
    this.calc.emit(true);
  }

  public back() {
    this.location.back();
  }
}
