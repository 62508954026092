import { ExpsAddress } from './address';

enum ExpsAddressFixturesKeys {
  Office_Expresssteuer_HQ = 'office-Expresssteuer-HQ',
  Work_HH = 'work-hh',
}
export const ExpsAddressFixtures: {
  [key in ExpsAddressFixturesKeys]: ExpsAddress;
} = {
  [ExpsAddressFixturesKeys.Office_Expresssteuer_HQ]: {
    city: {
      shortName: 'HH',
      longName: 'Hamburg',
    },
    country: {
      shortName: 'DE',
      longName: 'Deutschland',
    },
    lat: '53.54255362201504',
    long: '9.98539508037835',
    state: {
      shortName: 'HH',
      longName: 'Hamburg',
    },
    region: {
      shortName: 'HH',
      longName: 'Hamburg',
    },
    zipCode: {
      shortName: '20457',
      longName: '20457',
    },
    nbr: {
      shortName: 'nbr',
      longName: 'nbr',
    },
    street: {
      shortName: 'Am Sandtorkai 73',
      longName: 'Am Sandtorkai 73',
    },
    placeId: '',
    formatedAddress: '',
    unformatedAddress: '',
    isChanged: false,
  },
  [ExpsAddressFixturesKeys.Work_HH]: {
    city: {
      shortName: 'HH',
      longName: 'Hamburg',
    },
    country: {
      shortName: 'DE',
      longName: 'Deutschland',
    },
    lat: '53.54793407450498',
    long: '9.978056556535186',
    state: {
      shortName: 'HH',
      longName: 'Hamburg',
    },
    region: {
      shortName: 'HH',
      longName: 'Hamburg',
    },
    zipCode: {
      shortName: '20459',
      longName: '20459',
    },
    nbr: {
      shortName: 'nbr',
      longName: 'nbr',
    },
    street: {
      shortName: 'Engl. Planke 16',
      longName: 'Engl. Planke 16',
    },
    placeId: '',
    formatedAddress: '',
    unformatedAddress: '',
    isChanged: false,
  },
};
