import { ExpsAddress } from './address';

export class ConvertExpsAddress {
  public static getExpsAddressFromPlaces(
    googlePlaceResult: PlaceResult | null
  ): ExpsAddress {
    const splitAddress =
      googlePlaceResult?.formatted_address?.split(',')[0].trim() +
      ', ' +
      googlePlaceResult?.formatted_address?.split(',')[1].trim();
    const newAddress = ConvertExpsAddress.transformAddressComponent(
      googlePlaceResult?.address_components ?? [],
      googlePlaceResult?.geometry?.location?.lat(),
      googlePlaceResult?.geometry?.location?.lng()
    );
    newAddress.isChanged = true;
    newAddress.formatedAddress = splitAddress;
    newAddress.unformatedAddress = splitAddress;

    return newAddress;
  }
  private static transformAddressComponent(
    addressComponents: GeocoderAddressComponent[],
    latitude: number = 0,
    longitude: number = 0
  ): ExpsAddress {
    const a = {
      formatedAddress: '',
      street: { shortName: '', longName: '' },
      nbr: { shortName: '', longName: '' },
      city: { shortName: '', longName: '' },
      region: { shortName: '', longName: '' },
      state: { shortName: '', longName: '' },
      zipCode: { shortName: '', longName: '' },
      country: { shortName: '', longName: '' },
      placeId: '',
      long: longitude.toString(),
      lat: latitude.toString(),
      unformatedAddress: '',
      isChanged: false,
    };

    const zipCode = ConvertExpsAddress.mapAddress(
      addressComponents,
      'postal_code'
    );
    if (zipCode) {
      a.zipCode.shortName = zipCode.short_name;
      a.zipCode.longName = zipCode.long_name;
    } else {
      console.log('Google Place: zip not available');
    }

    const city = ConvertExpsAddress.mapAddress(addressComponents, 'locality');
    if (city) {
      a.city.shortName = city.short_name;
      a.city.longName = city.long_name;
    } else {
      console.log('Google Place: city not available');
    }

    const region = ConvertExpsAddress.mapAddress(
      addressComponents,
      'administrative_area_level_2'
    );
    if (region) {
      a.region.shortName = region.short_name;
      a.region.longName = region.long_name;
    } else {
      console.log('Google Place: region not available');
    }

    const state = ConvertExpsAddress.mapAddress(
      addressComponents,
      'administrative_area_level_1'
    );
    if (state) {
      a.state.shortName = state.short_name;
      a.state.longName = state.long_name;
    } else {
      console.log('Google Place: state not available');
    }

    const street = ConvertExpsAddress.mapAddress(addressComponents, 'route');
    if (street) {
      a.street.shortName = street.short_name;
      a.street.longName = street.long_name;
    } else {
      console.log('Google Place: street not available');
    }

    const country = ConvertExpsAddress.mapAddress(addressComponents, 'country');
    if (country) {
      a.country.shortName = country.short_name;
      a.country.longName = country.long_name;
    } else {
      console.log('Google Place: country not available');
    }

    const nbr = ConvertExpsAddress.mapAddress(
      addressComponents,
      'street_number'
    );
    if (nbr) {
      a.nbr.shortName = nbr.short_name;
      a.nbr.longName = nbr.long_name;
    } else {
      console.log('Google Place: street nbr not available');
    }
    return a;
  }

  private static mapAddress(
    addressComponents: GeocoderAddressComponent[],
    itemType: string
  ): GeocoderAddressComponent | undefined {
    let address: GeocoderAddressComponent | undefined = undefined;
    addressComponents.forEach((addressComponent) => {
      addressComponent.types.forEach((type) => {
        if (type === itemType) {
          address = addressComponent;
          return;
        }
      });
    });
    return address;
  }
}

/*
  Copy of the used fields from google.maps.places.PlaceResult
  as google is provided globally in our apps but not in this module.
 */
interface LatLng {
  /**
   * Comparison function.
   */
  equals(other: LatLng | null): boolean;
  /**
   * Returns the latitude in degrees.
   */
  lat(): number;
  /**
   * Returns the longitude in degrees.
   */
  lng(): number;
  /**
   * Converts to string representation.
   */
  toString(): string;
  /**
   * Returns a string of the form &quot;lat,lng&quot; for this LatLng. We
   * round the lat/lng values to 6 decimal places by default.
   */
  toUrlValue(precision?: number): string;
}
interface PlaceGeometry {
  /**
   * The Place’s position.
   */
  location?: LatLng;
}
interface GeocoderAddressComponent {
  /**
   * The full text of the address component
   */
  long_name: string;
  /**
   * The abbreviated, short text of the given address component
   */
  short_name: string;
  /**
   * An array of strings denoting the type of this address component. A list
   * of valid types can be found <a
   * href="https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes">here</a>
   */
  types: string[];
}
/**
 * Defines information about a Place.
 */
interface PlaceResult {
  /**
   * The collection of address components for this Place’s location. Only
   * available with {@link google.maps.places.PlacesService.getDetails}.
   */
  address_components?: GeocoderAddressComponent[];
  /**
   * The representation of the Place’s address in the <a
   * href="http://microformats.org/wiki/adr">adr microformat</a>. Only
   * available with {@link google.maps.places.PlacesService.getDetails}.
   */
  adr_address?: string;
  /**
   * The Place’s full address.
   */
  formatted_address?: string;
  /**
   * The Place’s geometry-related information.
   */
  geometry?: PlaceGeometry;
  /**
   * Attribution text to be displayed for this Place result. Available
   * <code>html_attributions</code> are always returned regardless of what
   * <code>fields</code> have been requested, and must be displayed.
   */
  html_attributions?: string[];
  /**
   * A unique identifier for the Place.
   */
  place_id?: string;
  /**
   * An array of <a
   * href="https://developers.google.com/maps/documentation/places/web-service/supported_types">
   * types for this Place</a> (for example, <code>["political",
   * "locality"]</code> or <code>["restaurant", "establishment"]</code>).
   */
  types?: string[];
  /**
   * URL of the official Google page for this place. This is the Google-owned
   * page that contains the best available information about the Place. Only
   * available with {@link google.maps.places.PlacesService.getDetails}.
   */
  url?: string;
}
