<ng-container *ngIf="user">
  <button [matMenuTriggerFor]="menu" mat-icon-button #b>
    <esui-user-icon [user]="user"></esui-user-icon>
  </button>
  <mat-menu #menu="matMenu">
    <a mat-menu-item [href]="profileHref">
      <mat-icon>badge</mat-icon>
      <span class="name">{{ user.displayName }}</span>
    </a>
    <button mat-menu-item (click)="logoutClicked.next()">
      <mat-icon>logout</mat-icon>
      <span>logout</span>
    </button>
    <button
      mat-menu-item
      (click)="getJwtClicked.next()"
      *ngIf="role === 'admin'"
    >
      <span>get JWT</span>
    </button>
    <button mat-menu-item disabled (click)="moreClicked.next()">
      <span>more...</span>
    </button>
  </mat-menu>
</ng-container>
