import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsStoreStateIndicatorModule } from '../es-store-state-indicator/es-store-state-indicator.module';
import { EsuiContentTagSelectionModule } from '../esui-content-tag-selection/esui-content-tag-selection.module';
import { EsuiContentTagViewerModule } from '../esui-content-tag-viewer/esui-content-tag-viewer.module';
import { EsuiDocumentViewerModule } from '../esui-document-viewer/esui-document-viewer.module';
import { EsuiSkeletonPlaceholderModule } from '../esui-skeleton-placeholder/esui-skeleton-placeholder.module';
import { EsuiTextOcrRendererModule } from '../esui-text-ocr-renderer/esui-text-ocr-renderer.module';
import { EsuiTaxofficeAssignReminderToRequestComponent } from './esui-taxoffice-assign-reminder-to-request.component';

@NgModule({
  declarations: [EsuiTaxofficeAssignReminderToRequestComponent],
  imports: [
    CommonModule,
    MatCardModule,
    EsuiSkeletonPlaceholderModule,
    EsuiDocumentViewerModule,
    EsCardKeyModule,
    EsuiTextOcrRendererModule,
    MatTabsModule,
    MatButtonModule,
    MatDividerModule,
    MatRadioModule,
    EsuiContentTagSelectionModule,
    MatCheckboxModule,
    FormsModule,
    MatChipsModule,
    MatFormFieldModule,
    MatSelectModule,
    EsuiContentTagViewerModule,
    EsStoreStateIndicatorModule,
  ],
  exports: [EsuiTaxofficeAssignReminderToRequestComponent],
})
export class EsuiTaxofficeAssignReminderToRequestModule {}
