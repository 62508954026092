import { Timestamp } from '../../src';

export abstract class DeviceRegistration {
  abstract token: string;
  abstract registrationDate: Timestamp;
  abstract lastSeen: Timestamp;

  static getTemplate(token: string): DeviceRegistration {
    return {
      token: token,
      registrationDate: Timestamp.now(),
      lastSeen: Timestamp.now(),
    };
  }
}
