import { Pipe, PipeTransform } from '@angular/core';
import { EnvironmentDetails } from '@expresssteuer/models';

@Pipe({
  name: 'partnerLogo',
})
export class PartnerLogoPipe implements PipeTransform {
  transform(value: EnvironmentDetails | string): unknown {
    let data = '';

    if (typeof value === 'string') {
      data = value as string;
    }
    if (typeof value === 'object') {
      data = value.companyShort as string;
    }

    switch (data) {
      case 'Deinplus':
      case 'DeinPlus':
      case 'app-deinplus.expresssteuer.com':
        return '/assets/media/logos/logo_deinplus.png';
      case 'IGBAU':
      case 'app-igbau.expresssteuer.com':
        return '/assets/media/logos/logo_igbau.png';
      case 'EXP':
      case 'app.expresssteuer.com':
        return '/assets/media/logos/exp-logo-icononly.png';
      case 'ngg':
      case 'app-ngg.expresssteuer.com':
        return '/assets/media/logos/ngg-logo.png';
      default:
        return '/assets/media/logos/logo-deinplus.png';
    }
  }
}
