import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TaxcaseShortComponent } from './taxcase-short.component';

@NgModule({
  declarations: [TaxcaseShortComponent],
  imports: [CommonModule, MatListModule, MatIconModule, MatTooltipModule],
  exports: [TaxcaseShortComponent],
})
export class EsTaxcaseShortModule {}
