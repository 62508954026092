import {
  BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
  NumberInput,
} from '@angular/cdk/coercion';
import { Directive, Inject, InjectionToken, Input } from '@angular/core';
import {
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, filter, map, switchMap, take } from 'rxjs/operators';

export interface IbanError extends ValidationErrors {
  invalidIban: true;
}

export type IbanValidatorService = {
  call: (input: { iban: string }) => Observable<{ isValid: boolean }>;
};

export const ESUI_IBAN_VALIDATOR = new InjectionToken<IbanValidatorService>(
  'ESUI_IBAN_VALIDATOR'
);

/**
 * Directive to validate based on a provided `ESUI_IBAN_VALIDATOR`
 * @note requries a provided `ESUI_IBAN_VALIDATOR`, for example: `import { ValidateIban } from '@expresssteuer/iban-api-angular';`
 */
@Directive({
  selector: '[esuiIban]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: EsuiIbanValidatorDirective,
      multi: true,
    },
  ],
})
export class EsuiIbanValidatorDirective implements AsyncValidator {
  private debounce$ = new BehaviorSubject<number>(500);
  private optional$ = new BehaviorSubject<boolean>(false);

  private iban$ = new BehaviorSubject<string | undefined>(undefined);
  private debouncedIban$ = this.debounce$.pipe(
    switchMap((debounce) => this.iban$.pipe(debounceTime(debounce)))
  );

  private validation$: Observable<IbanError | null> = combineLatest([
    this.iban$,
    this.optional$,
  ]).pipe(
    switchMap(([iban, optional]) => {
      if (!iban && optional) {
        return of(null);
      }
      if (!iban || iban.length < 4) {
        return of({
          invalidIban: true as const,
        });
      }

      return this.debouncedIban$.pipe(
        filter((e): e is string => !!e),
        switchMap((iban) =>
          this.ibanValidator.call({ iban }).pipe(
            map(({ isValid }) =>
              isValid
                ? null
                : {
                    invalidIban: true as const,
                  }
            )
          )
        )
      );
    })
  );

  @Input()
  set debounce(val: NumberInput) {
    this.debounce$.next(coerceNumberProperty(val));
  }

  @Input()
  set optional(val: BooleanInput) {
    this.optional$.next(coerceBooleanProperty(val));
  }

  constructor(
    @Inject(ESUI_IBAN_VALIDATOR) private ibanValidator: IbanValidatorService
  ) {}

  validate(control: { value: string }): Observable<IbanError | null> {
    this.iban$.next(control.value);
    return this.validation$.pipe(take(1));
  }
}
