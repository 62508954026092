import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DOCUMENTSOURCE, TaxNotice } from '@expresssteuer/models';
import { StoreStateIndicatorModel } from '../es-store-state-indicator/store-state-indicator.interface';

@Component({
  selector: 'esui-appeal-submit',
  templateUrl: './esui-appeal-submit.component.html',
  styleUrls: ['./esui-appeal-submit.component.scss'],
})
export class EsuiAppealSubmitComponent {
  @Input()
  documentStoreStateIndicator?: Partial<StoreStateIndicatorModel> | null;
  @Input() document?: {
    contentType: string;
    downloadUrl: string;
    source?: DOCUMENTSOURCE | null;
  } | null;

  @Input() taxNotice?: TaxNotice | null;

  @Output() appealDone = new EventEmitter<void>();
  @Output() acceptAnyway = new EventEmitter<void>();
}
