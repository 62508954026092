<div class="input-group date">
  <input
    class="form-control"
    type="date"
    placeholder="dd-mm-yyyy"
    name="dp"
    [(ngModel)]="dateViewModel"
    ngbDatepicker
    #d="ngbDatepicker"
  />
  <div class="input-group-append"></div>
</div>
