<ng-container *ngIf="taxNotice && document">
  <mat-card>
    <span esuiCardKey>Taxnotice verifier</span>
    <mat-card-header>
      <mat-card-title> </mat-card-title>
      <mat-card-subtitle>
        <esui-store-state-indicator
          [hasOnlineChangedAfterLocalChanged]="
            !!taxNoticeStoreStateIndicator?.hasOnlineChangedAfterLocalChanged
          "
          [isSaving]="!!taxNoticeStoreStateIndicator?.isSaving"
          [isLoading]="!!taxNoticeStoreStateIndicator?.isLoading"
          [hasChanges]="!!taxNoticeStoreStateIndicator?.hasChanges"
          [hasErrors]="!!taxNoticeStoreStateIndicator?.hasErrors"
        ></esui-store-state-indicator>
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <div class="focus" *ngIf="document">
        <div class="documentViewer">
          <esui-document-viewer
            [contentType]="document.contentType"
            [downloadUrl]="document.downloadUrl"
            [source]="document.source"
            [isLoading]="documentStoreStateIndicator?.isSaving"
            [hasErrors]="documentStoreStateIndicator?.hasErrors"
          ></esui-document-viewer>
        </div>
        <div class="metaViewer">
          <esui-tax-notice
            *ngIf="taxNotice"
            [taxNotice]="taxNotice"
            (taxNoticePartialChange)="taxNoticePartialChange.next($event)"
            (taxNoticeChange)="taxNoticeChange.next($event)"
          ></esui-tax-notice>

          <!-- <mat-divider></mat-divider> -->

          <ng-container *ngIf="showTransactionLines">
            <esui-transactionlines
              [showHeadline]="true"
              [transactionLines]="transactionLines"
              [transactionLinesState]="transactionLinesState"
              [selectedTransactionLines]="taxNotice.transactionLines"
              (selectedTransactionLinesChange)="
                selectedTransactionChange($event)
              "
            ></esui-transactionlines>

            <button
              mat-button
              (click)="generateFakeTransactionlineClick.next()"
            >
              fake transaction line
            </button>
            <button
              mat-button
              *ngIf="transactionLines?.length"
              (click)="clearFakeTransactionlineClick.next()"
            >
              clear fake transaction line
            </button>

            <mat-grid-list class="summary" cols="2" rowHeight="20px">
              <mat-grid-tile class="left" [colspan]="1" [rowspan]="1">
                <div class="left">actual return:</div>
              </mat-grid-tile>
              <mat-grid-tile class="right" [colspan]="1" [rowspan]="1">
                <div class="right">
                  {{ (taxNotice?.actualTaxReturn | number : '1.2-2') ?? '--' }}
                </div>
              </mat-grid-tile>
              <mat-grid-tile class="left" [colspan]="1" [rowspan]="1">
                <div class="left">selected transactions:</div>
              </mat-grid-tile>
              <mat-grid-tile class="right" [colspan]="1" [rowspan]="1">
                <div class="right">
                  {{ (sumOfTransactionLines | number : '1.2-2') ?? '--' }}
                </div>
              </mat-grid-tile>
              <mat-grid-tile class="left" [colspan]="1" [rowspan]="1">
                <div class="left">deductions:</div>
              </mat-grid-tile>
              <mat-grid-tile class="right" [colspan]="1" [rowspan]="1">
                <div class="right">
                  {{ (sumOfDeductions | number : '1.2-2') ?? '--' }}
                </div>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="2" [rowspan]="1">
                <div class="leftAndRight">
                  <mat-divider></mat-divider>
                </div>
              </mat-grid-tile>
              <mat-grid-tile class="left" [colspan]="1" [rowspan]="1">
                <div class="left">comparison:</div>
              </mat-grid-tile>
              <mat-grid-tile class="right" [colspan]="1" [rowspan]="1">
                <div
                  class="right"
                  [ngStyle]="{ color: (comparison ?? 0) > 0 ? 'red' : 'green' }"
                >
                  {{ (comparison | number : '1.2-2') ?? '--' }}
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="verifyAndSaveClick.next()"
        [disabled]="
          taxNoticeStoreStateIndicator?.isSaving === true ||
          taxNoticeStoreStateIndicator?.isLoading === true
        "
      >
        verify and save
      </button>
      <button
        type="button"
        mat-raised-button
        color="accent"
        (click)="saveClick.next()"
        [disabled]="
          taxNoticeStoreStateIndicator?.hasChanges !== true ||
          taxNoticeStoreStateIndicator?.isSaving === true ||
          taxNoticeStoreStateIndicator?.isLoading === true
        "
      >
        save
      </button>
      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="discardClick.next()"
        [disabled]="
          taxNoticeStoreStateIndicator?.hasChanges !== true ||
          taxNoticeStoreStateIndicator?.isSaving === true ||
          taxNoticeStoreStateIndicator?.isLoading === true
        "
      >
        discard
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>
