<ng-container *ngIf="viewMode"> {{ date | date }}</ng-container>
<ng-container *ngIf="!viewMode">
  <div
    role="group"
    class="group"
    [ngClass]="{
      showSubPlaceholders: showSubPlaceholders
    }"
  >
    <input
      type="text"
      [placeholder]="showSubPlaceholders ? 'DD' : ''"
      class="day"
      matInput
      [ngModel]="valueProxy.day"
      (ngModelChange)="setPartial({ day: $event })"
      [disabled]="disabled"
      [required]="required"
      (focus)="onFocusIn($event)"
      (focusout)="onFocusOut($event)"
      #street
    />
    <input
      type="text"
      [placeholder]="showSubPlaceholders ? 'MM' : ''"
      class="month"
      matInput
      [ngModel]="valueProxy.month"
      (ngModelChange)="setPartial({ month: $event })"
      [disabled]="disabled"
      [required]="required"
      (focus)="onFocusIn($event)"
      (focusout)="onFocusOut($event)"
      #nbr
    />
    <input
      type="text"
      [placeholder]="showSubPlaceholders ? 'YYYY' : ''"
      class="year"
      matInput
      [ngModel]="valueProxy.year"
      (ngModelChange)="setPartial({ year: $event })"
      [disabled]="disabled"
      [required]="required"
      (focus)="onFocusIn($event)"
      (focusout)="onFocusOut($event)"
      #zip
    />
  </div>
</ng-container>
