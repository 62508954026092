<div
  class="modal modal-sticky modal-sticky-lg"
  id="imgePreview"
  tabindex="-1"
  role="dialog"
  data-backdrop="true"
>
  <div class="modal-dialog" role="modal">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="imagePreviewTitle">
          <a href="{{ document?.file?.downloadUrl }}" target="_blank">
            Vorschau {{ document?.name }}
          </a>
        </h5>
        <button
          type="button"
          class="btn-close"
          data-dismiss="modal"
          aria-label="Close"
          style="border: 0"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>

      <div class="modal-body" *ngIf="document">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a
              class="nav-link active"
              id="preview-tab"
              data-toggle="tab"
              href="#preview"
              role="tab"
              aria-controls="capreviewses"
              aria-selected="true"
            >
              Vorschau
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="metadata-tab"
              data-toggle="tab"
              href="#metadata"
              role="tab"
              aria-controls="metadata"
              aria-selected="false"
            >
              Metadaten
            </a>
          </li>
        </ul>

        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade in show active mt-5 ml-5"
            id="preview"
            role="tabpanel"
            aria-labelledby="preview-tab"
          >
            <esui-document-viewer
              [contentType]="document.contentType"
              [downloadUrl]="document.file.filepath | getDownloadURL"
              [source]="document.source"
              [showNavigation]="true"
              [showDownloadButton]="true"
              class="signature"
            ></esui-document-viewer>
          </div>

          <div
            class="tab-pane fade in mt-5 ml-5"
            id="metadata"
            role="tabpanel"
            aria-labelledby="metadata-tab"
          >
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="exampleSelectl">Typ</label>
                  <select
                    class="form-control form-control-lg"
                    id="fileType"
                    [(ngModel)]="documentType"
                    (change)="updateDocumentType()"
                  >
                    <option value="expense">Ausgaben</option>
                    <option value="identitycard">Ausweis</option>
                    <option value="other">Sonstiges</option>
                    <option value="receipt">Quittung</option>
                    <option value="signature">Unterschrift</option>
                    <option value="wagestatement">
                      Lohnsteuerbescheinigung
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="exampleSelectl">Name</label>
                  <input
                    class="form-control form-control-lg"
                    id="fileName"
                    [(ngModel)]="document.name"
                    (change)="update()"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="exampleSelectl">Beschreibung</label>
                  <textarea
                    class="form-control form-control-lg"
                    id="fileName"
                    [(ngModel)]="document.description"
                    (change)="update()"
                    rows="3"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-body" *ngIf="!document">loading</div>

      <div class="modal-footer" *ngIf="document">
        <!-- <button type="button" class="btn btn-icon btn-danger">
                    <i class="flaticon2-rubbish-bin-delete-button"></i>
                </button> -->
        <button
          type="button"
          class="btn btn-light-success font-weight-bold"
          data-dismiss="modal"
          [routerLink]="['/documents', document.id]"
        >
          Öffnen
        </button>
        <button
          type="button"
          class="btn btn-light-primary font-weight-bold"
          data-dismiss="modal"
        >
          Schliessen
        </button>
      </div>
    </div>
  </div>
</div>
