import { NgModule } from '@angular/core';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import {
  Route,
  RouterModule,
  Routes,
  UrlMatchResult,
  UrlSegment,
  UrlSegmentGroup,
} from '@angular/router';
import { AisDocumentsViewComponent } from './ais-documents-view/ais-documents-view.component';
import { DocumentViewComponent } from './document-view/document-view.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/login']);

/**
 * Match urls like "/:collectionPath/:docId" where
 * collectionPath can contain '/' or be undefined.
 */
const collectionPathMatcher = (
  segments: UrlSegment[],
  group: UrlSegmentGroup,
  route: Route
): UrlMatchResult => {
  if (segments.length <= 0) {
    return null;
  }
  if (segments.length > 0) {
    // combine all but the last segments into a single one
    const collectionPath =
      segments.length > 1
        ? new UrlSegment(segments.slice(0, -1).join('/'), {})
        : undefined;
    const docId = new UrlSegment(segments[segments.length - 1].path, {});

    return {
      consumed: segments,
      posParams: {
        docId,
        collectionPath,
      },
    };
  }
  return null;
};

const routes: Routes = [
  {
    component: DocumentViewComponent,
    matcher: collectionPathMatcher,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: '',
    component: AisDocumentsViewComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AisDocumentsRoutingModule {}
