/// <reference types="@types/google.maps" />
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Optional,
  Self,
} from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NgControl,
  ValidationErrors,
} from '@angular/forms';
import { ExpsAddress } from '@expresssteuer/models';
import {
  AbstractValueAccessor,
  makeProvider,
} from '../abstract-value-accessor';

function addressValidation(
  control: AbstractControl<ExpsAddress | null>
): ValidationErrors | null {
  const addr = control.value;
  if (!addr || ExpsAddress.isAllEmpty(addr)) {
    return null;
  }
  if (ExpsAddress.isEmpty(addr)) {
    return { isEmpty: true };
  }
  if (!ExpsAddress.guard(addr)) {
    return {
      invalid: true,
    };
  }
  return null;
}

@Component({
  selector: 'esui-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useValue: addressValidation,
      multi: true,
    },
    ...makeProvider(AddressInputComponent),
  ],
})
export class AddressInputComponent extends AbstractValueAccessor<ExpsAddress> {
  @Input() viewMode? = false;

  override get empty(): boolean {
    return !this.value || ExpsAddress.isAllEmpty(this.value);
  }

  get showSubPlaceholders() {
    if (this.focused) {
      return true;
    }
    if (this.empty) {
      return false;
    }
    return true;
  }

  override controlType = 'AddressInputComponent';
  protected override data: ExpsAddress = ExpsAddress.getTemplate();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    protected elementRef: ElementRef,
    private ref: ChangeDetectorRef
  ) {
    super(ngControl, elementRef);
  }

  override onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elementRef.nativeElement.querySelector('input')?.focus();
    }
  }

  override writeValue(value: ExpsAddress) {
    if (!value) {
      value = ExpsAddress.getTemplate();
    }
    super.writeValue(value);
  }

  public placeChangedAddress(placesAddress: any) {
    this.ref.detectChanges();
    const newAddress = ExpsAddress.getFromGooglePlaceResult(placesAddress);
    this.value = newAddress;
  }

  setPart(change: {
    nbr?: string;
    zip?: string;
    city?: string;
    street?: string;
    region?: string;
    state?: string;
    country?: string;
  }) {
    const newAddress = this.value
      ? { ...this.value }
      : ExpsAddress.getTemplate();

    if (change.street !== undefined) {
      newAddress.street = {
        ...newAddress.street,
        longName: change.street,
        shortName: change.street,
      };
    }
    if (change.city !== undefined) {
      newAddress.city = {
        ...newAddress.city,
        longName: change.city,
        shortName: change.city,
      };
    }
    if (change.zip !== undefined) {
      newAddress.zipCode = {
        ...newAddress.zipCode,
        longName: change.zip,
        shortName: change.zip,
      };
    }
    if (change.nbr !== undefined) {
      newAddress.nbr = {
        ...newAddress.nbr,
        longName: change.nbr,
        shortName: change.nbr,
      };
    }

    if (change.region !== undefined) {
      newAddress.region = {
        ...newAddress.region,
        longName: change.region,
        shortName: change.region,
      };
    }

    if (change.state !== undefined) {
      newAddress.state = {
        ...newAddress.state,
        longName: change.state,
        shortName: change.state,
      };
    }

    if (change.country !== undefined) {
      newAddress.country = {
        ...newAddress.country,
        longName: change.country,
        shortName: change.country,
      };
    }

    newAddress.formatedAddress = ExpsAddress.getAddressLine(newAddress);
    newAddress.unformatedAddress = newAddress.formatedAddress;

    this.value = newAddress;
  }
}
