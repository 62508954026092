import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-religionselect',
  templateUrl: './religionselect.component.html',
  styleUrls: ['./religionselect.component.scss'],
})
export class ControlReligionselectComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
