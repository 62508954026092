<ng-container>
  <div class="container" *ngIf="documentViewerTemplate">
    <div>
      <!-- <h4>Backside</h4>
      <ng-container
        *ngTemplateOutlet="
          documentViewerTemplate;
          context: { $implicit: answerPayload?.backDocumentRef }
        "
      >
      </ng-container> -->
    </div>
    <div>
      <h4>Frontside</h4>
      <ng-container
        *ngTemplateOutlet="
          documentViewerTemplate;
          context: { $implicit: answerPayload?.frontDocumentRef }
        "
      >
      </ng-container>
    </div>
  </div>
</ng-container>
