<div
  *ngIf="
    AisDocumentsHelperService.clusterHitsByDocumenttype(hits) as clusteredHits
  "
>
  <div
    class="tab-content"
    *ngFor="
      let clusteredHitsKV of clusteredHits | keyvalue;
      trackBy: AisDocumentsHelperService.trackByKey
    "
  >
    <h3 class="mt-5">
      {{ AisDocumentsHelperService.mapClusterToLabel(clusteredHitsKV.key) }}
    </h3>

    <div class="list-group list-group-flush">
      <div
        *ngFor="
          let hit of clusteredHitsKV.value;
          trackBy: AisDocumentsHelperService.trackByObjectID
        "
        class="list-group-item"
      >
        <app-ais-documents-collection-list-item
          [hit]="hit"
        ></app-ais-documents-collection-list-item>
      </div>
    </div>
  </div>
</div>
