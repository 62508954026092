import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StoreStateIndicatorComponent } from './store-state-indicator.component';

@NgModule({
  declarations: [StoreStateIndicatorComponent],
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  exports: [StoreStateIndicatorComponent],
})
export class EsStoreStateIndicatorModule {}
