import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgLetModule } from 'ng-let';
import { FinanceOfficeComponent } from './finance-office.component';

@NgModule({
  declarations: [FinanceOfficeComponent],
  exports: [FinanceOfficeComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    NgLetModule,
  ],
})
export class EsFinanceOfficeModule {}
