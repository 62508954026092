import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ElsterResponse, TaxCase } from '@expresssteuer/models';
import { Observable } from 'rxjs';
import { TaxcasesService } from '../../../app/services/data/taxcases.service';

@Injectable({
  providedIn: 'root',
})
export class Elster {
  public elsterResponsesFiltered$: Observable<ElsterResponse[]>;

  constructor(
    public taxcaseservice: TaxcasesService,
    private fns: AngularFireFunctions,
    private db: AngularFirestore
  ) {}

  public async submitToElster(
    taxcase: TaxCase | null,
    prod: boolean = false
  ): Promise<ElsterResponse> {
    // this.loading = true;

    return new Promise<ElsterResponse>((resolve, reject) => {
      if (prod) {
        this.taxcaseservice.busy.start('Einreichung ans Finanzamt');
      } else {
        this.taxcaseservice.busy.start('Testeinreichung wird erstellt');
      }

      const taxcasetosend: TaxCase =
        taxcase != null ? taxcase : this.taxcaseservice.currentTaxCase;

      const callable = this.fns.httpsCallable('httpsElsterSendToTaxOffice');
      const data = callable({
        taxCaseId: taxcasetosend.id,
        production: prod,
      })
        .toPromise()
        .then((r) => {
          if (r.status.toLowerCase() === 'ok') {
            this.taxcaseservice.loadElster(taxcasetosend);
          }
          this.taxcaseservice.busy.stop();
          resolve(r);
        })
        .catch((err) => {
          console.error('Elster Interface Error', err);
          this.taxcaseservice.busy.stop();
          reject(err);
        });
    });
  }

  public loadForTaxcase(taxcase: TaxCase | null, prod: boolean = false): void {
    this.elsterResponsesFiltered$ = this.db
      .collection('taxcases')
      .doc(taxcase.id)
      .collection<ElsterResponse>('elster')
      .valueChanges();
  }
}
