<ng-container *ngIf="taxcase">
  <mat-toolbar>
    <mat-icon>person</mat-icon>
    <span>Taxcase</span>
    <span class="spacer"></span>
    <button mat-icon-button aria-label="save" (click)="saveClick.next()">
      <mat-icon>save</mat-icon>
    </button>
  </mat-toolbar>

  <pre>{{ taxcase | json }}</pre>
</ng-container>
