import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BinaryDocument, DocumentTypeFields } from '@expresssteuer/models';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  DocumentBindingHelper,
  DocumentsV2Service,
  PropertyBindingHelper,
} from '../../../../app/services/data/documents-v2.service';
import { State } from '../status-indicator/status-indicator.component';

@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss'],
})
export class DocumentViewComponent implements OnInit, OnDestroy {
  State = State;
  DocumentTypeFields = DocumentTypeFields;

  public predictionList: { title: string; value: string }[] = [];

  private subscriptions: Subscription[] = [];

  docHelper?: DocumentBindingHelper;
  syncState?: State = State.pending;
  document?: BinaryDocument;
  initialLoadState?: State = State.pending;

  typeSets?: {
    label: string;
    propHelper: PropertyBindingHelper;
    name: string;
  }[];

  constructor(
    public documentsV2Service: DocumentsV2Service,
    private actRoute: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.subscriptions = [
      this.actRoute.paramMap
        .pipe(
          map((params) => {
            const documentId = params.get('docId');
            const collectionPath = params.get('collectionPath');

            const docHelper = this.documentsV2Service.getDocument(
              documentId,
              collectionPath
            );
            this.docHelper = docHelper;

            this.typeSets = [
              {
                label: 'Identitätskarte',
                propHelper: this.docHelper.forProperty(
                  'metadata.type.identitycard'
                ),
                name: 'type_identity',
              },
              {
                label: 'Unterschrift',
                propHelper: this.docHelper.forProperty(
                  'metadata.type.signature'
                ),
                name: 'type_sig',
              },
              {
                label: 'Lohnsteuerbescheid',
                propHelper: this.docHelper.forProperty(
                  'metadata.type.wagestatement'
                ),
                name: 'type_wage',
              },
              {
                label: 'Beleg für Ausgabe',
                propHelper: this.docHelper.forProperty('metadata.type.expense'),
                name: 'type_exp',
              },
              {
                label: 'Anderer Beleg / Anderes',
                propHelper: this.docHelper.forProperty('metadata.type.other'),
                name: 'typeother',
              },
            ];
          }),
          switchMap(() => {
            return this.docHelper.metadata$.pipe(
              map((metadata) => {
                let state: State;
                if (metadata.fromCache) {
                  state = State.warning;
                } else if (metadata.hasPendingWrites) {
                  state = State.pending;
                } else {
                  state = State.success;
                }
                return {
                  state,
                };
              })
            );
          }),
          switchMap((args) => {
            return this.docHelper.value$.pipe(
              map((document) => {
                console.log('document', document);
                return {
                  ...args,
                  document,
                };
              })
            );
          })
        )
        .subscribe(
          ({ state, document }) => {
            this.initialLoadState =
              document === undefined ? State.danger : State.success;
            this.syncState = state;
            this.document = document;
          },
          () => {
            this.initialLoadState = State.danger;
          }
        ),
    ];
  }

  public isArray(obj: any): boolean {
    return Array.isArray(obj);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  navigateBack() {
    this.location.back();
  }
}
