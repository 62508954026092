export const abTestKeys = [
  'enableQuickFunnel',
  'exp07SocialButtons',
  'exp09OptimizationInOneTouch',
  'exp10PhoneVerification',
  'enableJigglypuff',
  'exp12VmaInOneTouch',
  'useSignUpSuccessModal',
  'exp13UploadOptimization',
  'exp14TrackpadSignature',
  'exp15BlockingWonderlandNotification',
  'exp16NewHelpOverlayKycUpload',
  'exp17JobSlideRemoveFields',
  'exp18ResultSlideAlwaysShowBulletPoints',
  'exp19JobSlideSplit',
  'exp20Poa',
  'expSmokeTest',
  'exp21Referrals',
] as const;
export type ABTestKeys = (typeof abTestKeys)[number];
export type ABTests = {
  [key in ABTestKeys]: boolean;
};
