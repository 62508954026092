import { Timestamp } from '../helpers/timestamp';

export interface ProcessActivityIndicator {
  processId: string;
  meta: {
    setAt: Timestamp;
    triggerName: string;
  };
}

export type FinishActivityIndicator = ProcessActivityIndicator & {
  meta: {
    status: FinishActivityIndicatorStatus;
    error?: unknown;
  };
};

export enum FinishActivityIndicatorStatus {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Abort = 'ABORT',
}
