<esui-section-column-scaffold
  [header]="{
    title: 'filter'
  }"
>
  <ng-container content>
    <mat-list>
      <mat-list-item *ngFor="let entry of filter; trackBy: idByKey">
        <mat-form-field matLine *ngIf="isStringEntry(entry)">
          <mat-icon matSuffix *ngIf="entry.icon">{{ entry.icon }}</mat-icon>
          <esui-text-input
            [placeholder]="entry.label"
            [ngModel]="entry.value"
            (ngModelChange)="
              filterEntryChange.next({ key: entry.key, value: $event })
            "
            [disabled]="entry.disabled === true"
          ></esui-text-input>
        </mat-form-field>

        <div class="buttonToggleField" *ngIf="isBooleanEntry(entry)">
          <div class="label">{{ entry.label }}</div>
          <esui-button-toggle-group
            [ngModel]="entry.value"
            [options]="[
              { value: null, key: '-', label: '-', icon: entry.icons?.null },
              {
                value: true,
                key: 'true',
                label: 'yes',
                icon: entry.icons?.true
              },
              {
                value: false,
                key: 'false',
                label: 'no',
                icon: entry.icons?.false
              }
            ]"
            (ngModelChange)="
              filterEntryChange.next({ key: entry.key, value: $event })
            "
            [disabled]="entry.disabled === true"
          ></esui-button-toggle-group>
        </div>

        <div *ngIf="isOptionEntry(entry)">
          <mat-form-field>
            <mat-icon matSuffix *ngIf="entry.icon">{{ entry.icon }}</mat-icon>
            <mat-label>{{ entry.label }}</mat-label>
            <mat-select
              [ngModel]="entry.value"
              (ngModelChange)="
                filterEntryChange.next({ key: entry.key, value: $event })
              "
              #selectEl="matSelect"
            >
              <mat-option
                *ngFor="let option of entry.options"
                [value]="option.value"
                [disabled]="entry.disabled === true"
              >
                <div class="option-entry">
                  <div>
                    {{ option.label }}
                  </div>
                  <mat-icon *ngIf="selectEl.panelOpen && option.icon">
                    {{ option.icon }}
                  </mat-icon>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-list-item>
    </mat-list>

    <div class="counter">
      <div class="row main">
        count:
        <span class="count">
          {{ taskCount ?? '--' }}
        </span>
      </div>
      <div class="row sub">
        time of count:
        <span class="date">{{ taskCountDate | date : 'medium' }}</span>
      </div>
    </div>
  </ng-container>
</esui-section-column-scaffold>
