import { ClientTodoId } from '../client-todo-id';

export abstract class ClientTodoEscalationInput {
  public static guard(input: unknown): input is ClientTodoEscalationInput {
    const i = input as Partial<ClientTodoEscalationInput> | undefined;
    return typeof i === 'object' && typeof i?.todoId === 'string';
  }
}

export interface ClientTodoEscalationInput {
  todoId: ClientTodoId;
}

export type ClientTodoEscalationOutput = void;
