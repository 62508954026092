<ng-container *ngIf="viewMode"> {{ date | date }}</ng-container>
<ng-container *ngIf="!viewMode">
  <input
    matInput
    [matDatepicker]="picker"
    [min]="min"
    [max]="max"
    [placeholder]="placeholder"
    (blur)="onTouched()"
    [value]="valueProxy"
    (dateChange)="valueProxy = $event.value"
    (focus)="onFocusIn($event)"
    (focusout)="onFocusOut($event)"
    [required]="required"
    [disabled]="disabled"
    [matAutocomplete]="auto"
  />

  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>

  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="this.value = $event.option.value"
  >
    <mat-option
      *ngFor="
        let autocompleteOption of autocompleteOptions;
        trackBy: trackByLabel
      "
      [value]="autocompleteOption.value"
    >
      {{ autocompleteOption.label }}
    </mat-option>
  </mat-autocomplete>
</ng-container>
<!-- TODO also handle onFocusIn and onFocusOut for picker interaction -->
<!--
`focused`
`touched`
`empty`
`shouldLabelFloat`
-->
