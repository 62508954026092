import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsStoreStateIndicatorModule } from '../es-store-state-indicator/es-store-state-indicator.module';
import { EsuiGenericListModule } from '../esui-generic-list/esui-generic-list.module';
import { EsuiSkeletonPlaceholderModule } from '../esui-skeleton-placeholder/esui-skeleton-placeholder.module';
import { PureMethodModule } from '../pipes/pure-method/pure-method.module';
import { EsuiTaxCalculationModule } from './components/tax-calculation.module';
import { EsuiFinalTaxcaseReviewV2Component } from './esui-final-taxcase-review-v2.component';

@NgModule({
  declarations: [EsuiFinalTaxcaseReviewV2Component],
  exports: [EsuiFinalTaxcaseReviewV2Component],
  imports: [
    CommonModule,
    EsCardKeyModule,
    PureMethodModule,
    EsStoreStateIndicatorModule,
    EsuiGenericListModule,
    EsuiSkeletonPlaceholderModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatTableModule,
    EsuiTaxCalculationModule,
  ],
})
export class EsuiFinalTaxcaseReviewV2Module {}
