<a
  [routerLink]="['/client', message.from.id]"
  class="font-size-lg font-weight-bolder text-dark-75 text-hover-primary mr-2"
  *ngIf="!message.metadata.unregistered"
  >{{ message.from.displayName }}</a
>
<a
  class="font-size-lg font-weight-bolder text-dark-75 text-hover-primary mr-2"
  *ngIf="message.metadata.unregistered"
  >{{ message.metadata.fromEmail }}</a
>
