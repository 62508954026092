import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Hotkey } from 'angular2-hotkeys';
import { HotkeysQuery } from '../../hotkeys.query';
import { HotkeysService } from '../../hotkeys.service';

@Component({
  selector: 'esui-hotkeys-cheatsheet',
  templateUrl: './hotkeys-cheatsheet.component.html',
  styleUrls: ['./hotkeys-cheatsheet.component.scss'],
})
export class HotkeysCheatsheetComponent implements OnInit {
  @Input() title = 'hotkeys';
  @Input() buttonText = 'hotkeys';

  @ViewChild('input') input?: ElementRef;

  readonly hotkeyCombo = 'alt+shift+k';

  constructor(
    public hotkeysService: HotkeysService,
    public hotkeysQuery: HotkeysQuery
  ) {}

  ngOnInit() {
    this.hotkeysService
      .register({
        combo: this.hotkeyCombo,
        action: 'show action sheet',
        description: 'show action sheet',
        preventBubbling: true,
      })
      .subscribe(() => {
        this.input?.nativeElement.focus();
        this.hotkeysService.setSearch('');
      });
  }

  trackByKey(_index: number, { key }: { key: Hotkey }) {
    return key;
  }

  displayFn(hotkey?: Hotkey): string {
    return (
      hotkey?.action ??
      (typeof hotkey?.description === 'function'
        ? hotkey?.description?.()
        : hotkey?.description) ??
      ''
    );
  }
}
