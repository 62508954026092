export interface FeatureToggles {
  enforceFinalTaxcaseReviewV2?: boolean;
  enableEmailVerification: boolean;
  enablePartnerSwitchInOneTouch?: boolean;
  enableWonderland?: boolean;
  enableCallCenterSendMessage?: boolean;
  enablePhoneVerification?: boolean;
  exp07SocialButtons?: boolean;
  displayCallFabButton?: boolean;
  displayCallNavButton?: boolean;
  enableIntercom?: boolean;
  enableJigglypuff?: boolean;
  enableMasterVollmachtViaBackend?: boolean;
  enableMasterVollmachtViaTaxform?: boolean;
  enablePayout: boolean;
  enableSkipOneTouchEdits?: boolean;
  enableStatusModalV2?: boolean;
  enableCustomContractStyles?: boolean;
  pauseSignup?: boolean;
}
