import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { EsuiNoTaskInteractionComponent } from './esui-no-task-interaction.component';

@NgModule({
  declarations: [EsuiNoTaskInteractionComponent],
  imports: [CommonModule, MatCardModule],
  exports: [EsuiNoTaskInteractionComponent],
})
export class EsuiNoTaskInteractionModule {}
