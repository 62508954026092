import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Timestamp } from '@angular/fire/firestore';
import { PhoneChangePhoneNumber } from '@expresssteuer/client-api-angular';
import {
  Client,
  ClientMetadata,
  ClientShort,
  DIRECTION,
  DOCUMENTTYPE,
  Extraction,
  MESSAGESTATUS,
  MESSAGETYPE,
  Message,
  ModelHistoryItem,
  PaymentStripe,
  TaxAdvisor,
  TaxCase,
  TaxId,
  TaxSettings,
  WithId,
} from '@expresssteuer/models';
import { TaxofficeHistoryItem } from '@expresssteuer/taxoffice-models';
import firebase from 'firebase/compat/app';
import { orderBy } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  firstValueFrom,
  of,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BusyManager } from '../../../app/shared/helpers/busymanager';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  busy: BusyManager = new BusyManager();

  clientsFiltered$: Observable<Client[]>;

  private aCurrentClient: Client | null = null;

  set currentClient(val: Client | null) {
    if (val && !val.paymentStripe) {
      val.paymentStripe = PaymentStripe.getTemplate();
    }
    if (val && !val.metadata) {
      val.metadata = ClientMetadata.getTemplate();
    }

    this.aCurrentClient = val;
    this.aCurrentClientMessages = null;
    this.aCurrentClientMessagesSend = null;
  }

  get currentClient(): Client | null {
    return this.aCurrentClient;
  }

  capFilter$: BehaviorSubject<string | null>;
  statusFilter$: BehaviorSubject<string | null>;
  limit = 50;

  constructor(
    private db: AngularFirestore,
    private fns: AngularFireFunctions,
    private callablePhoneNumberChange: PhoneChangePhoneNumber
  ) {
    this.capFilter$ = new BehaviorSubject<string | null>(null);
    this.statusFilter$ = new BehaviorSubject<string | null>(null);

    this.clientsFiltered$ = combineLatest([
      this.statusFilter$,
      this.capFilter$,
    ]).pipe(
      switchMap(([status, cap]) =>
        this.db
          .collection<Client>('clients', (ref) => {
            let query:
              | firebase.firestore.CollectionReference
              | firebase.firestore.Query = ref;
            if (status) {
              query = query.where('status', '==', status);
            }
            if (!status) {
              query = query.where('status', '==', 'new');
            }
            if (cap) {
              query = query.where('search.lastnameCap', '==', cap);
            }
            query = query.orderBy('lastname', 'asc');
            if (!cap) {
              query = query.limit(this.limit);
            }
            return query;
          })
          .valueChanges()
      )
    );
  }

  // Messages of the client
  private aCurrentClientMessages: Observable<Message[]> | null = null;
  private aCurrentClientMessagesSend: Observable<Message[]> | null = null;

  getCurrentClientMessagesByEmail(
    email?: string,
    limit = 100
  ): Observable<Message[]> {
    console.log('Get client communication by email ', email);
    // eslint-disable-next-line max-len
    const msgsInbound = this.db
      .collection<Message>('messagecenter', (ref) =>
        ref
          .where('metadata.fromEmail', '==', email)
          .where('direction', '==', 'inbound')
          .orderBy('metadata.created', 'desc')
          .limit(limit)
      )
      .valueChanges();

    const msgsOutbound = this.db
      .collection<Message>('messagecenter', (ref) =>
        ref
          .where('metadata.toEmail', '==', email)
          .where('direction', '==', 'outbound')
          .orderBy('metadata.created', 'desc')
      )
      .valueChanges();

    return this.combineLatestMsgsAndSortDesc(msgsInbound, msgsOutbound);
  }

  /**
   * returns wheter there is at least one valid device registration token fur user
   * indicates what he is an app user
   * @param clientId
   */
  userHasAppInstalled(clientId: string): Observable<boolean> {
    return this.db
      .collection<Client>('clients')
      .doc(clientId)
      .collection<TaxId>('deviceRegistrations')
      .get()
      .pipe(map((snap) => snap.docs.length > 0));
  }
  private combineLatestMsgsAndSortDesc(
    msgsInbound$: Observable<Message[]>,
    msgsOutbound$: Observable<Message[]>,
    brazeEvents$?: Observable<Message[]>
  ): Observable<Message[]> {
    const observables = [msgsInbound$, msgsOutbound$];
    if (brazeEvents$ != null) observables.push(brazeEvents$);
    return combineLatest(observables)
      .pipe(
        map(([msgsInbound, msgsOutbound, brazeEvents]) => [
          ...msgsInbound,
          ...msgsOutbound,
          ...brazeEvents,
        ])
      )
      .pipe(map((arr) => orderBy(arr, (a) => a.metadata.created, 'desc')));
  }

  getCurrentClientMessagesByMobile(
    mobile?: string,
    limit = 100
  ): Observable<Message[]> {
    console.log('Get client communication by mobile ', mobile);
    // eslint-disable-next-line max-len
    const msgsInbound = this.db
      .collection<Message>('messagecenter', (ref) =>
        ref
          .where('metadata.fromMobile', '==', mobile)
          .where('direction', '==', 'inbound')
          .orderBy('metadata.created', 'desc')
          .limit(limit)
      )
      .valueChanges();

    const msgsOutbound = this.db
      .collection<Message>('messagecenter', (ref) =>
        ref
          .where('metadata.toMobile', '==', mobile)
          .where('direction', '==', 'outbound')
          .orderBy('metadata.created', 'desc')
      )
      .valueChanges();

    return this.combineLatestMsgsAndSortDesc(msgsInbound, msgsOutbound);
  }

  getCurrentClientMessages(
    clientId?: string,
    limit = 100
  ): Observable<Message[]> {
    if (!clientId) {
      if (this.currentClient) {
        clientId = this.currentClient.id;
      }
    }

    console.log('Get client communication ', clientId);
    // eslint-disable-next-line max-len
    const msgsInbound$ = this.db
      .collection<Message>('messagecenter', (ref) =>
        ref
          .where('from.id', '==', clientId)
          .where('direction', '==', 'inbound')
          .orderBy('metadata.created', 'desc')
          .limit(limit)
      )
      .valueChanges();

    const msgsOutbound$ = this.db
      .collection<Message>('messagecenter', (ref) =>
        ref
          .where('to.id', '==', clientId)
          .where('direction', '==', 'outbound')
          .orderBy('metadata.created', 'desc')
      )
      .valueChanges();

    const brazeEvents$: Observable<Message[]> = this.db
      .collection(`clients/${clientId}/brazeEvents`)
      .valueChanges()
      .pipe(
        map((events) => {
          return events.map((event: any) => {
            const template = Message.getTemplate();
            const message = {
              ...template,
              metadata: {
                ...template.metadata,
                clientId,
                created:
                  event.time == null
                    ? Timestamp.now()
                    : Timestamp.fromMillis(event.time * 1000),
              },
              to: {
                ...template.to,
                id: clientId,
              } as ClientShort,
              type: 'braze' as MESSAGETYPE,
              direction: DIRECTION.OUTBOUND,
              subject: this.getSubject(event),
              message: this.getBody(event),
              state: MESSAGESTATUS.SENT,
            } as Message;
            return message;
          });
        })
      );

    const combinedList = this.combineLatestMsgsAndSortDesc(
      msgsInbound$,
      msgsOutbound$,
      brazeEvents$
    );

    this.aCurrentClientMessages = combinedList;

    return this.aCurrentClientMessages;
  }

  private getSubject(event: any): string {
    return `Braze ${event.campaign_id != null ? 'Campaign' : 'Canvas'}: ${
      event.campaign_name ?? event.canvas_name ?? 'n.a.'
    }`;
  }

  private getBody(event: any): string {
    return `
      Braze ${event.campaign_id != null ? 'Campaign' : 'Canvas'}:
      ${event.campaign_name ?? event.canvas_name ?? 'no name'}
      <br />
      Message Variation: ${event.message_variation_name ?? 'n.a.'}
      <br />
      Canvas Variation: ${event.canvas_variation_name ?? 'n.a.'}
      <br />
      Canvas Step: ${event.canvas_step_name ?? 'n.a.'}
    `;
  }

  get currentClientMessagesSend(): Observable<Message[]> {
    const currentClient = this.currentClient;
    if (currentClient == null) return of<Message[]>([]);
    if (!this.aCurrentClientMessagesSend) {
      // eslint-disable-next-line max-len
      const clientMsgByPhone = this.db
        .collection<Message>('messagecenter', (ref) =>
          ref
            .where('metadata.clientId', '==', '')
            .where('metadata.toMobile', '==', currentClient.mobile)
            .orderBy('metadata.created', 'desc')
        )
        .valueChanges();

      this.aCurrentClientMessagesSend = clientMsgByPhone;
    }
    return this.aCurrentClientMessagesSend;
  }

  get taxcasesOfClient$(): Observable<TaxCase[]> {
    const currentClient = this.currentClient;
    if (currentClient == null) return of([]);
    return this.db
      .collection<TaxCase>('taxcases', (ref) =>
        ref.where('client.id', '==', currentClient.id)
      )
      .valueChanges({ idField: 'id' });
  }

  /**
   * Connects to the single TaxId of the client
   * @returns
   */
  get taxIdOfClient$(): Observable<WithId<TaxId> | undefined> {
    return this.getTaxIdOfClient$();
  }

  /**
   * Connects to the single TaxId of the client
   * @param clientId
   * @returns
   */
  public getTaxIdOfClient$(
    clientId: string | undefined = this.currentClient?.id
  ): Observable<WithId<TaxId> | undefined> {
    if (!clientId) return of(undefined);
    return this.db
      .collection<Client>('clients')
      .doc(clientId)
      .collection<TaxId>('taxids')
      .doc(clientId)
      .valueChanges({ idField: 'id' });
  }

  /**
   * Get all TaxSettings for every year client/partner
   * @returns
   */
  get taxSettingsOfClient$(): Observable<TaxSettings[]> {
    if (this.currentClient?.id == null) return of<TaxSettings[]>([]);
    return this.db
      .collection<Client>('clients')
      .doc(this.currentClient.id)
      .collection<TaxSettings>('taxsettings')
      .valueChanges({ idField: 'id' });
  }

  /**
   * Get all vmdbImports for every year client/partner
   * @returns
   */
  get vmdbImportsOfClient$(): Observable<Extraction<unknown>[]> {
    if (this.currentClient?.id == null) return of<Extraction<unknown>[]>([]);
    return this.db
      .collection<Client>('clients')
      .doc(this.currentClient.id)
      .collection<Extraction<unknown>>('extractions')
      .valueChanges({ idField: 'id' });
  }

  /**
   * Get all TaxSettings for every year client/partner
   * @param clientId
   * @returns
   */
  specificVmdbImportsOfClient$(
    clientId: string,
    year: string,
    type: DOCUMENTTYPE
  ): Observable<Extraction<unknown>[]> {
    return this.db
      .collection<Client>('clients')
      .doc(clientId)
      .collection<Extraction<unknown>>('extractions', (q) =>
        q.where('year', '==', year).where('type', '==', type)
      )
      .valueChanges({ idField: 'id' });
  }

  /**
   * Get all history for client
   * @returns
   */
  get history$(): Observable<ModelHistoryItem[]> {
    if (this.currentClient?.id == null) return of<ModelHistoryItem[]>([]);
    return this.db
      .collection<Client>('clients')
      .doc(this.currentClient.id)
      .collection<ModelHistoryItem>('history')
      .valueChanges();
  }

  /**
   * Get all history items of taxoffice
   * @returns
   */
  get historyItemsTaxoffice$(): Observable<WithId<TaxofficeHistoryItem>[]> {
    if (this.currentClient?.id == null)
      return of<WithId<TaxofficeHistoryItem>[]>([]);
    return this.db
      .collection<Client>('clients')
      .doc(this.currentClient.id)
      .collection('historyItems')
      .doc('taxoffice')
      .collection<WithId<TaxofficeHistoryItem>>('items')
      .valueChanges({ idField: 'id' });
  }

  /**
   * @deprecated use taxcasesOfClient$ instead
   */
  async getCasesOfClient(client: Client | null): Promise<TaxCase[]> {
    const currentClient: Client | null = client ? client : this.currentClient;
    if (currentClient == null)
      throw new Error('ClientService.getCasesOfClient: client is null');

    const taxcases: TaxCase[] = [];

    console.log(
      `loading taxcases for client ${currentClient.firstname} ${currentClient.id}`
    );
    const taxcaseRef = await this.db
      .collection<TaxCase>('taxcases', (ref) =>
        ref.where('client.id', '==', currentClient.id)
      )
      .get()
      .toPromise();

    console.log(
      `found ${taxcaseRef?.size} taxcases for client ${currentClient.id}`
    );

    for (const c of taxcaseRef?.docs ?? []) {
      const tc: TaxCase = c.data() as TaxCase;
      tc.id = c.id;

      taxcases.push(tc);
    }
    return taxcases;
  }

  async find(searchstring: string): Promise<Client[]> {
    const clients: Client[] = [];
    const callable = await this.fns
      .httpsCallable('httpsCustomerSearch_v2')({ query: searchstring })
      .toPromise();

    for (const hit of callable.hits.hits) {
      const c: Client = hit._source as Client;
      clients.push(c);
    }
    return clients;
  }

  searchClientCap(letter: string): void {
    this.capFilter$.next(letter);
  }

  async exists(email: string): Promise<boolean> {
    const client = await this.db
      .collection('clients', (ref) => ref.where('email', '==', email))
      .get()
      .toPromise();

    if (client != undefined && client.size > 0) {
      if (client.size > 1) {
        console.error('MULTIPLE CLIENTS WITH SAME EMAIL');
        return true;
      } else if (client.size === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public async load(
    id: string,
    returnOnly: boolean = false
  ): Promise<Client | null> {
    console.log('locading client with id', id);
    this.busy.start('Lade Kunde ' + id);

    const clientRef = await this.db
      .collection<Client>('clients')
      .doc(id)
      .get()
      .toPromise();

    if (!clientRef?.exists) {
      console.error(
        'Client Service, the client with id ' + id + ' does not exist'
      );
      if (!returnOnly) {
        this.currentClient = null;
        this.busy.stop();
        return null;
      }
    }

    if (clientRef === undefined) return null;
    const client: Client | undefined = clientRef.data();
    if (client === undefined) return null;
    client.id = clientRef.id;
    if (!returnOnly) {
      this.currentClient = client;
    }
    console.log('client loaded');
    this.busy.stop();
    this.currentClient = client;
    return client;
  }

  public loadRealtime(
    clientId: string
    // returnOnly: boolean = false
  ): Observable<Client | undefined> {
    // this.busy.start('Lade Kunde ' + clientId);
    return this.db.collection<Client>('clients').doc(clientId).valueChanges();
  }
  //TODO: this code may overwrite backend changes and therefore needs to be migrated to a upate rather than a set.
  public async update(client: Client | null): Promise<void> {
    let updateClient: Client | null;
    if (client) {
      updateClient = client;
    } else {
      updateClient = this.currentClient;
    }

    if (updateClient == null) return;

    this.busy.start('Speichere Kunde');
    await this.db
      .collection('clients')
      .ref.doc(updateClient.id)
      .set(updateClient);
    this.busy.stop();
  }

  /**
   * - does nothing in production -
   * Sets the IBAN, SteuerId etc. to make it easier to locally go from signup to retention funnel
   */
  public async devUpdateClientForAndorra(): Promise<void> {
    if (environment.toggles.enableDevUpdateClientForAndorra !== true) return;
    const currentClient = this.currentClient;
    if (currentClient == null) {
      console.warn('devUpdateClientForAndorra.currentClient is null');
      return;
    }
    const data = {
      iban: 'DE02100100100006820101',
      clientTaxId: 12345678995,
      partnerTaxId: 12345678911,
      eTin: 'ABCDEFGH12A12A',
    };

    currentClient.bank.IBAN = data.iban;
    await this.update(this.currentClient);

    const taxsettings = await firstValueFrom(
      this.db
        .collection('clients')
        .doc(currentClient.id)
        .collection<TaxSettings>('taxsettings')
        .valueChanges({ idField: 'id' })
    );

    const promises = [2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015]
      .flatMap((year) => [
        { year, partner: false },
        { year, partner: true },
      ])
      .map(({ year, partner }) => {
        const currentTaxsetting = taxsettings.find(
          (ts) =>
            ts.year === year &&
            (((ts.id == null || ts.id.startsWith('client')) &&
              partner === false) ||
              (ts.id.startsWith('partner') && partner === true))
        );
        const taxsetting: TaxSettings =
          currentTaxsetting != null
            ? {
                ...currentTaxsetting,
                eTin: data.eTin,
              }
            : {
                taxId: partner ? data.partnerTaxId : data.clientTaxId,
                year: year,
                clientId: currentClient.id,
                created: null,
                eTin: data.eTin,
                financeDepartmentId: '999',
                taxAdvisor: { id: TaxAdvisor.getTemplate().id },
                taxCaseId: '',
                taxNumber: '',
                taxPerson: partner ? 'B' : 'A',
                type: partner ? 'partner' : 'client',
                validFrom: null,
                id: '',
              };

        if (taxsetting.id === '') {
          taxsetting.id = TaxSettings.buildDocumentId(taxsetting);
        }
        return taxsetting;
      })
      .map((taxsetting) => {
        return this.db
          .collection('clients')
          .doc(taxsetting.clientId)
          .collection('taxsettings')
          .doc(taxsetting.id)
          .set(taxsetting);
      });
    await Promise.all(promises);

    const taxId = await firstValueFrom(this.taxIdOfClient$);
    if (taxId != null) {
      taxId.taxId = data.clientTaxId;
      await this.db
        .collection('clients')
        .doc(taxId.clientId)
        .collection('taxids')
        .doc(taxId.id)
        .set(taxId);
    }
  }

  public async delete(client: Client): Promise<void> {
    this.busy.start('Deaktiviere Kunden');
    if (client.taxForms === undefined) return;
    for (const taxcase of client.taxForms) {
      console.log('deactivate taxcase: ', taxcase.caseId);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (this.busy.statusmessage = 'deactivate fall: '), taxcase.caseId;
      await this.db
        .collection('taxcases')
        .ref.doc(taxcase.caseId)
        .update({ status: 'deleted' });
    }

    console.log('taxcases deleted');
    this.busy.statusmessage = 'deactivate Kunde';
    await this.db
      .collection('clients')
      .ref.doc(client.id)
      .update({ status: 'deleted' });
    this.busy.stop();
  }

  public async updatePhoneNumberOfClient(
    phoneNumber: string,
    clientId: string
  ): Promise<Error | null> {
    this.busy.start('saving phone number');
    try {
      await firstValueFrom(
        this.callablePhoneNumberChange.call({
          clientId,
          newPhoneNumber: phoneNumber,
        })
      );
    } catch (error) {
      this.busy.error(error.message);
      this.busy.stop();
      return error;
    }
    this.busy.stop();
    return null;
  }
}
