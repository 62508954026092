import { Bank } from '@expresssteuer/banking-models';
import { MarketingIds } from '@expresssteuer/tracking-types';
import { CalculationData2 } from '../calculation/calculation';
import { BinaryDocumentLink } from '../document/document';
import { FirestoreMigration } from '../firestore/firestoreMigration';
import { Timestamp } from '../helpers/timestamp';
import { Task } from '../task/task';
import { TaxAdvisorBankAccount } from '../taxadvisor/bankaccounts/taxadvisorbank';
import { TaxCaseStatus } from '../taxcase/taxcase';
import { ExpsAddress } from '../taxform/address/address';
import { TaxFormGenerator } from '../taxform/taxform';
import { User } from '../user/user';
import { ClientAuthorizations } from './client-authorization';
import { PaymentStripe } from './clientPayment';
import { ClientNotification } from './notifications';

export enum CLIENTSTATUS {
  /**
   * New client without tests
   */
  NEW = 'new',
  /**
   * The client has been confirmed as a valid customer by data and id
   */
  CONFIRMED = 'confirmed',
  /**
   * We have all needed data from the client
   */
  COMPLETE = 'complete',
  /**
   * The client is fraud test or suspicous
   */
  FRAUD = 'fraud',
  /**
   * This client has been deactivated and is not used anymore
   */
  DEACTIVATED = 'deactivated',
  /**
   * This client has marked for deletion.
   * We keep only the data we have from a regulatory perspective
   */
  DELETED = 'deleted',
}

/**
 * @deprecated We use now clientTaxCase in a separate collection
 */
export class ClientTaxForm {
  public static getTemplate(): ClientTaxForm {
    return {
      taxFormId: '',
      taxYear: '',
      caseId: '',
    };
  }

  public taxFormId = '';
  public taxYear = '';
  public caseId = '';
  public assignedTo?: User | null;
  public lastUpdated?: Timestamp | null;
  public status?: TaxCaseStatus;
  public offer?: CalculationData2 | null;
  public todos?: Task[] = [];
}

export class ClientSearch {
  public static getTemplate(): ClientSearch {
    return {
      lastnameCap: '',
    };
  }

  public lastnameCap = '';
}

export class ClientCommunication {
  public static getTemplate(): ClientCommunication {
    return {
      isWhatsApp: true,
      isSMS: true,
      isEmail: true,
    };
  }

  public isWhatsApp = true;
  public isSMS = true;
  public isEmail = true;
}

export class ClientDocuments {
  public static getTemplate(): ClientDocuments {
    return {};
  }

  public signature_client?: BinaryDocumentLink;
  public signature_partner?: BinaryDocumentLink;
  /** @deprecated We use now a subcollection in the client/client_documents */
  public id_client?: BinaryDocumentLink;
  /** @deprecated We use now a subcollection in the client/client_documents */
  public id_partner?: BinaryDocumentLink;
  public profileImage?: BinaryDocumentLink;
}

export class ClientMetadata {
  public static getTemplate(): ClientMetadata {
    return {
      created: null,
      updated: null,
      keyAccount: null,
      taxadvisorId: null,
      selfSubmit: true,
    };
  }

  public keyAccount: User | null = null;
  public created?: Timestamp | null = null;
  public updated?: Timestamp | null = null;

  /**
   * @deprecated This is currently showing either the lastLogin before deprecation or the client creation date.
   *
   * Deprecation Reason: Updating this property on each login slows down the login process.
   */
  public lastLogin?: Timestamp | null = null;

  /**
   * The default taxAdvisor for this customer
   * This taxadvisor is the one we register the "Vollmacht" to.
   * If this taxadvisor changes, we have also to change the vollmacht
   * Also this taxadvisors bank accounts are the ones that will be used to recieve money back from the taxoffice
   */
  taxadvisorId?: string | null = null;

  /**
   * whether this client defaults to client submitted taxcase or by taxadvisor
   * will be passed to taxcase on it's creation or during taxcase testprepare
   */
  selfSubmit?: boolean = true;

  /** @deprecated We move this into a single document */
  public lastWhatsAppMessageRecieved?: Timestamp | null = null;
  /** @deprecated this is not used anymore */
  public mollie?: {
    clientId: string;
    mandateId: string;
  } = { clientId: '', mandateId: '' };
  /**
   * The Marketing IDs the user had when this client was created
   */
  public marketingIdsOnCreation?: MarketingIds;
  public userAgentOnCreation?: string;
  public clientGenerator?: TaxFormGenerator;
  /**
   * The referral code that was used for initial signup
   */
  public referralCodeUsedForSignup?: string;
}

export class ClientShort {
  public static getTemplate(): ClientShort {
    return {
      id: '',
      notifications: [],
      search: ClientSearch.getTemplate(),
      isScout: false,
      firstname: '',
      lastname: '',
      displayName: '',
      language: '',
      email: '',
      mobile: '',
      communication: { isWhatsApp: true, isEmail: true, isSMS: true },
      created: null,
    };
  }

  id = '';
  notifications?: ClientNotification[] = [];
  userId?: string = '';
  firstname = '';
  lastname = '';
  addressClient?: ExpsAddress;
  displayName?: string = '';
  language?: string = '';
  search: ClientSearch = ClientSearch.getTemplate();
  isScout? = false;
  email = '';
  mobile = '';
  created: Timestamp | null = null;
  /**
   * @deprecated We use now a subcollection under the client
   */
  //taxforms?: ClientTaxForm[] = [];
  communication: ClientCommunication = ClientCommunication.getTemplate();
  //documents?: ClientDocuments = ClientDocuments.getTemplate();
}

export class Client {
  public static getTemplate(): Client {
    return {
      id: '',
      notifications: [],
      npmModelVersion: '',
      paymentStripe: PaymentStripe.getTemplate(),

      search: ClientSearch.getTemplate(),
      /**
       * The linked ID with firebase authentication
       */
      userId: '',
      /**
       * The Status of the client
       */
      status: CLIENTSTATUS.NEW,
      isScout: false,
      memberships: [],
      firstname: '',
      lastname: '',
      language: '',
      email: '',
      mobile: '',
      mobileVerified: null,
      bank: Bank.getTemplate(),
      transferBankAccount: null,
      inboundBank: null,
      addressClient: ExpsAddress.getTemplate(),
      /**
       * TaxForms / Cases of the client with automatically updatet status
       */
      /**
       * @deprecated We use now a subcollection under the client
       */
      taxForms: [],
      metadata: ClientMetadata.getTemplate(),
      /**
       * Communication settings for the client.
       */
      communication: ClientCommunication.getTemplate(),
      authorizations: {
        vollmachtsDB: {
          active: false,
          created: null,
          active_until: null,
          deactivated: null,
          hasError: false,
          instanceNbr: null,
          message: '',
          lastUpdated: null,
        },
      },
    };
  }

  /**
   * Return a smaller Client Object. Use this wherever you do not neet a ful Client
   * @param client The Client you want to get a reduced version from
   * @example Do not use xxx.client = client as ClientShort. This will not remove extra properties. use xxx.client = Client.getClientShort
   */
  public static getClientShort(client: Client | ClientShort): ClientShort {
    return {
      id: client.id ? client.id : '',
      notifications: client.notifications ? client.notifications : [],
      firstname: client.firstname,
      lastname: client.lastname,
      email: client.email,
      mobile: client.mobile,
      displayName: client.firstname + ' ' + client.lastname,
      addressClient: client.addressClient,
      userId: client.userId,
      search: client.search,
      communication: ClientCommunication.getTemplate(),
      isScout: client.isScout || false,
      created: client.created || null,
    };
  }

  id: string = '';

  gender?: 'm' | 'f' | 'o' | null = null;

  notifications?: ClientNotification[] = [];

  migration?: FirestoreMigration;
  /**
   * Version Nbr of npm version which has updated last this record
   */
  npmModelVersion = '';

  /**
   * the last sentiment data from the client
   * This is extracted from communication NLP
   */
  lastSentiment?: {
    magnitude: number;
    score: number;
  };
  /**
   * The creation date of the client
   */
  created?: Timestamp;

  /**
   * The customer id in the stripe payment service
   * This property is still in use for very old cases where we have to pick our money over SEPA
   */
  paymentStripe: PaymentStripe = PaymentStripe.getTemplate();
  /**
   * Search by first letter lastname. This is not very usefull anymore since we use Algolia
   * So this will be deprecated in the near future
   */
  search: ClientSearch = ClientSearch.getTemplate();
  /**
   * The UID from Firebase Authentication. This UID is connected to the user account
   */
  userId?: string = '';
  status: CLIENTSTATUS = CLIENTSTATUS.NEW;
  confirmedByWonderland?: Timestamp;
  isScout?: boolean = false;
  firstname = '';
  lastname = '';
  displayName?: string = '';
  memberships?: Membership[] = [];
  language?: string = '';
  email = '';
  emailVerificationId? = '';
  emailVerified? = false;
  emailVerificationTimestamp?: Timestamp | null;
  mobile = '';
  mobileVerified?: boolean | null = null;
  birthdate?: Timestamp | null;
  deletionRequestedAt?: Timestamp | null;
  /**
   * Customers Bank Account for final payout
   */
  bank: Bank = Bank.getTemplate();

  inboundBank?: TaxAdvisorBankAccount | null = null;

  /**
   * This is what is set in the Elster Document.
   * Bank Transfer Account which is used to transfer money from the finance department to us.
   */
  transferBankAccount: Bank | null = null;
  /**
   * Contains the documents related to the client
   * Signature, Identity Card, for client and partner
   * If the face detection works we have here the profile image of the client too
   * @deprecated Moved to Client Documents Collection
   */
  documents?: ClientDocuments;
  /**
   * @deprecated Signature is deprecated because it produced error loops. The client documents are now under @see documents
   */
  signaturePartner?: any; // DEPRECATED => prduces circular refs => use documents => with document links instead
  addressClient: ExpsAddress = ExpsAddress.getTemplate();

  /**
   * Array of taxCases for the client
   * showing al types of information
   */
  taxyears?: Record<string, TaxYear>;

  /**
   * Is the client verified and his data confirmed
   */
  verified?: boolean;

  /**
   * @deprecated taxForms are moved into a subcollection
   */
  taxForms?: ClientTaxForm[] = [];
  metadata?: ClientMetadata = ClientMetadata.getTemplate();
  /**
   * Communication settings.
   */
  communication?: ClientCommunication = ClientCommunication.getTemplate();
  /**
   * This contains authorizations we have from the client.
   * DE : Vollmachten
   */
  authorizations: ClientAuthorizations = {
    // is the
    vollmachtsDB: {
      active: false,
      created: null,
      active_until: null,
      deactivated: null,
      hasError: false,
      instanceNbr: null,
      lastUpdated: null,
      message: '',
    },
  };
  authAdminPatches?: {
    deleteRedundantAuthUser?: {
      removedUid: string;
      commitedAt: Timestamp;
      email: string;
    };
    relinkAuth?: Timestamp;
  };

  /**
   * Indicating a soft lock on the client for agents to
   * know there is some technical investigation going on
   * for this client and its taxcases.
   */
  inMaintenanceMode?: boolean;
}

export enum TAXYEARTYPE {
  /**
   * Active TaxCase in our system
   */
  ACTIVE = 'active',
  /**
   * There is currently a retention case, but not a taxcase
   * Otherwise it would be active
   */
  RETENTION = 'retention',
  /**
   * Customer gave us the go to process the taxyear
   */
  PENDING = 'pending',
  /**
   * Customer does not want the taxyear to be processed from us
   */
  DECLINED = 'declined',
  /**
   * Taxyear has been or is been processed by an external company, competitor or manually
   */
  EXTERNAL = 'external',
}

export enum TAXYEAREXTERNALTYPE {
  UNKNOWN = 'unknown',
  MANUAL = 'manual',
  COMPETITOR = 'competitor',
  TAXADVISOR = 'taxadvisor',
}

export interface TaxYear {
  year: string;
  type: TAXYEARTYPE;
  /**
   * Do we already downloaded the data from VMDB for this year
   */
  vmdb: boolean;
  /**
   * The type of external processing
   * null =
   */
  externalType: TAXYEAREXTERNALTYPE | null;
  /**
   * The name if known from the competitor
   * @example TaxFix
   */
  externalName: string;
}

export abstract class Membership {
  /**
   * Long name of the Membership like Clubmember
   */
  public abstract displayName: string;
  /**
   * Short name like "cm"
   */
  public abstract short: string;
  /**
   * When is the membership active
   */
  public abstract start: Timestamp | null;
  /**
   * When is the membership invalid
   */
  public abstract end: Timestamp | null;
}
