<table
  *ngIf="rows"
  mat-table
  [dataSource]="rows"
  [ngClass]="{
    'mat-elevation-z8': elevated
  }"
>
  <ng-container matColumnDef="labelDe">
    <th mat-header-cell *matHeaderCellDef class="taxyear">{{ year }}</th>
    <td mat-cell *matCellDef="let element">{{ element.labelDe }}</td>
    <td mat-footer-cell *matFooterCellDef>
      Berechnete {{ (expectedReturn || 0) >= 0 ? 'Erstattung' : 'Nachzahlung' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="paid">
    <th mat-header-cell *matHeaderCellDef>Gezahlt</th>
    <td mat-cell *matCellDef="let element">
      {{ element.paid | currency : 'EUR' }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="calculated">
    <th mat-header-cell *matHeaderCellDef>Berechnet</th>
    <td mat-cell *matCellDef="let element">
      {{ element.calculated | currency : 'EUR' }}
    </td>
    <td mat-footer-cell *matFooterCellDef>
      {{ expectedReturn | currency : 'EUR' }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
  <tr
    mat-footer-row
    *matFooterRowDef="columns"
    [class]="{
      red: (expectedReturn ?? 0) < 0,
      green: (expectedReturn ?? 0) > 0
    }"
  ></tr>
</table>
