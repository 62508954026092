<esui-skeleton-placeholder
  class="document-renderer-canvas"
  [bigBoxMode]="true"
  [watchResult]="!!structuredOcr"
  [skeletonBuildInput]="[]"
>
  <ng-container *ngIf="structuredOcr">
    <div class="document-renderer-canvas">
      <div
        class="page"
        *ngFor="let page of structuredOcr.pages; let pageIndex = index"
        [style.width]="(page.dimensions.width * 0.5).toString() + 'px'"
        [style.height]="(page.dimensions.height * 0.9).toString() + 'px'"
      >
        <div class="page-info">
          <p>Page: {{ pageIndex + 1 }}</p>
        </div>
        <div
          class="text-block"
          *ngFor="let block of page.blocks; let blockIndex = index"
          (click)="onTextBlockClicked(pageIndex, blockIndex)"
          [class]="
            selectedBlockIdSet.has(getIdForIndex(pageIndex, blockIndex))
              ? 'text-block-active'
              : ''
          "
          [style.left]="((block?.boundingBox?.x ?? 0) * 100).toString() + '%'"
          [style.top]="((block?.boundingBox?.y ?? 0) * 100).toString() + '%'"
          [style.width]="
            ((block?.boundingBox?.width ?? 0) * 100).toString() + '%'
          "
          [style.height]="
            ((block?.boundingBox?.height ?? 0) * 100).toString() + '%'
          "
          [innerHTML]="block.text.replace(lineBreakRegex, '<br>')"
        ></div>
      </div>
    </div>
  </ng-container>
</esui-skeleton-placeholder>
