import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StatsForNerdsComponent } from './stats-for-nerds.component';

@NgModule({
  declarations: [StatsForNerdsComponent],
  imports: [CommonModule, MatButtonModule, MatTooltipModule, MatIconModule],
  exports: [StatsForNerdsComponent],
})
export class EsStatsForNerdsModule {}
