import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class BlockUiService {
  constructor() {}

  public blockContentSection() {
    this.block('kt_content');
  }

  public unblockContentSection() {
    this.unblock('kt_content');
  }

  public block(id: string) {
    $('#' + id).block({
      message: '<i class="icon-xl spinner"></i>',
      css: {
        background: 'none',
        border: '0px',
      },
    });
  }

  public unblock(id: string) {
    $('#' + id).unblock();
  }
}
