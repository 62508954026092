import { Directive } from '@angular/core';
import { NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { validateFinanceDepartmentId } from '@expresssteuer/tax-utilities';

export interface FinanceDepartmentIdError extends ValidationErrors {
  invalidFinanceDepartmentId: boolean;
}

@Directive({
  selector: '[esuiValidFinanceDepartmentIdValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EsuiValidFinanceDepartmentIdValidatorDirective,
      multi: true,
    },
  ],
})
export class EsuiValidFinanceDepartmentIdValidatorDirective
  implements Validator
{
  validate(control: { value: string | null }): FinanceDepartmentIdError | null {
    return control.value
      ? validateFinanceDepartmentId(control.value)
        ? null
        : { invalidFinanceDepartmentId: true }
      : null;
  }
}
