import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsStatsForNerdsModule } from '../es-stats-for-nerds/es-stats-for-nerds.module';
import { TaskHistoryCardComponent } from './components/task-history-card/task-history-card.component';
import { TaskHistoryItemComponent } from './components/task-history-item/task-history-item.component';
import { TaskHistoryComponent } from './components/task-history/task-history.component';

@NgModule({
  declarations: [
    TaskHistoryComponent,
    TaskHistoryItemComponent,
    TaskHistoryCardComponent,
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    EsCardKeyModule,
    EsStatsForNerdsModule,
  ],
  exports: [
    TaskHistoryComponent,
    TaskHistoryItemComponent,
    TaskHistoryCardComponent,
  ],
})
export class EsTaskHistoryModule {}
