import { Component, Input, OnInit } from '@angular/core';
import { AlgoliaDocument } from '@expresssteuer/models';
import { documentsV2Path } from '../../../../app/app-routing.module';

@Component({
  selector: 'app-ais-documents-collection-list-item',
  templateUrl: './ais-documents-collection-list-item.component.html',
  styleUrls: ['./ais-documents-collection-list-item.component.scss'],
})
export class AisDocumentsCollectionListItemComponent implements OnInit {
  @Input()
  hit: AlgoliaDocument;

  @Input()
  disabled: boolean;

  get linkToItem(): [string] | undefined {
    return (
      this.hit?.objectID && ['/' + documentsV2Path + '/' + this.hit.objectID]
    );
  }

  get uploadedDate(): Date | undefined {
    const timestamp = parseInt(this.hit?.uploadedMilis, 10);
    return timestamp ? new Date(timestamp) : undefined;
  }

  constructor() {}

  ngOnInit(): void {}
}
