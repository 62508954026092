import { Component, Input, OnInit } from '@angular/core';
import {
  Client,
  CLIENTSTATUS,
  MESSAGETYPE,
  TaxCase,
} from '@expresssteuer/models';
import moment from 'moment';
import { MessagingService } from '../../../../../app/services/data/messaging.service';

@Component({
  selector: 'app-clientheader',
  templateUrl: './clientheader.component.html',
  styleUrls: ['./clientheader.component.scss'],
})
export class ControlClientheaderComponent implements OnInit {
  CLIENTSTATUS = CLIENTSTATUS;

  public age = 0;
  private aClient: Client = Client.getTemplate();
  @Input() taxCase: TaxCase = TaxCase.getTemplate();

  @Input()
  public set client(val: Client) {
    this.aClient = val;
    if (val && val.birthdate) {
      const age = moment().diff(val.birthdate.toDate(), 'years');
      this.age = age;
    }
  }

  public get client(): Client {
    return this.aClient;
  }

  constructor(public messagingservice: MessagingService) {}

  public async sendWhatsApp() {
    await this.messagingservice.openMessanger(
      MESSAGETYPE.WHATSAPP,
      this.client
    );
  }

  public async sendEmail() {
    await this.messagingservice.openMessanger(MESSAGETYPE.EMAIL, this.client);
  }

  ngOnInit(): void {}
}
