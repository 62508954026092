import { Injectable } from '@angular/core';
import { Hotkey } from 'angular2-hotkeys';
import { BehaviorSubject, Subject } from 'rxjs';

interface HotkeysState {
  search?: string | null;
  hotkeys: Map<
    Hotkey,
    Subject<{
      event: KeyboardEvent;
      combo: string;
    } | null>
  >;
}

@Injectable()
export class HotkeysStore extends BehaviorSubject<HotkeysState> {
  constructor() {
    super({
      hotkeys: new Map(),
    });
  }

  update(reducer: (state: HotkeysState) => HotkeysState) {
    this.next(reducer(this.getValue()));
  }

  addHotkey(
    hotkey: Hotkey,
    emitter: Subject<{
      event: KeyboardEvent;
      combo: string;
    } | null>
  ) {
    const current = this.getValue();

    this.next({
      ...current,
      hotkeys: new Map([...current.hotkeys, [hotkey, emitter]]),
    });
  }

  removeHotkey(hotkey: Hotkey) {
    const current = this.getValue();

    this.next({
      ...current,
      hotkeys: new Map([...current.hotkeys].filter(([h]) => h !== hotkey)),
    });
  }
}
