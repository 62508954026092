import { SchemaProperty } from './vast';

export const VastGdbSchema: SchemaProperty = {
  title: 'VaSt_GDB-202201.xsd',
  $schema: 'http://json-schema.org/schema#',

  type: 'object',
  properties: {
    VaSt_GDB: {
      type: 'object',
      description:
        'Bescheinigung zur Basiskrankenversicherung und gesetzlichen Pflegeversicherung',
      properties: {
        Eingangsdatum: {
          description:
            'Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung',
          type: 'string',
        },
        Verfasser: {
          description: 'Übermittelnde Stelle',
          type: 'string',
        },
        Meldejahr: {
          description: 'Jahr',
          type: 'string',
        },
        FeststellungsDat: {
          description: 'Datum der Feststellung',
          type: 'string',
        },
        BetroffenePerson: {
          type: 'object',
          properties: {
            IdNr: {
              description: 'Identifikationsnummer',
              type: 'string',
            },
            Name: {
              description: 'Nachname',
              type: 'string',
            },
            Vorname: {
              description: 'Vorname',
              type: 'string',
            },
            Blockname: {
              description: 'Name',
              type: 'string',
            },
            NamensVorsatz: {
              description: 'Namensvorsatz',
              type: 'string',
            },
            NamensZusatz: {
              description: 'Namenszusatz',
              type: 'string',
            },
            Titel: {
              description: 'Titel',
              type: 'string',
            },
          },
        },
        GradDerBehinderung: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              Grad: {
                description: 'Grad der Behinderung',
                type: 'integer',
              },
              VonDat: {
                description: 'Gültig von',
                type: 'string',
              },
              BisDat: {
                description: 'Gültig bis',
                type: 'string',
              },
              Unbefristet: {
                description: 'Unbefristet gültig',
                type: 'string',
                enum: ['1'],
              },
            },
          },
        },
        Merkzeichen: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              Merkmal: {
                description: 'Merkzeichen',
                type: 'string',
              },
              VonDat: {
                description: 'Gültig von',
                type: 'string',
              },
              BisDat: {
                description: 'Gültig bis',
                type: 'string',
              },
              Unbefristet: {
                description: 'Unbefristet gültig',
                type: 'string',
                enum: ['1'],
              },
            },
          },
        },
        Hinterbliebene: {
          type: 'object',
          properties: {
            Gewaehrt: {
              description: 'Berücksichtigung Hinterbliebenen-Pauschbetrag',
              type: 'string',
              enum: ['1'],
            },
            VonDat: {
              description: 'Von',
              type: 'string',
            },
            BisDat: {
              description: 'Bis',
              type: 'string',
            },
            Unbefristet: {
              description: 'dauerhaft von der Finanzverwaltung gewährt',
              type: 'string',
              enum: ['1'],
            },
          },
        },
        version: {
          type: 'string',
          enum: ['202201'],
        },
      },
      required: ['version'],
    },
  },
  required: ['VaSt_GDB'],
};
