import { Component } from '@angular/core';
import {
  AbstractValueAccessor,
  makeProvider,
} from '../abstract-value-accessor';

@Component({
  selector: 'esui-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [...makeProvider(TextInputComponent)],
})
export class TextInputComponent extends AbstractValueAccessor<string> {
  protected data = '';

  /**
   * @override
   */
  get empty(): boolean {
    return !this.value;
  }

  /**
   * @override
   */
  controlType = 'TextInputComponent';

  /**
   * @override
   */
  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elementRef.nativeElement.querySelector('input')?.focus();
    }
  }
}
