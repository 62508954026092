<ng-container *ngIf="messages">
  <ng-container *ngFor="let message of messages">
    <esui-message [message]="message"></esui-message>
    <div
      class="lastReadMessageIndicator"
      *ngIf="lastUnreadMessageId === message.id"
    >
      <mat-divider></mat-divider>
      <span>unread</span>
      <mat-divider></mat-divider>
    </div>
  </ng-container>
  <ng-container *ngIf="messages.length < 1">
    <p>no message</p>
  </ng-container>
</ng-container>
