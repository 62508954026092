<esui-section-column-scaffold
  [header]="{
    title: 'todos'
  }"
>
  <ng-container headerMenu>
    <button
      matTooltip="re-generate todos"
      (click)="regenerateClick.next()"
      mat-icon-button
      aria-label="refresh"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  </ng-container>

  <ng-container content>
    <mat-selection-list
      [multiple]="false"
      [(ngModel)]="ngModel"
      (ngModelChange)="ngModelChange.next($event)"
    >
      <ng-container *ngFor="let todo of todos; trackBy: trackById">
        <ng-container
          *ngIf="{
            accepted: (todo.acceptedAnswers?.length ?? 0) > 0
          } as todoData"
        >
          <mat-list-option [value]="todo" @animateDestroy>
            <!-- 
              <span mat-list-icon class="active-icon">
                {{ todo.active }}
              </span> 
            -->
            <span
              mat-list-icon
              class="status-indicator"
              [ngClass]="{
                inactive: !todo.active,
                active: todo.active,
                accepted: todoData.accepted
              }"
              [matTooltip]="
                todoData.accepted
                  ? 'accepted'
                  : todo.active
                  ? 'active'
                  : 'inactive'
              "
            >
            </span>

            <span matLine>{{ todo.type }}</span>
            <pre matLine>manualMode: {{ todo.manualMode }}</pre>
            <pre matLine>id: {{ todo.id }}</pre>
          </mat-list-option>
        </ng-container>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-selection-list>
  </ng-container>
</esui-section-column-scaffold>
