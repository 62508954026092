import { Component, Input, OnInit } from '@angular/core';

enum Icon {
  image = 'bi-file-earmark-image',
  file = 'bi-file-earmark-richtext',
}

@Component({
  selector: 'app-ais-documents-icon',
  templateUrl: './ais-documents-icon.component.html',
  styleUrls: ['./ais-documents-icon.component.scss'],
})
export class AisDocumentsIconComponent implements OnInit {
  @Input()
  mimeType?: string = '';

  match(regex: string) {
    return !!this.mimeType?.match(new RegExp(regex));
  }

  constructor() {}

  ngOnInit(): void {}
}
