import { DOCUMENTSOURCE } from '../document/document';
import { Timestamp } from '../helpers/timestamp';

export abstract class TaxofficeCommunicationHistoryItem {
  /**
   * e.g. ref to taxofficeInformations or taxofficeRequests
   */
  abstract foreignDocumentRef: string;
  /**
   * associated client
   */
  abstract clientId: string;
  /**
   * the type used in the binary document or an arbitrary string. This is for the purpose of a better overview in a UI.
   */
  abstract type: string;
  /**
   * the source of the document, e.g. CLIENT, FINANCEDEPARTMENT, SYSTEM
   */
  abstract source: DOCUMENTSOURCE;
  /**
   * date the item was issued
   */
  abstract creationDate?: Timestamp;
  /**
   * date we received the letter
   */
  abstract receivingDate?: Timestamp;
  /**
   * binaryDocumentReference to revision safe binary document
   */
  abstract binaryDocumentRef?: string;
}
