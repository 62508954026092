import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsuiDocumentViewerModule } from '../esui-document-viewer/esui-document-viewer.module';
import { EsuiSkeletonPlaceholderModule } from '../esui-skeleton-placeholder/esui-skeleton-placeholder.module';
import { EsuiTaxNoticeModule } from '../esui-tax-notice/esui-tax-notice.module';
import { EsuiPayoutNomatchComponent } from './esui-payout-nomatch.component';

@NgModule({
  declarations: [EsuiPayoutNomatchComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    EsuiDocumentViewerModule,
    EsuiSkeletonPlaceholderModule,
    EsCardKeyModule,
    EsuiTaxNoticeModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
  ],
  exports: [EsuiPayoutNomatchComponent],
})
export class EsuiPayoutNomatchModule {}
