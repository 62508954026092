import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { NumberInputComponent } from './number-input.component';

@NgModule({
  declarations: [NumberInputComponent],
  imports: [CommonModule, FormsModule, MatInputModule, MatAutocompleteModule],
  exports: [NumberInputComponent],
})
export class NumberInputModule {}
