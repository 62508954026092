import { Directive } from '@angular/core';
import { NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { DateString } from '@expresssteuer/models';
import moment from 'moment';

export interface DateError extends ValidationErrors {
  invalidExistingHumanDate: boolean;
}

@Directive({
  selector: '[esuiValidExistingHumanDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EsuiDateValidatorDirective,
      multi: true,
    },
  ],
})
export class EsuiDateValidatorDirective implements Validator {
  validate(control: { value: DateString }): DateError | null {
    const momentDate = moment(control.value, 'YYYY-MM-DD');
    const age = moment().diff(momentDate, 'years');
    const isInTheFuture = moment().diff(momentDate) < 0; // checking only for years would allow up to one year ahead
    if (!momentDate.isValid() || age < 0 || age > 150 || isInTheFuture) {
      return {
        invalidExistingHumanDate: true,
      };
    }
    return null;
  }
}
