<div>
  <div>
    <mat-form-field>
      <mat-label>Tax ID</mat-label>
      <input
        matFormFieldControl
        type="number"
        matInput
        [ngModel]="validationResult?.taxId ?? ''"
        (ngModelChange)="taxidChange($event)"
      />
    </mat-form-field>
  </div>
  <div>
    <mat-checkbox
      [checked]="validationResult?.valid ?? false"
      [disabled]="true"
    >
      Valid (inferred automatically)
    </mat-checkbox>
  </div>
</div>
