import { Bank } from '@expresssteuer/banking-models';
import { Timestamp } from '../../helpers/timestamp';

export class TaxAdvisorBankAccountShort {
  static getTemplate(): TaxAdvisorBankAccountShort {
    return {
      taxAdvisorId: '',
      IBAN: '',
      bankCode: '',
      bankName: '',
      bankBIC: '',
    };
  }
  taxAdvisorId: string = '';
  IBAN: string = '';
  bankCode: string = '';
  bankName: string = '';
  bankBIC: string = '';
}
/**
 * The TaxAdvisor Bank Account is the all "DE Treuhandkonto"
 * from a TaxAdvisor
 * TODO: refactor to BankAccount new models
 */
export class TaxAdvisorBankAccount extends Bank {
  static getTaxAdvisorBankShort(
    bank: TaxAdvisorBankAccount
  ): TaxAdvisorBankAccountShort {
    return {
      taxAdvisorId: bank.taxAdvisorId,
      IBAN: bank.IBAN,
      bankBIC: bank.bankBIC,
      bankCode: bank.bankCode,
      bankName: bank.bankName,
    };
  }

  static override getTemplate(): TaxAdvisorBankAccount {
    return {
      id: '',
      active: true,
      default: false,
      taxAdvisorId: '',
      validFrom: null,
      validUntil: null,
      validated: false,
      valid: false,
      IBAN: '',
      bankCode: '',
      bankName: '',
      bankBIC: '',
      bankPLZ: '',
      bankCity: '',
    };
  }

  id: string = '';
  /**
   * The TaxAdvisor the Bank Account belongs to
   */
  taxAdvisorId: string = '';
  /**
   * The account is active or deactivated
   */
  active: boolean = true;
  /**
   * There is only one default per taxAdvisor allowed
   */
  default: boolean = false;
  /**
   * If a date is set, this bank account will be active beginning of
   * Null means the account is active without restriction
   */
  validFrom: Timestamp | null = null;
  /**
   * If a date is set, this bank account will be active until this date
   * Null means the account is active without restriction
   */
  validUntil: Timestamp | null = null;

  /**
   * Does this bank account provida vibans?
   * If true, this is the main Bank account number where virtual bank accounts will be grouped into.
   * If no, this is a standard bank account number
   */
  viban? = true;
}
