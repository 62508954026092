import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../app/services/security/authentication.service';

@Component({
  selector: 'app-userpanel',
  templateUrl: './userpanel.component.html',
  styleUrls: ['./userpanel.component.scss'],
})
export class UserpanelComponent implements OnInit {
  constructor(public security: AuthenticationService) {}

  ngOnInit(): void {}
}
