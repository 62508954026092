import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsuiDocumentViewerModule } from '../esui-document-viewer/esui-document-viewer.module';
import { EsuiSkeletonPlaceholderModule } from '../esui-skeleton-placeholder/esui-skeleton-placeholder.module';
import { DateInputModule } from '../form-inputs/date-input/date-input.module';
import { EsuiTaxnoticeManualProcessComponent } from './esui-taxnotice-manual-process.component';

@NgModule({
  declarations: [EsuiTaxnoticeManualProcessComponent],
  imports: [
    CommonModule,
    MatCardModule,
    EsCardKeyModule,
    EsuiSkeletonPlaceholderModule,
    MatFormFieldModule,
    DateInputModule,
    EsuiDocumentViewerModule,
    MatInputModule,
    FormsModule,
    MatTableModule,
    MatButtonModule,
    MatListModule,
  ],
  exports: [EsuiTaxnoticeManualProcessComponent],
})
export class EsuiTaxnoticeManualProcessModule {}
