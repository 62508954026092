<mat-list>
  <mat-list-item>
    <mat-icon mat-list-icon matTooltip="est. reimbursement">calculate</mat-icon>
    <span
      *ngLet="taxRefund"
      class="bubble"
      [ngClass]="{
        negative: taxRefund && taxRefund < 0,
        positive: taxRefund && taxRefund > 0,
        none: !taxRefund
      }"
    >
      <ng-container *ngIf="!taxRefund && taxRefund !== 0">-</ng-container>
      <ng-container *ngIf="taxRefund">
        {{ taxRefund | currency : 'EUR' }}
      </ng-container>
    </span>
  </mat-list-item>
</mat-list>
