import { Component } from '@angular/core';
import { AnchorMenuComponent } from './anchor-menu.component';

/**
 * @requires an provided `HREF_BUILDER`
 */
@Component({
  selector: 'esui-anchor-tabs',
  templateUrl: './anchor-tabs.component.html',
  styleUrls: ['./anchor-tabs.component.scss'],
})
export class AnchorTabsComponent extends AnchorMenuComponent {}
