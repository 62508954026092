import { Component, Input } from '@angular/core';
import { TaskHistoryComponent } from '../task-history/task-history.component';

@Component({
  selector: 'esui-task-history-card',
  templateUrl: './task-history-card.component.html',
  styleUrls: ['./task-history-card.component.scss'],
})
export class TaskHistoryCardComponent extends TaskHistoryComponent {
  @Input() transparentCard = false;
}
