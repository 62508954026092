import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Secret } from '@expresssteuer/models';
import algoliasearch, { SearchClient } from 'algoliasearch';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../security/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AlgoliaService {
  private rawSearchClient$: Observable<SearchClient>;
  public searchClient$: Observable<SearchClient>;

  constructor(
    private afStore: AngularFirestore,
    private afFunctions: AngularFireFunctions,
    private authenticationService: AuthenticationService
  ) {
    this.rawSearchClient$ = this.authenticationService.user$.pipe(
      filter((user) => !!user),
      switchMap((user) => {
        return this.afStore
          .collection<Secret>('secrets')
          .doc(user.authId)
          .valueChanges();
      }),
      map((secret) => {
        if (!secret?.algoliaKey) {
          console.info('generating new algolia token');
          return undefined;
        }
        if (secret.algoliaValidUntil * 1000 < Date.now()) {
          console.info('refreshing algolia token');
          return undefined;
        }
        return algoliasearch(environment.algolia.appId, secret.algoliaKey);
      }),
      shareReplay()
    );
    this.searchClient$ = this.rawSearchClient$.pipe(
      filter((searchClient) => !!searchClient)
    );
    this.rawSearchClient$
      .pipe(
        filter((searchClient) => !searchClient),
        switchMap(() => this.updateKey())
      )
      .subscribe();
  }

  updateKey(): Observable<any> {
    console.log('requesting new token');
    return this.afFunctions.httpsCallable('httpsGenerateAlgoliaSecuredKey')({});
  }
}
