<ng-container *ngIf="client">
  <mat-card
    [ngClass]="{
      transparentCard: transparentCard
    }"
  >
    <mat-card-header>
      <mat-icon *ngIf="client.inMaintenanceMode === true" mat-card-avatar>
        {{ client.inMaintenanceMode === true ? 'warning' : 'person' }}
      </mat-icon>
      <mat-card-title>
        <ng-container *ngIf="client.displayName; else fallbackName">
          {{ client.displayName }}
        </ng-container>
        <ng-template #fallbackName>
          {{ client.firstname }} {{ client.lastname }}
        </ng-template>
      </mat-card-title>
      <mat-card-subtitle> status: {{ client.status }} </mat-card-subtitle>
      <mat-card-subtitle class="warn" *ngIf="client.inMaintenanceMode === true"
        >in maintenance mode</mat-card-subtitle
      >
    </mat-card-header>
    <!-- <esui-stats-for-nerds [data]="client" label="client"></esui-stats-for-nerds> -->
  </mat-card>
</ng-container>
