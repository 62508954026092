<select
  class="form-control"
  id="static"
  *ngIf="data | async as statics"
  [(ngModel)]="selected"
>
  <option *ngFor="let item of statics.religions" [ngValue]="item.value">
    {{ item.labelDe }}
  </option>
</select>
