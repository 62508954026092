import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsuiDocumentViewerModule } from '../esui-document-viewer/esui-document-viewer.module';
import { EsuiSkeletonPlaceholderModule } from '../esui-skeleton-placeholder/esui-skeleton-placeholder.module';
import { PureMethodModule } from '../pipes/pure-method/pure-method.module';
import { TimestampModule } from '../pipes/timestamp/timestamp.module';
import { EsuiTaxofficeAssignDocsToRequestComponent } from './esui-taxoffice-assign-docs-to-request.component';

@NgModule({
  declarations: [EsuiTaxofficeAssignDocsToRequestComponent],
  imports: [
    CommonModule,
    EsCardKeyModule,
    EsuiDocumentViewerModule,
    EsuiSkeletonPlaceholderModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatSlideToggleModule,
    PureMethodModule,
    TimestampModule,
  ],
  exports: [EsuiTaxofficeAssignDocsToRequestComponent],
  providers: [DatePipe],
})
export class EsuiTaxofficeAssignDocsToRequestModule {}
