import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AdditionalExpense } from '@expresssteuer/models';

@Component({
  selector: 'esui-expense-list',
  templateUrl: './esui-expense-list.component.html',
  styleUrls: ['./esui-expense-list.component.scss'],
})
export class EsuiExpenseListComponent {
  @Input()
  public expenses: AdditionalExpense[] = [];

  @Output()
  public editExpense = new EventEmitter<AdditionalExpense>();
  @Output()
  public deleteExpense = new EventEmitter<AdditionalExpense>();
}
