<ng-container *ngIf="dialogData$ | async as data">
  <h2 mat-dialog-title>
    {{ (data.position ?? 0) + 1 }} / {{ data.total }} Dokument:
    {{ data.title }} (ID: {{ data.id }})
  </h2>
  <mat-dialog-content class="mat-typography mat-dialog-content">
    <esui-document-viewer
      class="document-viewer-container"
      [contentType]="data.pdfViewerData.contentType"
      [downloadUrl]="data.pdfViewerData.downloadUrl"
    ></esui-document-viewer>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      mat-raised-button
      [color]="data.docIsFulfillingActiveContentTagHandle ? 'warn' : 'primary'"
      *ngIf="data.toggleDocFulfillingActiveContentTagHandle"
      (click)="data.toggleDocFulfillingActiveContentTagHandle()"
    >
      {{
        data.docIsFulfillingActiveContentTagHandle
          ? 'Von der Antwort entfernen'
          : 'Zur Antwort hinzufügen'
      }}
      [enter]
    </button>
    <button
      mat-button
      mat-raised-button
      *ngIf="data.goPrev"
      [disabled]="data.hasPrev !== true"
      (click)="data.goPrev()"
    >
      Vorheriges [←]
    </button>
    <button
      mat-button
      mat-raised-button
      *ngIf="data.goNext"
      [disabled]="data.hasNext !== true"
      (click)="data.goNext()"
    >
      Nächstes [→]
    </button>
    <button mat-button mat-raised-button mat-dialog-close>
      Schließen [esc]
    </button>
  </mat-dialog-actions>
</ng-container>
