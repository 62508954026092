import { Component, EventEmitter, Input, Output } from '@angular/core';

const ALL_YEARS: number[] = [];
for (let i = 1950; i <= 2050; i++) {
  ALL_YEARS.push(i);
}

@Component({
  selector: 'esui-select-multi-year-chips',
  templateUrl: './esui-select-multi-year-chips.component.html',
  styleUrls: ['./esui-select-multi-year-chips.component.scss'],
})
export class EsuiSelectMultiYearChipsComponent {
  @Input()
  value: number[] = [];

  @Output()
  valueChange = new EventEmitter<number[]>();

  allYears: number[] = ALL_YEARS;

  add(year: number): void {
    if (this.value.includes(year)) return;
    this.valueChange.emit([...this.value, year].sort());
  }

  remove(year: number): void {
    if (!this.value.includes(year)) return;
    this.valueChange.emit(this.value.filter((y) => y !== year));
  }
}
