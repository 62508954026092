import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  ApprovalSetInput,
  ApprovalSetOutput,
} from '@expresssteuer/client-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApprovalSet {
  constructor(public afFunctions: AngularFireFunctions) {}

  /**
   * Last step of the aquana step. This will approve the previously shown Elster draft document and submit the taxCase to the financeDepartment.
   *
   * @api summary Finish aquana flow
   * @api tag aquana
   * @api consumer andorra,mobileapp
   */
  call(input: ApprovalSetInput) {
    console.log('Calling client-approvalSet');
    return this.afFunctions.httpsCallable<ApprovalSetInput, ApprovalSetOutput>(
      'client-approvalSet'
    )(input);
  }
}
