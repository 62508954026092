/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AircallService } from '../../../services/data/aircall/aircall.service';
import { AuthenticationService } from '../../../services/security/authentication.service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let $: any;
@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
})
export class AsideComponent implements OnInit {
  public env = environment;

  constructor(
    private route: Router,
    public security: AuthenticationService,
    public title: Title,
    public aircallService: AircallService,
    private router: Router
  ) {}

  public async logout() {
    await this.security.logout();
    await this.route.navigate(['/login']);
  }

  ngOnInit() {
    this.security.isLoggedIn$.subscribe(() => {
      console.log('Aircall Service user is logged in');

      if (!this.security.user) {
        throw new Error('User is not logged in, can not register with Aircall');
      }
      this.aircallService.registerUserForIncommingCalls(this.security.user);

      console.log('Aircall for user Registered', this.security.user.email);
    });
  }

  public updateTitle(titleName: string) {
    this.title.setTitle('ExS:: ' + titleName);
  }
}
