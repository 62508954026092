import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FinanceOffice, TaxAdvisor, TaxSettings } from '@expresssteuer/models';

@Component({
  selector: 'esui-taxsettings',
  templateUrl: './taxsettings.component.html',
  styleUrls: ['./taxsettings.component.scss'],
})
export class TaxsettingsComponent {
  @Input()
  eTin = '';
  @Output()
  eTinChange = new EventEmitter<string>();

  @Input()
  financeDepartmentId = '';
  @Output()
  financeDepartmentIdChange = new EventEmitter<string>();

  @Input()
  type: 'client' | 'partner' = 'client';
  @Output()
  typeChange = new EventEmitter<string>();

  @Input()
  taxPerson: 'A' | 'B' = 'A';
  @Output()
  taxPersonChange = new EventEmitter<string>();

  @Input()
  taxNumber = 0;
  @Output()
  taxNumberChange = new EventEmitter<number>();

  @Input()
  partnerTaxId = 0;
  @Output()
  partnerTaxIdChange = new EventEmitter<number>();

  /**
   * TaxAdvisor Settings
   */
  @Input()
  /**
   * If set, allows to select and show the taxAdvisor
   */
  taxadvisors?: TaxAdvisor[] | null;
  @Input()
  taxAdvisorId = '';
  @Output()
  taxAdvisorIdChange = new EventEmitter<string>();

  @Output()
  taxsettingChanged = new EventEmitter<TaxSettings>();

  @Input()
  financeOffice?: FinanceOffice | null;
}
