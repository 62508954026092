import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NumberInputModule } from '../form-inputs/number-input/number-input.module';
import { TextInputModule } from '../form-inputs/text-input/text-input.module';
import { EsuiExpenseEditorComponent } from './components/esui-expense-editor/esui-expense-editor.component';
import { EsuiExpenseListComponent } from './components/esui-expense-list/esui-expense-list.component';

@NgModule({
  declarations: [EsuiExpenseEditorComponent, EsuiExpenseListComponent],
  exports: [EsuiExpenseEditorComponent, EsuiExpenseListComponent],
  imports: [
    CommonModule,
    FormsModule,
    NumberInputModule,
    TextInputModule,
    MatCheckboxModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
  ],
})
export class EsuiExpenseEditorModule {}

export { EsuiExpenseEditorComponent } from './components/esui-expense-editor/esui-expense-editor.component';
export { EsuiExpenseListComponent } from './components/esui-expense-list/esui-expense-list.component';
