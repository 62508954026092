import {
  Answer as AnswerBase,
  AnswerFeedback as AnswerFeedbackBase,
  ClientTodo as ClientTodoBase,
  ClientTodoType,
  TodoMatcherMetadata as TodoMatcherMetadatasBase,
} from '../../../api-interfaces/src/index';

const matcherId = 'client-taxid-identification' as const;

export class TodoMatcherMetadata implements TodoMatcherMetadatasBase {
  matcherId = matcherId;
  input: undefined;
}

export enum TodoError {
  BAD_FILE_TYPE = 'bad_file_type',
  NANONETS_ERROR = 'nanonets_error',
}

export function isTodo(todo: unknown): todo is ClientTodo {
  return (
    (todo as Partial<ClientTodo> | null)?.todoMatcherMetadata?.matcherId ===
    matcherId
  );
}

export function isAnswer(todo: unknown, answer: unknown): answer is Answer {
  const p = (answer as Answer | null)?.payload || null;
  const validPayload = () =>
    [p?.frontDocumentRef].some(
      (e) => e == null || (e && typeof e === 'string')
    );
  return isTodo(todo) && validPayload();
}

export function isFeedback(
  todo: unknown,
  feedback: unknown
): feedback is AnswerFeedback {
  const data = (feedback as AnswerFeedback | null)?.data || null;
  return (
    isTodo(todo) &&
    (data == null ||
      (typeof data.valid === 'boolean' &&
        (data.taxid == null || typeof data.taxid === 'string')))
  );
}

export interface FeedbackData {
  errorId: TodoError | null;
  taxid: string | null;
  valid: boolean;
}

export type AnswerFeedback = AnswerFeedbackBase & {
  data: FeedbackData | null;
};

export interface AnswerPayload {
  frontDocumentRef?: string | null;
  frontDocumentUploadRef?: string | null;
}

export type Answer = AnswerBase & {
  payload: AnswerPayload;
};

export type ClientTodo = ClientTodoBase & {
  type: ClientTodoType.ClientTaxId;
  todoMatcherMetadata: TodoMatcherMetadata;
};
