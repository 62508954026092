<div id="kt_demo_panel" class="offcanvas offcanvas-right p-10">
  <!--begin::Header-->
  <div
    class="offcanvas-header d-flex align-items-center justify-content-between pb-7"
  >
    <h4 class="font-weight-bold m-0">Select A Demo</h4>
    <a
      href="#"
      class="btn btn-xs btn-icon btn-light btn-hover-primary"
      id="kt_demo_panel_close"
    >
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>
  <!--end::Header-->
  <!--begin::Content-->
  <div class="offcanvas-content">
    <!--begin::Wrapper-->
    <div class="offcanvas-wrapper mb-5 scroll-pull">
      <h5 class="font-weight-bold mb-4 text-center">Demo 1</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo1.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="https://keenthemes.com/metronic/preview/demo1/index.html"
            class="btn font-weight-bold btn-primary btn-shadow"
            target="_blank"
            >Default</a
          >
          <a
            href="https://keenthemes.com/metronic/preview/demo1/rtl/index.html"
            class="btn btn-light btn-shadow"
            target="_blank"
            >RTL Version</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 2</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo2.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="https://keenthemes.com/metronic/preview/demo2/index.html"
            class="btn font-weight-bold btn-primary btn-shadow"
            target="_blank"
            >Default</a
          >
          <a
            href="https://keenthemes.com/metronic/preview/demo2/rtl/index.html"
            class="btn btn-light btn-shadow"
            target="_blank"
            >RTL Version</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 3</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo3.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="https://keenthemes.com/metronic/preview/demo3/index.html"
            class="btn font-weight-bold btn-primary btn-shadow"
            target="_blank"
            >Default</a
          >
          <a
            href="https://keenthemes.com/metronic/preview/demo3/rtl/index.html"
            class="btn btn-light btn-shadow"
            target="_blank"
            >RTL Version</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 4</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo4.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="https://keenthemes.com/metronic/preview/demo4/index.html"
            class="btn font-weight-bold btn-primary btn-shadow"
            target="_blank"
            >Default</a
          >
          <a
            href="https://keenthemes.com/metronic/preview/demo4/rtl/index.html"
            class="btn btn-light btn-shadow"
            target="_blank"
            >RTL Version</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 5</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo5.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="https://keenthemes.com/metronic/preview/demo5/index.html"
            class="btn font-weight-bold btn-primary btn-shadow"
            target="_blank"
            >Default</a
          >
          <a
            href="https://keenthemes.com/metronic/preview/demo5/rtl/index.html"
            class="btn btn-light btn-shadow"
            target="_blank"
            >RTL Version</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 6</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo offcanvas-demo-active">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo6.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="https://keenthemes.com/metronic/preview/demo6/index.html"
            class="btn font-weight-bold btn-primary btn-shadow"
            target="_blank"
            >Default</a
          >
          <a
            href="https://keenthemes.com/metronic/preview/demo6/rtl/index.html"
            class="btn btn-light btn-shadow"
            target="_blank"
            >RTL Version</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 7</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo7.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="https://keenthemes.com/metronic/preview/demo7/index.html"
            class="btn font-weight-bold btn-primary btn-shadow"
            target="_blank"
            >Default</a
          >
          <a
            href="https://keenthemes.com/metronic/preview/demo7/rtl/index.html"
            class="btn btn-light btn-shadow"
            target="_blank"
            >RTL Version</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 8</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo8.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="https://keenthemes.com/metronic/preview/demo8/index.html"
            class="btn font-weight-bold btn-primary btn-shadow"
            target="_blank"
            >Default</a
          >
          <a
            href="https://keenthemes.com/metronic/preview/demo8/rtl/index.html"
            class="btn btn-light btn-shadow"
            target="_blank"
            >RTL Version</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 9</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo9.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="https://keenthemes.com/metronic/preview/demo9/index.html"
            class="btn font-weight-bold btn-primary btn-shadow"
            target="_blank"
            >Default</a
          >
          <a
            href="https://keenthemes.com/metronic/preview/demo9/rtl/index.html"
            class="btn btn-light btn-shadow"
            target="_blank"
            >RTL Version</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 10</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo10.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="https://keenthemes.com/metronic/preview/demo10/index.html"
            class="btn font-weight-bold btn-primary btn-shadow"
            target="_blank"
            >Default</a
          >
          <a
            href="https://keenthemes.com/metronic/preview/demo10/rtl/index.html"
            class="btn btn-light btn-shadow"
            target="_blank"
            >RTL Version</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 11</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo11.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 12</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo12.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 13</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo13.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 14</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo14.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 15</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo15.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 16</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo16.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 17</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo17.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 18</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo18.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 19</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo19.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 20</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo20.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 21</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo21.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 22</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo22.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 23</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo23.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 24</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img
            src="assets//../../../../../../doc/assets/media/demos/demo2.jpg"
            alt=""
            class="w-100"
          />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 25</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo25.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 26</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo26.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 27</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo27.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 28</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo28.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 29</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo29.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
      <h5 class="font-weight-bold mb-4 text-center">Demo 30</h5>
      <div class="overlay rounded-lg mb-8 offcanvas-demo">
        <div class="overlay-wrapper rounded-lg">
          <img src="/assets/media/demos/demo30.jpg" alt="" class="w-100" />
        </div>
        <div class="overlay-layer">
          <a
            href="#"
            class="btn font-weight-bold btn-primary btn-shadow disabled"
            >Coming soon</a
          >
        </div>
      </div>
    </div>
    <!--end::Wrapper-->
    <!--begin::Purchase-->
    <div class="offcanvas-footer">
      <a
        href="https://1.envato.market/EA4JP"
        target="_blank"
        class="btn btn-block btn-danger btn-shadow font-weight-bolder text-uppercase"
        >Buy Metronic Now!</a
      >
    </div>
    <!--end::Purchase-->
  </div>
  <!--end::Content-->
</div>
