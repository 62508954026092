/* eslint-disable */

export interface VaSt_FSA {
  VaSt_FSA: VaSt_FSA_CType;
}

export interface VaSt_FSA_CType {
  /**
   * Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung
   */
  Eingangsdatum?: string;

  Meldejahr?: string;
  Betrag?: string;
  BetroffenePerson?: BetroffenePersonM2062374656_CType;
  Institut?: InstitutM2062374656_CType;
  version: string;
}

export interface BetroffenePersonM2062374656_CType {
  /**
   * Identifikationsnummer
   */
  IdNr?: string;
  /**
   * Name
   */
  Name?: string;
  /**
   * Vorname
   */
  Vorname?: string;
  /**
   * Name
   */
  Blockname?: string;
  /**
   * Namensvorsatz
   */
  NamensVorsatz?: string;
  /**
   * Namenszusatz
   */
  NamensZusatz?: string;
  /**
   * Titel
   */
  Titel?: string;
  Ehegatte?: EhegatteM1219379809_CType;
}

export interface EhegatteM1219379809_CType {
  /**
   * Identifikationsnummer
   */
  IdNr?: string;
  /**
   * Name
   */
  Name?: string;
  /**
   * Vorname
   */
  Vorname?: string;
  /**
   * Name
   */
  Blockname?: string;
  /**
   * Namensvorsatz
   */
  NamensVorsatz?: string;
  /**
   * Namenszusatz
   */
  NamensZusatz?: string;
  /**
   * Titel
   */
  Titel?: string;
}

export interface InstitutM2062374656_CType {
  /**
   * BLZ
   */
  BLZ?: string;
  /**
   * BIC
   */
  BIC?: string;
  /**
   * Name
   */
  Name?: string;
}
