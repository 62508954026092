<div class="container">
  <div class="header-line">
    <mat-form-field appearance="fill">
      <mat-label>Filter by Category</mat-label>
      <mat-select [(value)]="currentCategory">
        <mat-option
          *ngFor="let cat of getCategories().concat(['NONE'])"
          [value]="cat"
        >
          {{ cat }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox [(ngModel)]="showOnlySelected">
      Show only selected
    </mat-checkbox>
  </div>

  <div>
    <mat-form-field>
      <mat-label>Search</mat-label>
      <input matInput [(ngModel)]="prefix" type="text" />
    </mat-form-field>
  </div>
  <div>
    <mat-chip-list class="chip-container" multiple>
      <mat-chip
        *ngFor="
          let tag of getTagsBySelection(currentCategory, showOnlySelected)
        "
        color="accent"
        [selected]="isTagSelected(tag)"
        (click)="tagSelected(tag)"
        >{{ tag }}</mat-chip
      >
    </mat-chip-list>
  </div>
</div>
