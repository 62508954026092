<div
  *ngIf="
    AisDocumentsHelperService.clusterHitsByDocumenttype(hits) as clusteredHits
  "
>
  <div
    class="tab-content"
    *ngFor="
      let clusteredHitsKV of clusteredHits | keyvalue;
      trackBy: AisDocumentsHelperService.trackByKey
    "
  >
    <h3 class="mt-5">
      {{ AisDocumentsHelperService.mapClusterToLabel(clusteredHitsKV.key) }}
    </h3>
    <div class="row flex-row flex-nowrap row-cols-md-6 g-4 horizontal-scroll">
      <div
        *ngFor="
          let hit of clusteredHitsKV.value;
          trackBy: AisDocumentsHelperService.trackByObjectID
        "
        class="col"
      >
        <app-ais-documents-icon-list-item
          [hit]="hit"
        ></app-ais-documents-icon-list-item>
      </div>
    </div>
    <hr />
  </div>
</div>
