import { Component, Input } from '@angular/core';
import { TaxcaseComment } from '@expresssteuer/models';

@Component({
  selector: 'esui-taxcase-comment',
  templateUrl: './taxcase-comment.component.html',
  styleUrls: ['./taxcase-comment.component.scss'],
})
export class TaxcaseCommentComponent {
  @Input()
  taxcaseComment?: TaxcaseComment | null;

  get updatedAt() {
    // TODO change to push strategy for performance improvements
    return this.taxcaseComment?.taskmetadata.updatedAt?.toDate();
  }
}
