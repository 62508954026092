import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { EsStoreStateIndicatorModule } from '../es-store-state-indicator/es-store-state-indicator.module';
import { EsuiTaxcaseOptimizationComponent } from './esui-taxcase-optimization.component';

@NgModule({
  exports: [EsuiTaxcaseOptimizationComponent],
  declarations: [EsuiTaxcaseOptimizationComponent],
  imports: [
    CommonModule,
    MatCardModule,
    EsStoreStateIndicatorModule,
    MatButtonModule,
  ],
})
export class EsuiTaxcaseOptimizationModule {}
