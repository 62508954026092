<!--begin::Header-->
<div id="kt_header" class="header header-fixed">
  <!--begin::Header Wrapper-->
  <div
    class="header-wrapper rounded-top-xl d-flex flex-grow-1 align-items-center"
  >
    <!--begin::Container-->
    <div
      class="container-fluid d-flex align-items-center justify-content-end justify-content-lg-between flex-wrap"
    >
      <!--begin::Menu Wrapper-->

      <app-clientnavigation></app-clientnavigation>

      <!--end::Menu Wrapper-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center py-3">
        <!--begin::Dropdown-->
        <div
          class="dropdown dropdown-inline"
          data-toggle="tooltip"
          title="Quick actions"
          data-placement="left"
        >
          <a
            (click)="createCustomer()"
            class="btn btn-dark font-weight-bold px-6"
            >Kunden erstellen</a
          >
        </div>
        <!--end::Dropdown-->
      </div>
      <!--end::Toolbar-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Header Wrapper-->
</div>
<!--end::Header-->

<!--begin::Content-->

<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container-fluid">
      <app-clientlist></app-clientlist>
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
