import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DISABILITY } from '@expresssteuer/models';

@Component({
  selector: 'app-control-disability',
  templateUrl: './control-disability.component.html',
  styleUrls: ['./control-disability.component.scss'],
})
export class ControlDisabilityComponent implements OnInit {
  DISABILITY: DISABILITY;

  constructor() {}

  disabilityValue: string;

  @Output()
  disabilityChange = new EventEmitter<string>();

  @Input()
  get disability(): string {
    return this.disabilityValue;
  }

  set disability(val: string) {
    this.disabilityValue = val;
    this.disabilityChange.emit(this.disabilityValue);
  }

  ngOnInit(): void {}
}
