<span *ngIf="taxcase && taxcase.metadata && taxcase.metadata.assigned">
  <div class="d-flex align-items-center">
    <!-- <div class="symbol symbol-40 symbol-sm flex-shrink-0">									
        <img id="userPhoto" [src]="taxcase.metadata.assigned.photoUrl" *ngIf="taxcase.metadata.assigned.photoUrl && taxcase.metadata.assigned.photoUrl.length > 5">
        <span class="symbol-label font-size-h4 font-weight-bold"></span>							
    </div>								 -->
    <div class="ml-4" *ngIf="taxcase.metadata">
      <div
        class="text-dark-75 font-weight-bolder font-size-lg mb-0"
        *ngIf="taxcase.metadata.assigned.displayName"
      >
        {{ taxcase.metadata.assigned.displayName }}
      </div>
      <a
        class="text-muted font-weight-bold text-hover-primary"
        *ngIf="taxcase.metadata.changed"
        >{{ taxcase.metadata.changed.toDate() | timeago }}</a
      >
    </div>
  </div>
</span>
