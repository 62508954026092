<esui-section-column-scaffold
  [header]="{
    title: 'documents'
  }"
>
  <ng-container content>
    <mat-selection-list
      [multiple]="false"
      [(ngModel)]="ngModel"
      (ngModelChange)="ngModelChange.next($event)"
    >
      <ng-container *ngFor="let group of sortedDocuments$ | async | keyvalue">
        <div mat-subheader>{{ group.key }}</div>
        <ng-container *ngFor="let document of group.value; trackBy: trackById">
          <mat-list-option [value]="document" @animateDestroy>
            <mat-icon
              mat-list-icon
              [matTooltip]="document.verified ? 'verified' : 'not verified'"
            >
              {{ document.verified ? 'verified' : 'pending' }}
            </mat-icon>
            <span matLine>
              <span class="tags">
                <span *ngFor="let tag of document.tags">{{ tag }}</span>
              </span>
            </span>
            <span matLine>{{ document.name }}</span>
            <!-- <pre matLine>{{ document.id }}</pre> -->
            <pre matLine>{{ document.uploaded | asDate | date }}</pre>
          </mat-list-option>
        </ng-container>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-selection-list>
  </ng-container>
</esui-section-column-scaffold>
