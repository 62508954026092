import { Component, Inject, Input } from '@angular/core';
import {
  HREF_BUILDER,
  HrefBuilder,
} from '../href-builder/href-builder.provider';

/**
 * @requires an provided `HREF_BUILDER`
 */
@Component({
  selector: 'esui-anchor-menu',
  templateUrl: './anchor-menu.component.html',
  styleUrls: ['./anchor-menu.component.scss'],
})
export class AnchorMenuComponent {
  #clientId?: string | null;
  @Input()
  set clientId(input: string | undefined | null) {
    this.inputChanged();
    this.#clientId = input;
  }
  get clientId(): string | undefined | null {
    return this.#clientId;
  }

  #taxcaseIds?: string[] | null;
  @Input()
  set taxcaseIds(input: string[] | undefined | null) {
    this.inputChanged();
    this.#taxcaseIds = input;
  }
  get taxcaseIds(): string[] | undefined | null {
    return this.#taxcaseIds;
  }

  #pathToTask?: string | null;
  @Input()
  set pathToTask(input: string | undefined | null) {
    this.inputChanged();
    this.#pathToTask = input;
  }
  get pathToTask(): string | undefined | null {
    return this.#pathToTask;
  }

  get clientInCrmUrl(): string | null | undefined | null {
    return this.builder.crm?.client?.(this.clientId ?? undefined);
  }
  get clientInTaskIslandTasksUrl(): string | null | undefined | null {
    return this.builder.taskIsland?.classic?.client?.(
      this.clientId ?? undefined
    );
  }
  get clientInTaskIslandCrmUrl(): string | null | undefined {
    return this.builder.taskIsland?.crm?.(this.clientId ?? undefined);
  }
  get taxcaseInCrmUrls(): { year: string; url: string }[] | null | undefined {
    if (!this.clientId) {
      return undefined;
    }
    if (!this.taxcaseIds) {
      return undefined;
    }
    if (!this.builder.crm?.taxcase) {
      return undefined;
    }

    return this.taxcaseIds
      .map((taxcaseId) => {
        const url = this.builder.crm?.taxcase?.(
          this.clientId ?? undefined,
          taxcaseId
        );
        if (!url) {
          return null;
        }
        return { year: taxcaseId.match(/[0-9]+/), url };
      })
      .filter((e): e is any => !!e);
  }

  get taxcaseInTaskIslandTasksUrls():
    | { year: string; url: string }[]
    | null
    | undefined {
    return this.taxcaseIds
      ?.map((taxcaseId) => {
        const url = this.builder.taskIsland?.classic?.taxcase?.(taxcaseId);
        if (!url) {
          return null;
        }
        return { year: taxcaseId.match(/[0-9]+/), url };
      })
      ?.filter((e): e is any => !!e);
  }

  get permaLinkToTask() {
    return this.builder.taskIsland?.classic?.task?.(
      this.pathToTask ?? undefined
    );
  }

  private inputChanged() {
    // TODO use push change starategy for performance improvements here
  }

  trackByUrl(i: number, { url }: { url: string }) {
    return `${i}${url}`;
  }

  constructor(@Inject(HREF_BUILDER) private builder: HrefBuilder) {}
}
