import { Component, Input } from '@angular/core';
import {
  AbstractValueAccessor,
  makeProvider,
} from '../abstract-value-accessor';

@Component({
  selector: 'esui-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [...makeProvider(NumberInputComponent)],
})
export class NumberInputComponent extends AbstractValueAccessor<number> {
  @Input()
  enableArrowKeyInput = false;

  //due to consistency issues this is always disabled
  enableScrollInput = false;

  protected data = 0;

  /**
   * @override
   */
  get empty(): boolean {
    return !this.value && this.value !== 0;
  }

  /**
   * @override
   */
  controlType = 'NumberInputComponent';

  /**
   * @override
   */
  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elementRef.nativeElement.querySelector('input')?.focus();
    }
  }
}
