<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  aria-label="open in..."
  matTooltip="open in..."
>
  <mat-icon><span class="material-icons">open_in_browser</span></mat-icon>
</button>
<mat-menu #menu="matMenu">
  <a
    *ngFor="let taxcaseInCrmUrl of taxcaseInCrmUrls; trackBy: trackByUrl"
    mat-menu-item
    [href]="taxcaseInCrmUrl.url"
    target="_blank"
    [disabled]="!taxcaseInCrmUrl.url"
  >
    <mat-icon>open_in_new</mat-icon>
    <mat-icon>fact_check</mat-icon>
    <span
      >open case <span class="taxcaseYear">{{ taxcaseInCrmUrl.year }}</span> in
      CRM</span
    >
  </a>
  <a
    *ngFor="
      let taxcaseInTaskIslandTasksUrl of taxcaseInTaskIslandTasksUrls;
      trackBy: trackByUrl
    "
    mat-menu-item
    [href]="taxcaseInTaskIslandTasksUrl.url"
    target="_blank"
    [disabled]="!taxcaseInTaskIslandTasksUrl.url"
  >
    <mat-icon>open_in_new</mat-icon>
    <mat-icon>fact_check</mat-icon>
    <span
      >open case
      <span class="taxcaseYear">{{ taxcaseInTaskIslandTasksUrl.year }}</span> in
      Task-Island</span
    >
  </a>
  <a
    mat-menu-item
    [href]="clientInCrmUrl"
    target="_blank"
    [disabled]="!clientInCrmUrl"
  >
    <mat-icon>open_in_new</mat-icon>
    <mat-icon>account_box</mat-icon>
    <span>open client in CRM</span>
  </a>
  <a
    mat-menu-item
    [href]="clientInTaskIslandTasksUrl"
    target="_blank"
    [disabled]="!clientInTaskIslandTasksUrl"
  >
    <mat-icon>open_in_new</mat-icon>
    <mat-icon>account_box</mat-icon>
    <span>open client in Task-Island Tasks</span>
  </a>
  <a
    mat-menu-item
    [href]="clientInTaskIslandCrmUrl"
    target="_blank"
    [disabled]="!clientInTaskIslandCrmUrl"
  >
    <mat-icon>open_in_new</mat-icon>
    <mat-icon>account_box</mat-icon>
    <span>open client in crm alpha</span>
  </a>
  <button
    *ngIf="permaLinkToTask"
    mat-menu-item
    target="_blank"
    [disabled]="!permaLinkToTask"
    [cdkCopyToClipboard]="permaLinkToTask"
  >
    <mat-icon>copy_all</mat-icon>
    <mat-icon>task</mat-icon>
    <span>copy perma link to task</span>
  </button>
</mat-menu>
