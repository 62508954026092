import { Client } from '../client/client';
import { TaxId } from '../client/taxId';
import { TaxSettings, TaxSettingsForTaxcase } from '../client/taxsettings';
import { BinaryDocument } from '../document/document';
import { Timestamp } from '../helpers/timestamp';
import { TaxAdvisor } from '../taxadvisor/taxadvisor';
import { TaxCase } from '../taxcase/taxcase';
import { ElsterResponse } from './elsterBase';

export enum ENDPOINT {
  /**
   * Send Productive to TaxOffice
   */
  PROD = 'prod',
  /**
   * Generate a testcase
   */
  TEST = 'test',
  /**
   * prints a draft document without sending to elster
   */
  PRINT = 'print',
}

export class Elster {
  public static getTemplate(): Elster {
    return {
      id: '',
      npmModelVersion: '',
      endpoint: ENDPOINT.TEST,
      client: null,
      taxId: null,
      taxsettings: [],
      plausibility: null,
      taxcase: null,
      created: null,
      elsterResponse: null,
      document: null,
      withAccountant: null,
      taxsettingsForTaxcase: null,
      taxadvisor: null,
    };
  }

  /**
   * @deprecated
   */
  public id?: string = '';
  /**
   * Version Nbr of npm version which has updated last this record
   * @deprecated
   */
  public npmModelVersion?: string;
  /**
   * Endpoint to send to TEST, generate test document. PROD = Send live to TaxOffice
   */
  public endpoint: ENDPOINT = ENDPOINT.TEST;
  /**
   * The Taxcase to generate the Elsterfile from
   */
  public taxcase: TaxCase | null = null;
  /**
   * When the request to generate has been created
   */
  public created: Timestamp | null = null;
  /**
   * The response from the service is stored here
   */
  public elsterResponse: ElsterResponse | null = null;
  /**
   * The generated binary document from the service
   */
  public document: BinaryDocument | null = null;

  public plausibility: ElsterPlausibility | null = null;

  public client: Client | null = null;
  /**
   * @deprecated use `this.taxsettingsForTaxcase`
   */
  public taxId?: TaxId | null = null;
  /**
   * @deprecated use `this.taxsettingsForTaxcase`
   */
  public taxsettings?: TaxSettings[] | null = [];
  public taxsettingsForTaxcase?: TaxSettingsForTaxcase | null;
  public taxadvisor?: TaxAdvisor | null;
  public withAccountant: boolean | null = null;
}

export interface ElsterPlausibility {
  state: ElsterPlausibilityState;
  stats?: {
    passed: number;
    failed: number;
  };
  log?: { errors: string[]; status: boolean; message: string }[];
}

export enum ElsterPlausibilityState {
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  UNKNOWN = 'UNKNOWN',
}
