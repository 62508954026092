import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EsuiDeductionTableModule } from '../esui-deduction-table/esui-deduction-table.module';
import { EsuiSkeletonPlaceholderModule } from '../esui-skeleton-placeholder/esui-skeleton-placeholder.module';
import { DateInputModule } from '../form-inputs/date-input/date-input.module';
import { PureMethodModule } from '../pipes/pure-method/pure-method.module';
import { EsuiTaxNoticeComponent } from './esui-tax-notice.component';

@NgModule({
  declarations: [EsuiTaxNoticeComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    EsuiDeductionTableModule,
    MatTooltipModule,
    DateInputModule,
    EsuiSkeletonPlaceholderModule,
    MatDividerModule,
    PureMethodModule,
  ],
  exports: [EsuiTaxNoticeComponent],
})
export class EsuiTaxNoticeModule {}
