<ng-container *ngIf="true">
  <mat-card>
    <span esuiCardKey>Taxnotice Inform BackPayer</span>
    <mat-card-header>
      <mat-card-title> </mat-card-title>
      <mat-card-subtitle> </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="focus" *ngIf="true">
        <div class="documentViewer">
          <esui-skeleton-placeholder
            [skeletonBuildInput]="[]"
            [watchResult]="!!taxNotice && !!taxnoticeDocument"
            [bigBoxMode]="true"
          >
            <ng-container *ngIf="taxnoticeDocument">
              <esui-document-viewer
                [contentType]="taxnoticeDocument.contentType"
                [downloadUrl]="taxnoticeDocument.downloadUrl"
                [isLoading]="taxnoticeDocumentStoreStateIndicator?.isSaving"
                [hasErrors]="taxnoticeDocumentStoreStateIndicator?.hasErrors"
                [showNavigation]="true"
                [showDownloadButton]="true"
              ></esui-document-viewer>
            </ng-container>
          </esui-skeleton-placeholder>
        </div>

        <div class="metaViewer">
          <esui-skeleton-placeholder
            [skeletonBuildInput]="[
              [0, 1, 1, 2],
              [0, 3],
              [0, 2, 2, 1]
            ]"
            [watchResult]="!!taxNotice && !!taxnoticeDocument"
            [bigBoxMode]="false"
          >
            <h3>Here will be a email fomular later</h3>
          </esui-skeleton-placeholder>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="clientInformed.next()"
        [disabled]=""
      >
        Informed Customer
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>
