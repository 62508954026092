import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User } from '@expresssteuer/models';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  public users$: Observable<User[]> = this.db
    .collection<User>('users', (ref) =>
      ref.where('enabled', '==', true).orderBy('displayName')
    )
    .valueChanges();

  constructor(private db: AngularFirestore) {}

  public async getUserById(id: string): Promise<User | null> {
    const userlist = await firstValueFrom(this.users$);
    return userlist.find((user) => user.id === id) ?? null;
  }
}
