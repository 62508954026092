<app-header-mobile></app-header-mobile>

<div class="d-flex flex-column flex-root">
  <!-- TODO: Replace page with an router outlet or inheritance-->
  <!--begin::Page-->
  <div class="d-flex flex-row flex-column-fluid page">
    <!--begin::Aside-->
    <app-aside></app-aside>
    <!--end::Aside-->
    <!--begin::Wrapper-->
    <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
      <!--begin::Header-->

      <router-outlet></router-outlet>

      <!--end::Content-->
      <!--begin::Footer-->
      <app-footer></app-footer>
      <!--end::Footer-->
    </div>
    <!--end::Wrapper-->
    <!--begin::Aside Secondary-->
    <app-asideright
      id="asideright"
      [ngClass]="{ 'd-none': !showAsideRight }"
    ></app-asideright>
    <!-- TODO: ASIDERIGHT> <app-asideright></app-asideright> -->
    <!--end::Aside Secondary-->
  </div>
  <!--end::Page-->
</div>
<!--end::Main-->
<!-- begin::Notifications Panel-->
<!-- TODO: NOTIFICATIONS PANEL>  -->
<!-- end::Notifications Panel-->
<!--begin::Quick Actions Panel-->
<!-- TODO: QUICKACTIONS PANEL>  -->
<!--end::Quick Actions Panel-->
<!-- begin::User Panel-->
<app-userpanel
  id="kt_quick_user"
  class="offcanvas offcanvas-left p-10"
></app-userpanel>
<!-- TODO: USER PANEL>  -->
<!-- end::User Panel-->
<!--begin::Quick Panel-->
<!-- TODO: QUICK PANEL>  -->
<!--end::Quick Panel-->
<!--begin::Chat Panel-->
<!-- TODO: CHAT PANEL>  -->
<app-chatpanel></app-chatpanel>
<!--end::Chat Panel-->

<!--begin::Scrolltop-->
<div id="kt_scrolltop" class="scrolltop">
  <span class="svg-icon">
    <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Up-2.svg-->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24" />
        <rect
          fill="#000000"
          opacity="0.3"
          x="11"
          y="10"
          width="2"
          height="10"
          rx="1"
        />
        <path
          d="M6.70710678,12.7071068 C6.31658249,13.0976311 5.68341751,13.0976311 5.29289322,12.7071068 C4.90236893,12.3165825 4.90236893,11.6834175 5.29289322,11.2928932 L11.2928932,5.29289322 C11.6714722,4.91431428 12.2810586,4.90106866 12.6757246,5.26284586 L18.6757246,10.7628459 C19.0828436,11.1360383 19.1103465,11.7686056 18.7371541,12.1757246 C18.3639617,12.5828436 17.7313944,12.6103465 17.3242754,12.2371541 L12.0300757,7.38413782 L6.70710678,12.7071068 Z"
          fill="#000000"
          fill-rule="nonzero"
        />
      </g>
    </svg>
    <!--end::Svg Icon-->
  </span>
</div>
<!--end::Scrolltop-->
<!--begin::Sticky Toolbar-->

<!-- TODO: STICKYTOOLBAR-->
<ul class="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
  <!--begin::Item-->
  <li
    class="nav-item mb-2"
    id="showRightAside"
    data-toggle="tooltip"
    title=""
    data-placement="right"
    data-original-title="Suche und Todos Ein/Ausblenden"
  >
    <a
      class="btn btn-sm btn-icon btn-bg-light btn-text-success btn-hover-success"
      (click)="switchAsideRight()"
    >
      <i
        class="flaticon2-left-arrow"
        [ngClass]="{
          'flaticon2-left-arrow': showAsideRight,
          'flaticon2-right-arrow': !showAsideRight
        }"
      ></i>
    </a>
  </li>
  <!--end::Item-->
</ul>

<!--end::Sticky Toolbar-->
<!--begin::Demo Panel-->

<!-- modal-sticky-bottom-right -->
<app-message-composer
  class="modal modal-sticky modal-sticky-lg modal-sticky-bottom-right"
  id="kt_inbox_compose"
  data-height="150"
  role="dialog"
  data-backdrop="false"
></app-message-composer>
<!--
<app-message-viewer
  class="modal modal-sticky modal-sticky-lg"
  id="messageviewer"
  data-height="150"
  role="dialog"
  data-backdrop="false"
></app-message-viewer> -->
<app-note-modal-editor
  class="modal modal-sticky modal-sticky-lg"
  id="noteadd_editor_add"
  data-height="150"
  role="dialog"
  data-backdrop="false"
></app-note-modal-editor>

<app-document-preview></app-document-preview>
