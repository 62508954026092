import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageReplySuggestion } from '@expresssteuer/models';

@Component({
  selector: 'esui-message-suggestion',
  templateUrl: './message-suggestion.component.html',
  styleUrls: ['./message-suggestion.component.scss'],
})
export class MessageSuggestionComponent {
  @Input() suggestion?: MessageReplySuggestion | null;
  @Output() suggestionSelected = new EventEmitter<MessageReplySuggestion>();
}
