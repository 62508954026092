import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNotNil',
})
export class IsNotNilPipe implements PipeTransform {
  transform<T>(value?: T | null): value is T {
    return value !== null && value !== undefined;
  }
}
