<ng-container *ngIf="sender">
  <div
    class="d-flex align-items-center justify-content-between mb-5 p-3 col-message {{
      selectedMessage &&
      sender.lastUnreadMessage.id === selectedMessage.lastUnreadMessage.id
        ? 'active'
        : ''
    }}"
  >
    <div class="d-flex align-items-center">
      <div class="flex-shrink-0 mr-7">
        <!-- Initials -->
        <div class="symbol symbol-50 symbol-lg-40 symbol-light-primary">
          <span
            class="font-size-h3 symbol-label font-weight-boldest text-center span-from"
            *ngIf="!sender.from.customerId"
          >
            {{ sender.lastUnreadMessage.type.substr(0, 2).toUpperCase() }}
          </span>
          <span
            class="font-size-h3 symbol-label font-weight-boldest text-center span-from"
            *ngIf="sender.from.customerId"
          >
            {{ sender.from.displayName.substr(0, 2).toUpperCase() }}
          </span>
        </div>
      </div>
      <div class="d-flex flex-column">
        <!-- name -->
        <span
          class="text-dark-75 font-weight-bold font-size-lg col-name col-senderAddress"
          *ngIf="!sender.from.customerId"
        >
          {{ sender.from.senderAddress }}
        </span>

        <span
          class="text-dark-75 font-weight-bold font-size-lg col-name col-displayName"
          *ngIf="sender.from.customerId"
        >
          {{ sender.from.displayName }}
        </span>

        <span class="text-muted font-weight-bold font-size-sm text-capitalize">
          {{ sender.keyAccount?.displayName }}
          <small>{{ sender.lastUnreadMessage.message.substr(0, 55) }}</small>
        </span>
      </div>
    </div>
    <div class="d-flex flex-column align-items-end">
      <span class="text-muted font-weight-bold font-size-sm text-right">
        {{ sender.lastUnreadMessage?.recieved?.toDate() | date : 'dd.MM.yyyy' }}
      </span>
      <span class="text-muted font-weight-bold font-size-sm text-right">
        {{ sender.lastUnreadMessage?.recieved?.toDate() | date : 'HH:mm' }}
      </span>
    </div>
  </div>
</ng-container>
