import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { BankTransactionLine } from '@expresssteuer/banking-models';
import { Subscription } from 'rxjs';
import { StoreStateIndicatorModel } from '../es-store-state-indicator/store-state-indicator.interface';

@Component({
  selector: 'esui-transactionlines',
  templateUrl: './transactionlines.component.html',
  styleUrls: ['./transactionlines.component.scss'],
})
export class TransactionlinesComponent implements OnDestroy {
  columnsToDisplay = [
    'Date',
    'InOutBound',
    'Amount',
    'Currency',
    'transactionText',
    'FitTaxNotice',
  ];
  @Input() showHeadline = false;

  private changeSubscription?: Subscription | null;
  selection = new SelectionModel<TransactionLineById>(true, []);

  unknownSelections: TransactionLineById[] = [];
  transactionLineByIds: TransactionLineById[] = [];
  @Input()
  set transactionLines(btls: BankTransactionLine[] | undefined | null) {
    console.log('setting transactionLines', btls);
    this.transactionLineByIds = (btls ?? []).map((btl) => ({
      id: this.getUid(btl),
      btl,
    }));
    this.setSelections();
  }
  @Input() transactionLinesState?: Partial<StoreStateIndicatorModel> | null;

  enteredSelectedTransactionLines: BankTransactionLine[] = [];
  @Input() set selectedTransactionLines(
    btls: BankTransactionLine[] | undefined | null
  ) {
    console.log('setting selectedTransactionLines', btls);
    this.enteredSelectedTransactionLines = btls ? [...btls] : [];
    this.setSelections();
  }
  @Output() selectedTransactionLinesChange = new EventEmitter<
    BankTransactionLine[]
  >();

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.transactionLineByIds.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.transactionLineByIds);
  }

  /**
   * Match the selections to the provided/displayed transaction lines and update
   * `this.selection` as well as `this.unknownSelections` with the result.
   */
  private setSelections() {
    const selectedIds = this.enteredSelectedTransactionLines.map((btl) => ({
      id: this.getUid(btl),
      btl,
    }));

    const selectedTransactionLinesById = [
      ...this.transactionLineByIds.filter(({ id }) =>
        selectedIds.some((e) => e.id === id)
      ),
    ];

    this.changeSubscription?.unsubscribe();

    this.selection.clear();
    this.selection.select(...selectedTransactionLinesById);

    this.changeSubscription = this.selection.changed.subscribe(() => {
      const currentSelection = this.selection.selected.map((e) => e.btl);
      this.selectedTransactionLinesChange.next(currentSelection);
    });

    // if someone provided a selection that is not part of the provided transactions, lets keep track of them:
    this.unknownSelections = selectedIds.filter(
      ({ id }) => !this.transactionLineByIds.some((e) => e.id === id)
    );
  }

  /**
   * Get a unique identifier for an BankTransactionLine.
   */
  private getUid(btl: BankTransactionLine) {
    console.log(`UID : ${btl.id}_${btl.transaction_id}`);
    return `${btl.id}_${btl.transaction_id}`;
  }

  ngOnDestroy(): void {
    this.changeSubscription?.unsubscribe();
    this.changeSubscription = null;
  }
}

interface TransactionLineById {
  id: string;
  btl: BankTransactionLine;
}
