<mat-card>
  <span esuiCardKey>Fix finance office ID</span>

  <mat-card-header>
    <mat-card-title> </mat-card-title>
    <mat-card-subtitle>
      tax settings status:
      <esui-store-state-indicator
        [hasOnlineChangedAfterLocalChanged]="
          !!taxSettingsHasOnlineChangedAfterLocalChanged
        "
        [isSaving]="!!taxSettingsIsSaving"
        [isLoading]="!!taxSettingsIsLoading"
        [hasChanges]="!!taxSettingsHasChanges"
        [hasErrors]="!!taxSettingsHasErrors"
      ></esui-store-state-indicator>

      tax cases status:
      <esui-store-state-indicator
        [hasOnlineChangedAfterLocalChanged]="false"
        [isLoading]="!!taxcasesIsLoading"
        [hasChanges]="false"
        [hasErrors]="!!taxcasesHasErrors"
      ></esui-store-state-indicator>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngIf="taxcasesByTaxsettings as taxcasesByTaxsettings">
      <mat-accordion multi="true">
        <ng-container
          *ngFor="
            let taxcase of taxcasesByTaxsettings.forTaxsettings;
            trackBy: trackByCreatedDate
          "
        >
          <mat-expansion-panel
            [expanded]="
              taxcase.metadata.status.current === TAXCASESTATUS.INPROGRESS
            "
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ taxcase.year }} ({{ taxcase.id }})
              </mat-panel-title>
              <mat-panel-description>
                {{ taxcase.metadata.status.current }}
                <mat-icon>map</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div
              *ngIf="
                taxcase.taxform.current.taxForm.information.addressClient
                  .formatedAddress ===
                taxcase.taxform.current.taxForm.information.addressCurrent
                  .formatedAddress
              "
            >
              <ng-container
                *ngTemplateOutlet="
                  address;
                  context: {
                    address:
                      taxcase.taxform.current.taxForm.information.addressCurrent
                  }
                "
              ></ng-container>
            </div>
            <ng-container
              *ngIf="
                taxcase.taxform.current.taxForm.information.addressClient
                  .formatedAddress !==
                taxcase.taxform.current.taxForm.information.addressCurrent
                  .formatedAddress
              "
            >
              <div
                *ngFor="
                  let addressByType of [
                    {
                      label: 'in tax year',
                      address:
                        taxcase.taxform.current.taxForm.information
                          .addressClient
                    },
                    {
                      label: 'when submitted',
                      address:
                        taxcase.taxform.current.taxForm.information
                          .addressCurrent
                    }
                  ]
                "
              >
                {{ addressByType.label }}:
                <ng-container
                  *ngTemplateOutlet="address; context: addressByType"
                ></ng-container>
              </div>
            </ng-container>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </ng-container>
  </mat-card-content>

  <mat-card-actions>
    <mat-form-field appearance="fill">
      <mat-label>Finance department ID</mat-label>
      <input
        matInput
        placeholder="0000"
        min="0"
        max="9999"
        type="number"
        [ngModel]="taxSettings?.financeDepartmentId"
        (ngModelChange)="
          taxSettingsChange.next({ financeDepartmentId: $event })
        "
      />
    </mat-form-field>
    <button mat-raised-button (click)="taxSettingsSaveClick.next()">
      save
    </button>
    <button mat-button (click)="taxSettingsDiscardClick.next()">discard</button>
  </mat-card-actions>
</mat-card>

<ng-template #address let-address="address">
  <p>
    <esui-address-input
      [viewMode]="true"
      [ngModel]="address"
    ></esui-address-input>
    <button
      mat-icon-button
      (click)="copyToClipboard(address.street.longName)"
      matTooltip="copy street to clipboard"
      aria-label="copy street to clipboard"
    >
      <mat-icon>content_copy</mat-icon>
    </button>

    <a
      [href]="
        'https://www.bzst.de/DE/Service/Behoerdenwegweiser/Finanzamtsuche/GemFa/finanzamtsuche_Formular.html?pageLocale=de&suche=' +
        address.zipCode.longName
      "
      target="_blank"
      >(search BZSt)</a
    >
  </p>
</ng-template>
