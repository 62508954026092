import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DateStringInputComponent } from './date-string-input.component';

@NgModule({
  declarations: [DateStringInputComponent],
  imports: [CommonModule, MatInputModule, FormsModule],
  exports: [DateStringInputComponent],
})
export class DateStringInputModule {}
