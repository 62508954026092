import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  EsuiFinanceDepartmentIdValidatorModule,
  EsuiTaxIdValidatorModule,
} from '@expresssteuer/angular-validators';
import { EsBankModule } from '../es-bank/es-bank.module';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsStatsForNerdsModule } from '../es-stats-for-nerds/es-stats-for-nerds.module';
import { EsStoreStateIndicatorModule } from '../es-store-state-indicator/es-store-state-indicator.module';
import { EsuiSectionColumnScaffoldModule } from '../esui-section-column-scaffold/esui-section-column-scaffold.module';
import { AddressInputModule } from '../form-inputs/address-input/address-input.module';
import { DateInputModule } from '../form-inputs/date-input/date-input.module';
import { DateStringInputModule } from '../form-inputs/date-string-input/date-string-input.module';
import { TimestampModule } from '../pipes/timestamp/timestamp.module';
import { ClientCardComponent } from './components/client-card/client-card.component';
import { ClientEditorXComponent } from './components/client-editor-x/client-editor-x.component';
import { ClientEditorComponent } from './components/client-editor/client-editor.component';
import { ClientInlineComponent } from './components/client-inline/client-inline.component';

@NgModule({
  declarations: [
    ClientInlineComponent,
    ClientCardComponent,
    ClientEditorXComponent,
    ClientEditorComponent,
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    MatCardModule,
    MatIconModule,
    EsCardKeyModule,
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    DateInputModule,
    EsBankModule,
    MatToolbarModule,
    MatButtonModule,
    EsStatsForNerdsModule,
    TimestampModule,
    DateStringInputModule,
    MatFormFieldModule,
    AddressInputModule,
    MatTooltipModule,
    EsuiSectionColumnScaffoldModule,
    EsStoreStateIndicatorModule,
    EsuiTaxIdValidatorModule,
    EsuiFinanceDepartmentIdValidatorModule,
  ],
  exports: [
    ClientInlineComponent,
    ClientCardComponent,
    ClientEditorComponent,
    ClientEditorXComponent,
  ],
})
export class EsClientModule {}
