import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor() {}

  public warning(aTitle: string, aMessage: string, url?: string) {
    this.sendNotify(aTitle, aMessage, 'danger', url);
  }

  public info(aTitle: string, aMessage: string) {
    this.sendNotify(aTitle, aMessage, 'info');
  }

  public success(aTitle: string, aMessage: string) {
    this.sendNotify(aTitle, aMessage, 'success');
  }

  public taxCaseSuccessfullUpdated() {
    this.success(
      'Erfolgreich gespeichert',
      'Alle Daten zum Fall wurden gespeichert.'
    );
  }

  public clientSuccessfullUpdated() {
    this.success(
      'Erfolgreich gespeichert',
      'Alle Daten zum Kunden wurden gespeichert.'
    );
  }

  private sendNotify(
    aTitle: string,
    aMessage: string,
    aType: string,
    url: string = ''
  ) {
    $.notify(
      {
        title: aTitle,
        message: aMessage,
        url,
      },
      {
        type: aType,
        delay: 7000,
        timer: 1000,
      }
    );
  }

  // public static notify(
  //   aTitle: string,
  //   aMessage: string,
  //   icon: string = 'glyphicon glyphicon-warning-sign',
  //   aUrl: string = ''
  // ): void {
  //   $.notify(
  //     {
  //       // options
  //       icon: 'glyphicon glyphicon-warning-sign',
  //       title: aTitle,
  //       message: aMessage,
  //       url: aUrl,
  //       target: '_self',
  //     },
  //     {
  //       // settings
  //       element: 'body',
  //       position: null,
  //       type: 'info',
  //       allow_dismiss: true,
  //       newest_on_top: true,
  //       showProgressbar: false,
  //       placement: {
  //         from: 'top',
  //         align: 'right',
  //       },
  //       offset: 20,
  //       spacing: 10,
  //       z_index: 1031,
  //       delay: 5000,
  //       timer: 1000,
  //       url_target: '_blank',
  //       mouse_over: null,
  //       animate: {
  //         enter: 'animated fadeInDown',
  //         exit: 'animated fadeOutUp',
  //       },
  //       onShow: null,
  //       onShown: null,
  //       onClose: null,
  //       onClosed: null,
  //       icon_type: 'class',
  //       template:
  //         '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
  //         '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
  //         '<span data-notify="icon"></span> ' +
  //         '<span data-notify="title">{1}</span> ' +
  //         '<span data-notify="message">{2}</span>' +
  //         '<div class="progress" data-notify="progressbar">' +
  //         '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
  //         '</div>' +
  //         '<a href="{3}" target="{4}" data-notify="url"></a>' +
  //         '</div>',
  //     }
  //   );
  // }
}
