import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { Timestamp } from '@angular/fire/firestore';
import { ACTIVITYTYPE, Client, Task, TaxCase } from '@expresssteuer/models';
import { Observable, throwError } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AuthenticationService } from '../security/authentication.service';

declare var $: any;
export class Pagination {
  start: any;
  end: any;
  firstItem: any;
  first: boolean;
  total: number;
  currentPage: number;
  recordsPerPage: number;
  initialized: boolean;

  constructor() {
    this.currentPage = 1;
    this.recordsPerPage = 10;
    this.total = 8;
    this.initialized = false;
  }

  public nextPage() {
    this.initialized = false;

    if (this.firstItem === this.start) {
      this.first = true;
    } else {
      this.first = false;
    }
  }
  public prevPage() {
    this.initialized = false;
    this.start = this.end;

    if (this.firstItem === this.start) {
      this.first = true;
    } else {
      this.first = false;
    }
  }

  public firstPage() {
    this.initialized = false;
    this.start = null;
    this.first = true;
  }
}

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  public pagination: Pagination = new Pagination();

  constructor(
    private db: AngularFirestore,
    public security: AuthenticationService
  ) {}

  public load(
    includeDone: boolean = false,
    pagingStart: any | null = null
  ): Observable<Task[]> {
    const collection: AngularFirestoreCollection = this.db.collection<Task[]>(
      'todocenter',
      (ref) =>
        ref
          .where('metadata.status.current', 'in', ['entry', 'inProgress'])
          .where('metadata.assigned.authId', '==', this.security.user.authId)
          .orderBy('metadata.created', 'desc')
          .limit(this.pagination.recordsPerPage)
          .startAfter(pagingStart)
    );

    return collection.snapshotChanges().pipe(
      tap((results) => {
        console.log('page loaded');
        if (results && results.length > 0) {
          this.pagination.start = results[results.length - 1].payload.doc;
          this.pagination.end = results[0].payload.doc;
          console.log('start', this.pagination.start.id);
          console.log('end', this.pagination.end.id);
        }
      }),
      map((actions) =>
        actions.map((a) => {
          const data: Task = a.payload.doc.data() as Task;
          const id = a.payload.doc.id;
          return data;
        })
      ),
      shareReplay(1)
    );
  }

  public load4TaxCase(taxcaseId: string): Observable<Task[]> {
    return this.db
      .collection<Task>('todocenter', (ref) =>
        ref
          .where('taskmetadata.taxcaseId', '==', taxcaseId)
          .where('type', '==', 'note')
          .where('taskmetadata.deleted', '==', false)
          .orderBy('taskmetadata.createdAt', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((d) => {
            const t = d.payload.doc.data(); // TODO: Übergabe checken
            const task: Task = t as Task;
            task.id = d.payload.doc.id;
            return task;
          })
        )
      );
  }

  public openNew() {
    $('#noteadd_editor_add').modal('show');
  }

  public addNote(
    task: Task,
    taxcase: TaxCase | null,
    client: Client | null = null
  ): void {
    if (taxcase === null && client === null) {
      throwError(new Error('taxcase or client must be set'));
    }

    task.type = ACTIVITYTYPE.NOTE;

    if (taxcase) {
      task.taskmetadata.taxcaseId = taxcase.id;
      if (taxcase.client) {
        task.taskmetadata.clientId = taxcase.client.id;
      }
    }
    if (client) {
      task.taskmetadata.clientId = client.id;
    }

    this.add(task);
  }

  public async add(task: Task) {
    task.taskmetadata.createdAt = Timestamp.now();
    task.taskmetadata.createdBy = this.security.user;
    task.taskmetadata.updatedAt = Timestamp.now();
    task.taskmetadata.updatedBy = this.security.user;

    console.log('Save Task object');
    // console.log(task);
    console.log('--');

    await this.db.collection('todocenter').add(task);
  }

  public async delete(task: Task) {
    task.taskmetadata.deleted = true;
    this.update(task);
  }

  public async update(task: Task) {
    task.taskmetadata.updatedBy = this.security.user;
    task.taskmetadata.updatedAt = Timestamp.now();

    await this.db.collection<Task>('todocenter').doc(task.id).set(task);
  }
}
