import { Component, Input } from '@angular/core';

@Component({
  selector: 'esui-floating-tab-bar',
  templateUrl: './floating-tab-bar.component.html',
  styleUrls: ['./floating-tab-bar.component.scss'],
})
export class FloatingTabBarComponent {
  @Input()
  positionX: 'right' | 'left' = 'left';
}
