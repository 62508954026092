<ng-container *ngIf="transactionLineByIds">
  <h3 *ngIf="showHeadline">Transactions</h3>
  <table mat-table [dataSource]="transactionLineByIds">
    <ng-container matColumnDef="Id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let transactionLine">
        {{ transactionLine.id }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let transactionLine">
        {{ transactionLine.btl.updated_at.toDate() | date : 'medium' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let transactionLine">
        {{ transactionLine.btl.operation_type }}
      </td>
    </ng-container>
    <ng-container matColumnDef="InOutBound">
      <th mat-header-cell *matHeaderCellDef>Income/Outgoing</th>
      <td mat-cell *matCellDef="let transactionLine">
        <ng-container *ngIf="transactionLine.btl.side === 'credit'">
          +
        </ng-container>
        <ng-container *ngIf="transactionLine.btl.side === 'debit'">
          -
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="Amount">
      <th mat-header-cell *matHeaderCellDef>Amount</th>
      <td mat-cell *matCellDef="let transactionLine">
        {{ transactionLine.btl.amount | currency : 'EUR' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Currency">
      <th mat-header-cell *matHeaderCellDef>Currency</th>
      <td mat-cell *matCellDef="let transactionLine">
        {{ transactionLine.btl.currency }}
      </td>
    </ng-container>
    <ng-container matColumnDef="transactionText">
      <th mat-header-cell *matHeaderCellDef>Verwendungszweck</th>
      <td mat-cell *matCellDef="let transactionLine">
        {{ transactionLine.btl.note }}
      </td>
    </ng-container>
    <ng-container matColumnDef="FitTaxNotice">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let transactionLine">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(transactionLine) : null"
          [checked]="selection.isSelected(transactionLine)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr
      mat-row
      *matRowDef="let transactionLine; columns: columnsToDisplay"
      (click)="selection.toggle(transactionLine)"
    ></tr>
  </table>
</ng-container>
<div *ngIf="unknownSelections.length > 0">
  yoyoo - please be CAREFUL! We detected a strange state; One or more of the
  currently selected transaction lines are not known:<br />
  <pre> {{ unknownSelections | json }} </pre>
</div>

<div class="overview">
  <mat-chip-list aria-label="selection overview">
    selection:
    <mat-chip *ngFor="let line of enteredSelectedTransactionLines">{{
      line.amount | currency : 'EUR'
    }}</mat-chip>

    <mat-chip *ngIf="enteredSelectedTransactionLines.length < 1">{{
      0 | currency : 'EUR'
    }}</mat-chip>
  </mat-chip-list>
</div>
