import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { EsuiSectionColumnScaffoldModule } from '../esui-section-column-scaffold/esui-section-column-scaffold.module';
import { ButtonToggleGroupModule } from '../form-inputs/button-toggle-group/button-toggle-group.module';
import { TextInputModule } from '../form-inputs/text-input/text-input.module';
import { TasksFilterComponent } from './tasks-filter.component';

@NgModule({
  declarations: [TasksFilterComponent],
  imports: [
    CommonModule,
    TextInputModule,
    FormsModule,
    MatFormFieldModule,
    ButtonToggleGroupModule,
    MatListModule,
    MatIconModule,
    MatSelectModule,
    EsuiSectionColumnScaffoldModule,
  ],
  exports: [TasksFilterComponent],
})
export class EsTasksFilterModule {}
