<ng-container>
  <div class="flex-container">
    <div class="flex-item">
      <mat-form-field appearance="fill">
        <mat-label>eTin</mat-label>
        <input
          matInput
          placeholder="eTin"
          [ngModel]="eTin"
          (ngModelChange)="eTinChange.emit($event)"
        />
      </mat-form-field>
    </div>
    <div class="flex-item">
      <mat-form-field appearance="fill">
        <mat-label>Finanzamt Identifikation</mat-label>
        <input
          matInput
          placeholder="0000"
          type="number"
          min="0"
          max="9999"
          [ngModel]="financeDepartmentId"
          (ngModelChange)="financeDepartmentIdChange.emit($event)"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="flex-container">
    <div class="flex-item">
      <mat-form-field appearance="fill">
        <mat-label>Steuernummer</mat-label>
        <input
          matInput
          placeholder="00000000000"
          type="text"
          [ngModel]="taxNumber"
          (ngModelChange)="taxNumberChange.emit($event)"
        />
      </mat-form-field>
    </div>
    <div class="flex-item">
      <mat-form-field appearance="fill">
        <mat-label>Person</mat-label>
        <mat-select
          [ngModel]="taxPerson"
          (ngModelChange)="taxPersonChange.emit($event)"
        >
          <mat-option value="A"> Person A </mat-option>
          <mat-option value="B"> Person B </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="flex-container">
    <div class="flex-item">
      <esui-taxadvisor
        [taxadvisors]="taxadvisors"
        [taxAdvisorId]="taxAdvisorId"
        [disableInactive]="false"
        *ngIf="type !== 'partner'"
        (taxAdvisorIdChange)="taxAdvisorIdChange.emit($event)"
      ></esui-taxadvisor>
    </div>

    <div class="flex-item" *ngIf="type === 'partner'">
      <mat-form-field appearance="fill">
        <mat-label>Steuer-ID Partner</mat-label>
        <input
          matInput
          placeholder="00000000000"
          type="number"
          [ngModel]="partnerTaxId"
          esuiValidTaxId
          #taxIdEl="ngModel"
          (ngModelChange)="partnerTaxIdChange.emit($event)"
        />
      </mat-form-field>
      <mat-error *ngIf="taxIdEl.errors?.invalidTaxId">
        Bitte gebe eine gültige Steuer-ID ein
      </mat-error>
    </div>
  </div>
  <ng-container *ngIf="financeOffice">
    <esui-finance-office [financeOffice]="financeOffice"></esui-finance-office>
  </ng-container>
</ng-container>
