import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { EsuiSkeletonPlaceholderModule } from '../esui-skeleton-placeholder/esui-skeleton-placeholder.module';
import { EsuiDataTaxnoticeListComponent } from './esui-data-taxnotice-list.component';

@NgModule({
  declarations: [EsuiDataTaxnoticeListComponent],
  imports: [
    CommonModule,
    EsuiSkeletonPlaceholderModule,
    MatTableModule,
    MatButtonModule,
  ],
  exports: [EsuiDataTaxnoticeListComponent],
})
export class EsuiDataTaxnoticeListModule {}
