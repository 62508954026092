import { Component, Input } from '@angular/core';

@Component({
  selector: 'esui-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent {
  @Input()
  public downloadUrl = '';
}
