import { formatCurrency } from '@angular/common';
import { SchemaProperty } from '@expresssteuer/eric-lib-interfaces';

type Data = Record<string, unknown>;

/**
 * transforms json data to follow the order and naming of the given json schema.
 * The data input can only be a json object. Ie only primitives/objects/arrays are supported.
 * The schema input has to be a valid json schema, it is not verified.
 * If the schema contains a description for a key it is used for the result key.
 * If a value has no corresponding schema it is just copied to the output.
 * If the schema declares a stringifier it is applied to the value.
 *
 * @example
 * ```
  const schema: SchemaProperty = {
      type: 'object',
      properties: {
        key: {
          type: 'string',
          description: 'key described',
        },
      },
    };
    const data = {
      key: 'Value',
    };
    const expectedResult = { 'key described': 'Value' };

    expect(transformDataToFollowSchema(data, schema)).toStrictEqual(
      expectedResult
    );
 * ```
 */
export function transformDataToFollowSchema<
  T extends Data,
  U extends SchemaProperty
>(data: T, schema?: U): Record<string, unknown> {
  const result: Record<string, unknown> = {};

  // Get the properties and values from the data object
  const dataProps = data;

  // Get the properties and values from the schema object
  const schemaProps = schema?.properties || {};

  // Loop through the schema properties and map them to the data values
  if (schemaProps) {
    for (const [key, value] of Object.entries(schemaProps)) {
      if (Object.prototype.hasOwnProperty.call(dataProps, key)) {
        if (value.type === 'object') {
          if (value.properties) {
            result[value.description || key] = transformDataToFollowSchema(
              dataProps[key] as Data,
              value
            );
          }
        } else if (value.type === 'array') {
          const itemsSchema = value.items as SchemaProperty;
          const itemsData = dataProps[key];

          result[value.description || key] = Array.isArray(itemsData)
            ? itemsData.map((item) =>
                transformDataToFollowSchema(item, itemsSchema)
              )
            : transformDataToFollowSchema(
                itemsData ?? ({} as any),
                itemsSchema
              );
        } else {
          result[value.description || key] = stringify(
            dataProps[key] as string,
            value.stringifier
          );
        }
      }
    }
  }

  // Handle case where data has properties not defined in schema
  for (const [key, value] of Object.entries(dataProps)) {
    if (
      !(schemaProps && Object.prototype.hasOwnProperty.call(schemaProps, key))
    ) {
      if (typeof value === 'object') {
        result[key] = Array.isArray(value)
          ? value.map((item) => transformDataToFollowSchema(item))
          : transformDataToFollowSchema(value as Data);
      } else {
        result[key] = `${value}`;
      }
    }
  }

  return result;
}

function stringify(data: string, stringifier?: string) {
  switch (stringifier) {
    case 'currency':
      return formatCurrency(parseFloat(data), 'de-DE', '');
    case 'currency-eur':
      return formatCurrency(parseFloat(data), 'de-DE', '€');
    default:
      return data;
  }
}
