import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExtractedDocument } from '@expresssteuer/models';

@Component({
  selector: 'esui-text-ocr-renderer',
  templateUrl: './esui-text-ocr-renderer.component.html',
  styleUrls: ['./esui-text-ocr-renderer.component.scss'],
})
export class EsuiTextOcrRendererComponent implements OnInit {
  constructor() {}

  lineBreakRegex = /\n/gim;

  selectedBlockIdSet = new Set<string>();

  @Input()
  structuredOcr?: ExtractedDocument | null;

  @Input() set selectedTextBlocksIds(selectedBlocks: Set<string>) {
    this.selectedBlockIdSet = selectedBlocks;
  }

  @Output()
  selectedTextBlocksIdsChange: EventEmitter<Set<string>> = new EventEmitter();

  ngOnInit(): void {}

  onTextBlockClicked(pageId: number, blockId: number) {
    const id = this.getIdForIndex(pageId, blockId);
    if (this.selectedBlockIdSet?.has(id)) {
      this.selectedBlockIdSet.delete(id);
      this.selectedTextBlocksIdsChange.next(this.selectedBlockIdSet);
    } else {
      this.selectedBlockIdSet.add(id);
      this.selectedTextBlocksIdsChange.next(this.selectedBlockIdSet);
    }
  }

  getIdForIndex(pageIndex: number, blockIndex: number) {
    return `${pageIndex}-${blockIndex}`;
  }
}
