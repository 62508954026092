export * from './lib/approval/set.api-angular';
export * from './lib/get-auth-info.api-angular';
export * from './lib/relink-auth.api-angular';

export * from './lib/approval/send-mail.api-angular';

export * from './lib/iban/update.api-angular';

export * from './lib/verify-email.api-angular';

export * from './lib/extraction-zip-download.api-angular';

export * from './lib/start-email-verification.api-angular';

export * from './lib/approval/back-to-edit.api-angular';

export * from './lib/phone/start-phone-verification.api-angular';

export * from './lib/phone/verify-phone.api-angular';

export * from './lib/phone/change-phone-number.api-angular';

export * from './lib/personal-info/update.api-angular';

export * from './lib/partner/get.api-angular';

export * from './lib/request-deletion.api-angular';
