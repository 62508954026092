<mat-button-toggle-group
  [vertical]="vertical"
  [ngModel]="value"
  (ngModelChange)="value = $event"
  [disabled]="disabled"
>
  <ng-container *ngFor="let option of options; trackBy: idByKey">
    <mat-button-toggle [value]="option.value">
      <mat-icon *ngIf="option.icon" [matTooltip]="option.label">{{
        option.icon
      }}</mat-icon>
      <ng-container *ngIf="!option.icon">{{ option.label }}</ng-container>
    </mat-button-toggle>
  </ng-container>
</mat-button-toggle-group>
