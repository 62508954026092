import {
  input as zodInput,
  object as zodObject,
  string as zodString,
} from 'zod';

export interface CounterParty {
  counterPartyId?: string;
  clientCounterPartyId?: string;
  name?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  zipCode?: number;
  city?: string;
  country?: string;
  category?: string;
  subCategory?: string;
  vatCode?: string;
  bankAccountNumber?: string;
  bankCode?: string;
  bankCountry?: string;
}

const BankingA352RawCounterPartyZod = zodObject({
  clientCounterPartyId: zodString()
    .describe(
      'Unique ID of the counter party, defined by the client when creating the entity.'
    )
    .optional(),
  name: zodString()
    .describe('Name of the counter party. F.e.: Mr Andrey Gerart')
    .optional(),
  addressLine1: zodString()
    .describe('First line of the address. F.e.: 1 Great Street')
    .optional(),
  addressLine2: zodString()
    .describe('Second line of the address. F.e.: Building B')
    .optional(),
  addressLine3: zodString()
    .describe('Third line of the address. F.e.: Door 354')
    .optional(),
  zipCode: zodString().describe('Zip code.').optional(),
  city: zodString().describe('City.').optional(),
  country: zodString().describe('Country ISO3166 code.').optional(),
  category: zodString()
    .describe('Category as defined by the company classification.')
    .optional(),
  subCategory: zodString()
    .describe('Subcategory as defined by the company classification.')
    .optional(),
  vatCode: zodString().describe('Company VAT code.').optional(),
  bankAccountNumber: zodString()
    .describe('Counter party IBAN or local bank account number.')
    .optional(),
  bankCode: zodString()
    .describe(
      'Bank BIC, bank routing number or sort code. Mandatory when a local bank account number is provided in "bankAccountNumber".'
    )
    .optional(),
  bankCountry: zodString().describe('Bank country.').optional(),
});

const BankingA352RawCounterPartyResponseZod =
  BankingA352RawCounterPartyZod.extend({
    counterPartyId: zodString().describe('Unique ID of the counter party.'),
  });

export type BankingA352RawCounterParty = zodInput<
  typeof BankingA352RawCounterPartyZod
>;
export type BankingA352RawCounterPartyResponse = zodInput<
  typeof BankingA352RawCounterPartyResponseZod
>;

export function asBankingA352RawCounterPartyResponse(
  data: unknown
): BankingA352RawCounterPartyResponse {
  return BankingA352RawCounterPartyResponseZod.parse(data);
}
