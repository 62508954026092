import { ElsterDocument } from './elsterBase';

export enum ELSTERRESPONSESTATUS {
  OK = 'OK',
  ERROR = 'ERROR',
}

export enum ELSTERRESPONSETYPE {
  NONE = '',
  LOG = 'LOG',
  JSON = 'JSON',
}

export class ElsterResponse {
  public static getTemplate(): ElsterResponse {
    return {
      filelink: null,
      created: null,
      status: ELSTERRESPONSESTATUS.OK,
      elsterDocument: null,
      elsterDocumentXml: '',
      type: ELSTERRESPONSETYPE.NONE,
      error: [],
      data: '',
    };
  }

  public id?: string = '';
  public filelink: string | null = null;
  public created: any;
  public status: ELSTERRESPONSESTATUS = ELSTERRESPONSESTATUS.OK;
  public elsterDocument: ElsterDocument | null = null;
  public elsterDocumentXml: string = '';
  public type: ELSTERRESPONSETYPE = ELSTERRESPONSETYPE.NONE;
  public error: string[] = [];
  public data: string | null = '';
}
