import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { Client } from '@expresssteuer/models';
import {
  NgAisIndex,
  NgAisInstantSearch,
  TypedBaseWidget,
} from 'angular-instantsearch';
import { connectAutocomplete } from 'instantsearch.js/es/connectors';

interface AlgoliaClient {
  objectID: string;
  firstname: string;
  lastname: string;
}

/**
 * An Algolia widget for rendering a search box for clients.
 *
 * @see https://www.algolia.com/doc/guides/building-search-ui/ui-and-ux-patterns/autocomplete/angular/
 */
@Component({
  selector: 'esui-client-search-autocomplete',
  templateUrl: './esui-client-search-autocomplete.component.html',
  styleUrls: ['./esui-client-search-autocomplete.component.scss'],
})
export class EsuiClientSearchAutocompleteComponent
  extends TypedBaseWidget<any, any>
  implements OnInit
{
  state?: {
    query: string;
    refine: any;
    indices: (any | { hits: Client[] })[];
  };

  lastEmittedClientId?: string | null;
  searchFieldValue = '';
  localPreview?: AlgoliaClient | null;

  #clientId?: string | null;
  @Input() set clientId(data: string | undefined | null) {
    if (this.#clientId === data) {
      return;
    }
    this.#clientId = data;
    if (data === this.lastEmittedClientId) {
      return;
    }
    this.localPreview = null;
    this.searchFieldValue = data ?? '';
  }
  get clientId(): string | undefined | null {
    return this.#clientId;
  }
  @Output() clientIdChange = new EventEmitter<string | null>();

  @Input() mode: 'default' | 'clientIdEditor' = 'default';
  @Input() appearance: 'legacy' | 'standard' | 'fill' | 'outline' = 'standard';

  constructor(
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch,
    @Optional()
    public parentIndex?: NgAisIndex
  ) {
    super('EsuiClientSearchAutocompleteComponent');
  }

  public clientClickHandler(input: AlgoliaClient) {
    this.localPreview = input;
    this.emitClientId(input.objectID);
  }

  public tryToEmitInputAsClientId(input?: string) {
    if (input && !input.match(/^[0-9a-z]+$/gi) && input.length > 10) {
      return;
    }
    this.localPreview = null;
    this.emitClientId(input);
  }

  emitClientId(clientId: string | undefined | null) {
    this.searchFieldValue = clientId ?? '';
    this.lastEmittedClientId = clientId;
    this.clientIdChange.emit(clientId);
  }

  public handleChange($event: KeyboardEvent) {
    this.state?.refine(($event.target as HTMLInputElement).value);
  }

  public ngOnInit() {
    this.createWidget(connectAutocomplete, {});
    super.ngOnInit();
  }

  trackById(index: number, obj: { id: string }) {
    return obj.id;
  }

  trackByValue(index: number, obj: any) {
    return JSON.stringify(obj);
  }
}
