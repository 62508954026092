<!--begin::Header-->
<div id="kt_header" class="header header-fixed">
  <!--begin::Header Wrapper-->
  <div
    class="header-wrapper rounded-top-xl d-flex flex-grow-1 align-items-center"
  >
    <!--begin::Container-->
    <div
      class="container-fluid d-flex align-items-center justify-content-end justify-content-lg-between flex-wrap"
    >
      <!--begin::Menu Wrapper-->

      <!--end::Menu Wrapper-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center py-3">
        <!--begin::Dropdown-->
        <div class="dropdown dropdown-inline">
          <a href="/clients" class="btn btn-dark font-weight-bold px-6"
            >Abbrechen</a
          >
          <a (click)="validateAndSave()" class="btn font-weight-bold px-6"
            >Speichern</a
          >
        </div>
        <!--end::Dropdown-->
      </div>
      <!--end::Toolbar-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Header Wrapper-->
</div>
<!--end::Header-->

<!--begin::Content-->

<div class="content-fluid d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->

    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Neuen Kunden erstellen
            <span class="d-block text-muted pt-2 font-size-sm"
              >Manuelle erfassung eines neuen Kunden</span
            >
          </h3>
        </div>
        <div class="card-toolbar"></div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body px-0">
        <div class="container-fluid">
          <!--begin::Row-->
          <div class="row">
            <div class="col-xl-2"></div>
            <div class="col-xl-7 my-2">
              <!--begin::Row-->
              <div class="row">
                <label class="col-3"></label>
                <div class="col-9">
                  <h6 class="text-dark font-weight-bold mb-10">Kundendaten:</h6>
                </div>
              </div>
              <!--end::Row-->

              <!--begin::Group-->
              <div class="form-group row">
                <label class="col-form-label col-3 text-lg-right text-left"
                  >Vorname</label
                >
                <div class="col-9">
                  <input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    name="firstname"
                    [(ngModel)]="client.firstname"
                  />
                </div>
              </div>
              <!--end::Group-->
              <!--begin::Group-->
              <div class="form-group row">
                <label class="col-form-label col-3 text-lg-right text-left"
                  >Nachname</label
                >
                <div class="col-9">
                  <input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    name="lastname"
                    [(ngModel)]="client.lastname"
                  />
                </div>
              </div>
              <!--end::Group-->
              <!--begin::Group-->
              <div class="form-group row">
                <label class="col-form-label col-3 text-lg-right text-left"
                  >Geburtsdatum</label
                >
                <div class="col-9">
                  <app-firebase-date-picker
                    [(date)]="client.birthdate"
                  ></app-firebase-date-picker>
                </div>
              </div>
              <!--end::Group-->

              <!--begin::Group-->
              <div class="form-group row">
                <label class="col-form-label col-3 text-lg-right text-left"
                  >Email Adresse</label
                >
                <div class="col-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="la la-at"></i
                      ></span>
                    </div>
                    <input
                      type="text"
                      name="email"
                      class="form-control form-control-lg form-control-solid"
                      [(ngModel)]="client.email"
                      placeholder="Email"
                    />
                  </div>
                  <span class="form-text text-muted"
                    >Email darf nicht bereits registriert sein</span
                  >
                </div>
              </div>
              <!--end::Group-->

              <!--begin::Group-->
              <div class="form-group row">
                <label class="col-form-label col-3 text-lg-right text-left"
                  >Telefonnummer</label
                >
                <div class="col-9">
                  <div class="input-group input-group-lg input-group-solid">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="la la-phone"></i
                      ></span>
                    </div>
                    <input
                      type="text"
                      class="form-control form-control-lg form-control-solid"
                      [ngModel]="client.mobile"
                      placeholder="Phone"
                      [disabled]="true"
                    />
                  </div>
                  <span class="form-text text-muted"
                    >Nummer muss das Format +491234567890 besitzen</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--end::Row-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
