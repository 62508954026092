import { Component, Input } from '@angular/core';
import { InternalUser, WithId } from '@expresssteuer/models';

@Component({
  selector: 'esui-user-list-item',
  templateUrl: './user-list-item.component.html',
  styleUrls: ['./user-list-item.component.scss'],
})
export class UserListItemComponent {
  @Input() user?: WithId<InternalUser>;
}
