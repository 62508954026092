import { Component, Input } from '@angular/core';
import { MESSAGESTATUS } from '@expresssteuer/models';

@Component({
  selector: 'esui-message-state',
  templateUrl: './message-state.component.html',
  styleUrls: ['./message-state.component.scss'],
})
export class MessageStateComponent {
  MESSAGESTATUS = MESSAGESTATUS;
  @Input() state?: MESSAGESTATUS;
}
