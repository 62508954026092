import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  ApprovalBackToEditInput,
  ApprovalBackToEditOutput,
} from '@expresssteuer/client-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApprovalBackToEdit {
  constructor(public afFunctions: AngularFireFunctions) {}

  /**
   * Restart the aquana flow so that client edits are possible again.
   *
   * @api summary Restart aquana flow
   * @api tag aquana
   * @api consumer andorra,mobileapp
   */
  call(input: ApprovalBackToEditInput) {
    console.log('Calling client-approvalBackToEdit');
    return this.afFunctions.httpsCallable<
      ApprovalBackToEditInput,
      ApprovalBackToEditOutput
    >('client-approvalBackToEdit')(input);
  }
}
