<nav *ngIf="showNavigation">
  <button
    mat-icon-button
    color="primary"
    aria-label="rotate"
    (click)="rotate()"
  >
    <mat-icon>rotate_left</mat-icon>
  </button>

  <button
    mat-icon-button
    color="primary"
    aria-label="zoom in"
    (click)="zoomBy(0.1)"
  >
    <mat-icon>zoom_in</mat-icon>
  </button>

  <button
    mat-icon-button
    color="primary"
    aria-label="zoom out"
    (click)="zoomBy(-0.1)"
  >
    <mat-icon>zoom_out</mat-icon>
  </button>

  <ng-container *ngIf="showDownloadButton">
    <button
      mat-icon-button
      color="primary"
      aria-label="download"
      (click)="downloadFile(downloadUrl, getFileName(downloadUrl, fileName))"
    >
      <mat-icon>download</mat-icon>
    </button>
  </ng-container>
</nav>

<pdf-viewer
  [src]="downloadUrl"
  [show-all]="true"
  [render-text]="true"
  [external-link-target]="'blank'"
  [zoom-scale]="'page-width'"
  [show-borders]="false"
  [original-size]="false"
  [zoom]="zoom"
  [rotation]="rotation"
></pdf-viewer>
