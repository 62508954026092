import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Client, MESSAGETYPE } from '@expresssteuer/models';
import { simple } from 'instantsearch.js/es/lib/stateMappings';
import { map } from 'rxjs/operators';
import { DocumentsService } from '../../../../app/services/data/documents.service';
import { MessagingService } from '../../../../app/services/data/messaging.service';
import { AlgoliaService } from '../../../../app/services/search/algolia.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit {
  public searchClient = null;

  private query = '';

  public configTaxcases$ = this.algoliaService.searchClient$.pipe(
    map((searchClient) => {
      return {
        indexName: environment.algolia.indexNames.taxcases,
        searchClient,
        routing: {},
        stateMapping: simple(),
      };
    })
  );

  public configMessages$ = this.algoliaService.searchClient$.pipe(
    map((searchClient) => {
      return {
        indexName: environment.algolia.indexNames.messages,
        searchClient,
        stateMapping: simple(),
      };
    })
  );

  /**
   * @deprecated use documents_v2
   */
  public configDocuments$ = this.algoliaService.searchClient$.pipe(
    map((searchClient) => {
      return {
        indexName: environment.algolia.indexNames.documents,
        searchClient,
        stateMapping: simple(),
      };
    })
  );

  public configClients$ = this.algoliaService.searchClient$.pipe(
    map((searchClient) => {
      return {
        indexName: environment.algolia.indexNames.clients,
        searchClient,
        stateMapping: simple(),
      };
    })
  );

  constructor(
    public documentsservice: DocumentsService,
    private messageService: MessagingService,
    public algoliaService: AlgoliaService
  ) {}

  public onQuery($event) {
    this.query = $event.target.value;
  }

  public get searchParameters() {
    return {
      query: this.query,
    };
  }

  public sendEmail(client: any) {
    console.log(client);
    this.messageService.openMessanger(MESSAGETYPE.EMAIL, client as Client);
  }
  public sendWhatsapp(client: any) {
    console.log(client);
    this.messageService.openMessanger(MESSAGETYPE.WHATSAPP, client as Client);
  }

  ngOnInit(): void {}
}
