<mat-card>
  <div class="fulfilled-status">
    <mat-chip-list>
      <ng-container *ngIf="request?.fullFilled; else notYetFulfilled">
        <mat-chip class="fulfilled"> Erledigt </mat-chip>
      </ng-container>
      <ng-template #notYetFulfilled>
        <mat-chip class="not-yet-fulfilled"> Noch nicht erledigt </mat-chip>
      </ng-template>
      <mat-chip class="fulfilled" *ngIf="request?.replySent">
        Antwort erfolgreich
      </mat-chip>
      <mat-chip class="reply-failed" *ngIf="request?.sendFailed">
        Letzte Antwort fehlgeschlagen
      </mat-chip>
      <span class="color-lightgray">
        Wird automatisch erledigt, sobald alle Content Tag Handles erledigt
        sind.
      </span>
    </mat-chip-list>
    <div>
      <button mat-raised-button (click)="assignOperator.emit()">
        Mich zuweisen!
      </button>
      <span
        >aktuell:
        <mat-chip>{{
          request?.assignedOperatorEmail ?? 'Nicht zugewiesen'
        }}</mat-chip></span
      >
    </div>
  </div>
  <mat-card-header><h2>Generelle Infos</h2> </mat-card-header>
  <div class="headline-container">
    <mat-card>
      <mat-card-content
        ><p>Finanzamt Nachfrage - ID: {{ request?.id ?? '-' }}</p>
        <p>Referenz: {{ request?.letterReference ?? '-' }}</p></mat-card-content
      >
    </mat-card>
    <mat-card>
      <mat-card-content
        ><p *ngIf="request?.creationDate">
          Erstellungsdatum: {{ request?.creationDate | asDate | date }}
        </p>
        <p *ngIf="request?.dueDate">
          Fristdatum: {{ request?.dueDate | asDate | date }}
        </p></mat-card-content
      >
    </mat-card>
    <mat-card>
      <mat-card-content
        ><p>clientId: {{ request?.clientId }}</p>
        <p>Name: {{ clientForRequest?.displayName }}</p>
        <a [href]="crmLink" target="_blank" rel="nofollow">Gehe zum CRM</a>
      </mat-card-content>
    </mat-card>
  </div>

  <mat-card-content>
    <ng-content></ng-content>
    <ng-container *ngIf="requestDocument">
      <div class="download-container">
        <ng-container
          *ngIf="downloadFileCount > 0; else disabledDownloadZipTemplate"
        >
          <button mat-raised-button (click)="downloadDocsZipClicked.emit()">
            <mat-icon>download</mat-icon> .zip herunterladen ({{
              downloadFileCount
            }}
            Dateien ausgewählt)
          </button>
        </ng-container>
        <ng-template #disabledDownloadZipTemplate>
          <button mat-raised-button disabled="">
            <mat-icon>download</mat-icon> .zip herunterladen ({{
              downloadFileCount
            }}
            Dateien ausgewählt)
          </button>
        </ng-template>
        <span class="color-lightgray">
          Die zip Datei sollte normalerweise immer automatisch über die E-Mail
          verschickt werden. Zur Kontrolle oder als Alternative im Notfall.
        </span>
      </div>
      <ng-container *ngIf="hideRequestDocument; else requestDocumentTemplate">
        <button mat-raised-button (click)="hideRequestDocument = false">
          Nachfrage einblenden
        </button>
      </ng-container>
      <ng-container *ngIf="request?.replySent">
        <button mat-raised-button (click)="resetSentStatus.next()">
          Email nochmal senden
        </button>
      </ng-container>
      <ng-template #requestDocumentTemplate>
        <button
          mat-raised-button
          (click)="hideRequestDocument = true"
          class="hide-request-button"
        >
          Nachfrage ausblenden
        </button>
        <esui-skeleton-placeholder
          [bigBoxMode]="true"
          [skeletonBuildInput]="[]"
          [watchResult]="!!requestDocument"
        >
          <ng-container *ngIf="requestDocument">
            <esui-document-viewer
              class="document-viewer-container"
              [contentType]="requestDocument.contentType"
              [downloadUrl]="requestDocument.downloadUrl"
            ></esui-document-viewer>
          </ng-container>
        </esui-skeleton-placeholder>
      </ng-template>
    </ng-container>
    <h3>Nachgefragte Content Tags</h3>
    <mat-accordion>
      <mat-expansion-panel
        *ngFor="
          let contentTagHandle of contentTagHandlesSorted;
          trackBy: trackContentTagHandle
        "
        [expanded]="activeContentTagHandle?.id === contentTagHandle.id"
        (opened)="contentTagHandleClicked.next(contentTagHandle.id)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div
              class="content-tag-handle-status-fulfilled"
              *ngIf="contentTagHandle.fulfilled"
            ></div>
            <div
              class="content-tag-handle-status-not-yet-fulfilled"
              *ngIf="!contentTagHandle.fulfilled"
            ></div>
            <ng-container *ngIf="contentTagHandle.year">
              <h3>{{ contentTagHandle.year }}:</h3>
            </ng-container>
            <h3>{{ contentTagHandle.tag }}</h3>
          </mat-panel-title>
          <mat-panel-description>
            <mat-chip-list>
              <mat-chip
                [disabled]="request?.replySent ?? false"
                *ngFor="
                  let fulfillingBinaryDocRef of sortDesc
                    | pureMethod
                      : {
                          thisArg: this,
                          args: [contentTagHandle.fulfillingBinaryDocRefs]
                        }
                "
                (removed)="
                  removeDocFromContentTagHandle.next({
                    removeFulfillingBinaryDocRef: fulfillingBinaryDocRef,
                    id: contentTagHandle.id
                  })
                "
              >
                <span
                  (click)="
                    showDocClicked.next({
                      contentTagHandleId: contentTagHandle.id,
                      binaryDocumentRef: fulfillingBinaryDocRef
                    });
                    contentTagHandleClicked.next(contentTagHandle.id);
                    $event.stopPropagation()
                  "
                >
                  {{
                    getFormattedBinaryDocRef
                      | pureMethod
                        : {
                            thisArg: this,
                            args: [fulfillingBinaryDocRef, binaryDocuments]
                          }
                  }}
                </span>
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <mat-chip
                *ngFor="
                  let textFulfillment of contentTagHandle.fulfillingTextsResponse ??
                    []
                "
              >
                {{ textFulfillment }}
              </mat-chip>
            </mat-chip-list>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="content-tag-handle-info">
          <div class="color-lightgray" *ngIf="contentTagHandle.id">
            ID: {{ contentTagHandle.id }}
          </div>
          <mat-slide-toggle
            class="fulfilled-toggle"
            [checked]="contentTagHandle.fulfilled"
            [disabled]="request?.replySent ?? false"
            (change)="
              contentTagHandleFulfilledChange.next({
                id: contentTagHandle.id,
                fulfilled: $event.checked
              })
            "
            >Erledigt [space]
          </mat-slide-toggle>
        </div>
        <div
          *ngIf="
            contentTagHandle.fulfillingTextsResponse &&
            contentTagHandle.fulfillingTextsResponse.length > 0
          "
        >
          <h3>Textuelle Antwort</h3>
          <div class="text-fulfillment">
            <p
              *ngFor="
                let line of contentTagHandle.fulfillingTextsResponse ?? []
              "
            >
              {{ line }}
            </p>
          </div>
        </div>
        <h4>Dokumente des Mandanten</h4>
        <div
          class="list-item"
          *ngFor="let doc of docsNotFulfillingActiveContentTagHandle ?? []"
        >
          <div class="list-item-doc-info">
            <i *ngIf="doc.uploaded">{{
              doc.uploaded | asDate | date : 'dd.MM.yy'
            }}</i>
            -
            <b>{{ doc.type ?? 'Unknown Type' }}</b> <br />
            <span class="color-lightgray">{{ doc.name }}</span>
          </div>
          <div class="list-item-tags">
            <div
              class="chip"
              *ngFor="
                let uniqueContentTag of getUniqueContentTags
                  | pureMethod : { args: [doc] }
              "
            >
              {{ uniqueContentTag }}
            </div>
          </div>
          <div class="list-item-actions">
            <button
              mat-icon-button
              title="Anzeigen"
              (click)="
                showDocClicked.next({
                  contentTagHandleId: contentTagHandle.id,
                  binaryDocumentRef: doc.ref
                })
              "
            >
              <mat-icon>preview</mat-icon>
            </button>
            <button
              mat-icon-button
              title="Auswählen"
              [disabled]="request?.replySent ?? false"
              (click)="
                addDocToContentTagHandle.next({
                  addFulfillingBinaryDocRef: doc.ref,
                  id: contentTagHandle.id
                })
              "
            >
              <mat-icon>assignment_turned_in</mat-icon>
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-content>
</mat-card>
