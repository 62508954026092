import { Component, Input } from '@angular/core';
import { TaskHistoryEntry } from '@expresssteuer/models';

@Component({
  selector: 'esui-task-history',
  templateUrl: './task-history.component.html',
  styleUrls: ['./task-history.component.scss'],
})
export class TaskHistoryComponent {
  @Input() taskHistory: TaskHistoryEntry[] = [];
}
