import {
  Answer as AnswerBase,
  AnswerFeedback as AnswerFeedbackBase,
  ClientTodo as ClientTodoBase,
  ClientTodoType,
  TodoMatcherMetadata as TodoMatcherMetadatasBase,
} from '../../../api-interfaces/src/index';

const matcherId = 'client-iban' as const;

export class TodoMatcherMetadata implements TodoMatcherMetadatasBase {
  matcherId = matcherId;
  input: undefined;
}

export function isTodo(todo: unknown): todo is ClientTodo {
  return (
    (todo as Partial<ClientTodo> | null)?.todoMatcherMetadata?.matcherId ===
    matcherId
  );
}

export function isAnswer(todo: unknown, answer: unknown): answer is Answer {
  const p = (answer as Answer | null)?.payload || null;
  const validPayload = () =>
    [p?.iban].some((e) => e == null || (e && typeof e === 'string'));
  return isTodo(todo) && validPayload();
}

export function isFeedback(
  todo: unknown,
  feedback: unknown
): feedback is AnswerFeedback {
  const data = (feedback as AnswerFeedback | null)?.data || null;
  return (
    isTodo(todo) &&
    (data == null ||
      (typeof data.valid === 'boolean' &&
        (data.iban == null || typeof data.iban === 'string')))
  );
}

export enum TodoError {
  BAD_FORMAT = 'bad_format',
}

export interface FeedbackData {
  errorId: TodoError | null;
  iban: string | null;
  comment: string | null;
  valid: boolean;
}

export type AnswerFeedback = AnswerFeedbackBase & {
  data: FeedbackData | null;
};

export interface AnswerPayload {
  iban: string;
}

export type Answer = AnswerBase & {
  payload: AnswerPayload;
};

export type ClientTodo = ClientTodoBase & {
  type: ClientTodoType.ClientPayoutIBAN;
  todoMatcherMetadata: TodoMatcherMetadata;
};
