<hotkeys-cheatsheet [title]="title"></hotkeys-cheatsheet>

<div class="search-container">
  <input
    [placeholder]="'action... [' + hotkeyCombo + ']'"
    aria-label="actions"
    matInput
    [matAutocomplete]="auto"
    type="search"
    [ngModel]="hotkeysQuery.search$ | async"
    (ngModelChange)="hotkeysService.setSearch($event)"
    (blur)="hotkeysService.setSearch('')"
    #input
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="hotkeysService.fireEventFor($any($event.option.value))"
    autoActiveFirstOption
  >
    <mat-option
      *ngFor="
        let option of hotkeysQuery.filteredHotkeys$ | async | keyvalue;
        trackBy: trackByKey
      "
      [value]="option.key"
    >
      <div class="option">
        <span>{{ option.key.description }}</span>
        <span
          ><code>[{{ option.key.combo }}]</code></span
        >
      </div>
    </mat-option>
  </mat-autocomplete>
</div>

<button type="button" mat-button (click)="hotkeysService.toggleCheatsheet()">
  {{ buttonText }}
</button>
