import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgAisModule } from 'angular-instantsearch';
import { EsuiClientSearchAutocompleteComponent } from './components/esui-client-search-autocomplete/esui-client-search-autocomplete.component';
import { EsuiClientSearchComponent } from './components/esui-client-search/esui-client-search.component';
import { EsuiSearchPanelComponent } from './components/esui-search-panel/esui-search-panel.component';

@NgModule({
  declarations: [
    EsuiClientSearchComponent,
    EsuiClientSearchAutocompleteComponent,
    EsuiSearchPanelComponent,
  ],
  imports: [
    CommonModule,
    NgAisModule,
    FormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [
    EsuiClientSearchComponent,
    EsuiClientSearchAutocompleteComponent,
    EsuiSearchPanelComponent,
  ],
})
export class EsuiSearchModule {}
