<ng-container *ngIf="deductions">
  <table mat-table [dataSource]="deductions" [trackBy]="trackDeductions">
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>Amount</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <mat-form-field>
          <mat-label></mat-label>
          <input
            matInput
            [disabled]="disabled"
            placeholder="0"
            type="number"
            [ngModel]="element.value"
            (ngModelChange)="partialDeductionChange(i, { value: $event })"
          />
          <mat-icon matSuffix>euro</mat-icon>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="type" *ngIf="availableTypes">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <mat-form-field>
          <mat-label></mat-label>
          <mat-select
            [disabled]="disabled"
            [ngModel]="element.type"
            (ngModelChange)="partialDeductionChange(i, { type: $event })"
          >
            <mat-option
              *ngFor="let typeOption of availableTypes | keyvalue"
              [value]="typeOption.value"
            >
              {{ typeOption.key }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button
          [disabled]="disabled"
          mat-icon-button
          (click)="removeDeduction(i)"
          matTooltip="remove deduction"
        >
          <mat-icon>remove</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
  </table>
</ng-container>
<button
  [disabled]="disabled"
  mat-icon-button
  color="primary"
  (click)="addDeduction()"
  matTooltip="add deduction"
>
  <mat-icon>add</mat-icon>
</button>
