import {
  custom as customZod,
  number as numberZod,
  string as stringZod,
} from 'zod';

/**
 * Returns the extracted TaxId and if the taxId is valid or not
 * @param taxId
 * @returns
 */
export function getTaxIdFrom(taxId: number | string): {
  taxId: string;
  valid: boolean;
} {
  /**
   * clean the numbers out
   */
  const taxIdString = taxId.toString().replace(/[^\d]/gm, '');

  return {
    taxId: taxIdString,
    valid: validateTaxid(taxIdString),
  };
}

/**
 * Validate if a TaxId is valid in Germany
 * @see https://de.wikipedia.org/wiki/Steuerliche_Identifikationsnummer
 * @param taxid the 11 number long taxId
 * @returns returns true if the taxId is valid, false otherwise
 */
export function validateTaxid(taxid: number | string) {
  //check length is 11
  const taxidString = taxid.toString();
  if (taxidString.length !== 11) {
    return false;
  }

  return mod11_10(taxidString);
}

/**
 * based on https://de.wikipedia.org/wiki/Steuerliche_Identifikationsnummer#Aufbau
 */
function mod11_10(taxid: string) {
  const ziffernfolge = taxid.split('').map(Number);

  let produkt = 10;
  for (let stelle = 1; stelle <= 10; stelle++) {
    let summe = (ziffernfolge[stelle - 1] + produkt) % 10;
    if (summe === 0) {
      summe = 10;
    }
    produkt = (summe * 2) % 11;
  }

  let pruefziffer = 11 - produkt;
  if (pruefziffer === 10) {
    pruefziffer = 0;
  }

  return pruefziffer === ziffernfolge[10];
}

export const TaxIdZod = stringZod()
  .or(numberZod())
  .pipe(
    customZod<string | number>((val) => {
      if (!(typeof val === 'string' || typeof val === 'number')) {
        return false;
      }
      return validateTaxid(val);
    })
  );
