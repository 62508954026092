import { Component, Input, TemplateRef } from '@angular/core';
import { VmaProofQAA } from '@expresssteuer/client-todos-api-interfaces';

@Component({
  selector: 'esui-vma-proof-answer',
  templateUrl: './esui-vma-proof-answer.component.html',
  styleUrls: ['./esui-vma-proof-answer.component.scss'],
})
export class EsuiVmaProofAnswerComponent {
  @Input()
  documentViewerTemplate?: TemplateRef<{ binaryDocumentRef: string }>;

  @Input()
  answerPayload?: VmaProofQAA.AnswerPayload;
}
