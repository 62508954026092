<input
  type="number"
  matInput
  [(ngModel)]="value"
  [disabled]="disabled"
  [placeholder]="placeholder"
  (focus)="onFocusIn($event)"
  (focusout)="onFocusOut($event)"
  [required]="required"
  [matAutocomplete]="auto"
  (keydown.arrowup)="(enableArrowKeyInput)"
  (keydown.arrowdown)="(enableArrowKeyInput)"
  (wheel)="(enableScrollInput)"
/>

<!-- {{ focused }} -->
<!-- {{ touched }} -->
<!-- {{ empty }} -->
<!-- {{ shouldLabelFloat }} -->

<mat-autocomplete #auto="matAutocomplete">
  <mat-option
    *ngFor="
      let autocompleteOption of autocompleteOptions;
      trackBy: trackByLabel
    "
    [value]="autocompleteOption.value"
  >
    {{ autocompleteOption.label }}
  </mat-option>
</mat-autocomplete>
