import { LONGTASKTYPE } from '../long-task/long-task';
import { TASKTYPE } from '../smart-task/smart-task';
import { Claims, ClaimsRole } from './claims';

/**
 * A record of permissions
 */
export type Perms = {
  [permKey in PermKey]?: PermActionsString;
};

/**
 * A key for a specific permission
 */
export enum PermKey {
  'INTERNAL_USERS' = 'iuser',
  //Callables in CRM
  'AUTH_ADMIN' = 'aa',
  'ONETOUCH_ADMIN' = 'oa',
  'EXTRACTION_ADMIN' = 'ea',
  // smart-tasks
  'DOCUMENT_REQUEST_TASKS' = 't.DR',
  'DOCUMENT_CLASSIFICATION_TASKS' = 't.DC',
  'INFORMATION_REQUEST_TASKS' = 't.IR',
  'FINAL_TAXCASE_REVIEW_TASKS' = 't.FTR',
  'FIX_FINANCE_DEPARTMENT_ID' = 't.FFDID',
  'TAXNOTICE_VERIFICATION' = 't.TNV',
  'TAXNOTICE_APPEAL' = 't.TNA',
  'TAXNOTCE_SUBMIT_APPEAL' = 't.TNSA',
  'TAXNOTICE_MANUAL_PROCESS' = 't.TNMP',
  'DOCUMENT_TO_CLIENT_ASSIGNMENT' = 't.DTCA',
  'JOBGROUP_ASSIGNMENT' = 't.JGAM',
  'JOBGROUP_OPTIMIZATION' = 't.JGO',
  'PAYOUT_IBAN_NO_MATCH' = 't.PINM',
  'PAYOUT_BATCH_MERGER' = 't.PBM',
  'EXTRACT_TAXOFFICE_REQUEST_CONTENT' = 't.ETRC',
  'ASSIGN_TAXOFFICE_REMINDER' = 't.ATR',
  'EXTRACT_TAXOFFICE_INFORMATION_CONTENT' = 't.ETIC',
  'TAG_BINARY_DOCUMENT' = 't.TBD',
  'TAXNOTICE_INFORM_BACKPAYER' = 't.TIBP',
  'CLIENT_TODO_HELP_GENERIC' = 't.CTHG',
  'CLIENT_TODO_HELP_TAXID' = 't.CTHTI',
  'CLIENT_TODO_HELP_IDENTIFYINGDOCUMENT' = 't.CTHID',
  'CLIENT_TODO_HELP_VMA_PROOF' = 't.CTHVP',
  'FAILING_VM_REQUEST' = 't.CTH',
  'TAXCASE_OPTIMIZATION' = 't.TCO',
  // <-NEW-TASK-PLACEHOLDER-MARKER::PermKey->
  'OTHER_TASKS' = 't.OT',
  'ALL_TASKS' = 't.all',
  // long-tasks
  'TAXOFFICE_DEADLINE' = 'lt.TD',
  'ALL_LONG_TASKS' = 'lt.all',
}

export function isPermKey(input: unknown): input is PermKey {
  return Object.values(PermKey).includes(input as PermKey);
}

const PermKeyToTaskType: { [permKey in PermKey]?: TASKTYPE | null } = {
  [PermKey.INTERNAL_USERS]: null,
  [PermKey.AUTH_ADMIN]: null,
  [PermKey.ONETOUCH_ADMIN]: null,
  [PermKey.DOCUMENT_REQUEST_TASKS]: TASKTYPE.DOCUMENT_REQUEST,
  [PermKey.DOCUMENT_CLASSIFICATION_TASKS]: TASKTYPE.DOCUMENT_CLASSIFICATION,
  [PermKey.INFORMATION_REQUEST_TASKS]: TASKTYPE.INFORMATION_REQUEST,
  [PermKey.FINAL_TAXCASE_REVIEW_TASKS]: TASKTYPE.FINAL_TAXCASE_REVIEW,
  [PermKey.FIX_FINANCE_DEPARTMENT_ID]: TASKTYPE.FIX_FINANCE_DEPARTMENT_ID,
  [PermKey.TAXNOTICE_VERIFICATION]: TASKTYPE.TAXNOTICE_VERIFICATION,
  [PermKey.TAXNOTICE_APPEAL]: TASKTYPE.TAXNOTCE_APPEAL,
  [PermKey.TAXNOTCE_SUBMIT_APPEAL]: TASKTYPE.TAXNOTCE_SUBMIT_APPEAL,
  [PermKey.DOCUMENT_TO_CLIENT_ASSIGNMENT]:
    TASKTYPE.DOCUMENT_TO_CLIENT_ASSIGNMENT,
  [PermKey.TAXNOTICE_MANUAL_PROCESS]: TASKTYPE.TAXNOTICE_MANUAL_PROCESS,
  [PermKey.JOBGROUP_ASSIGNMENT]: TASKTYPE.JOBGROUP_ASSIGNMENT,
  [PermKey.JOBGROUP_OPTIMIZATION]: TASKTYPE.JOBGROUP_OPTIMIZATION,
  [PermKey.PAYOUT_IBAN_NO_MATCH]: TASKTYPE.PAYOUT_IBAN_NO_MATCH,
  [PermKey.PAYOUT_BATCH_MERGER]: TASKTYPE.PAYOUT_BATCH_MERGER,
  [PermKey.EXTRACT_TAXOFFICE_REQUEST_CONTENT]:
    TASKTYPE.EXTRACT_TAXOFFICE_REQUEST_CONTENT,
  [PermKey.EXTRACT_TAXOFFICE_INFORMATION_CONTENT]:
    TASKTYPE.EXTRACT_TAXOFFICE_INFORMATION_CONTENT,
  [PermKey.ASSIGN_TAXOFFICE_REMINDER]: TASKTYPE.ASSIGN_TAXOFFICE_REMINDER,
  [PermKey.TAG_BINARY_DOCUMENT]: TASKTYPE.TAG_BINARY_DOCUMENT,
  [PermKey.TAXNOTICE_INFORM_BACKPAYER]: TASKTYPE.TAXNOTICE_INFORM_BACKPAYER,
  [PermKey.CLIENT_TODO_HELP_GENERIC]: TASKTYPE.CLIENT_TODO_HELP_GENERIC,
  [PermKey.CLIENT_TODO_HELP_TAXID]: TASKTYPE.CLIENT_TODO_HELP_TAXID,
  [PermKey.CLIENT_TODO_HELP_IDENTIFYINGDOCUMENT]:
    TASKTYPE.CLIENT_TODO_HELP_IDENTIFYINGDOCUMENT,
  [PermKey.CLIENT_TODO_HELP_VMA_PROOF]: TASKTYPE.CLIENT_TODO_HELP_VMA_PROOF,
  [PermKey.FAILING_VM_REQUEST]: TASKTYPE.FAILING_VM_REQUEST,
  [PermKey.TAXCASE_OPTIMIZATION]: TASKTYPE.TAXCASE_OPTIMIZATION,
  // <-NEW-TASK-PLACEHOLDER-MARKER::PermKeyToTaskType->
  [PermKey.OTHER_TASKS]: TASKTYPE.OTHER,
  [PermKey.ALL_TASKS]: null,
};
export function taskTypeFromPermKey(permKey: PermKey): TASKTYPE | null {
  return PermKeyToTaskType[permKey] || null;
}

const PermKeyToLongTaskType: { [permKey in PermKey]?: LONGTASKTYPE | null } = {
  [PermKey.TAXOFFICE_DEADLINE]: null,
};
export function longTaskTypeFromPermKey(permKey: PermKey): LONGTASKTYPE | null {
  return PermKeyToLongTaskType[permKey] || null;
}

/**
 * A set of allowed permission actions
 */
export type PermActionsString = `${PermActionKey.GET | ''}${
  | PermActionKey.LIST
  | ''}${PermActionKey.CREATE | ''}${PermActionKey.UPDATE | ''}${
  | PermActionKey.DELETE
  | ''}${PermActionKey.EXECUTE | ''}`;

/**
 * A key describing an allowed permission action
 */
export enum PermActionKey {
  GET = 'g',
  LIST = 'l',
  CREATE = 'c',
  UPDATE = 'u',
  DELETE = 'd',
  EXECUTE = 'x',
}

// ############################################################################
// parsed representations:
// @example ```'glx' <==> {canGet:true, canList:true, canExecute:true}```

/**
 * A PermActions representation in {object} form
 */
export abstract class PermActionsParsed {
  abstract readonly canExecute: boolean;
  abstract readonly canCreate: boolean;
  abstract readonly canGet: boolean;
  abstract readonly canList: boolean;
  abstract readonly canUpdate: boolean;
  abstract readonly canDelete: boolean;

  static fromPermActionsString(
    permission: PermActionsString
  ): PermActionsParsed {
    const canExecute = permission.includes('x');
    const canCreate = permission.includes('c');
    const canGet = permission.includes('g');
    const canList = permission.includes('l');
    const canUpdate = permission.includes('u');
    const canDelete = permission.includes('d');
    return { canExecute, canCreate, canGet, canList, canUpdate, canDelete };
  }
}

/**
 * A Perms representation in {object} from
 */
export type PermsParsed = {
  [shortKey in PermKey]?: PermActionsParsed;
};

/**
 * A Claims representation in {object} form
 */
export abstract class ClaimsParsed {
  abstract role: ClaimsRole;
  abstract perms: PermsParsed;

  static fromClaims(claims: Partial<Claims>): ClaimsParsed {
    const role = claims.role || '';
    const perms = this.permsToPermsParsed(claims.perms || {});
    return { role, perms };
  }

  private static permsToPermsParsed(perms: Perms): PermsParsed {
    return Object.entries<PermActionsString>(perms)
      .filter((entries): entries is [PermKey, PermActionsString] => {
        const ispermkey = isPermKey(entries[0]);
        return ispermkey;
      })
      .reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]: PermActionsParsed.fromPermActionsString(value),
        };
      }, {});
  }
}
