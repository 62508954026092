/* eslint-disable */

export interface VaSt_LStB {
  VaSt_LStB: VaSt_LStB_CType;
}

export interface VaSt_LStB_CType {
  /**
   * Name des Arbeitgebers
   */
  ArbGName?: string;
  /**
   * Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung
   */
  Eingangsdatum?: string;
  Allgemein?: Allgemein_491074567_CType;
  Dauer?: Dauer_491074567_CType;
  Besteuerungsmerkmal?: Besteuerungsmerkmal_491074567_CType;
  Besteuerungsgrund?: Besteuerungsgrund_491074567_CType;
  version: string;
}

export interface Allgemein_491074567_CType {
  /**
   * eTIN
   */
  eTIN?: string;
  /**
   * Identifikationsnummer
   */
  IdNr?: string;
}

export interface Dauer_491074567_CType {
  /**
   * Beschäftigungsjahr
   */
  Jahr?: string;
  /**
   * vom
   */
  Anfang?: string;
  /**
   * bis
   */
  Ende?: string;
}
export interface Besteuerungsmerkmal_491074567_CType {
  /**
   * @maxItems 12
   */
  ELStAM?: ELStAM_2071614152_CType | ELStAM_2071614152_CType[];
}

export interface ELStAM_2071614152_CType {
  /**
   * Besteuerungsmerkmale gültig ab
   */
  GueltigAb?: string;
  /**
   * eingetragener Steuerfreibetrag
   */
  Steuerfreibetrag?: string;
  /**
   * eingetragener Hinzurechnungsbetrag
   */
  Hinzurechnungsbetrag?: string;
  Steuerklasse?: SteuerklasseM1012334086_CType;
  Kirchensteuerabzug?: KirchensteuerabzugM1012334086_CType;
}

export interface SteuerklasseM1012334086_CType {
  /**
   * Steuerklasse
   */
  Klasse?: '0' | '1' | '2' | '3' | '4' | '5' | '6';
  /**
   * Faktor
   */
  Faktor?: string;
}

export interface KirchensteuerabzugM1012334086_CType {
  /**
   * Kirchensteuermerkmal (Konfession)
   */
  Konfession?: Konfessions;

  /**
   * Kirchensteuermerkmal (Konfession) des Ehegatten
   */
  Ehegkonfession?: Konfessions;
}

export interface Besteuerungsgrund_491074567_CType {
  /**
   * Zeiträume ohne Anspruch auf Arbeitslohn (Anzahl U)
   */
  AnzahlU?: string;
  /**
   * bescheinigter Großbuchstabe
   */
  Grossbuchstaben?: string;
  /**
   * Bruttoarbeitslohn
   */
  BruttoArbLohn?: string;
  /**
   * einbehaltene Lohnsteuer
   */
  LSteuer?: string;
  /**
   * einbehaltener Solidaritätszuschlag
   */
  Soli?: string;
  /**
   * einbehaltene Kirchensteuer des Arbeitnehmers
   */
  ArbnKiSteuer?: string;
  /**
   * einbehaltene Kirchensteuer des Ehegatten
   */
  EhegKiSteuer?: string;
  /**
   * ermäßigt besteuerter Arbeitslohn für mehrere Kalenderjahre und ermäßigt besteuerte Entschädigungen
   */
  ErmStBetrMKalJahr?: string;
  /**
   * einbehaltene Lohnsteuer bei ermäßigt besteuertem Arbeitslohn
   */
  LSteuerMKalJahr?: string;
  /**
   * einbehaltener Solidaritätszuschlag bei ermäßigt besteuertem Arbeitslohn
   */
  SoliMKalJahr?: string;
  /**
   * einbehaltene Kirchensteuer des Arbeitnehmers bei ermäßigt besteuertem Arbeitslohn
   */
  KiSteuerArbnMKalJahr?: string;
  /**
   * einbehaltene Kirchensteuer des Ehegatten bei ermäßigt besteuertem Arbeitslohn
   */
  KiSteuerEhegMKalJahr?: string;
  /**
   * steuerpflichtige Entschädigungen und Arbeitslohn für mehrere Kalenderjahre, die nicht ermäßigt besteuert wurden
   */
  StPflichtArbLohnMKalJahr?: string;
  /**
   * Kurzarbeitergeld, Zuschuss zum Mutterschaftsgeld, Verdienstausfallentschädigung (Infektionsschutzgesetz), Aufstockungsbetrag und Altersteilzeitzuschlag
   */
  KurzArbGeld?: string;
  /**
   * steuerfreier Arbeitslohn nach Doppelbesteuerungsabkommen
   */
  StFreiArbLohnDBA?: string;
  /**
   * steuerfreier Arbeitslohn nach Auslandstätigkeitserlass
   */
  StFreiArbLohnATE?: string;
  /**
   * Arbeitgeberanteil zur gesetzlichen Rentenversicherung
   */
  ArbgAnteilRenVers?: string;
  /**
   * Arbeitgeberanteil an berufsständische Versorgungseinrichtungen
   */
  ArbgAnteilBerufsVers?: string;
  /**
   * Arbeitnehmeranteil zur gesetzlichen Rentenversicherung
   */
  ArbnAnteilRenVers?: string;
  /**
   * Arbeitnehmeranteil an berufsständische Versorgungseinrichtungen
   */
  ArbnAnteilBerufsVers?: string;
  /**
   * steuerfreie Arbeitgeberzuschüsse zur gesetzlichen Krankenversicherung
   */
  StFreiGeKrankVers?: string;
  /**
   * steuerfreie Arbeitgeberzuschüsse zur privaten Krankenversicherung
   */
  StFreiPrKrankVers?: string;
  /**
   * steuerfreie Arbeitgeberzuschüsse zur gesetzlichen Pflegeversicherung
   */
  StFreiGePflegVers?: string;
  /**
   * Arbeitnehmerbeiträge zur gesetzlichen Krankenversicherung
   */
  ArbnAnteilKrankVers?: string;
  /**
   * Arbeitnehmerbeiträge zur sozialen Pflegeversicherung
   */
  ArbnAnteilPflegVers?: string;
  /**
   * Arbeitnehmerbeiträge zur Arbeitslosenversicherung
   */
  ArbnAnteilArblVers?: string;
  /**
   * nachgewiesene Beiträge zur privaten Krankenversicherung und Pflege-Pflichtversicherung
   */
  BeitrPrKrankVers?: string;
  /**
   * Arbeitnehmerbeitrag zur Winterbeschäftigungs-Umlage
   */
  ArbnAnteilWBUmlage?: string;
  /**
   * steuerpflichtiger Beitrag zur Zusatzversorgung
   */
  ArbgAnteilZusatzVers?: string;
  /**
   * Arbeitnehmeranteil zur Zusatzversorgung
   */
  ArbnAnteilZusatzVers?: string;
  /**
   * Anzahl Arbeitstage
   */
  AnzahlArbTag?: string;
  /**
   * steuerfreie Arbeitgeberleistungen für Fahrten zwischen Wohnung und Arbeitsstätte
   */
  StFreiArbgLeistg?: string;
  /**
   * pauschal besteuerte Arbeitgeberleistungen für Fahrten zwischen Wohnung und Arbeitsstätte
   */
  PauschArbgLeistg?: string;
  /**
   * steuerfreie Arbeitgeberleistungen für Fahrtkosten bei Auswärtstätigkeit
   */
  StFreiFahrtKAusw?: string;
  /**
   * steuerfreier Arbeitgeberzuschuss für Verpflegung bei Auswärtstätigkeit
   */
  StFreiVerpfleg?: string;
  /**
   * steuerfreie Arbeitgeberleistungen bei doppelter Haushaltsführung
   */
  StFreiDopHaushalt?: string;
  /**
   * Freibetrag DBA Türkei
   */
  FreibetragDbaTuerkei?: string;
  /**
   * @maxItems 12
   */
  VBez?: VBezM1083715281_CType | VBezM1083715281_CType[];
  /**
   * @maxItems 12
   */
  ErmStVBezMKalJahr?:
    | ErmStVBezMKalJahrM1083715281_CType
    | ErmStVBezMKalJahrM1083715281_CType[];
  /**
   * @maxItems 12
   */
  NErmStVBezMKalJahr?:
    | NErmStVBezMKalJahrM1083715281_CType
    | NErmStVBezMKalJahrM1083715281_CType[];
  /**
   * @maxItems 2
   */
  Kammerbeitrag?:
    | KammerbeitragM1083715281_CType
    | KammerbeitragM1083715281_CType[];
}

export interface VBezM1083715281_CType {
  /**
   * steuerbegünstigte Versorgungsbezüge (im Bruttoarbeitslohn enthalten)
   */
  Wert?: string;
  /**
   * bei unterjähriger Zahlung: erster Monat, für den Versorgungsbezug gezahlt wurde
   */
  Beginn?: string;
  /**
   * bei unterjähriger Zahlung: letzter Monat, für den Versorgungsbezug gezahlt wurde
   */
  Ende?: string;
  /**
   * maßgebendes Kalenderjahr des Versorgungsbeginns
   */
  Jahr?: string;
  /**
   * Bemessungsgrundlage für Versorgungsfreibetrag
   */
  BmgFreibetrag?: string;
  /**
   * einmalige Versorgungsbezüge (Sterbegeld, Kapitalauszahlungen, Abfindungen und Nachzahlungen - in steuerbegünstigten Versorgungsbezügen enthalten)
   */
  Einmversbezug?: string;
}

export interface ErmStVBezMKalJahrM1083715281_CType {
  /**
   * ermäßigt besteuerte Versorgungsbezüge für mehrere Kalenderjahre
   */
  Wert?: string;
  /**
   * maßgebendes Kalenderjahr des Versorgungsbeginns bei ermäßigt besteuerten Versorgungsbezügen
   */
  Jahr?: string;
}

export interface NErmStVBezMKalJahrM1083715281_CType {
  /**
   * Versorgungsbezüge für mehrere Kalenderjahre, die nicht ermäßigt besteuert wurden
   */
  Wert?: string;
  /**
   * maßgebendes Kalenderjahr bei Versorgungsbezügen für mehrere Kalenderjahre, die nicht ermäßigt besteuert wurden
   */
  Jahr?: string;
}

export interface KammerbeitragM1083715281_CType {
  /**
   * Kammerbeitrag
   */
  Wert?: string;
  /**
   * Kammerbeitrag/maßgebendes Bundesland
   */
  Bundesland?: string;
}

export type Konfessions =
  | 'ak'
  | 'ev'
  | 'lt'
  | 'rf'
  | 'fr'
  | 'fa'
  | 'fm'
  | 'fs'
  | 'fb'
  | 'fg'
  | 'ib'
  | 'iw'
  | 'is'
  | 'jd'
  | 'jh'
  | 'il'
  | '--'
  | 'na'
  | 'rk';
