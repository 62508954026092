import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TaxcaseCommentComponent } from './taxcase-comment/taxcase-comment.component';
import { TaxcaseCommentsComponent } from './taxcase-comments.component';

@NgModule({
  declarations: [TaxcaseCommentsComponent, TaxcaseCommentComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
  ],
  exports: [TaxcaseCommentsComponent],
})
export class EsTaxcaseCommentsModule {}
