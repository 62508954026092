<div class="taxoffice-email-composer">
  <ng-container *ngIf="!isVisible">
    <ng-container *ngIf="!disabled; else disabledButton">
      <button mat-raised-button color="primary" (click)="setVisible.emit()">
        E-Mail an Finanzamt verfassen
      </button>
    </ng-container>
    <ng-template #disabledButton>
      <button
        mat-raised-button
        color="primary"
        disabled
        matTooltip="Die Tags für alle Inhalte müssen zuerst ausgefüllt werden"
      >
        E-Mail an Finanzamt verfassen
      </button>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="isVisible">
    <div class="taxoffice-email-composer-header">
      <h4>E-Mail an Finanzamt verfassen</h4>
      <div class="taxoffice-email-composer-buttons">
        <div
          *ngIf="result?.success === true"
          class="taxoffice-email-composer-success"
        >
          E-Mail erfolgreich gesendet! 🎉📧
        </div>
        <div
          *ngIf="result?.success === false"
          class="taxoffice-email-composer-error"
        >
          Fehler: {{ result?.error }} - {{ result?.errorMessage }} 🔥
          <br />
          Bitte kontaktieren Sie den IT-Support mit der URL und einem Screenshot
        </div>
        <button
          [disabled]="disabled || loading"
          mat-raised-button
          color="primary"
          (click)="sendEmail()"
          class="mr-4"
        >
          E-Mail senden und in der Historie speichern
        </button>
        <button mat-raised-button (click)="doClose.emit()">Verwerfen</button>
      </div>
    </div>
    <div class="taxoffice-email-composer-info">
      <b>Anhänge:</b> Wenn Dokumente ausgewählt sind, wird ein Zip-Archiv
      erstellt und der E-Mail angehängt.
    </div>
    <form class="taxoffice-email-composer-form">
      <div class="loader" *ngIf="loading">
        <esui-spinner></esui-spinner>
      </div>
      <div class="flex-row">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Von</mat-label>
          <mat-select
            [formControl]="senderControl"
            (selectionChange)="changeSender($event.value)"
          >
            <mat-option
              *ngFor="let sender of this.availableSenders ?? []"
              [value]="sender.email"
              >{{ sender.id }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>An</mat-label>
          <input
            matInput
            required
            [formControl]="receiverControl"
            type="text"
            placeholder="E-Mail-Adresse des Finanzamts"
            (change)="changeReceiver($event)"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>BCC</mat-label>
          <input
            matInput
            [formControl]="ccControl"
            type="text"
            placeholder="BCC"
            (change)="changeCc($event)"
          />
        </mat-form-field>
      </div>
      <mat-form-field appearance="fill">
        <mat-label>Betreff</mat-label>
        <input
          matInput
          [required]="true"
          [formControl]="subjectControl"
          type="text"
          placeholder="... Betreff"
          (change)="changeSubject($event)"
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>E-Mail-Text</mat-label>
        <textarea
          matInput
          [required]="true"
          [formControl]="bodyControl"
          type="text"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          placeholder="... E-Mail-Text"
          (change)="changeBody($event)"
        ></textarea>
      </mat-form-field>
    </form>
  </ng-container>
</div>
