export function scrollToElement(element?: HTMLElement) {
  const scrollOptions: ScrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'center',
  };

  if (typeof (element as any)?.scrollIntoViewIfNeeded === 'function') {
    // webkit browsers support non standard `scrollIntoViewIfNeeded`
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded
    (element as any).scrollIntoViewIfNeeded(scrollOptions);
    return;
  }
  element?.scrollIntoView(scrollOptions);
}
