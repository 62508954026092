<esui-section-column-scaffold
  [header]="{
    title: 'partners'
  }"
>
  <ng-container content>
    <mat-selection-list
      [multiple]="false"
      [(ngModel)]="activePartners"
      (ngModelChange)="activePartnersChange.next($event)"
      [compareWith]="comparePartners"
    >
      <ng-container *ngFor="let partner of partners; trackBy: trackById">
        <mat-list-option [value]="partner" @animateDestroy>
          <mat-icon mat-list-icon> people_outline </mat-icon>
          <span matLine>{{ partner.firstName }} {{ partner.lastName }}</span>
          <pre matLine>{{ partner.partnerId }}</pre>
          <span matLine>
            <span class="tags">
              <span *ngFor="let year of partner.years">{{ year }}</span>
            </span>
          </span>
        </mat-list-option>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-selection-list>
  </ng-container>
</esui-section-column-scaffold>
