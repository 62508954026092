import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TaxcaseListOldComponent } from './taxcase-list-old.component';

@NgModule({
  declarations: [TaxcaseListOldComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatDividerModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
  ],
  exports: [TaxcaseListOldComponent, FormsModule],
})
export class EsTaxcaseListOldModule {}
