import { Timestamp } from '../../helpers/timestamp';
import {
  GOVERNENTALAIDTYPE,
  IGovermentalAid,
} from '../../interfaces/govermentalAid';

/**
 * @description A Govermental Aid is money from the goverment.
 * * valid expense types
 *  - unemployment
 *  - sickness
 *  - lifeAnnuity
 *  - parental
 *  - mother
 *  - alimony
 *  - insolvency
 *  - shorttimework
 */

export class GovermentalAid implements IGovermentalAid {
  public static getTemplate(
    category: GOVERNENTALAIDTYPE = GOVERNENTALAIDTYPE.UNEMPLOYMENT,
    value: number | null = null,
    description = 'undeclared'
  ): GovermentalAid {
    return {
      description: description,
      category: category,
      value: value,
      startDate: null,
      endDate: null,
      active: false,
      isCalculated: false,
    };
  }

  /**
   * @description Arbeitslosengeld = unemployment, Krankengeld = sickness,Leibrente = lifeAnnuity, Elterngeld = parental, Muttergeld = motherUnterhalt = alimony, Insolvenzgeld = insolvency, Kurzarbeitsgeld = shorttimework
   */
  category: GOVERNENTALAIDTYPE = GOVERNENTALAIDTYPE.UNEMPLOYMENT;
  description = '';
  /**
   * The full value recieved from the goverment during the specified time
   */
  value: number | null = null;
  /**
   * start of recieving aid. must be withing the year of reimbursement. If null, fullyear
   */
  startDate: Timestamp | null = null;
  /**
   * end of recieving aid. must be withing the year of reimbursement. If null, fullyear
   */
  endDate: Timestamp | null = null;
  /**
   * This flaw is used in the web ui. A client can set a gov but deactivate and the values are still saved
   */
  active = true;

  /**
   * Calculated values are only there during internal calculations and will be removed before starting a new Calculation
   */
  isCalculated = false;
}

export const PREDEFINEDAIDS: { [key: string]: GovermentalAid } = {
  Insolvensgeld: quickCreate('Insolvensgeld', GOVERNENTALAIDTYPE.INSOLVENCY),
  Mutterschaftsgeld: quickCreate(
    'Mutterschaftsgeld',
    GOVERNENTALAIDTYPE.MOTHER
  ),
  Elterngeld: quickCreate('Elterngeld', GOVERNENTALAIDTYPE.PARENTAL),
  Kurzarbeitsgeld: quickCreate(
    'Kurzarbeitsgeld',
    GOVERNENTALAIDTYPE.SHORTTIMEWORK
  ),
  Krankengeld: quickCreate('Krankengeld', GOVERNENTALAIDTYPE.SICKNESS),
  Arbeitslosengeld: quickCreate(
    'Arbeitslosengeld',
    GOVERNENTALAIDTYPE.UNEMPLOYMENT
  ),
};

function quickCreate(
  description: string,
  category: GOVERNENTALAIDTYPE
): GovermentalAid {
  const govermentalAid = GovermentalAid.getTemplate(
    category,
    null,
    description
  );
  govermentalAid.startDate = null;
  govermentalAid.endDate = null;
  return govermentalAid;
}
