import { Timestamp } from '@google-cloud/firestore';

/**
 * Represents an authorization
 */
export interface Authorization {
  /**
   * when we recieved or created the authorization
   */
  created: Timestamp | null;
  /**
   * if the authorization is deactivated and when
   */
  deactivated?: Timestamp | null;
  /**
   * If the authorization is set and active
   */
  active: boolean;
  /**
   * When has the authorization been activated
   */
  active_since?: Timestamp | null;
  /**
   * If the authorization has a time limit
   */
  active_until?: Timestamp | null;

  /**
   * If an error exists, we note it here
   */
  hasError?: boolean;
  /**
   * The message within Vmdb, the message is noted here
   */
  message?: string;

  /**
   * instance number on which vmdb instance the record is stored
   */
  instanceNbr?: number | null;
  /**
   * last time this record has been updated
   */
  lastUpdated?: Timestamp | null;
}
export interface ClientAuthorizations {
  vollmachtsDB: Authorization;
}
