<ng-container *ngIf="task">
  <mat-card
    [ngClass]="{
      transparentCard: transparentCard
    }"
  >
    <span esuiCardKey
      ><mat-icon
        *ngIf="typeDescription as tDesc"
        [matTooltip]="tDesc"
        inline="true"
        class="helpIcon"
        >help_outline</mat-icon
      >
    </span>
    <mat-card-header>
      <button
        type="button"
        mat-card-avatar
        mat-icon-button
        aria-label="task.done ? 'done' : 'not-done'"
        title="set done state"
        (click)="doneStateClick.next()"
      >
        <mat-icon>{{
          task.done ? 'task_alt' : 'radio_button_unchecked'
        }}</mat-icon>
      </button>
      <mat-card-title>{{ task.title }}</mat-card-title>
      <mat-card-subtitle>{{ typeLabel }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <h4>description:</h4>
      <p>
        {{ task.description || '--' }}
      </p>

      <mat-chip-list aria-label="additional info" selectable="false">
        <mat-chip
          color="accent"
          *ngFor="let taxcaseId of task.taxcaseIds ?? []"
          disableRipple="true"
          >{{ taxcaseId }}</mat-chip
        >
        <mat-chip *ngIf="task.deactivatableByAutomation" disableRipple="true"
          >part of automation</mat-chip
        >
        <mat-chip *ngIf="task.standalone" disableRipple="true"
          >standalone</mat-chip
        >
        <mat-chip color="primary" *ngIf="task.deactivated" disableRipple="true"
          >deactivated</mat-chip
        >
      </mat-chip-list>

      <!-- <h4>deactivatableByAutomation</h4>
      <p>
        {{ task.deactivatableByAutomation || '--' }}
      </p>
      <h4>standalone</h4>
      <p>
        {{ task.standalone || '--' }}
      </p>
      <h4>attachedDocuments</h4>
      <p>
        {{ task.attachedDocuments || '--' }}
      </p>
      <h4>deactivated</h4>
      <p>
        {{ task.deactivated || '--' }}
      </p>
      <h4>taxcaseIds</h4>
      <p>
        {{ task.taxcaseIds || '--' }}
      </p>
      <h4>taxcaseSpecificOptions</h4>
      <p>
        {{ task.taxcaseSpecificOptions || '--' }}
      </p>
      <h4>clientSpecificOptions</h4>
      <p>
        {{ task.clientSpecificOptions || '--' }}
      </p>
      <h4>clientId</h4>
      <p>
        {{ task.clientId || '--' }}
      </p> -->
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-button
        (click)="escalate()"
        (blur)="cancelEscalate()"
        (mouseleave)="cancelEscalate()"
        [color]="!escalateVerificationNeeded ? 'warn' : 'primary'"
        [disabled]="task.escalationLevel > 0"
      >
        <ng-container *ngIf="task.escalationLevel > 0"
          >escalated (level {{ task.escalationLevel }})</ng-container
        >

        <ng-container *ngIf="!task.escalationLevel">
          <span *ngIf="escalateVerificationNeeded"> escalate </span>
          <span *ngIf="!escalateVerificationNeeded">
            click again to verify escalation
          </span>
        </ng-container>
      </button>
      <esui-anchor-menu
        [clientId]="task.clientId"
        [taxcaseIds]="task.taxcaseIds"
        [pathToTask]="pathToTask"
      ></esui-anchor-menu>
    </mat-card-actions>
    <esui-stats-for-nerds [data]="task" label="task"></esui-stats-for-nerds>
  </mat-card>
</ng-container>
