import { SchemaProperty } from './vast';

export const VastRieSchema: SchemaProperty = {
  title: 'VaSt_RIE-202201.xsd',
  $schema: 'http://json-schema.org/schema#',

  type: 'object',
  properties: {
    VaSt_RIE: {
      type: 'object',
      description: 'Riester-Rente',
      properties: {
        Eingangsdatum: {
          description:
            'Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung',
          type: 'string',
        },
        BetroffenerFall: {
          type: 'object',
          properties: {
            Meldejahr: {
              description: 'Meldejahr',
              type: 'string',
            },
            NatPerson: {
              type: 'object',
              properties: {
                IdNr: {
                  description: 'Identifikationsnummer',
                  type: 'string',
                },
                Titel: {
                  description: 'Titel',
                  type: 'string',
                },
                Vorname: {
                  description: 'Vorname',
                  type: 'string',
                },
                NamensVorsatz: {
                  description: 'Namensvorsatz',
                  type: 'string',
                },
                Name: {
                  description: 'Name',
                  type: 'string',
                },
                NamensZusatz: {
                  description: 'Namenszusatz',
                  type: 'string',
                },
                Blockname: {
                  description: 'Name',
                  type: 'string',
                },
              },
            },
          },
        },
        Versicherung: {
          type: 'object',
          properties: {
            Anbieternummer: {
              description: 'Anbieternummer',
              type: 'string',
            },
            Name: {
              description: 'Name der Versicherung',
              type: 'string',
            },
          },
        },
        Vertragsdaten: {
          type: 'object',
          properties: {
            EinwilligDat: {
              description: 'Einwilligungsdatum (zur Datenübermittlung)',
              type: 'string',
            },
            VertragsNr: {
              description: 'Vertragsnummer',
              type: 'string',
            },
            ZertifiNr: {
              description: 'Zertifizierungsnummer',
              type: 'string',
            },
            SozialversicherungsNr: {
              description: 'Sozialversicherungs-/Zulagenummer',
              type: 'string',
            },
            MitgliedsNr: {
              description:
                'Mitgliedsnummer der landwirtschaftlichen Alterskasse',
              type: 'string',
            },
            SteuerlBerueckAV: {
              description: 'Eine steuerliche Berücksichtigung ist beabsichtigt',
              type: 'string',
            },
            BeitragsDaten: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  BetragArt: {
                    description: 'Beitragsart',
                    type: 'string',
                  },
                  Betrag: {
                    description: 'Höhe der Beiträge/Tilgungsleistungen',
                    type: 'number',
                    stringifier: 'currency-eur',
                  },
                },
              },
            },
          },
        },
        version: {
          type: 'string',
          enum: ['202201'],
        },
      },
      required: ['version'],
    },
  },
  required: ['VaSt_RIE'],
};
