import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  GetAuthInfoInput,
  GetAuthInfoOutput,
} from '@expresssteuer/client-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class GetAuthInfo {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: GetAuthInfoInput) {
    console.log('Calling client-getAuthInfo');
    return this.afFunctions.httpsCallable<GetAuthInfoInput, GetAuthInfoOutput>(
      'client-getAuthInfo'
    )(input);
  }
}
