<ng-container *ngIf="financeOffice">
  <p>
    <a [href]="financeOffice.bundeszentralamtUrl" target="_blank">
      Finanzamt {{ financeOffice.name }} - {{ financeOffice.buFaNr }} </a
    >, Telefon:
    <a *ngIf="financeOffice.tel" [href]="'tel:' + financeOffice.tel">{{
      financeOffice.tel
    }}</a>
    <span *ngIf="!financeOffice.tel">-</span>, Email:
    <a *ngIf="financeOffice.mail" [href]="'mailto:' + financeOffice.mail">{{
      financeOffice.mail
    }}</a>
    <span *ngIf="!financeOffice.mail">-</span>
  </p>
</ng-container>
