import { Component, Input, OnInit } from '@angular/core';
import { MESSAGESTATUS } from '@expresssteuer/models';

@Component({
  selector: 'app-control-message-twilio-status',
  templateUrl: './control-message-twilio-status.component.html',
  styleUrls: ['./control-message-twilio-status.component.scss'],
})
export class ControlMessageTwilioStatusComponent implements OnInit {
  constructor() {}

  MESSAGESTATUS = MESSAGESTATUS;
  private mStatus: MESSAGESTATUS;
  public color = 'label-danger';

  @Input()
  public set status(val: MESSAGESTATUS) {
    switch (val) {
      case MESSAGESTATUS.QUENUE:
      case MESSAGESTATUS.SENT:
        this.color = 'lable-warning-light';
        break;
      case MESSAGESTATUS.DELIVERED:
      case MESSAGESTATUS.READ:
      case MESSAGESTATUS.RECIEVED:
        this.color = 'lable-success-light';
        break;
      default:
        this.color = 'label-danger';
    }
    this.mStatus = val;
  }

  public get status(): MESSAGESTATUS {
    return this.mStatus;
  }

  ngOnInit(): void {}
}
