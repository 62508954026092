import {
  HealthInsurance,
  NursingInsurance,
  PensionInsurance,
  States,
  StateType,
  UnemploymentInsurance,
} from '../data/data';
import { State } from '../state/state';

export class Flats {
  public advertisingFlat: number = 1200; // Werbungskostenpauschale 1000
  public specialExpensesFlat: number = 36; // Sonderausgabenpauschale 36
  public relocationFlat: number = 0; // Umzugspauschale 787
  public relocationFlatChild: number = 0; // Umzugspauschale pro Kind 347
  public childFlat: number = 8388; // Kinderfreibetrag 7428
  public taxFreePension: number = 0;
  public offsiteFlat = 14.0; // Offsite Flat
  public offsiteFlat24Hours = 28.0; // Offise Flat
  public homeOfficeFlatPerDay = 5; // HomeOffice Pauschale pro Tag
  public homeOfficeMaxFlat = 600; // Maximale Homeoffice Pauschale pro Jahr
  public privateInsuranceLimit = 5362.5; // Minimum Gehalt bis man privat Versichert sein kann
}

export class ProgressionValue {
  progression!: number;
  progression_1!: {
    progression: number;
    value_1: number;
  };
  progression_2!: {
    progression: number;
    value_1: number;
    value_2: number;
  };
  progression_3!: {
    progression: number;
    value_1: number;
  };

  progression_4!: {
    value_1: number;
  };
}

export class TaxClass5 {
  zone_1!: number;
  zone_2!: number;
  zone_3!: number;
}

export interface Reduction {
  reduction: string;
  value: number;
}
export class DisablementReduction {
  public reductions: Reduction[] = [
    { reduction: '0', value: 0 },
    { reduction: '5', value: 0 },
    { reduction: '10', value: 0 },
    { reduction: '15', value: 0 },
    { reduction: '20', value: 384 },
    { reduction: '25', value: 384 },
    { reduction: '30', value: 620 },
    { reduction: '35', value: 620 },
    { reduction: '40', value: 860 },
    { reduction: '45', value: 860 },
    { reduction: '50', value: 1140 },
    { reduction: '55', value: 1140 },
    { reduction: '60', value: 1440 },
    { reduction: '65', value: 1440 },
    { reduction: '70', value: 1780 },
    { reduction: '75', value: 1780 },
    { reduction: '80', value: 2120 },
    { reduction: '85', value: 2120 },
    { reduction: '90', value: 2460 },
    { reduction: '95', value: 2460 },
    { reduction: '100', value: 2840 },
  ];
  constructor(reductions: Reduction[] = []) {
    if (reductions.length > 0) {
      this.reductions = reductions;
    }
  }

  public getReduction(disablementPercentage: number = 0): number {
    if (disablementPercentage >= 0 && disablementPercentage <= 100) {
      const result = this.reductions.find(
        (r) => r.reduction == disablementPercentage.toString()
      );
      return result!.value;
    } else {
      throw new Error('Disablement percentage must be within 0% and 100%');
    }
  }
}

export class S2022 {
  public static year: number = 2022;
  public static get(): State[] {
    return [
      new State(
        States['Baden-Württemberg'],
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Bayern,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Brandenburg,
        StateType.new,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 77400.0),
        new UnemploymentInsurance(0.012, 77400.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Bremen,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Hamburg,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Berlin,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Hessen,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States['Mecklenburg-Vorpommern'],
        StateType.new,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 77400.0),
        new UnemploymentInsurance(0.012, 77400.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Niedersachsen,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States['Nordrhein-Westfalen'],
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States['Rheinland-Pfalz'],
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Saarland,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Sachsen,
        StateType.new,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 77400.0),
        new UnemploymentInsurance(0.012, 77400.0),
        new NursingInsurance(0.02025, 56250.0),
        [2020]
      ),
      new State(
        States['Sachsen-Anhalt'],
        StateType.new,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 77400.0),
        new UnemploymentInsurance(0.012, 77400.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States['Schleswig-Holstein'],
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Thüringen,
        StateType.new,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 77400.0),
        new UnemploymentInsurance(0.012, 77400.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
    ];
  }

  //https://www.lohn-info.de/beitragsberechnung.html

  public static getDisablement() {
    return new DisablementReduction();
  }

  public static getProgression(): ProgressionValue {
    return {
      progression: 10347.0,
      progression_1: {
        progression: 14926.0,
        value_1: 972.87,
      },
      progression_2: {
        progression: 58596.0,
        value_1: 206.43,
        value_2: 869.32,
      },
      progression_3: {
        progression: 277825.0,
        value_1: 9136.63,
      },
      progression_4: {
        value_1: 17374.99,
      },
    };
  }

  //https://www.bmf-steuerrechner.de/ekst/eingabeformekst.xhtml?ekst-result=true

  public static getTaxClass5(): TaxClass5 {
    return {
      zone_1: 10898,
      zone_2: 28526,
      zone_3: 216400,
    };
    // https://www.buzer.de/gesetz/4499/al82514-0.htm?m=10.635#hit
  }

  public static getFlats(): Flats {
    return {
      childFlat: 8388,
      relocationFlat: 820,
      relocationFlatChild: 361.0,
      specialExpensesFlat: 36.0,
      advertisingFlat: 1200,
      taxFreePension: 0.9,
      offsiteFlat: 14,
      offsiteFlat24Hours: 28,
      homeOfficeFlatPerDay: 5,
      homeOfficeMaxFlat: 600,
      privateInsuranceLimit: 5362.5,
    };
    // https://www.vlh.de/arbeiten-pendeln/beruf/umzugspauschale-erneut-erhoeht.html#
    //
  }
}

export class S2021 {
  public static year: number = 2021;
  public static get(): State[] {
    return [
      new State(
        States['Baden-Württemberg'],
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Bayern,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Brandenburg,
        StateType.new,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 77400.0),
        new UnemploymentInsurance(0.012, 77400.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Bremen,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Hamburg,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Berlin,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Hessen,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States['Mecklenburg-Vorpommern'],
        StateType.new,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 77400.0),
        new UnemploymentInsurance(0.012, 77400.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Niedersachsen,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States['Nordrhein-Westfalen'],
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States['Rheinland-Pfalz'],
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Saarland,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Sachsen,
        StateType.new,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 77400.0),
        new UnemploymentInsurance(0.012, 77400.0),
        new NursingInsurance(0.02025, 56250.0),
        [2020]
      ),
      new State(
        States['Sachsen-Anhalt'],
        StateType.new,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 77400.0),
        new UnemploymentInsurance(0.012, 77400.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States['Schleswig-Holstein'],
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
      new State(
        States.Thüringen,
        StateType.new,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 77400.0),
        new UnemploymentInsurance(0.012, 77400.0),
        new NursingInsurance(0.01525, 56250.0),
        [2021]
      ),
    ];
  }

  //https://www.lohn-info.de/beitragsberechnung.html

  public static getDisablement() {
    return new DisablementReduction();
  }

  public static getProgression(): ProgressionValue {
    return {
      progression: 9744.0,
      progression_1: {
        progression: 14532.0,
        value_1: 972.87,
      },
      progression_2: {
        progression: 57051.0,
        value_1: 212.02,
        value_2: 972.79,
      },
      progression_3: {
        progression: 270500.0,
        value_1: 8963.74,
      },
      progression_4: {
        value_1: 17078.74,
      },
    };
  }

  //https://www.bmf-steuerrechner.de/ekst/eingabeformekst.xhtml?ekst-result=true

  public static getTaxClass5(): TaxClass5 {
    return {
      zone_1: 10898,
      zone_2: 28526,
      zone_3: 216400,
    };
    // https://www.buzer.de/gesetz/4499/al82514-0.htm?m=10.635#hit
  }

  public static getFlats(): Flats {
    return {
      childFlat: 7812,
      relocationFlat: 820,
      relocationFlatChild: 361.0,
      specialExpensesFlat: 36.0,
      advertisingFlat: 1000,
      taxFreePension: 0.9,
      offsiteFlat: 14,
      offsiteFlat24Hours: 28,
      homeOfficeFlatPerDay: 5,
      homeOfficeMaxFlat: 600,
      privateInsuranceLimit: 5212.5,
    };
    // https://www.vlh.de/arbeiten-pendeln/beruf/umzugspauschale-erneut-erhoeht.html#
    //
  }
}
export class S2020 {
  public static year: number = 2020;
  public static get(): State[] {
    return [
      new State(
        States['Baden-Württemberg'],
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2020]
      ),
      new State(
        States.Bayern,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2020]
      ),
      new State(
        States.Brandenburg,
        StateType.new,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 77400.0),
        new UnemploymentInsurance(0.012, 77400.0),
        new NursingInsurance(0.01525, 56250.0),
        [2020]
      ),
      new State(
        States.Bremen,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2020]
      ),
      new State(
        States.Hamburg,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2020]
      ),
      new State(
        States.Berlin,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2020]
      ),
      new State(
        States.Hessen,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2020]
      ),
      new State(
        States['Mecklenburg-Vorpommern'],
        StateType.new,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 77400.0),
        new UnemploymentInsurance(0.012, 77400.0),
        new NursingInsurance(0.01525, 56250.0),
        [2020]
      ),
      new State(
        States.Niedersachsen,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2020]
      ),
      new State(
        States['Nordrhein-Westfalen'],
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2020]
      ),
      new State(
        States['Rheinland-Pfalz'],
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2020]
      ),
      new State(
        States.Saarland,
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2020]
      ),
      new State(
        States.Sachsen,
        StateType.new,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 77400.0),
        new UnemploymentInsurance(0.012, 77400.0),
        new NursingInsurance(0.02025, 56250.0),
        [2020]
      ),
      new State(
        States['Sachsen-Anhalt'],
        StateType.new,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 77400.0),
        new UnemploymentInsurance(0.012, 77400.0),
        new NursingInsurance(0.01525, 56250.0),
        [2020]
      ),
      new State(
        States['Schleswig-Holstein'],
        StateType.old,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 82800.0),
        new UnemploymentInsurance(0.012, 82800.0),
        new NursingInsurance(0.01525, 56250.0),
        [2020]
      ),
      new State(
        States.Thüringen,
        StateType.new,
        new HealthInsurance(0.073, 56250.0),
        new PensionInsurance(0.093, 77400.0),
        new UnemploymentInsurance(0.012, 77400.0),
        new NursingInsurance(0.01525, 56250.0),
        [2020]
      ),
    ];
  }

  //https://www.lohn-info.de/beitragsberechnung.html

  public static getDisablement() {
    return new DisablementReduction();
  }

  public static getProgression(): ProgressionValue {
    return {
      progression: 9408.0,
      progression_1: {
        progression: 14532.0,
        value_1: 972.87,
      },
      progression_2: {
        progression: 57051.0,
        value_1: 212.02,
        value_2: 972.79,
      },
      progression_3: {
        progression: 270500.0,
        value_1: 8963.74,
      },
      progression_4: {
        value_1: 17078.74,
      },
    };
  }

  //https://www.bmf-steuerrechner.de/ekst/eingabeformekst.xhtml?ekst-result=true

  public static getTaxClass5(): TaxClass5 {
    return {
      zone_1: 10898,
      zone_2: 28526,
      zone_3: 216400,
    };
    // https://www.buzer.de/gesetz/4499/al82514-0.htm?m=10.635#hit
  }

  public static getFlats(): Flats {
    return {
      childFlat: 7812,
      relocationFlat: 820,
      relocationFlatChild: 361.0,
      specialExpensesFlat: 36.0,
      advertisingFlat: 1000,
      taxFreePension: 0.9,
      offsiteFlat: 14,
      offsiteFlat24Hours: 28,
      homeOfficeFlatPerDay: 5,
      homeOfficeMaxFlat: 600,
      privateInsuranceLimit: 5212.5,
    };
    // https://www.vlh.de/arbeiten-pendeln/beruf/umzugspauschale-erneut-erhoeht.html#
    //
  }
}

export class S2019 {
  public static year: number = 2019;
  public static get(): State[] {
    return [
      new State(
        States['Baden-Württemberg'],
        StateType.old,
        new HealthInsurance(0.073, 54450.0),
        new PensionInsurance(0.093, 80400.0),
        new UnemploymentInsurance(0.0125, 80400.0),
        new NursingInsurance(0.01525, 54450.0),
        [2019]
      ),
      new State(
        States.Bayern,
        StateType.old,
        new HealthInsurance(0.073, 54450.0),
        new PensionInsurance(0.093, 80400.0),
        new UnemploymentInsurance(0.0125, 80400.0),
        new NursingInsurance(0.01525, 54450.0),
        [2019]
      ),
      new State(
        States.Brandenburg,
        StateType.new,
        new HealthInsurance(0.073, 54450.0),
        new PensionInsurance(0.093, 73800.0),
        new UnemploymentInsurance(0.0125, 73800.0),
        new NursingInsurance(0.01525, 54450.0),
        [2019]
      ),
      new State(
        States.Bremen,
        StateType.old,
        new HealthInsurance(0.073, 54450.0),
        new PensionInsurance(0.093, 80400.0),
        new UnemploymentInsurance(0.0125, 80400.0),
        new NursingInsurance(0.01525, 54450.0),
        [2019]
      ),
      new State(
        States.Hamburg,
        StateType.old,
        new HealthInsurance(0.073, 54450.0),
        new PensionInsurance(0.093, 80400.0),
        new UnemploymentInsurance(0.0125, 80400.0),
        new NursingInsurance(0.01525, 54450.0),
        [2019]
      ),
      new State(
        States.Berlin,
        StateType.old,
        new HealthInsurance(0.073, 54450.0),
        new PensionInsurance(0.093, 80400.0),
        new UnemploymentInsurance(0.0125, 80400.0),
        new NursingInsurance(0.01525, 54450.0),
        [2019]
      ),
      new State(
        States.Hessen,
        StateType.old,
        new HealthInsurance(0.073, 54450.0),
        new PensionInsurance(0.093, 80400.0),
        new UnemploymentInsurance(0.0125, 80400.0),
        new NursingInsurance(0.01525, 54450.0),
        [2019]
      ),
      new State(
        States['Mecklenburg-Vorpommern'],
        StateType.new,
        new HealthInsurance(0.073, 54450.0),
        new PensionInsurance(0.093, 73800.0),
        new UnemploymentInsurance(0.0125, 73800.0),
        new NursingInsurance(0.01525, 54450.0),
        [2019]
      ),
      new State(
        States.Niedersachsen,
        StateType.old,
        new HealthInsurance(0.073, 54450.0),
        new PensionInsurance(0.093, 80400.0),
        new UnemploymentInsurance(0.0125, 80400.0),
        new NursingInsurance(0.01525, 54450.0),
        [2019]
      ),
      new State(
        States['Nordrhein-Westfalen'],
        StateType.old,
        new HealthInsurance(0.073, 54450.0),
        new PensionInsurance(0.093, 80400.0),
        new UnemploymentInsurance(0.0125, 80400.0),
        new NursingInsurance(0.01525, 54450.0),
        [2019]
      ),
      new State(
        States['Rheinland-Pfalz'],
        StateType.old,
        new HealthInsurance(0.073, 54450.0),
        new PensionInsurance(0.093, 80400.0),
        new UnemploymentInsurance(0.0125, 80400.0),
        new NursingInsurance(0.01525, 54450.0),
        [2019]
      ),
      new State(
        States.Saarland,
        StateType.old,
        new HealthInsurance(0.073, 54450.0),
        new PensionInsurance(0.093, 80400.0),
        new UnemploymentInsurance(0.0125, 80400.0),
        new NursingInsurance(0.01525, 54450.0),
        [2019]
      ),
      new State(
        States.Sachsen,
        StateType.new,
        new HealthInsurance(0.073, 54450.0),
        new PensionInsurance(0.093, 73800.0),
        new UnemploymentInsurance(0.0125, 73800.0),
        new NursingInsurance(0.02025, 54450.0),
        [2019]
      ),
      new State(
        States['Sachsen-Anhalt'],
        StateType.new,
        new HealthInsurance(0.073, 54450.0),
        new PensionInsurance(0.093, 73800.0),
        new UnemploymentInsurance(0.0125, 73800.0),
        new NursingInsurance(0.01525, 54450.0),
        [2019]
      ),
      new State(
        States['Schleswig-Holstein'],
        StateType.old,
        new HealthInsurance(0.073, 54450.0),
        new PensionInsurance(0.093, 80400.0),
        new UnemploymentInsurance(0.0125, 80400.0),
        new NursingInsurance(0.01525, 54450.0),
        [2019]
      ),
      new State(
        States.Thüringen,
        StateType.new,
        new HealthInsurance(0.073, 54450.0),
        new PensionInsurance(0.093, 73800.0),
        new UnemploymentInsurance(0.0125, 73800.0),
        new NursingInsurance(0.01525, 54450.0),
        [2019]
      ),
    ];
  }

  public static getDisablement() {
    return new DisablementReduction();
  }

  public static getProgression(): ProgressionValue {
    return {
      progression: 9168.0,
      progression_1: {
        progression: 14254.0,
        value_1: 980.14,
      },
      progression_2: {
        progression: 55960.0,
        value_1: 216.16,
        value_2: 965.58,
      },
      progression_3: {
        progression: 265327.0,
        value_1: 8780.9,
      },
      progression_4: {
        value_1: 16740.68,
      },
    };
  }

  public static getTaxClass5(): TaxClass5 {
    return {
      zone_1: 10635,
      zone_2: 27980,
      zone_3: 212261,
    };
  }

  public static getFlats(): Flats {
    return {
      childFlat: 7620,
      relocationFlat: 811,
      relocationFlatChild: 347.0,
      specialExpensesFlat: 36.0,
      advertisingFlat: 1000,
      taxFreePension: 0.88,
      offsiteFlat: 12,
      offsiteFlat24Hours: 24,
      homeOfficeFlatPerDay: 0,
      homeOfficeMaxFlat: 0,
      privateInsuranceLimit: 5062.5,
    };
  }
}

export class S2018 {
  public static year: number = 2018;
  public static get(): State[] {
    return [
      new State(
        States['Baden-Württemberg'],
        StateType.old,
        new HealthInsurance(0.073, 53100),
        new PensionInsurance(0.093, 78000),
        new UnemploymentInsurance(0.015, 78000),
        new NursingInsurance(0.01275, 53100),
        [2018]
      ),
      new State(
        States.Bayern,
        StateType.old,
        new HealthInsurance(0.073, 53100),
        new PensionInsurance(0.093, 78000),
        new UnemploymentInsurance(0.015, 78000),
        new NursingInsurance(0.01275, 53100),
        [2018]
      ),
      new State(
        States.Brandenburg,
        StateType.new,
        new HealthInsurance(0.073, 53100),
        new PensionInsurance(0.093, 69600),
        new UnemploymentInsurance(0.015, 69600),
        new NursingInsurance(0.01275, 53100),
        [2018]
      ),
      new State(
        States.Bremen,
        StateType.old,
        new HealthInsurance(0.073, 53100),
        new PensionInsurance(0.093, 78000),
        new UnemploymentInsurance(0.015, 78000),
        new NursingInsurance(0.01275, 53100),
        [2018]
      ),
      new State(
        States.Hamburg,
        StateType.old,
        new HealthInsurance(0.073, 53100),
        new PensionInsurance(0.093, 78000),
        new UnemploymentInsurance(0.015, 78000),
        new NursingInsurance(0.01275, 53100),
        [2018]
      ),
      new State(
        States.Berlin,
        StateType.old,
        new HealthInsurance(0.073, 53100),
        new PensionInsurance(0.093, 78000),
        new UnemploymentInsurance(0.015, 78000),
        new NursingInsurance(0.01275, 53100),
        [2018]
      ),
      new State(
        States.Hessen,
        StateType.old,
        new HealthInsurance(0.073, 53100),
        new PensionInsurance(0.093, 78000),
        new UnemploymentInsurance(0.015, 78000),
        new NursingInsurance(0.01275, 53100),
        [2018]
      ),
      new State(
        States['Mecklenburg-Vorpommern'],
        StateType.new,
        new HealthInsurance(0.073, 53100),
        new PensionInsurance(0.093, 69600),
        new UnemploymentInsurance(0.015, 69600),
        new NursingInsurance(0.01275, 53100),
        [2018]
      ),
      new State(
        States.Niedersachsen,
        StateType.old,
        new HealthInsurance(0.073, 53100),
        new PensionInsurance(0.093, 78000),
        new UnemploymentInsurance(0.015, 78000),
        new NursingInsurance(0.01275, 53100),
        [2018]
      ),
      new State(
        States['Nordrhein-Westfalen'],
        StateType.old,
        new HealthInsurance(0.073, 53100),
        new PensionInsurance(0.093, 78000),
        new UnemploymentInsurance(0.015, 78000),
        new NursingInsurance(0.01275, 53100),
        [2018]
      ),
      new State(
        States['Rheinland-Pfalz'],
        StateType.old,
        new HealthInsurance(0.073, 53100),
        new PensionInsurance(0.093, 78000),
        new UnemploymentInsurance(0.015, 78000),
        new NursingInsurance(0.01275, 53100),
        [2018]
      ),
      new State(
        States.Saarland,
        StateType.old,
        new HealthInsurance(0.073, 53100),
        new PensionInsurance(0.093, 78000),
        new UnemploymentInsurance(0.015, 78000),
        new NursingInsurance(0.01275, 53100),
        [2018]
      ),
      new State(
        States.Sachsen,
        StateType.new,
        new HealthInsurance(0.073, 53100),
        new PensionInsurance(0.093, 69600),
        new UnemploymentInsurance(0.015, 69600),
        new NursingInsurance(0.01775, 53100),
        [2018]
      ),
      new State(
        States['Sachsen-Anhalt'],
        StateType.new,
        new HealthInsurance(0.073, 53100),
        new PensionInsurance(0.093, 69600),
        new UnemploymentInsurance(0.015, 69600),
        new NursingInsurance(0.01275, 53100),
        [2018]
      ),
      new State(
        States['Schleswig-Holstein'],
        StateType.old,
        new HealthInsurance(0.073, 53100),
        new PensionInsurance(0.093, 78000),
        new UnemploymentInsurance(0.015, 78000),
        new NursingInsurance(0.01275, 53100),
        [2018]
      ),
      new State(
        States.Thüringen,
        StateType.new,
        new HealthInsurance(0.073, 53100),
        new PensionInsurance(0.093, 69600),
        new UnemploymentInsurance(0.015, 69600),
        new NursingInsurance(0.01275, 53100),
        [2018]
      ),
    ];
  }

  public static getDisablement() {
    return new DisablementReduction();
  }

  public static getProgression(): ProgressionValue {
    return {
      progression: 9000.0,
      progression_1: {
        progression: 13996.0,
        value_1: 997.8,
      },
      progression_2: {
        progression: 54949.0,
        value_1: 220.13,
        value_2: 948.49,
      },
      progression_3: {
        progression: 260532.0,
        value_1: 8621.75,
      },
      progression_4: {
        value_1: 16437.7,
      },
    };
  }

  public static getTaxClass5(): TaxClass5 {
    return {
      zone_1: 10440,
      zone_2: 27475,
      zone_3: 208426,
    };
  }

  public static getFlats(): Flats {
    return {
      childFlat: 7428.0,
      relocationFlat: 787.0,
      relocationFlatChild: 347.0,
      specialExpensesFlat: 36.0,
      advertisingFlat: 1000,
      taxFreePension: 0.86,
      offsiteFlat: 12,
      offsiteFlat24Hours: 24,
      homeOfficeFlatPerDay: 0,
      homeOfficeMaxFlat: 0,
      privateInsuranceLimit: 4950.0,
    };
  }
}

/**
 * @deprecated 2017 is not possible to send to taxoffice anymore
 */
export class S2017 {
  public static year: number = 2017;
  public static get(): State[] {
    return [
      new State(
        States['Baden-Württemberg'],
        StateType.old,
        new HealthInsurance(0.073, 52200.0),
        new PensionInsurance(0.0935, 76200),
        new UnemploymentInsurance(0.015, 76200),
        new NursingInsurance(0.01275, 52200.0),
        [2017]
      ),
      new State(
        States.Bayern,
        StateType.old,
        new HealthInsurance(0.073, 52200.0),
        new PensionInsurance(0.0935, 76200),
        new UnemploymentInsurance(0.015, 76200),
        new NursingInsurance(0.01275, 52200.0),
        [2017]
      ),
      new State(
        States.Brandenburg,
        StateType.new,
        new HealthInsurance(0.073, 52200.0),
        new PensionInsurance(0.0935, 68400),
        new UnemploymentInsurance(0.015, 68400),
        new NursingInsurance(0.01275, 52200.0),
        [2017]
      ),
      new State(
        States.Bremen,
        StateType.old,
        new HealthInsurance(0.073, 52200.0),
        new PensionInsurance(0.0935, 76200),
        new UnemploymentInsurance(0.015, 76200),
        new NursingInsurance(0.01275, 52200.0),
        [2017]
      ),
      new State(
        States.Hamburg,
        StateType.old,
        new HealthInsurance(0.073, 52200.0),
        new PensionInsurance(0.0935, 76200),
        new UnemploymentInsurance(0.015, 76200),
        new NursingInsurance(0.01275, 52200.0),
        [2017]
      ),
      new State(
        States.Berlin,
        StateType.old,
        new HealthInsurance(0.073, 52200.0),
        new PensionInsurance(0.0935, 76200),
        new UnemploymentInsurance(0.015, 76200),
        new NursingInsurance(0.01275, 52200.0),
        [2017]
      ),
      new State(
        States.Hessen,
        StateType.old,
        new HealthInsurance(0.073, 52200.0),
        new PensionInsurance(0.0935, 76200),
        new UnemploymentInsurance(0.015, 76200),
        new NursingInsurance(0.01275, 52200.0),
        [2017]
      ),
      new State(
        States['Mecklenburg-Vorpommern'],
        StateType.new,
        new HealthInsurance(0.073, 52200.0),
        new PensionInsurance(0.0935, 68400),
        new UnemploymentInsurance(0.015, 68400),
        new NursingInsurance(0.01275, 52200.0),
        [2017]
      ),
      new State(
        States.Niedersachsen,
        StateType.old,
        new HealthInsurance(0.073, 52200.0),
        new PensionInsurance(0.0935, 76200),
        new UnemploymentInsurance(0.015, 76200),
        new NursingInsurance(0.01275, 52200.0),
        [2017]
      ),
      new State(
        States['Nordrhein-Westfalen'],
        StateType.old,
        new HealthInsurance(0.073, 52200.0),
        new PensionInsurance(0.0935, 76200),
        new UnemploymentInsurance(0.015, 76200),
        new NursingInsurance(0.01275, 52200.0),
        [2017]
      ),
      new State(
        States['Rheinland-Pfalz'],
        StateType.old,
        new HealthInsurance(0.073, 52200.0),
        new PensionInsurance(0.0935, 76200),
        new UnemploymentInsurance(0.015, 76200),
        new NursingInsurance(0.01275, 52200.0),
        [2017]
      ),
      new State(
        States.Saarland,
        StateType.old,
        new HealthInsurance(0.073, 52200.0),
        new PensionInsurance(0.0935, 76200),
        new UnemploymentInsurance(0.015, 76200),
        new NursingInsurance(0.01275, 52200.0),
        [2017]
      ),
      new State(
        States.Sachsen,
        StateType.new,
        new HealthInsurance(0.073, 52200.0),
        new PensionInsurance(0.0935, 68400),
        new UnemploymentInsurance(0.015, 68400),
        new NursingInsurance(0.01775, 52200.0),
        [2017]
      ),
      new State(
        States['Sachsen-Anhalt'],
        StateType.new,
        new HealthInsurance(0.073, 52200.0),
        new PensionInsurance(0.0935, 68400),
        new UnemploymentInsurance(0.015, 68400),
        new NursingInsurance(0.01275, 52200.0),
        [2017]
      ),
      new State(
        States['Schleswig-Holstein'],
        StateType.old,
        new HealthInsurance(0.073, 52200.0),
        new PensionInsurance(0.0935, 76200),
        new UnemploymentInsurance(0.015, 76200),
        new NursingInsurance(0.01275, 52200.0),
        [2017]
      ),
      new State(
        States.Thüringen,
        StateType.new,
        new HealthInsurance(0.073, 52200.0),
        new PensionInsurance(0.0935, 68400),
        new UnemploymentInsurance(0.015, 68400),
        new NursingInsurance(0.01275, 52200.0),
        [2017]
      ),
    ];
  }

  public static getDisablement() {
    return new DisablementReduction();
  }

  public static getProgression(): ProgressionValue {
    return {
      progression: 8820.0,
      progression_1: {
        progression: 13769.0,
        value_1: 1007.27,
      },
      progression_2: {
        progression: 54057.0,
        value_1: 223.76,
        value_2: 939.57,
      },
      progression_3: {
        progression: 256303.0,
        value_1: 8475.44,
      },
      progression_4: {
        value_1: 16164.53,
      },
    };
  }

  public static getTaxClass5(): TaxClass5 {
    return {
      zone_1: 10240,
      zone_2: 27029,
      zone_3: 205043,
    };
  }

  public static getFlats(): Flats {
    return {
      childFlat: 7356.0,
      relocationFlat: 764.0,
      relocationFlatChild: 337.0,
      specialExpensesFlat: 36.0,
      advertisingFlat: 1000,
      taxFreePension: 0.84,
      offsiteFlat: 12,
      offsiteFlat24Hours: 24,
      homeOfficeFlatPerDay: 0,
      homeOfficeMaxFlat: 0,
      privateInsuranceLimit: 4800.0,
    };
  }
}

/**
 * @deprecated 2016 is not possible to send to the taxoffice  anymore
 */
export class S2016 {
  public static year: number = 2016;
  public static get(): State[] {
    return [
      new State(
        States['Baden-Württemberg'],
        StateType.old,
        new HealthInsurance(0.073, 50850),
        new PensionInsurance(0.0935, 74400),
        new UnemploymentInsurance(0.015, 74400),
        new NursingInsurance(0.01175, 50850),
        [2016]
      ),
      new State(
        States.Bayern,
        StateType.old,
        new HealthInsurance(0.073, 50850),
        new PensionInsurance(0.0935, 74400),
        new UnemploymentInsurance(0.015, 74400),
        new NursingInsurance(0.01175, 50850),
        [2016]
      ),
      new State(
        States.Brandenburg,
        StateType.new,
        new HealthInsurance(0.073, 50850),
        new PensionInsurance(0.0935, 64800),
        new UnemploymentInsurance(0.015, 64800),
        new NursingInsurance(0.01175, 50850),
        [2016]
      ),
      new State(
        States.Bremen,
        StateType.old,
        new HealthInsurance(0.073, 50850),
        new PensionInsurance(0.0935, 74400),
        new UnemploymentInsurance(0.015, 74400),
        new NursingInsurance(0.01175, 50850),
        [2016]
      ),
      new State(
        States.Hamburg,
        StateType.old,
        new HealthInsurance(0.073, 50850),
        new PensionInsurance(0.0935, 74400),
        new UnemploymentInsurance(0.015, 74400),
        new NursingInsurance(0.01175, 50850),
        [2016]
      ),
      new State(
        States.Berlin,
        StateType.old,
        new HealthInsurance(0.073, 50850),
        new PensionInsurance(0.0935, 74400),
        new UnemploymentInsurance(0.015, 74400),
        new NursingInsurance(0.01175, 50850),
        [2016]
      ),
      new State(
        States.Hessen,
        StateType.old,
        new HealthInsurance(0.073, 50850),
        new PensionInsurance(0.0935, 74400),
        new UnemploymentInsurance(0.015, 74400),
        new NursingInsurance(0.01175, 50850),
        [2016]
      ),
      new State(
        States['Mecklenburg-Vorpommern'],
        StateType.new,
        new HealthInsurance(0.073, 50850),
        new PensionInsurance(0.093, 64800),
        new UnemploymentInsurance(0.015, 64800),
        new NursingInsurance(0.01175, 50850),
        [2016]
      ),
      new State(
        States.Niedersachsen,
        StateType.old,
        new HealthInsurance(0.073, 50850),
        new PensionInsurance(0.0935, 74400),
        new UnemploymentInsurance(0.015, 74400),
        new NursingInsurance(0.01175, 50850),
        [2016]
      ),
      new State(
        States['Nordrhein-Westfalen'],
        StateType.old,
        new HealthInsurance(0.073, 50850),
        new PensionInsurance(0.0935, 74400),
        new UnemploymentInsurance(0.015, 74400),
        new NursingInsurance(0.01175, 50850),
        [2016]
      ),
      new State(
        States['Rheinland-Pfalz'],
        StateType.old,
        new HealthInsurance(0.073, 50850),
        new PensionInsurance(0.0935, 74400),
        new UnemploymentInsurance(0.015, 74400),
        new NursingInsurance(0.01175, 50850),
        [2016]
      ),
      new State(
        States.Saarland,
        StateType.old,
        new HealthInsurance(0.073, 50850),
        new PensionInsurance(0.0935, 74400),
        new UnemploymentInsurance(0.015, 74400),
        new NursingInsurance(0.01175, 50850),
        [2016]
      ),
      new State(
        States.Sachsen,
        StateType.new,
        new HealthInsurance(0.073, 50850),
        new PensionInsurance(0.0935, 64800),
        new UnemploymentInsurance(0.015, 64800),
        new NursingInsurance(0.01675, 50850),
        [2016]
      ),
      new State(
        States['Sachsen-Anhalt'],
        StateType.new,
        new HealthInsurance(0.073, 50850),
        new PensionInsurance(0.0935, 64800),
        new UnemploymentInsurance(0.015, 64800),
        new NursingInsurance(0.01175, 50850),
        [2016]
      ),
      new State(
        States['Schleswig-Holstein'],
        StateType.old,
        new HealthInsurance(0.073, 50850),
        new PensionInsurance(0.0935, 74400),
        new UnemploymentInsurance(0.015, 74400),
        new NursingInsurance(0.01175, 50850),
        [2016]
      ),
      new State(
        States.Thüringen,
        StateType.new,
        new HealthInsurance(0.073, 50850),
        new PensionInsurance(0.0935, 64800),
        new UnemploymentInsurance(0.015, 64800),
        new NursingInsurance(0.01175, 50850),
        [2016]
      ),
    ];
  }

  public static getDisablement() {
    return new DisablementReduction();
  }

  public static getProgression(): ProgressionValue {
    return {
      progression: 8652.0,
      progression_1: {
        progression: 13669.0,
        value_1: 993.62,
      },
      progression_2: {
        progression: 53665.0,
        value_1: 225.4,
        value_2: 952.48,
      },
      progression_3: {
        progression: 254446.0,
        value_1: 8394.14,
      },
      progression_4: {
        value_1: 16027.52,
      },
    };
  }

  public static getTaxClass5(): TaxClass5 {
    return {
      zone_1: 10070,
      zone_2: 26832,
      zone_3: 203557,
    };
  }

  public static getFlats(): Flats {
    return {
      childFlat: 7248.0,
      relocationFlat: 746.0,
      relocationFlatChild: 329.0,
      specialExpensesFlat: 36.0,
      advertisingFlat: 1000,
      taxFreePension: 0.82,
      offsiteFlat: 12,
      offsiteFlat24Hours: 24,
      homeOfficeFlatPerDay: 0,
      homeOfficeMaxFlat: 0,
      privateInsuranceLimit: 4800.0,
    };
  }
}
