<ng-container *ngIf="entry">
  <div class="icon">
    <mat-icon mat-list-icon>track_changes</mat-icon>
  </div>
  <div class="text">
    <div mat-line>
      {{ entry.context.timestamp | date : 'fullDate' }}
      {{ entry.context.timestamp | date : 'shortTime' }}
    </div>
    <!-- TODO user making the change -->
    <!-- <div mat-line>{{ entry.user | json }}</div> -->
    <div mat-line>
      <mat-list dense>
        <mat-list-item *ngFor="let change of entry.diff | keyvalue">
          <div mat-line>
            {{ change.key }}:
            <code *ngIf="!typeOf(change.value, 'object')">
              {{ change.value }}
            </code>
            <pre *ngIf="typeOf(change.value, 'object')">{{
              change.value | json
            }}</pre>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</ng-container>
