import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { EsuiSkeletonPlaceholderModule } from '../esui-skeleton-placeholder/esui-skeleton-placeholder.module';
import { EsuiTaxNoticeModule } from '../esui-tax-notice/esui-tax-notice.module';
import { PureMethodModule } from '../pipes/pure-method/pure-method.module';
import { EsuiDataTaxnoticeDetailComponent } from './esui-data-taxnotice-detail.component';

@NgModule({
  declarations: [EsuiDataTaxnoticeDetailComponent],
  imports: [
    CommonModule,
    EsuiSkeletonPlaceholderModule,
    EsuiTaxNoticeModule,
    FormsModule,
    MatInputModule,
    PureMethodModule,
    MatDividerModule,
    MatExpansionModule,
  ],
  exports: [EsuiDataTaxnoticeDetailComponent],
})
export class EsuiDataTaxnoticeDetailModule {}
