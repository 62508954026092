import { Component, Input, OnInit } from '@angular/core';
import { Message } from '@expresssteuer/models';

@Component({
  selector: 'app-control-messaging-username',
  templateUrl: './control-messaging-username.component.html',
  styleUrls: ['./control-messaging-username.component.scss'],
})
export class ControlMessagingUsernameComponent implements OnInit {
  constructor() {}

  @Input()
  public message: Message;

  ngOnInit(): void {}
}
