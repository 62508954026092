import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from '@expresssteuer/models';

@Pipe({
  name: 'asDate',
})
export class AsDatePipe implements PipeTransform {
  transform(value: Timestamp | null | undefined): Date | undefined | null {
    return value?.toDate();
  }
}
