import { Component, EventEmitter, Input, Output } from '@angular/core';

interface ValidationResult {
  valid: boolean;
}

@Component({
  selector: 'esui-vma-proof-feedback-form',
  templateUrl: './esui-vma-proof-feedback-form.component.html',
  styleUrls: ['./esui-vma-proof-feedback-form.component.scss'],
})
export class EsuiVmaProofFeedbackFormComponent {
  @Input()
  validationResult?: ValidationResult;

  @Output()
  validationResultChange = new EventEmitter<Record<string, unknown>>();

  validCheckboxChange(evt: boolean) {
    this.validationResultChange.next({
      valid: evt,
    });
  }
}
