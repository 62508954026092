<br />
<mat-divider></mat-divider>
<div class="finished-tasks">
  <div>
    <h5>{{ translationContentClientTaskViewer?.openTasksHeader }}</h5>
  </div>
</div>
<br />
<cdk-accordion class="example-accordion">
  <cdk-accordion-item
    *ngFor="let clientTask of clientFacingTasksOpen; let index = index"
    #accordionItem="cdkAccordionItem"
    class="example-accordion-item"
    role="button"
    tabindex="0"
    [attr.id]="'accordion-header-' + index"
    [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'accordion-body-' + index"
  >
    <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
      <div class="verified-state" *ngIf="clientTask.deactivated">
        <div>
          <mat-icon>task_alt</mat-icon>
        </div>
      </div>
      <div
        class="uploaded-state"
        *ngIf="allUploadsCompleted(clientTask) && !clientTask.deactivated"
      >
        <div>
          <mat-icon>done</mat-icon>
        </div>
      </div>
      <div class="unfinished-state" *ngIf="!allUploadsCompleted(clientTask)">
        <div>
          <mat-icon>radio_button_unchecked</mat-icon>
        </div>
      </div>
      <div class="header-content">
        <div class="header-text">
          {{ clientTask.type }} {{ clientTask.taskMatcherMetadata.input.name }}
        </div>
        <span
          class="example-accordion-item-description"
          *ngIf="accordionItem.expanded"
        >
          <mat-icon>expand_less</mat-icon>
        </span>
        <span
          class="example-accordion-item-description"
          *ngIf="!accordionItem.expanded"
        >
          <mat-icon>expand_more</mat-icon>
        </span>
      </div>
    </div>
    <div
      class="example-accordion-item-body"
      role="region"
      [style.display]="accordionItem.expanded ? '' : 'none'"
      [attr.id]="'accordion-body-' + index"
      [attr.aria-labelledby]="'accordion-header-' + index"
    >
      <esui-document-uploader
        [translationContent]="translationContentDocumentUploader"
        [clientDocumentUploadStates]="clientTask.meta.uploadStates"
        [multiUpload]="clientTask.allowMultiUpload"
        [userMarkedUploadAsDone]="clientTask.allDocsUploaded"
        [userMarkedAsNotAvailable]="clientTask.noneExistent"
        (userMarkedAsNotAvailableChange)="
          documentsNotAvailableForTask($event, clientTask)
        "
        (fileSelected)="fileUploadFromClientTask($event, clientTask)"
        (userMarkedUploadAsDoneChange)="
          allUploadsDoneForTask($event, clientTask)
        "
        (fileMarkedForDeletion)="clientDocumentToMarkForDeletion($event)"
        (newRemark)="newRemarkForTask($event, clientTask)"
      >
      </esui-document-uploader>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<br />
<mat-divider></mat-divider>
<div class="finished-tasks">
  <div>
    <h5>{{ translationContentClientTaskViewer?.closedTasksHeader }}</h5>
  </div>
</div>
<br />
<cdk-accordion class="example-accordion">
  <cdk-accordion-item
    *ngFor="let clientTask of clientFacingTasksDone; let index = index"
    #accordionItem="cdkAccordionItem"
    class="example-accordion-item"
    role="button"
    tabindex="0"
    [attr.id]="'accordion-header-' + index"
    [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'accordion-body-' + index"
  >
    <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
      <div class="verified-state" *ngIf="clientTask.deactivated">
        <div>
          <mat-icon>task_alt</mat-icon>
        </div>
      </div>
      <div
        class="uploaded-state"
        *ngIf="allUploadsCompleted(clientTask) && !clientTask.deactivated"
      >
        <div>
          <mat-icon>done</mat-icon>
        </div>
      </div>
      <div class="unfinished-state" *ngIf="!allUploadsCompleted(clientTask)">
        <div>
          <mat-icon>priority_high</mat-icon>
        </div>
      </div>
      <div class="header-content">
        <div class="header-text">
          {{ clientTask.type }} {{ clientTask.taskMatcherMetadata.input.name }}
        </div>
        <span
          class="example-accordion-item-description"
          *ngIf="accordionItem.expanded"
        >
          <mat-icon>expand_less</mat-icon>
        </span>
        <span
          class="example-accordion-item-description"
          *ngIf="!accordionItem.expanded"
        >
          <mat-icon>expand_more</mat-icon>
        </span>
      </div>
    </div>
    <div
      class="example-accordion-item-body"
      role="region"
      [style.display]="accordionItem.expanded ? '' : 'none'"
      [attr.id]="'accordion-body-' + index"
      [attr.aria-labelledby]="'accordion-header-' + index"
    >
      <esui-document-uploader
        [translationContent]="translationContentDocumentUploader"
        [clientDocumentUploadStates]="clientTask.meta.uploadStates"
        [multiUpload]="clientTask.allowMultiUpload"
        [userMarkedUploadAsDone]="clientTask.allDocsUploaded"
        [userMarkedAsNotAvailable]="clientTask.noneExistent"
        (userMarkedAsNotAvailableChange)="
          documentsNotAvailableForTask($event, clientTask)
        "
        (fileSelected)="fileUploadFromClientTask($event, clientTask)"
        (userMarkedUploadAsDoneChange)="
          allUploadsDoneForTask($event, clientTask)
        "
        (fileMarkedForDeletion)="clientDocumentToMarkForDeletion($event)"
        (newRemark)="newRemarkForTask($event, clientTask)"
      >
      </esui-document-uploader>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
