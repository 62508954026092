import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TaxCase } from '@expresssteuer/models';

@Component({
  selector: 'esui-taxcase-list-old',
  templateUrl: './taxcase-list-old.component.html',
  styleUrls: ['./taxcase-list-old.component.scss'],
})
export class TaxcaseListOldComponent {
  @Input() taxcases?: TaxCase[] | null;

  @Output() selectedTaxcaseChange = new EventEmitter<TaxCase>();
  @Input() selectedTaxcase?: TaxCase | null;

  trackById(index: number, { id }: { id: string }) {
    return `${index}${id}`;
  }
}
