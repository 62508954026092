<div class="btn-group">
  <button
    class="btn btn-primary font-weight-bold btn-lg dropdown-toggle"
    type="button"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    X
  </button>
  <div class="dropdown-menu">
    <a class="dropdown-item">Ablehnen, Kunden nicht Informieren</a>
    <a class="dropdown-item">Ablehnen doppelter Eintrag</a>
    <a class="dropdown-item">Ablehnen Ungültig</a>
    <a class="dropdown-item">Kunde hat abgelehnt</a>
  </div>
</div>
