import { DeepPartial } from '@expresssteuer/deep-object-helper';
import { z } from 'zod';
import { IAddress } from '../../interfaces/address';
import { ConvertExpsAddress } from './convert-exps-address';

export class AddressOptional {
  public static getTemplate(): AddressOptional {
    return {
      nbr: null,
    };
  }
  public nbr: string | null = null;
}

export const ExpsAddressZod = z
  .any()
  .transform((val) => val)
  .pipe(z.custom<ExpsAddress>((val) => ExpsAddress.guard(val)));

export class ExpsAddress implements IAddress {
  public static sanitize(address: ExpsAddress): ExpsAddress {
    return {
      formatedAddress: address.formatedAddress,
      placeId: address.placeId,
      long: address.long,
      lat: address.lat,
      unformatedAddress: address.unformatedAddress,
      isChanged: address.isChanged,

      street: {
        shortName: address.street.shortName,
        longName: address.street.longName,
      },
      nbr: { shortName: address.nbr.shortName, longName: address.nbr.longName },
      city: {
        shortName: address.city.shortName,
        longName: address.city.longName,
      },
      region: {
        shortName: address.region.shortName,
        longName: address.region.longName,
      },
      state: {
        shortName: address.state.shortName,
        longName: address.state.longName,
      },
      zipCode: {
        shortName: address.zipCode.shortName,
        longName: address.zipCode.longName,
      },
      country: {
        shortName: address.country.shortName,
        longName: address.country.longName,
      },
    };
  }

  public static guard(address: unknown): address is ExpsAddress {
    if (!address) {
      return false;
    }
    const e = address as DeepPartial<ExpsAddress>;

    return (
      [
        e.street?.longName,
        e.street?.shortName,
        e.nbr?.longName,
        e.nbr?.shortName,
        e.city?.longName,
        e.city?.shortName,
        e.region?.longName,
        e.region?.shortName,
        e.state?.longName,
        e.state?.shortName,
        e.zipCode?.longName,
        e.zipCode?.shortName,
        e.country?.longName,
        e.country?.shortName,
        e.formatedAddress,

        e.formatedAddress,
        e.placeId,
        e.long,
        e.lat,
        e.unformatedAddress,
      ].some((e) => !e || typeof e !== 'string') &&
      (e.isChanged == null || typeof e.isChanged === 'boolean')
    );
  }

  public static getAddressLine(address: ExpsAddress): string {
    const firstPart = [address?.street?.longName, address?.nbr?.longName]
      .filter((e) => Boolean(e))
      .join(' ');
    const secondPart = [address?.zipCode?.longName, address?.city?.longName]
      .filter((e) => Boolean(e))
      .join(' ');
    const thirdPart = [address?.country?.longName]
      .filter((e) => Boolean(e))
      .join(' ');

    return [firstPart, secondPart, thirdPart]
      .filter((e) => Boolean(e))
      .join(', ');
  }

  public static getTemplate(
    street: string = '',
    nbr: string = '',
    zipCode: string = '',
    city: string = '',
    state: string = '',
    stateShort: string = '',
    region: string = '',
    country: string = ''
  ): ExpsAddress {
    const addresString = `${street} ${nbr}, ${zipCode} ${city}, ${country}`;

    return {
      formatedAddress: addresString.length > 10 ? addresString : '',
      placeId: '',
      long: '',
      lat: '',
      unformatedAddress: addresString.length > 10 ? addresString : '',
      isChanged: true,

      street: { shortName: street, longName: street },
      nbr: { shortName: nbr, longName: nbr },
      city: { shortName: city, longName: city },
      region: { shortName: region, longName: region },
      state: { shortName: state, longName: stateShort },
      zipCode: { shortName: zipCode, longName: zipCode },
      country: { shortName: country, longName: country },
    };
  }

  public static getAddress(addr: ExpsAddress): ExpsAddress {
    if (addr.optional) {
      if (addr.optional.nbr && addr.optional.nbr?.length > 0) {
        addr.nbr.shortName = addr.optional.nbr;
        addr.nbr.shortName = addr.optional.nbr;
      }
    }

    return addr;
  }

  public static isEmpty(addr: ExpsAddress) {
    return [
      Boolean(addr?.city.longName),
      Boolean(addr?.nbr.longName),
      Boolean(addr?.street.longName),
      Boolean(addr?.zipCode.longName),
    ].includes(false);
  }

  public static isAllEmpty(addr: ExpsAddress) {
    return [
      Boolean(addr?.city.longName),
      Boolean(addr?.nbr.longName),
      Boolean(addr?.street.longName),
      Boolean(addr?.zipCode.longName),
    ].every((e) => e === false);
  }

  public static getFromGooglePlaceResult =
    ConvertExpsAddress.getExpsAddressFromPlaces;

  public street = { shortName: '', longName: '' };
  public nbr = { shortName: '', longName: '' };
  public city = { shortName: '', longName: '' };
  public region = { shortName: '', longName: '' };
  public state = { shortName: '', longName: '' };
  public zipCode = { shortName: '', longName: '' };
  public country = { shortName: '', longName: '' };
  /**
   * Contains the full address string
   */
  public formatedAddress: string = '';
  public placeId: string = '';
  public long: string = '';
  public lat: string = '';
  /**
   * @deprecated We do not use this anymore. This was for the address in the ui to be set
   */
  public unformatedAddress: string = '';
  /**
   * @deprecated We do not use this anymore. Changes are detected in the backend automatically
   */
  public isChanged: boolean = true;
  public optional?: AddressOptional = AddressOptional.getTemplate();
}
