<div *ngIf="claims">
  <h2>Role</h2>
  <esui-button-toggle-group
    [options]="roleOptions"
    [ngModel]="claims.role"
    (ngModelChange)="roleChanged($event)"
    [disabled]="disabled"
  ></esui-button-toggle-group>

  <h2>Permissions</h2>
  <p *ngIf="!disabled && isLoggedInUser">
    These are your own claims, please make sure to refresh permissions in user
    profile
  </p>
  <table
    mat-table
    [dataSource]="dataSource"
    [trackBy]="trackByKey"
    class="mat-elevation-z0"
  >
    <ng-container matColumnDef="short">
      <th mat-header-cell *matHeaderCellDef>Short</th>
      <td mat-cell *matCellDef="let element">
        <pre>{{ element.key }}</pre>
      </td>
    </ng-container>

    <ng-container matColumnDef="long">
      <th mat-header-cell *matHeaderCellDef>Long</th>
      <td mat-cell *matCellDef="let element">
        <pre> {{ element.label }}</pre>
      </td>
    </ng-container>

    <ng-container matColumnDef="perm">
      <th mat-header-cell *matHeaderCellDef>perm</th>
      <td mat-cell *matCellDef="let element">
        <esui-permission-input
          [ngModel]="element.value"
          (ngModelChange)="permissionsChanged(element, $event)"
          [disabled]="disabled"
        ></esui-permission-input>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
