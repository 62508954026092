import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  TaxCase,
  TAXCASESTATUS,
  TAXCASESTATUS_INPROGRESS,
  TAXCASESTATUS_REJECT,
  User,
} from '@expresssteuer/models';
import { Elster } from '../../../../app/services/data/elster.service';
import { SepaService } from '../../../../app/services/data/sepa.service';
import { TaxcasesService } from '../../../../app/services/data/taxcases.service';
import { UsersService } from '../../../../app/services/data/users.service';
import { BlockUiService } from '../../../../app/services/helpers/blockui.service';
import { NotificationService } from '../../../../app/services/helpers/notification.service';
import { AuthenticationService } from '../../../../app/services/security/authentication.service';

declare var $: any;

@Component({
  selector: 'app-statusbuttons-list',
  templateUrl: './statusbuttons-list.component.html',
  styleUrls: ['./statusbuttons-list.component.scss'],
})
export class StatusbuttonsListComponent implements OnInit {
  taxCaseValue: TaxCase;
  taxCaseStatus: TAXCASESTATUS;
  taxCaseSubStatus: string;

  TAXCASESTATUS = TAXCASESTATUS;
  TAXCASESTATUS_INPROGRESS = TAXCASESTATUS_INPROGRESS;
  TAXCASESTATUS_REJECT = TAXCASESTATUS_REJECT;

  @Output()
  taxCaseChange = new EventEmitter<TaxCase>();

  @Input()
  get taxcase(): TaxCase {
    return this.taxCaseValue;
  }

  set taxcase(val: TaxCase) {
    this.taxCaseValue = val;
    this.taxCaseChange.emit(this.taxCaseValue);
  }

  @Input()
  get currentStatus(): TAXCASESTATUS {
    return this.taxCaseStatus;
  }

  set currentStatus(val: TAXCASESTATUS) {
    this.taxCaseStatus = val;
  }

  @Input()
  get currentSubStatus(): TAXCASESTATUS {
    return this.taxCaseStatus;
  }

  set currentSubStatus(val: TAXCASESTATUS) {
    this.taxCaseSubStatus = val;
  }

  constructor(
    private security: AuthenticationService,
    private taxcasesservice: TaxcasesService,
    private elster: Elster,
    public notificationservice: NotificationService,
    private blockUiService: BlockUiService,
    public userservice: UsersService,
    private sepaService: SepaService
  ) {}

  ngOnInit(): void {}

  public getCurrentTaxCaseStatus() {
    return this.taxcase
      ? this.taxcase.metadata.status.current
      : this.currentStatus;
  }

  public getCurrentTaxCaseSubStatus() {
    return this.taxcase
      ? this.taxcase.metadata.status.substatus
      : this.currentSubStatus;
  }

  private async update() {
    try {
      await this.taxcasesservice.save(this.taxcase);
    } catch (err) {
      console.error(err);
    }
  }

  public decline(
    reason: string = '',
    informClient: boolean = true,
    reject: TAXCASESTATUS_REJECT | null = null
  ) {
    this.taxcase.metadata.status.current = TAXCASESTATUS.REJECT;
    this.taxcase.metadata.status.substatus = reject;

    // const history: TaxCaseHistoryItem = TaxCaseHistoryItem.getTemplate('Abgelehnt', this.security.user);

    // this.taxcase.metadata.status.history.push(history);
    this.update();
  }

  public async accept() {
    if (this.taxcase) {
      if (this.taxcase.metadata.status.current === TAXCASESTATUS.ENTRY) {
        this.taxcase.metadata.status.current = TAXCASESTATUS.INPROGRESS;
        this.taxcase.metadata.status.substatus =
          TAXCASESTATUS_INPROGRESS.WAITINGDATA;
        // this.taxcase.metadata.status.substatus = TAXCASESTATUS_INPROGRESS.WAITING;
      }

      try {
        this.update();
      } catch (err) {
        console.error('Could not assign user', err);
      }
    }
  }

  // ACCEPT
  public acceptAndAssignToMe(): void {
    this.taxcase.metadata.assigned.authId = this.security.user.authId; // makes it work for the emulator too
    this.taxcase.metadata.assigned = this.security.user;
    // const historyItem: TaxCaseHistoryItem = TaxCaseHistoryItem.getTemplate('Assigned to me', this.security.user);

    this.accept();
  }

  public async getSepaExport() {
    const url = await this.sepaService.getExportForTaxId(
      this.taxcasesservice.currentTaxCase,
      true
    );
  }

  public acceptAndAssignToSomeone(user: User): void {
    user.authId = this.security.user.authId; // makes it work for the emulator too
    this.taxcase.metadata.assigned = user;
    // const historyItem: TaxCaseHistoryItem = TaxCaseHistoryItem.getTemplate('Reasigned to Another User', this.security.user);

    this.accept();
  }

  public assignToMe() {
    this.taxcase.metadata.assigned = this.security.user;
    this.update();
  }

  public assignToSomeone(user: User): void {
    this.taxcase.metadata.assigned = user;
    this.update();
    // const historyItem: TaxCaseHistoryItem = TaxCaseHistoryItem.getTemplate('Reasigned to Another User', this.security.user);
  }

  // Finanzamt
  public async sendToTaxoffice(production: boolean = false) {
    // this.blockUiService.blockContentSection();
    // const historyItem: TaxCaseHistoryItem = TaxCaseHistoryItem.getTemplate('send taxcase', this.security.user);
    // this.taxcase.metadata.status.history.push(historyItem);
    // try {
    //   const response: ElsterResponse = await this.elster.submitToElster(this.taxcase, production);
    //   if (response.status === ELSTERRESPONSESTATUS.OK) {
    //     this.notificationservice.success('Testfall einreichnung OK', 'Der Fall wurde erfolgreich eingereicht');
    //   } else {
    //     this.notificationservice.warning('Testfalleinreichung mit Fehlern', 'Einreichen war fehlerhaft. Bitte im Fall die Antwort der Elsterschnittstelle prüfen');
    //   }
    //   this.blockUiService.unblockContentSection();
    // } catch (err) {
    //   this.notificationservice.warning('Testfall einreichnung Fehler', 'Der Fall konte nicht eingereicht werden');
    //   this.blockUiService.unblockContentSection();
    // }
  }

  public async changeStatus(
    status: TAXCASESTATUS,
    substatus: TAXCASESTATUS_INPROGRESS | TAXCASESTATUS_REJECT | null
  ) {
    // const historyItem: TaxCaseHistoryItem = TaxCaseHistoryItem.getTemplate('changed status', this.security.user);
    // this.taxcase.metadata.status.history.push(historyItem);
    this.taxcase.metadata.status.current = status;
    this.taxcase.metadata.status.substatus = substatus;
    this.update();
  }

  public setSepaDate() {
    $('#sepa-date-set').modal('show');
  }
}
