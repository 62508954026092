import { Directive, EventEmitter, Output } from '@angular/core';

@Directive()
export class BusyManager {
  @Output()
  public isBusy = false;
  @Output()
  public statusmessage = '';

  @Output()
  public isError: boolean;

  @Output() Start = new EventEmitter<string>();
  @Output() Stop = new EventEmitter<string>();

  public start(message: string = 'Arbeite'): void {
    this.statusmessage = message;
    this.isBusy = true;
    this.isError = false;

    this.Start.emit(this.statusmessage);
  }

  public stop(): void {
    this.statusmessage = '';
    this.isBusy = false;
    this.isError = false;

    this.Stop.emit(this.statusmessage);
  }

  public error(error: string): void {
    this.statusmessage = error;
    this.isBusy = false;
    this.isError = true;

    this.Stop.emit(error);
  }
}
