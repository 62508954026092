import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TaxcasesService } from '../../../../../app/services/data/taxcases.service';

@Component({
  selector: 'app-dashboard-navigation',
  templateUrl: './dashboard-navigation.component.html',
  styleUrls: ['./dashboard-navigation.component.scss'],
})
export class DashboardNavigationComponent implements OnInit {
  constructor(public taxcasesservice: TaxcasesService, public title: Title) {
    // this.taxcasesservice.statisticcounters.subscribe(r => {
    //   console.log('statistics loaded', r);
    // })
  }

  ngOnInit(): void {}

  public updateTitle(titleName: string) {
    this.title.setTitle('ExS:: ' + titleName);
  }
}
