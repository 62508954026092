import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ContentTagEntry,
  ContentTaggable,
  DOCUMENTSOURCE,
  ExtractedDocument,
  TaxCase,
  TaxofficeLetter,
} from '@expresssteuer/models';
import { StoreStateIndicatorModel } from '../es-store-state-indicator/store-state-indicator.interface';

@Component({
  selector: 'esui-text-content-extraction',
  templateUrl: './esui-text-content-extraction.component.html',
  styleUrls: ['./esui-text-content-extraction.component.scss'],
})
export class EsuiTextContentExtractionComponent {
  selectedTextBlocks: Set<string> = new Set<string>();
  selectedTags: string[] = [];
  selectedTaxcasesIds: Set<string> = new Set<string>();
  selectedYears: Set<string> = new Set<string>();

  @Input()
  prefilterTagCategory?: 'DATA_REQUIRED' | 'PROVIDER_DATA' | 'NONE' = 'NONE';

  @Input()
  withYearSelection = true;

  @Input()
  taxofficeLetterStoreStateIndicator?: Partial<StoreStateIndicatorModel> | null;

  @Input()
  currentError: string | null = null;

  @Input()
  structuredOcr?: ExtractedDocument | null;

  @Input()
  taxCases?: TaxCase[] | null;

  @Input()
  originalDocument?: {
    contentType: string;
    downloadUrl: string;
    source?: DOCUMENTSOURCE | null;
  } | null;

  @Input()
  taxofficeLetter?: ContentTaggable | null;

  @Output()
  taxofficeLetterChange: EventEmitter<ContentTaggable> =
    new EventEmitter<ContentTaggable>();

  @Output()
  verifyAndSave = new EventEmitter<void>();

  @Output()
  save = new EventEmitter<void>();

  @Output()
  discard = new EventEmitter<void>();

  onTaxofficeLetterChange(letterData: Partial<TaxofficeLetter>) {
    if (this.taxofficeLetter) {
      const letter = { ...this.taxofficeLetter, ...letterData };
      this.taxofficeLetterChange.next(letter);
    }
  }

  toggleTaxcaseSelect(taxcaseId: string, taxYear: string) {
    this.selectedTaxcasesIds.has(taxcaseId)
      ? this.selectedTaxcasesIds.delete(taxcaseId)
      : this.selectedTaxcasesIds.add(taxcaseId);
  }

  clearSelections() {
    this.selectedTextBlocks.clear();
    this.selectedTags = [];
    this.selectedTaxcasesIds.clear();
    this.selectedYears.clear();
  }

  removeContentTagsByIndex(indices: number[]) {
    const contentTags = this.taxofficeLetter?.contentTags ?? [];
    const filteredTags = contentTags.filter((v, i) => !indices.includes(i));
    this.onTaxofficeLetterChange({ contentTags: filteredTags });
  }

  onYearsSelected(selected: Set<string>) {
    selected.forEach((year) => {
      this.selectedYears.add(year);
    });
  }

  onDeleteYears(year: string) {
    this.selectedYears.delete(year);
  }
  onDeleteAllYears() {
    this.selectedYears.clear();
  }

  addCurrentContentTagAndClearState() {
    const contentTags = [...(this.taxofficeLetter?.contentTags ?? [])];
    const textBlockMapping: ContentTagEntry['textToBlockMapping'] =
      Array.from(this.selectedTextBlocks.values()).map((ids) => {
        const pageIndex = parseInt(ids.split('-')[0]);
        const blockIndex = parseInt(ids.split('-')[1]);
        const block =
          this.taxofficeLetter?.visionOCRStructured?.pages[pageIndex].blocks[
            blockIndex
          ];
        return {
          text: block?.text,
          boundingBox: block?.boundingBox ?? undefined,
        };
      }) ?? [];
    const textAggregated = Array.from(this.selectedTextBlocks.values())
      .map((ids) => {
        const pageIndex = parseInt(ids.split('-')[0]);
        const blockIndex = parseInt(ids.split('-')[1]);
        return (
          this.taxofficeLetter?.visionOCRStructured?.pages[pageIndex].blocks[
            blockIndex
          ].text ?? ''
        );
      })
      .reduce((acc, curr) => acc + curr, '');
    const taxcases = Array.from(this.selectedTaxcasesIds.values());
    const affectedYears = Array.from(this.selectedYears.values()).reduce(
      (acc, curr) => {
        return { ...acc, [curr]: true };
      },
      {} as { [key: number]: true }
    );
    if (Object.keys(affectedYears).length === 0) {
      this.currentError = 'Please select at least 1 year, 1 tag';
      return;
    }
    for (const tag of this.selectedTags) {
      contentTags.push({
        contentTag: tag as any,
        textBlock: textAggregated,
        associatedTaxcasesRefs: taxcases,
        textToBlockMapping: textBlockMapping,
        affectedYears: affectedYears,
      });
    }
    this.onTaxofficeLetterChange({ contentTags: contentTags });
    this.currentError = null;
    this.clearSelections();
  }
}
