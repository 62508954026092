/* eslint-disable */

export interface VaSt_RIE {
  VaSt_RIE: VaSt_RIE_CType;
}

export interface VaSt_RIE_CType {
  /**
   * Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung
   */
  Eingangsdatum?: string;
  BetroffenerFall?: BetroffenerFallM2062363430_CType;
  Versicherung?: VersicherungM2062363430_CType;
  Vertragsdaten?: VertragsdatenM2062363430_CType;
  version: string;
}

export interface BetroffenerFallM2062363430_CType {
  /**
   * Meldejahr
   */
  Meldejahr?: string;
  NatPerson?: NatPerson_1957120765_CType;
}

export interface NatPerson_1957120765_CType {
  /**
   * Identifikationsnummer
   */
  IdNr?: string;
  /**
   * Titel
   */
  Titel?: string;
  /**
   * Vorname
   */
  Vorname?: string;
  /**
   * Namensvorsatz
   */
  NamensVorsatz?: string;
  /**
   * Name
   */
  Name?: string;
  /**
   * Namenszusatz
   */
  NamensZusatz?: string;
  /**
   * Name
   */
  Blockname?: string;
}

export interface VersicherungM2062363430_CType {
  /**
   * Anbieternummer
   */
  Anbieternummer?: string;
  /**
   * Name der Versicherung
   */
  Name?: string;
}

export interface VertragsdatenM2062363430_CType {
  /**
   * Einwilligungsdatum (zur Datenübermittlung)
   */
  EinwilligDat?: string;
  /**
   * Vertragsnummer
   */
  VertragsNr?: string;
  /**
   * Zertifizierungsnummer
   */
  ZertifiNr?: string;
  /**
   * Sozialversicherungs-/Zulagenummer
   */
  SozialversicherungsNr?: string;
  /**
   * Mitgliedsnummer der landwirtschaftlichen Alterskasse
   */
  MitgliedsNr?: string;
  /**
   * Eine steuerliche Berücksichtigung ist beabsichtigt
   * @note added in 2021
   */
  SteuerlBerueckAV?: string;
  /**
   * @maxItems 2
   */
  BeitragsDaten?:
    | BeitragsDatenM1713591000_CType
    | BeitragsDatenM1713591000_CType[];
}

export interface BeitragsDatenM1713591000_CType {
  /**
   * Beitragsart
   */
  BetragArt?: string;
  /**
   * Höhe der Beiträge/Tilgungsleistungen
   */
  Betrag?: string;
}
