import { isAbstractTimestamp } from '@expresssteuer/firebase-helper';
import { Timestamp } from '@expresssteuer/models';
import { ClientTodoId } from './client-todo-id';

export enum ClientTodoType {
  ClientIdentifyingDocument = 'ClientIdentifyingDocument',
  PartnerIdentifyingDocument = 'PartnerIdentifyingDocument',
  ClientTaxId = 'ClientTaxId',
  PartnerTaxId = 'PartnerTaxId',
  ClientPayoutIBAN = 'ClientPayoutIBAN',
  OneTouch = 'OneTouch',
  TaxYearVerificationAndSend = 'TaxYearVerificationAndSend',
  VmaProof = 'VmaProof',
  PartnerSignature = 'PartnerSignature',
}

export interface TodoMatcherMetadata<T = unknown> {
  matcherId: string;
  input: T;
}

export type ClientTodoVersion = '0.1' | '0.2';

export enum EscalatedBy {
  Client = 'Client',
  Automatically = 'Automatically',
}

export interface ClientTodo {
  version: ClientTodoVersion | null;

  /**
   * The unique id for the task
   */
  readonly id: ClientTodoId;
  /**
   * An object to uniquely identify this task (eg. to match with incoming documents)
   * aka. "Composite Primary Key" for a Task
   */
  readonly todoMatcherMetadata: TodoMatcherMetadata;
  /**
   * TODO refine, maybe array?
   */
  year: number | null;
  /**
   * The specific kind of todo;
   * There can be multiple todos with the same type, but
   * not with the same `todoMatcherMetadata`/`id`.
   */
  type: ClientTodoType;

  active: boolean;
  /**
   * When set to true, this task will not be deactivated by
   * the system automatically
   */
  manualMode: boolean;
  /**
   * The time deactivated was last set
   */
  activeLastSetAt: Timestamp;
  /**
   * The creation Date for the task
   */
  createdAt: Timestamp;
  /**
   * The last update Date for the task
   */
  updatedAt: Timestamp;

  /**
   * `${clientAnswerId}_${feedbackId}`[];
   */
  acceptedAnswers: string[];
  /**
   * `${clientAnswerId}_${feedbackId}`[];
   */
  faultyAnswers: string[];
  /**
   * `${clientAnswerId}_${feedbackId}`[];
   */
  processingAnswers: string[];
  /**
   * `${clientAnswerId}`[];
   */
  draftAnswers: string[];

  /**
   * Escalation flag for a smartTaskReflector
   * for CS agents.
   */
  helpNeeded: boolean;

  /**
   * Who or what triggered the escalation.
   */
  escalatedBy?: EscalatedBy | null;

  /**
   * When was the last time the escalation was triggered
   */
  lastEscalatedAt?: Timestamp | null;

  /**
   * Partial Client copy
   */
  client: {
    createdAt: Timestamp | null;
  };
}

/**
 * Metadata for clientTodoFields
 * @property [k in keyof ClientTodo]: { autoGenerated: boolean; }
 * NOTE: the keys are not typed so we can infer the fields
 * types; This is subject to change once we update to
 * TypeScript 4.9
 * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-9.html#the-satisfies-operator
 *
 * For now, the type safety is assured through a unit test.
 */
export const clientTodoPropertyMetadata =
  // : {
  //   [k in keyof ClientTodo]: {
  //     autoGenerated: boolean;
  //   };
  // }
  {
    id: {
      autoGenerated: true as const,
    },
    version: {
      autoGenerated: false as const,
    },
    todoMatcherMetadata: {
      autoGenerated: false as const,
    },
    year: {
      autoGenerated: false as const,
    },
    type: {
      autoGenerated: false as const,
    },
    active: {
      autoGenerated: false as const,
    },
    manualMode: {
      autoGenerated: false as const,
    },
    activeLastSetAt: {
      autoGenerated: true as const,
    },
    createdAt: {
      autoGenerated: true as const,
    },
    updatedAt: {
      autoGenerated: true as const,
    },
    acceptedAnswers: {
      autoGenerated: false as const,
    },
    faultyAnswers: {
      autoGenerated: false as const,
    },
    processingAnswers: {
      autoGenerated: false as const,
    },
    draftAnswers: {
      autoGenerated: false as const,
    },
    helpNeeded: {
      autoGenerated: false as const,
    },
    escalatedBy: {
      autoGenerated: false as const,
    },
    lastEscalatedAt: {
      autoGenerated: false as const,
    },
    client: {
      autoGenerated: true as const,
    },
  };

type KeysMatching<T extends object, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

type AutogeneratedKeys = KeysMatching<
  typeof clientTodoPropertyMetadata,
  {
    autoGenerated: true;
  }
>;

export type ClientTodoWithoutAutoGeneratedFields<
  TClientTodo extends ClientTodo = ClientTodo
> = Omit<TClientTodo, AutogeneratedKeys>;

export type AutoGeneratedClientTodoFields = Pick<ClientTodo, AutogeneratedKeys>;

export function isClientTodo(data: unknown): data is ClientTodo {
  const d = (data as Partial<ClientTodo>) || undefined;
  const isStringArray = (d: unknown): d is string[] => {
    return Array.isArray(d) && d.every((e) => typeof e === 'string');
  };

  return (
    !!d &&
    !!d.id &&
    typeof d.id === 'string' &&
    !!d.todoMatcherMetadata &&
    (!d.year || !d.year === null) &&
    Object.values(ClientTodoType).includes(d.type as ClientTodoType) &&
    typeof d.active === 'boolean' &&
    isAbstractTimestamp(d.createdAt) &&
    isAbstractTimestamp(d.activeLastSetAt) &&
    isAbstractTimestamp(d.createdAt) &&
    isAbstractTimestamp(d.updatedAt) &&
    isStringArray(d.acceptedAnswers) &&
    isStringArray(d.faultyAnswers) &&
    isStringArray(d.processingAnswers)
  );
}

export function getClientTodoTemplate<
  TTodoMatcherMetadata extends TodoMatcherMetadata = TodoMatcherMetadata,
  TClientTodoType extends ClientTodoType = ClientTodoType
>(args: {
  todoMatcherMetadata: TTodoMatcherMetadata;
  type: TClientTodoType;
}): ClientTodoWithoutAutoGeneratedFields & {
  todoMatcherMetadata: TTodoMatcherMetadata;
  type: TClientTodoType;
} {
  return {
    version: '0.2',
    todoMatcherMetadata: args.todoMatcherMetadata,
    year: null, // TODO
    type: args.type,
    active: true,
    manualMode: false,
    acceptedAnswers: [],
    faultyAnswers: [],
    processingAnswers: [],
    draftAnswers: [],
    helpNeeded: false,
    escalatedBy: null,
    lastEscalatedAt: null,
  };
}

export interface Answer {
  id?: string | null;
  ready: boolean;
  payload: unknown;
  createdAt: Timestamp;
}

export function isAnswer(data: unknown): data is Answer {
  const d = data as Partial<Answer> | null;
  return (
    !!d && typeof d.ready === 'boolean' && isAbstractTimestamp(d.createdAt)
  );
}

export enum FeedbackProvider {
  Nanonets = 'Nanonets',
  TaskIsland = 'TaskIsland',
}

export interface AnswerFeedback {
  data: unknown | null;
  accepted: boolean | null;
  isProcessing: boolean;
  createdAt: Timestamp;
  provider: FeedbackProvider;
}

export type IsTodo<TClientTodo extends ClientTodo> = (
  todo: unknown
) => todo is TClientTodo;

export type IsAnswer<TAnswer extends Answer> = (
  todo: unknown,
  answer: unknown
) => answer is TAnswer;

export type IsFeedback<TFeedback extends AnswerFeedback> = (
  todo: unknown,
  feedback: unknown
) => feedback is TFeedback;

export * from './client-todo-id';
export * from './lib/client-todo-escalation.api-interface';
export * from './lib/manual-trigger.api-interface';
export * from './lib/submit-manual-validation-result.api-interface';
export * from './question-and-answers/index';
export * as allQAAs from './question-and-answers/index';
