export interface FunctionFeatureConfigurations {
  smsVerificationSendTimeoutSeconds?: number;
  smsVerificationMaxAttempts?: number;

  /**
   * begin: braze configs
   */
  brazeSyncClientTodos?: boolean;
  skipOneTouchEditsRatioInPct?: number;
  enableTaxcaseAutomationUnattractiveWhatsapp?: boolean;
  enableTaxcaseAutomationWaitingfordataretrievalSentEmail?: boolean;
  enableTaxcaseAutomationWaitingfordataretrievalSentWhatsapp?: boolean;
  enableTaxcaseAutomationReadytosendWhatsapp?: boolean;
  enableTaxcaseAutomationSenttotaxofficeWhatsapp?: boolean;
  enableTaxcaseAutomationQuestionsfromtaxofficeWhatsapp?: boolean;
  enableTaxcaseAutomationAppealedWhatsapp?: boolean;
  enableTaxcaseAutomationPaidWhatsapp?: boolean;
  configForMessageOnManualRejectedClientTodo?: {
    [clientTodoType: string]: {
      brazeCanvas?: {
        enabled?: boolean;
        canvasId?: string;
      };
      channels?: {
        [channel: string]: boolean;
      };
    };
  };
  /**
   * end: braze configs
   */

  nanonetsEnableBetaZeroTrainIdentificationDocumentModel?: boolean;
  /**
   * number between 1 and 0
   */
  plausibilityCheckerRandomQA?: number;

  /**
   * configurations for the VAST (Deric) service
   */
  vast?: {
    /**
     * original Mehdi version
     */
    enableVastVersion1?: boolean;
    /**
     * new separated version with no automatic applying of the data
     */
    enableVastVersion2?: boolean;
    /**
     * Amount of data to be downloaded per vast service call and processed within G Cloud Tasks
     */
    vastProcessingLimit?: number;
  };

  documentPipeline?: {
    /**
     * Witch version of the prediction model should be used
     * possible values (nanonets, palm)
     */
    usePredictionVersion?: string;
  };
}
