import { Component, Input } from '@angular/core';
import { MESSAGESTATUS, MESSAGETYPE } from '@expresssteuer/models';

@Component({
  selector: 'app-status-bubble',
  templateUrl: './status-bubble.component.html',
  styleUrls: ['./status-bubble.component.scss'],
})
export class StatusBubbleComponent {
  constructor() {}

  MESSAGESTATUS = MESSAGESTATUS;
  MESSAGETYPE = MESSAGETYPE;

  private mStatus: MESSAGESTATUS;
  public color = 'bubble-error';

  @Input() public type: MESSAGETYPE;

  @Input()
  public set status(val: MESSAGESTATUS) {
    switch (val) {
      case MESSAGESTATUS.QUENUE:
        this.color = 'bubble-blue';
        break;
      case MESSAGESTATUS.SENT:
      case MESSAGESTATUS.DELIVERED:
      case MESSAGESTATUS.RECIEVED:
        this.color = 'bubble-gray';
        break;
      case MESSAGESTATUS.READ:
        this.color = 'bubble-green';
        break;
      default:
        this.color = 'bubble-red';
    }
    this.mStatus = val;
  }
  public get status(): MESSAGESTATUS {
    return this.mStatus;
  }
}
