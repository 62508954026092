import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'esui-action-box',
  templateUrl: './action-box.component.html',
  styleUrls: ['./action-box.component.scss'],
})
export class ActionBoxComponent {
  @Output() saveClick = new EventEmitter<void>();

  @Input() status?: {
    isSaving?: boolean | null;
    isLoading?: boolean | null;
    hasChanges?: boolean | null;
    hasErrors?: boolean | null;
  } | null;
}
