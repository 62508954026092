import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientIdentifyingProofQAA } from '@expresssteuer/client-todos-api-interfaces';
import { asDateString } from '@expresssteuer/models';

// TODO: type should be the validationResult type of the handler. try to import it here
type ValidationResult = ClientIdentifyingProofQAA.PageData & {
  expirationDateTimestamp?: { seconds: number };
};

@Component({
  selector: 'esui-client-identifying-proof-feedback-form',
  templateUrl: './esui-client-identifying-proof-feedback-form.component.html',
  styleUrls: ['./esui-client-identifying-proof-feedback-form.component.scss'],
})
export class EsuiClientIdentifyingProofFeedbackFormComponent {
  @Input()
  firstnameExpected?: string;
  @Input()
  lastnameExpected?: string;

  @Input()
  validationResult?: ValidationResult;

  @Output()
  validationResultChange = new EventEmitter<Record<string, unknown>>();

  propChange(update: Partial<ValidationResult>) {
    if (update.expirationDateTimestamp != null) {
      update.expirationDate = asDateString(
        new Date(update.expirationDateTimestamp.seconds * 1000),
        null
      );
    }
    this.validationResultChange.next({ ...this.validationResult, ...update });
  }
}
