import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IdentifyingDocumentType,
  identifyingDocumentTypes,
} from '@expresssteuer/document-validate-identifying-proof';
import {
  BinaryDocument,
  DOCUMENTTYPE,
  SpecificDocument,
} from '@expresssteuer/models';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'esui-document-list',
  templateUrl: './esui-document-list.component.html',
  styleUrls: ['./esui-document-list.component.scss'],
  animations: [
    trigger('animateDestroy', [
      state(
        'void',
        style({ transform: 'scaleY(0)', transformOrigin: 'top', height: 0 })
      ),
      transition('* => void', animate('500ms ease')),
    ]),
  ],
})
export class DocumentListComponent {
  DOCUMENTTYPE = DOCUMENTTYPE;

  sortedDocuments$ = new BehaviorSubject<Record<
    DOCUMENTTYPE | 'unknown',
    (BinaryDocument & { tags: string[] })[]
  > | null>(null);

  @Input() set documents(values: BinaryDocument[] | undefined | null) {
    const withTags = values?.map((doc) => {
      const tags = [] as string[];
      if (
        identifyingDocumentTypes.includes(doc.type as IdentifyingDocumentType)
      ) {
        const d = doc as unknown as SpecificDocument<IdentifyingDocumentType>;
        if (d.parsedFields?.firstname) {
          tags.push(d.parsedFields.firstname);
        }
        if (d.parsedFields?.lastname) {
          tags.push(d.parsedFields.lastname);
        }
      }

      if (
        [
          DOCUMENTTYPE.wagestatement,
          DOCUMENTTYPE.incometaxstatement,
          DOCUMENTTYPE.plainTaxId,
        ].includes(doc.type as DOCUMENTTYPE)
      ) {
        const d = doc as unknown as SpecificDocument<
          | DOCUMENTTYPE.wagestatement
          | DOCUMENTTYPE.incometaxstatement
          | DOCUMENTTYPE.plainTaxId
        >;
        if (d.parsedFields?.taxId) {
          tags.push(`${d.parsedFields.taxId}`);
        }
        if (d.parsedFields?.firstname) {
          tags.push(d.parsedFields.firstname);
        }
        if (d.parsedFields?.lastname) {
          tags.push(d.parsedFields.lastname);
        }
      }

      return { ...doc, tags };
    });

    const groupedByType =
      withTags?.reduce((acc, curr) => {
        const type = (curr.type || 'unknown') as DOCUMENTTYPE | 'unknown';
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push(curr);
        return acc;
      }, {} as Record<DOCUMENTTYPE | 'unknown', (BinaryDocument & { tags: string[] })[]>) ??
      null;

    this.sortedDocuments$.next(groupedByType);
  }

  @Output() ngModelChange = new EventEmitter<BinaryDocument[]>();
  @Input() ngModel?: BinaryDocument[] | null = [];

  trackById(index: number, { id }: { id: string }) {
    return index + id;
  }
}
