import { SchemaProperty } from './vast';

export const VastVwlSchema: SchemaProperty = {
  title: 'VaSt_VWL-202201.xsd',
  $schema: 'http://json-schema.org/schema#',

  type: 'object',
  properties: {
    VaSt_VWL: {
      type: 'object',
      description: 'Vermögensbildungsbescheinigung',
      properties: {
        Eingangsdatum: {
          description:
            'Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung',
          type: 'string',
        },
        Anbieter: {
          description: 'Name des Anbieters',
          type: 'string',
        },
        Meldejahr: {
          description: 'Meldejahr',
          type: 'string',
        },
        BetroffenePerson: {
          type: 'object',
          properties: {
            IdNr: {
              description: 'Identifikationsnummer',
              type: 'string',
            },
            Vorname: {
              description: 'Vorname',
              type: 'string',
            },
            Name: {
              description: 'Name',
              type: 'string',
            },
            Blockname: {
              description: 'Name',
              type: 'string',
            },
            NamensVorsatz: {
              description: 'Namensvorsatz',
              type: 'string',
            },
            NamensZusatz: {
              description: 'Namenszusatz',
              type: 'string',
            },
            Titel: {
              description: 'Titel',
              type: 'string',
            },
            GebDat: {
              description: 'Geburtsdatum',
              type: 'string',
            },
          },
        },
        Vertrag: {
          type: 'object',
          properties: {
            EinwilligDat: {
              description: 'Einwilligungsdatum (zur Datenübermittlung)',
              type: 'string',
            },
            AnlageArt: {
              description: 'Art der Anlage',
              type: 'string',
              enum: ['1', '2', '3', '4', '8'],
            },
            InstitutsSchluessel: {
              description: 'Institutsschlüssel (IFAS)',
              type: 'string',
            },
            VertragsNr: {
              description: 'Vertragsnummer',
              type: 'string',
            },
            Betrag: {
              description: 'Vermögenswirksame Leistungen',
              type: 'number',
              stringifier: 'currency-eur',
            },
            EndeSperrfrist: {
              description: 'Ende der Sperrfrist',
              type: 'string',
            },
          },
        },
        version: {
          type: 'string',
          enum: ['202201'],
        },
      },
      required: ['version'],
    },
  },
  required: ['VaSt_VWL'],
};
