import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Subscription } from 'rxjs';
import { HotkeysService } from '../../../es-hotkeys/hotkeys.service';

@Component({
  selector: 'esui-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit, OnDestroy {
  @Input()
  public downloadUrl!: string;

  subs: Subscription[] = [];

  zoom: number = 100;
  rotation: number = 0;

  zoomBy(amount: number) {
    this.zoom += amount;
  }

  rotateBy(amount: number) {
    this.rotation = (this.rotation += amount) % 360;
  }

  reset() {
    this.zoom = 100;
    this.rotation = 0;
  }

  ngOnInit() {
    if (!this.hotkeysService) {
      return;
    }

    this.subs.push(
      this.hotkeysService
        ?.register({
          combo: 'alt+r',
          description: 'rotate image',
        })
        .subscribe(() => this.rotateBy(90)),
      this.hotkeysService
        ?.register({
          combo: ['alt+plus', 'alt+='],
          description: 'zoom in image',
        })
        .subscribe(() => this.zoomBy(10)),
      this.hotkeysService
        ?.register({
          combo: 'alt+-',
          description: 'zoom out image',
        })
        .subscribe(() => this.zoomBy(-10)),
      this.hotkeysService
        ?.register({
          combo: 'alt+0',
          description: 'reset zoom/rotation',
        })
        .subscribe(() => this.reset())
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.subs = [];
  }

  constructor(@Optional() private hotkeysService?: HotkeysService) {}
}
