import { Timestamp } from '@google-cloud/firestore';
import { BankProvider } from './bank-provider';

export interface BankTransaction {
  provider:
    | 'qonto'
    | 'stripe'
    | 'taxadvisor'
    | BankProvider.A352
    | string
    | null;
  amount: number;
  amount_cents: number;
  currency: string;
  emitted_at: Timestamp | Date | null;
  transaction_id: string;
  reference: string;
  //QONTO (old): pending' | 'reversed' | 'declined' | 'completed'
  //A352: 'BOOKED' | 'PENDING' | 'DELETED'
  //reversed, declined => DELETED
  //completed => BOOKED
  status:
    | 'pending'
    | 'reversed'
    | 'declined'
    | 'completed'
    | 'BOOKED'
    | 'PENDING'
    | 'DELETED'
    | string;
  iban: string;
  vIban: string;
}

/**
 * this is not for abstractions but for emergency-cases like support of Qonto
 * For our business-logic use BankTransaction and DO NOT RELY on this.
 * If there is provider-specific details, you have to go to the raw data
 * @deprecated
 */
export interface ITransactions<ITransaction extends BankTransaction> {
  transactions: ITransaction[];
}

export class BankTransactions {
  transactions: BankTransaction[] = [];
}
