<div *ngIf="taxcase">
  <div class="row">
    <div
      class="col"
      *ngIf="
        taxcase.taxform.current.taxForm.information.maritalStatus ===
        MARITALSTATUS.married
      "
    >
      <span class="label label-dark-light label-inline mr-2">Verheiratet</span>
    </div>
    <div
      class="col"
      *ngIf="
        taxcase.taxform.current.taxForm.information.maritalStatus ===
        MARITALSTATUS.divorced
      "
    >
      <span class="label label-dark-light label-inline mr-2">Geschieden</span>
    </div>

    <div
      class="col"
      *ngIf="taxcase.taxform.current.taxForm.childrens.length > 0"
    >
      <i class="fa fa-child"></i>:{{
        taxcase.taxform.current.taxForm.childrens.length
      }}
    </div>

    <div
      class="col"
      *ngIf="taxcase.taxform.current.taxForm.information.hasDisability"
    >
      <i class="fab fa-accessible-icon"></i>
    </div>
  </div>
</div>
