<mat-button-toggle-group
  class="mat-button-toggle-appearance-small"
  multiple
  [ngModel]="value | split"
  (ngModelChange)="updatePermission($event)"
  [disabled]="disabled"
>
  <mat-button-toggle
    *ngFor="let permission of permissionList | keyvalue; trackBy: trackByKey"
    [value]="permission.key"
    [title]="permission.value"
  >
    {{ permission.key }}
  </mat-button-toggle>
</mat-button-toggle-group>
