import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TaxNotice, TaxnoticeType } from '@expresssteuer/models';
import { StoreStateIndicatorModel } from '../es-store-state-indicator/store-state-indicator.interface';

@Component({
  selector: 'esui-taxnotice-appeal',
  templateUrl: './esui-taxnotice-appeal.component.html',
  styleUrls: ['./esui-taxnotice-appeal.component.scss'],
})
export class EsuiTaxnoticeAppealComponent {
  columnsToDisplay = [
    'Year',
    'Date',
    'Return Value',
    'Type',
    'Verified',
    'Appeal Status',
  ];
  @Input()
  taxnoticeDocumentStoreStateIndicator?: Partial<StoreStateIndicatorModel> | null;
  @Input() taxnoticeDocument?: {
    contentType: string;
    downloadUrl: string;
  } | null;

  @Input()
  elsterDocumentStoreStateIndicator?: Partial<StoreStateIndicatorModel> | null;
  @Input() elsterDocument?: {
    contentType: string;
    downloadUrl: string;
  } | null;

  @Input() taxNotice?: TaxNotice | null;
  @Input() sameYearTaxnotices?: TaxNotice[] | null;
  @Input() taxnoticeDifferenceThreshhold?: number | null;
  @Output() taxNoticeChange = new EventEmitter<TaxNotice>();
  @Output() taxNoticePartialChange = new EventEmitter<Partial<TaxNotice>>();

  @Output() appealClick = new EventEmitter<void>();
  @Output() notAppealClick = new EventEmitter<void>();

  partialTaxnoticeChange<K extends keyof TaxNotice>(
    key: K,
    value: TaxNotice[K]
  ): void;
  partialTaxnoticeChange(change: Partial<TaxNotice>): void;
  partialTaxnoticeChange<K extends keyof TaxNotice>(
    a: Partial<TaxNotice> | K,
    b?: TaxNotice[K]
  ): void {
    const change =
      typeof a === 'string'
        ? {
            [a]: b,
          }
        : a;

    const merged = { ...this.taxNotice, ...change };
    this.taxNoticePartialChange.next(change);
    this.taxNoticeChange.next(merged as any);
  }

  sumOfTaxnoticeActualReturns(): number {
    let overall = this.taxNotice?.actualTaxReturn ?? 0;
    overall +=
      this.sameYearTaxnotices
        ?.map((taxnotice) => taxnotice.actualTaxReturn ?? 0)
        .reduce((p, c) => p + c, 0) ?? 0;
    return overall;
  }

  expectedTaxnoticeReturn(): number {
    if (
      this.taxNotice &&
      (this.taxNotice.taxnoticeType === TaxnoticeType.INITIAL ||
        this.taxNotice.taxnoticeType === undefined ||
        this.taxNotice.taxnoticeType === null)
    ) {
      return this.taxNotice.expectedTaxReturn ?? 0;
    } else {
      const initialTaxnotice = this.sameYearTaxnotices?.filter(
        (e) =>
          e.taxnoticeType === TaxnoticeType.INITIAL ||
          e.taxnoticeType === null ||
          e.taxnoticeType === undefined
      );
      if (initialTaxnotice && initialTaxnotice.length > 0) {
        return initialTaxnotice[0].expectedTaxReturn ?? 0;
      }
      return 0;
    }
  }

  calculatedDiferrence(): number {
    return this.sumOfTaxnoticeActualReturns() - this.expectedTaxnoticeReturn();
  }
}
