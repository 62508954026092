import { Component, Input, OnInit } from '@angular/core';
import { BinaryDocument } from '@expresssteuer/models';
import {
  DocumentsService,
  TYPE,
} from '../../../../../app/services/data/documents.service';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
})
export class DocumentPreviewComponent implements OnInit {
  TYPE = TYPE;

  public document: BinaryDocument;
  public type: TYPE;
  public documentType: string;

  constructor(public documentsservice: DocumentsService) {
    documentsservice.isLoaded.subscribe((document) => {
      console.log('loaded');
      this.document = document;
      this.type = documentsservice.type;
      this.initDocumentType(document);
    });
  }

  @Input()
  public set documentid(id: string) {
    console.log('loading document ', id);
    this.documentsservice.load(id);
  }

  ngOnInit(): void {}

  public initDocumentType(document: BinaryDocument) {
    if (document.metadata.type.expense) {
      this.documentType = 'expense';
    } else if (document.metadata.type.identitycard) {
      this.documentType = 'identitycard';
    } else if (document.metadata.type.other) {
      this.documentType = 'other';
    } else if (document.metadata.type.receipt) {
      this.documentType = 'receipt';
    } else if (document.metadata.type.signature) {
      this.documentType = 'signature';
    } else if (document.metadata.type.wagestatement) {
      this.documentType = 'wagestatement';
    } else {
      this.documentType = 'other';
    }
  }

  public updateDocumentType() {
    this.resetDocumentType();

    if (this.documentType === 'expense') {
      this.document.metadata.type.expense = true;
    } else if (this.documentType === 'identitycard') {
      this.document.metadata.type.identitycard = true;
    } else if (this.documentType === 'other') {
      this.document.metadata.type.other = true;
    } else if (this.documentType === 'receipt') {
      this.document.metadata.type.receipt = true;
    } else if (this.documentType === 'signature') {
      this.document.metadata.type.signature = true;
    } else if (this.documentType === 'taxoffice') {
      this.document.metadata.type.taxoffice = true;
    } else if (this.documentType === 'testtaxoffice') {
      this.document.metadata.type.testtaxoffice = true;
    } else if (this.documentType === 'wagestatement') {
      this.document.metadata.type.wagestatement = true;
    } else {
      this.document.metadata.type.other = true;
    }

    this.update();
  }

  public resetDocumentType() {
    this.document.metadata.type.expense = false;
    this.document.metadata.type.identitycard = false;
    this.document.metadata.type.other = false;
    this.document.metadata.type.receipt = false;
    this.document.metadata.type.signature = false;
    this.document.metadata.type.taxoffice = false;
    this.document.metadata.type.testtaxoffice = false;
    this.document.metadata.type.wagestatement = false;
  }

  public async update() {
    await this.documentsservice.update(this.document);
  }
}
