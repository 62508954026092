<div
  class="btn-group btn-group-lg"
  role="group"
  aria-label="Large button group"
>
  <button
    type="button"
    class="btn btn-outline-secondary font-weight-bold"
    [ngClass]="{ active: taxclass === 1 }"
    (click)="taxclass = 1"
  >
    1
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary font-weight-bold"
    [ngClass]="{ active: taxclass === 2 }"
    (click)="taxclass = 2"
  >
    2
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary font-weight-bold"
    [ngClass]="{ active: taxclass === 3 }"
    (click)="taxclass = 3"
  >
    3
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary font-weight-bold"
    [ngClass]="{ active: taxclass === 4 }"
    (click)="taxclass = 4"
  >
    4
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary font-weight-bold"
    [ngClass]="{ active: taxclass === 5 }"
    (click)="taxclass = 5"
  >
    5
  </button>
</div>
