import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-firebase-date-picker',
  templateUrl: './firebase-date-picker.component.html',
  styleUrls: ['./firebase-date-picker.component.scss'],
})
export class FirebaseDatePickerComponent {
  private dateValue: Timestamp = Timestamp.now();

  @Output()
  dateChange = new EventEmitter<Timestamp>();

  @Input()
  set date(val: Timestamp) {
    this.dateValue = val;
  }

  get dateViewModel() {
    return this.dateValue;
  }

  set dateViewModel(val) {
    this.dateValue = val;
    this.dateChange.emit(this.dateValue || null);
  }
}
