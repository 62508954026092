import { Component, Input, TemplateRef } from '@angular/core';
import { ClientTaxIdProofQAA } from '@expresssteuer/client-todos-api-interfaces';

@Component({
  selector: 'esui-client-taxid-proof-answer',
  templateUrl: './esui-client-taxid-proof-answer.component.html',
  styleUrls: ['./esui-client-taxid-proof-answer.component.scss'],
})
export class EsuiClientTaxidProofAnswerComponent {
  @Input()
  documentViewerTemplate?: TemplateRef<{ binaryDocumentRef: string }>;

  @Input()
  answerPayload?: ClientTaxIdProofQAA.AnswerPayload;
}
