<mat-card>
  <mat-card-header>
    <mat-card-title>Failing VM Request</mat-card-title>
  </mat-card-header>
  <mat-card-content class="card-container" *ngIf="vmRequest">
    <mat-form-field appearance="fill">
      <label>TaxId</label>
      <esui-text-input
        [ngModel]="vmRequest.taxId"
        (ngModelChange)="taxIdChange.emit($event)"
      ></esui-text-input>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <label>Vorname</label>
      <esui-text-input
        [ngModel]="vmRequest.firstname"
        (ngModelChange)="firstNameChange.emit($event)"
      ></esui-text-input>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <label>Nachname</label>
      <esui-text-input
        [ngModel]="vmRequest.lastname"
        (ngModelChange)="lastNameChange.emit($event)"
      ></esui-text-input>
    </mat-form-field>
    <button
      type="button"
      mat-raised-button
      color="primary"
      (click)="saveVmRequest.emit()"
    >
      Save VM request
    </button>
  </mat-card-content>
</mat-card>
