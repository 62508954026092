import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

export enum PlaceholderType {
  title,
  smallSub,
  bigSub,
  boxSub,
}

@Component({
  selector: 'esui-skeleton-placeholder',
  templateUrl: './esui-skeleton-placeholder.component.html',
  styleUrls: ['./esui-skeleton-placeholder.component.scss'],
})
export class EsuiSkeletonPlaceholderComponent {
  watchInputDelayed = new BehaviorSubject(undefined as unknown);
  objectIsLoaded$ = this.watchInputDelayed.pipe(
    map((e) => e === undefined || e === null),
    switchMap((e) => {
      if (!e) {
        return new BehaviorSubject(e).pipe(delay(500));
      } else {
        return new BehaviorSubject(e);
      }
    })
  );
  @Input() skeletonBuildInput?: PlaceholderType[][];
  @Input() bigBoxMode?: boolean;
  @Input() set watchResult(watchResult: boolean) {
    this.watchInputDelayed.next(watchResult ? {} : null);
  }

  get PlaceholderType(): typeof PlaceholderType {
    return PlaceholderType;
  }
}
