import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EsuiGenericListComponent } from './esui-generic-list.component';

@NgModule({
  declarations: [EsuiGenericListComponent],
  exports: [EsuiGenericListComponent],
  imports: [CommonModule],
})
export class EsuiGenericListModule {}
