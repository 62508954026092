import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EsuiValidateWithValueValidatorDirective } from './esui-validate-with-value-validator.directive';

@NgModule({
  declarations: [EsuiValidateWithValueValidatorDirective],
  imports: [CommonModule],
  exports: [EsuiValidateWithValueValidatorDirective],
})
export class EsuiValidateWithValueValidatorModule {}
