import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EsuiSkeletonPlaceholderModule } from '../esui-skeleton-placeholder/esui-skeleton-placeholder.module';
import { EsuiTextOcrRendererComponent } from './esui-text-ocr-renderer.component';

@NgModule({
  declarations: [EsuiTextOcrRendererComponent],
  imports: [CommonModule, EsuiSkeletonPlaceholderModule],
  exports: [EsuiTextOcrRendererComponent],
})
export class EsuiTextOcrRendererModule {}
