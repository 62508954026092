import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  PhoneStartPhoneVerificationInput,
  PhoneStartPhoneVerificationOutput,
} from '@expresssteuer/client-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class PhoneStartPhoneVerification {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: PhoneStartPhoneVerificationInput) {
    console.log('Calling client-phoneStartPhoneVerification');
    return this.afFunctions.httpsCallable<
      PhoneStartPhoneVerificationInput,
      PhoneStartPhoneVerificationOutput
    >('client-phoneStartPhoneVerification')(input);
  }
}
