import { CalculationData2 } from '../calculation/calculation';
import { Timestamp } from '../helpers/timestamp';
import { Task } from '../task/task';
import { TaxCase, TaxCaseStatus } from '../taxcase/taxcase';
import { User } from '../user/user';

export enum CLIENTPAYMENTSTATUS {
  /**
   * There is no invoice created yet
   */
  NONE = 'none',
  /**
   * Invoice created, waiting for payment
   */
  OPEN = 'open',
  /**
   * Invoice has been paid
   */
  PAID = 'paid',
}

/**
 * The Cases a client has in his own client object to visualize in his list
 */
export class ClientTaxCase {
  public static getTemplate(): ClientTaxCase {
    return {
      taxFormId: '',
      taxYear: '',
      taxCaseId: '',
      assignedTo: null,
      lastUpdated: null,
      paymentStatus: CLIENTPAYMENTSTATUS.NONE,
    };
  }
  public static getFromTaxCase(taxcase: TaxCase): ClientTaxCase {
    return {
      taxYear: taxcase.year,
      taxCaseId: taxcase.id,
      status: taxcase.metadata.status,
      offer: taxcase.taxform.current.calculation,
      taxFormId: taxcase.taxform.current.taxForm.id,
      paymentStatus: CLIENTPAYMENTSTATUS.NONE,
      skipOneTouchEdits: false,
    };
  }

  /**
   * The original taxform id
   * @deprecated we do not need this anymore. Could be dangerus if the case was copied
   */
  public taxFormId = '';
  /**
   * The TaxCase Year
   */
  public taxYear = '';
  /**
   * The amount of taxreturn estimated by our employees
   */
  expectedTaxReturn?: number | null;
  /**
   * The TaxCase ID
   * @deprecated The ClientTaxCase.id in the collection is the same id as the TaxCase
   */
  public taxCaseId = '';
  public assignedTo?: User | null;
  public lastUpdated?: Timestamp | null;
  public status?: TaxCaseStatus;
  public pdfStatus?: PdfStatus;
  public offer?: CalculationData2 | null;
  public todos?: Task[] = [];
  public paymentStatus: CLIENTPAYMENTSTATUS = CLIENTPAYMENTSTATUS.NONE;
  /**
   * Whether this taxcase is qualified to skip one touch edits based on Grundfreibetrag.
   * Defaults to false and is only true if selected for experiment group or for new clients when experiment is finished.
   */
  public skipOneTouchEdits?: boolean = false;
}

/**
 * status of a pdf that is to be generated (ie. elster draft)
 */
export enum PdfStatus {
  Generating = 'GENERATING',
  Generated = 'GENERATED',
  Error = 'ERROR',
}
