import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-control-radio-button-group',
  templateUrl: './control-radio-button-group.component.html',
  styleUrls: ['./control-radio-button-group.component.scss'],
})
export class ControlRadioButtonGroupComponent implements OnInit {
  @Input() options: { value: string; display: string }[];

  private selectedValue: string;

  @Output()
  valueChange = new EventEmitter();

  @Input()
  get value() {
    return this.selectedValue;
  }

  set value(val: string) {
    this.selectedValue = val;
    this.valueChange.emit(this.selectedValue);
  }

  ngOnInit(): void {}
}
