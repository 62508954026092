import { Component, Input, OnInit } from '@angular/core';
import { Message, MESSAGETYPE } from '@expresssteuer/models';

declare var KTApp: any;
@Component({
  selector: 'app-control-message-message-preview',
  templateUrl: './control-message-message-preview.component.html',
  styleUrls: ['./control-message-message-preview.component.scss'],
})
export class ControlMessageMessagePreviewComponent implements OnInit {
  public MESSAGETYPE = MESSAGETYPE;

  constructor() {}

  @Input()
  message: Message;

  ngOnInit(): void {
    KTApp.initPopovers();
  }
}
