<ng-container [ngSwitch]="true">
  <!-- pdfs -->
  <div *ngSwitchCase="match('^application/pdf')">
    <img height="32" src="/assets/media/svg/files/pdf.svg" />
  </div>
  <!-- images -->
  <ng-container *ngSwitchCase="match('^image')">
    <ng-container [ngSwitch]="true">
      <div *ngSwitchCase="match('^image/jpg|^image/jpeg')">
        <img height="32" src="/assets/media/svg/files/jpg.svg" />
      </div>
      <div *ngSwitchDefault>
        <img height="32" src="assets/media/svg/icons/Files/Compiled-file.svg" />
      </div>
    </ng-container>
  </ng-container>
  <!-- videos -->
  <ng-container *ngSwitchCase="match('^video')">
    <ng-container [ngSwitch]="true">
      <div *ngSwitchCase="match('^video/mp4')">
        <img height="32" src="/assets/media/svg/files/mp4.svg" />
      </div>
      <div *ngSwitchDefault>
        <img height="32" src="assets/media/svg/icons/Files/Media.svg" />
      </div>
    </ng-container>
  </ng-container>
  <!-- audios -->
  <div *ngSwitchCase="match('^audio')">
    <img height="32" src="assets/media/svg/icons/Files/Music.svg" />
  </div>
  <!-- other -->
  <div *ngSwitchDefault>
    <img height="32" src="assets/media/svg/icons/Files/File.svg" />
  </div>
</ng-container>
