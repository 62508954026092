import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-control-document-safesearch',
  templateUrl: './control-document-safesearch.component.html',
  styleUrls: ['./control-document-safesearch.component.scss'],
})
export class ControlDocumentSafesearchComponent implements OnInit {
  constructor() {}

  @Input()
  public safeSearch: string;

  ngOnInit(): void {}
}
