/* eslint-disable */

export interface VaSt_Pers1 {
  VaSt_Pers1: {
    Inhaber?: {
      NatPers?: {
        AdrKette?: {
          AuslAdr?: {
            AdressErg?: string;
            AuslPlz?: string;
            HausNr?: string;
            HausNrZu?: string;
            Ort?: string;
            Str?: string;
          };
          PostfAdr?: {
            Ort?: string;
            Plz?: string;
            Postfach?: string;
          };
          StrAdr?: {
            AdressErg?: string;
            HausNr?: string;
            HausNrZu?: string;
            Ort?: string;
            Plz?: string;
            Str?: string;
            AuslAdr?: {
              AdressErg?: string;
              AuslPlz?: string;
              HausNr?: string;
              HausNrZu?: string;
              Ort?: string;
              Str?: string;
            };
            PostfAdr?: {
              Ort?: string;
              Plz?: string;
              Postfach?: string;
            };
            StrAdr?: {
              AdressErg?: string;
              HausNr?: string;
              HausNrZu?: string;
              Ort?: string;
              Plz?: string;
              Str?: string;
            };
          };
        };
        BankKonto?: {
          BIC?: string;
          Blz?: string;
          IBAN?: string;
          Kontonummer?: string;
          NameInstitut?: string;
          Ort?: string;
        };
        GebDat?: string;
        Name?: string;
        NamensVorsatz?: string;
        NamensZusatz?: string;
        QuellHinweis?: string;
        SteuerIDs?: {
          PersIdNr?: string;
        };
        Titel?: string;
        Vorname?: string;
        NatPers?: {
          AdrKette?: {
            AuslAdr?: {
              AdressErg?: string;
              AuslPlz?: string;
              HausNr?: string;
              HausNrZu?: string;
              Ort?: string;
              Str?: string;
            };
            PostfAdr?: {
              Ort?: string;
              Plz?: string;
              Postfach?: string;
            };
            StrAdr?: {
              AdressErg?: string;
              HausNr?: string;
              HausNrZu?: string;
              Ort?: string;
              Plz?: string;
              Str?: string;
              AuslAdr?: {
                AdressErg?: string;
                AuslPlz?: string;
                HausNr?: string;
                HausNrZu?: string;
                Ort?: string;
                Str?: string;
              };
              PostfAdr?: {
                Ort?: string;
                Plz?: string;
                Postfach?: string;
              };
              StrAdr?: {
                AdressErg?: string;
                HausNr?: string;
                HausNrZu?: string;
                Ort?: string;
                Plz?: string;
                Str?: string;
              };
            };
          };
          BankKonto?: {
            BIC?: string;
            Blz?: string;
            IBAN?: string;
            Kontonummer?: string;
            NameInstitut?: string;
            Ort?: string;
          };
          GebDat?: string;
          Name?: string;
          NamensVorsatz?: string;
          NamensZusatz?: string;
          QuellHinweis?: string;
          SteuerIDs?: {
            PersIdNr?: string;
          };
          Titel?: string;
          Vorname?: string;
        };
      };
    };
    Vertreter?: {
      NNatPers?: {
        AdrKette?: {
          AuslAdr?: {
            AdressErg?: string;
            AuslPlz?: string;
            HausNr?: string;
            HausNrZu?: string;
            Ort?: string;
            Str?: string;
          };
          GrossempfAdr?: {
            Ort?: string;
            Plz?: string;
          };
          PostfAdr?: {
            Ort?: string;
            Plz?: string;
            Postfach?: string;
          };
          StrAdr?: {
            AdressErg?: string;
            HausNr?: string;
            HausNrZu?: string;
            Ort?: string;
            Plz?: string;
            Str?: string;
            AuslAdr?: {
              AdressErg?: string;
              AuslPlz?: string;
              HausNr?: string;
              HausNrZu?: string;
              Ort?: string;
              Str?: string;
            };
            GrossempfAdr?: {
              Ort?: string;
              Plz?: string;
            };
            PostfAdr?: {
              Ort?: string;
              Plz?: string;
              Postfach?: string;
            };
            StrAdr?: {
              AdressErg?: string;
              HausNr?: string;
              HausNrZu?: string;
              Ort?: string;
              Plz?: string;
              Str?: string;
            };
          };
        };
        Firmenname?: string;
      };
      NatPers?: {
        AdrKette?: {
          AuslAdr?: {
            AdressErg?: string;
            AuslPlz?: string;
            HausNr?: string;
            HausNrZu?: string;
            Ort?: string;
            Str?: string;
          };
          PostfAdr?: {
            Ort?: string;
            Plz?: string;
            Postfach?: string;
          };
          StrAdr?: {
            AdressErg?: string;
            HausNr?: string;
            HausNrZu?: string;
            Ort?: string;
            Plz?: string;
            Str?: string;
            AuslAdr?: {
              AdressErg?: string;
              AuslPlz?: string;
              HausNr?: string;
              HausNrZu?: string;
              Ort?: string;
              Str?: string;
            };
            PostfAdr?: {
              Ort?: string;
              Plz?: string;
              Postfach?: string;
            };
            StrAdr?: {
              AdressErg?: string;
              HausNr?: string;
              HausNrZu?: string;
              Ort?: string;
              Plz?: string;
              Str?: string;
            };
          };
        };
        Name?: string;
        NamensVorsatz?: string;
        NamensZusatz?: string;
        Titel?: string;
        Vorname?: string;
        NNatPers?: {
          AdrKette?: {
            AuslAdr?: {
              AdressErg?: string;
              AuslPlz?: string;
              HausNr?: string;
              HausNrZu?: string;
              Ort?: string;
              Str?: string;
            };
            GrossempfAdr?: {
              Ort?: string;
              Plz?: string;
            };
            PostfAdr?: {
              Ort?: string;
              Plz?: string;
              Postfach?: string;
            };
            StrAdr?: {
              AdressErg?: string;
              HausNr?: string;
              HausNrZu?: string;
              Ort?: string;
              Plz?: string;
              Str?: string;
              AuslAdr?: {
                AdressErg?: string;
                AuslPlz?: string;
                HausNr?: string;
                HausNrZu?: string;
                Ort?: string;
                Str?: string;
              };
              GrossempfAdr?: {
                Ort?: string;
                Plz?: string;
              };
              PostfAdr?: {
                Ort?: string;
                Plz?: string;
                Postfach?: string;
              };
              StrAdr?: {
                AdressErg?: string;
                HausNr?: string;
                HausNrZu?: string;
                Ort?: string;
                Plz?: string;
                Str?: string;
              };
            };
          };
          Firmenname?: string;
        };
        NatPers?: {
          AdrKette?: {
            AuslAdr?: {
              AdressErg?: string;
              AuslPlz?: string;
              HausNr?: string;
              HausNrZu?: string;
              Ort?: string;
              Str?: string;
            };
            PostfAdr?: {
              Ort?: string;
              Plz?: string;
              Postfach?: string;
            };
            StrAdr?: {
              AdressErg?: string;
              HausNr?: string;
              HausNrZu?: string;
              Ort?: string;
              Plz?: string;
              Str?: string;
              AuslAdr?: {
                AdressErg?: string;
                AuslPlz?: string;
                HausNr?: string;
                HausNrZu?: string;
                Ort?: string;
                Str?: string;
              };
              PostfAdr?: {
                Ort?: string;
                Plz?: string;
                Postfach?: string;
              };
              StrAdr?: {
                AdressErg?: string;
                HausNr?: string;
                HausNrZu?: string;
                Ort?: string;
                Plz?: string;
                Str?: string;
              };
            };
          };
          Name?: string;
          NamensVorsatz?: string;
          NamensZusatz?: string;
          Titel?: string;
          Vorname?: string;
        };
      };
    };
    version: string;
  };
}
