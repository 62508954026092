import { Pipe, PipeTransform } from '@angular/core';
import { Message } from '@expresssteuer/models';

@Pipe({
  name: 'sentiment',
})
export class SentimentPipe implements PipeTransform {
  transform(value: Message, debug = true): string {
    let result = '';

    if (value.intent?.sentiment) {
      result = Message.getSentiment(value.intent.sentiment);

      if (debug && value.intent?.sentiment?.magnitude) {
        result += ` (m:${
          Math.round(value.intent.sentiment.magnitude * 100) / 100
        } s:${Math.round(value.intent.sentiment.score * 100) / 100})`;
      }
    }

    return result;
  }
}
