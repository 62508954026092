import { Timestamp } from '../../../helpers/timestamp';

interface extractAddress {
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
}
export interface MasterdataData extends Record<string, unknown> {
  dataOf: string;
  /**
   *  @deprecated TODO: this is not the taxnumber but the taxid!
   */
  taxnumber: number;
  customer: {
    firstName: string;
    lastName: string;
    birthday: Timestamp | null;
    address: extractAddress;
  };
  iban: string;
  taxRepresentative: {
    firstName: string;
    lastName: string;
    additionalName: string;
    address: extractAddress;
  };
}

export class MasterdataData {
  public static getTemplate(): MasterdataData {
    return {
      dataOf: '',
      /**
       *  @deprecated TODO: this is not the taxnumber but the taxid!
       */
      taxnumber: 0,
      customer: {
        firstName: '',
        lastName: '',
        birthday: null,
        address: {
          street: '',
          houseNumber: '',
          zipCode: '',
          city: '',
        },
      },
      iban: '',
      taxRepresentative: {
        firstName: '',
        lastName: '',
        additionalName: '',
        address: {
          street: '',
          houseNumber: '',
          zipCode: '',
          city: '',
        },
      },
    };
  }
}
