<div
  class="input-group"
  *ngIf="
    campaign && campaignsservice.campaigns$ && taxcaseservice.currentTaxCase
  "
>
  <select
    [compareWith]="compare"
    class="form-control"
    name="campaigns"
    [(ngModel)]="campaign"
    *ngIf="campaignsservice.campaigns$ | async; let campaigns"
  >
    <option [ngValue]="c" *ngFor="let c of campaigns">{{ c.name }}</option>
  </select>
</div>
