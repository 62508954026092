import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quickpanel',
  templateUrl: './quickpanel.component.html',
  styleUrls: ['./quickpanel.component.scss'],
})
export class QuickpanelComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
