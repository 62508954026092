import { Component, Input } from '@angular/core';
import {
  AdditionalExpense,
  ADDITIONALEXPENSECATEGORY,
  ADDITIONALEXPENSESUBCATEGORY,
  EXPENSETYPE,
  IAdditionalExpense,
} from '@expresssteuer/models';

@Component({
  selector: 'esui-expense-editor',
  templateUrl: './esui-expense-editor.component.html',
  styleUrls: ['./esui-expense-editor.component.scss'],
})
export class EsuiExpenseEditorComponent {
  ADDITIONALEXPENSECATEGORY = ADDITIONALEXPENSECATEGORY;
  EXPENSETYPE = EXPENSETYPE;
  ADDITIONALEXPENSESUBCATEGORY = ADDITIONALEXPENSESUBCATEGORY;

  @Input()
  public expense?: IAdditionalExpense;

  public onSubCategoryChange() {
    if (!this.expense) {
      return;
    }
    switch (this.expense.subcategory) {
      case ADDITIONALEXPENSESUBCATEGORY.DONATION:
        this.expense.description = 'Spende';
        break;
      case ADDITIONALEXPENSESUBCATEGORY.HOUSEHOLDSERVICES:
        this.expense.description = 'Haushaltsnahe Dienstleistungen';
        break;
      case ADDITIONALEXPENSESUBCATEGORY.CRAFTSMANSERVICES:
        this.expense.description = 'Handwerkerleistungen';
        break;
      case ADDITIONALEXPENSESUBCATEGORY.EXTRABURDEN:
        this.expense.description = 'Krankheitskosten';
        break;
      case ADDITIONALEXPENSESUBCATEGORY.WORKEQUIPMENT:
        this.expense.description = 'Arbeitsmittel';
        break;
      case ADDITIONALEXPENSESUBCATEGORY.WORKROOM:
        this.expense.description = 'Arbeitszimmer';
        break;
      case ADDITIONALEXPENSESUBCATEGORY.EDUCATION:
        this.expense.description = 'Ausbildung';
        break;
      case ADDITIONALEXPENSESUBCATEGORY.APPLICATION:
        this.expense.description = 'Werbungskosten';
        break;
      case ADDITIONALEXPENSESUBCATEGORY.RELOCATION:
        this.expense.description = 'Umzugskosten';
        break;
      case ADDITIONALEXPENSESUBCATEGORY.TRAVELCOST:
        this.expense.description = 'Reisekosten';
        break;
      case ADDITIONALEXPENSESUBCATEGORY.DOUBLEHOUSEHOLDCOST:
        this.expense.description = 'Doppelte Haushaltsführung';
        break;
      case ADDITIONALEXPENSESUBCATEGORY.LIVING:
        this.expense.description = 'Unterhalt';
        break;
      case ADDITIONALEXPENSESUBCATEGORY.OTHER:
        this.expense.description = 'Weitere Kosten';
        break;
      default: {
        const aExp = AdditionalExpense.getTemplate();
        aExp.type = this.expense.type as EXPENSETYPE;
        aExp.partial = this.expense.partial;
        aExp.category = this.expense.category as ADDITIONALEXPENSECATEGORY;
        aExp.description = this.expense.description;
        aExp.value = this.expense.value;
        aExp.subcategory = this.expense
          .subcategory as ADDITIONALEXPENSESUBCATEGORY;
        this.expense = aExp;
        break;
      }
    }
  }
}
