import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { EsCalculationModule } from '../es-calculation/es-calculation.module';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsStoreStateIndicatorModule } from '../es-store-state-indicator/es-store-state-indicator.module';
import { EsTaxcaseCommentsModule } from '../es-taxcase-comments/es-taxcase-comments.module';
import { EsTaxcaseShortModule } from '../es-taxcase-short/es-taxcase-short.module';
import { EsuiDocumentViewerModule } from '../esui-document-viewer/esui-document-viewer.module';
import { FinalTaxcaseReviewComponent } from './final-taxcase-review.component';

@NgModule({
  declarations: [FinalTaxcaseReviewComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    EsTaxcaseShortModule,
    EsCalculationModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    EsuiDocumentViewerModule,
    EsStoreStateIndicatorModule,
    EsTaxcaseCommentsModule,
    EsCardKeyModule,
  ],
  exports: [FinalTaxcaseReviewComponent],
})
export class EsFinalTaxcaseReviewModule {}
