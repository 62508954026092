<div class="dropdown dropdown-inline">
  <a
    href="#"
    class="btn btn-clean btn-hover-light-primary btn-sm btn-icon mr-3"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <i class="ki ki-bold-more-ver"></i>
  </a>
  <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
    <!--begin::Navigation-->
    <ul class="navi navi-hover" *ngIf="message">
      <li class="navi-header pb-1">
        <span class="text-primary text-uppercase font-weight-bold font-size-sm"
          >Nachricht:</span
        >
      </li>

      <li class="navi-item">
        <a class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-reply"></i>
          </span>
          <span class="navi-text">Antworten</span>
        </a>
      </li>
      <li class="navi-item">
        <a class="navi-link" (click)="markReadUnread()">
          <span class="navi-icon">
            <i class="flaticon2-check-mark" *ngIf="!message.metadata.read"></i>
            <i class="flaticon2-email" *ngIf="message.metadata.read"></i>
          </span>
          <span class="navi-text">
            {{
              message.metadata.read
                ? 'Als offen markieren'
                : 'Als erledigt markieren'
            }}
          </span>
        </a>
      </li>
      <li class="navi-item" *ngIf="!message.metadata.deleted">
        <a
          class="navi-link"
          [swal]="{
            title: 'Diese Nachricht löschen?',
            text: 'Die Nachricht wird  gelöscht',
            type: 'warning',
            showCancelButton: true
          }"
          (showCancelButton)="(true)"
          (focusCancel)="(true)"
          (confirm)="markAsDelete()"
        >
          <span class="navi-icon">
            <i class="flaticon-delete-1"></i>
          </span>
          <span class="navi-text"> Löschen </span>
        </a>
      </li>
      <li class="navi-item" *ngIf="message.metadata.deleted">
        <a
          class="navi-link"
          [swal]="{
            title: 'Diese Nachricht wiederherstellen?',
            text: 'Die Nachricht wird wiederhergestellt',
            type: 'warning',
            showCancelButton: true
          }"
          (showCancelButton)="(true)"
          (focusCancel)="(true)"
          (confirm)="markAsDelete()"
        >
          <span class="navi-icon">
            <i class="flaticon-delete-1"></i>
          </span>
          <span class="navi-text"> Wiederherstellen </span>
        </a>
      </li>
    </ul>
    <!--end::Navigation-->
  </div>
  <span
    class="btn btn-default btn-icon btn-sm mr-2"
    data-toggle="tooltip"
    title=""
    data-original-title="Antworten"
    (click)="reply()"
  >
    <i class="flaticon2-reply icon-sm"></i>
  </span>
</div>
