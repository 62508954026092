/* eslint-disable @typescript-eslint/no-explicit-any */

import { FieldValue } from '@google-cloud/firestore';
import { DeepReadonly } from 'deep-freeze';
import { set as setByPath } from 'dot-prop';
import { isEqual } from 'lodash';
import { z } from 'zod';

export abstract class AbstractFirestore {
  abstract Timestamp: AbstractStaticTimestamp;
}

export abstract class AbstractStaticTimestamp {
  abstract now(): AbstractTimestamp;
  abstract fromDate(date: Date): AbstractTimestamp;
  abstract fromMillis(milliseconds: number): AbstractTimestamp;
}
export abstract class AbstractTimestamp {
  abstract readonly seconds: number;
  abstract readonly nanoseconds: number;
  abstract toDate(): Date;
  abstract toMillis(): number;
  abstract isEqual(other: AbstractTimestamp): boolean;
  abstract valueOf(): string;
}

export function isAbstractTimestamp(input: any): input is AbstractTimestamp {
  if (
    input &&
    Object.keys(input).length === 2 &&
    typeof input.seconds === 'number' &&
    typeof input.nanoseconds === 'number' &&
    typeof input.toDate === 'function' &&
    typeof input.toMillis === 'function'
  ) {
    return true;
  }
  return false;
}

export const TimestampZod = z.custom<AbstractTimestamp>((val) =>
  isAbstractTimestamp(val)
);

export function isSerializedAbstractTimestamp(
  input: any
): input is
  | { _seconds: number; _nanoseconds: number }
  | { seconds: number; nanoseconds: number } {
  if (
    Object.keys(input).length === 2 &&
    ((typeof input._seconds === 'number' &&
      typeof input._nanoseconds === 'number') ||
      (typeof input.seconds === 'number' &&
        typeof input.nanoseconds === 'number')) &&
    input.toDate === undefined
  ) {
    return true;
  }
  return false;
}

export function normalizeSerializedAbstractTimestamp(
  input:
    | { _seconds: number; _nanoseconds: number }
    | { seconds: number; nanoseconds: number }
): { seconds: number; nanoseconds: number } {
  function isUnderscored(
    input: any
  ): input is { _seconds: number; _nanoseconds: number } {
    return (
      typeof input['_seconds'] === 'number' &&
      typeof input['_nanoseconds'] === 'number'
    );
  }

  function isNotUnderscored(
    input: any
  ): input is { seconds: number; nanoseconds: number } {
    return (
      typeof input['seconds'] === 'number' &&
      typeof input['nanoseconds'] === 'number'
    );
  }

  if (isUnderscored(input)) {
    return { seconds: input._seconds, nanoseconds: input._nanoseconds };
  }

  if (isNotUnderscored(input)) {
    return { seconds: input.seconds, nanoseconds: input.nanoseconds };
  }

  throw new Error('trying to normalize faulty SerializedFirebaseTimestamp');
}

/**
 * Serialize an object from firestore
 */
export function serializeFirestoreObject(
  object: any,
  { replaceUndefinedWith }: { replaceUndefinedWith?: any } = {}
) {
  const replacer = (_key: any, value: any) => {
    if (replaceUndefinedWith !== undefined && value === undefined) {
      return replaceUndefinedWith;
    }
    return value;
  };

  return JSON.stringify(object, replacer);
}

/**
 * Delete undefined properties from object or replace them with a value.
 * When `withValue`=== undefined, the propertie with this value will be deleted, otherwise the properties will get the `value`'s value
 */
export function replaceUndefinedInFirestoreObject(
  obj: any,
  {
    withValue,
    Timestamp,
  }: { withValue?: any; Timestamp: AbstractStaticTimestamp }
) {
  return deserializeFirestoreObject(
    serializeFirestoreObject(obj, { replaceUndefinedWith: withValue }),
    Timestamp
  );
}

/**
 * Deserialize a string originally serialized from a firestore object
 */

export function deserializeFirestoreObject<T = any>(
  input: string,
  Timestamp: AbstractStaticTimestamp
): T {
  let parsed;
  try {
    parsed = JSON.parse(input);
  } catch (error) {
    parsed = input;
  }
  return deserializeFirestoreObjectFromJSObject<T>(parsed, Timestamp);
}

/**
 * Deserialize an object originally serialized from a firestore object
 */
export function deserializeFirestoreObjectFromJSObject<T>(
  input: any,
  Timestamp: AbstractStaticTimestamp
): T {
  if (
    ['string', 'number', 'bigint', 'boolean', 'symbol'].includes(typeof input)
  ) {
    return input as T;
  }
  if ([null].includes(input)) {
    return input as T;
  }
  if (typeof input === 'function') {
    console.error(
      'Deserialising an object with a function property. This should never occure.'
    );
    return input as T;
  }
  if (typeof input === 'undefined') {
    console.error(
      'Deserialising an object with a undefined property. This should never occure.'
    );
    return input as unknown as T;
  }
  if (typeof input !== 'object') {
    console.error(
      `Deserialising an unknown type ${typeof input}. This should never occure.`,
      input
    );
    return input as T;
  }

  // firebase specific types:
  // Timestamp
  if (isSerializedAbstractTimestamp(input)) {
    return makeTimestamp(input, Timestamp) as unknown as T;
  }
  // TODO other firebase specific objects? Eg.: GeographicalPoint, Reference
  // if(isSerializedGeographicalPoint){
  //   return ...
  // }
  // if (isSerializedReference(input)) {
  //   return ...
  // }

  if (Array.isArray(input)) {
    return input.map((val) =>
      deserializeFirestoreObjectFromJSObject(val, Timestamp)
    ) as unknown as T;
  }

  return Object.keys(input)
    .filter((key) => Object.prototype.hasOwnProperty.call(input, key))
    .map((key) => {
      const newVal = deserializeFirestoreObjectFromJSObject(
        input[key],
        Timestamp
      );
      return [key, newVal] as [string, unknown];
    })
    .reduce((acc, [key, val]) => {
      acc[key] = val;
      return acc;
    }, {} as Record<string, unknown>) as unknown as T;
}

export function makeTimestamp(
  timestamp: any,
  Timestamp: AbstractStaticTimestamp,
  returnIfBroken: AbstractTimestamp | null = null
): AbstractTimestamp | null {
  if (timestamp === null || timestamp === '') {
    return returnIfBroken;
  }

  try {
    if (isAbstractTimestamp(timestamp)) {
      return Timestamp.fromMillis(timestamp.toMillis());
    }

    if (isSerializedAbstractTimestamp(timestamp)) {
      const { seconds, nanoseconds } =
        normalizeSerializedAbstractTimestamp(timestamp);
      const millis = seconds * 1000 + nanoseconds / 1000000;
      return Timestamp.fromMillis(millis);
    }
  } catch (err) {
    console.error('invalid timestamp', err);
    console.info(timestamp);
  }
  return returnIfBroken;
}

/**
 * Clone a firebase object through serialize/deserialize it back and forth
 */
export function cloneFirebaseObject<T>(
  object: DeepReadonly<T>,
  Timestamp: AbstractStaticTimestamp
): T {
  return deserializeFirestoreObject(
    serializeFirestoreObject(object),
    Timestamp
  );
}

function isFieldValue(object: any): object is FieldValue {
  return (
    (object &&
      typeof object === 'object' &&
      [
        'ArrayRemoveFieldValueImpl',
        'ArrayUnionFieldValueImpl',
        'DeleteFieldValueImpl',
        'NumericIncrementFieldValueImpl',
        'ServerTimestampFieldValueImpl',
        'ArrayRemoveTransform',
        'ArrayUnionTransform',
        'DeleteTransform',
        'NumericIncrementTransform',
        'ServerTimestampTransform',
      ].includes(object.constructor?.name)) ||
    Boolean(
      (object?.constructor?.name as string | undefined)?.endsWith(
        'FieldValueImpl'
      )
    )
  );
}

export function isNativeFirebaseObject(
  object: any
): object is AbstractTimestamp | FieldValue {
  if (isAbstractTimestamp(object)) {
    return true;
  }
  if (isFieldValue(object)) {
    return true;
  }
  // TODO handle all firebase native objects
  return false;
}

/**
 * "Open up" a type to allow specific field values to be
 * set instead of the actual value.
 *
 * @example
```
let a: ToWriteOption<Timestamp> = Timestamp.now();
a = FieldValue.serverTimestamp();

let b: ToWriteOption<number[]> = [];
b = FieldValue.arrayRemove(1);
```
 */
export type ToWriteOption<O> = O extends AbstractTimestamp | number | unknown[]
  ? // TODO handle all firebase native objects
    FieldValue | O
  : O;

/**
 * Apply `ToWriteOption` recursively
 *
 * @example
```
const a: AsFirebaseWriteObject<{
  a: [1, 2, '2'];
  b: AbstractTimestamp;
  c: AbstractTimestamp[];
}> = {} as any;

a.a = FieldValue.arrayRemove();
a.b = FieldValue.serverTimestamp();
a.c = [FieldValue.serverTimestamp()];
a.c = FieldValue.arrayRemove()
```
 */
export type AsFirebaseWriteObject<O extends object | unknown[]> = {
  [K in keyof O]: O[K] extends object | unknown[]
    ? ToWriteOption<O[K]> | AsFirebaseWriteObject<O[K]>
    : ToWriteOption<O[K]>;
};

function toDotObject(
  obj: Record<
    string,
    string | number | boolean | null | undefined | symbol | object
  >,
  exceptForObjectsThatMatch?: (
    obj: Record<string, string | number | boolean | null | undefined | symbol>
  ) => boolean
): Record<string, string | number | boolean | null | undefined | symbol> {
  const pathObject: Record<
    string,
    string | number | boolean | null | undefined | symbol
  > = {};
  function addPropertyAsPathRecursively(
    obj: Record<string, unknown>,
    current?: string
  ) {
    for (const key in obj) {
      const value = obj[key];
      const newKey = current ? current + '.' + key : key;
      if (
        value &&
        typeof value === 'object' &&
        (!exceptForObjectsThatMatch || !exceptForObjectsThatMatch(value as any))
      ) {
        addPropertyAsPathRecursively(value as any, newKey);
      } else {
        pathObject[newKey] = value as
          | string
          | number
          | boolean
          | null
          | undefined
          | symbol;
      }
    }
  }
  addPropertyAsPathRecursively(obj);
  return pathObject;
}

/**
 * Make a dot object from obj while preserving NativeFirebaseObjects
 *
 * @example requires as any
 * ```
 * toDotObjectForFirebase(data as any)
 * ```
 */
export function toDotObjectForFirebase(
  obj: Record<string, string | number | boolean | null | undefined | symbol>
): Record<string, string | number | boolean | null | undefined | symbol> {
  return toDotObject(obj, isNativeFirebaseObject);
}
/**
 * Make a dot object from obj while preserving NativeFirebaseObjects and Arrays
 * use wit as any on parameter to avoid ts errors
 */
export function toDotObjectForFirebaseWithoutArrays(
  obj: Record<
    string,
    string | number | boolean | null | undefined | symbol | object
  >
): Record<string, string | number | boolean | null | undefined | symbol> {
  return toDotObject(
    obj,
    (data) => Array.isArray(data) || isNativeFirebaseObject(data)
  );
}

/**
 * Assing a dotObject's value to an existing object
 */
export function assignDotObjectToObject<T extends Record<string, unknown>>(
  fromDotObject: Record<string, unknown>,
  toRegularObject: T
) {
  Object.keys(fromDotObject).forEach((path) => {
    setByPath(toRegularObject, path, fromDotObject[path]);
  });
}

/**
 * This function compares flat objects on quality of their properties
 * For arrays we copy the reference into the diff object so any changes
 * on the array will be reflected in the diff object before write
 * also checks for native Firebase objects
 * @param before
 * @param after all undefined fields will be ignored and are not contained in the diff object
 */
export function getChangedSetAsDotObject(
  before: Record<keyof unknown, unknown>,
  after: Record<keyof unknown, unknown>
): Record<string, unknown> {
  const dotBefore = toDotObjectForFirebaseWithoutArrays(before);
  const dotAfter = toDotObjectForFirebaseWithoutArrays(after);

  return Object.keys(dotAfter).reduce((acc, key) => {
    const isEqualNativeFirebase =
      isNativeFirebaseObject(dotAfter[key]) &&
      isNativeFirebaseObject(dotBefore[key]) &&
      ((dotAfter[key] as any)?.isEqual?.(dotBefore[key]) ??
        isEqual(dotBefore[key], dotAfter[key]));
    if (
      dotAfter[key] !== undefined &&
      !isEqualNativeFirebase &&
      (dotBefore[key] !== dotAfter[key] || Array.isArray(dotAfter[key]))
    ) {
      acc[key] = dotAfter[key];
    }
    return acc;
  }, {} as Record<string, unknown>);
}

export function getChangedSetAsDotObjectForDbUpdate<
  T extends Record<keyof unknown, unknown>
>(before: T, after: T): Record<string, unknown> {
  return getChangedSetAsDotObject(before, after);
}
