import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { PermissionInputComponent } from './permission-input.component';
import { SplitPipe } from './split.pipe';

@NgModule({
  declarations: [PermissionInputComponent, SplitPipe],
  imports: [CommonModule, FormsModule, MatInputModule, MatButtonToggleModule],
  exports: [PermissionInputComponent],
})
export class PermissionInputModule {}
