import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { OrderByDirection, Timestamp } from '@angular/fire/firestore';
import {
  Client,
  Elster,
  Job,
  MARITALSTATUS,
  ModelHistoryItem,
  TaxCase,
  TaxCaseMetadata,
  TAXCASESTATUS,
  TAXCASESTATUS_INPROGRESS,
  TAXCASESTATUS_REJECT,
  TaxFormMetadata,
} from '@expresssteuer/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { ClientsService } from '../../../app/services/data/clients.service';
import { BusyManager } from '../../shared/helpers/busymanager';
import { AuthenticationService } from '../security/authentication.service';

export class Pagination {
  start: any;
  end: any;
  firstItem: any;
  first: boolean;
  total: number;
  currentPage: number;
  recordsPerPage: number;
  initialized: boolean;

  constructor() {
    this.currentPage = 1;
    this.recordsPerPage = 20;
    this.total = 8;
    this.initialized = false;
  }

  public nextPage() {
    this.initialized = false;

    if (this.firstItem === this.start) {
      this.first = true;
    } else {
      this.first = false;
      this.currentPage++;
    }
  }

  public goto(page: number) {
    this.initialized = false;
    this.start = page * 20;
    this.currentPage = page;

    console.log(' START : ', this.start);

    if (this.firstItem === this.start) {
      this.first = true;
    } else {
      this.first = false;
    }
  }

  public prevPage() {
    this.initialized = false;
    this.start = this.end;

    if (this.firstItem === this.start) {
      this.first = true;
    } else {
      this.first = false;
      this.currentPage--;
    }
  }

  public firstPage() {
    this.currentPage = 1;
    this.initialized = false;
    this.start = null;
    this.first = true;
  }
}

export interface CountersMain {
  entry: number;
  inProgress: number;
  done: number;
  archived: number;
  reject: number;
  deleted: number;
}
export interface CountersSub {
  // rejected
  fraud: number;
  error: number;
  test: number;
  deleted: number;
  double: number;
  other: number;
  customer_decline: number;
  noresponse: number;
  no_customer_response: number;
  unatractive: number;
  // Progress
  waiting_data: number;
  waiting_dataretrievel: number;
  waiting_dataretrievel_sent: number;
  senttotaxoffice: number;
  readytosend: number;
  readytosendwitherror: number;
  questionsfromtaxoffice: number;
  returnedfromtaxoffice: number;
  appealed: number;
  paymentdeclined: number;
  waitingformoney: number;
  moneyrecieved: number;
  paid: number;
  paymentnotpossible: number;
  sepa: number;
  sepaprocessed: number;
}

@Injectable({
  providedIn: 'root',
})
export class TaxcasesService {
  public busy: BusyManager = new BusyManager();
  public onlyMyCases: boolean = false;

  public currentTaxCase: TaxCase = null;
  public currentTaxCaseElsterResponses: Observable<Elster[]>;

  public history: Observable<ModelHistoryItem[]>;
  public jobsOutdated: Observable<Job[]>;

  public statisticcountersMain: CountersMain;
  public statisticcountersSub: CountersSub;

  public currentStatus: TAXCASESTATUS = TAXCASESTATUS.ENTRY;
  public currentSubStatus:
    | TAXCASESTATUS_INPROGRESS
    | TAXCASESTATUS_REJECT
    | null = null;

  public currentTaxCaseCampaignMigration: boolean; // If Campaign needs migration and a full recalculation
  public currentClient: Client = null;

  // Filters
  private localTestcaseFilter$: BehaviorSubject<boolean | null>;
  private localStatusFilter$: BehaviorSubject<string | null>;

  public pagination: Pagination = new Pagination();

  public myCases: Observable<TaxCase[]>;

  // eslint-disable-next-line max-len
  private buildQuery(
    status: TAXCASESTATUS = TAXCASESTATUS.ENTRY,
    substatus: TAXCASESTATUS_INPROGRESS | TAXCASESTATUS_REJECT | null = null,
    pagingStart: any | null
  ): AngularFirestoreCollection {
    let sortfield = 'metadata.created';
    let sortDirection: OrderByDirection = 'desc';

    if (
      substatus === TAXCASESTATUS_INPROGRESS.SEPA ||
      substatus === TAXCASESTATUS_INPROGRESS.SEPAPROCESSED ||
      substatus === TAXCASESTATUS_INPROGRESS.WAITINGFORMONEY
    ) {
      sortfield = 'client.lastname';
      sortDirection = 'asc';
    }

    let colref = this.db
      .collection<TaxCase>('taxcases')
      .ref.where('metadata.status.current', '==', status);

    if (this.onlyMyCases && this.security.user) {
      console.log('ONLY OWN ITEMS QUERY');
      colref = colref.where(
        'metadata.assigned.authId',
        '==',
        this.security.user.authId
      );
    }

    if (substatus) {
      colref = colref.where('metadata.status.substatus', '==', substatus);
    }

    if (sortfield && sortDirection) {
      if (substatus === TAXCASESTATUS_INPROGRESS.WAITINGDATA) {
        colref = colref.orderBy('metadata.created', 'asc');
      } else {
        colref = colref.orderBy(sortfield, sortDirection);
      }
    } else {
      if (substatus === TAXCASESTATUS_INPROGRESS.WAITINGDATA) {
        colref = colref.orderBy('metadata.created', 'asc');
      } else {
        colref = colref.orderBy('metadata.created', 'desc');
      }
    }

    colref = colref.limit(this.pagination.recordsPerPage);
    if (pagingStart !== null && pagingStart !== undefined) {
      console.log('Start paging at ', pagingStart);
      colref = colref.startAfter(pagingStart);
    }

    return this.db.collection<TaxCase>('taxcases', (ref) => colref);
  }

  public updateElsterProdPrepareToIsTaxOfficeTestSent() {
    const taxCaseListener$ = this.db
      .collection<TaxCase>('taxcases', (q) =>
        q
          .where('metadata.status.current', '==', TAXCASESTATUS.INPROGRESS)
          .where(
            'metadata.status.substatus',
            '==',
            TAXCASESTATUS_INPROGRESS.ELSTER_PROD_PREPARE
          )
          .orderBy('metadata.changed', 'desc')
          .limit(200)
      )
      .valueChanges();

    taxCaseListener$.subscribe((r) => {
      r.forEach((aTaxCase) => {
        aTaxCase.metadata.isTaxOfficeProdSent = false;
        aTaxCase.metadata.isTaxOfficeTestSent = true;

        this.db.collection<TaxCase>('taxcases').doc(aTaxCase.id).set(aTaxCase);
        console.log(aTaxCase);
      });
    });
  }

  // eslint-disable-next-line max-len
  public getTaxCases(
    status: TAXCASESTATUS = TAXCASESTATUS.ENTRY,
    substatus: TAXCASESTATUS_INPROGRESS | TAXCASESTATUS_REJECT | null = null
  ): Observable<TaxCase[]> {
    // All good, return the collection
    this.currentStatus = status;
    this.currentSubStatus = substatus;

    if (status === TAXCASESTATUS.VIRTUAL_MY) {
      return this.myCases;
    }

    console.log(
      `get taxcases status: ${status} substatus: ${substatus} limit : ${this.pagination.recordsPerPage} start: ${this.pagination.start}`
    );
    // prepare the query
    const collection: AngularFirestoreCollection = this.buildQuery(
      status,
      substatus,
      this.pagination.start
    );
    this.pagination.initialized = true;
    return collection.snapshotChanges().pipe(
      tap((results) => {
        console.log('page loaded with records ', results.length);
        if (results && results.length > 0) {
          this.pagination.start = results[results.length - 1].payload.doc;
          this.pagination.end = results[0].payload.doc;
          console.log('start', this.pagination.start.id);
          console.log('end', this.pagination.end.id);
        }
      }),
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as TaxCase;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      shareReplay(1)
    );
  }

  constructor(
    private db: AngularFirestore,
    public clientservice: ClientsService,
    private fns: AngularFireFunctions,
    public security: AuthenticationService
  ) {
    // this.statisticcounters = this.db.collection('statistics').doc<Counters>('taxcases_count').valueChanges();

    if (this.security.user) {
      this.loadMyCases(this.security.user.authId);
      this.activateStatistics();
    } else {
      this.security.afAuth.authState.subscribe((s) => {
        this.loadMyCases(s.uid);
        this.activateStatistics();
      });
    }
  }

  public get taxCaseJobsOutdated(): Observable<Job[]> {
    if (!this.jobsOutdated) {
      console.log('INIT Original Jobs FOR TAXCASE', this.currentTaxCase.id);
      this.jobsOutdated = this.db
        .collection('taxcases')
        .doc(this.currentTaxCase.id)
        .collection<Job>('jobs-outdated')
        .valueChanges();
    }
    return this.jobsOutdated;
  }

  public get taxCaseHistory(): Observable<ModelHistoryItem[]> {
    if (!this.history) {
      console.log('INIT HISTORY FOR TAXCASE', this.currentTaxCase.id);
      this.history = this.db
        .collection('taxcases')
        .doc(this.currentTaxCase.id)
        .collection<ModelHistoryItem>('history', (d) => d.orderBy('created'))
        .valueChanges();
    }
    return this.history;
  }

  private async activateStatistics() {
    this.db
      .collection('statistics')
      .doc('taxcase_counters_main')
      .valueChanges()
      .subscribe((r) => {
        this.statisticcountersMain = r as CountersMain;
      });
    this.db
      .collection('statistics')
      .doc('taxcase_counters_sub')
      .valueChanges()
      .subscribe((r) => {
        this.statisticcountersSub = r as CountersSub;
      });
  }

  private loadMyCases(uid: string) {
    this.myCases = this.db
      .collection<TaxCase>('taxcases', (q) =>
        q
          .where('metadata.status.current', '==', TAXCASESTATUS.INPROGRESS)
          .where('metadata.assigned.authId', '==', uid)
          .orderBy('metadata.changed', 'desc')
          .limit(100)
      )
      .valueChanges();
  }

  public setStatusToLoad(
    tests: boolean | null = false,
    status: string | null = 'entry'
  ): void {
    this.localTestcaseFilter$.next(tests);
    this.localStatusFilter$.next(status);
  }

  public async loadElster(taxcase: TaxCase | null): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      const taxcaseelster: TaxCase =
        taxcase != null ? taxcase : this.currentTaxCase;
      this.currentTaxCaseElsterResponses = this.db
        .collection('taxcases')
        .doc(taxcaseelster.id)
        .collection<Elster>('elster', (ref) => ref.orderBy('created', 'desc'))
        .valueChanges();
    });
  }

  public async load(
    id: string,
    force: boolean = false,
    returnOnly: boolean = false
  ): Promise<TaxCase> {
    if (!this.currentTaxCase || this.currentTaxCase.id !== id || force) {
      this.busy.start('Lade Fall ' + id);

      this.history = null;

      try {
        const taxCaseref = await this.db
          .collection<TaxCase>('taxcases')
          .doc(id)
          .get()
          .toPromise();

        if (!taxCaseref || !taxCaseref.exists) {
          this.busy.error(
            'Fall mit ID: ' + id + ' konnte nicht geladen werden'
          );
          throw new Error('TaxCase mit ID: ' + id + ' could not be loaded');
        }

        const taxcase: TaxCase = taxCaseref.data() as TaxCase;

        /** Sanitize TaxCase */
        // Jobs
        taxcase.taxform.current.taxForm.jobs.forEach((j) => {
          if (!j.wagestatement.socialSecurity) {
            j.wagestatement.socialSecurity = {
              health_employee: 0,
              pension_employee: 0,
              pension_employer: 0,
              socialCare_employee: 0,
              unemployment_employee: 0,
            };
          }
        });

        const taxCaseListener$ = this.db
          .collection<TaxCase>('taxcases')
          .doc(id)
          .valueChanges();
        taxCaseListener$.subscribe((r) => {
          // console.log(`=> load() taxform data`);
        });

        //console.log(taxcase);

        if (
          taxcase.taxform.current &&
          taxcase.taxform.current.calculation &&
          taxcase.taxform.current.calculation.campaign
        ) {
          console.log(taxcase.taxform.current.calculation.campaign);
        } else {
          console.error('CAMPAIGN IS MISSING');
        }

        if (!taxcase.id) {
          taxcase.id = taxCaseref.id;
        }

        if (!taxcase.metadata) {
          taxcase.metadata = TaxCaseMetadata.getTemplate();
        }
        // check for Campaign => if not exists, reinitialise the calculation to new Format

        if (!taxcase.taxform.current.taxForm.metadata) {
          taxcase.taxform.current.taxForm.metadata =
            TaxFormMetadata.getTemplate();
        }

        if (!taxcase.taxform.current.taxForm.metadata.campaign) {
          if (!taxcase.taxform.current.taxForm.metadata.campaign) {
            taxcase.taxform.current.taxForm.metadata =
              TaxFormMetadata.getTemplate();
            taxcase.taxform.current.taxForm.metadata.campaign.id = '';
            taxcase.taxform.current.taxForm.metadata.campaign.promoCode = '';
            taxcase.taxform.original.taxForm.metadata =
              TaxFormMetadata.getTemplate();
            taxcase.taxform.original.taxForm.metadata.campaign.id = '';
            taxcase.taxform.original.taxForm.metadata.campaign.promoCode = '';
          }
        }

        if (taxcase.metadata.hasOpenTasks === undefined) {
          taxcase.metadata.hasOpenTasks = true;
        }

        // Filter empty expenses
        const tmpExpenses = [];
        const tmpExpensesPartner = [];
        for (const exp of taxcase.taxform.current.taxForm.information
          .additionalExpenses) {
          if (exp.value && exp.value > 0) {
            tmpExpenses.push(exp);
          }
        }
        for (const exp of taxcase.taxform.current.taxForm.partner
          .additionalExpenses) {
          if (exp.value && exp.value > 0) {
            tmpExpensesPartner.push(exp);
          }
        }
        taxcase.taxform.current.taxForm.information.additionalExpenses =
          tmpExpenses;
        taxcase.taxform.current.taxForm.partner.additionalExpenses =
          tmpExpensesPartner;

        let client: Client;

        if (taxcase.taxform.original.client) {
          client = await this.clientservice.load(
            taxcase.taxform.original.client.id,
            returnOnly
          );
        }

        if (!taxcase.taxform.original.client && taxcase.client) {
          client = await this.clientservice.load(taxcase.client.id, returnOnly);
        }

        if (client) {
          taxcase.client = Client.getClientShort(client);
          taxcase.taxform.current.client = client;
        }

        // Campaign Migration
        if (taxcase.taxform.current.calculation.campaign.from) {
          this.currentTaxCaseCampaignMigration = false;
        } else {
          this.currentTaxCaseCampaignMigration = true;
        }

        if (!returnOnly) {
          this.currentTaxCase = taxcase;
        }

        console.log('taxcase');
        this.busy.stop();
        return taxcase;
      } catch (err) {
        console.log('Error loading taxcase with id:', id);
        console.error(err);
        this.busy.error('Fall kann nicht geladen werden');
        throw err;
      }
    } else {
      return this.currentTaxCase;
    }
  }
  public async update(): Promise<void> {
    this.busy.start('Speichere Fall ' + this.currentTaxCase.id);

    // Set updating User and Time
    this.currentTaxCase.metadata.changed = Timestamp.fromDate(new Date());
    this.currentTaxCase.metadata.updated = this.security.user;

    await this.db
      .collection<TaxCase>('taxcases')
      .doc(this.currentTaxCase.id)
      .set(this.currentTaxCase);

    this.busy.stop();
  }

  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public getUIText_maritalstatus(status: string): string {
    switch (status) {
      case MARITALSTATUS.single:
        return 'ledig';
      case MARITALSTATUS.married:
        return 'verheiratet';
      case MARITALSTATUS.divorced:
        return 'geschieden';
      default:
        return '';
    }
  }

  public getUIText_childrens(childLength: number): string {
    if (childLength > 1) {
      return childLength + ' kinder';
    } else if (childLength === 1) {
      return childLength + ' kind';
    } else {
      return '';
    }
  }

  public async save(taxcase: TaxCase): Promise<void> {
    console.log('Start saving');
    // Set updating User and Time
    taxcase.metadata.changed = Timestamp.fromDate(new Date());
    taxcase.metadata.updated = this.security.user;
    taxcase.metadata.updated.lastSeen = Timestamp.fromDate(new Date());
    await this.db.collection<TaxCase>('taxcases').doc(taxcase.id).set(taxcase);
  }
}
