import { EventEmitter, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Client, User } from '@expresssteuer/models';
import { distinctUntilChanged, Observable } from 'rxjs';

import { Call } from './aircall-event.model';

@Injectable({
  providedIn: 'root',
})
export class AircallService {
  constructor(private db: AngularFirestore) {}

  public airCallEventsOnIncommingCalls?: Observable<Call[]>;
  public airCallEventsOnHangupCalls?: Observable<Call[]>;
  public onIncommingCall = new EventEmitter<Call>();
  public onCallHangup = new EventEmitter<Call>();

  public connected = false;

  public ringingClient: Client | null = null;

  private lastEventTime = 0;

  /**
   * Register a User who gets Aircall Events
   * @param user
   */
  public registerUserForIncommingCalls(user: User) {
    const now = Math.floor(new Date().getTime() / 1000);

    this.airCallEventsOnIncommingCalls = this.db
      .collection<Call>('aircall', (q) =>
        q
          .where('event', '==', 'call.ringing_on_agent')
          .where('data.user.email', '==', user.email)
          .where('timestamp', '>', now)
      )
      .valueChanges()
      .pipe(distinctUntilChanged());

    this.airCallEventsOnHangupCalls = this.db
      .collection<Call>('aircall', (q) =>
        q
          .where('event', '==', 'call.hungup')
          .where('data.user.email', '==', user.email)
          .where('timestamp', '>', now)
      )
      .valueChanges()
      .pipe(distinctUntilChanged());

    this.connected = true;
    this.emitIncommingCallEvent();
    this.emitCallHangupEvent();
  }

  private emitIncommingCallEvent() {
    if (!this.airCallEventsOnIncommingCalls) {
      throw new Error('Aicall not connected');
    }
    this.airCallEventsOnIncommingCalls.subscribe((calls) => {
      calls.forEach((call) => {
        if (call.event === 'call.ringing_on_agent') {
          console.log('Incomming call');

          if (this.lastEventTime <= call.timestamp) {
            this.lastEventTime = call.timestamp;
            this.onIncommingCall.emit(call);
            this.lookup(call.data.number.digits).catch((e) =>
              console.log(
                'lookup client not found for number',
                call.data.number.digits
              )
            );
          }
        }
      });
    });
  }

  private emitCallHangupEvent() {
    if (!this.airCallEventsOnHangupCalls) {
      throw new Error('Aicall not connected');
    }
    this.airCallEventsOnHangupCalls.subscribe((calls) => {
      calls.forEach((call) => {
        if (call.event === 'call.hungup') {
          console.log('HANG UP');

          if (this.lastEventTime <= call.timestamp) {
            this.lastEventTime = call.timestamp;
            this.onCallHangup.emit(call);
            this.ringingClient = null;
          }
        }
      });
    });
  }

  public async lookup(number: string): Promise<Client> {
    const mobile = number.replace(/\s+/g, '');

    console.log('LOOKUP Client', mobile);

    const clients = await this.db
      .collection<Client>('clients', (q) => q.where('mobile', '==', mobile))
      .get()
      .toPromise();

    console.log('found clients', clients?.size);

    if (clients?.size === 0) {
      throw new Error('No Client found');
    }
    this.ringingClient = clients?.docs[0].data() as Client;
    return clients?.docs[0].data() as Client;
  }
}
