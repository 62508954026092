<ng-container *ngIf="true">
  <mat-card>
    <span esuiCardKey>Taxnotice Appeal</span>
    <mat-card-header>
      <mat-card-title> </mat-card-title>
      <mat-card-subtitle> </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="focus" *ngIf="true">
        <div class="documentViewer">
          <esui-skeleton-placeholder
            [skeletonBuildInput]="[]"
            [watchResult]="!!taxNotice && !!taxnoticeDocument"
            [bigBoxMode]="true"
          >
            <ng-container *ngIf="taxnoticeDocument">
              <esui-document-viewer
                [contentType]="taxnoticeDocument.contentType"
                [downloadUrl]="taxnoticeDocument.downloadUrl"
                [isLoading]="taxnoticeDocumentStoreStateIndicator?.isSaving"
                [hasErrors]="taxnoticeDocumentStoreStateIndicator?.hasErrors"
                [showNavigation]="true"
                [showDownloadButton]="true"
              ></esui-document-viewer>
            </ng-container>
          </esui-skeleton-placeholder>
        </div>
        <div class="documentViewer">
          <esui-skeleton-placeholder
            [skeletonBuildInput]="[]"
            [watchResult]="!!taxNotice && !!elsterDocument"
            [bigBoxMode]="true"
          >
            <ng-container *ngIf="elsterDocument">
              <esui-document-viewer
                [contentType]="elsterDocument.contentType"
                [downloadUrl]="elsterDocument.downloadUrl"
                [isLoading]="elsterDocumentStoreStateIndicator?.isSaving"
                [hasErrors]="elsterDocumentStoreStateIndicator?.hasErrors"
                [showNavigation]="true"
                [showDownloadButton]="true"
              ></esui-document-viewer>
            </ng-container>
          </esui-skeleton-placeholder>
        </div>
        <div class="metaViewer">
          <esui-skeleton-placeholder
            [skeletonBuildInput]="[
              [0, 1, 1, 2],
              [0, 3],
              [0, 2, 2, 1]
            ]"
            [watchResult]="!!taxNotice && !!taxnoticeDocument"
            [bigBoxMode]="false"
          >
            <h3>Current Taxnotice</h3>
            <ng-container *ngIf="taxNotice">
              <div class="returnValues">
                <mat-form-field>
                  <mat-label>tax year</mat-label>
                  <input
                    matInput
                    type="text"
                    [ngModel]="taxNotice.taxYear"
                    [disabled]="true"
                  />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>notice date</mat-label>
                  <esui-date-input
                    [disabled]="true"
                    type="date"
                    [ngModel]="taxNotice.taxNoticeReturnDate"
                  ></esui-date-input>
                </mat-form-field>
              </div>

              <div class="returnValues">
                <mat-form-field>
                  <mat-label>calculated tax return</mat-label>
                  <input
                    [disabled]="true"
                    matInput
                    placeholder="Amount"
                    [ngModel]="taxNotice.expectedTaxReturn"
                    type="number"
                    class="example-right-align"
                  />
                  <span matPrefix>€&nbsp;</span>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>actual tax return</mat-label>
                  <input
                    matInput
                    type="number"
                    [disabled]="true"
                    [ngModel]="taxNotice.actualTaxReturn"
                  />
                  <span matPrefix>€&nbsp;</span>
                </mat-form-field>
              </div>

              <ng-container *ngIf="sameYearTaxnotices">
                <h3>Taxnotices of {{ taxNotice.taxYear }}</h3>
                <table mat-table [dataSource]="sameYearTaxnotices">
                  <ng-container matColumnDef="Year">
                    <th mat-header-cell *matHeaderCellDef>Year</th>
                    <td mat-cell *matCellDef="let taxnotice">
                      {{ taxnotice.taxYear }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Date">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <td mat-cell *matCellDef="let taxnotice">
                      <ng-container
                        *ngIf="taxnotice.taxNoticeReturnDate; else noDate"
                      >
                        {{
                          taxnotice.taxNoticeReturnDate.toDate()
                            | date : 'shortDate'
                        }}
                      </ng-container>
                      <ng-template #noDate> -- </ng-template>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Return Value">
                    <th mat-header-cell *matHeaderCellDef>Return Value</th>
                    <td mat-cell *matCellDef="let taxnotice">
                      {{ taxnotice.actualTaxReturn | currency : 'EUR' }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Type">
                    <th mat-header-cell *matHeaderCellDef>Type</th>
                    <td mat-cell *matCellDef="let taxnotice">
                      {{ taxnotice.taxnoticeType }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Verified">
                    <th mat-header-cell *matHeaderCellDef>Verified</th>
                    <td mat-cell *matCellDef="let taxnotice">
                      {{ taxnotice.verified }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Appeal Status">
                    <th mat-header-cell *matHeaderCellDef>Appeal Status</th>
                    <td mat-cell *matCellDef="let taxnotice">
                      {{ taxnotice.appealedStatus }}
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                  <tr
                    mat-row
                    *matRowDef="let taxnotice; columns: columnsToDisplay"
                  ></tr>
                </table>
              </ng-container>
              <h3>Summary</h3>
              <mat-grid-list class="summary" cols="2" rowHeight="20px">
                <mat-grid-tile class="left" [colspan]="1" [rowspan]="1">
                  <div class="left">expected overall Return Value</div>
                </mat-grid-tile>
                <mat-grid-tile class="right" [colspan]="1" [rowspan]="1">
                  <div class="right">
                    {{ (expectedTaxnoticeReturn() | number : '1.2-2') ?? '--' }}
                  </div>
                </mat-grid-tile>
                <mat-grid-tile class="left" [colspan]="1" [rowspan]="1">
                  <div class="left">combined actual Return</div>
                </mat-grid-tile>
                <mat-grid-tile class="right" [colspan]="1" [rowspan]="1">
                  <div class="right">
                    {{
                      (sumOfTaxnoticeActualReturns() | number : '1.2-2') ?? '--'
                    }}
                  </div>
                </mat-grid-tile>
                <mat-grid-tile class="left" [colspan]="1" [rowspan]="1">
                  <div class="left">current threshhold</div>
                </mat-grid-tile>
                <mat-grid-tile class="right" [colspan]="1" [rowspan]="1">
                  <div class="right">
                    {{
                      (taxnoticeDifferenceThreshhold | number : '1.2-2') ?? '--'
                    }}
                  </div>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="2" [rowspan]="1">
                  <div class="leftAndRight">
                    <mat-divider></mat-divider>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile class="left" [colspan]="1" [rowspan]="1">
                  <div class="left">comparison:</div>
                </mat-grid-tile>
                <mat-grid-tile class="right" [colspan]="1" [rowspan]="1">
                  <div
                    class="right"
                    [ngStyle]="{
                      color:
                        calculatedDiferrence() +
                          (taxnoticeDifferenceThreshhold ?? 0) <
                        0
                          ? 'red'
                          : 'green'
                    }"
                  >
                    {{ (calculatedDiferrence() | number : '1.2-2') ?? '--' }}
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
            </ng-container>
          </esui-skeleton-placeholder>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="notAppealClick.next()"
        [disabled]=""
      >
        Accept
      </button>
      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="appealClick.next()"
        [disabled]=""
      >
        Appeal
      </button>
    </mat-card-actions>
  </mat-card></ng-container
>
