<div class="modal-dialog modal-dialog-scrollable" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel"></h5>
      <h5 class="modal-title" id="exampleModalLabel">Kommentar</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <i aria-hidden="true" class="ki ki-close"></i>
      </button>
    </div>
    <div class="modal-body" style="height: 300px">
      <div id="kt_note_editor" style="height: 200px"></div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-light-primary font-weight-bold"
        data-dismiss="modal"
      >
        Schliessen
      </button>
      <button
        type="button"
        class="btn btn-primary font-weight-bold"
        (click)="add()"
      >
        Speichern
      </button>
    </div>
  </div>
</div>
