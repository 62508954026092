import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SmartTask, User } from '@expresssteuer/models';

interface WithRef {
  _ref: string;
}

@Component({
  selector: 'esui-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
  animations: [
    trigger('animateDestroy', [
      state(
        'void',
        style({ transform: 'scaleY(0)', transformOrigin: 'top', height: 0 })
      ),
      transition('* => void', animate('500ms ease')),
    ]),
  ],
})
export class TaskListComponent {
  @Input() tasks: (SmartTask & WithRef)[] = [];
  @Input() usersPerTasks?: Map<SmartTask & WithRef, Partial<User>[]> | null;

  @Output() ngModelChange = new EventEmitter<(SmartTask & WithRef)[]>();
  @Input() ngModel: (SmartTask & WithRef)[] = [];

  trackByRef(index: number, { _ref }: WithRef) {
    return _ref;
  }
}
