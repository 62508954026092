import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Optional,
  Self,
} from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { scrollToElement } from '../helper/helper';

/**
 * Mark a form as touched on submit
 * @source https://netbasal.com/quick-tip-for-angular-forms-keep-your-markallastouched-dry-a1956fbd2d4c
 */
@Directive({
  selector: '[esuiTouchOnSubmit]',
})
export class EsuiTouchOnSubmitDirective {
  /**
   * When set to `true`, we scroll to the first invalid form control.
   * @default true
   */
  @Input()
  get scrollToFirstInvalid(): boolean {
    return this._scrollToFirstInvalid;
  }
  set scrollToFirstInvalid(value: BooleanInput) {
    this._scrollToFirstInvalid = coerceBooleanProperty(value);
  }
  private _scrollToFirstInvalid = true;

  private static scrollToElement(element?: HTMLElement) {
    const scrollOptions: ScrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'center',
    };

    if (typeof (element as any)?.scrollIntoViewIfNeeded === 'function') {
      // webkit browsers support non standard `scrollIntoViewIfNeeded`
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded
      (element as any).scrollIntoViewIfNeeded(scrollOptions);
      return;
    }
    element?.scrollIntoView(scrollOptions);
  }

  @HostListener('submit')
  onSubmit() {
    if (this.container?.control) {
      this.markAsTouchedAndScrollToInvalid(this.container?.control);
    }
  }

  markAsTouchedAndScrollToInvalid(control: AbstractControl) {
    control.markAllAsTouched();

    if (this.scrollToFirstInvalid) {
      const firstInvalidField = this.getFirstInvalidField();
      scrollToElement(firstInvalidField);
    }
  }

  private getFirstInvalidField(): HTMLElement | undefined {
    return this.elementRef.nativeElement?.querySelector(
      '.ng-invalid:not(form):not([ngmodelgroup])' // TODO is this query always returning the correct element?
    );
  }

  constructor(
    private elementRef: ElementRef,
    @Optional() @Self() private container?: ControlContainer
  ) {
    if (container === null) {
      console.warn(
        `${this.constructor.name} used on a component without a ${ControlContainer.name}`
      );
    }
  }
}
