import { Timestamp } from '../helpers/timestamp';

export enum jobStatus {
  'maried',
  'single',
}

export interface IPartner {
  inChurch: false;
  marriedSince: Timestamp | null;
  birthdate: Timestamp | null;
  firstname: string;
  lastname: string;
  hasDisability: boolean;
  disability: string;
  jobStatus: jobStatus;
  unionMembership: boolean;
  unionMember: string;
  start: Timestamp | null;
  end: Timestamp | null;
}
