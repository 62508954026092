import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InternalUser } from '@expresssteuer/models';

@Component({
  selector: 'esui-user-icon-menu',
  templateUrl: './user-icon-menu.component.html',
  styleUrls: ['./user-icon-menu.component.scss'],
})
export class UserIconMenuComponent {
  @Input() user?: InternalUser;
  @Input() role?: string | null;
  @Input() profileHref?: string | null;
  @Output() logoutClicked = new EventEmitter<void>();
  @Output() moreClicked = new EventEmitter<void>();
  @Output() getJwtClicked = new EventEmitter<void>();
}
