/**
 * Returns an object with the given object's id as key and the object as value.
 * @param arg - The object to bump the id of.
 * @returns An object with the given object's id as key and the object as value.
 */
export function bumpId<T extends { id: string }>(arg: T): KeyWithId<T> {
  return {
    [arg.id]: arg,
  } as KeyWithId<T>; // TODO
}

/**
 * Returns an object with the elements of the input array as values and their ids as keys.
 * @param arg - The input array with elements that have an `id` property.
 * @returns An object with the elements of the input array as values and their ids as keys.
 * 
 * @example
```ts
  interface User {
    id: string;
    name: string;
    email: string;
  }

  const users: User[] = [
    { id: 'A1', name: 'Alice', email: 'alice@example.com' },
    { id: 'A2', name: 'Bob', email: 'bob@example.com' },
    { id: 'A3', name: 'Charlie', email: 'charlie@example.com' },
  ];

  const usersById = bumpIds(users);

  console.log(usersById['A1']); // { id: 'A1', name: 'Alice', email: 'alice@example.com' }
  console.log(usersById['A2']); // { id: 'A2', name: 'Bob', email: 'bob@example.com' }
  console.log(usersById['A3']); // { id: 'A3', name: 'Charlie', email: 'charlie@example.com' }
```
 */
export function bumpIds<T extends { id: string }[]>(
  arg: T
): {
  [key in T[number]['id']]: T[number];
} {
  return Object.fromEntries(arg.map((e) => [e.id, e])) as {
    [key in T[number]['id']]: T[number];
  };
}

export type KeyWithId<T extends { id: string }> = { [key in T['id']]: T };
