import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { EsStoreStateIndicatorModule } from '../es-store-state-indicator/es-store-state-indicator.module';
import { TransactionlinesComponent } from './transactionlines.component';

@NgModule({
  declarations: [TransactionlinesComponent],
  imports: [
    CommonModule,
    MatTableModule,
    EsStoreStateIndicatorModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatChipsModule,
  ],
  exports: [TransactionlinesComponent],
})
export class TransactionlinesModule {}
