export abstract class DataTaxnotice {
  static getTemplate(
    parsedDataPoints: BescheidDataValue[],
    parsedDataHeader: BescheidDataHeader,
    originRawDataTaxnoticeRefPath: string,
    data: Partial<DataTaxnotice> & {
      taxId: string;
      clientId: string;
      taxNumber: string;
      payoutIban: string | null;
      transferAmount: number;
      refundAmount: number;
      taxAdvisorId: string;
      year: string;
    }
  ): DataTaxnotice {
    return {
      expectedAmount: null,
      taxcaseId: null,
      wrongPayoutAccount: null,
      taxcaseProvidedIban: null,
      ...data,
      parsedDataPoints,
      parsedHeaderData: parsedDataHeader,
      rawTaxnoticeDataRefPath: originRawDataTaxnoticeRefPath,
    };
  }

  abstract taxId: string;
  abstract clientId: string;
  abstract taxNumber: string;
  abstract payoutIban: string | null;
  abstract taxcaseProvidedIban: string | null;
  abstract parsedDataPoints: BescheidDataValue[];
  /**
   * amount actually send to our iban
   */
  abstract transferAmount: number;
  /**
   * amount we subtract our charges from (without any Verrechnungen)
   */
  abstract refundAmount: number;
  abstract expectedAmount: number | null;
  abstract rawTaxnoticeDataRefPath: string;
  abstract parsedHeaderData: BescheidDataHeader;
  abstract taxAdvisorId: string;
  abstract year: string;
  abstract taxcaseId: string | null;
  abstract wrongPayoutAccount: boolean | null;
}

export interface BescheidDataValue {
  fullId: string;
  category: string;
  id: string;
  subNumber: string;
  format: string;
  length: number;
  rawValue: string;
  parsedValue: string | number | null;
  mappedDescription: string | null;
  textLength: number;
  text: string;
}

export interface BescheidDataHeader {
  version: number;
  financeOfficeNumber: string;
  isRealTaxNumber: boolean;
  taxNumber: string;
  subType: string;
  year: string;
  process: string;
  transmissionAt: string;
  taxType: string; // 'EB' | 'UB' | 'GB' | 'GF';
  setTyping: string;
  creationDate: string;
  creationTime: string;
  taxId: string;
  partnerId: string | null;
  transferrerId: string;
  transferrerName: string;
  elsterTransferId: string | null;
}

export interface BescheidData {
  header: BescheidDataHeader;
  values: BescheidDataValue[];
  namedValues: Partial<NamedBescheidData>;
}
export interface NamedBescheidData {
  soliAmountOpen: BescheidDataValue;
  incomeTaxAmountOpen: BescheidDataValue;
  overallAmountOpen: BescheidDataValue;
  transferIban: BescheidDataValue;
}
