<span>
  <i
    class="far fas fa-check-double text-success mr-5"
    *ngIf="safeSearch === 'VERY_UNLIKELY'"
  ></i>
  <i
    class="far fas fa-check-circle text-success mr-5"
    *ngIf="safeSearch === 'UNLIKELY'"
  ></i>
  <i
    class="far flaticon-questions-circular-button text-warning mr-5"
    *ngIf="safeSearch === 'LIKELY'"
  ></i>
  <i
    class="far flaticon-warning-sign text-warning mr-5"
    *ngIf="safeSearch === 'POSSIBLE'"
  ></i>
  <i class="far flaticon-circle text-danger mr-5" *ngIf="safeSearch === ''"></i>
</span>
