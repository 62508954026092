import {
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import { NgAisModule } from 'angular-instantsearch';

export function buildImportsForRoot(options = {}) {
  // TODO build based on provided options
  return [NgAisModule.forRoot(), MatMomentDateModule];
}

export function buildProvidersForRoot(options = {}) {
  // TODO build based on provided options
  return [{ provide: DateAdapter, useClass: MomentDateAdapter }];
}
