import { PayoutPayment } from '@expresssteuer/payout-api-interfaces';
import { BankingA352PaymentDetailsExclusiveResponse } from '@expresssteuer/banking-api-interfaces';

export interface BankingPayoutApprovedPaymentList {
  processed?: boolean;
  retries?: number;
  listId: string;
  payments: BankingPayoutApprovedPayments;
}

export interface BankingPayoutApprovedPayments {
  [id: string]: BankingPayoutApprovedPayment;
}

export interface BankingPayoutApprovedPayment extends PayoutPayment {
  listId: string;
  payoutId: string;
  response?: BankingA352PaymentDetailsExclusiveResponse;
}
