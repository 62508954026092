import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MARITALSTATUS } from '@expresssteuer/models';

@Component({
  selector: 'app-marital-status-bar',
  templateUrl: './marital-status-bar.component.html',
  styleUrls: ['./marital-status-bar.component.scss'],
})
export class ControlMaritalStatusBarComponent implements OnInit {
  MARITALSTATUS = MARITALSTATUS;
  maritalstatusValue: MARITALSTATUS;

  @Output()
  maritalStatusChange = new EventEmitter<MARITALSTATUS>();

  @Input()
  get maritalstatus(): MARITALSTATUS {
    return this.maritalstatusValue;
  }

  set maritalstatus(val: MARITALSTATUS) {
    this.maritalstatusValue = val;
    this.maritalStatusChange.emit(this.maritalstatusValue);
  }

  constructor() {}

  ngOnInit(): void {}
}
