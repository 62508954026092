<div class="form-group row">
  <div class="col-12">
    <select
      class="form-control form-control-lg form-control-solid bg-white"
      type="text"
      name="filterUser"
      [ngModel]="currentUser"
      (ngModelChange)="valueChange.next($event)"
    >
      <option *ngIf="currentUser" [ngValue]="currentUser">
        {{ currentUser.displayName }}
      </option>
      <option *ngFor="let user of allUsers" [ngValue]="user">
        {{ user.displayName }}
      </option>
    </select>
  </div>
</div>
