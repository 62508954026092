import {
  AdditionalExpense,
  ADDITIONALEXPENSECATEGORY,
  ADDITIONALEXPENSESUBCATEGORY,
} from './additionalexpenses';

export class AdditionalExpenseRurup extends AdditionalExpense {
  public static override getTemplate(): AdditionalExpenseRurup {
    return {
      id: '',
      type: 'rurup',
      description: '',
      category: ADDITIONALEXPENSECATEGORY.advertise,
      subcategory: ADDITIONALEXPENSESUBCATEGORY.PENSION,
      value: 0.0,
      partial: false,
      provider: '',
      certificate: '',
      contract: '',
      allowance: '',
    };
  }

  public provider: string = '';
  public certificate: string = '';
  public contract: string = '';
  public allowance: string = '';
}
