import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PureMethodModule } from '../pipes/pure-method/pure-method.module';
import { AudioViewerComponent } from './components/audio-viewer/audio-viewer.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { PdfViewerLocalComponent } from './components/pdf-viewer-2/pdf-viewer-2.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { ResourceSanitizePipe } from './components/pdf-viewer/resourceSanitize.pipe';
import { EsuiDocumentViewerComponent } from './esui-document-viewer.component';
@NgModule({
  declarations: [
    EsuiDocumentViewerComponent,
    ImageViewerComponent,
    PdfViewerComponent,
    PdfViewerLocalComponent,
    AudioViewerComponent,
    ResourceSanitizePipe,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    PdfViewerModule,
    PureMethodModule,
  ],
  exports: [EsuiDocumentViewerComponent],
})
export class EsuiDocumentViewerModule {}
