import { Timestamp } from '../helpers/timestamp';
import { TaskMatcherMetadata } from '../smart-task/smart-task';
import { ClientFacingTask, CLIENTFACINGTASKTYPE } from './client-facing-task';

export const getOrGenerateId = (task: {
  id?: string;
  taskMatcherMetadata?: TaskMatcherMetadata | null;
  clientId?: string;
}): string | undefined => {
  if (task.id) {
    return task.id;
  }

  if (!task.taskMatcherMetadata) {
    return undefined;
  }

  let stringToHash = JSON.stringify(task.taskMatcherMetadata);
  if (task.clientId) {
    stringToHash = `${task.clientId}${stringToHash}`;
  }
  //return createHash('sha256').update(stringToHash).digest('hex');
  return stringToHash;
};

export const getTemplate = <TTaskMatcherMetadata extends TaskMatcherMetadata>(
  data: Partial<ClientFacingTask> & {
    taskMatcherMetadata: TTaskMatcherMetadata;
    clientId: string;
    type: CLIENTFACINGTASKTYPE;
  }
): ClientFacingTask<TTaskMatcherMetadata> => {
  const id = getOrGenerateId(data);
  if (!id) {
    throw new Error(
      'ups, no task id in ClientFacingTask.getTemplate, this shoudl never occur!'
    );
  }
  const newTask: ClientFacingTask<TTaskMatcherMetadata> = {
    // default data:
    years: [],
    taxcaseIds: [],
    createdAt: Timestamp.now(),
    referencedDocuments: [],
    deactivated: false,
    deactivatedChangedAt: null,
    noneExistent: false,
    // provided data:
    ...data,
    // generated data:
    id,
  };

  return newTask;
};
