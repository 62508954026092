import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Partner } from '../esui-partner/esui-partner.component';

@Component({
  selector: 'esui-partners-list',
  templateUrl: './esui-partners-list.component.html',
  styleUrls: ['./esui-partners-list.component.scss'],
  animations: [
    trigger('animateDestroy', [
      state(
        'void',
        style({ transform: 'scaleY(0)', transformOrigin: 'top', height: 0 })
      ),
      transition('* => void', animate('500ms ease')),
    ]),
  ],
})
export class EsuiPartnersListComponent {
  @Output() activePartnersChange = new EventEmitter<Partner[]>();
  @Input() activePartners?: { partnerId: string }[] | null = [];

  @Input() partners?: Partner[] | null = [];

  trackById(_index: number, { partnerId }: Partner) {
    return partnerId;
  }

  comparePartners(o1: { partnerId: string }, o2: { partnerId: string }) {
    return o1.partnerId === o2.partnerId;
  }
}
