import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TaxCase } from '@expresssteuer/models';

@Component({
  selector: 'esui-taxcase-editor',
  templateUrl: './taxcase-editor.component.html',
  styleUrls: ['./taxcase-editor.component.scss'],
})
export class TaxcaseEditorComponent {
  @Input()
  taxcase?: TaxCase;

  @Output()
  taxcasePartialChange = new EventEmitter<Partial<TaxCase>>();

  @Output()
  taxcaseChange = new EventEmitter<TaxCase>();

  @Output()
  saveClick = new EventEmitter<void>();

  partialUpdate(change: Partial<TaxCase>) {
    const merged = {
      ...(this.taxcase ?? TaxCase.getTemplate()),
      ...change,
    };
    this.taxcaseChange.next(merged);
    this.taxcasePartialChange.next(change);
  }
}
