<mat-card>
  <h1 esuiCardKey>Taxcase Final überprüfen</h1>
  <mat-card-header>
    <mat-card-title>
      <esui-store-state-indicator
        [hasOnlineChangedAfterLocalChanged]="
          !!taxcaseHasOnlineChangedAfterLocalChanged
        "
        [isSaving]="!!taxcaseIsSaving"
        [isLoading]="!!taxcaseIsLoading"
        [hasChanges]="!!taxcaseHasChanges"
        [hasErrors]="!!taxcaseHasErrors"
      ></esui-store-state-indicator
    ></mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="column left">
      <esui-tax-calculation
        [year]="taxcase?.year"
        [rows]="taxCalculationRows"
        [expectedReturn]="taxCalculationExpectedReturn"
      ></esui-tax-calculation>

      <br />
      <esui-generic-list
        [displayItems]="taxStatementItems"
        class="mat-elevation-z8"
      ></esui-generic-list>
    </div>

    <div class="column right">
      <h4>Problematische Extraktion</h4>
      <esui-generic-list
        [displayItems]="
          convertFlagsToDisplayItems | pureMethod : { args: [taxcase] }
        "
        class="mat-elevation-z8"
      ></esui-generic-list>

      <h4>Plausibilitätscheck Fehler</h4>
      <esui-generic-list
        [displayItems]="plausibilityCheckReportErrorItems"
        class="mat-elevation-z8"
      ></esui-generic-list>

      <h4>Plausibilitätscheck Erfolgreich</h4>
      <esui-generic-list
        [displayItems]="plausibilityCheckReportSuccessItems"
        class="mat-elevation-z8"
      ></esui-generic-list>
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="enableActionButtons">
    <button mat-raised-button color="primary" (click)="submitTaskClick.next()">
      send to customer
    </button>
    <button
      mat-raised-button
      (click)="unattractive()"
      (blur)="cancelUnattractive()"
      (mouseleave)="cancelUnattractive()"
      color="warn"
    >
      <ng-container>
        <span *ngIf="unattractiveConfirmationNeeded"> unattractive </span>
        <span *ngIf="!unattractiveConfirmationNeeded">
          click again to confirm
        </span>
      </ng-container>
    </button>
    <button mat-raised-button (click)="showCommentClick.next()">
      more work needed
    </button>
    <div class="comment" *ngIf="showComment">
      <mat-form-field>
        <mat-label>Reason</mat-label>
        <input
          matInput
          [ngModel]="comment"
          (ngModelChange)="commentChange.next($event)"
        />
        <button
          matSuffix
          mat-icon-button
          aria-label="send"
          (click)="moreWorkNeededClick.next()"
          [disabled]="!comment"
        >
          <mat-icon>send</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </mat-card-actions>
</mat-card>
