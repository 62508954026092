/* eslint-disable */

import { Konfessions } from './vast-lstb';
export interface VaSt_Pers2 {
  VaSt_Pers2: {
    IdNr?: string;
    Religion?: Konfessions;
    version: string;
  };
}
