import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DOCUMENTSOURCE, TaxNotice } from '@expresssteuer/models';
import { StoreStateIndicatorModel } from '../es-store-state-indicator/store-state-indicator.interface';
import { BankTransactionLine } from '@expresssteuer/banking-models';

@Component({
  selector: 'esui-taxnotice-verifier',
  templateUrl: './esui-taxnotice-verifier.component.html',
  styleUrls: ['./esui-taxnotice-verifier.component.scss'],
})
export class EsuiTaxnoticeVerifierComponent {
  @Input()
  documentStoreStateIndicator?: Partial<StoreStateIndicatorModel> | null;
  @Input() document?: {
    contentType: string;
    downloadUrl: string;
    source?: DOCUMENTSOURCE | null;
  } | null;

  @Input()
  taxNoticeStoreStateIndicator?: Partial<StoreStateIndicatorModel> | null;

  @Input() taxNotice?: TaxNotice | null;
  @Output() taxNoticeChange = new EventEmitter<TaxNotice>();
  @Output() taxNoticePartialChange = new EventEmitter<Partial<TaxNotice>>();

  @Output() verifyAndSaveClick = new EventEmitter<void>();
  @Output() saveClick = new EventEmitter<void>();
  @Output() discardClick = new EventEmitter<void>();

  @Input() transactionLines?: BankTransactionLine[] | null;
  @Input() transactionLinesState?: Partial<StoreStateIndicatorModel> | null;

  @Output() generateFakeTransactionlineClick = new EventEmitter<void>();
  @Output() clearFakeTransactionlineClick = new EventEmitter<void>();

  @Input() showTransactionLines?: boolean = false;

  partialTaxnoticeChange<K extends keyof TaxNotice>(
    key: K,
    value: TaxNotice[K]
  ): void;
  partialTaxnoticeChange(change: Partial<TaxNotice>): void;
  partialTaxnoticeChange<K extends keyof TaxNotice>(
    a: Partial<TaxNotice> | K,
    b?: TaxNotice[K]
  ): void {
    const change =
      typeof a === 'string'
        ? {
            [a]: b,
          }
        : a;

    const merged = { ...this.taxNotice, ...change };
    this.taxNoticePartialChange.next(change);
    this.taxNoticeChange.next(merged as any);
  }

  selectedTransactionChange(selectedTransactions: BankTransactionLine[]) {
    this.partialTaxnoticeChange({ transactionLines: selectedTransactions });
  }

  get sumOfDeductions() {
    return (this.taxNotice?.deductions ?? []).reduce(
      (acc, curr) => curr.value + acc,
      0
    );
  }

  get sumOfTransactionLines() {
    return (this.taxNotice?.transactionLines ?? []).reduce(
      (acc, curr) => curr.amount + acc,
      0
    );
  }

  get comparison() {
    if (typeof this.taxNotice?.actualTaxReturn !== 'number') {
      return undefined;
    }
    return (
      this.taxNotice.actualTaxReturn -
      this.sumOfDeductions -
      this.sumOfTransactionLines
    );
  }
}
