import { Component, Input, TemplateRef } from '@angular/core';
import { ClientIdentifyingProofQAA } from '@expresssteuer/client-todos-api-interfaces';

@Component({
  selector: 'esui-client-identifying-proof-answer',
  templateUrl: './esui-client-identifying-proof-answer.component.html',
  styleUrls: ['./esui-client-identifying-proof-answer.component.scss'],
})
export class EsuiClientIdentifyingProofAnswerComponent {
  @Input()
  documentViewerTemplate?: TemplateRef<{ binaryDocumentRef: string }>;

  @Input()
  answerPayload?: ClientIdentifyingProofQAA.AnswerPayload;
}
