import { Component, Input } from '@angular/core';
import { MARITALSTATUS, TaxCase } from '@expresssteuer/models';

@Component({
  selector: 'esui-taxcase-short',
  templateUrl: './taxcase-short.component.html',
  styleUrls: ['./taxcase-short.component.scss'],
})
export class TaxcaseShortComponent {
  MARITALSTATUS = MARITALSTATUS;

  @Input()
  taxcase?: TaxCase | null;

  @Input()
  horizontal?: boolean | null | undefined = false;

  /**
   * Sum of all job wages
   */
  get totalWage(): number {
    return (
      this.taxcase?.taxform?.current?.taxForm?.jobs?.reduce(
        (acc, job) => (job?.wagestatement?.wage ?? 0) + acc,
        0
      ) ?? 0
    );
  }

  /**
   * If some job has shorttime
   */
  get hasShorttime(): boolean {
    return (
      this.taxcase?.taxform?.current?.taxForm?.jobs?.some(
        (job) => job.worksetup.shortTime?.shortTime === true
      ) ?? false
    );
  }
}
