import { Component, Input } from '@angular/core';
import { DOCUMENTSOURCE } from '@expresssteuer/models';

declare const window: { location: { hostname: string } };

enum TYPE {
  IMAGE = 'image',
  PDF = 'pdf',
  AUDIO = 'audio',
  OTHER = 'other',
}
@Component({
  selector: 'esui-document-viewer',
  templateUrl: './esui-document-viewer.component.html',
  styleUrls: ['./esui-document-viewer.component.scss'],
})
export class EsuiDocumentViewerComponent {
  TYPE = TYPE;
  DOCUMENTSOURCE = DOCUMENTSOURCE;

  @Input() contentType?: string | null;
  @Input() downloadUrl?: string | null;
  @Input() source?: DOCUMENTSOURCE | null;
  @Input() isLoading?: boolean | null;
  @Input() hasErrors?: boolean | null;
  isLocal =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';
  @Input()
  public showNavigation = true;
  @Input()
  public showDownloadButton = false;

  @Input()
  public fileName?: string;

  public get type(): TYPE {
    switch (this.contentType) {
      case 'application/pdf':
      case 'text/plain':
        return TYPE.PDF;
      case 'audio/ogg':
        return TYPE.AUDIO;
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/png':
        return TYPE.IMAGE;
      default:
        return TYPE.OTHER;
    }
  }
}
