export enum BankProvider {
  CLIENT = 'client',
  /**
   * @deprecated qonto is intended to be removed
   */
  QONTO = 'qonto',
  A352 = 'a352',
  STRIPE = 'stripe',
  TAXADVISOR = 'taxadvisor',
  EXPRESSSTEUER = 'expresssteuer',
}
