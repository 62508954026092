import { Component, Input } from '@angular/core';
import { FinanceOffice } from '@expresssteuer/models';

@Component({
  selector: 'esui-finance-office',
  templateUrl: './finance-office.component.html',
  styleUrls: ['./finance-office.component.scss'],
})
export class FinanceOfficeComponent {
  @Input()
  financeOffice?: FinanceOffice | null;
}
