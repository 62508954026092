<div class="form-group" *ngIf="document">
  <label for="documentCategory">Kategorie </label>
  <select
    class="form-control"
    id="documentCategory"
    [(ngModel)]="document.metadata.type"
  >
    <option [ngValue]="DOCUMENTTYPE.identitycard">Identitätskarte</option>
    <option [ngValue]="DOCUMENTTYPE.signature">Unterschrift</option>
    <option [ngValue]="DOCUMENTTYPE.wagestatement">Lohnsteuer</option>
    <option [ngValue]="DOCUMENTTYPE.expense">Beleg Ausgabe</option>
    <option [ngValue]="DOCUMENTTYPE.receipt">Beleg Rechnung</option>
    <option [ngValue]="DOCUMENTTYPE.other">Anderes</option>
    <option [ngValue]="DOCUMENTTYPE.testtaxoffice" disabled="true">
      Testfall Finanzamt
    </option>
    <option [ngValue]="DOCUMENTTYPE.taxoffice" disabled="true">
      Finanzamt Fall
    </option>
  </select>
</div>
