<div #frame>
  <ng-container
    *ngIf="{
      small:
        (mode ?? 'small') === 'small'
          ? true
          : mode === 'large'
          ? false
          : ((hostWidth$ | async) ?? 0) < 700
    } as data"
  >
    <ng-container *ngIf="data.small">
      <mat-tab-group>
        <mat-tab label="short">
          <ng-template matTabContent>
            <esui-taxcase-short [taxcase]="taxcase"></esui-taxcase-short>
          </ng-template>
        </mat-tab>
        <mat-tab label="calculation">
          <ng-template matTabContent>
            <esui-tax-calculation
              [year]="taxcase?.year"
              [rows]="taxCalculationRows"
              [expectedReturn]="taxCalculationExpectedReturn"
            >
            </esui-tax-calculation>
          </ng-template>
        </mat-tab>

        <mat-tab label="additions">
          <ng-template matTabContent>
            <esui-generic-list
              [elevated]="false"
              [displayItems]="taxStatementItems"
            ></esui-generic-list>
          </ng-template>
        </mat-tab>

        <mat-tab label="extractions">
          <ng-template matTabContent>
            <esui-generic-list
              [elevated]="false"
              [displayItems]="
                convertFlagsToDisplayItems | pureMethod : { args: [taxcase] }
              "
            ></esui-generic-list>
          </ng-template>
        </mat-tab>

        <mat-tab label="plausibility">
          <ng-template matTabContent>
            <h4>errors</h4>
            <esui-generic-list
              [elevated]="false"
              [displayItems]="plausibilityCheckReportErrorItems"
            ></esui-generic-list>
            <h4>success</h4>
            <esui-generic-list
              [elevated]="false"
              [displayItems]="plausibilityCheckReportSuccessItems"
            ></esui-generic-list>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </ng-container>

    <ng-container *ngIf="!data.small">
      <div class="row">
        <esui-taxcase-short
          [taxcase]="taxcase"
          [horizontal]="true"
        ></esui-taxcase-short>
      </div>
      <div class="row">
        <esui-tax-calculation
          [year]="taxcase?.year"
          [rows]="taxCalculationRows"
          [expectedReturn]="taxCalculationExpectedReturn"
          [elevated]="false"
        >
        </esui-tax-calculation>
        <esui-generic-list
          [elevated]="false"
          [displayItems]="taxStatementItems"
        ></esui-generic-list>
      </div>
      <div class="row">
        <div>
          <h4>flags</h4>
          <esui-generic-list
            [elevated]="false"
            [displayItems]="
              convertFlagsToDisplayItems | pureMethod : { args: [taxcase] }
            "
          ></esui-generic-list>
        </div>
      </div>

      <div class="row">
        <div>
          <h4>errors</h4>
          <esui-generic-list
            [elevated]="false"
            [displayItems]="plausibilityCheckReportErrorItems"
          ></esui-generic-list>
        </div>
        <div>
          <h4>success</h4>
          <esui-generic-list
            [elevated]="false"
            [displayItems]="plausibilityCheckReportSuccessItems"
          ></esui-generic-list>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
