import { DOCUMENTTYPE } from '../document/document';
import { Timestamp } from '../helpers/timestamp';
import { TaxofficeLetter } from './taxoffice-letter';

/**
 * Request of finance office to provide information with due date
 */
export abstract class TaxofficeRequest extends TaxofficeLetter {
  documentType: DOCUMENTTYPE.taxoffice_request = DOCUMENTTYPE.taxoffice_request;
  /**
   * current due date
   */
  abstract dueDate: Timestamp | null;
  /**
   * amount of reminders for this request letter
   */
  abstract reminderCount: number | null;
  /**
   * amount of times, we extended the due date
   */
  abstract extendedDueDateCount: number;
  /**
   * list of associated reminders
   */
  abstract taxofficeReminderIds: string[] | null;
  /**
   * reply sent to the taxoffice
   */
  abstract replySent: boolean;
  /**
   * reply bounced
   */
  abstract sendFailed: boolean | null;
  /**
   * last messageId which was generated to send email
   */
  abstract lastOutboundReplyId?: string | null;
  /**
   * Email of self assinged user to process this request.
   * Note: this is a quick fix solution until a holistic one is built for all long tasks
   */
  abstract assignedOperatorEmail?: string | null;
}
