import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Sort as MaterialSort } from '@angular/material/sort';
import {
  Client,
  Documenttype,
  Timestamp,
  UniqueContentTagMap,
} from '@expresssteuer/models';
import { Observable } from 'rxjs';
import { EsuiLoggerService } from '../esui-debug/services/esui-logger.service';

interface Item {
  id: string;
  clientData?: Client;
  dueDate: Timestamp | null;
  containedUniqueTags: UniqueContentTagMap;
  reminderCount: number | null;
  extendedDueDateCount: number;
  fullFilled: boolean;
}

interface Sort {
  /** The id of the column being sorted. */
  active: string;
  /** The sort direction. */
  direction: 'asc' | 'desc' | '';
}
export type FilterCategories = 'fulfilled' | 'unfulfilled' | 'done' | string;
@Component({
  selector: 'esui-tax-office-deadlines-filter',
  templateUrl: './esui-tax-office-deadlines-filter.component.html',
  styleUrls: ['./esui-tax-office-deadlines-filter.component.scss'],
  providers: [EsuiLoggerService],
})
export class EsuiTaxOfficeDeadlinesFilterComponent {
  @Input() countOfCategory?: number | null;
  @Input() sort?: Sort | null;
  @Input() currentLookAheadCount?: number | null;
  @Input() currentLookBehindCount?: number | null;
  @Output() sortChange = new EventEmitter<Sort>();

  @Input() pageSize?: number | null;
  @Input() selectedCategory: FilterCategories | null = 'unfulfilled';
  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() nextClick = new EventEmitter<void>();
  @Output() previousClick = new EventEmitter<void>();
  @Output() startClick = new EventEmitter<void>();
  @Output() categorySelected = new EventEmitter<FilterCategories>();

  /**
   * @note pass as an observable input as a requirement for
   * Angular CDK Tables.
   */
  @Input()
  list$?: Observable<Item[]>;
  @Output() rowClick = new EventEmitter<{
    clientId: string;
    requestId: string;
  }>();

  displayedColumns: (keyof Item)[] = [
    'id',
    'clientData' as const,
    'dueDate' as const,
    'containedUniqueTags' as const,
    'reminderCount' as const,
    'extendedDueDateCount' as const,
    'fullFilled' as const,
  ];

  Documenttype = Documenttype;

  handleSortChange(sort: MaterialSort) {
    if (!this.displayedColumns.includes(sort.active as keyof Item)) {
      this.logger.warn('sorting emitted an unknown active column');
    }

    this.sortChange.next(sort as Sort);
  }

  constructor(private logger: EsuiLoggerService) {
    logger.registerOwner(this);
  }

  getDaysUntilDue(dueDate: Timestamp): number {
    const dateNow = new Date();
    const daysUntilDeadline = dueDate.toDate().getTime() - dateNow.getTime();
    return Math.floor(daysUntilDeadline / (1000 * 60 * 60 * 24));
  }

  uniqueTagsToStringArray(uniqueTags: UniqueContentTagMap): string[] {
    if (!uniqueTags) return [];
    return Object.keys(uniqueTags);
  }
}
