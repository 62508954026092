import { PercentPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BinaryDocumentMetadata,
  Documenttype,
  DOCUMENTTYPE,
} from '@expresssteuer/models';

@Component({
  selector: 'esui-document-type-select',
  templateUrl: './document-type-select.component.html',
  styleUrls: ['./document-type-select.component.scss'],
})
export class DocumentTypeSelectComponent {
  constructor(public percentPipe: PercentPipe) {}
  @Input() documentType?: DOCUMENTTYPE | null;
  @Input() metadata?: BinaryDocumentMetadata;

  @Output() documentTypeSelect = new EventEmitter<DOCUMENTTYPE>();

  get typeSuggestions(): (TypeItem & { score?: number })[] {
    const typeSuggestions = this.metadata?.mldata?.predictions?.type;
    if (!typeSuggestions) {
      return this.typelist;
    }

    return this.typelist
      .map((entry) => {
        const score =
          typeSuggestions.find((ts) => ts.suggestion === entry.value)?.score ||
          0;
        const label = `${entry.label} (${this.percentPipe.transform(
          score,
          '1.0-2'
        )})`;

        return {
          ...entry,
          score,
          label,
        };
      })
      .sort((a, b) => {
        return a.score - b.score;
      });
  }

  private readonly typelist: TypeItem[] = Documenttype.getLabels();

  trackByTypeItem(index: number, item: TypeItem) {
    return index + item.value;
  }
}

class TypeItem {
  label = '';
  value: DOCUMENTTYPE = DOCUMENTTYPE.other;
}
