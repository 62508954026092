import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EsuiDocumentViewerModule } from '../esui-document-viewer/esui-document-viewer.module';
import { MessageComposerComponent } from './components/message-composer/message-composer.component';
import { MessageStateComponent } from './components/message-status/message-state.component';
import { MessageSuggestionComponent } from './components/message-suggestion/message-suggestion.component';
import { MessageComponent } from './components/message/message.component';
import { MessagesComponent } from './components/messages/messages.component';

@NgModule({
  declarations: [
    MessageComponent,
    MessageStateComponent,
    MessagesComponent,
    MessageComposerComponent,
    MessageSuggestionComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatTooltipModule,
    MatIconModule,
    EsuiDocumentViewerModule,
    TextFieldModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatDividerModule,
  ],
  exports: [MessageComponent, MessagesComponent, MessageComposerComponent],
})
export class EsMessagesModule {}
