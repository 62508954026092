import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlgoliaDocument } from '@expresssteuer/models';
import { AisDocumentsHelperService } from '../services/ais-documents-helper.service';

@Component({
  selector: 'app-ais-documents-collection-list',
  templateUrl: './ais-documents-collection-list.component.html',
  styleUrls: ['./ais-documents-collection-list.component.scss'],
})
export class AisDocumentsCollectionListComponent {
  @Input()
  hits: any;
  AisDocumentsHelperService = AisDocumentsHelperService;

  #selectMode = false;
  @Input() set selectMode(mode: boolean) {
    this.#selectMode = mode;
    if (this.#selectMode) {
      return;
    }
    this.selections = [];
    this.selectionsChange.next(this.selections);
  }
  get selectMode(): boolean {
    return this.#selectMode;
  }
  @Input() selections: AlgoliaDocument[] = [];
  @Output() selectionsChange = new EventEmitter<AlgoliaDocument[]>();

  /**
   * If not already selected, add the provided document to the selection;
   * otherwise remove it from the selection.
   */
  toggleSelection(doc: AlgoliaDocument) {
    if (!this.selectMode) {
      return;
    }
    if (this.isSelected(doc)) {
      // remove from selection
      this.selections = this.selections.filter((e) => e.id !== doc.id);
    } else {
      // add to selection
      this.selections = [...this.selections, doc];
    }
    this.selectionsChange.next(this.selections);
  }

  isSelected(doc: AlgoliaDocument) {
    return this.selectMode && this.selections.some((e) => e.id === doc.id);
  }
}
