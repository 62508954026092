import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-control-job-working-daysaweek',
  templateUrl: './control-job-working-daysaweek.component.html',
  styleUrls: ['./control-job-working-daysaweek.component.scss'],
})
export class ControlJobWorkingDaysaweekComponent implements OnInit {
  workingDaysValue: number;

  @Output()
  workingdaysChange = new EventEmitter<number>();

  @Input()
  get workingdays(): number {
    return this.workingDaysValue;
  }

  set workingdays(val: number) {
    this.workingDaysValue = val;
    this.workingdaysChange.emit(this.workingDaysValue);
  }

  ngOnInit(): void {}
}
