<div class="content pt-0 d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Entry-->
  <!--begin::Hero-->
  <div
    class="d-flex flex-row-fluid bgi-size-cover bgi-position-center"
    style="
      background-image: url('/metronic/theme/html/demo6/dist/assets/media/bg/bg-9.jpg');
    "
  >
    <div class="container-fluid">
      <!--begin::Topbar-->

      <!--end::Topbar-->
      <div
        class="d-flex align-items-stretch text-center flex-column pt-20 pb-20"
      >
        <!--begin::Form-->
        <form class="d-flex position-relative w-75 px-lg-40 m-auto">
          <div class="input-group">
            <!--begin::Icon-->
            <div class="input-group-prepend">
              <span class="input-group-text bg-white border-0 py-7 px-8">
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon | path:/metronic/theme/html/demo6/dist/assets/media/svg/icons/General/Search.svg-->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      ></path>
                      <path
                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      ></path>
                    </g>
                  </svg>
                  <!--end::Svg Icon-->
                </span>
              </span>
            </div>
            <!--end::Icon-->
            <!--begin::Input-->
            <input
              type="text"
              class="form-control h-auto border-0 py-7 px-1 font-size-h6"
              placeholder="Kunden/Steuerfälle/Mitteilungen/Dokumente"
              (keyup)="onQuery($event)"
            />
            <!--end::Input-->

            <ais-instantsearch
              *ngIf="configClients$ | async as configClients"
              [config]="configClients"
            >
              <ais-configure
                [searchParameters]="searchParameters"
              ></ais-configure>
            </ais-instantsearch>
          </div>
        </form>
        <!--end::Form-->
      </div>
    </div>
  </div>
  <!--end::Hero-->

  <div class="container-fluid mb-8">
    <div class="row">
      <div class="col-xs-1 col-sm-2 col-md-4 col-lg-3 col-xl-3">
        <ais-instantsearch
          *ngIf="configClients$ | async as configClients"
          [config]="configClients"
        >
          <ais-configure [searchParameters]="searchParameters"></ais-configure>

          <div class="card card-custom card-stretch gutter-b">
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark">
                  <div class="symbol symbol-40 symbol-light-primary mr-5">
                    <span class="symbol-label">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                            />
                            <path
                              d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                            />
                          </g>
                        </svg>
                      </span>
                    </span>
                  </div>
                  Kunden
                </span>
              </h3>
              <span class="card-toolbar">
                <ais-stats>
                  <ng-template class="pull-right" let-state="state">
                    {{ state.nbHits }}
                  </ng-template>
                </ais-stats>
              </span>
            </div>

            <div class="card-body pt-8">
              <ais-hits>
                <ng-template let-hits="hits" let-results="results">
                  <div
                    class="d-flex mb-10 borderBottom"
                    *ngFor="let hit of hits; let i = index"
                  >
                    <span class="label label-outline-dark label-square mr-2">{{
                      i + 1
                    }}</span>

                    <div class="d-flex flex-column font-weight-bold">
                      <a
                        href="/client/{{ hit.objectID }}"
                        class="text-dark text-hover-primary mb-1 font-size-lg"
                      >
                        <ais-highlight
                          attribute="firstname"
                          [hit]="hit"
                        ></ais-highlight
                        >&nbsp;
                        <ais-highlight
                          attribute="lastname"
                          [hit]="hit"
                        ></ais-highlight>
                        &nbsp;
                        <ais-highlight
                          attribute="taxId"
                          [hit]="hit"
                        ></ais-highlight>
                      </a>
                      <span class="text-muted">
                        Email:
                        <ais-highlight
                          attribute="email"
                          [hit]="hit"
                          (click)="sendEmail(hit)"
                        ></ais-highlight>
                      </span>
                      <span class="text-muted">
                        Phone:
                        <ais-highlight
                          attribute="mobile"
                          [hit]="hit"
                          (click)="sendWhatsapp(hit)"
                        ></ais-highlight>
                      </span>
                      <span class="text-muted" *ngFor="let tc of hit.taxcases">
                        {{ tc.taxYear }} -
                        <a
                          href="/client/{{ hit.client_id }}/taxcase/{{
                            hit.objectID
                          }}/information"
                          class="text-muted text-hover-primary"
                        >
                          {{ tc.caseId }}
                        </a>
                        <br />
                      </span>
                    </div>
                  </div>
                </ng-template>
              </ais-hits>
            </div>
          </div>
        </ais-instantsearch>
      </div>

      <div class="col-xs-1 col-sm-2 col-md-4 col-lg-3 col-xl-3">
        <ais-instantsearch
          *ngIf="configTaxcases$ | async as configTaxcases"
          [config]="configTaxcases"
        >
          <ais-configure [searchParameters]="searchParameters"></ais-configure>

          <div class="card card-custom card-stretch gutter-b">
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark">
                  <div class="symbol symbol-40 symbol-light-primary mr-5">
                    <span class="symbol-label">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                            />
                            <path
                              d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                            />
                          </g>
                        </svg>
                      </span>
                    </span>
                  </div>
                  Fälle
                </span>
                <span
                  class="text-muted mt-3 font-weight-bold font-size-sm"
                ></span>
              </h3>
              <span class="card-toolbar">
                <ais-stats>
                  <ng-template class="pull-right" let-state="state">
                    {{ state.nbHits }}
                  </ng-template>
                </ais-stats>
              </span>
            </div>

            <div class="card-body pt-8">
              <ais-hits>
                <ng-template let-hits="hits" let-results="results">
                  <div
                    class="d-flex mb-10 borderBottom"
                    *ngFor="let hit of hits; let i = index"
                  >
                    <span class="label label-outline-dark label-square mr-2">{{
                      i + 1
                    }}</span>

                    <div class="d-flex flex-column font-weight-bold">
                      <a
                        href="/client/{{ hit.clientId }}/taxcase/{{
                          hit.objectID
                        }}/information"
                        class="text-dark text-hover-primary mb-1 font-size-lg"
                      >
                        <ais-highlight
                          attribute="firstname"
                          [hit]="hit"
                        ></ais-highlight>
                        <ais-highlight
                          attribute="lastname"
                          [hit]="hit"
                        ></ais-highlight>
                      </a>

                      <span class="text-muted">
                        <a
                          href="/client/{{ hit.clientId }}/taxcase/{{
                            hit.objectID
                          }}/information"
                          class="text-dark text-hover-primary mb-1 font-size-lg"
                        >
                          <ais-highlight
                            attribute="objectID"
                            [hit]="hit"
                          ></ais-highlight>
                        </a>
                      </span>

                      <span class="text-muted">
                        <button
                          class="btn btn-xs font-weight-bold"
                          [ngClass]="{
                            'btn-warning': hit.status === 'inProgress',
                            'btn-danger': hit.status === 'reject',
                            'btn-success': hit.status === 'done'
                          }"
                        >
                          {{ hit.status }}
                        </button>
                      </span>
                    </div>
                  </div>
                </ng-template>
              </ais-hits>
            </div>
          </div>
        </ais-instantsearch>
      </div>

      <div class="col-xs-1 col-sm-2 col-md-4 col-lg-3 col-xl-3">
        <ais-instantsearch
          *ngIf="configMessages$ | async as configMessages"
          [config]="configMessages"
        >
          <ais-configure [searchParameters]="searchParameters"></ais-configure>

          <div class="card card-custom card-stretch gutter-b">
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark">
                  <div class="symbol symbol-40 symbol-light-primary mr-5">
                    <span class="symbol-label">
                      <span class="svg-icon svg-icon-primary svg-icon-2x">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                              d="M5,9 L19,9 C20.1045695,9 21,9.8954305 21,11 L21,20 C21,21.1045695 20.1045695,22 19,22 L5,22 C3.8954305,22 3,21.1045695 3,20 L3,11 C3,9.8954305 3.8954305,9 5,9 Z M18.1444251,10.8396467 L12,14.1481833 L5.85557487,10.8396467 C5.4908718,10.6432681 5.03602525,10.7797221 4.83964668,11.1444251 C4.6432681,11.5091282 4.77972206,11.9639747 5.14442513,12.1603533 L11.6444251,15.6603533 C11.8664074,15.7798822 12.1335926,15.7798822 12.3555749,15.6603533 L18.8555749,12.1603533 C19.2202779,11.9639747 19.3567319,11.5091282 19.1603533,11.1444251 C18.9639747,10.7797221 18.5091282,10.6432681 18.1444251,10.8396467 Z"
                              fill="#000000"
                            />
                            <path
                              d="M11.1288761,0.733697713 L11.1288761,2.69017121 L9.12120481,2.69017121 C8.84506244,2.69017121 8.62120481,2.91402884 8.62120481,3.19017121 L8.62120481,4.21346991 C8.62120481,4.48961229 8.84506244,4.71346991 9.12120481,4.71346991 L11.1288761,4.71346991 L11.1288761,6.66994341 C11.1288761,6.94608579 11.3527337,7.16994341 11.6288761,7.16994341 C11.7471877,7.16994341 11.8616664,7.12798964 11.951961,7.05154023 L15.4576222,4.08341738 C15.6683723,3.90498251 15.6945689,3.58948575 15.5161341,3.37873564 C15.4982803,3.35764848 15.4787093,3.33807751 15.4576222,3.32022374 L11.951961,0.352100892 C11.7412109,0.173666017 11.4257142,0.199862688 11.2472793,0.410612793 C11.1708299,0.500907473 11.1288761,0.615386087 11.1288761,0.733697713 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                              transform="translate(11.959697, 3.661508) rotate(-270.000000) translate(-11.959697, -3.661508) "
                            />
                          </g>
                        </svg>
                      </span>
                    </span>
                  </div>
                  Nachrichten
                </span>
              </h3>
              <span class="card-toolbar">
                <ais-stats>
                  <ng-template class="pull-right" let-state="state">
                    {{ state.nbHits }}
                  </ng-template>
                </ais-stats>
              </span>
            </div>

            <div class="card-body pt-8">
              <ais-hits>
                <ng-template let-hits="hits" let-results="results">
                  <div
                    class="d-flex mb-10 borderBottom"
                    *ngFor="let hit of hits; let i = index"
                  >
                    <span class="label label-outline-dark label-square mr-2">{{
                      i + 1
                    }}</span>

                    <div class="d-flex flex-column font-weight-bold">
                      <a
                        href="/client/{{ hit.objectID }}"
                        class="text-dark text-hover-primary mb-1 font-size-lg"
                      >
                        <ais-highlight
                          attribute="client_name"
                          [hit]="hit"
                        ></ais-highlight>
                      </a>

                      <span class="text-muted">
                        <a
                          href="/client/{{ hit.client_id }}"
                          class="text-hover-primary"
                        >
                          <p *ngIf="hit.type === 'email'">
                            <ais-highlight
                              attribute="subject"
                              [hit]="hit"
                            ></ais-highlight>
                          </p>
                          <p>
                            <ais-highlight
                              attribute="message"
                              [hit]="hit"
                            ></ais-highlight>
                          </p>
                        </a>
                      </span>

                      <span class="text-muted">
                        <div
                          *ngFor="let att of hit.attachments"
                          data-toggle="modal"
                          data-target="#imgePreview"
                          (click)="documentsservice.getDocument(att.id)"
                        >
                          {{ att.name }} <br />
                        </div>
                      </span>
                    </div>
                  </div>
                </ng-template>
              </ais-hits>
            </div>
          </div>
        </ais-instantsearch>
      </div>
    </div>
  </div>
</div>
