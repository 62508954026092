<div class="card card-custom gutter-b">
  <div class="d-flex card-body">
    <div class="row" *ngIf="taxcase">
      <div class="col-2">
        <div class="alert-icon">
          <span class="svg-icon svg-icon-primary svg-icon-xl">
            <!--begin::Svg Icon | path:../../../../../../../metronic/themes/metronic/theme/html/demo6/dist/assets/media/svg/icons/Tools/Compass.svg-->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <rect
                  fill="#000000"
                  opacity="0.3"
                  x="2"
                  y="2"
                  width="10"
                  height="12"
                  rx="2"
                />
                <path
                  d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z"
                  fill="#000000"
                />
              </g>
            </svg>
            <!--end::Svg Icon-->
          </span>
        </div>
      </div>

      <div class="col-6 align-items-center">
        <div
          *ngIf="taxcase.metadata.status.current === TAXCASESTATUS.INPROGRESS"
        >
          <div
            *ngIf="
              taxcase.metadata.status.substatus ===
                TAXCASESTATUS_INPROGRESS.WAITINGDATA ||
              taxcase.metadata.status.substatus ===
                TAXCASESTATUS_INPROGRESS.WAITINGFORDATARETRIEVAL
            "
          >
            <span>
              <a
                class="btn btn-light-success font-weight-bold mr-2"
                *ngIf="taxcase.metadata.isTaxOfficeTestSent"
                >Ans Finanzamt einreichen</a
              >
            </span>
            <span>
              <a class="btn btn-light-success">
                <i class="flaticon-whatsapp"></i> Vermisste Unterlagen
              </a>
            </span>
            <span>
              <a class="btn btn-light-success">
                <i class="flaticon-whatsapp"></i> Erinnerung Unterlagen
              </a>
            </span>
          </div>
          <div
            *ngIf="
              taxcase.metadata.status.substatus ===
              TAXCASESTATUS_INPROGRESS.SENTTOTAXOFFICE
            "
          >
            <a class="btn btn-light-success font-weight-bold mr-2"
              >Status : Bescheid erhalten</a
            >
          </div>
          <div
            *ngIf="
              taxcase.metadata.status.substatus ===
              TAXCASESTATUS_INPROGRESS.RETURNEDFROMTAXOFFICE
            "
          >
            <a class="btn btn-light-success font-weight-bold mr-2"
              >Status : Rückfrage vom FA</a
            >
            <a class="btn btn-light-success font-weight-bold mr-2"
              >SEPA herrunterladen</a
            >
          </div>
          <div
            *ngIf="
              taxcase.metadata.status.substatus ===
              TAXCASESTATUS_INPROGRESS.APPEALED
            "
          >
            <a class="btn btn-light-success font-weight-bold mr-2"
              >Status : Rückfrage vom FA</a
            >
            <a class="btn btn-light-success font-weight-bold mr-2"
              >SEPA herrunterladen</a
            >
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
