import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  StartEmailVerificationInput,
  StartEmailVerificationOutput,
} from '@expresssteuer/client-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class StartEmailVerification {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: StartEmailVerificationInput) {
    console.log('Calling client-startEmailVerification');
    return this.afFunctions.httpsCallable<
      StartEmailVerificationInput,
      StartEmailVerificationOutput
    >('client-startEmailVerification')(input);
  }
}
