import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'esui-taxcase-optimization',
  templateUrl: './esui-taxcase-optimization.component.html',
  styleUrls: ['./esui-taxcase-optimization.component.scss'],
})
export class EsuiTaxcaseOptimizationComponent implements OnInit {
  @Output()
  submitTaskClick = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
