import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Client } from '@expresssteuer/models';
import { Observable } from 'rxjs';

declare let $: any;

@Component({
  selector: 'app-clientlist',
  templateUrl: './clientlist.component.html',
  styleUrls: ['./clientlist.component.scss'],
})
export class ClientlistComponent implements OnInit, OnDestroy {
  clients$: Observable<Client[]>;

  constructor(private db: AngularFirestore) {}

  ngOnDestroy(): void {
    this.clients$ = undefined;
  }

  ngOnInit(): void {
    this.clients$ = this.db
      .collection<Client>('clients', (r) =>
        r.orderBy('created', 'desc').limit(200)
      )
      .valueChanges({ idField: 'id' });
  }
}
