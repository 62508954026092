import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardKeyDirective } from './card-key.directive';

@NgModule({
  declarations: [CardKeyDirective],
  imports: [CommonModule],
  exports: [CardKeyDirective],
})
export class EsCardKeyModule {}
