import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WithId } from '@expresssteuer/models';
import { DataTaxnotice } from '@expresssteuer/taxnotices-models';

@Component({
  selector: 'esui-data-taxnotice-list',
  templateUrl: './esui-data-taxnotice-list.component.html',
  styleUrls: ['./esui-data-taxnotice-list.component.scss'],
})
export class EsuiDataTaxnoticeListComponent {
  displayedColumns: string[] = [
    'Year',
    'Refund Amount',
    'Transfer Amount',
    'Payout Info',
    'Wrong Payout?',
    'Detail',
    'Recalculate',
  ];
  @Input() taxnoticeList?: WithId<DataTaxnotice>[] | null;
  @Output() taxnoticeDetailSelected = new EventEmitter<string>();
  @Output() recalculateSelected = new EventEmitter<string>();
}
