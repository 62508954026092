import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsStoreStateIndicatorModule } from '../es-store-state-indicator/es-store-state-indicator.module';
import { EsuiSkeletonPlaceholderModule } from '../esui-skeleton-placeholder/esui-skeleton-placeholder.module';
import { EsuiJobgroupAssignComponent } from './esui-jobgroup-assign.component';
import { GoogleMapsLinkPipe } from './google-link.pipe';

@NgModule({
  declarations: [EsuiJobgroupAssignComponent, GoogleMapsLinkPipe],
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatButtonModule,
    MatRadioModule,
    MatGridListModule,
    EsCardKeyModule,
    EsuiSkeletonPlaceholderModule,
    EsStoreStateIndicatorModule,
  ],
  exports: [EsuiJobgroupAssignComponent],
})
export class EsuiJobgroupAssignModule {}
