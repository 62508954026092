import { Timestamp } from '../helpers/timestamp';
import { ExpsAddress } from '../taxform/taxform';
import { Disability } from './disablement';
/**
 * Describes default params for a person
 */

export enum Gender {
  male = 'M',
  female = 'F',
  other = 'O',
  unspecified = 'NOT SPECIFIED',
}
export interface IPerson {
  gender?: Gender;
  firstname: string;
  lastname: string;
  birthdate: Timestamp | null;
}

export class Person {
  firstname: string = '';
  lastname: string = '';
  birthdate: Timestamp | null = null;

  livingAddress?: ExpsAddress;
  disability?: Disability;

  public static getAge(birthdate: Timestamp | null): number | null {
    console.log(birthdate);
    return null;
  }
}
