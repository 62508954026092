import { Component, Input } from '@angular/core';

@Component({
  selector: 'esui-section-column-scaffold',
  templateUrl: './esui-section-column-scaffold.component.html',
  styleUrls: ['./esui-section-column-scaffold.component.scss'],
})
export class EsuiSectionColumnScaffoldComponent {
  @Input() header?: {
    icon?: string | null;
    title?: string | null;
    tooltip?: string | null;
  } | null;
}
