<div *ngIf="status">
  <span
    class="label label-sm label-light-primary label-inline font-weight-lighter mr-2"
    >{{ status.current }}</span
  >
  <br />
  <span
    class="label label-sm label-inline font-weight-lighter mr-2"
    [ngClass]="{
      'label-light-danger': status.current === TAXCASESTATUS.REJECT
    }"
    *ngIf="
      status.substatus && status.substatus !== null && status.substatus !== ''
    "
    >{{ status.substatus }}</span
  >
</div>
