import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticsData } from '../../../../app/services/data/staticdata.service';

@Component({
  selector: 'app-control-staticdata-dropdown',
  templateUrl: './control-staticdata-dropdown.component.html',
  styleUrls: ['./control-staticdata-dropdown.component.scss'],
})
export class ControlStaticDataDropdownComponent implements OnInit {
  constructor() {}

  @Input()
  public data: Observable<StaticsData>;
  private aSelected: string;

  @Output()
  changeSelected = new EventEmitter<string>();

  @Input()
  get selected(): string {
    return this.aSelected;
  }

  set selected(val: string) {
    this.aSelected = val;
    this.changeSelected.emit(this.aSelected);
  }

  ngOnInit(): void {}
}
