import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomerApprovalComponent } from './customer-approval.component';

@NgModule({
  declarations: [CustomerApprovalComponent],
  exports: [CustomerApprovalComponent],
  imports: [CommonModule],
})
export class CustomerApprovalModule {}
