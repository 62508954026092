import { SchemaProperty } from './vast';

export const VastLerslSchema: SchemaProperty = {
  title: 'VaSt_LErsL-202201.xsd',
  $schema: 'http://json-schema.org/schema#',

  type: 'object',
  properties: {
    VaSt_LErsL: {
      type: 'object',
      description: 'Lohnersatzleistungen',
      properties: {
        Zuflussjahr: {
          description: 'Jahr der Zahlung/Erstattung',
          type: 'string',
        },
        Eingangsdatum: {
          description:
            'Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung',
          type: 'string',
        },
        LeistungsEmpfaenger: {
          type: 'object',
          properties: {
            IdNr: {
              description: 'Identifikationsnummer',
              type: 'string',
            },
          },
        },
        Leistung: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              Betrag: {
                description: 'Leistungsbetrag',
                type: 'number',
                stringifier: 'currency-eur',
              },
              Art: {
                description: 'Art der Leistung',
                type: 'string',
              },
              LeistungsPflichtiger: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    Name: {
                      description: 'Leistungserbringer',
                      type: 'string',
                    },
                    Zeitraum: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          Beginn: {
                            description: 'Beginn',
                            type: 'string',
                          },
                          Ende: {
                            description: 'Ende',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        version: {
          type: 'string',
          enum: ['202201'],
        },
      },
      required: ['version'],
    },
  },
  required: ['VaSt_LErsL'],
};
