import { Timestamp } from '../helpers/timestamp';

export abstract class ClientContractSignRequest {
  public static getTemplate(
    input: Partial<ClientContractSignRequest> & {
      signature: string;
      clientId: string;
      partner: boolean;
      partnerId?: string;
      createdAt: Timestamp;
      contractName: string;
      contractVersion: string;
    }
  ): ClientContractSignRequest {
    return {
      signature: input.signature,
      clientId: input.clientId,
      partner: input.partner,
      partnerId: input.partnerId,
      createdAt: input.createdAt,
      contractName: input.contractName,
      contractVersion: input.contractVersion,
      requestCompleted: false,
    };
  }
  abstract createdAt: Timestamp;
  abstract signature: string;
  abstract clientId: string;
  abstract partner?: boolean; // should be set, but is not for old requests
  abstract partnerId?: string;
  abstract contractName: string;
  abstract contractVersion: string;
  abstract pathToContract?: string;
  abstract requestCompleted?: boolean; // should be set, but is not for old requests
  static collectionName = 'client_sign_request';
}
