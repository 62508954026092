import { Checklist } from './checklist';
import { ChecklistItem } from './checklistItem';

export class ClientChecklist extends Checklist {
  public static override getTemplate(): ClientChecklist {
    return {
      identitycard: ChecklistItem.getTemplate(),
      signature: ChecklistItem.getTemplate(),
      birthdate: ChecklistItem.getTemplate(),
      financeDepartment: ChecklistItem.getTemplate(),
      eTin: ChecklistItem.getTemplate(),
      taxId: ChecklistItem.getTemplate(),
      iban: ChecklistItem.getTemplate(),
      paymentmandate: ChecklistItem.getTemplate(),
      created: null,
      complete: false,
      completecount: 0,
      itemcount: 1,
      modified: null,
      progress: 0,
    };
  }

  /**
   * The identitycard from the client
   */
  identitycard: ChecklistItem = ChecklistItem.getTemplate();
  /**
   * The signature from the client
   */
  signature: ChecklistItem = ChecklistItem.getTemplate();
  /**
   * If this property is not set, we do not need to check
   */
  disabled?: ChecklistItem = ChecklistItem.getTemplate();
  birthdate: ChecklistItem = ChecklistItem.getTemplate();

  /**
   * Tax settings
   */
  financeDepartment: ChecklistItem = ChecklistItem.getTemplate();
  eTin: ChecklistItem = ChecklistItem.getTemplate();
  taxId: ChecklistItem = ChecklistItem.getTemplate();

  /**
   * The IBAN for paying out
   */
  iban: ChecklistItem = ChecklistItem.getTemplate();
  /**
   * A valid mandate to collect money from.
   * Can be Stripe Lastschrift or a credit card.
   * This is only mandatory if the client has a product where he has to pay in advance
   */
  paymentmandate: ChecklistItem = ChecklistItem.getTemplate();

  /**
   * PARTNER FIELDS
   */

  /**
   * If the client is married we need the identitycard from the partner soo
   */
  indetitycardPartner?: ChecklistItem = ChecklistItem.getTemplate();
  /**
   * If the partner is disabled we need the disablement too
   */
  disabledPartner?: ChecklistItem = ChecklistItem.getTemplate();
  /**
   * If married, we need a signature
   */
  signaturePartner?: ChecklistItem = ChecklistItem.getTemplate();

  eTinPartner?: ChecklistItem = ChecklistItem.getTemplate();
  taxIdPArtner?: ChecklistItem = ChecklistItem.getTemplate();
}
