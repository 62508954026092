import {
  Component,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import {
  BaseWidget,
  NgAisIndex,
  NgAisInstantSearch,
} from 'angular-instantsearch';
import { connectRefinementList } from 'instantsearch.js/es/connectors';

@Component({
  selector: 'app-ais-documents-refinement-list',
  templateUrl: './ais-documents-refinement-list.component.html',
  styleUrls: ['./ais-documents-refinement-list.component.scss'],
})
export class AisDocumentsRefinementListComponent
  extends BaseWidget
  implements OnInit
{
  @Input()
  attribute: string;

  @Input()
  transformItems?: <U extends any>(items: any[]) => U[];

  @Input()
  searchable?: boolean;

  @Input()
  limit?: number;

  @Input()
  searchPlaceholder = 'Search...';

  searchValue = '';
  searchKeyup(value) {
    this.searchValue = value;
    this.triggerSearch();
  }

  searchClear() {
    this.searchValue = '';
    this.triggerSearch();
  }

  triggerSearch() {
    this.state.searchForItems(this.searchValue);
  }

  /**
   * Combine items for each match and display them
   * as one with a label.
   * @example
   * ```
   * merge = [{matcher: '^image\/', label: 'image'}]
   * merges
   * ['image/jpeg', 'image/png', 'application/pdf']
   * to
   * ['image', 'application/pdf']
   * ```
   */
  @Input()
  merge?: { matcher; label }[];

  /**
   * Items sorted into groups based on `this.merge`.
   */
  get groupedItems(): Map<string, any[]> {
    return (
      this.state.items?.reduce((acc, curr) => {
        const label =
          this.merge?.find((f) => {
            return curr.label.match(f.matcher);
          })?.label || curr.label;
        const set = acc.get(label) || [];
        acc.set(label, [...set, curr]);
        return acc;
      }, new Map<string, any[]>()) || new Map()
    );
  }

  /**
   * Items merged based on `this.groupedItems`,
   * limited based on `this.limit`,
   * and filtered based on `this.searchValue`.
   */
  get items(): any[] {
    const merged = [...this.groupedItems].map(([label, items]) => ({
      label,
      value: label,
      count: items.reduce((acc, curr) => acc + curr.count, 0),
      isRefined: items.some((e) => e.isRefined),
    }));
    return merged;
  }

  /**
   * Refine all items of a group that has the `value`.
   */
  refine(value: string) {
    const items = this.groupedItems.get(value);
    items.forEach((item) => {
      this.state.refine(item.value);
    });
  }

  public state: {
    items: any[];
    refine: (value: string) => void;
    createURL: Function;
    isFromSearch: boolean;
    searchForItems: Function;
    isShowingMore: boolean;
    canToggleShowMore: boolean;
    toggleShowMore: Function;
    widgetParams: object;
  };
  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('AisDocumentsRefinementListComponent');
  }
  ngOnInit() {
    this.createWidget(connectRefinementList, {
      // instance options
      attribute: this.attribute,
      limit: this.limit,
    });
    super.ngOnInit();
  }
}
