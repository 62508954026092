//How content tags should be built:
// 1:{PROVIDES || REQUIRE}
// 2:{TYPE: BILL || LIST || BANKSTATEMENT || IBAN || CONFIRMATION || REASON || QUESTIONNAIRE || USAGEINDICATOR || POWEROFATTORNEY}
// 3:{EXACTINFORMATION: MOVINGEXPENSES || ... }
// {1}_{2}

export enum DataRequired {
  RequireListMovingexpenses = 'REQUIRE_LIST_MOVINGEXPENSES',
  RequireBillCarinspection = 'REQUIRE_BILL_CARINSPECTION',
  RequireConfirmationEmployeradress = 'REQUIRE_CONFIRMATION_EMPLOYERADRESS',
  RequireConfirmationDaysworked = 'REQUIRE_CONFIRMATION_DAYSWORKED',
  RequireListDaystravelledtowork = 'REQUIRE_LIST_DAYSTRAVELLEDTOWORK',
  RequireBankstatementPatientcare = 'REQUIRE_BANKSTATEMENT_PATIENTCARE',
  RequireBankstatementHouseservices = 'REQUIRE_BANKSTATEMENT_HOUSESERVICES',
  RequireBillHandymanservices = 'REQUIRE_BILL_HANDYMANSERVICES',
  RequireBillEverything = 'REQUIRE_BILL_EVERYTHING',
  RequireBillDonations = 'REQUIRE_BILL_DONATIONS',
  RequireBankstatementDonations = 'REQUIRE_BANKSTATEMENT_DONATIONS',
  RequireUsageindicationWorksupplies = 'REQUIRE_USAGEINDICATION_WORKSUPPLIES',
  RequireBillWorksupplies = 'REQUIRE_BILL_WORKSUPPLIES',
  RequireBillAdvertisingcost = 'REQUIRE_BILL_ADVERTISINGCOST',
  RequireBillTelephonecost = 'REQUIRE_BILL_TELEPHONECOST',
  RequireUsageindicationTelephonecost = 'REQUIRE_USAGEINDICATION_TELEPHONECOST',
  RequireBillInternetcost = 'REQUIRE_BILL_INTERNETCOST',
  RequireUsageindicationInternetcost = 'REQUIRE_USAGEINDICATION_INTERNETCOST',
  RequireBillTravelcost = 'REQUIRE_BILL_TRAVELCOST',
  RequireReasonTravelcost = 'REQUIRE_REASON_TRAVELCOST',
  RequireReasonBusinesstrip = 'REQUIRE_REASON_BUSINESSTRIP',
  RequireConfirmationMealallowance = 'REQUIRE_CONFIRMATION_MEALALLOWANCE',
  RequireQuestionnaireStudyroom = 'REQUIRE_QUESTIONNAIRE_STUDYROOM',
  RequireIdentityHandicappedidChild = 'REQUIRE_IDENTITY_HANDICAPPEDID_CHILD',
  RequireIdentityBirthcertificateChild = 'REQUIRE_IDENTITY_BIRTHCERTIFICATE_CHILD',
  RequireBillSupervisioncostChild = 'REQUIRE_BILL_SUPERVISIONCOST_CHILD',
  RequireBillSchoolcostChild = 'REQUIRE_BILL_SCHOOLCOST_CHILD',
  RequireTaxdataTaxidChild = 'REQUIRE_TAXDATA_TAXID_CHILD',
  RequireOtherSpecialcase = 'REQUIRE_OTHER_SPECIALCASE',
  RequireTaxstatementPrevyear = 'REQUIRE_TAXSTATEMENT_PREVYEAR',
  RequirePowerofattorney = 'REQUIRE_POWEROFATTORNEY',
  RequireIbanEstpayout = 'REQUIRE_IBAN_ESTPAYOUT',
  RequireIdentityHandicappedClient = 'REQUIRE_IDENTITY_HANDICAPPED_CLIENT',
  RequireIdentityHandicappedPartner = 'REQUIRE_IDENTITY_HANDICAPPED_PARTNER',
  RequireIdentityCardClient = 'REQUIRE_IDENTITY_CARD_CLIENT',
  RequireIdentityCardPartner = 'REQUIRE_IDENTITY_CARD_PARTNER',
  RequireTaxdataTaxidClient = 'REQUIRE_TAXDATA_TAXID_CLIENT',
  RequireTaxdataTaxidPartner = 'REQUIRE_TAXDATA_TAXID_PARTNER',
  RequireIdentityDocumentClient = 'REQUIRE_IDENTITY_DOCUMENT_CLIENT',
  RequireForeignincome = 'REQUIRE_FOREIGNINCOME',
  RequireQuestionnaireTradeincome = 'REQUIRE_QUESTIONNAIRE_TRADEINCOME',
  RequireForeignassets = 'REQUIRE_FOREIGNASSETS',
  RequireRentalincome = 'REQUIRE_RENTALINCOME',
  RequireSelfemployment = 'REQUIRE_SELFEMPLOYMENT',
  RequireStockinvestments = 'REQUIRE_STOCKINVESTMENTS',
  RequireRetirement = 'REQUIRE_RETIREMENT',
  RequireOther = 'REQUIRE_OTHER',
  RequireIbanCustomer = 'REQUIRE_IBAN_CUSTOMER',
  RequireEwr = 'REQUIRE_EWR',
}

export const KillRequireTags: DataRequired[] = [
  DataRequired.RequireForeignincome,
  DataRequired.RequireQuestionnaireTradeincome,
  DataRequired.RequireForeignassets,
  DataRequired.RequireRentalincome,
  DataRequired.RequireSelfemployment,
  DataRequired.RequireStockinvestments,
  DataRequired.RequireRetirement,
  DataRequired.RequireOther,
];

export enum ProviderDataTags {
  ProvidesTaxstatementrecall = 'PROVIDES_TAXSTATEMENTRECALL',
  ProvidesChurchtax = 'PROVIDES_CHURCHTAX',
  ProvidesTaxaccountstatement = 'PROVIDES_TAXACCOUNTSTATEMENT',
  ProvidesPayout = 'PROVIDES_PAYOUT',
  ProvidesTaxcredit = 'PROVIDES_TAXCREDIT',
  ProvidesSummation = 'PROVIDES_SUMMATION',
  ProvidesTaxcredittransfer = 'PROVIDES_TAXCREDITTRANSFER',
  ProvidesTaxofficechange = 'PROVIDES_TAXOFFICECHANGE',
  ProvidesNewtaxid = 'PROVIDES_NEWTAXID',
  ProvidesOverduenotice = 'PROVIDES_OVERDUENOTICE',
  ProvidesEnforcementnotice = 'PROVIDES_ENFORCEMENTNOTICE',
  ProvidesTaxesalreadyclaimed = 'PROVIDES_TAXESALREADYCLAIMED',
  ProvidesTaxdataTaxidPartner = 'PROVIDES_TAXDATA_TAXID_PARTNER',
  ProvidesIdentityDocumentPartner = 'PROVIDES_IDENTITY_DOCUMENT_PARTNER',
  ProvidesIdentityCardClient = 'PROVIDES_IDENTITY_CARD_CLIENT',
  ProvidesIdentityCardPartner = 'PROVIDES_IDENTITY_CARD_PARTNER',
  ProvidesForwardtoappealsoffice = 'PROVIDES_FORWARDTOAPPEALSOFFICE',
  ProvidesDeferralaccepted = 'PROVIDES_DEFERRALACCEPTED',
  ProvidesDeferralrefused = 'PROVIDES_DEFERRALREFUSED',
  ProvidesResponsedelayed = 'PROVIDES_RESPONSEDELAYED',
  ProvidesAppealdeviation = 'PROVIDES_APPEALDEVIATION',
  ProvidesListMovingexpenses = 'PROVIDES_LIST_MOVINGEXPENSES',
  ProvidesBillCarinspection = 'PROVIDES_BILL_CARINSPECTION',
  ProvidesConfirmationEmployeradress = 'PROVIDES_CONFIRMATION_EMPLOYERADRESS',
  ProvidesConfirmationDaysworked = 'PROVIDES_CONFIRMATION_DAYSWORKED',
  ProvidesListDaystravelledtowork = 'PROVIDES_LIST_DAYSTRAVELLEDTOWORK',
  ProvidesBankstatementPatientcare = 'PROVIDES_BANKSTATEMENT_PATIENTCARE',
  ProvidesBankstatementHouseservices = 'PROVIDES_BANKSTATEMENT_HOUSESERVICES',
  ProvidesBillHandymanservices = 'PROVIDES_BILL_HANDYMANSERVICES',
  ProvidesBillDonations = 'PROVIDES_BILL_DONATIONS',
  ProvidesBankstatementDonations = 'PROVIDES_BANKSTATEMENT_DONATIONS',
  ProvidesUsageindicationWorksupplies = 'PROVIDES_USAGEINDICATION_WORKSUPPLIES',
  ProvidesBillWorksupplies = 'PROVIDES_BILL_WORKSUPPLIES',
  ProvidesBillAdvertisingcost = 'PROVIDES_BILL_ADVERTISINGCOST',
  ProvidesBillTelephonecost = 'PROVIDES_BILL_TELEPHONECOST',
  ProvidesUsageindicationTelephonecost = 'PROVIDES_USAGEINDICATION_TELEPHONECOST',
  ProvidesBillInternetcost = 'PROVIDES_BILL_INTERNETCOST',
  ProvidesUsageindicationInternetcost = 'PROVIDES_USAGEINDICATION_INTERNETCOST',
  ProvidesBillTravelcost = 'PROVIDES_BILL_TRAVELCOST',
  ProvidesReasonTravelcost = 'PROVIDES_REASON_TRAVELCOST',
  ProvidesReasonBusinesstrip = 'PROVIDES_REASON_BUSINESSTRIP',
  ProvidesConfirmationMealallowance = 'PROVIDES_CONFIRMATION_MEALALLOWANCE',
  ProvidesQuestionnaireStudyroom = 'PROVIDES_QUESTIONNAIRE_STUDYROOM',
  ProvidesIdentityHandicappedidChild = 'PROVIDES_IDENTITY_HANDICAPPEDID_CHILD',
  ProvidesIdentityBirthcertificateChild = 'PROVIDES_IDENTITY_BIRTHCERTIFICATE_CHILD',
  ProvidesBillSupervisioncost = 'PROVIDES_BILL_SUPERVISIONCOST',
  ProvidesBillSchoolcost = 'PROVIDES_BILL_SCHOOLCOST',
  ProvidesTaxdataTaxidChild = 'PROVIDES_TAXDATA_TAXID_CHILD',
  ProvidesOther = 'PROVIDES_OTHER',
  ProvidesTaxstatementPrevyear = 'PROVIDES_TAXSTATEMENT_PREVYEAR',
  ProvidesPowerofattorney = 'PROVIDES_POWEROFATTORNEY',
  ProvidesIbanEstpayout = 'PROVIDES_IBAN_ESTPAYOUT',
  ProvidesIdentityHandicappedidClient = 'PROVIDES_IDENTITY_HANDICAPPEDID_CLIENT',
  ProvidesHelptaxofficeletter = 'PROVIDES_HELPTAXOFFICELETTER',
  ProvidesIdentityDocumentClient = 'PROVIDES_IDENTITY_DOCUMENT_CLIENT',
  ProvidesTaxdataTaxidClient = 'PROVIDES_IDENTITY_DOCUMENT_PARTNER',
  ProvidesIbanCustomer = 'PROVIDES_IBAN_CUSTOMER',
}

/**
 * @description CONTENT TAGS included in one finance office letter
 * used to classify passages of text and
 */
export type ContentTags = DataRequired;

export type ProviderContentTags = ProviderDataTags;

export const CONTENT_TAGS_STRUCTURED = [
  {
    category: 'DATA_REQUIRED',
    values: Object.values(DataRequired),
  },
  {
    category: 'PROVIDER_DATA',
    values: Object.values(ProviderDataTags),
  },
];
