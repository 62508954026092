import { Component, Input, OnInit } from '@angular/core';
import { Client, ClientShort } from '@expresssteuer/models';

@Component({
  selector: 'app-control-client-name',
  templateUrl: './control-client-name.component.html',
  styleUrls: ['./control-client-name.component.scss'],
})
export class ControlClientNameComponent implements OnInit {
  private aClient: Client | ClientShort | null;

  constructor() {}

  @Input()
  public set client(val: Client | ClientShort | null) {
    this.aClient = val;
  }

  public get client(): Client | ClientShort | null {
    return this.aClient;
  }

  ngOnInit(): void {}
}
