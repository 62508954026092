<ng-container *ngIf="task">
  <div class="icon">
    <mat-icon [matTooltip]="task.title || typeDescription || ''">{{
      icon
    }}</mat-icon>
  </div>
  <div class="chipList">
    <div
      class="chipItem"
      *ngFor="let user of users ?? []; trackBy: trackByUser"
      disableRipple="true"
    >
      <img
        *ngIf="user.photoUrl; else noImage"
        class="chipImg"
        [src]="user?.photoUrl"
        [alt]="user?.displayName ?? '-'"
        [matTooltip]="user?.displayName ?? '-'"
      />
      <!-- 
        TODO in the future lets maybe think about using the below for faulty photoUrls?
        (error)="" 
      -->
      <ng-template #noImage>
        <mat-icon
          aria-hidden="false"
          aria-label="Default user image"
          [matTooltip]="user?.displayName ?? '-'"
          >account_circle_full</mat-icon
        >
      </ng-template>
    </div>
  </div>
</ng-container>
