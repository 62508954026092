import {
  PlausibilityCheckReport,
  PlausibilityCheckReportStatus,
} from '@expresssteuer/plausibility-interface';
import { DisplayItem } from '../../esui-generic-list/esui-generic-list.component';

export function convertPlausibilityCheckReportToDisplayItems(
  results: PlausibilityCheckReport[]
): DisplayItem[] {
  return results.map((result) => {
    return new DisplayItem(
      '',
      StatusOutput[result.status].icon + ' ' + result.message,
      result.details || '',
      'string'
    );
  });
}

const StatusOutput: Record<
  PlausibilityCheckReportStatus,
  { icon: string; color: string }
> = {
  success: {
    icon: '✅',
    color: 'green',
  },
  warning: {
    icon: '⚠️',
    color: 'orange',
  },
  error: {
    icon: '❌',
    color: 'red',
  },
  failure: {
    icon: '💥',
    color: 'red',
  },
};
