import { NgModule } from '@angular/core';
import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { ClientsNewComponent } from './views/pages/clients/clients-new/clients-new.component';
import { ClientsComponent } from './views/pages/clients/clients.component';
import { MessagingV2Component } from './views/pages/messaging-v2/messaging-v2.component';
import { NotFoundComponent } from './views/pages/not-found/not-found.component';
import { SearchComponent } from './views/pages/search/search.component';
import { TaxcasesArchivedComponent } from './views/pages/taxcases/taxcases-archived/taxcases-archived.component';
import { TaxcasesDoneComponent } from './views/pages/taxcases/taxcases-done/taxcases-done.component';
import { TaxcasesEntryComponent } from './views/pages/taxcases/taxcases-entry/taxcases-entry.component';
import { TaxcasesInProgressComponent } from './views/pages/taxcases/taxcases-in-progress/taxcases-in-progress.component';
import { TaxcasesMyCasesComponent } from './views/pages/taxcases/taxcases-my-cases/taxcases-my-cases.component';
import { TaxcasesRejectedComponent } from './views/pages/taxcases/taxcases-rejected/taxcases-rejected.component';

// Pages

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/login']);
const redirectLoggedInToTaxCases = () =>
  redirectLoggedInTo(['/taxcases/entry']);

export const documentsV2Path = 'documents';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/taxcases/entry',
  },
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'taxcases',
    pathMatch: 'full',
    redirectTo: '/taxcases/entry',
  },
  {
    path: 'taxcases/entry',
    component: TaxcasesEntryComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'taxcases/my',
    component: TaxcasesMyCasesComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'taxcases/rejected',
    component: TaxcasesRejectedComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'taxcases/progress',
    component: TaxcasesInProgressComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'taxcases/deleted',
    component: TaxcasesArchivedComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'taxcases/done',
    component: TaxcasesDoneComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'clients',
    component: ClientsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'clients/new',
    component: ClientsNewComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'messaging',
    redirectTo: 'messaging/inbox',
    pathMatch: 'full',
  },
  {
    path: 'messaging/inbox',
    component: MessagingV2Component,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'messaging/inbox/:msgId',
    component: MessagingV2Component,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  // {
  //   path: 'messaging/:directory',
  //   component: MessagingComponent,
  //   canActivate: [AngularFireAuthGuard],
  //   data: { authGuardPipe: redirectUnauthorizedToLogin },
  // },
  {
    path: 'client/:clientId/taxcase/:taxcaseId',
    loadChildren: () =>
      import('./views/pages/taxcase-v2/taxcase.module').then(
        (mod) => mod.TaxcaseV2Module
      ),
  },
  {
    path: 'client/:clientId',
    loadChildren: () =>
      import('./views/pages/client/client.module').then(
        (mod) => mod.ClientModule
      ),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
  },

  // { path: 'login',canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToTaxCases },  loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: documentsV2Path,
    loadChildren: () =>
      import('./modules/ais-documents/ais-documents.module').then(
        (mod) => mod.AisDocumentsModule
      ),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./views/pages/reports/reports.module').then(
        (m) => m.ReportsModule
      ),
  },
  // { path: '404', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
