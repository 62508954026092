import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlgoliaDocument } from '@expresssteuer/models';
import { SearchParameters } from 'angular-instantsearch/instantsearch/instantsearch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ClientsService } from '../../../services/data/clients.service';
import { DocumentsService } from '../../../services/data/documents.service';
import { AisDocumentsConfigService } from '../services/ais-documents-config.service';
import { AisDocumentsHelperService } from '../services/ais-documents-helper.service';

enum ViewOption {
  icons = 'icons',
  list = 'list',
  table = 'table',
}

@Component({
  selector: 'app-ais-documents-view',
  templateUrl: './ais-documents-view.component.html',
  styleUrls: ['./ais-documents-view.component.scss'],
})
export class AisDocumentsViewComponent implements OnInit {
  public isEmulator = environment.emulator;
  AisDocumentsHelperService = AisDocumentsHelperService;
  ViewOption = ViewOption;
  viewOptions = Object.keys(ViewOption);

  private viewModeParamKey = 'viewMode';
  private advanceModeParamKey = 'advanceMode';

  @Input() selectMode = false;
  @Input() selections: AlgoliaDocument[] = [];
  @Output() selectionsChange = new EventEmitter<AlgoliaDocument[]>();

  @Input() previewOnly = false;

  @Input()
  public showFacetFilters = true;

  @Input()
  public set clientId(id: string) {
    this._clientId = id;
    this.updateSearchParameters();
  }
  public get clientId() {
    return this._clientId;
  }
  private _clientId?: string;

  @Input()
  public set taxcaseId(id: string) {
    this._taxcaseId = id;
    this.updateSearchParameters();
  }
  public get taxcaseId() {
    return this._taxcaseId;
  }
  private _taxcaseId?: string;

  public pageHitsPerPageOptions = [
    { label: '10', value: 10, default: true },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
  ];

  searchParameters: SearchParameters = {};

  set activeViewOption(value: ViewOption) {
    this.router.navigate([], {
      queryParams: {
        [this.viewModeParamKey]: value,
      },
      queryParamsHandling: 'merge',
    });
  }
  activeViewOption$: Observable<ViewOption> = this.route.queryParamMap.pipe(
    map((e) => {
      const value = e.get(this.viewModeParamKey);
      return (Object as any).values(ViewOption).includes(value) // assure its a ViewOption
        ? (value as ViewOption)
        : ViewOption.table;
    })
  );

  set advanceViewOption(value: boolean) {
    this.router.navigate([], {
      queryParams: {
        [this.advanceModeParamKey]: value,
      },
      queryParamsHandling: 'merge',
    });
  }
  advanceViewOption$: Observable<boolean> = this.route.queryParamMap.pipe(
    map((e) => {
      const value = e.get(this.advanceModeParamKey);
      return value === 'true';
    })
  );

  transformUserItems(items: any[]) {
    return items.map((e) => 'test');
  }

  updateSearchParameters() {
    const facetFilters = [];
    if (this.clientId) {
      facetFilters.push([`metadata.clientId:${this.clientId}`]);
    }
    if (this.taxcaseId) {
      facetFilters.push([`metadata.taxcaseid:${this.taxcaseId}`]);
    }

    this.searchParameters = {
      facetFilters,
    };
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public aisDocumentsConfigService: AisDocumentsConfigService,
    private clientService: ClientsService,
    public documentsService: DocumentsService
  ) {}
  ngOnInit() {
    if (this.isEmulator) {
      this.documentsService.loadForClient(this.clientService.currentClient.id);
    }
  }
}
