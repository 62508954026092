import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'esui-year-selection',
  templateUrl: './esui-year-selection.component.html',
  styleUrls: ['./esui-year-selection.component.scss'],
})
export class EsuiYearSelectionComponent {
  fromYear?: number;
  toYear?: number;

  @Input()
  currentFormError?: string | null = null;

  @Input()
  selectedYears?: Set<string>;

  @Output()
  deleteYear = new EventEmitter<string>();

  @Output()
  deleteAllYears = new EventEmitter<void>();

  @Output()
  selectedYearsChange = new EventEmitter<Set<string>>();

  onRangeAdded(startYear?: number, endYear?: number) {
    this.currentFormError = null;
    if (!startYear) {
      return;
    }
    const yearTo = endYear ?? startYear;
    if (
      startYear > 2100 ||
      startYear < 2018 ||
      yearTo > 2100 ||
      yearTo < 2018
    ) {
      this.currentFormError = 'Invalid year range';
      return;
    }
    const years = new Set<string>();
    for (let i = startYear; i <= yearTo; i++) {
      years.add(i.toString());
    }
    this.toYear = undefined;
    this.fromYear = undefined;
    this.selectedYearsChange.emit(years);
  }

  clearYears() {
    this.deleteAllYears.emit();
  }
}
