import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OnlineStatusComponent } from './online-status.component';

@NgModule({
  declarations: [OnlineStatusComponent],
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  exports: [OnlineStatusComponent],
})
export class EsOnlineStatusModule {}

export * from './browser-online-status.service';
export * from './firebase-online-status.service';
export * from './online-status.service';
