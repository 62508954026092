import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { EsTaxcaseShortModule } from '../es-taxcase-short/es-taxcase-short.module';
import { EsuiTaxCalculationModule } from '../esui-final-taxcase-review-v2/components/tax-calculation.module';
import { EsuiGenericListModule } from '../esui-generic-list/esui-generic-list.module';
import { PureMethodModule } from '../pipes/pure-method/pure-method.module';
import { EsuiTaxcaseDetailComponent } from './esui-taxcase-detail.component';

@NgModule({
  declarations: [EsuiTaxcaseDetailComponent],
  exports: [EsuiTaxcaseDetailComponent],
  imports: [
    CommonModule,
    EsuiTaxCalculationModule,
    EsuiGenericListModule,
    EsTaxcaseShortModule,
    PureMethodModule,
    MatTabsModule,
  ],
})
export class EsuiTaxcaseDetailModule {}
