import { Component, Input } from '@angular/core';
import { TaskHistoryEntry } from '@expresssteuer/models';

@Component({
  selector: 'esui-task-history-item',
  templateUrl: './task-history-item.component.html',
  styleUrls: ['./task-history-item.component.scss'],
})
export class TaskHistoryItemComponent {
  @Input() entry?: TaskHistoryEntry;

  typeOf(value: unknown, type: string) {
    return typeof value === type;
  }
}
