<div class="btn-group btn-group-lg" role="group">
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="maritalstatus === MARITALSTATUS.single"
    (click)="maritalstatus = MARITALSTATUS.single"
  >
    Ledig
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="maritalstatus === MARITALSTATUS.married"
    (click)="maritalstatus = MARITALSTATUS.married"
  >
    Verheiratet
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="maritalstatus === MARITALSTATUS.divorced"
    (click)="maritalstatus = MARITALSTATUS.divorced"
  >
    Geschieden
  </button>
</div>
