import { DOCUMENTSOURCE } from '../document/document';

export interface RevisionSafeStoreOptions {
  fileName: string;
  fileMetaData: any;
  fileExtension: string;
  folderName?: string;
  contentType: string;
  source: DOCUMENTSOURCE;
}
