import { DeepPartial } from '@expresssteuer/deep-object-helper';
import { Elster2021E10 } from './Elster2021E10';
import { ElsterDocument } from './elsterBase';

function getElsterDocumentNutzerDaten2021Template(
  e10Model: DeepPartial<Elster2021E10>,
  year: number | string
) {
  return {
    E10: {
      ...e10Model,
      '@': {
        xmlns: `http://finkonsens.de/elster/elstererklaerung/est/e10/v${year}`,
        version: `${year}`,
      },
    },
  };
}

export abstract class NutzerDaten2021 {
  public static getTemplate: (
    ...args: Parameters<typeof getElsterDocumentNutzerDaten2021Template>
  ) => NutzerDaten2021 = getElsterDocumentNutzerDaten2021Template;
  abstract E10: ReturnType<
    typeof getElsterDocumentNutzerDaten2021Template
  >['E10'];
}

function getElsterDocument2021Template(
  e10Model: DeepPartial<Elster2021E10>,
  year: number | string,
  financeDepartment?: string,
  state?: string,
  production: boolean = false
) {
  const oldElsterDocument = ElsterDocument.getTemplate();
  const testFinanceDepartment = '9198';
  const testState = 'EC';

  if (financeDepartment?.toString().length !== 4 || !state) {
    throw new Error(
      '`financeDepartment` and `state` have to be set for ElsterDocument2021'
    );
  }

  return {
    Elster: {
      '@': { xmlns: 'http://www.elster.de/elsterxml/schema/v11' },
      TransferHeader: {
        ...oldElsterDocument.Elster.TransferHeader,
        Vorgang: 'send-Auth',
        Testmerker: production === false ? '700000004' : undefined,
        Empfaenger: {
          '@': { id: 'L' },
          Ziel: production === true ? state : testState,
        },
      },
      DatenTeil: {
        Nutzdatenblock: {
          NutzdatenHeader: {
            ...oldElsterDocument.Elster.DatenTeil.Nutzdatenblock
              .NutzdatenHeader,
            Empfaenger: {
              '@': { id: 'F' },
              '#text':
                production === true ? financeDepartment : testFinanceDepartment,
            },
          },
          Nutzdaten: NutzerDaten2021.getTemplate(e10Model, year),
        },
      },
    },
  };
}

export abstract class ElsterDocument2021 {
  public static getTemplate: (
    ...args: Parameters<typeof getElsterDocument2021Template>
  ) => ElsterDocument2021 = getElsterDocument2021Template;
  abstract Elster: ReturnType<typeof getElsterDocument2021Template>['Elster'];
}
