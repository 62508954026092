import {
  Answer as AnswerBase,
  AnswerFeedback as AnswerFeedbackBase,
  ClientTodo as ClientTodoBase,
  ClientTodoType,
  TodoMatcherMetadata as TodoMatcherMetadatasBase,
} from '../../../api-interfaces/src/index';

// TODO; TODO #placeholder

const matcherId = 'vma-proof' as const;

export class TodoMatcherMetadata implements TodoMatcherMetadatasBase {
  matcherId = matcherId;
  input: {
    jobItemId: string;
    year: string;
  };

  constructor(input: TodoMatcherMetadata['input']) {
    this.input = { ...input };
  }
}

export enum TodoError {
  TODO = 'TODO', // TODO #placeholder
}

export function isTodo(todo: unknown): todo is ClientTodo {
  return (
    (todo as Partial<ClientTodo> | null)?.todoMatcherMetadata?.matcherId ===
    matcherId
  );
}

export function isAnswer(todo: unknown, answer: unknown): answer is Answer {
  const p = (answer as Answer | null)?.payload || null;
  const validPayload = () =>
    [p?.frontDocumentRef].some(
      (e) => e == null || (e && typeof e === 'string')
    );

  return isTodo(todo) && validPayload();
}

export function isFeedback(
  todo: unknown,
  feedback: unknown
): feedback is AnswerFeedback {
  const data = (feedback as AnswerFeedback | null)?.data || null;
  return isTodo(todo) && (data == null || typeof data.valid === 'boolean');
}

export interface FeedbackData {
  errorId: TodoError | null;
  valid: boolean;
}

export type AnswerFeedback = AnswerFeedbackBase & {
  data: FeedbackData | null;
};

export interface AnswerPayload {
  frontDocumentRef?: string | null;
  frontDocumentUploadRef?: string | null;
}

export type Answer = AnswerBase & {
  payload: AnswerPayload;
};

export type ClientTodo = ClientTodoBase & {
  type: ClientTodoType.VmaProof;
  todoMatcherMetadata: TodoMatcherMetadata;
};
