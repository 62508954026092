<mat-card>
  <span esuiCardKey>Content Tag Extraction</span>
  <mat-card-header>
    <mat-card-title> Extract Content From Document </mat-card-title>
    <mat-card-subtitle>
      <esui-store-state-indicator
        [hasOnlineChangedAfterLocalChanged]="
          !!taxofficeLetterStoreStateIndicator?.hasOnlineChangedAfterLocalChanged
        "
        [isSaving]="!!taxofficeLetterStoreStateIndicator?.isSaving"
        [isLoading]="!!taxofficeLetterStoreStateIndicator?.isLoading"
        [hasChanges]="!!taxofficeLetterStoreStateIndicator?.hasChanges"
        [hasErrors]="!!taxofficeLetterStoreStateIndicator?.hasErrors"
      ></esui-store-state-indicator
    ></mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div class="container">
      <div class="document-container">
        <mat-tab-group mat-align-tabs="start">
          <mat-tab label="Original Document">
            <div class="padding-10">
              <esui-skeleton-placeholder
                class="skelleton-container"
                [bigBoxMode]="true"
                [skeletonBuildInput]="[]"
                [watchResult]="!!originalDocument"
              >
                <ng-container *ngIf="originalDocument">
                  <esui-document-viewer
                    class="document-viewer-container"
                    [contentType]="originalDocument.contentType"
                    [downloadUrl]="originalDocument.downloadUrl"
                  ></esui-document-viewer>
                </ng-container>
              </esui-skeleton-placeholder>
            </div>
          </mat-tab>
          <mat-tab label="Text Extract">
            <div class="tagging-container">
              <esui-text-ocr-renderer
                [structuredOcr]="structuredOcr"
                [(selectedTextBlocksIds)]="selectedTextBlocks"
              ></esui-text-ocr-renderer></div
          ></mat-tab>
        </mat-tab-group>
      </div>
      <div class="work-space">
        <esui-skeleton-placeholder
          class="skelleton-flex"
          [skeletonBuildInput]="[
            [0, 3],
            [0, 3],
            [0, 2],
            [0, 1, 1, 1]
          ]"
          [watchResult]="!!taxCases && !!taxofficeLetter"
          [bigBoxMode]="false"
        >
          <div class="tag-selection-row">
            <h4>Select Tags</h4>
            <div class="divider-100">
              <mat-divider></mat-divider>
            </div>
            <esui-content-tag-selection
              [(selectedTags)]="selectedTags"
              [currentCategory]="prefilterTagCategory"
              class="chip-container"
            ></esui-content-tag-selection>
          </div>

          <div *ngIf="withYearSelection">
            <div class="taxcase-row">
              <h4>Select TaxCase</h4>
              <div class="divider-100">
                <mat-divider></mat-divider>
              </div>
              <div class="taxcase-selection-container">
                <mat-checkbox
                  *ngFor="let taxcase of taxCases"
                  [ngModel]="selectedTaxcasesIds.has(taxcase.id)"
                  (ngModelChange)="
                    toggleTaxcaseSelect(taxcase.id, taxcase.year)
                  "
                  >Taxcase: {{ taxcase.year }}</mat-checkbox
                >
              </div>
            </div>
          </div>

          <div class="taxcase-row">
            <h4>Affected years</h4>
            <div class="divider-100">
              <mat-divider></mat-divider>
              <esui-year-selection
                (selectedYearsChange)="onYearsSelected($event)"
                [selectedYears]="selectedYears"
                (deleteYear)="onDeleteYears($event)"
                (deleteAllYears)="onDeleteAllYears()"
              ></esui-year-selection>
            </div>
          </div>
          <div class="tag-overview-tow">
            <h4>Current added Content Tags</h4>
            <div class="divider-100">
              <mat-divider></mat-divider>
            </div>
            <esui-content-tag-viewer
              [taxofficeLetter]="taxofficeLetter"
              (removeContentTagsAtIndex)="removeContentTagsByIndex($event)"
            >
            </esui-content-tag-viewer>
          </div>
        </esui-skeleton-placeholder>
        <div class="button-row">
          <button
            mat-raised-button
            (click)="addCurrentContentTagAndClearState()"
            color="primary"
          >
            Add Content Tag Entry
          </button>
          <button mat-raised-button (click)="clearSelections()" color="accent">
            Clear Selections
          </button>
        </div>
        <div class="error-row">
          <mat-chip color="warn" [selected]="!!currentError">{{
            currentError ?? 'No Error'
          }}</mat-chip>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button
      type="button"
      mat-raised-button
      color="primary"
      (click)="verifyAndSave.next()"
      [disabled]="
        taxofficeLetterStoreStateIndicator?.isSaving === true ||
        taxofficeLetterStoreStateIndicator?.isLoading === true
      "
    >
      verify and save
    </button>
    <button
      type="button"
      mat-raised-button
      color="accent"
      (click)="save.next()"
      [disabled]="
        taxofficeLetterStoreStateIndicator?.hasChanges !== true ||
        taxofficeLetterStoreStateIndicator?.isSaving === true ||
        taxofficeLetterStoreStateIndicator?.isLoading === true
      "
    >
      save
    </button>
    <button
      type="button"
      mat-raised-button
      color="warn"
      (click)="discard.next()"
      [disabled]="
        taxofficeLetterStoreStateIndicator?.hasChanges !== true ||
        taxofficeLetterStoreStateIndicator?.isSaving === true ||
        taxofficeLetterStoreStateIndicator?.isLoading === true
      "
    >
      discard
    </button>
  </mat-card-actions>
</mat-card>
