import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { ContentTaggable } from '@expresssteuer/models';

@Component({
  selector: 'esui-content-tag-viewer',
  templateUrl: './esui-content-tag-viewer.component.html',
  styleUrls: ['./esui-content-tag-viewer.component.scss'],
})
export class EsuiContentTagViewerComponent {
  @Input()
  taxofficeLetter?: Partial<ContentTaggable> | null = null;

  @Output()
  removeContentTagsAtIndex = new EventEmitter<number[]>();

  deleteSelectedContentTagIndizes(selectionList: MatSelectionList) {
    const indices = selectionList.selectedOptions.selected.map((e) => e.value);
    console.log(indices);
    this.removeContentTagsAtIndex.next(indices);
  }
}
