<div class="btn-group">
  <button
    class="btn btn-primary font-weight-bold btn-lg dropdown-toggle"
    type="button"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    Annehmen
  </button>
  <div class="dropdown-menu">
    <a class="dropdown-item">Annehmen und mir Zuweisen</a>
    <a class="dropdown-item">Annehmen und jemandem Zuseisen</a>
  </div>
</div>
