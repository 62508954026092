import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsStoreStateIndicatorModule } from '../es-store-state-indicator/es-store-state-indicator.module';
import { EsuiDocumentViewerModule } from '../esui-document-viewer/esui-document-viewer.module';
import { EsuiTaxNoticeModule } from '../esui-tax-notice/esui-tax-notice.module';
import { TransactionlinesModule } from '../esui-transaction-lines/transactionlines.module';
import { EsuiTaxnoticeVerifierComponent } from './esui-taxnotice-verifier.component';

@NgModule({
  declarations: [EsuiTaxnoticeVerifierComponent],
  imports: [
    CommonModule,
    EsuiDocumentViewerModule,
    EsuiTaxNoticeModule,
    MatCardModule,
    MatButtonModule,
    EsStoreStateIndicatorModule,
    TransactionlinesModule,
    MatListModule,
    MatDividerModule,
    EsCardKeyModule,
    MatGridListModule,
    MatButtonModule,
  ],
  exports: [EsuiTaxnoticeVerifierComponent],
})
export class EsuiTaxnoticeVerifierModule {}
