export interface Notification {
  title: string;
  body: string;
  actionLink?: string;
  sound?: boolean;
}

export enum NotificationChannels {
  TaxcaseUpdates = 'steuerfall updates',
  News = 'neuigkeiten',
  GeneralInfo = 'generelle infos',
}
export type NotificationChannelKeys = keyof typeof NotificationChannels;

export enum Topics {
  GeneralInfo = 'general-info',
  News = 'neuigkeiten',
  Anonymous = 'anonymous-infos',
}
