import { DOCUMENTTYPE } from '../document/document';
import { Timestamp } from '../helpers/timestamp';
import { TaxofficeLetter } from './taxoffice-letter';

/**
 * reminder for request letters to provide info ASAP
 */
export abstract class TaxofficeReminder extends TaxofficeLetter {
  documentType: DOCUMENTTYPE.taxoffice_reminder =
    DOCUMENTTYPE.taxoffice_reminder;
  /**
   * current due date
   */
  abstract dueDate: Timestamp | null;
  /**
   * associated request document
   */
  abstract taxofficeRequestId: string | null;
}
