<mat-card>
  <h1 esuiCardKey>Assign Jobgroup</h1>
  <mat-card-header>
    <mat-card-subtitle>
      <esui-store-state-indicator
        [hasOnlineChangedAfterLocalChanged]="
          !!hasOnlineChangedAfterLocalChanged
        "
        [isSaving]="!!isSaving"
        [isLoading]="!!isLoading"
        [hasChanges]="!!hasChanges"
        [hasErrors]="!!hasErrors"
      ></esui-store-state-indicator>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="job-list">
      <esui-skeleton-placeholder
        [skeletonBuildInput]="[]"
        [watchResult]="!jobgroupsLoading"
        [bigBoxMode]="true"
      >
        <div *ngIf="jobgroups?.length === 0">
          There are no jobgroups defined. Create them using the CRM.
        </div>
        <mat-radio-group
          aria-label="Select a jobgroup"
          [ngModel]="selectedJobgroup"
          (ngModelChange)="jobGroupSelected.next($event)"
        >
          <mat-radio-button
            *ngFor="let jobgroup of jobgroups; let i = index"
            class="radio-button"
            [value]="jobgroup.id"
            >{{ jobgroup.labelDE }}</mat-radio-button
          >
        </mat-radio-group>
      </esui-skeleton-placeholder>
    </div>
    <div class="customer-information">
      <esui-skeleton-placeholder
        [skeletonBuildInput]="[[0], [1, 2]]"
        [watchResult]="!isLoading"
        [bigBoxMode]="false"
      >
        <div class="profession">{{ jobName }}</div>
        <div class="info" *ngIf="hasMultipleEmployersForJob">
          Info: This task will assign the job group to multiple jobs with the
          same name.
        </div>
        <div class="employer" *ngFor="let employer of employers">
          <div>{{ employer?.name }}</div>
          <a
            [href]="employer?.address?.formatedAddress | googleMapsLink"
            target="_blank"
            rel="noopener noreferrer"
            >{{ employer?.address?.formatedAddress }}</a
          >
        </div>
      </esui-skeleton-placeholder>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button
      type="button"
      mat-raised-button
      color="primary"
      [disabled]="!hasChanges"
      (click)="saveJobGroupClicked.next()"
    >
      Save Job Group
    </button>
    <button
      type="button"
      mat-raised-button
      color="secondary"
      (click)="noMatchingJobGroupClicked.next()"
    >
      No Matching Job Group
    </button>
  </mat-card-actions>
</mat-card>
