import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchClient } from 'algoliasearch';

/**
 * A full search component for searching a client in Algolia.
 */
@Component({
  selector: 'esui-client-search',
  templateUrl: './esui-client-search.component.html',
  styleUrls: ['./esui-client-search.component.scss'],
})
export class EsuiClientSearchComponent {
  @Input() indexName?: string | null;
  @Input() searchClient?: SearchClient | null;

  @Input() clientId?: string | null;
  @Output() clientIdChange = new EventEmitter<string | null | undefined>();

  @Input() mode: 'default' | 'clientIdEditor' = 'default';
  @Input() appearance: 'legacy' | 'standard' | 'fill' | 'outline' = 'standard';
}
