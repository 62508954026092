<ng-container *ngIf="!viewMode">
  <select class="form-control" [(ngModel)]="valueProxy" [disabled]="isDisabled">
    <option
      *ngFor="let set of options | keyvalue : originalOrder"
      [ngValue]="set.value"
      (click)="onTouched()"
    >
      {{ set.key }}
    </option>
  </select>
</ng-container>

<ng-container *ngIf="viewMode">
  {{ activeKey }}
</ng-container>
