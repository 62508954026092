import { Timestamp } from '../helpers/timestamp';

/**
 * Defines a checklist what needs to be complete
 * Intended to save checklists to firestore
 * The ID of this item has to be taxcase => taxcase.id, client => client.id
 */
export class Checklist {
  public static getTemplate(): Checklist {
    return {
      created: null,
      modified: null,
      complete: false,
      itemcount: 0,
      completecount: 0,
      progress: 0.0,
    };
  }

  /**
   * If complex validation is needed, overwrite this method
   * @param checklist
   * @returns
   */
  public static validate(checklist: Checklist): boolean {
    console.log(checklist);
    return true;
  }

  created: Timestamp | null = null;
  modified: Timestamp | null = null;
  complete: boolean = false;
  /**
   * amount of items to check
   */
  itemcount = 0;
  /**
   * amount of items completed
   */
  completecount = 0;
  /**
   * the progress in percent of items complete
   */
  progress = 0;
}
