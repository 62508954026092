import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  VerifyEmailInput,
  VerifyEmailOutput,
} from '@expresssteuer/client-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class VerifyEmail {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: VerifyEmailInput) {
    console.log('Calling client-verifyEmail');
    return this.afFunctions.httpsCallable<VerifyEmailInput, VerifyEmailOutput>(
      'client-verifyEmail'
    )(input);
  }
}
