import { Component, Input } from '@angular/core';
import { SmartTask, User } from '@expresssteuer/models';
import { TaskHelper } from '../../task-helper';

@Component({
  selector: 'esui-task-list-item',
  templateUrl: './task-list-item.component.html',
  styleUrls: ['./task-list-item.component.scss'],
})
export class TaskListItemComponent {
  @Input() task?: SmartTask;
  @Input() users?: Partial<User>[] | null;

  get icon() {
    return TaskHelper.taskTypeToIcon(this.task?.type || '');
  }

  get typeDescription() {
    return TaskHelper.taskTypeToDescription(this.task?.type || '');
  }

  get taxyears() {
    return new Set(
      (this.task?.taxcaseIds ?? [])
        .map((id) => {
          const parsedNumberString = id?.match(/[1-9][0-9]+/)?.[0];
          if (parsedNumberString) {
            return parseInt(parsedNumberString, 10);
          }
          return null;
        })
        .filter((e) => typeof e === 'number')
        .sort()
    );
  }

  trackByUser(index: number, user: unknown) {
    return JSON.stringify({ user, index });
  }
}
