<div class="aside aside-left d-flex flex-column" id="kt_aside">
  <!--begin::Brand-->
  <div
    class="aside-brand d-flex flex-column align-items-center flex-column-auto pt-8 pb-10"
  >
    <!--begin::Logo-->
    <div
      class="btn p-0 symbol symbol-60 symbol-light-primary"
      id="kt_quick_user_toggle"
    >
      <div class="symbol-label">
        <img
          alt="Logo"
          src="/assets/media/users/benedikt.png"
          class="h-75 align-self-center"
        />
      </div>
    </div>
    <!--end::Logo-->
  </div>
  <!--end::Brand-->
  <!--begin::Nav Wrapper-->
  <div
    class="aside-nav d-flex flex-column align-items-center flex-column-fluid pb-10"
  >
    <!--begin::Nav-->
    <ul class="nav flex-column">
      <!--begin::Item-->

      <li
        class="nav-item mb-2"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title="{{ aircallService.ringingClient?.id || 'kein Anruf' }}"
      >
        <a
          href="client/{{ aircallService.ringingClient?.id || '' }}"
          target="_blank"
          [ngClass]="{
            'btn btn-icon btn-danger': aircallService.ringingClient,
            'btn btn-icon btn-secondary': !aircallService.ringingClient
          }"
          ><span class="bi bi-headset"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-headset"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z"
              /></svg></span
        ></a>
      </li>

      <li
        class="nav-item mb-2"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title="Suche"
      >
        <a
          [routerLink]="['/search']"
          routerLinkActive="active"
          class="nav-link btn btn-icon btn-hover-text-primary btn-lg"
          (click)="updateTitle('Suche')"
        >
          <span class="svg-icon svg-icon-xxl">
            <!--begin::Svg Icon | path:assets/media/svg/icons/Layout/Layout-4-blocks.svg-->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path
                  d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                  opacity="0.3"
                />
                <path
                  d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                />
              </g>
            </svg>
            <!--end::Svg Icon-->
          </span>
        </a>
      </li>
      <!--end::Item-->

      <!--begin::Item-->
      <li
        class="nav-item mb-2"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title="Steuerfälle"
      >
        <a
          [routerLink]="['/taxcases/entry']"
          routerLinkActive="active"
          class="nav-link btn btn-icon btn-hover-text-primary btn-lg"
          (click)="updateTitle('Meine Fälle')"
        >
          <span class="svg-icon svg-icon-xxl">
            <!--begin::Svg Icon | path:assets/media/svg/icons/Layout/Layout-4-blocks.svg-->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <rect
                  fill="#000000"
                  opacity="0.3"
                  x="11"
                  y="7"
                  width="2"
                  height="14"
                  rx="1"
                />
                <path
                  d="M6.70710678,20.7071068 C6.31658249,21.0976311 5.68341751,21.0976311 5.29289322,20.7071068 C4.90236893,20.3165825 4.90236893,19.6834175 5.29289322,19.2928932 L11.2928932,13.2928932 C11.6714722,12.9143143 12.2810586,12.9010687 12.6757246,13.2628459 L18.6757246,18.7628459 C19.0828436,19.1360383 19.1103465,19.7686056 18.7371541,20.1757246 C18.3639617,20.5828436 17.7313944,20.6103465 17.3242754,20.2371541 L12.0300757,15.3841378 L6.70710678,20.7071068 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                  transform="translate(12.000003, 16.999999) scale(1, -1) translate(-12.000003, -16.999999) "
                />
                <rect
                  fill="#000000"
                  opacity="0.3"
                  x="3"
                  y="3"
                  width="18"
                  height="2"
                  rx="1"
                />
              </g>
            </svg>
            <!--end::Svg Icon-->
          </span>
        </a>
      </li>
      <!--end::Item-->
      <!--begin::Item-->
      <li
        class="nav-item mb-2"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title="Formulare"
      >
        <a
          [routerLink]="['/taxforms']"
          routerLinkActive="active"
          class="nav-link btn btn-icon btn-hover-text-primary btn-lg"
          (click)="updateTitle('Leads')"
          *ngIf="false"
        >
          <span class="svg-icon svg-icon-xxl">
            <!--begin::Svg Icon | path:assets/media/svg/icons/Layout/Layout-4-blocks.svg-->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <polygon
                  fill="#000000"
                  opacity="0.3"
                  points="6 3 18 3 20 6.5 4 6.5"
                />
                <path
                  d="M6,5 L18,5 C19.1045695,5 20,5.8954305 20,7 L20,19 C20,20.1045695 19.1045695,21 18,21 L6,21 C4.8954305,21 4,20.1045695 4,19 L4,7 C4,5.8954305 4.8954305,5 6,5 Z M9,9 C8.44771525,9 8,9.44771525 8,10 C8,10.5522847 8.44771525,11 9,11 L15,11 C15.5522847,11 16,10.5522847 16,10 C16,9.44771525 15.5522847,9 15,9 L9,9 Z"
                  fill="#000000"
                />
              </g>
            </svg>
            <!--end::Svg Icon-->
          </span>
        </a>
      </li>
      <!--end::Item-->
      <!--begin::Item-->

      <li
        *ngIf="env.emulator"
        class="nav-item mb-2"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title="Kunden"
      >
        <a
          [routerLink]="['/clients']"
          routerLinkActive="active"
          class="nav-link btn btn-icon btn-hover-text-primary btn-lg"
          (click)="updateTitle('Kunden')"
        >
          <span class="svg-icon svg-icon-xxl">
            <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg-->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <path
                  d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                  opacity="0.3"
                />
                <path
                  d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                />
              </g>
            </svg>
            <!--end::Svg Icon-->
          </span>
        </a>
      </li>
      <!--end::Item-->
      <!--begin::Item-->
      <li
        class="nav-item mb-2"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title="Messaging"
      >
        <a
          [routerLink]="['/messaging/inbox']"
          routerLinkActive="active"
          class="nav-link btn btn-icon btn-hover-text-primary btn-lg"
          data-toggle="tab"
          data-target="#kt_aside_tab_3"
          role="tab"
          (click)="updateTitle('Nachrichten')"
        >
          <span class="svg-icon svg-icon-xxl">
            <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg-->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path
                  d="M21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L6,18 C4.34314575,18 3,16.6568542 3,15 L3,6 C3,4.34314575 4.34314575,3 6,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 Z"
                  fill="#000000"
                  opacity="0.3"
                />
                <path
                  d="M7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 Z M12.5,12 C11.6715729,12 11,11.3284271 11,10.5 C11,9.67157288 11.6715729,9 12.5,9 C13.3284271,9 14,9.67157288 14,10.5 C14,11.3284271 13.3284271,12 12.5,12 Z M17.5,12 C16.6715729,12 16,11.3284271 16,10.5 C16,9.67157288 16.6715729,9 17.5,9 C18.3284271,9 19,9.67157288 19,10.5 C19,11.3284271 18.3284271,12 17.5,12 Z"
                  fill="#000000"
                  opacity="0.3"
                />
              </g>
            </svg>
            <!--end::Svg Icon-->
          </span>
        </a>
      </li>
      <!--end::Item-->

      <!--begin::Item-->
      <li
        class="nav-item mb-2"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title="Documents"
      >
        <a
          [routerLink]="['/documents']"
          routerLinkActive="active"
          class="nav-link btn btn-icon btn-hover-text-primary btn-lg"
          data-toggle="tab"
          data-target="#kt_aside_tab_3"
          role="tab"
          (click)="updateTitle('Dokumente')"
        >
          <span class="svg-icon svg-icon-xxl">
            <!--begin::Svg Icon | path:assets/media/svg/icons/Media/Equalizer.svg-->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path
                  d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z"
                  fill="#000000"
                  opacity="0.3"
                />
                <path
                  d="M12.6706167,18.7881514 L15.9697449,13.8394592 C16.1995092,13.4948127 16.1063788,13.0291607 15.7617323,12.7993963 C15.6385316,12.7172626 15.4937759,12.673434 15.3457071,12.673434 L12.659208,12.673434 L12.659208,9.69999981 C12.659208,9.28578625 12.3234215,8.94999981 11.909208,8.94999981 C11.6584431,8.94999981 11.4242696,9.07532566 11.2851703,9.28397466 L7.98604212,14.2326669 C7.75627777,14.5773134 7.84940818,15.0429654 8.19405469,15.2727297 C8.31725533,15.3548635 8.4620111,15.398692 8.61007984,15.398692 L11.296579,15.398692 L11.296579,18.3721263 C11.296579,18.7863398 11.6323654,19.1221263 12.046579,19.1221263 C12.2973439,19.1221263 12.5315174,18.9968004 12.6706167,18.7881514 Z"
                  fill="#000000"
                  opacity="0.3"
                />
              </g>
            </svg>
            <!--end::Svg Icon-->
          </span>
        </a>
      </li>
      <!--end::Item-->
    </ul>
    <!--end::Nav-->
  </div>
  <!--end::Nav Wrapper-->
  <!--begin::Footer-->
  <div
    class="aside-footer d-flex flex-column align-items-center flex-column-auto py-8"
  >
    <!--begin::Notifications-->
    <a
      class="btn btn-icon btn-hover-text-primary btn-lg mb-1 position-relative"
      id="kt_quick_notifications_toggle"
      data-toggle="tooltip"
      data-placement="right"
      data-container="body"
      data-boundary="window"
      title="Tasksystem"
      href="{{ env.taskIsland.endpoint }}"
      target="_blank"
    >
      <span class="svg-icon svg-icon-xxl">
        <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Layers.svg-->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <polygon points="0 0 24 0 24 24 0 24" />
            <path
              d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
              fill="#000000"
              fill-rule="nonzero"
            />
            <path
              d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
              fill="#000000"
              opacity="0.3"
            />
          </g>
        </svg>
        <!--end::Svg Icon-->
      </span>
    </a>
    <!-- SETTINGS -->
    <a
      [routerLink]="['/admin']"
      routerLinkActive="active"
      class="btn btn-icon btn-hover-text-primary btn-lg mb-1 position-relative"
      id="kt_quick_notifications_toggle"
      data-toggle="tooltip"
      data-placement="right"
      data-container="body"
      data-boundary="window"
      title="Administration / Settings"
    >
      <span class="svg-icon svg-icon-xxl">
        <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Layers.svg-->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect x="0" y="0" width="24" height="24" />
            <path
              d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z"
              fill="#000000"
            />
          </g>
        </svg>
        <!--end::Svg Icon-->
      </span>
    </a>
    <!--end::Notifications-->
    <!--begin::Quick Actions-->
    <a
      (click)="logout()"
      class="btn btn-icon btn-hover-text-primary btn-lg mb-1"
      id="kt_quick_actions_toggle"
      data-toggle="tooltip"
      data-placement="right"
      data-container="body"
      data-boundary="window"
      title="Abmelden"
    >
      <span class="svg-icon svg-icon-xxl">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect x="0" y="0" width="24" height="24" />
            <path
              d="M7.62302337,5.30262097 C8.08508802,5.000107 8.70490146,5.12944838 9.00741543,5.59151303 C9.3099294,6.05357769 9.18058801,6.67339112 8.71852336,6.97590509 C7.03468892,8.07831239 6,9.95030239 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,9.99549229 17.0108275,8.15969002 15.3875704,7.04698597 C14.9320347,6.73472706 14.8158858,6.11230651 15.1281448,5.65677076 C15.4404037,5.20123501 16.0628242,5.08508618 16.51836,5.39734508 C18.6800181,6.87911023 20,9.32886071 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,9.26852332 5.38056879,6.77075716 7.62302337,5.30262097 Z"
              fill="#000000"
              fill-rule="nonzero"
            />
            <rect
              fill="#000000"
              opacity="0.3"
              x="11"
              y="3"
              width="2"
              height="10"
              rx="1"
            />
          </g>
        </svg>
      </span>
    </a>
    <!--end::Quick Actions-->
    <!--begin::Quick Panel
        <a href="#" class="btn btn-icon btn-hover-text-primary btn-lg mb-1" id="kt_quick_panel_toggle"
            data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window"
            title="Quick Panel">
            <span class="svg-icon svg-icon-xxl">

                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24" />
                        <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5" />
                        <path
                            d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                            fill="#000000" opacity="0.3" />
                    </g>
                </svg>

            </span>
        </a>
        -->
    <!--end::Quick Panel-->
    <!--begin::Languages-->

    <!--end::Languages-->
  </div>
  <!--end::Footer-->
</div>
