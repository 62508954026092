import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CONTENT_TAGS_STRUCTURED } from '@expresssteuer/models';
import { compareTwoStrings } from 'string-similarity';

@Component({
  selector: 'esui-content-tag-selection',
  templateUrl: './esui-content-tag-selection.component.html',
  styleUrls: ['./esui-content-tag-selection.component.scss'],
})
export class EsuiContentTagSelectionComponent {
  staticTags: { category: string; values: string[] }[] =
    CONTENT_TAGS_STRUCTURED;

  _internalTagsSelectedState: Set<string> = new Set<string>();

  showOnlySelected = false;

  prefix = '';

  @Input()
  currentCategory?: string = 'NONE';

  @Input()
  dynamicTags?: { category: string; values: string[] }[] | null;

  @Input() set selectedTags(tags: string[] | null) {
    this._internalTagsSelectedState.clear();
    tags
      ?.filter((tag) =>
        this.getAllTags().find((tagToFind) => tag === tagToFind)
      )
      .map((tag) => {
        this._internalTagsSelectedState.add(tag);
      });
  }

  @Output()
  selectedTagsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  getAllTags(): string[] {
    return (this.dynamicTags ?? [])
      .concat(this.staticTags)
      .map((tag) => tag.values)
      .reduce((curr, acc) => curr.concat(acc), []);
  }

  getTagsByCategory(category?: string): string[] {
    if (!category || category === 'NONE') {
      return this.getAllTags();
    }
    return (this.dynamicTags ?? [])
      .concat(this.staticTags)
      .filter((tag) => category === tag.category)
      .map((tag) => tag.values)
      .reduce((cur, acc) => cur.concat(acc), []);
  }

  getTagsBySelection(
    category?: string,
    onlySelected: boolean = false
  ): string[] {
    let tags = this.getTagsByCategory(category);
    if (this.prefix !== '') {
      tags = this.simplePrefixSearch(this.prefix, tags);
    }
    if (!onlySelected) {
      return tags;
    }
    return tags.filter((tag) => this._internalTagsSelectedState.has(tag));
  }

  simplePrefixSearch(prefix: string, tags: string[]): string[] {
    return tags.filter((tag) => {
      const seperated = tag.split('_');
      const name = seperated.slice(1, seperated.length).join();
      return compareTwoStrings(prefix.toUpperCase(), name.toUpperCase()) > 0.2;
    });
  }

  getCategories(): string[] {
    return (this.dynamicTags ?? [])
      .concat(this.staticTags)
      .map((tags) => tags.category);
  }

  isTagSelected(tag: string): boolean {
    return this._internalTagsSelectedState.has(tag);
  }

  tagSelected(tag: string): void {
    if (this._internalTagsSelectedState.has(tag)) {
      this._internalTagsSelectedState.delete(tag);
      this.selectedTagsChange.next(
        Array.from(this._internalTagsSelectedState.values())
      );
    } else {
      this.selectedTagsChange.next(
        Array.from(this._internalTagsSelectedState.values()).concat([tag])
      );
    }
  }
}
