<mat-card>
  <h1 esuiCardKey>Optimize for Jobgroup</h1>
  <mat-card-header>
    <mat-card-subtitle>
      <esui-store-state-indicator
        [hasOnlineChangedAfterLocalChanged]="
          !!hasOnlineChangedAfterLocalChanged
        "
        [isSaving]="!!isSaving"
        [isLoading]="!!isLoading"
        [hasChanges]="!!hasChanges"
        [hasErrors]="!!hasErrors"
      ></esui-store-state-indicator>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="person">
      <esui-jobgroup-optimize-current-expenses
        [name]="clientName"
        [commutingCost]="clientCommutingCost"
        [homeofficeCost]="clientHomeofficeCost"
        [additionalExpenses]="clientAdditionalExpenses"
      >
      </esui-jobgroup-optimize-current-expenses>
      <div class="jobgroup-list">
        <esui-jobgroup-optimize-proposed-expenses
          *ngFor="let jobGroup of clientJobGroups"
          [jobGroup]="jobGroup"
          (addExpenseClicked)="addExpenseClicked.next($event)"
        >
        </esui-jobgroup-optimize-proposed-expenses>
        <div *ngIf="!clientJobGroups?.length">keine Jobgruppen zugewiesen</div>
      </div>
    </div>

    <ng-container *ngIf="hasPartner">
      <hr />
      <div class="person">
        <esui-jobgroup-optimize-current-expenses
          [name]="partnerName"
          [commutingCost]="partnerCommutingCost"
          [homeofficeCost]="partnerHomeofficeCost"
          [additionalExpenses]="partnerAdditionalExpenses"
        >
        </esui-jobgroup-optimize-current-expenses>

        <div class="jobgroup-list">
          <esui-jobgroup-optimize-proposed-expenses
            *ngFor="let jobGroup of partnerJobGroups"
            [jobGroup]="jobGroup"
            (addExpenseClicked)="addExpenseClicked.next($event)"
          >
          </esui-jobgroup-optimize-proposed-expenses>
          <div *ngIf="!partnerJobGroups?.length">
            keine Jobgruppen zugewiesen
          </div>
        </div>
      </div>
    </ng-container>
  </mat-card-content>
  <mat-card-actions>
    <button
      type="button"
      mat-raised-button
      color="secondary"
      [disabled]="!hasChanges"
      (click)="saveOptimizationClicked.next()"
    >
      Save
    </button>
    <button
      type="button"
      mat-raised-button
      color="primary"
      (click)="finishOptimizationClicked.next()"
    >
      Finish Optimization
    </button>
  </mat-card-actions>
</mat-card>
