import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EsStoreStateIndicatorModule } from '../../es-store-state-indicator/es-store-state-indicator.module';
import { ActionBoxComponent } from './action-box.component';

@NgModule({
  declarations: [ActionBoxComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    EsStoreStateIndicatorModule,
  ],
  exports: [ActionBoxComponent],
})
export class ActionBoxModule {}
