import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientFacingTask, ClientFileUploadState } from '@expresssteuer/models';
import { TextingTranslationInputDocumentUploader } from '../esui-document-uploader/esui-document-uploader.component';

// workaround for testing -> add to model
export type ClientFacingTaskWithUploadState = ClientFacingTask & {
  meta: { uploadStates: ClientFileUploadState[] };
};

export type ClientFacingTaskWithFile = {
  clientFacingTask: ClientFacingTask;
  file: File;
};

export type ClientFacingTaskWithRemark = {
  clientFacingTask: ClientFacingTask;
  remark: string;
};

export type TextingTranslationInputClientTaskViewer = {
  openTasksHeader: string;
  closedTasksHeader: string;
};
@Component({
  selector: 'esui-client-facing-task-viewer',
  templateUrl: './esui-client-facing-task-viewer.component.html',
  styleUrls: ['./esui-client-facing-task-viewer.component.scss'],
})
export class EsuiClientFacingTaskViewerComponent {
  @Input()
  translationContentDocumentUploader?: TextingTranslationInputDocumentUploader;
  @Input()
  translationContentClientTaskViewer?: TextingTranslationInputClientTaskViewer;

  clientFacingTasksOpen?: ClientFacingTaskWithUploadState[];
  clientFacingTasksDone?: ClientFacingTaskWithUploadState[];
  @Input()
  public set clientFacingTasks(
    clientFacingTasks: ClientFacingTaskWithUploadState[]
  ) {
    this.clientFacingTasksOpen = clientFacingTasks.filter((e) => {
      return !this.allUploadsCompleted(e);
    });
    this.clientFacingTasksDone = clientFacingTasks.filter((e) => {
      return this.allUploadsCompleted(e);
    });
  }

  @Output() clientFacingTaskChange =
    new EventEmitter<ClientFacingTaskWithUploadState>();
  @Output() clientFacingTaskPartialChange = new EventEmitter<
    Partial<ClientFacingTaskWithUploadState>
  >();
  @Output() fileToUploadSelected = new EventEmitter<ClientFacingTaskWithFile>();
  @Output() remarkForTask = new EventEmitter<ClientFacingTaskWithRemark>();
  @Output() fileMarkedForDeletion = new EventEmitter<string>();

  partialClientFacingTaskChange(
    change: Partial<ClientFacingTaskWithUploadState>,
    original: ClientFacingTaskWithUploadState
  ): void {
    const merged = { ...original, ...change };
    this.clientFacingTaskPartialChange.next(change);
    this.clientFacingTaskChange.next(merged as any);
  }

  fileUploadFromClientTask(fileSelected: File, clientTask: ClientFacingTask) {
    this.fileToUploadSelected.next({
      file: fileSelected,
      clientFacingTask: clientTask,
    });
  }

  newRemarkForTask(text: string, clientTask: ClientFacingTask) {
    this.remarkForTask.next({
      clientFacingTask: clientTask,
      remark: text,
    });
  }

  allUploadsDoneForTask(
    event: boolean,
    clientTask: ClientFacingTaskWithUploadState
  ) {
    this.partialClientFacingTaskChange(
      { allDocsUploaded: event } as Partial<ClientFacingTask>,
      clientTask
    );
  }
  documentsNotAvailableForTask(
    event: boolean,
    clientTask: ClientFacingTaskWithUploadState
  ) {
    this.partialClientFacingTaskChange({ noneExistent: event }, clientTask);
  }

  clientDocumentUploadPartialStateChange(
    partial: Partial<ClientFileUploadState> & { id: string },
    clientTask: ClientFacingTaskWithUploadState
  ) {
    const updatedUploadStates = clientTask.meta.uploadStates.map(
      (uploadState) => {
        if (uploadState.id === partial.id) {
          return { ...partial, ...uploadState };
        } else {
          return uploadState;
        }
      }
    );
    this.partialClientFacingTaskChange(
      {
        meta: { uploadStates: updatedUploadStates },
      },
      clientTask
    );
  }

  clientDocumentToMarkForDeletion(uploadDocumentId: string) {
    this.fileMarkedForDeletion.next(uploadDocumentId);
  }

  allUploadsCompleted(
    clientFacingTask: ClientFacingTaskWithUploadState
  ): boolean {
    const uploadAllComplete = clientFacingTask.meta.uploadStates
      .map((e) => e.documentState.uploaded)
      .reduce((p, c) => p && c, true);
    return uploadAllComplete;
  }
  allUploadDocumentsVerified(
    clientFacingTask: ClientFacingTaskWithUploadState
  ) {
    const verifiedAll = clientFacingTask.meta.uploadStates
      .map((e) => e.documentState.verified)
      .reduce((p, c) => p && c, true);
    return verifiedAll;
  }
  allDocumentsProcessed(clientFacingTask: ClientFacingTaskWithUploadState) {
    const processedAll = clientFacingTask.meta.uploadStates
      .map((e) => e.documentState.processed)
      .reduce((p, c) => p && c, true);
    return processedAll;
  }
}
