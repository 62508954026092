import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface OnlineStatusService {
  connectionState$: Observable<boolean>;
}

export const ONLINE_STATUS_SERVICE = new InjectionToken<OnlineStatusService>(
  'ONLINE_STATUS_SERVICE'
);
