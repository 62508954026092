<div *ngIf="taxcaseComment as taxcaseComment">
  <div class="msg-header">
    <div>{{ taxcaseComment.taskmetadata.updatedBy?.displayName ?? '' }}</div>
    <div class="subject" *ngIf="taxcaseComment.subject">
      :: {{ taxcaseComment.subject }}
    </div>
    <div class="updatedAt" *ngIf="taxcaseComment.taskmetadata.updatedAt">
      {{ updatedAt | date : 'dd.MM.yyyy HH:mm' }}
    </div>
  </div>
  <div class="msg-body">
    <div class="msg-cards">
      <mat-card class="msg-card">
        <mat-card-header> </mat-card-header>
        <mat-card-content [innerHtml]="taxcaseComment.message">
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
