<div class="btn-group btn-group-lg" role="group">
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="jobstatus === JOBSTATUS.employed"
    (click)="jobstatus = JOBSTATUS.employed"
  >
    Arbeitend
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="jobstatus === JOBSTATUS.minijob"
    (click)="jobstatus = JOBSTATUS.minijob"
  >
    450€
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="jobstatus === JOBSTATUS.parental_leave"
    (click)="jobstatus = JOBSTATUS.parental_leave"
  >
    Elternzeit
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="jobstatus === JOBSTATUS.selfemployed"
    (click)="jobstatus = JOBSTATUS.selfemployed"
  >
    Selbstständig
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="jobstatus === JOBSTATUS.unemployed"
    (click)="jobstatus = JOBSTATUS.unemployed"
  >
    Arbeitslos
  </button>
</div>
