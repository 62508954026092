import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';
import { OnlineStatusService } from './online-status.service';

@Injectable()
export class BrowserOnlineStatusService implements OnlineStatusService {
  private subscriptionCount$ = new BehaviorSubject<number>(0);

  private subject$ = new BehaviorSubject<boolean>(
    window.navigator.onLine === true
  );

  public connectionState$: Observable<boolean> = this.subject$.pipe(
    (source$) => {
      return defer(() => {
        this.subscriptionCount$.next(this.subscriptionCount$.getValue() + 1);
        this.onCountUpdate();
        return source$;
      }).pipe(
        finalize(() => {
          this.subscriptionCount$.next(this.subscriptionCount$.getValue() - 1);
          this.onCountUpdate();
        })
      );
    },
    shareReplay(1)
  );

  private callback = () => {
    if (window.navigator.onLine === true) {
      console.log('BrowserOnlineStatusService::connected');
      this.subject$.next(true);
    } else {
      console.log('BrowserOnlineStatusService::not-connected');
      this.subject$.next(false);
    }
  };

  private onCountUpdate() {
    const count = this.subscriptionCount$.getValue();
    if (count === 1) {
      this.onStart();
    }
    if (count === 0) {
      this.onEnd();
    }
  }

  private onStart() {
    window.addEventListener('online', this.callback);
    window.addEventListener('offline', this.callback);
  }

  private onEnd() {
    window.removeEventListener('online', this.callback);
    window.removeEventListener('offline', this.callback);
  }
}
