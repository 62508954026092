import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Employer, JobGroup, WithId } from '@expresssteuer/models';

@Component({
  selector: 'esui-jobgroup-assign',
  templateUrl: './esui-jobgroup-assign.component.html',
  styleUrls: ['./esui-jobgroup-assign.component.scss'],
})
export class EsuiJobgroupAssignComponent {
  @Input() jobName?: string | null;
  @Input() employers?: Employer[] | null;

  @Input() jobgroups?: WithId<JobGroup>[] | null;
  @Input() jobgroupsLoading?: boolean | null;
  @Input() selectedJobgroup?: string | null;

  @Input() hasOnlineChangedAfterLocalChanged?: boolean | null;
  @Input() isSaving?: boolean | null;
  @Input() isLoading?: boolean | null;
  @Input() hasChanges?: boolean | null;
  @Input() hasErrors?: boolean | null;

  @Output() jobGroupSelected = new EventEmitter<string>();
  @Output() saveJobGroupClicked = new EventEmitter<void>();
  @Output() noMatchingJobGroupClicked = new EventEmitter<void>();

  get hasMultipleEmployersForJob() {
    return Array.isArray(this.employers) && this.employers?.length > 1;
  }
}
