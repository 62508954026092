import { SchemaProperty } from './vast';

export const VastPers2Schema: SchemaProperty = {
  title: 'VaSt_Pers2-1.xsd',
  $schema: 'http://json-schema.org/schema#',
  type: 'object',
  properties: {
    VaSt_Pers2: {
      description: 'Religionszugehörigkeit',
      properties: {
        IdNr: {
          type: 'string',
        },
        Religion: {
          type: 'string',
        },
        version: {
          type: 'string',
          enum: ['1'],
        },
      },
      required: ['version'],
      type: 'object',
    },
  },
  required: ['VaSt_Pers2'],
};
