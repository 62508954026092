<mat-form-field appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [ngModel]="taxAdvisorId"
    (ngModelChange)="taxAdvisorIdChange.emit($event)"
  >
    <mat-option
      [value]="advisor.id"
      *ngFor="let advisor of taxadvisors"
      [disabled]="disableInactive ? !advisor.active : false"
      >{{ advisor.displayName }}</mat-option
    >
  </mat-select>
</mat-form-field>
