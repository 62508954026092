/* eslint-disable no-restricted-globals */
import { Component, Input } from '@angular/core';
import {
  SchemaProperty,
  VastDocumentResponse,
  VastSchemas,
  VastTypes,
} from '@expresssteuer/eric-lib-interfaces';
import { jsonToYaml } from './helper-functions/json2yaml';
import { transformDataToFollowSchema } from './helper-functions/transform-data-to-follow-schema';

@Component({
  selector: 'esui-vast-renderer',
  templateUrl: './esui-vast-renderer.component.html',
  styleUrls: ['./esui-vast-renderer.component.scss'],
})
export class EsuiVastRendererComponent {
  @Input()
  data: VastDocumentResponse[] | null | undefined;

  render(data: VastDocumentResponse[] | null | undefined): any {
    if (!data) return '';
    let result = '';

    const showIndex = data.length > 1;
    data.forEach((response, index) => {
      result +=
        (showIndex ? `${index + 1}. ` : '') +
        this.convertVastDocumentResponseToString(response) +
        '\n';
    });
    return result;
  }

  convertVastDocumentResponseToString(response: VastDocumentResponse) {
    const schema: SchemaProperty =
      VastSchemas[Object.keys(response)[0] as VastTypes];

    return jsonToYaml(transformDataToFollowSchema(response, schema));
  }
}
