import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { EsuiTaxIdValidatorModule } from '@expresssteuer/angular-validators';
import { EsFinanceOfficeModule } from '../es-finance-office/es-finance-office.module';
import { EsuiTaxadvisorModule } from '../esui-taxadvisor/esui-taxadvisor.module';
import { TaxsettingsComponent } from './taxsettings.component';

@NgModule({
  declarations: [TaxsettingsComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    EsuiTaxadvisorModule,
    EsFinanceOfficeModule,
    EsuiTaxIdValidatorModule,
  ],
  exports: [TaxsettingsComponent],
})
export class EsTaxsettingsModule {}
