import { Component, Input } from '@angular/core';
import { DropdownInputDirective } from './dropdown-input.directive';

@Component({
  selector: 'esui-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss'],
})
export class DropdownInputComponent extends DropdownInputDirective<
  string,
  string
> {
  protected data = '';

  isDisabled = false;

  @Input()
  options = new Map<string, string>([]);

  /**
   * @override
   */
  get empty(): boolean {
    return false; //is never empty
  }

  controlType = 'DropdownInputComponent';
  typeCast(val: any) {
    return val;
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elementRef.nativeElement.querySelector('input')?.focus();
    }
  }
}
