import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  ExtractionZipDownloadInput,
  ExtractionZipDownloadOutput,
} from '@expresssteuer/client-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class ExtractionZipDownload {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: ExtractionZipDownloadInput) {
    console.log('Calling client-extractionZipDownload');
    return this.afFunctions.httpsCallable<
      ExtractionZipDownloadInput,
      ExtractionZipDownloadOutput
    >('client-extractionZipDownload')(input);
  }
}
