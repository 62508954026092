import { EventEmitter, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Timestamp } from '@angular/fire/firestore';
import {
  BinaryDocument,
  BinaryDocumentMetadata,
  BinaryDocumentMetadataML,
  BinaryDocumentMetadataTypes,
} from '@expresssteuer/models';
import { combineLatest, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BusyManager } from '../../../app/shared/helpers/busymanager';

export enum TYPE {
  IMAGE = 'image',
  PDF = 'pdf',
  AUDIO = 'audio',
  OTHER = 'other',
}

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  public set currentDocument(val: BinaryDocument) {
    if (!val.metadata) {
      val.metadata = BinaryDocumentMetadata.getTemplate();
    }
    if (!val.metadata.mldata) {
      val.metadata.mldata = BinaryDocumentMetadataML.getTemplate();
    }
    if (!val.metadata.type) {
      val.metadata.type = BinaryDocumentMetadataTypes.getTemplate();
    }

    this.aCurrentDocument = val;
  }
  public get currentDocument(): BinaryDocument {
    return this.aCurrentDocument;
  }

  public get type(): TYPE {
    switch (this.currentDocument.contentType) {
      case 'application/pdf':
        return TYPE.PDF;
      case 'audio/ogg':
        return TYPE.AUDIO;
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/png':
        return TYPE.IMAGE;

      default:
        return TYPE.OTHER;
    }
  }

  constructor(
    private db: AngularFirestore,
    private afStorage: AngularFireStorage
  ) {
    if (!this.documentsFiltered$) {
      this.filter.testtaxoffice = false;
      this.filter.taxoffice = false;
      this.filter.expense = true;
      this.filter.identitycard = false;
      this.filter.other = false;
      this.filter.receipt = false;
      this.filter.signature = false;
      this.filter.wagestatement = false;
      this.loadFilteredDocuments();
    }
  }

  public set limit(val: number) {
    if (this.dataLimit !== val) {
      this.dataLimit = val;
      this.loadFilteredDocuments();
    }
  }
  public get limit(): number {
    return this.dataLimit;
  }

  public busy: BusyManager = new BusyManager();

  // public currentDocumentUrl: Observable<any>;
  public documentsFiltered$: Observable<BinaryDocument[]>;
  public documentsClient$: Observable<
    (BinaryDocument & { fromClientDocuments: boolean })[]
  >;
  public documentsTaxCase$: Observable<any[]>;
  private aCurrentDocument: BinaryDocument;
  private dataLimit = 20;

  public filter: BinaryDocumentMetadataTypes =
    BinaryDocumentMetadataTypes.getTemplate();

  public isLoaded: EventEmitter<BinaryDocument> = new EventEmitter();

  public async delete(document: BinaryDocument) {
    if (document.id && document.id.length > 2) {
      await this.db
        .collection<BinaryDocument>('documentcenter')
        .doc(document.id)
        .delete();
    }
  }

  public loadFilteredDocuments(): void {
    let path = '';
    if (this.filter.expense) {
      path = 'metadata.type.expense';
    }
    if (this.filter.identitycard) {
      path = 'metadata.type.identitycard';
    }
    if (this.filter.receipt) {
      path = 'metadata.type.receipt';
    }
    if (this.filter.signature) {
      path = 'metadata.type.signature';
    }
    if (this.filter.wagestatement) {
      path = 'metadata.type.wagestatement';
    }
    if (this.filter.testtaxoffice) {
      path = 'metadata.type.testtaxoffice';
    }
    if (this.filter.taxoffice) {
      path = 'metadata.type.taxoffice';
    }

    console.log('load filtered documents', this.filter);

    if (this.filter.other) {
      this.documentsFiltered$ = this.db
        .collection<BinaryDocument>('documentcenter', (r) =>
          r.orderBy('uploaded', 'desc').limit(this.dataLimit)
        )
        .valueChanges();
    } else {
      this.documentsFiltered$ = this.db
        .collection<BinaryDocument>('documentcenter', (r) =>
          r
            .where(path, '==', true)
            .orderBy('uploaded', 'desc')
            .limit(this.dataLimit)
        )
        .valueChanges();
    }
  }

  public loadForClient(clientId: string) {
    const fromDocCenter$ = this.db
      .collection<BinaryDocument>('documentcenter', (ref) =>
        ref
          .where('metadata.clientId', '==', clientId)
          .orderBy('uploaded', 'desc')
          .limit(100)
      )
      .valueChanges()
      .pipe(
        map<
          BinaryDocument[],
          (BinaryDocument & { fromClientDocuments: boolean })[]
        >((docs) => docs.map((doc) => ({ ...doc, fromClientDocuments: false })))
      );

    const fromClient$ = this.db
      .collection('clients')
      .doc(clientId)
      .collection<BinaryDocument>('clientDocuments', (ref) =>
        ref.orderBy('uploaded', 'desc').limit(100)
      )
      .valueChanges()
      .pipe(
        tap(
          () => {},
          (err) => console.log('error loading clientDocuments', err)
        ),
        catchError(() => []),
        map<
          BinaryDocument[],
          (BinaryDocument & { fromClientDocuments: boolean })[]
        >((docs) => docs.map((doc) => ({ ...doc, fromClientDocuments: true })))
      );

    this.documentsClient$ = combineLatest([fromDocCenter$, fromClient$]).pipe(
      // concat docs from both collections:
      map((docSets) =>
        []
          .concat(...docSets)
          .sort((a, b) => +a.uploaded.toDate() - +b.uploaded.toDate())
      )
    );
  }

  public loadDocumentForTaxCase(taxCaseId: string) {
    this.documentsTaxCase$ = this.db
      .collection<BinaryDocument>('documentcenter', (ref) =>
        ref
          .where('metadata.taxcaseid', '==', taxCaseId)
          .orderBy('uploaded', 'desc')
          .limit(this.dataLimit)
      )
      .valueChanges()
      .pipe(
        map((collection) =>
          collection.reduce((reduced, item) => {
            let fileType = 'expense';

            if (item.metadata.type === undefined) {
              item.metadata.type = {
                other: true,
              } as any;
            }

            if (item.metadata.type.identitycard) {
              fileType = 'identitycard';
            }

            if (item.metadata.type.other) {
              fileType = 'other';
            }

            if (item.metadata.type.receipt) {
              fileType = 'receipt';
            }

            if (item.metadata.type.signature) {
              fileType = 'signature';
            }

            if (item.metadata.type.taxoffice) {
              fileType = 'taxoffice';
            }

            if (item.metadata.type.testtaxoffice) {
              fileType = 'testtaxoffice';
            }

            if (item.metadata.type.wagestatement) {
              fileType = 'wagestatement';
            }

            const otherItems = reduced.get(fileType) || [];
            return reduced.set(fileType, [...otherItems, item]);
          }, new Map())
        )
      )
      .pipe(
        map((reduced) => {
          return Array.from(reduced.keys(), (key) => ({
            type: key,
            files: reduced.get(key),
          }));
        })
      );
  }

  public async getDocument(
    fileId: string,
    collectionPath = 'documentcenter'
  ): Promise<BinaryDocument> {
    this.resetCurrentDocument();

    try {
      const document: BinaryDocument = (await (
        await this.db.collection(collectionPath).ref.doc(fileId).get()
      ).data()) as BinaryDocument;
      this.currentDocument = document;

      console.log('Storage Path ', document.file.filepath);
      console.log('document', this.currentDocument);

      this.isLoaded.emit(this.currentDocument);

      return document;
    } catch (err) {
      console.error('Could not laod document', err);
    }
  }

  public resetCurrentDocument() {
    // reset currentDocument for Modal, so it can show loading
    this.currentDocument = new BinaryDocument();
    this.isLoaded.emit(this.currentDocument);
  }

  public async update(document: BinaryDocument | null) {
    const updateDocument = document ? document : this.currentDocument;

    updateDocument.modified = Timestamp.now();

    await this.db
      .collection('documentcenter')
      .doc(updateDocument.id)
      .update(updateDocument);
  }

  /**
   * Load a document
   * @param id of the document
   * @param clientId when set, looking in the client's `clientDocuments`
   * rather than the default documentcenter.
   */
  public async load(id: string, clientId?: string): Promise<void> {
    // if (!this.currentDocument && this.currentDocument.id === id) {
    //   return;
    // }

    if (!id) {
      return;
    }
    const collectionPath = clientId
      ? `clients/${clientId}/clientDocuments`
      : undefined;
    this.currentDocument = await this.getDocument(id, collectionPath);
  }
}
