import {
  ForEachTaxcaseStatus,
  TAXCASESTATUS,
  TAXCASESTATUS_INPROGRESS,
  TAXCASESTATUS_REJECT,
} from './taxcase';

type mappedStatus =
  | 'unknown'
  | 'rejected'
  | 'retrievingVast'
  | 'internalProcessing'
  | 'allowingOptimizing'
  | 'secondInternalProcessing'
  | 'communication'
  | 'payout'
  | 'done';

export const taxcaseStatusMap: ForEachTaxcaseStatus<mappedStatus> = {
  [TAXCASESTATUS.ENTRY]: 'unknown',
  [TAXCASESTATUS.REJECT]: {
    [TAXCASESTATUS_REJECT.CUSTOMERDECLINED]: 'rejected',
    [TAXCASESTATUS_REJECT.DOUBLE]: 'rejected',
    [TAXCASESTATUS_REJECT.ERROR]: 'rejected',
    [TAXCASESTATUS_REJECT.FRAUD]: 'rejected',
    [TAXCASESTATUS_REJECT.NORESPONSE]: 'rejected',
    [TAXCASESTATUS_REJECT.OTHER]: 'rejected',
    [TAXCASESTATUS_REJECT.TEST]: 'rejected',
    [TAXCASESTATUS_REJECT.UNATTRACTIVE]: 'rejected',
    [TAXCASESTATUS_REJECT.WRONG_TARGETGROUP]: 'rejected',
  },
  [TAXCASESTATUS.INPROGRESS]: {
    [TAXCASESTATUS_INPROGRESS.NORESPONSE]: 'rejected',

    //unknown
    [TAXCASESTATUS_INPROGRESS.WAITINGFORTAXID]: 'unknown',
    [TAXCASESTATUS_INPROGRESS.PAYMENTNOTPOSSIBLE]: 'unknown',
    [TAXCASESTATUS_INPROGRESS.PAYMENTDECLINED]: 'unknown',

    //retrievingVast
    [TAXCASESTATUS_INPROGRESS.WAITINGDATA]: 'retrievingVast',
    [TAXCASESTATUS_INPROGRESS.WAITINGFORDATARETRIEVAL]: 'retrievingVast',
    [TAXCASESTATUS_INPROGRESS.WAITINGFORDATARETRIEVAL_CHANGES]:
      'retrievingVast',
    [TAXCASESTATUS_INPROGRESS.WAITINGFORDATARETRIEVAL_SENT]: 'retrievingVast',

    //internalProcessing
    [TAXCASESTATUS_INPROGRESS.WAITINGFORDATARETRIEVAL_RETURNED]:
      'internalProcessing',
    [TAXCASESTATUS_INPROGRESS.ELSTER_TEST_PREPARE]: 'internalProcessing',
    [TAXCASESTATUS_INPROGRESS.ELSTER_TEST_COMPLETE]: 'internalProcessing',
    [TAXCASESTATUS_INPROGRESS.ELSTER_TEST_ERROR]: 'internalProcessing',
    [TAXCASESTATUS_INPROGRESS.READY_FOR_CLIENT_APPROVAL]: 'internalProcessing',
    [TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_APPROVAL_PREPARE]:
      'internalProcessing',
    [TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_APPROVAL_ERROR]:
      'internalProcessing',

    //allowingOptimizing
    [TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_EDITS]: 'allowingOptimizing',
    [TAXCASESTATUS_INPROGRESS.WAITING_FOR_CLIENT_APPROVAL]:
      'allowingOptimizing',

    //secondInternalProcessing
    [TAXCASESTATUS_INPROGRESS.ELSTER_PROD_PREPARE]: 'secondInternalProcessing',
    [TAXCASESTATUS_INPROGRESS.ELSTER_PROD_ERROR]: 'secondInternalProcessing',
    [TAXCASESTATUS_INPROGRESS.READYTOSEND]: 'secondInternalProcessing',
    [TAXCASESTATUS_INPROGRESS.READYTOSENDWITHERROR]: 'secondInternalProcessing',

    //communication
    [TAXCASESTATUS_INPROGRESS.SENTTOTAXOFFICE]: 'communication',
    [TAXCASESTATUS_INPROGRESS.QUESTIONSFROMTAXOFFICE]: 'communication',
    [TAXCASESTATUS_INPROGRESS.RETURNEDFROMTAXOFFICE]: 'communication',
    [TAXCASESTATUS_INPROGRESS.APPEALED]: 'communication',
    [TAXCASESTATUS_INPROGRESS.WAITINGFORMONEY]: 'communication',

    //payout
    [TAXCASESTATUS_INPROGRESS.SEPA]: 'payout',
    [TAXCASESTATUS_INPROGRESS.SEPAPROCESSED]: 'payout',
    [TAXCASESTATUS_INPROGRESS.MONEYRECIEVED]: 'payout',

    //done
    [TAXCASESTATUS_INPROGRESS.PAID]: 'done',
  },
  [TAXCASESTATUS.DONE]: 'done',
};
