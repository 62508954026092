<div
  class="sidebar sidebar-left d-flex flex-row-auto flex-column"
  id="kt_sidebar"
>
  <!--begin::Aside Secondary Header-->

  <!--end::Aside Secondary Header-->
  <!--begin::Aside Secondary Content-->
  <div class="sidebar-content flex-column-fluid pb-10 pt-9 px-5 px-lg-10">
    <!--begin: Stats Widget 19
        <div class="card card-custom bg-light-success card-shadowless gutter-b">-->
    <!--begin::Body
            <div class="card-body my-3">
                <a href="#"
                    class="card-title font-weight-bolder text-success text-hover-state-dark font-size-h6 mb-4 d-block">SAP
                    UI Progress</a>
                <div class="font-weight-bold text-muted font-size-sm">
                    <span class="text-dark-75 font-size-h2 font-weight-bolder mr-2">67%</span>Average</div>
                <div class="progress progress-xs mt-7 bg-success-o-60">
                    <div class="progress-bar bg-success" role="progressbar" style="width: 67%;"
                        aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>-->
    <!--end:: Body
        </div>-->
    <!--end: Stats:Widget 19-->
    <!--begin::Stats Widget 20
        <div class="card card-custom bg-light-warning card-shadowless gutter-b">-->
    <!--begin::Body
            <div class="card-body my-4">
                <a href="#"
                    class="card-title font-weight-bolder text-warning font-size-h6 mb-4 text-hover-state-dark d-block">Airplus
                    Budget</a>
                <div class="font-weight-bold text-muted font-size-sm">
                    <span class="text-dark-75 font-weight-bolder font-size-h2 mr-2">87K%</span>23k to goal</div>
                <div class="progress progress-xs mt-7 bg-warning-o-60">
                    <div class="progress-bar bg-warning" role="progressbar" style="width: 87%;"
                        aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>-->
    <!--end::Body
        </div>-->
    <!--end::Stats Widget 20-->
    <!--begin::List Widget 4-->
    <div class="card card-custom card-shadowless gutter-b bg-light">
      <!--begin::Header-->
      <div class="card-header border-0">
        <h3 class="card-title font-weight-bolder text-dark">Meine Todos</h3>
        <div class="card-toolbar">
          <div class="dropdown dropdown-inline">
            <a
              href="#"
              class="btn btn-light btn-sm font-size-sm font-weight-bolder dropdown-toggle text-dark-75"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              >Neu</a
            >
            <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <!--begin::Navigation-->
              <ul class="navi navi-hover">
                <li class="navi-header pb-1">
                  <span
                    class="text-primary text-uppercase font-weight-bold font-size-sm"
                    >Neues Todo</span
                  >
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-icon">
                      <i class="flaticon2-phone"></i>
                    </span>
                    <span class="navi-text">An/Rückruf</span>
                  </a>
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-icon">
                      <i class="flaticon2-calendar-8"></i>
                    </span>
                    <span class="navi-text">Erinnerung</span>
                  </a>
                </li>
              </ul>
              <!--end::Navigation-->
            </div>
          </div>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-2">
        <!--begin::Item-->
        <div class="d-flex align-items-center mb-10">
          <!--begin::Bullet-->
          <span class="bullet bullet-bar bg-success align-self-stretch"></span>
          <!--end::Bullet-->
          <!--begin::Checkbox-->
          <label
            class="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4"
          >
            <input type="checkbox" name="select" value="1" />
            <span></span>
          </label>
          <!--end::Checkbox-->
          <!--begin::Text-->
          <div class="d-flex flex-column flex-grow-1">
            <a
              href="#"
              class="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
              >Create FireStone Logo</a
            >
            <span class="text-muted font-weight-bold">Due in 2 Days</span>
          </div>
          <!--end::Text-->
          <!--begin::Dropdown-->
          <div
            class="dropdown dropdown-inline ml-2"
            data-toggle="tooltip"
            title="Quick actions"
            data-placement="left"
          >
            <a
              href="#"
              class="btn btn-hover-light-primary btn-sm btn-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="ki ki-bold-more-hor"></i>
            </a>
            <div
              class="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right"
            >
              <!--begin::Navigation-->
              <ul class="navi navi-hover">
                <li class="navi-header font-weight-bold py-4">
                  <span class="font-size-lg">Choose Label:</span>
                  <i
                    class="flaticon2-information icon-md text-muted"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Click to learn more..."
                  ></i>
                </li>
                <li class="navi-separator mb-3 opacity-70"></li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-text">
                      <span
                        class="label label-xl label-inline label-light-success"
                        >Customer</span
                      >
                    </span>
                  </a>
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-text">
                      <span
                        class="label label-xl label-inline label-light-danger"
                        >Partner</span
                      >
                    </span>
                  </a>
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-text">
                      <span
                        class="label label-xl label-inline label-light-warning"
                        >Suplier</span
                      >
                    </span>
                  </a>
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-text">
                      <span
                        class="label label-xl label-inline label-light-primary"
                        >Member</span
                      >
                    </span>
                  </a>
                </li>
                <li class="navi-item">
                  <a href="#" class="navi-link">
                    <span class="navi-text">
                      <span class="label label-xl label-inline label-light-dark"
                        >Staff</span
                      >
                    </span>
                  </a>
                </li>
                <li class="navi-separator mt-3 opacity-70"></li>
                <li class="navi-footer py-4">
                  <a class="btn btn-clean font-weight-bold btn-sm" href="#">
                    <i class="ki ki-plus icon-sm"></i>Add new</a
                  >
                </li>
              </ul>
              <!--end::Navigation-->
            </div>
          </div>
          <!--end::Dropdown-->
        </div>
        <!--end:Item-->
      </div>
      <!--end::Body-->
    </div>
    <!--end:List Widget 4-->

    <!--end::List Widget 1-->
  </div>
  <!--end::Aside Secondary Content-->
</div>
