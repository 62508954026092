import { Directive, HostBinding } from '@angular/core';

/**
 * Add a label/key to a card on the top right
 */
@Directive({
  selector: '[esuiCardKey]',
})
export class CardKeyDirective {
  @HostBinding('style.position')
  position = 'absolute';

  @HostBinding('style.right')
  right = '1em';

  @HostBinding('style.color')
  color = 'rgba(0, 0, 0, 0.54)';

  @HostBinding('style.font-size')
  fontSize = '0.9em';

  @HostBinding('style.padding-left')
  paddingLeft = '0.5em';

  @HostBinding('style.padding-bottom')
  paddingBottom = '0.5em';

  @HostBinding('style.background-color')
  backgroundColor = 'rgba(255, 255, 255, 0.8)';
}
