<div
  class="wrapper"
  *ngFor="let i of tasksOnScreen | keyvalue; trackBy: trackByStringified"
>
  <esui-user-icon class="icon" [user]="i.value.user"></esui-user-icon>
  <div class="content">
    <span class="name">{{ i.value.user?.displayName }}</span>
    <mat-chip-list aria-label="sessions">
      <ng-container
        *ngFor="
          let session of i.value.sessions | keyvalue;
          trackBy: trackByStringified
        "
      >
        <ng-container
          *ngFor="
            let taskId of session.value.taskIds;
            trackBy: trackByStringified
          "
        >
          <a [href]="taskInTaskIslandUrl(taskId)" target="_blank">
            <mat-chip
              class="sessions"
              [ngStyle]="{
                backgroundColor: colorFor(taskId)
              }"
            >
              <mat-chip-avatar>
                <mat-icon>task</mat-icon>
              </mat-chip-avatar>
              <span class="taskId">{{ taskId }}</span>
            </mat-chip>
          </a>
        </ng-container>
      </ng-container>
    </mat-chip-list>
  </div>
</div>
