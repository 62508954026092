import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  BinaryDocument,
  BinaryDocumentMetadata,
  DOCUMENTTYPE,
} from '@expresssteuer/models';

@Component({
  selector: 'app-document-category',
  templateUrl: './document-category.component.html',
  styleUrls: ['./document-category.component.scss'],
})
export class DocumentCategoryComponent implements OnInit {
  constructor() {}

  DOCUMENTTYPE = DOCUMENTTYPE;

  @Output()
  public documentChange = new EventEmitter<BinaryDocument>();
  private mDocument: BinaryDocument;

  @Input()
  public get document(): BinaryDocument {
    return this.mDocument;
  }

  public set document(val: BinaryDocument) {
    if (!val.metadata) {
      val.metadata = BinaryDocumentMetadata.getTemplate();
      val.metadata.type = null;
    }

    this.mDocument = val;
    this.documentChange.emit(val);
  }

  ngOnInit(): void {}
}
