import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { TaxCalculationComponent } from './tax-calculation.component';

@NgModule({
  declarations: [TaxCalculationComponent],
  exports: [TaxCalculationComponent],
  imports: [CommonModule, MatTableModule],
})
export class EsuiTaxCalculationModule {}
