<mat-card>
  <mat-card-content class="filter-holder">
    <mat-radio-group
      class="radio-holder"
      aria-label="Select an option"
      [value]="selectedCategory"
      (change)="categorySelected.next($event.value)"
    >
      <mat-radio-button value="unfulfilled">Unfulfilled</mat-radio-button>
      <mat-radio-button value="fulfilled">Fulfilled</mat-radio-button>
      <mat-radio-button value="done">Done</mat-radio-button>
    </mat-radio-group>
    Anzahl: {{ countOfCategory ?? 'Unbekannt' }}
  </mat-card-content>
</mat-card>
<table
  mat-table
  [dataSource]="list$ ?? []"
  matSort
  matSortActive="created"
  matSortDisableClear
  matSortDirection="desc"
  #sort="matSort"
  (matSortChange)="handleSortChange($event)"
  [matSortDirection]="sort?.direction ?? ''"
  [matSortActive]="sort?.active ?? ''"
>
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID und Zuweisung</th>
    <td mat-cell *matCellDef="let row">
      <p>{{ row.id }}</p>
      <p>
        Bearbeiter:
        <mat-chip>{{ row.assignedOperatorEmail ?? 'Ohne Zuweisung' }}</mat-chip>
      </p>
    </td>
  </ng-container>

  <ng-container matColumnDef="clientData">
    <th mat-header-cell *matHeaderCellDef>Client</th>
    <td mat-cell *matCellDef="let row">
      <p>{{ row.clientData.firstname }} {{ row.clientData.lastname }}</p>
      <p>{{ row.clientData.email }}</p>
    </td>
  </ng-container>

  <ng-container matColumnDef="dueDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
      due date
    </th>
    <td mat-cell *matCellDef="let row">
      <p *ngIf="row.dueDate">{{ row.dueDate | asDate | date }}</p>
      <p *ngIf="row.dueDate">
        {{
          getDaysUntilDue
            | pureMethod
              : {
                  args: [row.dueDate],
                  thisArg: row.dueDate
                }
        }}
        days
      </p>
    </td>
  </ng-container>

  <ng-container matColumnDef="containedUniqueTags">
    <th mat-header-cell *matHeaderCellDef>Tags</th>
    <td mat-cell *matCellDef="let row">
      <mat-chip-list class="tag-list">
        <mat-chip
          *ngFor="
            let tag of uniqueTagsToStringArray
              | pureMethod
                : {
                    args: [row.containedUniqueTags],
                    thisArg: row
                  }
          "
          >{{ tag }}</mat-chip
        >
      </mat-chip-list>
    </td>
  </ng-container>

  <ng-container matColumnDef="reminderCount">
    <th mat-header-cell *matHeaderCellDef>reminder count</th>
    <td mat-cell *matCellDef="let row">{{ row.reminderCount }}</td>
  </ng-container>

  <ng-container matColumnDef="extendedDueDateCount">
    <th mat-header-cell *matHeaderCellDef>extended due date count</th>
    <td mat-cell *matCellDef="let row">{{ row.extendedDueDateCount }}</td>
  </ng-container>

  <ng-container matColumnDef="fullFilled">
    <th mat-header-cell *matHeaderCellDef>fulfilled</th>
    <td mat-cell *matCellDef="let row">{{ row.fullFilled }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    [class.fulfilled]="row.fullFilled === true"
    [class.clickable]="rowClick.observers.length > 0"
    (click)="rowClick.next({ clientId: row.clientId, requestId: row.id })"
  ></tr>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">no data</td>
  </tr>
</table>

<esui-firestore-paginator
  [ahead]="currentLookAheadCount"
  [nextImpossible]="(currentLookAheadCount ?? 0) === 0"
  [backImpossible]="(currentLookBehindCount ?? 0) === 0"
  [pageSize]="pageSize"
  (pageSizeChange)="pageSizeChange.next($event)"
  (nextClick)="nextClick.next($event)"
  (previousClick)="previousClick.next($event)"
  (startClick)="startClick.next($event)"
>
</esui-firestore-paginator>
