import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { EsuiDocumentUploaderComponent } from './esui-document-uploader.component';

@NgModule({
  declarations: [EsuiDocumentUploaderComponent],
  imports: [
    CommonModule,
    NgxDropzoneModule,
    MatButtonModule,
    TranslateModule.forRoot(),
    MatIconModule,
    MatProgressSpinnerModule,
    TextFieldModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSnackBarModule,
  ],
  exports: [EsuiDocumentUploaderComponent],
})
export class EsuiDocumentUploaderModule {}
