<app-ais-documents-icon [mimeType]="hit.contentType"></app-ais-documents-icon>
<td colspan="2" class="text-truncate" style="max-width: 300px">
  <a
    *ngIf="!disabled"
    [routerLink]="linkToItem"
    class="text-dark text-hover-primary mb-1 font-size-lg"
  >
    <ais-highlight attribute="name" [hit]="hit"></ais-highlight>
  </a>
  <ais-highlight *ngIf="disabled" attribute="name" [hit]="hit"></ais-highlight>
</td>
<td>
  {{ hit.metadata.type?.expense ? 'Ausgaben' : '' }}
  {{ hit.metadata.type?.identitycard ? 'Ausweis' : '' }}
  {{ hit.metadata.type?.other ? 'Sonstiges' : '' }}
  {{ hit.metadata.type?.receipt ? 'Rechnung' : '' }}
  {{ hit.metadata.type?.signature ? 'Unterschrift' : '' }}
  {{ hit.metadata.type?.taxoffice ? 'Elster-Finanzamt' : '' }}
  {{ hit.metadata.type?.testtaxoffice ? 'Elster-Test' : '' }}
  {{ hit.metadata.type?.wagestatement ? 'Lohnabrechnung' : '' }}
</td>
<td>{{ uploadedDate | timeago }} ({{ hit.uploaded }})</td>
<td>{{ hit.metadata.client.displayName }}</td>
<ng-template #empty><span>--</span></ng-template>
