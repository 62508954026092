export enum ERRORCODES {
  /**
   * The To Address could not be found
   */
  'NOTFOUND' = '63003',
  /**
   * The Template is temporarly paused
   */
  'TEMPLATE_PAUSED' = '63041',
}

/**
 * The response from Twilio over the webhook
 */
export interface TwilioGridNotification {
  AccountSid: string;
  ApiVersion: string;
  ChannelInstallSid: string;
  ChannelPrefix: string;
  ChannelToAddress: string;
  ErrorCode: string;
  EventType: string;
  From: string;
  MessageSid: string;
  MessageStatus: string;
  SmsSid: string;
  SmsStatus: string;
  To: string;
}
