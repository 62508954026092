import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdditionalExpense, Campaign } from '@expresssteuer/models';
import { TaxcasesService } from '../../../../app/services/data/taxcases.service';
import { CampaignsService } from '../../../services/data/campaigns.service';

@Component({
  selector: 'app-control-campaign-dropdown',
  templateUrl: './control-campaign.component.html',
  styleUrls: ['./control-campaign.component.scss'],
})
export class ControlCampaignComponent implements OnInit {
  @Output()
  public Change = new EventEmitter<Campaign>();

  @Input()
  public name: string;

  campaignValue: Campaign;
  campaignIdValue: string;

  // Campaign
  @Output()
  campaignChange = new EventEmitter<Campaign>();

  @Input()
  get campaign(): Campaign {
    return this.campaignValue;
  }

  set campaign(val: Campaign) {
    if (this.campaignValue) {
      this.Change.emit(this.campaignValue);
    }
    this.campaignValue = val;
    this.campaignChange.emit(this.campaignValue);

    try {
      this.taxcaseservice.currentTaxCase.taxform.current.taxForm.metadata.campaign.id =
        val.id;
      this.taxcaseservice.currentTaxCase.taxform.current.taxForm.metadata.campaign.promoCode =
        val.promoCode;
    } catch (err) {
      // console.error(err);
    }
  }

  public compare(a: AdditionalExpense, b: AdditionalExpense) {
    if (a !== null && b !== null) {
      return a.id === b.id;
    } else {
      return false;
    }
  }

  constructor(
    public campaignsservice: CampaignsService,
    public taxcaseservice: TaxcasesService
  ) {}

  ngOnInit(): void {}
}
