import { Directive } from '@angular/core';
import { NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { validateTaxid } from '@expresssteuer/tax-utilities';

export interface TaxIdError extends ValidationErrors {
  invalidTaxId: boolean;
}

@Directive({
  selector: '[esuiValidTaxId]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EsuiTaxIdValidatorDirective,
      multi: true,
    },
  ],
})
export class EsuiTaxIdValidatorDirective implements Validator {
  validate(control: { value: string | null }): TaxIdError | null {
    return control.value
      ? validateTaxid(control.value)
        ? null
        : { invalidTaxId: true }
      : null;
  }
}
