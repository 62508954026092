/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { InboxSender } from '@expresssteuer/models';

@Component({
  selector: 'app-message-user-composer',
  templateUrl: './message-user-composer.component.html',
  styleUrls: ['./message-user-composer.component.scss'],
})
export class MessageUserComponent {
  @Input()
  sender: InboxSender;
}
