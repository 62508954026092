<div class="form">
  <div class="section">
    <esui-document-type-select
      [documentType]="validationResult?.type ?? undefined"
      (documentTypeSelect)="propChange({ type: $event })"
    >
    </esui-document-type-select>
    <mat-checkbox
      [checked]="validationResult?.isTypeCorrect ?? false"
      [disabled]="true"
    >
      Ist der Dokumentenart korrekt? (automatisch)
    </mat-checkbox>
  </div>
  <div class="section">
    <mat-form-field>
      <mat-label>Vorname</mat-label>
      <mat-hint>
        (vgl. zum CRM bzw. Sign-Up Funnel: "{{ firstnameExpected }}")
      </mat-hint>
      <input
        matFormFieldControl
        type="text"
        matInput
        [ngModel]="validationResult?.firstname ?? ''"
        (ngModelChange)="propChange({ firstname: $event })"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Nachname</mat-label>
      <mat-hint>
        (vgl. zum CRM bzw. Sign-Up Funnel: "{{ lastnameExpected }}")
      </mat-hint>
      <input
        matFormFieldControl
        type="text"
        matInput
        [ngModel]="validationResult?.lastname ?? ''"
        (ngModelChange)="propChange({ lastname: $event })"
      />
    </mat-form-field>
    <mat-checkbox
      [checked]="validationResult?.isNameCorrect ?? false"
      [disabled]="true"
    >
      Ist der Name korrekt? (automatisch)
    </mat-checkbox>
  </div>
  <mat-form-field>
    <mat-label>Ablaufdatum</mat-label>
    <esui-date-input
      [ngModel]="validationResult?.expirationDateTimestamp"
      (ngModelChange)="propChange({ expirationDateTimestamp: $event })"
    ></esui-date-input>
  </mat-form-field>
  <br />
  <mat-checkbox
    [checked]="validationResult?.isValidExpirationDate ?? false"
    [disabled]="true"
  >
    Ist das Ablaufdatum gültig? (automatisch)
  </mat-checkbox>
  <span class="hint">
    Auch abgelaufene Ausweise werden akzeptiert. Siehe
    https://app.clickup.com/t/861mceyw5
  </span>
</div>
