import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { EsuiDocumentViewerModule } from '../esui-document-viewer/esui-document-viewer.module';
import { EsuiAppealSubmitComponent } from './esui-appeal-submit.component';

@NgModule({
  declarations: [EsuiAppealSubmitComponent],
  imports: [
    CommonModule,
    MatCardModule,
    EsuiDocumentViewerModule,
    MatButtonModule,
  ],
  exports: [EsuiAppealSubmitComponent],
})
export class EsuiAppealSubmitModule {}
