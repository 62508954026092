import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DOCUMENTSOURCE, TaxCase, TaxcaseComment } from '@expresssteuer/models';
import { DisplayItem } from '../esui-generic-list/esui-generic-list.component';
import { TaxCalculationRow } from './components/tax-calculation.component';
import { convertFlagsToDisplayItems } from './helper/flag-display-converter';

@Component({
  selector: 'esui-final-taxcase-review-v2',
  templateUrl: './esui-final-taxcase-review-v2.component.html',
  styleUrls: ['./esui-final-taxcase-review-v2.component.scss'],
})
export class EsuiFinalTaxcaseReviewV2Component {
  convertFlagsToDisplayItems = convertFlagsToDisplayItems;

  @Input()
  taxcase?: TaxCase | null;
  @Input()
  taxcaseHasOnlineChangedAfterLocalChanged?: boolean | null;
  @Input()
  taxcaseIsSaving?: boolean | null;
  @Input()
  taxcaseIsLoading?: boolean | null;
  @Input()
  taxcaseHasChanges?: boolean | null;
  @Input()
  taxcaseHasErrors?: boolean | null;

  @Input() enableActionButtons?: boolean | null;

  @Input() documentDownloadUrl?: string | null;
  @Input() documentContentType?: string | null;
  @Input() documentSource?: DOCUMENTSOURCE | null;
  @Input()
  documentHasOnlineChangedAfterLocalChanged?: boolean | null;
  @Input()
  documentIsSaving?: boolean | null;
  @Input()
  documentIsLoading?: boolean | null;
  @Input()
  documentHasChanges?: boolean | null;
  @Input()
  documentHasErrors?: boolean | null;

  @Output()
  submitTaskClick = new EventEmitter<void>();
  @Output()
  moreWorkNeededClick = new EventEmitter<void>();
  @Output()
  unattractiveClick = new EventEmitter<void>();

  @Input()
  taxcaseComments?: TaxcaseComment[] | null;
  @Input()
  showComment?: boolean | null;
  @Input()
  comment?: string | null;
  @Output()
  commentChange = new EventEmitter<string>();
  @Output()
  showCommentClick = new EventEmitter<void>();

  @Input()
  taxCalculationExpectedReturn: number | null | undefined;

  @Input()
  taxCalculationRows: TaxCalculationRow[] | null | undefined;

  @Input()
  taxStatementItems: DisplayItem[] | null | undefined;

  @Input()
  plausibilityCheckReportSuccessItems: DisplayItem[] | null | undefined;
  @Input()
  plausibilityCheckReportErrorItems: DisplayItem[] | null | undefined;

  public unattractiveConfirmationNeeded = true;
  cancelUnattractive() {
    this.unattractiveConfirmationNeeded = true;
  }
  unattractive() {
    if (this.unattractiveConfirmationNeeded) {
      this.unattractiveConfirmationNeeded = false;
      return;
    }
    this.unattractiveConfirmationNeeded = true;
    this.unattractiveClick.next();
  }
}
