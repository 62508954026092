import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { HotkeysStore } from './hotkeys.store';

@Injectable()
export class HotkeysQuery {
  constructor(private hotkeysStore: HotkeysStore) {}

  search$ = this.hotkeysStore.pipe(map((e) => e.search));

  filteredHotkeys$ = this.hotkeysStore.pipe(
    map(({ search, hotkeys }) => {
      if (!search) {
        return hotkeys;
      }
      return new Map(
        [...hotkeys].filter(([hotkey]) => {
          return matchSearch(search, hotkey);
        })
      );
    })
  );
}

function matchSearch(
  search: string,
  hotkey: {
    // ignored linting to match `Hotkey`'s interface
    // eslint-disable-next-line @typescript-eslint/ban-types
    description?: string | Function;
    action?: string;
  }
) {
  if (hotkey.action?.includes(search)) {
    return true;
  }
  if (typeof hotkey.description === 'function') {
    const desc = hotkey.description();
    if (typeof desc !== 'string') {
      return false;
    }
    return desc.includes(search);
  }
  if (typeof hotkey.description === 'string') {
    return hotkey.description.includes(search);
  }
  return false;
}
