import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AnchorMenuComponent } from './anchor-menu.component';
import { AnchorTabsComponent } from './anchor-tabs.component';

@NgModule({
  declarations: [AnchorMenuComponent, AnchorTabsComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    ClipboardModule,
    MatListModule,
    RouterModule,
  ],
  exports: [AnchorMenuComponent, AnchorTabsComponent],
})
export class EsAnchorMenuModule {}
