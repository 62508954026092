/* eslint-disable @typescript-eslint/ban-types */
//TODO maybe use type-fest

import {
  ElsterResponse,
  ELSTERRESPONSESTATUS,
  ELSTERRESPONSETYPE,
} from './elsterResponse';
import {
  DATEFORMAT,
  ElsterDate,
  ElsterNumber,
  ElsterSpecial,
} from './elsterutils';
import { Field } from './field';

export class TransferHeader {
  public '@': { version: '11' } = { version: '11' };
  public Verfahren = 'ElsterErklaerung';
  public DatenArt = 'ESt';
  public Vorgang = 'send-Auth';
  public Testmerker? = '700000004';
  public HerstellerID = '21955';
  public DatenLieferant = 'Softwaretester ERiC';
  public Datei = {
    Verschluesselung: 'CMSEncryptedData',
    Kompression: 'GZIP',
  };
}

export class Vorsatz {
  public static getTemplate(): Vorsatz {
    return {
      '@': { unterfallart: '10', ordNrArt: 'S', vorgang: '01' },
      StNr: '',
      ID: '',
      IDEhefrau: '',
      Zeitraum: '',
      Erstelldatum: '',
      Erstellzeit: '',
      AbsName: '',
      AbsStr: '',
      AbsPlz: '',
      AbsOrt: '',
      Copyright: '',
      Rueckuebermittlung: { '@': { bescheid: 'nein' } },
    };
  }
  public '@' = { unterfallart: '10', ordNrArt: 'S', vorgang: '01' };
  public StNr? = '';
  public ID? = '';
  public IDEhefrau? = '';
  public Zeitraum = '';
  public Erstelldatum = '';
  public Erstellzeit = '';
  public AbsName = '';
  public AbsStr = '';
  public AbsPlz = '';
  public AbsOrt = '';
  public Copyright = '';
  public Rueckuebermittlung = { '@': { bescheid: 'nein' } };
}

export abstract class Jahressteuererklaerung {
  public static getTemplate(): Jahressteuererklaerung {
    return {
      '@': { version: '2' },
      Vorsatz: Vorsatz.getTemplate(),
      Feld: [],
    };
  }
  public abstract '@': { version: '2' };
  public abstract Vorsatz: Vorsatz;
  public abstract Feld: Object[];
}

export abstract class NutzerDaten {
  public static getTemplate(): NutzerDaten {
    return {
      Jahressteuererklaerung: Jahressteuererklaerung.getTemplate(),
      Feld: [],
    };
  }
  public abstract Jahressteuererklaerung: Jahressteuererklaerung;
  public abstract Feld: Object[];
}

export abstract class ElsterDocument {
  public static getTemplate(): ElsterDocument {
    return {
      Elster: {
        '@': { xmlns: 'http://www.elster.de/elsterxml/schema/v11' },
        TransferHeader: {
          '@': { version: '11' },
          Verfahren: 'ElsterErklaerung',
          DatenArt: 'ESt',
          Vorgang: 'send-Auth',
          Testmerker: '700000004',
          HerstellerID: '21955',
          DatenLieferant: 'Softwaretester ERiC',
          Datei: {
            Verschluesselung: 'CMSEncryptedData',
            Kompression: 'GZIP',
          },
        },
        DatenTeil: {
          Nutzdatenblock: {
            NutzdatenHeader: {
              '@': { version: '11' },
              NutzdatenTicket: 'adfjasldkfuweor456asvs',
              Empfaenger: { '#text': '9198', '@': { id: 'F' } },
              Hersteller: {
                ProduktName: 'ERICTest',
                ProduktVersion: '2009.1/0',
              },
              DatenLieferant: 'Softwaretester ERiC',
            },
            Nutzdaten: NutzerDaten.getTemplate(),
          },
        },
      },
    };
  }

  public abstract Elster: {
    '@': { xmlns: 'http://www.elster.de/elsterxml/schema/v11' };
    TransferHeader: TransferHeader;
    DatenTeil: {
      Nutzdatenblock: {
        NutzdatenHeader: {
          '@': { version: '11' };
          NutzdatenTicket: string;
          Empfaenger: { '#text': string; '@': { id: string } };
          Hersteller: {
            ProduktName: string;
            ProduktVersion: string;
          };
          DatenLieferant: string;
        };
        Nutzdaten: NutzerDaten;
      };
    };
  };
}

export {
  Field,
  ElsterDate,
  DATEFORMAT,
  ElsterNumber,
  ElsterSpecial,
  ELSTERRESPONSESTATUS,
  ELSTERRESPONSETYPE,
  ElsterResponse,
};
