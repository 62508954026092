import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  TaxAdditionType,
  TaxDeductionType,
  TaxNotice,
} from '@expresssteuer/models';

@Component({
  selector: 'esui-tax-notice',
  templateUrl: './esui-tax-notice.component.html',
  styleUrls: ['./esui-tax-notice.component.scss'],
})
export class EsuiTaxNoticeComponent {
  @Input() taxNotice?: TaxNotice | null;
  /**
   * If the taxYear should be editable in the form
   */
  @Input()
  taxYearEditable = false;
  /**
   * Disable all controls
   */
  @Input()
  disabled = false;
  @Output() taxNoticeChange = new EventEmitter<TaxNotice>();
  @Output() taxNoticePartialChange = new EventEmitter<Partial<TaxNotice>>();

  taxnoticeAdditionEnum = TaxAdditionType;

  taxnoticeDeductionEnum = TaxDeductionType;

  partialChange<K extends keyof TaxNotice>(key: K, value: TaxNotice[K]): void;
  partialChange(change: Partial<TaxNotice>): void;
  partialChange<K extends keyof TaxNotice>(
    a: Partial<TaxNotice> | K,
    b?: TaxNotice[K]
  ): void {
    const change =
      typeof a === 'string'
        ? {
            [a]: b,
          }
        : a;

    const merged = {
      ...this.taxNotice, // ?? Template // TODO
      ...change,
    };
    this.taxNoticePartialChange.next(change);
    this.taxNoticeChange.next(
      merged as any // TODO
    );
  }

  calculateTaxnoticeTransferAmount(): number {
    const toSubtract =
      this.taxNotice?.deductions?.reduce((acc, curr) => acc + curr.value, 0) ??
      0;
    const toAdd =
      this.taxNotice?.additions?.reduce((acc, curr) => acc + curr.value, 0) ??
      0;
    return (this.taxNotice?.actualTaxReturn ?? 0) - toSubtract + toAdd;
  }
}
