import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  GetBankInfoInput,
  GetBankInfoOutput,
} from '@expresssteuer/iban-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class GetBankInfo {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: GetBankInfoInput) {
    console.log('Calling iban-getBankInfo');
    return this.afFunctions.httpsCallable<GetBankInfoInput, GetBankInfoOutput>(
      'iban-getBankInfo'
    )(input);
  }
}
