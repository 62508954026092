import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { EsuiFirestorePaginatorModule } from '../esui-firestore-paginator/esui-firestore-paginator.module';
import { PureMethodModule } from '../pipes/pure-method/pure-method.module';
import { TimestampModule } from '../pipes/timestamp/timestamp.module';
import { EsuiTaxOfficeDeadlinesFilterComponent } from './esui-tax-office-deadlines-filter.component';

@NgModule({
  declarations: [EsuiTaxOfficeDeadlinesFilterComponent],
  imports: [
    CommonModule,
    MatTableModule,
    TimestampModule,
    EsuiFirestorePaginatorModule,
    MatSortModule,
    PureMethodModule,
    MatRadioModule,
    MatCardModule,
    MatChipsModule,
  ],
  exports: [EsuiTaxOfficeDeadlinesFilterComponent],
})
export class EsuiTaxOfficeDeadlinesFilterModule {}
