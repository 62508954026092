import { Component, forwardRef, Inject, OnInit, Optional } from '@angular/core';
import {
  BaseWidget,
  NgAisIndex,
  NgAisInstantSearch,
} from 'angular-instantsearch';
import { connectClearRefinements } from 'instantsearch.js/es/connectors';

@Component({
  selector: 'app-ais-documents-clear-refinements',
  templateUrl: './ais-documents-clear-refinements.component.html',
  styleUrls: ['./ais-documents-clear-refinements.component.scss'],
})
export class AisDocumentsClearRefinementsComponent
  extends BaseWidget
  implements OnInit
{
  public state: {
    hasRefinements: boolean;
    refine: () => void;
    createURL: Function;
    widgetParams: object;
  };
  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('AisDocumentsClearRefinementsComponent');
  }
  ngOnInit() {
    this.createWidget(connectClearRefinements, {
      // instance options
    });
    super.ngOnInit();
  }
}
