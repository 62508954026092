export interface SubmitManualValidationResultInput {
  clientId: string;
  todoId: string;
  answerId: string;
  validationResult: unknown;
}

export abstract class SubmitManualValidationResultInput {
  public static guard(
    input: unknown
  ): input is SubmitManualValidationResultInput {
    const i = input as SubmitManualValidationResultInput;
    return (
      typeof i === 'object' &&
      typeof i.clientId === 'string' &&
      typeof i.todoId === 'string' &&
      typeof i.answerId === 'string'
    );
  }
}

export type SubmitManualValidationResultOutput = void;
