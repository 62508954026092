import { Component, Input } from '@angular/core';

@Component({
  selector: 'esui-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss'],
})
export class UserIconComponent {
  @Input() user?: Partial<{ photoUrl: string | null }> | null;
}
