<ais-instantsearch
  *ngIf="configTaxcases$ | async as configTaxcases; else loading"
  [config]="configTaxcases"
>
  <ais-configure [searchParameters]="searchParameters"></ais-configure>
  <div class="d-flex flex-row">
    <div
      class="fflex-column offcanvas-mobile col-sm-3 pr-0"
      id="kt_profile_aside"
    >
      <div class="card card-custom gutter-b">
        <div class="card-body">
          <form>
            <div class="form-group mb-5" *ngIf="auth.user">
              <label class="font-size-h3 font-weight-bolder text-dark mb-2"
                >Meine Fälle</label
              >
              <ais-toggle
                id="filterMyCases"
                attribute="assigned_displayName"
                label="{{ auth.user.displayName }}"
                [on]="auth.user.displayName"
                (click)="toggleCountForMyCases()"
              >
              </ais-toggle>
            </div>

            <div class="form-group mb-11">
              <label class="font-size-h3 font-weight-bolder text-dark mb-7"
                >Status</label
              >
              <ais-refinement-list
                attribute="status"
                [sortBy]="['isRefined', 'name:asc']"
              ></ais-refinement-list>
            </div>

            <div class="form-group mb-11">
              <label class="font-size-h3 font-weight-bolder text-dark mb-7"
                >SubStatus</label
              >
              <ais-refinement-list
                attribute="substatus"
                [showMoreLimit]="50"
                [limit]="500"
                [sortBy]="['isRefined', 'name:asc']"
                :show-more-label="See more"
                :show-less-label="See less"
              ></ais-refinement-list>
            </div>

            <div class="form-group mb-11">
              <label class="font-size-h3 font-weight-bolder text-dark mb-7"
                >Familienstand</label
              >
              <ais-refinement-list
                attribute="maritalstatus"
                [sortBy]="['isRefined', 'name:asc']"
              ></ais-refinement-list>
            </div>

            <div class="form-group mb-11">
              <label class="font-size-h3 font-weight-bolder text-dark mb-7"
                >Steuerberater</label
              >
              <ais-refinement-list
                attribute="taxadvisor"
                [sortBy]="['isRefined', 'name:asc']"
              ></ais-refinement-list>
            </div>

            <div class="form-group mb-11">
              <label class="font-size-h3 font-weight-bolder text-dark mb-7"
                >Fristen</label
              >
              <ais-refinement-list
                label="Mit Frist"
                attribute="taxofficeDeadline?.hasNotice"
              ></ais-refinement-list>
              <!-- <ais-toggle
                [attribute]="taxofficeDeadline?.hasNotice"
                label="Mit Frist"
              ></ais-toggle> -->

              <ais-refinement-list
                label="Mit Erinnerung"
                attribute="taxofficeDeadline?.hasReminder"
              ></ais-refinement-list>
              <!-- <ais-toggle
                [attribute]="taxofficeDeadline?.hasReminder"
                label="Mit Erinnerung"
              ></ais-toggle> -->
            </div>

            <div class="form-group mb-11" *ngIf="false">
              <label class="font-size-h3 font-weight-bolder text-dark mb-7"
                >Zahlungsart</label
              >
              <ais-refinement-list
                attribute="paymenttype"
                [sortBy]="['isRefined', 'name:asc']"
              ></ais-refinement-list>
            </div>

            <div class="form-group mb-11" *ngIf="false">
              <label class="font-size-h3 font-weight-bolder text-dark mb-7"
                >Plattform</label
              >
              <ais-refinement-list
                attribute="domain"
                [sortBy]="['isRefined', 'name:asc']"
              ></ais-refinement-list>
            </div>

            <div class="form-group mb-11">
              <label class="font-size-h3 font-weight-bolder text-dark mb-7"
                >Produkt-Typ</label
              >
              <ais-refinement-list
                attribute="product"
                [sortBy]="['isRefined', 'name:asc']"
              ></ais-refinement-list>
            </div>

            <div class="form-group mb-11">
              <label class="font-size-h3 font-weight-bolder text-dark mb-7"
                >Jahr</label
              >
              <ais-refinement-list attribute="year"></ais-refinement-list>
            </div>

            <div class="form-group mb-11">
              <label class="font-size-h3 font-weight-bolder text-dark mb-7"
                >Zugewiesen</label
              >
              <ais-refinement-list
                attribute="assigned_displayName"
                [sortBy]="['isRefined', 'name:asc']"
                [limit]="30"
              ></ais-refinement-list>
            </div>

            <!--
                            <div class="form-group mb-11">
                                <label class="font-size-h3 font-weight-bolder text-dark mb-7">Meine Fälle</label>
                                <div class="checkbox-list">
                                    <label class="checkbox checkbox-lg mb-7">
                                        <input type="checkbox" name="filterMyCases" (checked)="taxcasesservice.onlyMyCases" name="showOnlyMyCases" (click)="taxcasesservice.onlyMyCases = !taxcasesservice.onlyMyCases; substatus=substatus">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold ml-2">aktivieren</div>
                                    </label>
                                </div>

                            </div>



                            <div class="form-group mb-7">
                                <label class="font-size-h3 font-weight-bolder text-dark mb-2">Status</label>

                                <div class="checkbox-list">
                                    <label class="checkbox checkbox-lg mb-7">
                                        <input type="checkbox" name="filterCaseByStatus" (click)="status=TAXCASESTATUS.ENTRY">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">New</div>
                                        <div class="ml-auto text-muted font-weight-bold">-</div>
                                    </label>
                                    <label class="checkbox checkbox-lg mb-7">
                                        <input type="checkbox" name="filterCaseByStatus" (click)="status=TAXCASESTATUS.INPROGRESS">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">inProgress</div>
                                        <div class="ml-auto text-muted font-weight-bold">-</div>
                                    </label>
                                    <label class="checkbox checkbox-lg mb-7">
                                        <input type="checkbox" name="filterCaseByStatus" (click)="status=TAXCASESTATUS.DONE">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Erledigt</div>
                                        <div class="ml-auto text-muted font-weight-bold">-</div>
                                    </label>
                                    <label class="checkbox checkbox-lg mb-7">
                                        <input type="checkbox" name="filterCaseByStatus" (click)="status=TAXCASESTATUS.REJECT">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Rejected</div>
                                        <div class="ml-auto text-muted font-weight-bold">-</div>
                                    </label>
                                 </div>
                            </div>

                            <div class="form-group mb-7">
                                <label class="font-size-h3 font-weight-bolder text-dark mb-2">SubStatus inProgress</label>

                                <div class="checkbox-list">
                                    <label class="checkbox checkbox-lg mb-7">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=null" *ngIf="taxcasesservice.statisticcountersMain">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Alle</div>
                                        <div class="ml-auto text-muted font-weight-bold">-</div>
                                    </label>
                                    <label class="checkbox checkbox-lg mb-7">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="status=TAXCASESTATUS.INPROGRESS;substatus=TAXCASESTATUS_INPROGRESS.WAITINGDATA">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Warten</div>
                                        <div class="ml-auto text-muted font-weight-bold">-</div>
                                    </label>
                                    <label class="checkbox checkbox-lg mb-7">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="status=TAXCASESTATUS.INPROGRESS;substatus=TAXCASESTATUS_INPROGRESS.WAITINGFORDATARETRIEVAL">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Daten abrufen</div>
                                        <div class="ml-auto text-muted font-weight-bold">-</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_INPROGRESS.WAITINGFORDATARETRIEVAL_SENT">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Daten abgerufen</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus"  (click)="substatus=TAXCASESTATUS_INPROGRESS.READYTOSEND">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Bereit</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_INPROGRESS.READYTOSENDWITHERROR">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Bereit Error</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_INPROGRESS.SENTTOTAXOFFICE">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Beim Finanzamt</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_INPROGRESS.RETURNEDFROMTAXOFFICE">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Bescheid erhalten</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_INPROGRESS.APPEALED">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Einspurch</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_INPROGRESS.SEPA">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Sepa</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_INPROGRESS.SEPAPROCESSED">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Sepa (P)</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_INPROGRESS.WAITINGFORMONEY">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Warten auf bezahlung</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_INPROGRESS.PAYMENTDECLINED">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Bezahlung abgelehnt</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_INPROGRESS.PAYMENTDECLINED">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Bezahlung abgelehnt</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_INPROGRESS.PAID">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Bezahlung erhalten</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_INPROGRESS.PAID">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Bezahlung erhalten</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                </div>


                            </div>


                            <div class="form-group mb-7">
                                <label class="font-size-h3 font-weight-bolder text-dark mb-2">SubStatus Rejected</label>

                                <div class="checkbox-list">

                                    <label class="checkbox checkbox-lg mb-7">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_REJECT.FRAUD">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Betrug</div>
                                        <div class="ml-auto text-muted font-weight-bold">-</div>
                                    </label>
                                    <label class="checkbox checkbox-lg mb-7">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_REJECT.ERROR">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Fehler</div>
                                        <div class="ml-auto text-muted font-weight-bold">-</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_REJECT.TEST">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Test</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus"  (click)="substatus=TAXCASESTATUS_REJECT.DOUBLE">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Doppelt</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_REJECT.OTHER">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Andere</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_REJECT.CUSTOMERDECLINED">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Kunde hat abgelehnt</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_REJECT.NORESPONSE">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Keine Antwort</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>
                                    <label class="checkbox checkbox-lg">
                                        <input type="checkbox" name="filterCaseBySubStatus" (click)="substatus=TAXCASESTATUS_REJECT.UNATTRACTIVE">
                                        <span></span>
                                        <div class="font-size-lg text-dark-75 font-weight-bold">Nicht antraktive</div>
                                        <div class="ml-auto text-muted font-weight-bold">38</div>
                                    </label>

                                </div>


                            </div>
                    -->
          </form>
        </div>
      </div>
    </div>

    <div class="flex-row-fluid col-sm-9">
      <div class="card card-custom card-stretch card-shadowless gutter-b">
        <div class="card-header border-0 py-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark">{{
              title
            }}</span>
            <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
              header
            }}</span>
          </h3>

          <div class="card-toolbar"></div>
        </div>

        <div class="card-body pt-0 pb-3">
          <div class="row align-items-center">
            <div class="col-sm-4">
              <div class="input-icon">
                <input
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Search..."
                  id="kt_datatable_search_query"
                  (keyup)="onQuery($event)"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-sm-2">
              <ais-hits-per-page [items]="pageLimits"></ais-hits-per-page>
            </div>
            <div class="col-sm-6">
              <ais-pagination class="float-right"></ais-pagination>
            </div>
          </div>

          <!--  <div class="row align-items-center">
                        <div class="col-sm-4">
                            <ais-hits-per-page
                            [items]="pageLimits"
                          ></ais-hits-per-page>

                        </div>
                        <div class="col-sm-8">
                            <ais-sort-by [items]="[
                                  { value: 'created_millis', label: 'Erstelldatum' },
                                  { value: 'assigned_displayName', label: 'Bearbeiter' },
                                  { value: 'status', label: 'Status' },
                                  { value: 'substatus', label: 'Sub Status' },
                                  { value: 'calculation.current.taxRefund', label: 'Erstattung' }
                                ]"></ais-sort-by>
                        </div>
                    </div> -->

          <ais-hits>
            <ng-template let-hits="hits" let-results="results">
              <div class="tab-content">
                <!--begin::Table-->
                <div class="table-responsive">
                  <table class="table table-sm table-hovertable-head-bg">
                    <thead>
                      <tr class="text-left text-uppercase">
                        <th style="min-width: 40px"></th>
                        <th style="min-width: 170px" class="pl-7">
                          <span class="text-dark-75">Kunde</span>
                        </th>
                        <th style="min-width: 120px">
                          <span class="text-dark-75">Status</span>
                        </th>
                        <th
                          style="min-width: 100px"
                          *ngIf="hits.status === TAXCASESTATUS.INPROGRESS"
                        >
                          Elster
                        </th>
                        <th style="min-width: 100px">Eingang</th>
                        <th style="min-width: 100px">Erstattung</th>
                        <th
                          style="min-width: 100px"
                          class="d-none d-lg-block d-xl-none"
                        >
                          Rückerstattung
                        </th>
                        <th style="min-width: 100px">Infos</th>
                        <th>Bearbeiter</th>
                        <th style="min-width: 60px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let tc of hits; let i = index">
                        <td>
                          <div class="symbol symbol-50 symbol-light">
                            <span class="symbol-label">
                              <img
                                [src]="'/assets/media/logos/' + tc.image"
                                class="h-75 align-self-center"
                              />
                              <div
                                class="dropdown dropdown-inline"
                                data-toggle="tooltip"
                                *ngIf="tc.isTest"
                                title="Dies ist ein Testfall"
                              >
                                <span
                                  class="label label-light-danger label-rounded font-weight-bolder position-absolute top-0 right-1 mt-4 mr0"
                                  >T</span
                                >
                              </div>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex">
                            <div>
                              <a
                                target="_blank"
                                [routerLink]="['/client', tc.clientId]"
                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                              >
                                {{ tc.firstname }} {{ tc.lastname }}

                                <div *ngIf="tc">
                                  <div class="row">
                                    <div
                                      class="col"
                                      *ngIf="
                                        tc.maritalstatus ===
                                        MARITALSTATUS.married
                                      "
                                    >
                                      <span
                                        class="label label-dark-light label-inline mr-2"
                                        >Verheiratet</span
                                      >
                                    </div>
                                    <div
                                      class="col"
                                      *ngIf="
                                        tc.maritalstatus ===
                                        MARITALSTATUS.divorced
                                      "
                                    >
                                      <span
                                        class="label label-dark-light label-inline mr-2"
                                        >Geschieden</span
                                      >
                                    </div>
                                    <div
                                      class="col"
                                      *ngIf="tc.taxofficeDeadline?.noticeDate"
                                    >
                                      <span
                                        class="label label-danger label-inline mr-2"
                                        >FA Bescheid:
                                        {{
                                          tc.taxofficeDeadline.noticeDate
                                            | date : 'dd.MM.yyyy'
                                        }}
                                      </span>
                                    </div>
                                    <div
                                      class="col"
                                      *ngIf="tc.taxofficeDeadline?.reminderDate"
                                    >
                                      <span
                                        class="label label-danger label-inline mr-2"
                                        >FA Erinerung:
                                        {{
                                          tc.taxofficeDeadline.reminderDate
                                            | date : 'dd.MM.yyyy'
                                        }}
                                      </span>
                                    </div>
                                    <div class="col" *ngIf="tc.isCopy">
                                      <span
                                        class="label label-dark-light label-inline mr-2"
                                        >Copy</span
                                      >
                                    </div>

                                    <div
                                      class="col"
                                      *ngIf="tc.childrens !== 'Kinderlos'"
                                    >
                                      <i class="fa fa-child"></i>:{{
                                        tc.childrens
                                      }}
                                    </div>

                                    <div
                                      class="col"
                                      *ngIf="
                                        tc.client_disabled !==
                                        'Keine Behinderung'
                                      "
                                    >
                                      <i class="fab fa-accessible-icon"></i>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span class="text-muted font-weight-bold">
                            <div *ngIf="tc.status">
                              <span
                                class="label label-sm label-light-primary label-inline font-weight-lighter mr-2"
                                >{{ tc.status }}</span
                              >
                              <br />
                              <span
                                class="label label-sm label-inline font-weight-lighter mr-2"
                                [ngClass]="{
                                  'label-light-danger':
                                    tc.status === TAXCASESTATUS.REJECT
                                }"
                                *ngIf="
                                  tc.substatus &&
                                  tc.substatus !== null &&
                                  tc.substatus !== ''
                                "
                              >
                                {{ tc.substatus }}
                              </span>
                            </div>
                          </span>
                        </td>
                        <td *ngIf="hits.status === TAXCASESTATUS.INPROGRESS">
                          <a
                            [routerLink]="['/taxcase', tc.objectID, 'elster']"
                            *ngIf="tc.isTaxOfficeTestSent === true"
                            class="btn btn-outline-success btn-sm mr-3"
                          >
                            <i class="flaticon-open-box"></i>
                            Ok
                          </a>
                          <a
                            [routerLink]="['/taxcase', tc.objectID, 'elster']"
                            *ngIf="tc.isTaxOfficeTestSent === false"
                            class="btn btn-outline-danger btn-sm mr-3"
                          >
                            <i class="flaticon-open-box"></i>
                            Fehler
                          </a>
                          <a
                            *ngIf="tc.isTaxOfficeTestSent === null"
                            class="btn btn-outline-warning btn-sm mr-3"
                          >
                            <i class="flaticon-open-box"></i>
                            -
                          </a>
                        </td>
                        <td>
                          <span
                            class="text-dark-75 font-weight-bolder d-block font-size-lg"
                            *ngIf="tc.created"
                          >
                            {{ tc.created | date : 'dd.MM.yyyy HH:mm' }}
                          </span>
                          <span
                            class="text-dark-75 font-weight-light d-block font-size-sm"
                            *ngIf="tc.created"
                          >
                            {{ tc.created | timeago }}
                            <small
                              style="font-size: 8px; background: none"
                              tooltip="Updated"
                              *ngIf="tc.updated"
                            >
                              <br />
                              {{ tc.updated | date : 'dd.MM.yyyy HH:mm' }}
                            </small>
                          </span>
                        </td>
                        <td class="d-none d-lg-block d-xl-none">
                          <span
                            class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >
                            {{ tc.year }}
                          </span>
                        </td>
                        <td>
                          <span
                            *ngIf="tc.calculation"
                            class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >
                            <div
                              data-toggle="popover"
                              title="Kunden Berechnung (todo)"
                              data-html="true"
                            >
                              <span
                                class="label label-inline label-square label-light-dark"
                                *ngIf="
                                  tc.calculation.current &&
                                  tc.calculation.current.taxRefund > 10
                                "
                                >{{
                                  tc.calculation.current.taxRefund
                                    | currency : 'EUR'
                                }}</span
                              >
                              <span
                                class="label label-inline label-square label-light-danger"
                                *ngIf="
                                  tc.calculation.current &&
                                  tc.calculation.current.taxRefund <= 0
                                "
                                >{{
                                  tc.calculation.current.taxRefund
                                    | currency : 'EUR'
                                }}</span
                              >
                            </div>
                          </span>
                          <span
                            *ngIf="tc.calculation && tc.calculation.current"
                            class="text-muted font-weight-bold"
                          >
                            Original
                            {{
                              tc.calculation.original.taxRefund
                                | currency : 'EUR'
                            }}
                          </span>
                        </td>
                        <td>
                          <span
                            class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >
                            <a
                              target="_blank"
                              [routerLink]="[
                                '/client',
                                tc.clientId,
                                'taxcase',
                                tc.objectID
                              ]"
                            >
                              {{ tc.objectID }}
                            </a>
                          </span>
                          <span class="text-muted font-weight-bold">
                            <a
                              target="_blank"
                              class="text-muted"
                              [routerLink]="[
                                '/client',
                                tc.clientId,
                                'taxcase',
                                tc.objectID
                              ]"
                            >
                              {{ tc.objectID }}
                            </a>
                          </span>
                        </td>

                        <td colspan="2">
                          <span>
                            <div class="d-flex align-items-center">
                              <div class="ml-4">
                                <!-- <div class="text-dark-75 font-weight-bolder font-size-lg mb-0"
                                                                    *ngIf="tc.assigned_displayName">{{tc.assigned_displayName}}</div> -->

                                <div class="btn-group ml-2">
                                  <button
                                    type="button"
                                    class="btn btn-primary font-weight-bold"
                                    data-toggle="popover"
                                    title="Übernehmen"
                                    (click)="assignToMe(tc)"
                                  >
                                    {{ tc.assigned_displayName }}
                                    <br />
                                    <small *ngIf="tc.updated">
                                      {{ tc.updated | timeago }}
                                    </small>
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-primary font-weight-bold dropdown-toggle dropdown-toggle-split"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  ></button>
                                  <div
                                    class="dropdown-menu dropdown-menu-sm p-0 m-0 dropdown-menu-right"
                                  >
                                    <ul
                                      class="navi py-5"
                                      *ngIf="
                                        userservice.users$ | async;
                                        let users
                                      "
                                    >
                                      <li
                                        class="navi-item"
                                        *ngFor="let user of users"
                                      >
                                        <a
                                          class="navi-link"
                                          (click)="assignToSomeone(tc, user)"
                                        >
                                          <span class="navi-icon">
                                            <i class="flaticon-avatar"></i>
                                          </span>
                                          <span class="navi-text">{{
                                            user.displayName
                                          }}</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <!-- <a class="text-muted font-weight-bold text-hover-primary"
                                                                    *ngIf="tc.updated">{{tc.updated | timeago}}</a> -->
                              </div>
                            </div>
                          </span>
                        </td>

                        <!-- <td class="text-right">

                                                    <app-statusbuttons-list [currentStatus]="tc.status" [taxcase]="tc"
                                                        [currentSubStatus]="tc.substatus"></app-statusbuttons-list>

                                                </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-template>
          </ais-hits>
        </div>

        <div class="card-footer d-flex justify-content-between">
          <ais-pagination></ais-pagination>
        </div>
      </div>
    </div>
  </div>
</ais-instantsearch>

<ng-template #loading>Loading</ng-template>
