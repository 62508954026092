import { Component, Input } from '@angular/core';
import { ClientShort, Message } from '@expresssteuer/models';
import { MessagingService } from '../../../../app/services/data/messaging.service';
import { AuthenticationService } from '../../../../app/services/security/authentication.service';

@Component({
  selector: 'app-control-message-messageactions-dropdown',
  templateUrl: './control-message-messageactions-dropdown.component.html',
  styleUrls: ['./control-message-messageactions-dropdown.component.scss'],
})
export class ControlMessageMessageactionsDropdownComponent {
  constructor(
    public messagingservice: MessagingService,
    public security: AuthenticationService
  ) {}

  @Input()
  message?: Message = undefined;

  public markReadUnread() {
    if (this.message === undefined) return;
    if (!this.message.metadata.read) {
      this.message.metadata.read = true;
    } else {
      this.message.metadata.read = false;
    }
    this.messagingservice
      .updateRead(this.message)
      .catch((e) =>
        console.warn(
          'ControlMessageMessageactionsDropdownComponent.markReadUnread.error',
          e
        )
      );
  }

  public markAsDelete() {
    if (this.message === undefined) return;
    if (!this.message.metadata.deleted) {
      this.message.metadata.deleted = true;
    } else {
      this.message.metadata.deleted = false;
    }
    this.messagingservice
      .delete(this.message)
      .catch((e) =>
        console.warn(
          'ControlMessageMessageactionsDropdownComponent.markAsDelete.error',
          e
        )
      );
  }

  public reply() {
    const client: ClientShort | null = this.message?.from
      ? (this.message.from as ClientShort)
      : null;

    this.messagingservice.openMessanger(
      this.message?.type,
      client,
      'Antwort: ' + this.message?.subject
    );
  }
}
