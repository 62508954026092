<div class="suggestions" *ngIf="showSuggestions" @slideInOutA>
  <ng-container *ngFor="let suggestion of suggestions; trackBy: trackByMessage">
    <esui-message-suggestion
      *ngIf="suggestion"
      [suggestion]="suggestion"
      (suggestionSelected)="suggestionSelected.next($event)"
    >
    </esui-message-suggestion>
  </ng-container>
</div>

<div class="editorArea">
  <button
    mat-icon-button
    *ngIf="!showEditor"
    (click)="noSuggestionMatchesClick.next()"
    mapTooltip="compose a message"
  >
    <mat-icon>add_comment</mat-icon>
  </button>
  <button mat-icon-button *ngIf="showEditor" (click)="cancelClick.next()">
    <mat-icon>close</mat-icon>
  </button>
  <div *ngIf="showEditor" class="editor" @slideInOutB>
    <mat-form-field appearance="fill">
      <mat-label>new message</mat-label>
      <textarea
        autofocus
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="10"
        [ngModel]="message?.message"
        (ngModelChange)="messageContentChangeHandler($event)"
      ></textarea>
    </mat-form-field>
    <button
      mat-icon-button
      [disabled]="!message"
      *ngIf="message"
      (click)="sendMessageClick.next()"
    >
      <mat-icon>send</mat-icon>
    </button>
  </div>
</div>
