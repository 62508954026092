<ng-container
  *ngIf="skeletonBuildInput && (objectIsLoaded$ | async); else showContent"
>
  <ng-container *ngIf="bigBoxMode; else showSkeleton">
    <div class="bigBox"><div class="loader"></div></div>
  </ng-container>
  <ng-template #showSkeleton>
    <div *ngFor="let box of skeletonBuildInput">
      <div class="placeholderBox">
        <div class="contentBox" *ngFor="let entry of box">
          <div class="titlePlaceHolder" *ngIf="entry === PlaceholderType.title">
            <div class="loader"></div>
          </div>
          <div
            class="contentPlaceHolderShort"
            *ngIf="entry === PlaceholderType.smallSub"
          >
            <div class="loader"></div>
          </div>
          <div
            class="contentPlaceHolderLong"
            *ngIf="entry === PlaceholderType.bigSub"
          >
            <div class="loader"></div>
          </div>
          <div
            class="contentPlaceHolder2"
            *ngIf="entry === PlaceholderType.boxSub"
          >
            <div class="loader"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #showContent>
  <ng-content></ng-content>
</ng-template>
