/* eslint-disable */

export interface VaSt_RBM {
  VaSt_RBM: VaSt_RBM_CType;
}

export interface VaSt_RBM_CType {
  /**
   * Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung
   */
  Eingangsdatum?: string;
  LeistungsEmpfaenger?: LeistungsEmpfaengerM2062363639_CType;
  Mitteilung?: MitteilungM2062363639_CType;
  version: string;
}

export interface LeistungsEmpfaengerM2062363639_CType {
  /**
   * Identifikationsnummer
   */
  IdNr?: string;
  /**
   * Titel
   */
  Titel?: string;
  /**
   * Vorname
   */
  Vorname?: string;
  /**
   * Namensvorsatz
   */
  NamensVorsatz?: string;
  /**
   * Name
   */
  Name?: string;
  /**
   * Namenszusatz
   */
  NamensZusatz?: string;
  /**
   * Name
   */
  Blockname?: string;
}

export interface MitteilungM2062363639_CType {
  /**
   * Zuflussjahr
   */
  Zuflussjahr?: string;
  /**
   * Vom Datenübermittler verwendetes Ordnungsmerkmal
   */
  Ordnungsmerkmal?: string;
  /**
   * Renten-/Leistungserbringer
   */
  MitteilungsPflichtigerName?: string;
  /**
   * Vertragsnummer
   */
  VertragsNr?: string;
  /**
   * Auszahlungsform
   */
  Auszahlungsform?: '01' | '02' | '03' | '04' | '05';
  Leistung?: Leistung_1496424457_CType;
  /**
   * @maxItems 30
   */
  Vorzeitraum?: Vorzeitraum_1496424457_CType | Vorzeitraum_1496424457_CType[];
  /**
   * @maxItems 96
   */
  Krankenversicherung?:
    | Krankenversicherung_1496424457_CType
    | Krankenversicherung_1496424457_CType[];
}

export interface Leistung_1496424457_CType {
  /**
   * Rentenanpassungsbetrag
   */
  Anpassungsbetrag?: string;
  /**
   * Währung
   */
  Waehrung?: string;
  /**
   * Beginn der Rente/Leistung
   */
  Beginn?: string;
  /**
   * Ende der Rente/Leistung
   */
  Ende?: string;
  /**
   * @maxItems 55
   */
  Teilleistung?:
    | TeilleistungM1990336412_CType
    | TeilleistungM1990336412_CType[];
}

export interface TeilleistungM1990336412_CType {
  /**
   * Rechtsgrundlage
   */
  Grundlage?:
    | '01'
    | '02'
    | '03'
    | '04'
    | '05'
    | '06'
    | '07'
    | '12a'
    | '08'
    | '12b'
    | '10'
    | '11'
    | '12c'
    | '12d'
    | '14'
    | '15'
    | '18'
    | '16'
    | '17';
  /**
   * Renten-/Leistungsbetrag
   */
  Betrag?: string;
  /**
   * Abfindung einer Kleinbetragsrente
   */
  AbfindungKleinbetragsrente?: 'true';
  /**
   * Rentenart
   */
  Rentenart?: '01' | '02' | '03' | '04' | '09' | '99';
  /**
   * Im Renten-/Leistungsbetrag enthaltene Nachzahlungen für mehrere vorangegangene Kalenderjahre
   */
  EnthalteneNachzahlung?: string;
  /**
   * Geburtsdatum des Erblassers bei Garantiezeitrenten
   */
  Geburtsdatum?: string;
  /**
   * Beginn der Rente des Erblassers
   */
  Beginn?: string;
  VersFB?: VersFBM1501635691_CType;
}

export interface VersFBM1501635691_CType {
  /**
   * Bemessungsgrundlage für den Versorgungsfreibetrag
   */
  BMG?: string;
  /**
   * maßgebendes Kalenderjahr des Versorgungsbeginns
   */
  Jahr?: string;
  /**
   * bei unterjähriger Zahlung: Erster Monat, für den Versorgungsbezüge gezahlt wurden
   */
  Beginn?: string;
  /**
   * bei unterjähriger Zahlung: Letzter Monat, für den Versorgungsbezüge gezahlt wurden
   */
  Ende?: string;
}

export interface Vorzeitraum_1496424457_CType {
  /**
   * Beginn einer vorhergehenden Rente/Leistung
   */
  Beginn?: string;
  /**
   * Ende einer vorhergehenden Rente/Leistung
   */
  Ende?: string;
}

export interface Krankenversicherung_1496424457_CType {
  /**
   * Beitragsart zur Kranken-/Pflegeversicherung
   */
  Beitragsart?: '01' | '03' | '04' | '06' | '02' | '05';
  /**
   * erster Monat des Zeitraums, für den die Beiträge/Zuschüsse zur Kranken-/Pflegeversicherung geleistet/erstattet wurden
   */
  Beginn?: string;
  /**
   * letzter Monat des Zeitraums, für den die Beiträge/Zuschüsse zur Kranken-/Pflegeversicherung geleistet/erstattet wurden
   */
  Ende?: string;
  /**
   * Kalenderjahr, für das Beiträge/Zuschüsse zur Kranken-/Pflegeversicherung geleistet/erstattet wurden
   */
  Jahr?: string;
  /**
   * Höhe der geleisteten/erstatteten Beiträge/Zuschüsse zur Kranken-/Pflegeversicherung
   */
  Betrag?: string;
}
