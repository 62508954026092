import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
 * To download external resources, this approach uses a
 * simple fetch.
 *
 * Background: Only same origin urls can be downloaded via
 * anchor tags in HTML;
 * @see https://stackoverflow.com/a/49500465 for details;
 * which this is based on.
 */
@Injectable({ providedIn: 'root' })
export class FileDownloaderService {
  private window = this.document.defaultView;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  private forceDownload(blob: string, filename: string) {
    const a = this.document.createElement('a');
    a.download = filename;
    a.href = blob;
    // For Firefox https://stackoverflow.com/a/32226068
    this.document.body.appendChild(a);
    a.click();
    a.remove();
  }

  // Current blob size limit is around 500MB for browsers
  public downloadResource(url: string, filename?: string) {
    let filenameToUse = filename;
    if (!filenameToUse) {
      filenameToUse = url.split('\\').pop()?.split('/').pop() ?? 'unknown';
    }
    fetch(url, {
      headers: this.window
        ? new Headers({
            Origin: this.window.location.origin,
          })
        : undefined,
      mode: 'cors',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = globalThis.URL.createObjectURL(blob);
        this.forceDownload(blobUrl, filename!);
      })
      .catch((e) => console.error(e));
  }
}
