import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TaxCase, TAXCASESTATUS, TaxSettings } from '@expresssteuer/models';
@Component({
  selector: 'esui-fix-finance-office-id',
  templateUrl: './fix-finance-office-id.component.html',
  styleUrls: ['./fix-finance-office-id.component.scss'],
})
export class FixFinanceOfficeIdComponent {
  TAXCASESTATUS = TAXCASESTATUS;

  @Input() taxSettings?: TaxSettings | null;
  #taxcases?: TaxCase[] | null;
  @Input() taxSettingsHasOnlineChangedAfterLocalChanged?: boolean | null;
  @Input() taxSettingsIsSaving?: boolean | null;
  @Input() taxSettingsIsLoading?: boolean | null;
  @Input() taxSettingsHasChanges?: boolean | null;
  @Input() taxSettingsHasErrors?: boolean | null;

  @Input() set taxcases(data: TaxCase[] | null | undefined) {
    this.#taxcases = data;
  }
  get taxcases(): TaxCase[] | null | undefined {
    return this.#taxcases;
  }
  @Input() taxcasesIsLoading?: boolean | null;
  @Input() taxcasesHasErrors?: boolean | null;

  /**
   * Get the taxcases from taxcases clustered by the year of taxsettings.
   */
  get taxcasesByTaxsettings():
    | { forTaxsettings: TaxCase[]; notForTaxsettings: TaxCase[] }
    | null
    | undefined {
    const taxcaseByTaxsettingsYear = (tc?: TaxCase) =>
      `${tc?.year}` === `${this.taxSettings?.year}`;

    return this.taxcases?.reduce(
      (acc, curr) => {
        const isForTaxSettings = taxcaseByTaxsettingsYear(curr);
        if (isForTaxSettings) {
          acc.forTaxsettings.push(curr);
        } else {
          acc.notForTaxsettings.push(curr);
        }
        return acc;
      },
      {
        forTaxsettings: [] as TaxCase[],
        notForTaxsettings: [] as TaxCase[],
      }
    );
  }

  @Output() taxSettingsChange = new EventEmitter<Partial<TaxSettings>>();
  @Output() taxSettingsDiscardClick = new EventEmitter<void>();
  @Output() taxSettingsSaveClick = new EventEmitter<void>();

  trackByCreatedDate(i: number, taxcase: TaxCase) {
    return taxcase.metadata.created?.valueOf();
  }

  copyToClipboard(data: string) {
    this.clipboard.copy(data);
  }

  constructor(private clipboard: Clipboard) {}
}
