<ng-container *ngIf="indexName && searchClient">
  <ais-instantsearch
    [config]="{
      indexName: indexName,
      searchClient: searchClient
    }"
  >
    <esui-client-search-autocomplete
      [clientId]="clientId"
      (clientIdChange)="clientIdChange.next($event)"
      [mode]="mode"
      [appearance]="appearance"
    ></esui-client-search-autocomplete>
  </ais-instantsearch>
</ng-container>
