import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EsCardKeyModule } from '../es-card-key/es-card-key.module';
import { EsStoreStateIndicatorModule } from '../es-store-state-indicator/es-store-state-indicator.module';
import { EsuiDocumentViewerModule } from '../esui-document-viewer/esui-document-viewer.module';
import { EsuiSearchModule } from '../esui-search/esui-search.module';
import { EsuiDocumentClientAssignerComponent } from './esui-document-client-assigner.component';

@NgModule({
  declarations: [EsuiDocumentClientAssignerComponent],
  imports: [
    CommonModule,
    EsuiDocumentViewerModule,
    MatTooltipModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    EsCardKeyModule,
    EsStoreStateIndicatorModule,
    EsuiSearchModule,
  ],
  exports: [EsuiDocumentClientAssignerComponent],
})
export class EsuiDocumentClientAssignerModule {}
