<!--begin::Header-->
<div id="kt_header" class="header header-fixed">
  <!--begin::Header Wrapper-->
  <div
    class="header-wrapper rounded-top-xl d-flex flex-grow-1 align-items-center"
  >
    <!--begin::Container-->
    <div
      class="container-fluid d-flex align-items-center justify-content-end justify-content-lg-between flex-wrap"
    >
      <!--begin::Menu Wrapper-->

      <app-dashboard-navigation></app-dashboard-navigation>

      <!--end::Menu Wrapper-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center py-3">
        <!--begin::Dropdown-->
        <div
          class="dropdown dropdown-inline"
          data-toggle="tooltip"
          title="Quick actions"
          data-placement="left"
        >
          <a
            href="#"
            class="btn btn-dark font-weight-bold px-6"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            >Steuerfall erstellen</a
          >
          <div
            class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-md dropdown-menu-right"
          >
            <!--begin::Navigation-->
            <ul class="navi navi-hover">
              <li class="navi-header font-weight-bold py-4">
                <span class="font-size-lg">Choose Label:</span>
                <i
                  class="flaticon2-information icon-md text-muted"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Click to learn more..."
                ></i>
              </li>
              <li class="navi-separator mb-3 opacity-70"></li>
              <li class="navi-item">
                <a href="#" class="navi-link">
                  <span class="navi-text">
                    <span
                      class="label label-xl label-inline label-light-success"
                      >Customer</span
                    >
                  </span>
                </a>
              </li>
              <li class="navi-item">
                <a href="#" class="navi-link">
                  <span class="navi-text">
                    <span class="label label-xl label-inline label-light-danger"
                      >Partner</span
                    >
                  </span>
                </a>
              </li>
              <li class="navi-item">
                <a href="#" class="navi-link">
                  <span class="navi-text">
                    <span
                      class="label label-xl label-inline label-light-warning"
                      >Suplier</span
                    >
                  </span>
                </a>
              </li>
              <li class="navi-item">
                <a href="#" class="navi-link">
                  <span class="navi-text">
                    <span
                      class="label label-xl label-inline label-light-primary"
                      >Member</span
                    >
                  </span>
                </a>
              </li>
              <li class="navi-item">
                <a href="#" class="navi-link">
                  <span class="navi-text">
                    <span class="label label-xl label-inline label-light-dark"
                      >Staff</span
                    >
                  </span>
                </a>
              </li>
              <li class="navi-separator mt-3 opacity-70"></li>
              <li class="navi-footer py-4">
                <a class="btn btn-clean font-weight-bold btn-sm" href="#">
                  <i class="ki ki-plus icon-sm"></i>Add new</a
                >
              </li>
            </ul>
            <!--end::Navigation-->
          </div>
        </div>
        <!--end::Dropdown-->
      </div>
      <!--end::Toolbar-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Header Wrapper-->
</div>
<!--end::Header-->

<!--begin::Content-->

<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container-fluid">
      <app-card-taxcases></app-card-taxcases>
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
