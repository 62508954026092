import { Injectable } from '@angular/core';
import { simple } from 'instantsearch.js/es/lib/stateMappings';
import { map } from 'rxjs/operators';
import { AlgoliaService } from '../../../../app/services/search/algolia.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AisDocumentsConfigService {
  searchClient$ = this.algoliaService.searchClient$;

  public configDocuments$ = this.searchClient$.pipe(
    map((searchClient) => {
      return {
        indexName: environment.algolia.indexNames.documents_v2,
        searchClient: searchClient,
        routing: {
          stateMapping: simple(),
        },
      };
    })
  );

  constructor(private algoliaService: AlgoliaService) {}
}
