import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PureMethodModule } from '../pipes/pure-method/pure-method.module';
import { EsuiVastRendererComponent } from './esui-vast-renderer.component';

@NgModule({
  declarations: [EsuiVastRendererComponent],
  exports: [EsuiVastRendererComponent],
  imports: [CommonModule, PureMethodModule],
})
export class EsuiVastRendererModule {}
