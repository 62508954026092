import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  PersonalInfoUpdateInput,
  PersonalInfoUpdateOutput,
} from '@expresssteuer/client-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class PersonalInfoUpdate {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: PersonalInfoUpdateInput) {
    console.log('Calling client-personalInfoUpdate');
    return this.afFunctions.httpsCallable<
      PersonalInfoUpdateInput,
      PersonalInfoUpdateOutput
    >('client-personalInfoUpdate')(input);
  }
}
