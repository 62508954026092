import { NgModule } from '@angular/core';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { EsuiDocumentViewerModule } from '@expresssteuer/ui-components';
import { NgAisModule } from 'angular-instantsearch';
import { SharedComponentsModule } from '../../shared/components/shared-components.module';
import { AisDocumentsClearRefinementsComponent } from './ais-documents-clear-refinements/ais-documents-clear-refinements.component';
import { AisDocumentsCollectionListItemComponent } from './ais-documents-collection-list-item/ais-documents-collection-list-item.component';
import { AisDocumentsCollectionListComponent } from './ais-documents-collection-list/ais-documents-collection-list.component';
import { AisDocumentsIconListItemComponent } from './ais-documents-icon-list-item/ais-documents-icon-list-item.component';
import { AisDocumentsIconListComponent } from './ais-documents-icon-list/ais-documents-icon-list.component';
import { AisDocumentsIconComponent } from './ais-documents-icon/ais-documents-icon.component';
import { AisDocumentsPaginationComponent } from './ais-documents-pagination/ais-documents-pagination.component';
import { AisDocumentsRefinementListComponent } from './ais-documents-refinement-list/ais-documents-refinement-list.component';
import { AisDocumentsRoutingModule } from './ais-documents-routing.module';
import { AisDocumentsSearchBoxComponent } from './ais-documents-search-box/ais-documents-search-box.component';
import { AisDocumentsTableItemComponent } from './ais-documents-table-item/ais-documents-table-item.component';
import { AisDocumentsTableComponent } from './ais-documents-table/ais-documents-table.component';
import { AisDocumentsViewComponent } from './ais-documents-view/ais-documents-view.component';
import { DocumentViewComponent } from './document-view/document-view.component';
import { SuggestionStringPipe } from './pipes/suggestion-string.pipe';
import { SearchPresenterComponent } from './search-presenter/search-presenter.component';
import { StatusIndicatorComponent } from './status-indicator/status-indicator.component';
import { ScorePredictionsPipe } from './pipes/score-predictions.pipe';
@NgModule({
  declarations: [
    AisDocumentsViewComponent,
    AisDocumentsCollectionListItemComponent,
    AisDocumentsIconComponent,
    AisDocumentsCollectionListComponent,
    AisDocumentsIconListComponent,
    AisDocumentsIconListItemComponent,
    AisDocumentsClearRefinementsComponent,
    AisDocumentsSearchBoxComponent,
    AisDocumentsPaginationComponent,
    SearchPresenterComponent,
    AisDocumentsTableComponent,
    AisDocumentsTableItemComponent,
    AisDocumentsRefinementListComponent,
    DocumentViewComponent,
    StatusIndicatorComponent,
    SuggestionStringPipe,
    ScorePredictionsPipe,
  ],
  imports: [
    SharedComponentsModule,
    AisDocumentsRoutingModule,
    NgAisModule,
    AngularFireStorageModule,
    EsuiDocumentViewerModule,
  ],
  exports: [AisDocumentsViewComponent],
})
export class AisDocumentsModule {}
