<esui-section-column-scaffold [header]="{ title: 'partner' }">
  <ng-container headerMenu>
    <ng-container *ngIf="mode && mode !== Mode.readonly">
      <esui-store-state-indicator
        *ngIf="mode === Mode.edit"
        [isSaving]="isSaving ?? false"
        [hasErrors]="false"
        [hasChanges]="hasUnsavedChanges ?? false"
      ></esui-store-state-indicator>
      <ng-container *ngIf="mode === Mode.read">
        <button mat-button (click)="editClick.next()">edit</button>
      </ng-container>
      <ng-container
        *ngIf="mode === Mode.edit && hasUnsavedChanges && !isSaving"
      >
        <button mat-button (click)="saveClick.next()">save</button>
      </ng-container>
      <ng-container *ngIf="mode === Mode.edit && !isSaving">
        <button mat-button (click)="discardClick.next()">discard</button>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container content *ngIf="partner">
    <h3></h3>

    <mat-form-field [matTooltip]="mode === Mode.edit ? 'not editable' : ''">
      <mat-label>partnerId</mat-label>
      <input matInput disabled [ngModel]="partner.partnerId" />
      <mat-icon class="icon" matPrefix>tag</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>first name</mat-label>
      <input
        matInput
        [disabled]="mode !== Mode.edit"
        [ngModel]="partner.firstName"
        (ngModelChange)="
          partialPartnerUpdateInputData.next({ firstName: $event })
        "
      />
      <span class="icon" matPrefix> F </span>
    </mat-form-field>

    <mat-form-field>
      <mat-label>last name</mat-label>
      <input
        matInput
        [disabled]="mode !== Mode.edit"
        [ngModel]="partner.lastName"
        (ngModelChange)="
          partialPartnerUpdateInputData.next({ lastName: $event })
        "
      />
      <span class="icon" matPrefix> L </span>
    </mat-form-field>

    <mat-form-field>
      <mat-label>birthdate</mat-label>
      <esui-date-string-input
        [disabled]="mode !== Mode.edit"
        [ngModel]="partner.birthdate"
        (ngModelChange)="
          partialPartnerUpdateInputData.next({ birthdate: $event })
        "
      ></esui-date-string-input>
      <mat-icon class="icon" matPrefix>cake</mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>tax ID</mat-label>
      <input matInput esuiValidTaxId disabled [ngModel]="partner.taxId" />
      <span class="icon" matPrefix>#X</span>
    </mat-form-field>
  </ng-container>
</esui-section-column-scaffold>
