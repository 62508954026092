import { Component, Input, OnInit } from '@angular/core';
import { MESSAGESTATUS } from '@expresssteuer/models';

@Component({
  selector: 'app-message-status',
  templateUrl: './message-status.component.html',
  styleUrls: ['./message-status.component.scss'],
})
export class MessageStatusComponent implements OnInit {
  MESSAGESTATUS = MESSAGESTATUS;
  color: string;

  private _state: MESSAGESTATUS;

  @Input()
  public set state(state: MESSAGESTATUS) {
    console.log(state);
    switch (state) {
      case MESSAGESTATUS.QUENUE:
        this.color = 'bubble-blue';
        break;
      case MESSAGESTATUS.SENT:
      case MESSAGESTATUS.DELIVERED:
      case MESSAGESTATUS.RECIEVED:
        this.color = 'bubble-gray';
        break;
      case MESSAGESTATUS.READ:
        this.color = 'bubble-green';
        break;
      default:
        this.color = 'bubble-red';
        break;
    }
    this._state = state;
  }
  public get state() {
    return this._state;
  }

  constructor() {}

  ngOnInit(): void {}
}
