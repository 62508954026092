import { EventEmitter, Injectable, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BaseMessageTemplate } from '@expresssteuer/models';
import { Observable } from 'rxjs';
import { BusyManager } from '../../../app/shared/helpers/busymanager';

@Injectable({
  providedIn: 'root',
})
export class MessagetemplateService {
  public busy: BusyManager = new BusyManager();

  private aCurrent: BaseMessageTemplate;

  public changeTemplates = new EventEmitter<
    Observable<BaseMessageTemplate[]>
  >();

  public get current(): BaseMessageTemplate | undefined {
    return this.aCurrent;
  }

  public set current(val: BaseMessageTemplate) {
    this.aCurrent = val;
  }

  private aTemplates$: Observable<BaseMessageTemplate[]>;

  @Input()
  public get templates(): Observable<BaseMessageTemplate[]> {
    if (!this.aTemplates$) {
      this.init();
    }
    return this.aTemplates$;
  }

  public set templates(val: Observable<BaseMessageTemplate[]>) {
    this.aTemplates$ = val;
    this.changeTemplates.emit(val);
  }

  constructor(private db: AngularFirestore) {}

  public async load(id: string) {
    if (this.current?.id !== id) {
      this.busy.start('Loading Template');
      const templateRef = await this.db
        .collection('statics')
        .doc('message_templates')
        .collection<BaseMessageTemplate>('twilio_message_templates')
        .doc(id)
        .get()
        .toPromise();
      this.current = templateRef.data() as BaseMessageTemplate;
      this.busy.stop();
    }
  }

  public async new(): Promise<string> {
    return new Promise<string>(async (r, rej) => {
      const template: BaseMessageTemplate = {
        id: 'new',
        message: '',
        name: 'Neues Template',
        subject: '',
        usage: {
          email: false,
          whatsapp: true,
          push: false,
        },
        notificationChannel: null,
        deepLink: null,
      };
      template.id = this.db.createId();
      this.current = template;
      r(template.id);
    });
  }

  public async update() {
    this.busy.start('Speichere Template');
    await this.db
      .collection('statics')
      .doc('message_templates')
      .collection<BaseMessageTemplate>('twilio_message_templates')
      .doc(this.current.id)
      .set(this.current);
    this.busy.stop();
  }

  public async delete(template: BaseMessageTemplate) {
    await this.db
      .collection('statics')
      .doc('message_templates')
      .collection<BaseMessageTemplate>('twilio_message_templates')
      .doc(template.id)
      .delete();
  }

  private init() {
    this.aTemplates$ = this.db
      .collection('statics')
      .doc('message_templates')
      .collection<BaseMessageTemplate>('twilio_message_templates', (ref) =>
        ref.orderBy('name')
      )
      .valueChanges();
    console.log('linked base templates');
  }
}
