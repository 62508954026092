import { Component } from '@angular/core';
import { AbstractValueAccessor } from '../abstract-value-accessor';

@Component({
  selector: 'esui-permission-input',
  templateUrl: './permission-input.component.html',
  styleUrls: ['./permission-input.component.scss'],
})
export class PermissionInputComponent extends AbstractValueAccessor<string> {
  protected data = '';
  permissionList = {
    x: 'execute',
    g: 'get',
    l: 'list',
    c: 'create',
    u: 'update',
    d: 'delete',
  };

  /**
   * @override
   */
  get empty(): boolean {
    return false; //is never empty
  }

  /**
   * @override
   */
  controlType = 'PermissionInputComponent';

  isPermissionSet(permission: string) {
    return this.value?.includes(permission);
  }

  updatePermission(permission: string[]) {
    this.value = permission.join('');
  }

  /**
   * @override
   */
  onContainerClick(event: MouseEvent): void {
    return; // no autoclicks
  }

  trackByKey(index: number, item: { key: string }) {
    return item.key; // or item.id
  }
}
