import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Timestamp } from '@angular/fire/firestore';
import { User } from '@expresssteuer/models';
import { map, shareReplay, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public user: User | null = null;
  constructor(public afAuth: AngularFireAuth, private db: AngularFirestore) {
    this.user$.subscribe((user) => {
      this.user = user;
    });
  }

  public user$ = this.afAuth.authState.pipe(
    switchMap(async (firebaseUser) => {
      console.log('AUTHSTATE CHANGED', firebaseUser);

      if (!firebaseUser) {
        return null;
      }
      const user = User.getTemplate();
      user.authId = firebaseUser.uid;
      user.displayName = firebaseUser.displayName ?? 'n.a.';
      user.email = firebaseUser.email ?? 'n.a.';
      user.photoUrl = firebaseUser.photoURL ?? 'n.a.';
      user.lastSeen = Timestamp.fromDate(new Date());

      // update last seend
      try {
        const thisuser = await this.db
          .collection('users')
          .ref.where('authId', '==', firebaseUser.uid)
          .get();
        if (thisuser.size === 1) {
          await this.db
            .collection('users')
            .doc(thisuser.docs[0].id)
            .set(
              { lastSeen: user.lastSeen, photoUrl: firebaseUser.photoURL },
              { merge: true }
            );
        } else {
          console.error(
            'Could not find matching User for uid ' +
              firebaseUser.uid +
              ' in database'
          );
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
      /* TODO Implement Notifications by listening
       dbdirect.list('taxcases').snapshotChanges().subscribe(r => {
         console.log('happens');
         console.log(r);
       });
       */

      return user;
    }),
    shareReplay()
  );

  isLoggedIn$ = this.user$.pipe(map((user) => (user !== null ? true : false)));

  public async logout() {
    await this.afAuth.signOut();
  }
}
