import { SchemaProperty } from './vast';

export const VastLstbSchema: SchemaProperty = {
  title: 'VaSt_LStB-202201.xsd',
  $schema: 'http://json-schema.org/schema#',

  type: 'object',
  properties: {
    VaSt_LStB: {
      type: 'object',
      description: 'Lohnsteuerbescheinigung',
      properties: {
        ArbGName: {
          description: 'Name des Arbeitgebers',
          type: 'string',
        },
        Eingangsdatum: {
          description:
            'Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung',
          type: 'string',
        },
        Allgemein: {
          type: 'object',
          properties: {
            eTIN: {
              description: 'eTIN',
              type: 'string',
            },
            IdNr: {
              description: 'Identifikationsnummer',
              type: 'string',
            },
          },
        },
        Dauer: {
          type: 'object',
          properties: {
            Jahr: {
              description: 'Beschäftigungsjahr',
              type: 'string',
            },
            Anfang: {
              description: 'vom',
              type: 'string',
            },
            Ende: {
              description: 'bis',
              type: 'string',
            },
          },
        },
        Besteuerungsmerkmal: {
          type: 'object',
          properties: {
            ELStAM: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  GueltigAb: {
                    description: 'Besteuerungsmerkmale gültig ab',
                    type: 'string',
                  },
                  Steuerfreibetrag: {
                    description: 'eingetragener Steuerfreibetrag',
                    type: 'integer',
                  },
                  Hinzurechnungsbetrag: {
                    description: 'eingetragener Hinzurechnungsbetrag',
                    type: 'integer',
                  },
                  Steuerklasse: {
                    type: 'object',
                    properties: {
                      Klasse: {
                        description: 'Steuerklasse',
                        type: 'string',
                        enum: ['0', '1', '2', '3', '4', '5', '6'],
                      },
                      Faktor: {
                        description: 'Faktor',
                        type: 'number',
                      },
                    },
                  },
                  Kirchensteuerabzug: {
                    type: 'object',
                    properties: {
                      Konfession: {
                        description: 'Kirchensteuermerkmal (Konfession)',
                        type: 'string',
                        enum: [
                          'ak',
                          'ev',
                          'lt',
                          'rf',
                          'fr',
                          'fa',
                          'fm',
                          'fs',
                          'fb',
                          'fg',
                          'ib',
                          'iw',
                          'is',
                          'jd',
                          'jh',
                          'il',
                          '--',
                          'na',
                          'rk',
                        ],
                      },
                      Ehegkonfession: {
                        description:
                          'Kirchensteuermerkmal (Konfession) des Ehegatten',
                        type: 'string',
                        enum: [
                          'ak',
                          'ev',
                          'lt',
                          'rf',
                          'fr',
                          'fa',
                          'fm',
                          'fs',
                          'fb',
                          'fg',
                          'ib',
                          'iw',
                          'is',
                          'jd',
                          'jh',
                          'il',
                          '--',
                          'na',
                          'rk',
                        ],
                      },
                    },
                  },
                },
              },
            },
          },
        },
        Besteuerungsgrund: {
          type: 'object',
          properties: {
            AnzahlU: {
              description: 'Zeiträume ohne Anspruch auf Arbeitslohn (Anzahl U)',
              type: 'integer',
            },
            Grossbuchstaben: {
              description: 'bescheinigter Großbuchstabe',
              type: 'string',
            },
            BruttoArbLohn: {
              description: 'Bruttoarbeitslohn',
              type: 'number',
              stringifier: 'currency-eur',
            },
            LSteuer: {
              description: 'einbehaltene Lohnsteuer',
              type: 'number',
              stringifier: 'currency-eur',
            },
            Soli: {
              description: 'einbehaltener Solidaritätszuschlag',
              type: 'number',
              stringifier: 'currency-eur',
            },
            ArbnKiSteuer: {
              description: 'einbehaltene Kirchensteuer des Arbeitnehmers',
              type: 'number',
              stringifier: 'currency-eur',
            },
            EhegKiSteuer: {
              description: 'einbehaltene Kirchensteuer des Ehegatten',
              type: 'number',
              stringifier: 'currency-eur',
            },
            ErmStBetrMKalJahr: {
              description:
                'ermäßigt besteuerter Arbeitslohn für mehrere Kalenderjahre und ermäßigt besteuerte Entschädigungen',
              type: 'number',
              stringifier: 'currency-eur',
            },
            LSteuerMKalJahr: {
              description:
                'einbehaltene Lohnsteuer bei ermäßigt besteuertem Arbeitslohn',
              type: 'number',
              stringifier: 'currency-eur',
            },
            SoliMKalJahr: {
              description:
                'einbehaltener Solidaritätszuschlag bei ermäßigt besteuertem Arbeitslohn',
              type: 'number',
              stringifier: 'currency-eur',
            },
            KiSteuerArbnMKalJahr: {
              description:
                'einbehaltene Kirchensteuer des Arbeitnehmers bei ermäßigt besteuertem Arbeitslohn',
              type: 'number',
              stringifier: 'currency-eur',
            },
            KiSteuerEhegMKalJahr: {
              description:
                'einbehaltene Kirchensteuer des Ehegatten bei ermäßigt besteuertem Arbeitslohn',
              type: 'number',
              stringifier: 'currency-eur',
            },
            StPflichtArbLohnMKalJahr: {
              description:
                'steuerpflichtige Entschädigungen und Arbeitslohn für mehrere Kalenderjahre, die nicht ermäßigt besteuert wurden',
              type: 'number',
              stringifier: 'currency-eur',
            },
            KurzArbGeld: {
              description:
                'Kurzarbeitergeld, Zuschuss zum Mutterschaftsgeld, Verdienstausfallentschädigung (Infektionsschutzgesetz), Aufstockungsbetrag und Altersteilzeitzuschlag',
              type: 'number',
              stringifier: 'currency-eur',
            },
            StFreiArbLohnDBA: {
              description:
                'steuerfreier Arbeitslohn nach Doppelbesteuerungsabkommen',
              type: 'number',
              stringifier: 'currency-eur',
            },
            StFreiArbLohnATE: {
              description:
                'steuerfreier Arbeitslohn nach Auslandstätigkeitserlass',
              type: 'number',
              stringifier: 'currency-eur',
            },
            ArbgAnteilRenVers: {
              description:
                'Arbeitgeberanteil zur gesetzlichen Rentenversicherung',
              type: 'number',
              stringifier: 'currency-eur',
            },
            ArbgAnteilBerufsVers: {
              description:
                'Arbeitgeberanteil an berufsständische Versorgungseinrichtungen',
              type: 'number',
              stringifier: 'currency-eur',
            },
            ArbnAnteilRenVers: {
              description:
                'Arbeitnehmeranteil zur gesetzlichen Rentenversicherung',
              type: 'number',
              stringifier: 'currency-eur',
            },
            ArbnAnteilBerufsVers: {
              description:
                'Arbeitnehmeranteil an berufsständische Versorgungseinrichtungen',
              type: 'number',
              stringifier: 'currency-eur',
            },
            StFreiGeKrankVers: {
              description:
                'steuerfreie Arbeitgeberzuschüsse zur gesetzlichen Krankenversicherung',
              type: 'number',
              stringifier: 'currency-eur',
            },
            StFreiPrKrankVers: {
              description:
                'steuerfreie Arbeitgeberzuschüsse zur privaten Krankenversicherung',
              type: 'number',
              stringifier: 'currency-eur',
            },
            StFreiGePflegVers: {
              description:
                'steuerfreie Arbeitgeberzuschüsse zur gesetzlichen Pflegeversicherung',
              type: 'number',
              stringifier: 'currency-eur',
            },
            ArbnAnteilKrankVers: {
              description:
                'Arbeitnehmerbeiträge zur gesetzlichen Krankenversicherung',
              type: 'number',
              stringifier: 'currency-eur',
            },
            ArbnAnteilPflegVers: {
              description:
                'Arbeitnehmerbeiträge zur sozialen Pflegeversicherung',
              type: 'number',
              stringifier: 'currency-eur',
            },
            ArbnAnteilArblVers: {
              description: 'Arbeitnehmerbeiträge zur Arbeitslosenversicherung',
              type: 'number',
              stringifier: 'currency-eur',
            },
            BeitrPrKrankVers: {
              description:
                'nachgewiesene Beiträge zur privaten Krankenversicherung und Pflege-Pflichtversicherung',
              type: 'number',
              stringifier: 'currency-eur',
            },
            ArbnAnteilWBUmlage: {
              description:
                'Arbeitnehmerbeitrag zur Winterbeschäftigungs-Umlage',
              type: 'number',
              stringifier: 'currency-eur',
            },
            ArbgAnteilZusatzVers: {
              description: 'steuerpflichtiger Beitrag zur Zusatzversorgung',
              type: 'number',
              stringifier: 'currency-eur',
            },
            ArbnAnteilZusatzVers: {
              description: 'Arbeitnehmeranteil zur Zusatzversorgung',
              type: 'number',
              stringifier: 'currency-eur',
            },
            AnzahlArbTag: {
              description: 'Anzahl Arbeitstage',
              type: 'integer',
            },
            StFreiArbgLeistg: {
              description:
                'steuerfreie Arbeitgeberleistungen für Fahrten zwischen Wohnung und Arbeitsstätte',
              type: 'number',
              stringifier: 'currency-eur',
            },
            PauschArbgLeistg: {
              description:
                'pauschal besteuerte Arbeitgeberleistungen für Fahrten zwischen Wohnung und Arbeitsstätte',
              type: 'number',
              stringifier: 'currency-eur',
            },
            StFreiFahrtKAusw: {
              description:
                'steuerfreie Arbeitgeberleistungen für Fahrtkosten bei Auswärtstätigkeit',
              type: 'number',
              stringifier: 'currency-eur',
            },
            StFreiVerpfleg: {
              description:
                'steuerfreier Arbeitgeberzuschuss für Verpflegung bei Auswärtstätigkeit',
              type: 'number',
              stringifier: 'currency-eur',
            },
            StFreiDopHaushalt: {
              description:
                'steuerfreie Arbeitgeberleistungen bei doppelter Haushaltsführung',
              type: 'number',
              stringifier: 'currency-eur',
            },
            FreibetragDbaTuerkei: {
              description: 'Freibetrag DBA Türkei',
              type: 'number',
              stringifier: 'currency-eur',
            },
            VBez: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  Wert: {
                    description:
                      'steuerbegünstigte Versorgungsbezüge (im Bruttoarbeitslohn enthalten)',
                    type: 'number',
                    stringifier: 'currency-eur',
                  },
                  Beginn: {
                    description:
                      'bei unterjähriger Zahlung: erster Monat, für den Versorgungsbezug gezahlt wurde',
                    type: 'string',
                  },
                  Ende: {
                    description:
                      'bei unterjähriger Zahlung: letzter Monat, für den Versorgungsbezug gezahlt wurde',
                    type: 'string',
                  },
                  Jahr: {
                    description:
                      'maßgebendes Kalenderjahr des Versorgungsbeginns',
                    type: 'string',
                  },
                  BmgFreibetrag: {
                    description:
                      'Bemessungsgrundlage für Versorgungsfreibetrag',
                    type: 'number',
                    stringifier: 'currency-eur',
                  },
                  Einmversbezug: {
                    description:
                      'einmalige Versorgungsbezüge (Sterbegeld, Kapitalauszahlungen, Abfindungen und Nachzahlungen - in steuerbegünstigten Versorgungsbezügen enthalten)',
                    type: 'number',
                    stringifier: 'currency-eur',
                  },
                },
              },
            },
            ErmStVBezMKalJahr: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  Wert: {
                    description:
                      'ermäßigt besteuerte Versorgungsbezüge für mehrere Kalenderjahre',
                    type: 'number',
                    stringifier: 'currency-eur',
                  },
                  Jahr: {
                    description:
                      'maßgebendes Kalenderjahr des Versorgungsbeginns bei ermäßigt besteuerten Versorgungsbezügen',
                    type: 'string',
                  },
                },
              },
            },
            NErmStVBezMKalJahr: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  Wert: {
                    description:
                      'Versorgungsbezüge für mehrere Kalenderjahre, die nicht ermäßigt besteuert wurden',
                    type: 'number',
                    stringifier: 'currency-eur',
                  },
                  Jahr: {
                    description:
                      'maßgebendes Kalenderjahr bei Versorgungsbezügen für mehrere Kalenderjahre, die nicht ermäßigt besteuert wurden',
                    type: 'string',
                  },
                },
              },
            },
            Kammerbeitrag: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  Wert: {
                    description: 'Kammerbeitrag',
                    type: 'number',
                    stringifier: 'currency-eur',
                  },
                  Bundesland: {
                    description: 'Kammerbeitrag/maßgebendes Bundesland',
                    type: 'string',
                  },
                },
              },
            },
          },
        },
        version: {
          type: 'string',
          enum: ['202201'],
        },
      },
      required: ['version'],
    },
  },
  required: ['VaSt_LStB'],
};
