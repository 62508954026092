import {
  BankingA352PaymentApiInterface,
  BankingA352PaymentDetailsExclusiveResponse,
} from '@expresssteuer/banking-api-interfaces';

export interface BankingA352Payment
  extends BankingA352PaymentApiInterface,
    Partial<BankingA352PaymentDetailsExclusiveResponse> {
  listId: string;
  payoutName: string;
  bic: string;
}
