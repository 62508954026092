import { Component, Input } from '@angular/core';
import { ClientTodo } from '@expresssteuer/client-todos-api-interfaces';

@Component({
  selector: 'esui-client-todo',
  templateUrl: './esui-client-todo.component.html',
  styleUrls: ['./esui-client-todo.component.scss'],
})
export class EsuiClientTodoComponent {
  @Input() todo?: ClientTodo | null;
}
