import { VastTypes } from '@expresssteuer/eric-lib-interfaces';
import { DOCUMENTTYPE } from '../../document/document';
import { Timestamp } from '../../helpers/timestamp';

export type Extraction<T = unknown> = {
  created: Timestamp;
  id: string;
  year: string;
  clientOrPartner: ClientOrPartner;
  /**
   * Link to binary document in documentcenter
   */
  binaryDocumentIds?: string[];
  type?: DOCUMENTTYPE;
  vastType?: VastTypes;
  extractedData?: T[];
  /**
   * If the record has been merged into a taxcase or a new taxcase has been build
   */
  processed: boolean;
  extractorVersion?: number;
};

/**
 * Helper for mock data only
 */
export class ExtractionFixtureBuilder<T = unknown> {
  private readonly extraction: Extraction<T>;

  constructor() {
    this.extraction = {
      created: null as any, // TODO
      id: 'mock-id',
      year: '2021',
      clientOrPartner: 'client',
      processed: false,
    };
  }

  withId(id: string): ExtractionFixtureBuilder<T> {
    this.extraction.id = id;
    return this;
  }

  withYear(year: string): ExtractionFixtureBuilder<T> {
    this.extraction.year = year;
    return this;
  }

  withClientOrPartner(
    clientOrPartner: ClientOrPartner
  ): ExtractionFixtureBuilder<T> {
    this.extraction.clientOrPartner = clientOrPartner;
    return this;
  }

  withBinaryDocumentIds(
    binaryDocumentIds: string[]
  ): ExtractionFixtureBuilder<T> {
    this.extraction.binaryDocumentIds = binaryDocumentIds;
    return this;
  }

  withType(type: DOCUMENTTYPE): ExtractionFixtureBuilder<T> {
    this.extraction.type = type;
    return this;
  }

  withVastType(vastType: VastTypes): ExtractionFixtureBuilder<T> {
    this.extraction.vastType = vastType;
    return this;
  }

  withExtractedData(extractedData: T[]): ExtractionFixtureBuilder<T> {
    this.extraction.extractedData = extractedData;
    return this;
  }

  withProcessed(processed: boolean): ExtractionFixtureBuilder<T> {
    this.extraction.processed = processed;
    return this;
  }

  withExtractorVersion(extractorVersion: number): ExtractionFixtureBuilder<T> {
    this.extraction.extractorVersion = extractorVersion;
    return this;
  }

  build(): Extraction<T> {
    return this.extraction;
  }
}

export function getExtractionId(file: {
  year: string;
  clientOrPartner: ClientOrPartner;
  type?: DOCUMENTTYPE;
  vastType?: VastTypes;
}) {
  if (!file.type && !file.vastType) {
    throw new Error('Either type or vastType must be provided');
  }
  return `${file.year}_${file.clientOrPartner}_${file.type ?? file.vastType}`;
}

export type ClientOrPartner = 'client' | 'partner';
