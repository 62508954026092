import { Component, Input } from '@angular/core';
import { ClientInlineComponent } from '../client-inline/client-inline.component';

@Component({
  selector: 'esui-client-card',
  templateUrl: './client-card.component.html',
  styleUrls: ['./client-card.component.scss'],
})
export class ClientCardComponent extends ClientInlineComponent {
  @Input() transparentCard = false;
}
