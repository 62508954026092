import { Component, Input } from '@angular/core';

@Component({
  selector: 'esui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  //TODO disable click if disabled
  /** Only used for style, click event is still fired */
  @Input() disabled = false;
}
