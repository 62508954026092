import { Component } from '@angular/core';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent {
  async createCustomer() {
    fetch(
      'http://localhost:5001/expresssteuer-development/europe-west3/httpsDebugUI?action=createTestCase&token=bWzVjKYcEvmIxZ3H1ojz&email=&password='
    )
      .then((r) => r.json())
      .then((j) => {
        console.log(j);
      });
  }
}
