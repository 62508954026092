import {
  BankAccountActivity,
  BankAccountStatus,
  BankAccountType,
} from './bank-account';
import { BankProvider } from './bank-provider';
import { Timestamp } from '@google-cloud/firestore';

export interface ClientBankAccount {
  active: boolean;
  balance: number;
  iban: string;
  bic: string;
  provider: BankProvider;
  clientId: string;
  status: BankAccountStatus;
  currency: string;
  created: Timestamp;
  updated: Timestamp;
  activity: BankAccountActivity;
  id: string;
  lastActivity: Timestamp;
  taxadvisorId: string;
  type: BankAccountType;
  isBalanceConsistent?: boolean;
}
