import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JOBSTATUS } from '@expresssteuer/models';

@Component({
  selector: 'app-control-jobstatus',
  templateUrl: './control-jobstatus.component.html',
  styleUrls: ['./control-jobstatus.component.scss'],
})
export class ControlJobstatusComponent implements OnInit {
  JOBSTATUS = JOBSTATUS;
  jobStatusValue: JOBSTATUS;

  @Output()
  jobstatusChange = new EventEmitter<JOBSTATUS>();

  @Input()
  get jobstatus(): JOBSTATUS {
    return this.jobStatusValue;
  }

  set jobstatus(val: JOBSTATUS) {
    this.jobStatusValue = val;
    this.jobstatusChange.emit(this.jobStatusValue);
  }

  ngOnInit(): void {}
}
