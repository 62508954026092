<div *ngFor="let expense of expenses">
  {{ expense.description }} - <code>{{ expense.id }}</code>
  <button
    class="float-right"
    mat-raised-button
    (click)="deleteExpense.emit(expense)"
  >
    delete
  </button>
  <button
    mat-raised-button
    color="primary"
    class="float-right"
    (click)="editExpense.emit(expense)"
  >
    edit
  </button>

  <hr />
</div>
