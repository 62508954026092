<div class="d-flex w-100 justify-content-start">
  <app-ais-documents-icon [mimeType]="hit.contentType"></app-ais-documents-icon>
  <table class="ml-6 table-sm">
    <tbody>
      <tr>
        <td colspan="2">
          <a
            [routerLink]="linkToItem"
            class="text-dark text-hover-primary mb-1 font-size-lg"
          >
            <ais-highlight attribute="name" [hit]="hit"></ais-highlight>
          </a>
        </td>
      </tr>
      <tr>
        <td>objectID</td>
        <td>
          <samp>
            <ais-highlight attribute="objectID" [hit]="hit"></ais-highlight>
          </samp>
        </td>
      </tr>
      <tr>
        <td>clientID</td>
        <td>
          <samp>
            <ais-highlight
              attribute="metadata.clientId"
              [hit]="hit"
            ></ais-highlight>
          </samp>
        </td>
      </tr>
      <tr>
        <td>Client</td>
        <td>
          <samp
            *ngIf="hit.metadata?.client?.displayName?.length > 0; else empty"
          >
            <ais-highlight
              attribute="metadata.client.displayName"
              [hit]="hit"
            ></ais-highlight>
          </samp>
        </td>
      </tr>
      <tr>
        <td>contentType</td>
        <td>
          <samp>
            <ais-highlight attribute="contentType" [hit]="hit"></ais-highlight>
          </samp>
        </td>
      </tr>
      <tr>
        <td>uploaded</td>
        <td>
          <samp>{{ hit.uploaded }}</samp>
        </td>
        <!-- TODO check weird dates -->
      </tr>
      <tr>
        <td>description</td>
        <td>
          <samp *ngIf="hit.description; else empty">
            <ais-highlight attribute="description" [hit]="hit"></ais-highlight>
          </samp>
        </td>
      </tr>
      <tr>
        <td>ocr</td>
        <td>
          <samp *ngIf="hit.metadata?.mldata?.ocrText; else empty">
            <ais-highlight
              attribute="metadata.mldata.ocrText"
              [hit]="hit"
            ></ais-highlight>
          </samp>
        </td>
      </tr>
      <tr>
        <td>labels</td>
        <td>
          <small
            *ngIf="
              hit.metadata?.mldata?.labelAnnotations?.length > 0;
              else empty
            "
          >
            <span
              *ngFor="
                let label of hit.metadata.mldata.labelAnnotations;
                let i = index
              "
              class="badge bg-secondary mr-2"
            >
              {{ hit.metadata.mldata.labelAnnotations[i].description }}
              <!-- TODO check why ais-highlight is not working for the labelAnnotations -->
              <!-- <ais-highlight [attribute]="'hit.metadata.mldata.labelAnnotations['+i+'].description'"
                                [hit]="hit"></ais-highlight> -->
            </span>
          </small>
        </td>
      </tr>
      <ng-template #empty><span>--</span></ng-template>
    </tbody>
  </table>
  <div class="flex-fill"></div>

  <esui-document-viewer
    style="display: block"
    viewerStyle="display: block; max-height: 200px; max-width: 200px;"
    [downloadUrl]="hit.file.filepath | getDownloadURL"
    [contentType]="hit.contentType"
    [showNavigation]="true"
    [showDownloadButton]="true"
    [source]="hit.source"
  >
  </esui-document-viewer>
</div>
