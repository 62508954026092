import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { EsuiDebugService } from '../esui-debug/services/esui-debug.service';

@Component({
  selector: 'esui-stats-for-nerds',
  templateUrl: './stats-for-nerds.component.html',
  styleUrls: ['./stats-for-nerds.component.scss'],
})
export class StatsForNerdsComponent {
  @Input() data?: unknown;
  @Input() label?: string;

  @Input() open = false;
  @Output() openChange = new EventEmitter<boolean>();

  constructor(@Optional() public debugService?: EsuiDebugService) {}

  toggle() {
    this.open = !this.open;
    this.openChange.next(this.open);
    if (this.open) {
      console.log('StatsForNerdsComponent', this.data);
    }
  }
}
