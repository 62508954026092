import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  PartnerGetInput,
  PartnerGetOutput,
} from '@expresssteuer/client-api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class PartnerGet {
  constructor(public afFunctions: AngularFireFunctions) {}

  call(input: PartnerGetInput) {
    console.log('Calling client-partnerGet');
    return this.afFunctions.httpsCallable<PartnerGetInput, PartnerGetOutput>(
      'client-partnerGet'
    )(input);
  }
}
