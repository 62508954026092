import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AisDocumentsHelperService {
  static mapClusterToLabel(clusterName: string) {
    switch (clusterName) {
      case 'expense':
        return 'Ausgaben';
      case 'identitycard':
        return 'Ausweise';
      case 'receipt':
        return 'Quittung';
      case 'signature':
        return 'Unterschriften';
      case 'taxoffice':
        return 'Finanzamt';
      case 'testtaxoffice':
        return 'Test Finanzamt';
      case 'wagestatement':
        return 'Gehaltsabrechnungen';
      case 'invoice':
        return 'Rechnung';
      case 'others':
        return 'Anderes';
      case 'representationAuthorization':
        return 'Vollmachten';
      default:
        return clusterName;
    }
  }

  static refineClusterListItem(items: any[]): any[] {
    return items.map((item) => {
      const label = AisDocumentsHelperService.mapClusterToLabel(item.value);
      const highlighted = AisDocumentsHelperService.mapClusterToLabel(
        (item as any).highlighted
      ); // TODO check/update type
      return {
        ...item,
        highlighted,
        label,
      };
    });
  }

  /**
   * Group hits into clusters based on the hit's `type`.
   * A hit can be sorted into multiple cluster.
   * When no cluster can be found the hit is sorted into the cluster with the "`othersClusterName`".
   */
  static clusterHitsByDocumenttype<
    T extends { type?: { [key: string]: boolean } | string; [key: string]: any }
  >(hits: T[]) {
    const othersClusterName = 'others';

    const map = hits.reduce((acc, curr) => {
      // get hits's clusterNames:
      let clusterNames = [];
      if (typeof curr.type === 'object') {
        clusterNames = Object.keys(curr.type || {}).filter(
          (key) => curr.type[key]
        );
      } else if (typeof curr.type === 'string') {
        clusterNames = [curr.type];
      }
      if (clusterNames.length <= 0) {
        // no cluster found, use others:
        clusterNames.push(othersClusterName);
      }
      // add hit to each found cluster:
      clusterNames.forEach((clusterName) => {
        const collectionForKey = acc.has(clusterName)
          ? acc.get(clusterName)
          : [];
        collectionForKey.push(curr);
        acc.set(clusterName, collectionForKey);
      });
      return acc;
    }, new Map<string, T[]>());
    return map;
  }

  static trackByKey(index: number, item: [string, unknown]) {
    return item[0];
  }

  static trackByObjectID(index: number, { objectID }: { objectID: string }) {
    return objectID;
  }
}
