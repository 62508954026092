<ng-container *ngIf="taxNotice && document">
  <mat-card class="taskContainer">
    <span esuiCardKey>Taxnotice Appeal Submit</span>
    <mat-card-header>
      <mat-card-title> </mat-card-title>
      <mat-card-subtitle> </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="contentContainer">
      <div class="focus" *ngIf="document">
        <div class="documentViewer">
          <esui-document-viewer
            [contentType]="document.contentType"
            [downloadUrl]="document.downloadUrl"
            [source]="document.source"
            [isLoading]="documentStoreStateIndicator?.isSaving"
            [hasErrors]="documentStoreStateIndicator?.hasErrors"
            [showNavigation]="true"
            [showDownloadButton]="true"
          ></esui-document-viewer>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="actions">
      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="acceptAnyway.next()"
        [disabled]=""
      >
        Accept Anyway
      </button>
      <div class="disclaimerButton">
        <button
          type="button"
          mat-raised-button
          color="primary"
          (click)="appealDone.next()"
          [disabled]=""
        >
          Sent
        </button>
        <div class="submitText">
          make sure an appeal for this taxnotice has been submitted before
          closing this task!
        </div>
      </div>
    </mat-card-actions>
  </mat-card></ng-container
>
