/* eslint-disable */

/**
 * @note whole document added in 2021
 */
export interface VaSt_GDB {
  VaSt_GDB: VaSt_GDB_CType;
}

export interface VaSt_GDB_CType {
  /**
   * Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung
   */
  Eingangsdatum?: string;
  /**
   * Übermittelnde Stelle
   */
  Verfasser?: string;
  /**
   * Jahr
   */
  Meldejahr?: string;
  /**
   * Datum der Feststellung
   */
  FeststellungsDat?: string;
  BetroffenePerson?: BetroffenePersonM2062374159_CType;
  /**
   * @maxItems 10
   */
  GradDerBehinderung?:
    | GradDerBehinderungM2062374159_CType
    | GradDerBehinderungM2062374159_CType[];
  /**
   * @maxItems 10
   */
  Merkzeichen?: MerkzeichenM2062374159_CType | MerkzeichenM2062374159_CType[];
  /**
   * @note removed in 2022
   */
  DauerndKoerpEinsch?: DauerndKoerpEinschM2062374159_CType;
  /**
   * @note removed in 2022
   */
  TypBerufskrankheit?: TypBerufskrankheitM2062374159_CType;
  Hinterbliebene?: HinterbliebeneM2062374159_CType;
  version: '202101';
}

export interface BetroffenePersonM2062374159_CType {
  /**
   * Identifikationsnummer
   */
  IdNr?: string;
  /**
   * Nachname
   */
  Name?: string;
  /**
   * Vorname
   */
  Vorname?: string;
  /**
   * Name
   */
  Blockname?: string;
  /**
   * Namensvorsatz
   */
  NamensVorsatz?: string;
  /**
   * Namenszusatz
   */
  NamensZusatz?: string;
  /**
   * Titel
   */
  Titel?: string;
}

export interface GradDerBehinderungM2062374159_CType {
  /**
   * Grad der Behinderung
   */
  Grad?: string;
  /**
   * Gültig von
   */
  VonDat?: string;
  /**
   * Gültig bis
   */
  BisDat?: string;
  /**
   * Unbefristet gültig
   */
  Unbefristet?: '1';
}

export interface MerkzeichenM2062374159_CType {
  /**
   * Merkzeichen
   */
  Merkmal?: string;
  /**
   * Gültig von
   */
  VonDat?: string;
  /**
   * Gültig bis
   */
  BisDat?: string;
  /**
   * Unbefristet gültig
   */
  Unbefristet?: '1';
}

/**
 * @note removed in 2022
 */
export interface DauerndKoerpEinschM2062374159_CType {
  /**
   * Gültig von
   */
  VonDat?: string;
  /**
   * Gültig bis
   */
  BisDat?: string;
}

/**
 * @note removed in 2022
 */
export interface TypBerufskrankheitM2062374159_CType {
  /**
   * Gültig von
   */
  VonDat?: string;
  /**
   * Gültig bis
   */
  BisDat?: string;
}

export interface HinterbliebeneM2062374159_CType {
  /**
   * Berücksichtigung Hinterbliebenen-Pauschbetrag
   */
  Gewaehrt?: '1';
  /**
   * Von
   */
  VonDat?: string;
  /**
   * Bis
   */
  BisDat?: string;
  /**
   * dauerhaft von der Finanzverwaltung gewährt
   */
  Unbefristet?: '1';
}
