import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { EsuiContentTagSelectionComponent } from './esui-content-tag-selection.component';

@NgModule({
  declarations: [EsuiContentTagSelectionComponent],
  imports: [
    CommonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    FormsModule,
    MatInputModule,
  ],
  exports: [EsuiContentTagSelectionComponent],
})
export class EsuiContentTagSelectionModule {}
