import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { NotificationService } from './services/helpers/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ExS CRM : ';

  private _showAsideRight = false;

  private subs: Subscription[] = [];

  @Output()
  public showAsideEmitter = new EventEmitter<boolean>();

  public switchAsideRight(): void {
    this.showAsideRight = !this.showAsideRight;
  }

  public set showAsideRight(val: boolean) {
    this._showAsideRight = val;
    this.showAsideEmitter.emit(val);
  }

  @Input()
  public get showAsideRight(): boolean {
    return this._showAsideRight;
  }

  constructor(
    private aff: AngularFireFunctions,
    private afStorage: AngularFireStorage,
    private afAuth: AngularFireAuth,
    private notificationService: NotificationService
  ) {
    this.subs.push(
      this.afAuth.user.subscribe((user) => {
        if (!user?.email) {
          return;
        }
        const isAllowed = user.email.endsWith(`@expresssteuer.com`);
        if (!isAllowed) {
          void this.afAuth.signOut();
          this.notificationService.warning(
            'Falsche Email',
            'Bitte melde dich mit deiner Expresssteuer Email an.'
          );
        }
      })
    );
  }

  public ngOnInit() {
    if (environment.emulator) {
      console.warn('RUNNING ON LOCAL FIREBASE EMULATOR');
      this.aff
        .useFunctionsEmulator('http://localhost:5001')
        .then(() => console.warn('Using functions emulator'));

      this.afStorage.storage.useEmulator('localhost', 9199);
      console.warn('Using storage emulator');
    }
  }

  ngOnDestory() {
    this.subs.forEach((s) => s.unsubscribe());
    this.subs = [];
  }
}
