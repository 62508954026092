<div class="year-select-container">
  <div class="year-range-fields">
    <mat-form-field class="input-year-style">
      <mat-label>From</mat-label>
      <input
        matInput
        maxlength="4"
        placeholder="Ex. 2021"
        type="number"
        [(ngModel)]="fromYear"
      />
    </mat-form-field>
    -
    <mat-form-field class="input-year-style">
      <mat-label>To</mat-label>
      <input
        matInput
        maxlength="4"
        placeholder="Ex. 2024"
        type="number"
        [(ngModel)]="toYear"
      />
    </mat-form-field>
    <button mat-mini-fab (click)="onRangeAdded(fromYear, toYear)">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-mini-fab color="warn" (click)="clearYears()">
      <mat-icon>delete</mat-icon>
    </button>
    <mat-error *ngIf="currentFormError">
      Please enter a valid year between 2018 and 2100
    </mat-error>
  </div>
  <mat-chip-list class="year-chip-container" *ngIf="selectedYears">
    <mat-chip *ngFor="let year of selectedYears" (click)="deleteYear.emit(year)"
      >{{ year }}
    </mat-chip>
  </mat-chip-list>
</div>
