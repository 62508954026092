console.log('USING DEV ENV');

export const environment = {
  production: false,
  emulator: false,
  isMockEnabled: false, // You have to switch this, when your real back-end is done
  algolia: {
    appId: 'W6Z6VUY52V',
    indexNames: {
      documents_v2: 'dev_documents_v2',
      taxcases: 'dev_taxcases',
      messages: 'dev_messages',
      documents: 'dev_documents',
      clients: 'dev_clients',
    },
  },
  firebase: {
    apiKey: 'AIzaSyA7aHq45T9jOnO6Gsc8_mxNSx7UEKJZPMc',
    authDomain: 'expresssteuer-development.firebaseapp.com',
    projectId: 'expresssteuer-development',
    storageBucket: 'expresssteuer-development.appspot.com',
    messagingSenderId: '849191636990',
    appId: '1:849191636990:web:6de303079cf9f04b87baaf',
    databaseURL: 'https://expresssteuer-development.firebaseio.com',
    measurementId: 'G-QCKMDL982G',
  },
  storage: {
    signedContractsBucket: 'expresssteuer-dev-signed-contracts',
  },
  client_id:
    '67549507632-v5apl567oilfcgvpscu1phvgn8hh0gp3.apps.googleusercontent.com',
  GOOGLE_MAPS_API_KEY: 'AIzaSyA_6-IH14ugWxj6UIOaBq5swwN7Ta7dOAQ',
  FB_ACCOUNT: '2327542700662420',
  GA_ACCOUNT: 'UA-137473816',
  cloudfunctionsUrl: 'https://europe-west3-dcg-dev.cloudfunctions.net',
  taskIsland: {
    endpoint: 'https://dev-tasks.expresssteuer.com',
  },
  toggles: {
    enableDevUpdateClientForAndorra: true,
    enableTaxofficeCommunication: true,
  },
};
