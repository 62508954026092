import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EsuiDocumentViewerModule } from '@expresssteuer/ui-components';
import { NgbDateAdapter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TimeagoModule } from 'ngx-timeago';
import { DropzoneDirective } from '../../../app/directives/dropzone.directive';
import { NgbDateFirestoreAdapter } from '../../../app/shared/adapters/ngb-date-firestore-adapter.service';
import { ControlCampaignComponent } from '../../../app/shared/components/campaign/control-campaign.component';
import { ControlClientheaderComponent } from '../../../app/shared/components/client/clientheader/clientheader.component';
import { ControlDisabilityComponent } from '../../../app/shared/components/control-disability/control-disability.component';
import { ControlJobWorkingDaysaweekComponent } from '../../../app/shared/components/control-job-working-daysaweek/control-job-working-daysaweek.component';
import { ControlJobstatusComponent } from '../../../app/shared/components/control-jobstatus/control-jobstatus.component';
import { ControlRadioButtonGroupComponent } from '../../../app/shared/components/control-radio-button-group/control-radio-button-group.component';
import { FirebaseDatePickerComponent } from '../../../app/shared/components/firebase-date-picker/firebase-date-picker.component';
import { ControlGoogleAddressComponent } from '../../../app/shared/components/google-address/google-address.component';
import { GobackAndSaveComponent } from '../../../app/shared/components/header/goback-and-save/goback-and-save.component';
import { ControlMaritalStatusBarComponent } from '../../../app/shared/components/marital-status-bar/marital-status-bar.component';
import { ControlTaxclassComponent } from '../../../app/shared/components/taxclass/taxclass.component';
import { ControlClientNameComponent } from '../../../app/shared/controls/control-client-name/control-client-name.component';
import { ControlClientReturnValueComponent } from '../../../app/shared/controls/control-client-return-value/control-client-return-value.component';
import { ControlClientTagifyComponent } from '../../../app/shared/controls/control-client-tagify/control-client-tagify.component';
import { ControlCustomerTagifyComponent } from '../../../app/shared/controls/control-customer-tagify/control-customer-tagify.component';
import { ControlDocumentSafesearchComponent } from '../../../app/shared/controls/control-document-safesearch/control-document-safesearch.component';
import { ControlStaticDataDropdownComponent } from '../../../app/shared/controls/control-staticdata-dropdown/control-staticdata-dropdown.component';
import { AsideComponent } from '../../../app/shared/layout/aside/aside.component';
import { AsiderightComponent } from '../../../app/shared/layout/asideright/asideright.component';
import { ChatpanelComponent } from '../../../app/shared/layout/chatpanel/chatpanel.component';
import { FlyoutrightComponent } from '../../../app/shared/layout/flyoutright/flyoutright.component';
import { FooterComponent } from '../../../app/shared/layout/footer/footer.component';
import { NavigationComponent } from '../../../app/shared/layout/navigation/navigation.component';
import { NotificationsComponent } from '../../../app/shared/layout/notifications/notifications.component';
import { QuickactionsComponent } from '../../../app/shared/layout/quickactions/quickactions.component';
import { QuickpanelComponent } from '../../../app/shared/layout/quickpanel/quickpanel.component';
import { StickytoolbarComponent } from '../../../app/shared/layout/stickytoolbar/stickytoolbar.component';
import { UserpanelComponent } from '../../../app/shared/layout/userpanel/userpanel.component';
import { NgxAutocomPlaceModule } from '../../services/ngx-places/ngx-autocom-place.module';
import { ControlIbanValidatorComponent } from './control-iban-validator/control-iban-validator.component';
import { DocumentuploaderComponent } from './documentuploader/documentuploader.component';
import { UploadTaskComponent } from './documentuploader/upload-task/upload-task.component';
import { DocumentInlinePreviewComponent } from './inline-document-preview/inline-document-preview.component';
import { NumberPickerComponent } from './legacy-number-input/legacy-number-input.component';

@NgModule({
  declarations: [
    GobackAndSaveComponent,
    FirebaseDatePickerComponent,
    AsideComponent,
    NavigationComponent,
    StickytoolbarComponent,
    FlyoutrightComponent,
    FooterComponent,
    AsiderightComponent,
    NotificationsComponent,
    QuickactionsComponent,
    UserpanelComponent,
    QuickpanelComponent,
    ChatpanelComponent,
    ControlClientheaderComponent,
    ControlClientTagifyComponent,
    ControlCustomerTagifyComponent,
    ControlClientNameComponent,
    ControlClientReturnValueComponent,
    ControlDocumentSafesearchComponent,
    ControlCampaignComponent,
    ControlGoogleAddressComponent,
    ControlMaritalStatusBarComponent,
    ControlTaxclassComponent,
    ControlStaticDataDropdownComponent,
    ControlDisabilityComponent,
    ControlJobWorkingDaysaweekComponent,
    ControlJobstatusComponent,
    ControlRadioButtonGroupComponent,
    ControlIbanValidatorComponent,
    DocumentuploaderComponent,
    UploadTaskComponent,
    DropzoneDirective,
    DocumentInlinePreviewComponent,
    NumberPickerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxAutocomPlaceModule,
    RouterModule,
    NgbModule,
    TimeagoModule.forRoot(),
    SweetAlert2Module.forRoot(),
    AngularFireStorageModule,
    EsuiDocumentViewerModule,
  ],
  exports: [
    CommonModule,
    GobackAndSaveComponent,
    FirebaseDatePickerComponent,
    TimeagoModule,
    SweetAlert2Module,
    AsideComponent,
    NavigationComponent,
    StickytoolbarComponent,
    FlyoutrightComponent,
    FooterComponent,
    AsiderightComponent,
    NotificationsComponent,
    QuickactionsComponent,
    UserpanelComponent,
    QuickpanelComponent,
    ChatpanelComponent,
    ControlClientheaderComponent,

    ControlClientTagifyComponent,
    ControlCustomerTagifyComponent,

    ControlClientNameComponent,

    ControlClientReturnValueComponent,
    ControlDocumentSafesearchComponent,
    ControlCampaignComponent,
    ControlGoogleAddressComponent,
    ControlMaritalStatusBarComponent,
    ControlTaxclassComponent,
    ControlStaticDataDropdownComponent,
    ControlDisabilityComponent,
    ControlJobWorkingDaysaweekComponent,
    ControlJobstatusComponent,
    ControlRadioButtonGroupComponent,
    ControlIbanValidatorComponent,
    DocumentuploaderComponent,
    UploadTaskComponent,
    FormsModule,
    DropzoneDirective,
    DocumentInlinePreviewComponent,
    NumberPickerComponent,
  ],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateFirestoreAdapter }],
})
export class SharedComponentsModule {}
