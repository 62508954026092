import { SchemaProperty } from './vast';

export const VastKrvSchema: SchemaProperty = {
  title: 'VaSt_KRV-202201.xsd',
  $schema: 'http://json-schema.org/schema#',

  type: 'object',
  properties: {
    VaSt_KRV: {
      type: 'object',
      description:
        'Bescheinigung zur Basiskrankenversicherung und gesetzlichen Pflegeversicherung',
      properties: {
        Eingangsdatum: {
          description:
            'Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung',
          type: 'string',
        },
        BetroffenerFall: {
          type: 'object',
          properties: {
            Meldejahr: {
              description: 'Jahr der Zahlung/Erstattung',
              type: 'string',
            },
            BetroffenePerson: {
              type: 'object',
              properties: {
                NatPerson: {
                  type: 'object',
                  properties: {
                    IdNr: {
                      description: 'Versicherte Person: Identifikationsnummer',
                      type: 'string',
                    },
                    Titel: {
                      description: 'Versicherte Person: Titel',
                      type: 'string',
                    },
                    Vorname: {
                      description: 'Versicherte Person: Vorname',
                      type: 'string',
                    },
                    NamensVorsatz: {
                      description: 'Versicherte Person: Namensvorsatz',
                      type: 'string',
                    },
                    Name: {
                      description: 'Versicherte Person: Nachname',
                      type: 'string',
                    },
                    NamensZusatz: {
                      description: 'Versicherte Person: Namenszusatz',
                      type: 'string',
                    },
                    Blockname: {
                      description: 'Versicherte Person: Name',
                      type: 'string',
                    },
                    GebDat: {
                      description: 'Versicherte Person: Geburtsdatum',
                      type: 'string',
                    },
                  },
                },
                WeiterePerson: {
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {
                      NatPerson: {
                        type: 'object',
                        properties: {
                          IdNr: {
                            description:
                              'Versicherungsnehmer: Identifikationsnummer',
                            type: 'string',
                          },
                          Titel: {
                            description: 'Versicherungsnehmer: Titel',
                            type: 'string',
                          },
                          Vorname: {
                            description: 'Versicherungsnehmer: Vorname',
                            type: 'string',
                          },
                          NamensVorsatz: {
                            description: 'Versicherungsnehmer: Namensvorsatz',
                            type: 'string',
                          },
                          Name: {
                            description: 'Versicherungsnehmer: Nachname',
                            type: 'string',
                          },
                          NamensZusatz: {
                            description: 'Versicherungsnehmer: Namenszusatz',
                            type: 'string',
                          },
                          Blockname: {
                            description: 'Versicherungsnehmer: Name',
                            type: 'string',
                          },
                        },
                      },
                      NNPers: {
                        type: 'object',
                        properties: {
                          Firmenname: {
                            description: 'Versicherungsnehmer',
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        Versicherung: {
          type: 'object',
          properties: {
            Name: {
              description: 'Übermittelnde Stelle',
              type: 'string',
            },
          },
        },
        Vertragsdaten: {
          type: 'object',
          properties: {
            EinwilligDat: {
              description: 'Einwilligungsdatum (zur Datenübermittlung)',
              type: 'string',
            },
            Beitragsdaten: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  BetragArt: {
                    description: 'Beitragsart',
                    type: 'string',
                    enum: [
                      '01',
                      '02',
                      '05',
                      '06',
                      '07',
                      '10',
                      '15',
                      '16',
                      '17',
                      '18',
                      '11',
                      '13',
                      '12',
                      '14',
                    ],
                  },
                  MerkmalGesBeiEinw: {
                    description:
                      'Einwilligung zur Übermittlung des Gesamtbetrags',
                    type: 'string',
                    enum: ['true', 'false'],
                  },
                  MerkmalBeitragArt: {
                    description: 'Merkmal zum Beitrag',
                    type: 'string',
                    enum: ['1', '2'],
                  },
                  MerkmalBeiTrag: {
                    description: 'Beitragstragung',
                    type: 'string',
                    enum: ['3', '2', '1'],
                  },
                  Beginn: {
                    description:
                      'Beginn des Zeitraums, für den die Zahlungen/Erstattungen erfolgen',
                    type: 'string',
                  },
                  Ende: {
                    description:
                      'Ende des Zeitraums, für den die Zahlungen/Erstattungen erfolgen',
                    type: 'string',
                  },
                  Betrag: {
                    description:
                      'Höhe der geleisteten/erstatteten Beiträge/Zuschüsse',
                    type: 'number',
                    stringifier: 'currency',
                  },
                  Waehrung: {
                    description:
                      'Währung der geleisteten/erstatteten Beiträge/Zuschüsse',
                    type: 'string',
                  },
                },
              },
            },
          },
        },
        version: {
          type: 'string',
          enum: ['202201'],
        },
      },
      required: ['version'],
    },
  },
  required: ['VaSt_KRV'],
};
