import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'esui-floating-tab-bar-tab',
  templateUrl: './floating-tab-bar-tab.component.html',
  styleUrls: ['./floating-tab-bar-tab.component.scss'],
})
export class FloatingTabBarTabComponent {
  @Input() @HostBinding('class.open') open = false;
  @Output() openChange = new EventEmitter<boolean>();

  /**
   * When set to false, the `@Input() open` will not directly be modified on change
   */
  @Input() useInternalState = true;

  buttonClickHandler() {
    const newState = !this.open;
    this.openChange.next(newState);
    if (this.useInternalState) {
      this.open = newState;
    }
  }
}
