import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Event, NavigationEnd, Router } from '@angular/router';
import {
  ClientMetadata,
  DIRECTION,
  InboxMessage,
  InboxSender,
  Message,
  MESSAGETYPE,
  User,
} from '@expresssteuer/models';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ClientsService } from '../../../../app/services/data/clients.service';
import { DocumentsService } from '../../../../app/services/data/documents.service';
import { MessagingService } from '../../../../app/services/data/messaging.service';
import { UsersService } from '../../../../app/services/data/users.service';
import { BlockUiService } from '../../../../app/services/helpers/blockui.service';
import { NotificationService } from '../../../../app/services/helpers/notification.service';
import { AuthenticationService } from '../../../../app/services/security/authentication.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let KTAppChat: any;

@Component({
  selector: 'app-messaging-v2',
  templateUrl: './messaging-v2.component.html',
  styleUrls: ['./messaging-v2.component.scss'],
})
export class MessagingV2Component implements AfterViewInit, OnDestroy {
  public debug = console.debug;

  public keyAccountFilterActive = true;

  subscriptions: Subscription[] = [];

  currentPath = '';

  selectedClient;
  selectedMessage: InboxSender;
  selectedClientCommunication: Observable<Message[]>;

  preSelectedMessage: InboxMessage;

  senders: InboxSender[] = [];

  public filterUser: User;
  public allUsers: User[];

  public allUsersWithCurrent: Observable<{
    allUsers: User[];
    currentUser: User;
  }>;

  public toggleNotAssignedNewMessages = false;

  limits = [
    1, 5, 10, 20, 30, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000,
    2000, 4000, 5000, 10000,
  ];

  constructor(
    private router: Router,
    public messagingservice: MessagingService,
    public documentsservice: DocumentsService,
    public clientService: ClientsService,
    public userservice: UsersService,
    public notificationService: NotificationService,
    public authenticationService: AuthenticationService,
    public blockUiService: BlockUiService,
    private afs: AngularFirestore,
    public security: AuthenticationService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const urls = event.url.split('/');
        this.currentPath = urls[urls.length];
        console.log('current message path ', this.currentPath);
      }
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
  }

  ngAfterViewInit() {
    this.updateChatScreen();
    // Init PreSelected Message
    this.loadUsers();
    this.initMessages();
  }

  public initMessages() {
    this.authenticationService.user$.subscribe((authUser) => {
      this.filterUser = authUser;
      this.loadMessages();
    });
  }

  public loadUsers() {
    this.userservice.users$.pipe(take(1)).subscribe((uList) => {
      this.allUsers = uList;
    });
  }

  public loadMessages() {
    if (this.keyAccountFilterActive) {
      this.loadKeyAccountMessages();
    } else {
      this.loadOpenMessages();
    }
  }

  public loadKeyAccountMessages() {
    this.ngOnDestroy();
    this.keyAccountFilterActive = true;
    this.messagingservice.keyAccountId = this.filterUser.authId;

    console.log('Load Message for: ', this.messagingservice.keyAccountId);

    this.subscriptions.push(
      this.messagingservice.messagesInboxKeyAccount$.subscribe((mList) => {
        this.senders = mList;
        if (mList.length > 0 && !this.selectedClient) {
          this.setMessage(mList[0]);
          this.updateChatScreen();
        }
      })
    );

    console.log('this.subscriptions', this.subscriptions.length);
  }

  public loadOpenMessages() {
    this.ngOnDestroy();

    this.keyAccountFilterActive = false;

    console.log('Load Open Messages');

    this.subscriptions.push(
      this.messagingservice.messagesInboxNewFiltered$.subscribe((mList) => {
        this.senders = mList;
        if (mList.length > 0 && !this.selectedClient) {
          this.setMessage(mList[0]);
          this.updateChatScreen();
        }
      })
    );
  }

  public changeOrder() {
    if (this.messagingservice.sortDirection === 'asc') {
      this.messagingservice.sortDirection = 'desc';
    } else {
      this.messagingservice.sortDirection = 'asc';
    }

    this.loadMessages();
  }

  public filterUserChange(aEvent) {
    this.filterUser = aEvent;
    this.messagingservice.keyAccountId = aEvent.authId;
    console.log('filterUserChange', this.messagingservice.keyAccountId);
    this.loadKeyAccountMessages();
  }

  public updateChatScreen() {
    console.log('update chat screen');
    setTimeout(() => {
      KTAppChat.init('kt_chat_aside_inbox', 'kt_chat_aside_outbox');
    }, 1000);
  }

  public renderMessage(m: Message): string {
    const message =
      m.type === MESSAGETYPE.EMAIL
        ? '<h5>'.concat(m.subject).concat('</h5>').concat(m.message)
        : m.message;

    return message.length > 0
      ? message
      : '<small>attachment without message</small>';
  }

  public setMessage(aMsg: InboxSender) {
    this.selectedClient = aMsg.from;
    this.selectedMessage = aMsg;

    // for debugging ease, show id in url
    window.history.replaceState({}, '', `/messaging/inbox/` + aMsg.id);

    if (this.selectedClient && !this.selectedClient.metadata) {
      this.selectedClient.metadata = ClientMetadata.getTemplate();
    }

    if (this.selectedClient && this.selectedClient.customerId) {
      this.selectedClientCommunication =
        this.clientService.getCurrentClientMessages(
          this.selectedClient.customerId
        );
    } else {
      if (aMsg.lastUnreadMessage.type === MESSAGETYPE.EMAIL) {
        this.selectedClientCommunication =
          this.clientService.getCurrentClientMessagesByEmail(
            this.selectedClient.senderAddress
          );
      }
      if (
        aMsg.lastUnreadMessage.type === MESSAGETYPE.WHATSAPP ||
        aMsg.lastUnreadMessage.type === MESSAGETYPE.SMS
      ) {
        this.selectedClientCommunication =
          this.clientService.getCurrentClientMessagesByMobile(
            this.selectedClient.senderAddress
          );
      }
    }

    this.updateChatScreen();
  }

  public assignKeyAccount(assignToFilterUser = false) {
    this.blockUiService.blockContentSection();
    if (this.selectedClient && this.selectedMessage) {
      let keyAccount: User;
      if (assignToFilterUser) {
        keyAccount = this.filterUser;
      } else {
        keyAccount = this.authenticationService.user;
      }

      this.selectedMessage.keyAccount = keyAccount;

      if (this.selectedClient.customerId) {
        this.afs
          .collection('clients')
          .doc(this.selectedClient.customerId)
          .update({ metadata: { keyAccount } })
          .then(() => {
            //console.debug('success', this.selectedClient.metadata.keyAccount);
            this.sendAssignSuccessNotification();
            this.blockUiService.unblockContentSection();
          })
          .catch((error) => {
            console.error(error);
            this.blockUiService.unblockContentSection();
          });
      } else {
        this.blockUiService.unblockContentSection();
      }
    } else {
      console.error('keyaccount, missing information');
      this.blockUiService.unblockContentSection();
    }
  }

  public markAllRead() {
    this.blockUiService.blockContentSection();

    this.selectedClientCommunication
      .pipe(take(1))
      .subscribe(async (messages) => {
        for await (const message of messages) {
          if (
            !message.metadata.read &&
            message.direction === DIRECTION.INBOUND
          ) {
            message.metadata.read = true;
            this.messagingservice.updateRead(message);
          }
        }
        this.blockUiService.unblockContentSection();
      });
  }

  public async reply() {
    const client = this.selectedClient.customerId
      ? await this.clientService.load(this.selectedClient.customerId, true)
      : null;

    this.messagingservice.openMessanger(
      this.selectedMessage.lastUnreadMessage.type,
      client,
      'Antwort: ' + this.selectedMessage.lastUnreadMessage.subject
    );
  }

  private sendAssignSuccessNotification() {
    this.notificationService.success(
      'Zugewiesen',
      'Kunde wurde dir erfolgreich zugewiesen'
    );
  }

  public setToggleNotAssignedNewMessage() {
    if (this.toggleNotAssignedNewMessages) {
      this.toggleNotAssignedNewMessages = false;
    } else {
      this.toggleNotAssignedNewMessages = true;
    }
  }

  public showNotAssignedNewMessages(aKeyAccount) {
    if (this.toggleNotAssignedNewMessages) {
      return !aKeyAccount;
    } else {
      return (
        !aKeyAccount ||
        (aKeyAccount && aKeyAccount.authId !== this.filterUser?.authId)
      );
    }
  }

  set limit(value: number) {
    console.log('set limit', value);
    if (value === this.messagingservice.limit) {
      return;
    }
    this.messagingservice.limit = value;
    this.loadMessages();
  }

  get limit(): number {
    return this.messagingservice.limit;
  }
}
