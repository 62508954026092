import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonToggleGroupComponent } from './button-toggle-group.component';

@NgModule({
  declarations: [ButtonToggleGroupComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonToggleModule,
    MatIconModule,
    MatTooltipModule,
  ],
  exports: [ButtonToggleGroupComponent],
})
export class ButtonToggleGroupModule {}
