import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientApprovalClientReadable } from '@expresssteuer/client-api-interfaces';
import { WithId } from '@expresssteuer/models';

@Component({
  selector: 'esui-customer-approval',
  templateUrl: './customer-approval.component.html',
  styleUrls: ['./customer-approval.component.scss'],
})
export class CustomerApprovalComponent {
  @Input()
  public approvals: WithId<ClientApprovalClientReadable>[] = [];
  @Output()
  public openDocumentClicked = new EventEmitter<string>();
}
