import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Client } from '@expresssteuer/models';

@Component({
  selector: 'app-clients-new',
  templateUrl: './clients-new.component.html',
  styleUrls: ['./clients-new.component.scss'],
})
export class ClientsNewComponent {
  public client: Client;
  public erroressage = '';

  constructor(
    private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    private router: Router
  ) {
    this.client = Client.getTemplate();
  }

  public async validateAndSave() {
    // Form validators

    // Validate email is not used in accounts

    this.afAuth
      .fetchSignInMethodsForEmail(this.client.email)
      .then(async (r) => {
        console.log(r);
        if (r.length === 0) {
          const password: string = Math.random().toString(36).slice(-8);

          try {
            const credentials =
              await this.afAuth.createUserWithEmailAndPassword(
                this.client.email,
                password
              );

            if (credentials.user == null) throw new Error('user is null');
            this.client.userId = credentials.user.uid;
            this.client.id = credentials.user.uid;

            try {
              await this.db
                .collection('clients')
                .doc(credentials.user.uid)
                .set(this.client);
              this.router
                .navigate(['client', this.client.id])
                .catch((e) =>
                  console.warn('ClientsNewComponent.validatesAndSafe.error', e)
                );
            } catch (err) {
              console.error(err);
            }
          } catch (err) {
            console.error('Could not create User', err);
          }
        } else {
          console.error('User already registered');
          this.erroressage =
            'Kundenemailadresse ' +
            this.client.email +
            ' ist bereits im System erfasst.';
        }
      })
      .catch((e) =>
        console.warn('ClientsNewComponent.validateAndSave.error', e)
      );
  }
}
