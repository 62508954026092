import {
  input as zodInput,
  object as zodObject,
  number as zodNumber,
  string as zodString,
} from 'zod';

const RetryBankAccountActivationDetailsZod = zodObject({
  currentStatus: zodString(),
  errorMessage: zodString(),
  taxadvisorId: zodString(),
  statusCode: zodNumber().optional(),
  activationResponseData: zodObject({}).optional(),
});

const RetryBankAccountActivationZod = zodObject({
  clientId: zodString(),
  vIban: zodString(),
  retries: zodNumber().optional(),
  details: RetryBankAccountActivationDetailsZod.optional(),
});

export type RetryBankAccountActivation = zodInput<
  typeof RetryBankAccountActivationZod
>;

export type RetryBankAccountActivationDetails = zodInput<
  typeof RetryBankAccountActivationDetailsZod
>;

export const asRetryBankAccountActivation = (
  data: unknown
): RetryBankAccountActivation => {
  return RetryBankAccountActivationZod.parse(data);
};

export const RETRY_BANK_ACCOUNT_ACTIVATION_SUB_COLLECTION_NAME =
  'retry_bank_account_activation';
