import { Component, Input } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  shareReplay,
  startWith,
  timer,
} from 'rxjs';

const fullHour$ = new Observable<Date>((subscriber) => {
  const now = new Date();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();
  const milliseconds = now.getMilliseconds();
  const timeSinceLastHour = minutes * 60 + seconds + milliseconds / 1000;
  const timeUntilNextHour = 60 * 60 - timeSinceLastHour;

  const timer$ = timer(timeUntilNextHour * 1000, 60 * 60 * 1000);

  const subscription = timer$.subscribe(() => {
    subscriber.next(new Date());
  });

  return () => subscription.unsubscribe();
}).pipe(shareReplay({ bufferSize: 1, refCount: true }));

@Component({
  selector: 'esui-personal-greeting',
  templateUrl: './esui-personal-greeting.component.html',
  styleUrls: ['./esui-personal-greeting.component.scss'],
})
export class EsuiPersonalGreetingComponent {
  private name$ = new BehaviorSubject<string | null>(null);

  @Input() set name(val: string | null | undefined) {
    this.name$.next(val ?? null);
  }

  @Input() showGreeting = true;
  @Input() showMotivation = true;

  greetingMessage$ = combineLatest({
    name: this.name$,
    timer: fullHour$.pipe(startWith(new Date())),
  }).pipe(
    map(({ name, timer }) => {
      const hour = timer.getHours();
      let greeting = '';

      if (hour >= 4 && hour < 12) {
        const greetings = [
          'Good morning',
          'Rise and shine',
          'Top of the morning to you',
        ];
        greeting = greetings[Math.floor(Math.random() * greetings.length)];
      } else if (hour >= 12 && hour < 18) {
        const greetings = ['Good afternoon', 'Hello there', 'Howdy'];
        greeting = greetings[Math.floor(Math.random() * greetings.length)];
      } else if (hour >= 18 && hour < 23) {
        const greetings = ['Good evening', 'Hey', 'What’s up'];
        greeting = greetings[Math.floor(Math.random() * greetings.length)];
      } else if (hour >= 23 || hour < 4) {
        const greetings = ['Hey there', 'Hello', 'Greetings'];
        greeting = greetings[Math.floor(Math.random() * greetings.length)];
      }

      if (name) {
        return `${greeting}, ${name}!`;
      } else {
        return `${greeting}!`;
      }
    })
  );

  motivationMessage$ = fullHour$.pipe(startWith(new Date())).pipe(
    map(() => {
      const motivations = [
        'Keep up the good work',
        'You got this',
        'Stay awesome',
        'You are capable of amazing things',
        'You are doing great',
      ];
      const motivation =
        motivations[Math.floor(Math.random() * motivations.length)];
      return motivation;
    })
  );
}
