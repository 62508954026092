<div class="viewer">
  <img
    [src]="downloadUrl"
    [ngStyle]="{
      width: zoom + '%'
    }"
    [attr.data-rotate]="rotation"
  />
</div>

<div class="actionButtons">
  <button
    color="primary"
    (click)="rotateBy(90)"
    mat-icon-button
    aria-label="rotate"
    class="mat-elevation-z0"
  >
    <mat-icon>rotate_left</mat-icon>
  </button>

  <button
    color="primary"
    (click)="zoomBy(10)"
    [disabled]="zoom >= 190"
    mat-icon-button
    aria-label="zoom in"
    class="mat-elevation-z0"
  >
    <mat-icon>zoom_in</mat-icon>
  </button>

  <button
    color="primary"
    (click)="zoomBy(-10)"
    [disabled]="zoom <= 10"
    mat-icon-button
    aria-label="zoom out"
    class="mat-elevation-z0"
  >
    <mat-icon>zoom_out</mat-icon>
  </button>

  <button
    color="primary"
    (click)="reset()"
    [disabled]="rotation === 0 && zoom === 100"
    mat-icon-button
    aria-label="zoom and rotate back"
    class="mat-elevation-z0"
  >
    <mat-icon>zoom_out_map</mat-icon>
  </button>
</div>
