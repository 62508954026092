<ng-container *ngIf="address">
  <div class="input-group">
    <input
      type="text"
      class="form-control"
      [(ngModel)]="address.formatedAddress"
      placeholder="Adresse"
      aria-describedby="basic-addon2"
      ngxAutocomPlace
      (selectedPlace)="placeChangedAddress($event)"
      [debounceTime]="500"
      #addressInput
    />
    <div class="input-group-append">
      <span class="input-group-text"
        ><i class="la la-map-marker icon-lg"></i
      ></span>
    </div>
  </div>

  <ng-container *ngIf="showStreetNumber">
    <div class="input-group" id="clientStreetNr">
      <input
        type="text"
        class="form-control"
        [(ngModel)]="address.nbr.longName"
        placeholder="Hausnummer"
        aria-describedby="basic-addon2"
      />
    </div>
  </ng-container>

  <ng-container *ngIf="address.nbr.longName.length === 0">
    <span class="text-danger">Die Hausnummer darf nicht leer sein.</span>
  </ng-container>
  <ng-container *ngIf="address.nbr.longName.length > 6">
    <span class="text-danger"
      >Die Hausnummer darf nicht länger als 6 Zeichen sein. (Aktuell:
      {{ address.nbr.longName.length }})</span
    >
  </ng-container>

  <ng-container *ngIf="showStreetNumberToggle">
    <span class="form-text text-muted">
      <a (click)="showStreetNumber = !showStreetNumber">{{
        showStreetNumber ? 'Hausnummer verbergen' : 'Hausnummer anzeigen'
      }}</a>
    </span>
  </ng-container>
</ng-container>
