<mat-list role="listbox">
  <mat-list-item
    role="listitem"
    *ngFor="let taxcaseInCrmUrl of taxcaseInCrmUrls; trackBy: trackByUrl"
  >
    <a
      mat-button
      [href]="taxcaseInCrmUrl.url"
      target="_blank"
      [disabled]="!taxcaseInCrmUrl.url"
    >
      <mat-icon>open_in_new</mat-icon>
      <mat-icon>fact_check</mat-icon>
      <span
        >open case
        <span class="taxcaseYear">{{ taxcaseInCrmUrl.year }}</span> in CRM</span
      >
    </a>
  </mat-list-item>
  <mat-list-item
    role="listitem"
    *ngFor="
      let taxcaseInTaskIslandTasksUrl of taxcaseInTaskIslandTasksUrls;
      trackBy: trackByUrl
    "
  >
    <a
      mat-button
      [href]="taxcaseInTaskIslandTasksUrl.url"
      target="_blank"
      [disabled]="!taxcaseInTaskIslandTasksUrl.url"
    >
      <mat-icon>open_in_new</mat-icon>
      <mat-icon>fact_check</mat-icon>
      <span
        >open case
        <span class="taxcaseYear">{{ taxcaseInTaskIslandTasksUrl.year }}</span>
        in Task-Island</span
      >
    </a>
  </mat-list-item>
  <mat-list-item role="listitem">
    <a
      mat-button
      [href]="clientInCrmUrl"
      target="_blank"
      [disabled]="!clientInCrmUrl"
    >
      <mat-icon>open_in_new</mat-icon>
      <mat-icon>account_box</mat-icon>
      <span>open client in CRM</span>
    </a>
  </mat-list-item>
  <mat-list-item role="listitem">
    <a
      mat-button
      [href]="clientInTaskIslandTasksUrl"
      target="_blank"
      [disabled]="!clientInTaskIslandTasksUrl"
    >
      <mat-icon>open_in_new</mat-icon>
      <mat-icon>account_box</mat-icon>
      <span>open client in Task-Island Tasks</span>
    </a>
  </mat-list-item>
  <mat-list-item role="listitem">
    <a
      mat-button
      [href]="clientInTaskIslandCrmUrl"
      target="_blank"
      [disabled]="!clientInTaskIslandCrmUrl"
    >
      <mat-icon>open_in_new</mat-icon>
      <mat-icon>account_box</mat-icon>
      <span>open client in crm alpha</span>
    </a>
  </mat-list-item>
  <mat-list-item role="listitem" *ngIf="permaLinkToTask">
    <button
      mat-button
      target="_blank"
      [disabled]="!permaLinkToTask"
      [cdkCopyToClipboard]="permaLinkToTask"
    >
      <mat-icon>copy_all</mat-icon>
      <mat-icon>task</mat-icon>
      <span>copy perma link to task</span>
    </button>
  </mat-list-item>
</mat-list>
