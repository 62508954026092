import { Component, EventEmitter, Input, Output } from '@angular/core';

type BasicOptions = {
  key: string;
  label: string;
  disabled?: boolean;
};

type StringOptions = {
  type: 'string';
  value: string | null;
  icon?: string;
} & BasicOptions;

type BooleanOptions = {
  type: 'boolean';
  value: boolean | null;
  icons?: {
    true: string;
    false: string;
    null: string;
  };
} & BasicOptions;

type OptionOptions = {
  type: 'option';
  value: any | null;
  options: { value: any; key: string; label: string; icon?: string }[];
  icon?: string;
} & BasicOptions;

@Component({
  selector: 'esui-tasks-filter',
  templateUrl: './tasks-filter.component.html',
  styleUrls: ['./tasks-filter.component.scss'],
})
export class TasksFilterComponent {
  @Input() taskCount?: number | null;
  @Input() taskCountDate?: Date | null;

  @Input() filter: (StringOptions | BooleanOptions | OptionOptions)[] = [];

  isBooleanEntry(
    entry: TasksFilterComponent['filter'][0]
  ): entry is BooleanOptions {
    return entry.type === 'boolean';
  }

  isStringEntry(
    entry: TasksFilterComponent['filter'][0]
  ): entry is StringOptions {
    return entry.type === 'string';
  }

  isOptionEntry(
    entry: TasksFilterComponent['filter'][0]
  ): entry is OptionOptions {
    return entry.type === 'option';
  }

  @Output() filterEntryChange = new EventEmitter<{
    key: string;
    value: any;
  }>();

  idByKey({ key }: any) {
    return key;
  }
}
