import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Claims,
  PermActionsString,
  PermKey,
  Perms,
} from '@expresssteuer/models';

interface DataRow {
  key: keyof Perms;
  label: string;
  value: PermActionsString;
}
type DataSource = DataRow[];

@Component({
  selector: 'esui-claims-editor',
  templateUrl: './claims-editor.component.html',
  styleUrls: ['./claims-editor.component.scss'],
})
export class ClaimsEditorComponent {
  static emptyClaims: Claims = {
    perms: {},
    role: '',
  };

  roleOptions = [
    { value: 'admin', key: 'admin', label: 'Admin' },
    { value: 'user', key: 'user', label: 'Nutzer' },
    { value: '', key: 'none', label: 'Keine' },
  ];

  initiatedWithEmptyValue = false;
  dataSource: DataSource = ClaimsEditorComponent.emptyDataSource();

  @Input() disabled = false;
  @Input() isLoggedInUser = false;

  @Input()
  set claims(claims: Claims | null | undefined) {
    this.initiatedWithEmptyValue = !claims;
    const c = claims || ClaimsEditorComponent.emptyClaims;

    this.dataSource = ClaimsEditorComponent.emptyDataSource();
    if (c.perms) {
      Object.entries(c.perms).forEach(([key, value]) => {
        const found = this.dataSource.find((el: DataRow) => el.key === key);
        if (found) {
          found.value = value;
        }
      });
    }

    this.#claims = c;
  }
  get claims(): Claims {
    return this.#claims;
  }
  #claims: Claims = ClaimsEditorComponent.emptyClaims;

  @Output() claimsChanged = new EventEmitter<Claims>();

  displayedColumns: string[] = ['long', 'short', 'perm'];

  static emptyDataSource(): DataSource {
    return [...Object.entries(PermKey)].map(([label, key]) => {
      return { key, label, value: '' };
    });
  }
  trackByKey(index: number, element: DataRow) {
    return element.key;
  }
  roleChanged(role: '' | 'admin' | 'user') {
    this.claimsChanged.next({ ...this.claims, role: role });
  }
  permissionsChanged(element: DataRow, permission: string) {
    const newClaims = {
      ...this.claims,
      perms: { ...this.claims.perms, [element.key]: permission || '' },
    };

    this.claims = newClaims;

    this.claimsChanged.next(newClaims);
  }
}
