<div *ngIf="percentage | async as pct">
  <progress [value]="pct" max="100"></progress>
  {{ pct | number }}%
</div>

<div *ngIf="snapshot | async as snap">
  {{ snap.bytesTransferred }} of {{ snap.totalBytes }}

  <div *ngIf="downloadURL as url">
    <h3>OK</h3>
  </div>

  <!-- <button (click)="task.pause()" [disabled]="!isActive(snap)">Pause</button> -->
  <button (click)="task.cancel()" [disabled]="!isActive(snap)">
    Abbrechen
  </button>
  <!-- <button (click)="task.resume()" [disabled]="!(snap?.state === 'paused')">Resume</button> -->
</div>
