<div>
  <div>
    <mat-checkbox
      [ngModel]="validationResult?.valid"
      (ngModelChange)="validCheckboxChange($event)"
    >
      This is a valid certificate for meal allowance
    </mat-checkbox>
  </div>
</div>
