import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Client,
  DOCUMENTSOURCE,
  SmartTask,
  TaxofficeLetter,
  TaxofficeRequest,
  WithId,
} from '@expresssteuer/models';
import { StoreStateIndicatorModel } from '../es-store-state-indicator/store-state-indicator.interface';

@Component({
  selector: 'esui-taxoffice-assign-reminder-to-request',
  templateUrl: './esui-taxoffice-assign-reminder-to-request.component.html',
  styleUrls: ['./esui-taxoffice-assign-reminder-to-request.component.scss'],
})
export class EsuiTaxofficeAssignReminderToRequestComponent {
  @Input() task?: SmartTask | null;
  @Input()
  taxofficeLetterStoreStateIndicator?: Partial<StoreStateIndicatorModel> | null;
  @Input() activeTaxofficeRequest?: WithId<TaxofficeRequest> | null;
  @Input() taxofficeRequests?: WithId<TaxofficeRequest>[] | null;
  @Input() client?: Client | null;
  @Input()
  originalDocument?: {
    contentType: string;
    downloadUrl: string;
    source?: DOCUMENTSOURCE | null;
  } | null;
  @Input()
  originalDocumentOfActiveRequest?: {
    contentType: string;
    downloadUrl: string;
    source?: DOCUMENTSOURCE | null;
  } | null;
  @Input() taxofficeLetter?: TaxofficeLetter | null;

  @Output() taxofficeRequestSelectedId = new EventEmitter<string>();
  @Output() verifyAndSave = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();
  @Output() discard = new EventEmitter<void>();
}
