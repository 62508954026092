import { Injectable } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
/**
 * NgbDateAdapter implementation that allows using Firebase Firestore TimeStamp as a user date model.
 * https://firebase.google.com/docs/reference/js/firebase.firestore.Timestamp
 */
@Injectable()
export class NgbDateFirestoreAdapter extends NgbDateAdapter<Timestamp> {
  /**
   * Converts Firestore TimeStamp to a NgbDateStruct
   */

  fromModel(ts: Timestamp): NgbDateStruct | null {
    if (ts instanceof Timestamp) {
      return {
        year: ts.toDate().getUTCFullYear(),
        month: ts.toDate().getUTCMonth() + 1,
        day: ts.toDate().getUTCDate(),
      };
    } else {
      return null;
    }
  }

  /**
   * Converts a NgbDateStruct to a Firestore TimeStamp
   */
  toModel(ngbDate: NgbDateStruct): Timestamp | null {
    if (ngbDate) {
      const jsDate = new Date(
        Date.UTC(
          ngbDate.year ? ngbDate.year : new Date().getFullYear(),
          ngbDate.month ? ngbDate.month - 1 : new Date().getMonth() - 1,
          ngbDate.day ? ngbDate.day : new Date().getDate()
        )
      );
      return Timestamp.fromDate(jsDate);
    } else {
      return null;
    }
  }
}
