import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MARITALSTATUS, TaxCase } from '@expresssteuer/models';

@Component({
  selector: 'app-control-client-infos',
  templateUrl: './control-client-infos.component.html',
  styleUrls: ['./control-client-infos.component.scss'],
})
export class ControlClientInfosComponent implements OnInit {
  constructor() {}

  @Input()
  get taxcase(): TaxCase {
    return this.taxCaseValue;
  }

  set taxcase(val: TaxCase) {
    this.taxCaseValue = val;
    this.taxCaseChange.emit(this.taxCaseValue);
  }

  MARITALSTATUS = MARITALSTATUS;

  private taxCaseValue: TaxCase;

  @Output()
  taxCaseChange = new EventEmitter<TaxCase>();

  ngOnInit(): void {}
}
