<mat-icon [ngClass]="{ loading: isLoading }" [matTooltip]="syncTooltip">{{
  hasOnlineChangedAfterLocalChanged
    ? 'sync_problem'
    : isSaving
    ? 'save'
    : hasChanges
    ? 'pending_actions'
    : 'published_with_changes'
}}</mat-icon>

<mat-icon matTooltip="has errors" *ngIf="hasErrors">error</mat-icon>

<p *ngIf="hasOnlineChangedAfterLocalChanged">
  ATTENTION! Someone else already made changes to the document; discard your
  local changes to avoid conflicts.
</p>
