import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { EsuiDocumentViewerModule } from '../esui-document-viewer/esui-document-viewer.module';
import { EsuiDocumentViewerDialogComponent } from './esui-document-viewer-dialog.component';

@NgModule({
  declarations: [EsuiDocumentViewerDialogComponent],
  imports: [
    CommonModule,
    EsuiDocumentViewerModule,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class EsuiDocumentViewerDialogModule {}
