Items per page:
<mat-form-field appearance="outline">
  <mat-select
    [ngModel]="pageSize"
    (ngModelChange)="pageSizeChange.next($event)"
  >
    <mat-option *ngFor="let s of pageSizeOptions" [value]="s">
      {{ s }}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="outline" class="small-field">
  <mat-label>Von</mat-label>
  <input [disabled]="true" matInput value="{{ ahead }}" />
</mat-form-field>
<button mat-icon-button aria-label="start" (click)="startClick.next()">
  <mat-icon>start</mat-icon>
</button>

<button
  mat-icon-button
  aria-label="previous"
  (click)="previousClick.next()"
  [disabled]="!!backImpossible"
>
  <mat-icon>chevron_left</mat-icon>
</button>
<button
  mat-icon-button
  aria-label="next"
  (click)="nextClick.next()"
  [disabled]="!!nextImpossible"
>
  <mat-icon>chevron_right</mat-icon>
</button>
