<ng-container *ngIf="task">
  <mat-card>
    <span esuiCardKey>client assigner</span>
    <mat-card-header>
      <mat-card-title> </mat-card-title>
      <mat-card-subtitle>
        <esui-store-state-indicator
          [hasOnlineChangedAfterLocalChanged]="
            !!hasOnlineChangedAfterLocalChanged
          "
          [isSaving]="!!isSaving"
          [isLoading]="!!isLoading"
          [hasChanges]="!!hasChanges"
          [hasErrors]="!!hasErrors"
        ></esui-store-state-indicator>
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <div class="focus" *ngIf="document && downloadUrl">
        <div class="document-viewer">
          <esui-document-viewer
            [downloadUrl]="downloadUrl"
            [contentType]="document.contentType"
            [source]="document.source"
            [showNavigation]="true"
            [showDownloadButton]="true"
          ></esui-document-viewer>
        </div>
        <div class="meta-viewer">
          <esui-client-search
            [searchClient]="searchClient"
            [indexName]="indexName"
            [clientId]="clientId"
            (clientIdChange)="clientIdChange.next($event)"
          >
          </esui-client-search>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button
        type="button"
        mat-raised-button
        color="accent"
        (click)="saveClick.next()"
        [disabled]="
          hasChanges !== true || isSaving === true || isLoading === true
        "
      >
        save
      </button>
      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="discardClick.next()"
        [disabled]="
          hasChanges !== true || isSaving === true || isLoading === true
        "
      >
        discard
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>
