import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { EsuiContentTagViewerComponent } from './esui-content-tag-viewer.component';

@NgModule({
  declarations: [EsuiContentTagViewerComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
  ],
  exports: [EsuiContentTagViewerComponent],
})
export class EsuiContentTagViewerModule {}
