import { BankingA352RawPaymentListApiInterface as RawPaymentListApiInterface } from '@expresssteuer/banking-api-interfaces';
import { BankingA352Payment } from './banking-a352-payment.interface';

export interface BankingA352RawPaymentList
  extends Omit<RawPaymentListApiInterface, 'payments'> {
  id: string;
  processed?: boolean;
  payments: BankingA352Payments;
}

export interface BankingA352Payments {
  [clientPaymentId: string]: BankingA352Payment;
}
