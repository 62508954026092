<ng-container *ngIf="!!translationContent">
  <div class="upload-explanation">
    {{ translationContent.uploadDescription }}
  </div>
  <div class="preview-zone">
    <div
      class="preview-element"
      *ngFor="let documentState of clientDocumentUploadStates"
    >
      <div class="state-indicator">
        <ng-container *ngIf="documentState.documentState.verified"
          ><mat-icon>verified</mat-icon></ng-container
        >
      </div>
      <div class="preview-image-container">
        <div
          class="upload-indicator"
          *ngIf="!documentState.documentState.uploaded"
        >
          <mat-spinner diameter="50"> </mat-spinner>
        </div>
        <ng-container *ngIf="!documentState.documentState.uploaded">
          <div class="image-darkener"></div>
        </ng-container>
        <img
          class="preview-image"
          src="{{ documentState.thumbnailSmallUri }}"
        />
        <ng-container *ngIf="!documentState.documentState.processed">
          <div class="deleteFileBtn">
            <button
              mat-mini-fab
              color="primary"
              aria-label="delete uploaded file button"
              (click)="onFileDeleteClick(documentState)"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
      <div *ngIf="fileNameShown" class="file-name">
        {{ documentState.fileName }}
      </div>
    </div>
  </div>
  <div class="upload-zone">
    <div
      class="custom-dropzone"
      ngx-dropzone
      (change)="addFile($event)"
      id="uploadFileZone"
      [accept]="'image/*,application/pdf'"
      [multiple]="multiUpload ?? false"
    >
      <ngx-dropzone-label>
        {{ translationContent.uploadZoneText }}
      </ngx-dropzone-label>
    </div>
  </div>
  <div *ngIf="enableRemark" class="remark-container">
    <div class="send-remark-btn">
      <button
        mat-mini-fab
        color="primary"
        aria-label="delete uploaded file button"
        (click)="sendRemark()"
      >
        <mat-icon>send</mat-icon>
      </button>
    </div>
    <mat-form-field class="remark-field" appearance="fill">
      <mat-label>
        {{ translationContent.commentSectionLabel }}
      </mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
        [(ngModel)]="remarkInput"
      ></textarea>
    </mat-form-field>
  </div>
  <div
    class="confirm-zone"
    *ngIf="
      multiUpload &&
      clientDocumentUploadStates &&
      clientDocumentUploadStates.length > 0
    "
  >
    <div class="confirmation-text">
      {{ translationContent.disclaimerTextConfirmUpload }}
    </div>
    <mat-checkbox
      [checked]="userMarkedUploadAsDone ?? false"
      [disabled]="userMarkedUploadAsDone"
      (change)="userMarkedUploadAsDoneChange.next($event.checked)"
    ></mat-checkbox>
  </div>
  <div
    class="confirm-zone"
    *ngIf="
      !isMandatory &&
      clientDocumentUploadStates &&
      clientDocumentUploadStates.length === 0
    "
  >
    <div class="confirmation-text">
      {{ translationContent.disclaimerTextNotAvailable }}
    </div>
    <mat-checkbox
      [checked]="userMarkedAsNotAvailable"
      [disabled]="userMarkedAsNotAvailable"
      (change)="userMarkedAsNotAvailableChange.next($event.checked)"
    ></mat-checkbox>
  </div>
</ng-container>
