import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { Answer, ClientTodo } from '@expresssteuer/client-todos-api-interfaces';

@Component({
  selector: 'esui-client-todo-help-task',
  templateUrl: './esui-client-todo-help-task.component.html',
  styleUrls: ['./esui-client-todo-help-task.component.scss'],
})
export class EsuiClientTodoHelpTaskComponent implements OnChanges {
  @ContentChild(TemplateRef, { static: true })
  documentViewerTemplate?: TemplateRef<{ binaryDocumentRef: string }>;

  @Input()
  client?: { id: string; firstname: string; lastname: string };
  @Input()
  partner?: { partnerId: string; firstname: string; lastname: string } | null;
  @Input()
  todo?: ClientTodo;
  @Input()
  answers: Answer[] = [];
  @Input()
  activeAnswerId?: string;
  @Input()
  activeAnswerIsReady?: boolean;
  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationResult: any; // you could check if we can use typecasting here for each individual todo. Dont waste to much time tho.
  @Input()
  submitDisabled?: boolean;

  @Output()
  makeAnswerActive = new EventEmitter<string>();
  @Output()
  validationResultChange = new EventEmitter<Record<string, unknown>>();
  @Output()
  submitValidationResult = new EventEmitter<void>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedAnswerPayload?: any; // you could check if we can use typecasting here for each individual todo. Dont waste to much time tho.

  ngOnChanges(changes: SimpleChanges) {
    if (changes.answers != null || changes.activeAnswerId != null) {
      this.selectedAnswerPayload = this.answers.find(
        ({ id }) => id === this.activeAnswerId
      )?.payload;
    }
  }
}
