<a [routerLink]="linkToItem">
  <div class="card h-100 border-0 mb-3 text-center">
    <app-ais-documents-icon
      [mimeType]="hit.contentType"
    ></app-ais-documents-icon>
    <h5 class="card-title m-2 text-truncate">
      <ais-highlight attribute="name" [hit]="hit"></ais-highlight>
    </h5>
    <small class="text-muted text-truncate">
      <samp>
        <ais-highlight attribute="contentType" [hit]="hit"></ais-highlight>
      </samp>
    </small>
  </div>
</a>
