<ng-container>
  <div *ngIf="documentViewerTemplate">
    <ng-container
      *ngTemplateOutlet="
        documentViewerTemplate;
        context: { $implicit: answerPayload?.frontDocumentRef }
      "
    >
    </ng-container>
  </div>
</ng-container>
