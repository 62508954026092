<ng-container *ngIf="debugService">
  <button
    *ngIf="debugService.debug$ | async"
    type="button"
    mat-icon-button
    [matTooltip]="(label ? label + ' ' : '') + '[stats for nerds]'"
    (click)="toggle()"
  >
    <mat-icon>pest_control</mat-icon>
  </button>

  <pre *ngIf="this.open">
  {{ this.data | json }}
</pre
  >
</ng-container>
