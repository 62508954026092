import { Component, EventEmitter, Input, Output } from '@angular/core';

// TODO: type should be the validationResult type of the handler. try to import it here
interface ValidationResult {
  valid: boolean;
  taxId: string;
}

@Component({
  selector: 'esui-client-taxid-proof-feedback-form',
  templateUrl: './esui-client-taxid-proof-feedback-form.component.html',
  styleUrls: ['./esui-client-taxid-proof-feedback-form.component.scss'],
})
export class EsuiClientTaxidProofFeedbackFormComponent {
  @Input()
  validationResult?: ValidationResult;

  @Output()
  validationResultChange = new EventEmitter<Record<string, unknown>>();

  taxidChange(evt: string) {
    this.validationResultChange.next({
      ...this.validationResult,
      taxId: `${evt}`,
    });
  }
}
