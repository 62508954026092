import { KeyValue } from '@angular/common';
import { AbstractValueAccessor } from './abstract-value-accessor';

/**
 * Helper to implement the `AbstractValueAccessor` with
 * key-value `options`.
 */
export abstract class AbstractKeyValueAccessor<
  TLabel,
  TValue
> extends AbstractValueAccessor<TValue> {
  abstract options: Map<TLabel, TValue>;

  /**
   * Preserve original property order
   */
  originalOrder = (
    _a: KeyValue<TLabel, TValue>,
    _b: KeyValue<TLabel, TValue>
  ): number => {
    return 0;
  };
}
