import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { SendEmailToTaxofficeOutput } from '@expresssteuer/taxoffice-api-interfaces';

export interface EsuiTaxofficeEmailComposerFormValues {
  sender: string;
  cc: string;
  receiver: string;
  subject: string;
  body: string;
}

@Component({
  selector: 'esui-taxoffice-email-composer',
  templateUrl: './esui-taxoffice-email-composer.component.html',
  styleUrls: ['./esui-taxoffice-email-composer.component.scss'],
})
export class EsuiTaxofficeEmailComposerComponent implements OnChanges {
  @Input()
  disabled = false;
  @Input()
  loading = false;
  @Input()
  isVisible = false;
  @Input()
  values: EsuiTaxofficeEmailComposerFormValues | null = null;
  @Input()
  result: SendEmailToTaxofficeOutput | null = null;
  @Input()
  availableSenders?: { id: string; email: string }[] | null;

  @Output()
  changeValues = new EventEmitter<
    Partial<EsuiTaxofficeEmailComposerFormValues>
  >();
  @Output()
  setVisible = new EventEmitter<void>();
  @Output()
  doClose = new EventEmitter<void>();
  @Output()
  send = new EventEmitter<void>();

  senderControl = new FormControl<string>('');
  receiverControl = new FormControl<string>('');
  ccControl = new FormControl<string>('');
  subjectControl = new FormControl<string>('');
  bodyControl = new FormControl<string>('');

  ngOnChanges(changes: SimpleChanges): void {
    const values = changes?.values?.currentValue as
      | EsuiTaxofficeEmailComposerFormValues
      | undefined;
    if (values !== undefined) {
      this.senderControl.setValue(values?.sender ?? '');
      this.receiverControl.setValue(values?.receiver ?? '');
      this.subjectControl.setValue(values?.subject ?? '');
      this.bodyControl.setValue(values?.body ?? '');
      this.ccControl.setValue(values?.cc ?? '');
    }
    if (changes?.availableSenders?.currentValue != null) {
      this.senderControl.setValue(
        changes.availableSenders.currentValue?.[0].email
      );
    }
    if (changes?.loading?.currentValue === true) {
      this.senderControl.disable();
      this.receiverControl.disable();
      this.subjectControl.disable();
      this.bodyControl.disable();
      this.ccControl.disable();
    } else {
      this.senderControl.enable();
      this.receiverControl.enable();
      this.subjectControl.enable();
      this.bodyControl.enable();
      this.ccControl.enable();
    }
  }

  sendEmail() {
    if (
      this.senderControl.valid &&
      this.receiverControl.valid &&
      this.subjectControl.valid &&
      this.bodyControl.valid
    ) {
      this.send.emit();
    }
  }

  changeSender(email: string): void {
    this.changeValues.emit({
      ...this.values,
      sender: email,
    });
  }
  changeReceiver(event: Event): void {
    this.changeValues.emit({
      receiver: (event.target as HTMLInputElement).value,
    });
  }
  changeSubject(event: Event): void {
    this.changeValues.emit({
      subject: (event.target as HTMLInputElement).value,
    });
  }
  changeBody(event: Event): void {
    this.changeValues.emit({
      body: (event.target as HTMLInputElement).value,
    });
  }
  changeCc(event: Event): void {
    this.changeValues.emit({
      cc: (event.target as HTMLInputElement).value,
    });
  }
}
