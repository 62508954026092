import { ClientShort } from '../client/client';
import { BinaryDocument } from '../document/document';
import { Timestamp } from '../helpers/timestamp';
import { InvoiceLine } from './invoiceline';
import { Transaction } from '@expresssteuer/banking-models';

/**
 * Variables for the template
 * This is a work in progress
 * INVOICE ID = TAXCASE.ID _INVOICE _STORNO _XXX;
 */
export enum INVOICETYPE {
  /**
   * An Invoice has an negative value.
   * The client needs us to pay an amount
   */
  INVOICE = 'INV',
  /**
   * If the customer has positice return, we only provide a RECEIPT to show him what he paid for
   */
  RECEIPT = 'REC',
  /**
   * A Storno is a negative invoice of an existing invoice
   * TODO: This needs to be implemented
   */
  STORNO = 'STO',
}
export class Invoice {
  public static getTemplate(): Invoice {
    return {
      id: '',
      number: '',
      type: INVOICETYPE.INVOICE,
      client: ClientShort.getTemplate(),
      created: null,
      document: BinaryDocument.getTemplate(),
      invoiceDate: null,
      stripePaymentLink: '',
      amount: 0,
      amountOpen: 0,
      amountOriginal: 0,
      vats: null,
      invoicelines: [],
      transactions: [],
      status: Invoicestatus.draft,
    };
  }

  /**
   * The firestore document id
   */
  id = '';

  /**
   * The invoice number
   * We take the final number from stripe
   */
  number = '';

  /**
   * Type of invoice.
   * Invoice/Storno Invoice/Or Updated Invoice.
   */
  type: INVOICETYPE = INVOICETYPE.INVOICE;

  /**
   * The invoice Id created at stripe
   * The data is synced over webhook with stripe
   */
  stripeInvoiceId?: string;
  /**
   * The generated link for the digital invoice => for later use.
   * With this link the customer could also pay his invoice of download a copy
   * The data is synced over webhook with stripe

   */
  stripePaymentLink?: string;
  /**
   * The invoice status at stripe for this invoice
   * The data is synced over webhook with stripe

   */
  stripePaymentStatus?: string;
  /**
   * the connected TaxCaseId
   */
  client: ClientShort = ClientShort.getTemplate();
  /**
   * The Date the invoice was created
   */
  invoiceDate: Timestamp | null = null;
  /**
   * The link to the generated invoice internaly
   */
  document?: BinaryDocument | null = null;
  //public includedTax = ''; we use vat values
  /**
   * Original amount to pay. Never changes
   */
  amountOriginal: number = 0;
  /**
   * Open amount if the client pays not complete sum,
   */
  amountOpen: number = 0;
  /**
   * The amount to pay for the client. This amount can be changed if we wanna charge a different amount.
   */
  amount: number = 0;
  /**
   * The used vats from the invoice lines.
   * There will be one record for each var percentage
   * The Percentage will be as string and the number is the total value of all vats
   */
  vats: InvoiceVat[] | null = null;

  /**
   * The assigned invoice lines
   */
  invoicelines: InvoiceLine[] = [];

  /**
   * The transactios that are used to pay out the customer
   * if empty, the customer has no money returned
   */
  transactions: Transaction[] = [];
  /**
   * When the invoice is created in the database
   */
  created: Timestamp | null = null;

  status: Invoicestatus = Invoicestatus.draft;
}

export enum Invoicestatus {
  /**
   * The invoice is created but not yet validated and sent to the customer.
   * A Draft invoice can still be deleted or changed
   */
  draft = 'draft',
  /**
   * The invoice is sent to the customer. A change afterwards is not possible anymore
   */
  open = 'open',
  /**
   * The invoice is over the period where it should be paid
   */
  overdue = 'overdue',
  /**
   *
   */
  paid = 'paid',
}

/**
 * A single Vat cumulated value
 * @examle combines multiple 19% vats togeter
 */
export class InvoiceVat {
  vat: string = '';
  value: number = 0;
}
