import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LOGGER_LEVEL, LoggingLevel } from './esui-logger.service';

class DebuggExpress {
  #exposedObjects: Record<string, unknown>;
  /**
   * exposedObjects
   */
  eo?: Record<string, unknown>;

  constructor(
    exposedObjects: Record<string, unknown>,
    private esuiDebugService?: EsuiDebugService
  ) {
    this.#exposedObjects = exposedObjects;
  }

  activate(loggingLevel?: LoggingLevel) {
    this.esuiDebugService?.activateLogging(loggingLevel);
    this.esuiDebugService?.activateDebugging();
    this.eo = this.#exposedObjects;
    return this;
  }

  deactivate() {
    this.esuiDebugService?.deactivateLogging();
    this.esuiDebugService?.deactivateDebugging();
    delete this.eo;
    return this;
  }
}

interface WindowWithDebugging extends Window {
  de?: DebuggExpress;
}

export const OBJECTS_TO_EXPOSE = new InjectionToken<Record<string, unknown>>(
  'OBJECTS_TO_EXPOSE'
);

/**
 * Service to specify the debug level of an application.
 * Further, it attaches a `DebuggExpress` object to the browsers window for interactive
 * modification.
 */
@Injectable({
  providedIn: 'root',
})
export class EsuiDebugService {
  #levelSubject$: BehaviorSubject<LoggingLevel>;
  public level$: Observable<LoggingLevel>;
  public get currentLevel(): LoggingLevel {
    return this.#levelSubject$.getValue();
  }

  #debugSubject$: BehaviorSubject<boolean>;
  public debug$: Observable<boolean>;
  public get debugActive(): boolean {
    return this.#debugSubject$.getValue();
  }

  constructor(
    @Optional()
    @Inject(OBJECTS_TO_EXPOSE)
    private objectsToExpose: Record<string, unknown> = {},
    @Optional()
    @Inject(LOGGER_LEVEL)
    private initLoggerLevel: LoggingLevel = LoggingLevel.None
  ) {
    this.#levelSubject$ = new BehaviorSubject<LoggingLevel>(initLoggerLevel);
    this.level$ = this.#levelSubject$.asObservable();

    this.#debugSubject$ = new BehaviorSubject<boolean>(false);
    this.debug$ = this.#debugSubject$.asObservable();

    const windowWithActivateDebugging = window as WindowWithDebugging;
    windowWithActivateDebugging.de = new DebuggExpress(objectsToExpose, this);
  }

  public activateLogging(loggingLevel: LoggingLevel = LoggingLevel.Debug) {
    this.#levelSubject$.next(loggingLevel);
    console.log('LOGGING SET TO ', loggingLevel);
  }

  public deactivateLogging() {
    this.#levelSubject$.next(this.initLoggerLevel);
    console.log('LOGGING SET TO ', this.initLoggerLevel);
  }

  public activateDebugging() {
    this.#debugSubject$.next(true);
    console.log('DEBUGGING ACTIVATED');
  }

  public deactivateDebugging() {
    this.#debugSubject$.next(false);
    console.log('DEBUGGING DISBALED');
  }
}
