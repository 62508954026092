import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';

export interface AutocompleteOptions<T = any> {
  value: T;
  label: string;
}
/**
 * Helper for easy implementation of the `ControlValueAccessor`.
 * NOTE: Components extending this class most likely need specific
 * providers @see `makeProvider`
 * 
 * The following properties should be reflected in the implementing Component's template when appropriate:
 - `focused`
 - `touched`
 - `empty`
 - `shouldLabelFloat`
 - `placeholder`
 - `required`
 - `disabled`
 - `value`
 - `onFocusIn()`
 - `onFocusOut()`
 */
@Directive()
export abstract class AbstractValueAccessor<T = any>
  implements MatFormFieldControl<T>, ControlValueAccessor, OnDestroy, OnInit
{
  static nextId = 0;

  stateChanges: Subject<void> = new Subject();
  #focused = false;
  #touched = false;
  #placeholder = '';
  #required = false;
  #disabled = false;
  autofilled?: boolean | undefined = undefined;

  get focused(): boolean {
    return this.#focused;
  }

  get touched(): boolean {
    return this.#touched;
  }

  @Input() autocompleteOptions: AutocompleteOptions<T>[] = [];

  /** @deprecated currently not implemented */
  @Input() showAutocomplete = false;

  // TODO
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string | undefined =
    undefined;

  @HostBinding() id = `valueAccessor-${AbstractValueAccessor.nextId++}`;
  onChange = (_: T) => {
    //
  };
  onTouched = () => {
    //
  };

  abstract readonly empty: boolean;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.#focused || !this.empty;
  }

  @Input()
  get placeholder() {
    return this.#placeholder;
  }
  set placeholder(plh) {
    this.#placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this.#required;
  }
  set required(req) {
    this.#required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this.#disabled;
  }
  set disabled(value: BooleanInput) {
    this.#disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): T {
    return this.data;
  }
  set value(v: T) {
    if (!isEqual(v, this.data)) {
      this.data = v;
      this.onChange(v);
      this.stateChanges.next();
    }
  }

  /**
   * @override
   */
  get errorState(): boolean {
    return this.touched && Object.keys(this.ngControl.errors ?? {}).length > 0;
  }

  abstract readonly controlType: string;

  /**
   * This value needs to be initialized; later use value only
   */
  protected abstract data: T;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    protected elementRef: ElementRef<HTMLElement>
  ) {
    // Replace the provider from above with this.
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  trackByLabel(index: number, item: AutocompleteOptions) {
    return item.label;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  ngOnInit() {
    this.stateChanges = new Subject<void>();
  }

  private isFocusEvent(event: Event | FocusEvent): event is FocusEvent {
    // return !!(event as any).relatedTarget;
    return ['focus', 'focusout'].includes(event.type);
  }

  onFocusIn(event: FocusEvent | Event) {
    if (this.#focused) {
      return;
    }
    this.#focused = true;
    this.stateChanges.next();
  }
  onFocusOut(event: FocusEvent | Event) {
    this.#touched = true;
    this.#focused = false;
    this.onTouched();
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]) {
    // TODO
    // const controlElement = this.elementRef.nativeElement.getRootNode();
    // controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  /**
   * @example
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elementRef.nativeElement.querySelector('input')?.focus();
    }
   */
  abstract onContainerClick(event: MouseEvent): void;

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: T) {
    this.data = value;
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}

/**
 * Build a Component's providers for:
 * `NG_VALUE_ACCESSOR`, `NG_VALIDATORS`
 * @example ```
\@Component({
  // [...]
  providers: [...makeProvider(MyAbstractValueAccessor)],
})
export class MyAbstractValueAccessor
  extends AbstractValueAccessor<MyType>{
    // [...]
  }
 * ```
 */
export function makeProvider(type: any) {
  return [
    // {
    //   provide: NG_VALUE_ACCESSOR,
    //   useExisting: forwardRef(() => type),
    //   multi: true,
    // },
    // {
    //   provide: NG_VALIDATORS,
    //   useExisting: type,
    //   multi: true,
    // },
    { provide: MatFormFieldControl, useExisting: type },
  ];
}
