/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  BaseMessageTemplate,
  Client,
  ClientShort,
  Message,
  MessageConfig,
  MESSAGETYPE,
  NotificationChannels,
  TaxCase,
} from '@expresssteuer/models';
import { SearchClient, SearchIndex } from 'algoliasearch';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { ClientsService } from '../../../../../services/data/clients.service';
import { MessagetemplateService } from '../../../../../services/data/messagetemplate.service';
import {
  MessageOpen,
  MessagingService,
} from '../../../../../services/data/messaging.service';
import { TaxcasesService } from '../../../../../services/data/taxcases.service';
import { BlockUiService } from '../../../../../services/helpers/blockui.service';
import { NotificationService } from '../../../../../services/helpers/notification.service';
import { AlgoliaService } from '../../../../../services/search/algolia.service';
import { AuthenticationService } from '../../../../../services/security/authentication.service';

declare var $: any;
declare var KTAppInbox: any;
declare var KTUtil: any;
declare var Tagify: any;

export interface ListItem {
  value: string;
  firstname: string;
  lastname: string;
  mobile: string;
  email: string;
  initials: string;
  initialsState: string;
  pic: string;
  class: string;
  client: Client;
}

@Component({
  selector: 'app-message-composer',
  templateUrl: './message-composer.component.html',
  styleUrls: ['./message-composer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MessageComposerComponent implements OnInit {
  MESSAGETYPE = MESSAGETYPE;

  public emptyCase: TaxCase = TaxCase.getTemplate();

  private aClient: Client | ClientShort | null;

  isAppUser = false;

  @Input()
  public messagetype: MESSAGETYPE = MESSAGETYPE.WHATSAPP;
  searchClient: SearchClient;
  clientsearch: SearchIndex;

  constructor(
    public clientservice: ClientsService,
    public taxcaseseservice: TaxcasesService,
    public messageService: MessagingService,
    public blockUiService: BlockUiService,
    public notificationService: NotificationService,
    public templatesservice: MessagetemplateService,
    public algoliaService: AlgoliaService,
    private security: AuthenticationService
  ) {
    this.messageService.eventOpen.subscribe((open: MessageOpen) => {
      console.log('MESSANGER OPEN', open);
      this.set(open.clientId, open.taxcaseId, open.templateId, open.type);
    });
    this.algoliaService.searchClient$.subscribe((searchClient) => {
      this.searchClient = searchClient;
      this.clientsearch = this.searchClient.initIndex(
        environment.algolia.indexNames.clients
      );
    });
  }

  public currentTemplate: BaseMessageTemplate;
  public currentTaxCase: TaxCase;
  public currentTaxCases: TaxCase[] = [];
  public currentClient: Client;
  public tagify;

  public async set(
    clientId: string = '',
    taxcaseid: string = '',
    templateid: string = '',
    messagetype: MESSAGETYPE = MESSAGETYPE.WHATSAPP
  ) {
    this.resetComposer();

    if (clientId !== '') {
      this.clientid = clientId;

      await this.getClientTaxCases();
    }

    if (taxcaseid !== null) {
      this.taxcase = taxcaseid;
    }
  }

  @Input()
  public set clientid(id: string) {
    this.clientservice.load(id).then((c) => {
      this.aClient = c;
      if (this.aClient) {
        console.log('ADD CLIENT AS TAG');

        const item: ListItem = {
          class: 'tagify__tag--primary',
          email: this.aClient.email,
          initials:
            (this.aClient.firstname as string).substr(0, 1).toUpperCase() +
            (this.aClient.lastname as string).substr(0, 1).toUpperCase(),
          initialsState: '',
          pic: '/assets/media/users/default.jpg',
          value: this.aClient.firstname + ' ' + this.aClient.lastname,
          firstname: this.aClient.firstname,
          lastname: this.aClient.lastname,
          mobile: this.aClient.mobile,
          client: this.aClient,
        };

        this.tagify.addTags([item]);
        this.getClientTaxCases();
      }
    });
    firstValueFrom(this.clientservice.userHasAppInstalled(id))
      .then((isAppAvailable) => (this.isAppUser = isAppAvailable))
      .catch((err) =>
        console.log(
          `unable to retrieve if user has app installed: ${err.message}`
        )
      );
  }

  @Input()
  public set taxcase(id: string) {
    if (id && id.length > 5) {
      this.taxcaseseservice.load(id, false, true).then((tc) => {
        this.currentTaxCases = [tc];
      });
    }
  }

  ngOnInit(): void {
    $(document).ready(async () => {
      await KTAppInbox.initCompose();

      this.cleanTagsFromInput();

      this.initComposeInputTags('compose_to');
      // this.initComposeInputTags('compose_cc');
      // this.initComposeInputTags('compose_bcc');
    });
  }

  public getComposeTo(): ListItem {
    const inputValue = $('input.input_compose_to').serializeArray()[0].value;
    if (inputValue !== '') {
      return JSON.parse(inputValue)[0];
    } else {
      return null;
    }
  }

  public getComposeCc(): ListItem {
    const inputValue = $('input.input_compose_cc').serializeArray()[0].value;
    if (inputValue !== '') {
      return JSON.parse(inputValue);
    } else {
      return null;
    }
  }

  public getComposeBcc(): ListItem {
    const inputValue = $('input.input_compose_bcc').serializeArray()[0].value;
    if (inputValue !== '') {
      return JSON.parse(inputValue);
    } else {
      return null;
    }
  }

  public getComposeSubject(): string {
    const inputValue = $('input.input_compose_subject').val();
    if (inputValue !== '') {
      return inputValue;
    } else {
      return null;
    }
  }

  public getActionLink(): string {
    const inputValue = $('input.input_compose_action_link').val();
    console.log('here is our action link');
    console.log(inputValue);
    if (inputValue !== '') {
      return inputValue;
    } else {
      return null;
    }
  }

  public getComposeMessage(): string {
    let inputValue;

    if (this.messagetype === MESSAGETYPE.EMAIL) {
      inputValue = $('#kt_inbox_compose_editor .ql-editor').html();
    } else {
      inputValue = $('#kt_inbox_compose_editor .ql-editor').text();
    }

    if (inputValue === '<p><br></p>') {
      return null;
    } else {
      return inputValue;
    }
  }

  public setComposeMessage(value: string) {
    $('#kt_inbox_compose_editor .ql-editor').text(value);
  }

  public setComposeSubject(value: string) {
    $('#compose_subject').val(value);
  }

  public async getClientTaxCases() {
    try {
      const client = this.getComposeTo()?.client;

      if (!client) {
        return;
      }

      console.log(client.id);
      console.log(client.firstname);

      const aClient: Client = await this.clientservice.load(
        this.getComposeTo().client.id,
        true
      );
      // const c: Client = this.client ? this.client as Client : this.getComposeTo().client;
      console.log('Client : ' + aClient.firstname);

      this.currentTaxCases = await this.clientservice.getCasesOfClient(aClient);

      console.log('cases loaded', this.currentTaxCases);
    } catch (err) {
      console.error('client not defined', err);
    }

    if (this.currentTaxCases.length > 0) {
      console.log('Taxcases exosts ', this.currentTaxCases.length);
      this.currentTaxCase = this.currentTaxCases[0];
    } else {
      console.log('No taxcases for client found');
    }
  }

  initComposeInputTags(inputName: string) {
    let controller: AbortController;
    const formEl = KTUtil.getById('kt_inbox_compose_form');
    const ccEl = KTUtil.find(formEl, '[name=' + inputName + ']');
    this.tagify = new Tagify(ccEl, {
      delimiters: ', ', // add new tags when a comma or a space character is entered
      maxTags: 1,
      blacklist: ['fuck', 'shit', 'pussy'],
      keepInvalidTags: true, // do not remove invalid tags (but keep them marked as invalid)
      whitelist: [],
      templates: {
        dropdownItem(tagData: {
          initialsState: any;
          pic: any;
          initials: any;
          value: any;
          email: any;
          mobile;
        }) {
          try {
            let html = '';

            html += '<div class="tagify__dropdown__item">';
            html += '   <div class="d-flex align-items-center">';
            // eslint-disable-next-line max-len
            html +=
              '       <span class="symbol sumbol-' +
              (tagData.initialsState ? tagData.initialsState : '') +
              ' mr-2" style="background-image: url(\'' +
              (tagData.pic ? tagData.pic : '') +
              '\')">';
            html +=
              '           <span class="symbol-label">' +
              (tagData.initials ? tagData.initials : '') +
              '</span>';
            html += '       </span>';
            html += '       <div class="d-flex flex-column">';
            // eslint-disable-next-line max-len
            html +=
              '           <a href="#" class="text-dark-75 text-hover-primary font-weight-bold">' +
              (tagData.value ? tagData.value : '') +
              '</a>';
            // eslint-disable-next-line max-len
            html +=
              '           <span class="text-muted font-weight-bold">' +
              (tagData.email ? tagData.email : '') +
              ' ' +
              (tagData.mobile ? tagData.mobile : '') +
              '</span>';
            html += '       </div>';
            html += '   </div>';
            html += '</div>';

            return html;
          } catch (err) {
            console.log(err);
          }
        },
      },
      transformTag(tagData: { class: string }) {
        tagData.class = 'tagify__tag tagify__tag--primary';
      },
      dropdown: {
        classname: 'color-blue',
        enabled: 1,
        maxItems: 10,
      },
    });

    this.tagify.on('dropdown:hide', async (e) => {
      this.getClientTaxCases();
    });

    this.tagify.on('input', async (e) => {
      const value: string = e.detail.value;
      if (value.length > 4) {
        this.tagify.settings.whitelist.length = 0; // reset the whitelist

        controller && controller.abort();
        controller = new AbortController();

        // show loading animation and hide the suggestions dropdown
        this.tagify.loading(true).dropdown.hide.call(this.tagify);

        const searchList = await this.search(value);

        this.tagify.settings.whitelist = searchList;

        this.tagify.loading(false).dropdown.show.call(this.tagify, value);
      }
    });
  }

  public async search(value: string): Promise<any> {
    const resultList = [];
    try {
      const r = await this.clientsearch.search(value);

      const clients = r.hits;
      for (const client of clients) {
        const clientData = client as any;
        const item: ListItem = {
          class: 'tagify__tag--primary',
          email: clientData.email,
          initials:
            (clientData.firstname as string).substr(0, 1).toUpperCase() +
            (clientData.lastname as string).substr(0, 1).toUpperCase(),
          initialsState: '',
          pic: '/assets/media/users/default.jpg',
          value: clientData.firstname + ' ' + clientData.lastname,
          firstname: clientData.firstname,
          lastname: clientData.lastname,
          mobile: clientData.mobile,
          client: clientData,
        };
        item.client.id = client.objectID;
        resultList.push(item);
      }
      console.log('result', r.hits.length);
      return resultList;
    } catch (err) {
      console.log('Access to search searvice failed', err);
      return [];
    }
  }

  private cleanTagsFromInput() {
    $('.input_compose_to').parent().find('tags').first().remove();
    $('.input_compose_cc').parent().find('tags').first().remove();
    $('.input_compose_bcc').parent().find('tags').first().remove();
  }

  private clearInput() {
    $('.input_compose_to').val('');
    $('.input_compose_cc').val('');
    $('.input_compose_bcc').val('');
    $('.ql-editor').html('');
    $('#kt_inbox_compose_form')[0].reset();
  }

  public validate() {
    if (this.currentTemplate) {
      this.setTemplate(this.currentTemplate);
    }
  }

  public async setTemplate(template: BaseMessageTemplate | null) {
    console.log('set template');
    if (template) {
      const client: Client = this.clientid
        ? (this.aClient as Client)
        : this.getComposeTo().client;
      const taxcase: TaxCase = this.currentTaxCase
        ? this.currentTaxCase
        : TaxCase.getTemplate();
      try {
        const config: MessageConfig = {
          client,
          taxCase: taxcase,
          user: this.security.user,
          from: this.security.user,
        };

        const msg = Message.getTemplate(MESSAGETYPE.WHATSAPP, template, config);

        this.setComposeMessage(msg.message);

        try {
          this.setComposeSubject(template.subject);
        } catch (err) {
          console.error(err);
        }
      } catch (err) {
        console.error('replace template message errorr:', err);
      }
    } else {
      this.setComposeMessage('');
    }
  }

  public async sendMessage() {
    this.blockUi();

    const to = this.getComposeTo() as ListItem;
    const subject = this.getComposeSubject() as string;
    const message = this.getComposeMessage() as string;
    const actionLink = this.getActionLink() as string;

    const isFormValid = to !== null && subject !== null && message !== null;

    if (isFormValid) {
      let messageTo = to.client !== undefined ? to.client : to.value;

      if (messageTo instanceof Object) {
        messageTo = await this.clientservice.load(messageTo.id, true);
      }

      console.log(messageTo, subject, message);

      try {
        // console.log(message);
        await this.messageService.send(
          this.messagetype,
          messageTo,
          message,
          subject,
          this.currentTaxCase ? this.currentTaxCase.id : undefined,
          null,
          true,
          actionLink,
          NotificationChannels.TaxcaseUpdates
        );

        this.notificationService.success(
          'Nachricht',
          'Nachricht wurde verschickt'
        );
      } catch (error) {
        this.unblockUi();
        this.notificationService.warning(
          'Oops da ist was schief gelaufen',
          'Bitte versuch es erneut.'
        );
        console.log(error);
        return;
      }

      this.unblockUi();
    } else {
      this.unblockUi();
      this.notificationService.info('Hinweis', 'Fülle alle Felder aus.');
    }
  }

  public resetComposer() {
    this.clearInput();
    this.cleanTagsFromInput();
    this.initComposeInputTags('compose_to');
  }

  public blockUi() {
    this.blockUiService.block('kt_inbox_compose_form');
  }

  public unblockUi() {
    this.blockUiService.unblock('kt_inbox_compose_form');
  }
}
