<div class="btn-group btn-group-lg" role="group">
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="workingdays === 1"
    (click)="workingdays = 1"
  >
    1
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="workingdays === 2"
    (click)="workingdays = 2"
  >
    2
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="workingdays === 3"
    (click)="workingdays = 3"
  >
    3
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="workingdays === 4"
    (click)="workingdays = 4"
  >
    4
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="workingdays === 5"
    (click)="workingdays = 5"
  >
    5
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="workingdays === 6"
    (click)="workingdays = 6"
  >
    6
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    [class.active]="workingdays === 7"
    (click)="workingdays = 7"
  >
    7
  </button>
</div>
