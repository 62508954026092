<div *ngIf="expense">
  <h1>Ausgabe</h1>
  <section>
    <mat-form-field>
      <mat-label>Kategorie</mat-label>

      <mat-select [(ngModel)]="expense.category">
        <mat-option [value]="ADDITIONALEXPENSECATEGORY.workequipment">
          Arbeitsmittel
        </mat-option>
        <mat-option [value]="ADDITIONALEXPENSECATEGORY.advertise">
          Werbungskosten
        </mat-option>
        <mat-option [value]="ADDITIONALEXPENSECATEGORY.special">
          Sonderausgaben
        </mat-option>
        <mat-option [value]="ADDITIONALEXPENSECATEGORY.household">
          Haushaltsnahe Dienstleistungen
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label> Unterkategorie </mat-label>

      <mat-select
        [(ngModel)]="expense.subcategory"
        (change)="onSubCategoryChange()"
      >
        <mat-option
          value="workequipment"
          *ngIf="expense.category === ADDITIONALEXPENSECATEGORY.workequipment"
        >
          Arbeitsmittel
        </mat-option>
        <mat-option
          value="workroom"
          *ngIf="expense.category === ADDITIONALEXPENSECATEGORY.advertise"
        >
          Arbeitszimmer
        </mat-option>
        <mat-option
          value="education"
          *ngIf="expense.category === ADDITIONALEXPENSECATEGORY.advertise"
        >
          Ausbildung
        </mat-option>
        <mat-option
          value="application"
          *ngIf="expense.category === ADDITIONALEXPENSECATEGORY.advertise"
        >
          Werbungskosten
        </mat-option>
        <mat-option
          value="relocation"
          *ngIf="expense.category === ADDITIONALEXPENSECATEGORY.advertise"
        >
          Umzugskosten
        </mat-option>
        <mat-option
          value="travelcost"
          *ngIf="expense.category === ADDITIONALEXPENSECATEGORY.advertise"
        >
          Reisekosten
        </mat-option>
        <mat-option
          value="doublehouseholdcost"
          *ngIf="expense.category === ADDITIONALEXPENSECATEGORY.advertise"
        >
          Doppelte Haushaltsführung
        </mat-option>
        <mat-option
          value="living"
          *ngIf="expense.category === ADDITIONALEXPENSECATEGORY.special"
        >
          Unterhalt
        </mat-option>
        <mat-option
          value="donation"
          *ngIf="expense.category === ADDITIONALEXPENSECATEGORY.special"
        >
          Spenden
        </mat-option>
        <mat-option
          value="extraburden"
          *ngIf="expense.category === ADDITIONALEXPENSECATEGORY.special"
        >
          Außergewöhnliche Belastung
        </mat-option>
        <mat-option
          value="householdservices"
          *ngIf="expense.category === ADDITIONALEXPENSECATEGORY.household"
        >
          Haushaltsnahe Dienstleistungen
        </mat-option>
        <mat-option
          value="craftmanservices"
          *ngIf="expense.category === ADDITIONALEXPENSECATEGORY.household"
        >
          Handwerkerleistungen
        </mat-option>
        <mat-option value="other">Weitere Kosten (andere)</mat-option>
      </mat-select>
    </mat-form-field>
  </section>

  <section>
    <mat-form-field>
      <mat-label>Betrag</mat-label>
      <esui-number-input [(ngModel)]="expense.value"></esui-number-input>
    </mat-form-field>
    <mat-checkbox [(ngModel)]="expense.partial"> Partiell?</mat-checkbox>
  </section>

  <mat-form-field>
    <mat-label>Beschreibung</mat-label>
    <esui-text-input [(ngModel)]="expense.description"></esui-text-input>
  </mat-form-field>
</div>
