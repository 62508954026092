<mat-form-field [appearance]="appearance">
  <mat-icon matPrefix>search</mat-icon>
  <input
    matInput
    [matAutocomplete]="auto"
    placeholder="search client"
    (keyup)="handleChange($event)"
    (keyup.enter)="tryToEmitInputAsClientId(searchFieldValue)"
    [(ngModel)]="searchFieldValue"
  />
  <button
    *ngIf="searchFieldValue"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="emitClientId(null)"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete">
    <div *ngFor="let index of state?.indices || []; trackBy: trackByValue">
      <mat-option
        *ngFor="let option of index.hits; trackBy: trackById"
        [value]="mode === 'clientIdEditor' ? option.objectID : option.email"
        (click)="clientClickHandler(option)"
      >
        <div class="option">
          <div>
            <div class="name">{{ option.firstname }} {{ option.lastname }}</div>
          </div>
          <div>
            <div class="row email">
              <span class="icon">
                <mat-icon [inline]="true">email</mat-icon>
              </span>
              <span class="text">{{ option.email }}</span>
            </div>
            <div class="row mobile">
              <span class="icon">
                <mat-icon [inline]="true">call</mat-icon>
              </span>
              <span class="text">{{ option.mobile }}</span>
            </div>
            <div class="row location">
              <span class="icon">
                <mat-icon [inline]="true">pin_drop</mat-icon>
              </span>
              <span class="text">
                <span class="region" *ngIf="!option.address">
                  {{ option.city.longName }} {{ option.country.longName }}
                </span>
                <span class="address" *ngIf="option.address">
                  {{ option.address }}
                </span>
              </span>
            </div>
            <div class="row status">
              <span class="icon">
                <mat-icon [inline]="true">sync</mat-icon>
              </span>
              <span class="text">{{ option.status }}</span>
            </div>
            <div class="row objectID">
              <span class="icon">
                <mat-icon [inline]="true">tag</mat-icon>
              </span>
              <span class="text">{{ option.objectID }}</span>
            </div>
          </div>
        </div>
      </mat-option>
    </div>
  </mat-autocomplete>
</mat-form-field>

<ng-container *ngIf="localPreview && mode === 'default'">
  <mat-form-field>
    <input
      matInput
      placeholder="firstname"
      disabled
      [ngModel]="localPreview.firstname"
    />
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      placeholder="lastname"
      disabled
      [ngModel]="localPreview.lastname"
    />
  </mat-form-field>
</ng-container>

<mat-form-field *ngIf="mode === 'default'">
  <input matInput placeholder="client id" disabled [ngModel]="clientId" />
</mat-form-field>
