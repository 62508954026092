import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import { DeepPartial } from '@expresssteuer/deep-object-helper';
import { AbstractTimestamp } from '@expresssteuer/firebase-helper';
import { Timestamp } from '@expresssteuer/models';
import moment, { Moment } from 'moment';
import {
  AbstractValueAccessor,
  makeProvider,
} from '../abstract-value-accessor';

function timestampValidation(
  control: AbstractControl<AbstractTimestamp | null>
): ValidationErrors | null {
  const tmspt = (control.value as DeepPartial<AbstractTimestamp>) || null;

  if (!tmspt) {
    return null;
  }
  if (tmspt.seconds && (tmspt as any).toDate) {
    // maybe use isFirebaseTimestamp in the future?
    return null;
  }
  return { TypeError: true };
}

/**
 * Depends on used DateAdapter:
 * NativeDateAdapter: Date  (supports only en-US)
 * MomentDateAdapter: Moment (current)
 *
 * other alternatives:
 * LuxonDateAdapter
 * DateFnsDateAdapter (Angular 13)
 */
type InternalDate = Moment;
type ExternalDate = Timestamp;
@Component({
  selector: 'esui-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useValue: timestampValidation,
      multi: true,
    },
    ...makeProvider(DateInputComponent),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DateInputComponent extends AbstractValueAccessor<ExternalDate | null> {
  @Input() viewMode = false;
  @Input() min = '1900-01-01';
  @Input() max = '2999-12-31';

  get empty(): boolean {
    if (!this.value) {
      return true;
    }

    const asDate = this.value?.toDate?.();
    return !(asDate instanceof Date) || isNaN(+asDate);
  }

  controlType = 'DateInputComponent';
  protected data: Timestamp | null = null;

  #proxyCache: InternalDate | null = null;

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elementRef.nativeElement.querySelector('input')?.focus();
    }
  }

  get date() {
    return this.value?.toDate?.();
  }

  set valueProxy(val: InternalDate | null) {
    this.#proxyCache = val;
    this.value = val
      ? Timestamp.fromDate(
          //string format to prevent timezone offset (regression bug)
          new Date(val.format('YYYY-MM-DD'))
        )
      : null;
  }
  get valueProxy(): InternalDate | null {
    return this.#proxyCache;
  }

  @Input()
  override get value(): ExternalDate | null {
    return super.value;
  }
  override set value(v: ExternalDate | null) {
    this.#proxyCache = v ? moment(v.toDate()) : null;
    super.value = v;
  }

  override writeValue(value: ExternalDate) {
    super.writeValue(value);
    if (!this.value) {
      return (this.#proxyCache = null);
    }
    try {
      return (this.#proxyCache = moment(value.toDate()));
    } catch (error) {
      console.log('date get proxy error');
    }
    return (this.#proxyCache = null);
  }
}
