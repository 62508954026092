import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Client,
  TaxCase,
  TAXCASESTATUS,
  TAXCASESTATUS_INPROGRESS,
  TAXCASESTATUS_REJECT,
} from '@expresssteuer/models';

@Component({
  selector: 'app-control-actionbar-taxcase',
  templateUrl: './control-actionbar-taxcase.component.html',
  styleUrls: ['./control-actionbar-taxcase.component.scss'],
})
export class ControlActionbarTaxcaseComponent implements OnInit {
  TAXCASESTATUS = TAXCASESTATUS;
  TAXCASESTATUS_INPROGRESS = TAXCASESTATUS_INPROGRESS;
  TAXCASESTATUS_REJECT = TAXCASESTATUS_REJECT;

  private aTaxcase: TaxCase;
  private aClient: Client;

  @Output()
  public taxcaseChanged = new EventEmitter<TaxCase>();

  @Input()
  public get taxcase(): TaxCase {
    return this.aTaxcase;
  }

  public set taxcase(val: TaxCase) {
    this.aTaxcase = val;
    this.taxcaseChanged.emit(val);
  }

  public set client(val: Client) {
    this.aClient = val;
  }

  @Input()
  public get client(): Client {
    return this.aClient;
  }

  constructor() {}

  ngOnInit(): void {}
}
