import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'esui-firestore-paginator',
  templateUrl: './esui-firestore-paginator.component.html',
  styleUrls: ['./esui-firestore-paginator.component.scss'],
})
export class EsuiFirestorePaginatorComponent {
  @Input() ahead?: number | null;
  @Input() nextImpossible?: boolean | null;
  @Input() pageSize?: number | null;
  @Input() backImpossible?: boolean | null;
  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() nextClick = new EventEmitter<void>();
  @Output() previousClick = new EventEmitter<void>();
  @Output() startClick = new EventEmitter<void>();

  @Input() pageSizeOptions: number[] = [5, 10, 25, 100];
}
