<div class="d-flex card card-custom gutter-b" *ngIf="client">
  <div class="card-body">
    <!--begin::Details-->
    <div class="d-flex mb-9">
      <!--begin: Pic-->
      <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
        <div class="symbol symbol-50 symbol-lg-120">
          <img src="/assets/media/users/blank.png" alt="image" />
        </div>

        <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
          <span class="font-size-h3 symbol-label font-weight-boldest"></span>
        </div>
      </div>
      <!--end::Pic-->

      <!--begin::Info-->
      <div class="flex-grow-1">
        <!--begin::Title-->
        <div class="d-flex justify-content-between flex-wrap mt-1">
          <div class="d-flex mr-3">
            <a
              [routerLink]="['/client', client?.id]"
              class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
              >{{ client?.firstname }} {{ client?.lastname }}</a
            >
            <a>
              <i
                class="flaticon2-correct text-primary font-size-h5"
                *ngIf="client.status === CLIENTSTATUS.NEW"
              ></i>
              <i
                class="flaticon-medal text-success font-size-h5"
                *ngIf="client.status === CLIENTSTATUS.CONFIRMED"
              ></i>
              <i
                class="flaticon-warning-sign text-danger font-size-h5"
                *ngIf="
                  client.status === CLIENTSTATUS.FRAUD ||
                  client.status === CLIENTSTATUS.DEACTIVATED ||
                  client.status === CLIENTSTATUS.DELETED
                "
              ></i>
            </a>
          </div>

          <!--
                    <div class="my-lg-0 my-3">
                        <a href="#" class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3">ask</a>
                        <a href="#" class="btn btn-sm btn-info font-weight-bolder text-uppercase">hire</a>
                    </div>
                    -->
        </div>
        <!--end::Title-->

        <!--begin::Content-->
        <div class="d-flex flex-wrap justify-content-between mt-1">
          <div class="d-flex flex-column flex-grow-1 pr-8">
            <div class="d-flex flex-wrap mb-4">
              <a
                (click)="sendEmail()"
                class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                ><i class="flaticon2-new-email mr-2 font-size-lg"></i
                >{{ client?.email }}</a
              >
              <a
                (click)="sendWhatsApp()"
                class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                ><i class="flaticon2-phone mr-2 font-size-lg"></i>
                {{ client?.mobile }}</a
              >
              <a class="text-dark-50 text-hover-primary font-weight-bold"
                ><i class="flaticon2-placeholder mr-2 font-size-lg"></i
                >{{ client?.addressClient.city.longName }}
              </a>
            </div>

            <div class="d-flex flex-wrap mb-4">
              <a
                class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                ><i
                  class="flaticon2-new-email mr-2 font-size-lg"
                  *ngIf="age"
                ></i>
                <span *ngIf="client?.birthdate">
                  Geburtstag:
                  {{ client?.birthdate.toDate() | date : 'dd.MM.yyyy' }}
                </span>
                (Alter: {{ age > 0 ? age : '??' }})
              </a>
              <a
                class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                *ngIf="taxCase"
              >
                <i class="flaticon-black mr-2 font-size-lg"></i>
                {{ taxCase.taxform.current.taxForm.information.maritalStatus }}
              </a>
              <a class="text-dark-50 text-hover-primary font-weight-bold">
                <i class="flaticon2-placeholder mr-2 font-size-lg"></i>
                {{ client?.addressClient.formatedAddress }}
              </a>
              <a
                class="text-dark-50 text-hover-primary font-weight-bold"
                *ngIf="
                  taxCase &&
                  taxCase.taxform.current.taxForm.childrens.length > 0
                "
              >
                &nbsp;
                <i class="fa fa-child mr-2 font-size-lg"></i>
                Kinder {{ taxCase.taxform.current.taxForm.childrens.length }}
              </a>
              <a class="text-dark-50 text-hover-primary font-weight-bold">
                &nbsp;
                <i class="fa fa-child mr-2 font-size-lg"></i>
                Erstellt
                {{ taxCase.metadata.created.toDate() | date : 'dd.MM.yyyy' }}
              </a>
            </div>

            <!--
                        <span class="font-weight-bold text-dark-50">I distinguish three main text objectives could be
                            merely to inform people.</span>-->
          </div>

          <!--
                    <div class="d-flex align-items-center w-25 flex-fill float-right mt-lg-12 mt-8">
                        <span class="font-weight-bold text-dark-75">Progress</span>
                        <div class="progress progress-xs mx-3 w-100">
                            <div class="progress-bar bg-success" role="progressbar" style="width: 63%;"
                                aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <span class="font-weight-bolder text-dark">78%</span>
                    </div>
                    -->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Info-->
    </div>
    <!--end::Details-->

    <!--
        <div class="separator separator-solid"></div>
        -->
  </div>
</div>
