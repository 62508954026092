import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InternalUser } from '@expresssteuer/models';
@Component({
  selector: 'esui-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent {
  @Input() user?: InternalUser;

  @Input() open = false;
  @Output() openChange = new EventEmitter<boolean>();

  toggle() {
    this.open = !this.open;
    this.openChange.next(this.open);
  }
}
