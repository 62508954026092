import { Timestamp } from '../helpers/timestamp';

/**
 * DB Reference to a DIVA or RAW Bescheid Retrieval
 * Documents state and location of a retrieved document
 */
export abstract class ElsterRetrievalDocumentReference {
  id = '';
  elsterReference = '';
  status: ElsterRetrievalDocumentStatus = ElsterRetrievalDocumentStatus.OPEN;
  lastError = '';
  date_of_retrieval: Timestamp | null = null;
  updated_at: Timestamp = Timestamp.now();
  created: Timestamp = Timestamp.now();
  bucketPath: string | null = null;
  date_of_confirmation: Timestamp | null = Timestamp.now();
  taxadvisor: string | null = null;
  databasepath: string | null = null;

  static getTemplate(
    obj: Partial<ElsterRetrievalDocumentReference> & { elsterReference: string }
  ): ElsterRetrievalDocumentReference {
    return {
      status: ElsterRetrievalDocumentStatus.OPEN,
      elsterReference: obj.elsterReference,
      lastError: obj.lastError ?? '',
      updated_at: Timestamp.now(),
      created: Timestamp.now(),
      date_of_retrieval: null,
      date_of_confirmation: null,
      bucketPath: null,
      id: `${obj.elsterReference}`,
      taxadvisor: obj.taxadvisor ?? null,
      databasepath: null,
    };
  }
}

export enum ElsterRetrievalDocumentStatus {
  OPEN,
  RETRIEVED,
  ERROR,
  CONFIRMED,
}
