<mat-card>
  <span esuiCardKey>{{ task?.type }}</span>
  <mat-card-header>
    <mat-card-title>{{ task?.title }}</mat-card-title>
    <mat-card-subtitle>
      <esui-store-state-indicator
        [hasOnlineChangedAfterLocalChanged]="
          !!taxofficeLetterStoreStateIndicator?.hasOnlineChangedAfterLocalChanged
        "
        [isSaving]="!!taxofficeLetterStoreStateIndicator?.isSaving"
        [isLoading]="!!taxofficeLetterStoreStateIndicator?.isLoading"
        [hasChanges]="!!taxofficeLetterStoreStateIndicator?.hasChanges"
        [hasErrors]="!!taxofficeLetterStoreStateIndicator?.hasErrors"
      ></esui-store-state-indicator
    ></mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div class="container">
      <div class="document-container">
        <div class="padding-10">
          <h4>Dokument der Erinnerung vom Finanzamt</h4>
          <div class="divider-100">
            <mat-divider></mat-divider>
          </div>
          <div class="padding-10">
            Referenz: {{ taxofficeLetter?.letterReference }}
          </div>
        </div>
        <div class="p-10">
          <esui-skeleton-placeholder
            class="skelleton-container"
            [bigBoxMode]="true"
            [skeletonBuildInput]="[]"
            [watchResult]="!!originalDocument"
          >
            <ng-container *ngIf="originalDocument">
              <esui-document-viewer
                class="document-viewer-container"
                [contentType]="originalDocument.contentType"
                [downloadUrl]="originalDocument.downloadUrl"
              ></esui-document-viewer>
            </ng-container>
          </esui-skeleton-placeholder>
        </div>
      </div>
      <div class="work-space">
        <esui-skeleton-placeholder
          class="skelleton-flex"
          [skeletonBuildInput]="[
            [0, 3],
            [0, 3],
            [0, 2],
            [0, 1, 1, 1]
          ]"
          [watchResult]="!!taxofficeRequests && !!taxofficeLetter"
          [bigBoxMode]="false"
        >
          <div class="request-selection-row">
            <h4>Mandant</h4>
            <div class="divider-100">
              <mat-divider></mat-divider>
            </div>
            Name: {{ client?.displayName }}
          </div>
          <div class="request-selection-row">
            <h4>Wähle eine Nachfrage des Finanzamts an den Mandanten aus</h4>
            <div class="divider-100">
              <mat-divider></mat-divider>
            </div>
            <mat-form-field class="taxoffice-request-select">
              <mat-label>Nachfrage des Finanzamts</mat-label>
              <mat-select
                name="documenttype"
                [ngModel]="activeTaxofficeRequest?.id"
                (ngModelChange)="taxofficeRequestSelectedId.next($event)"
              >
                <mat-option
                  *ngFor="let option of taxofficeRequests"
                  [value]="option.id"
                >
                  Nachfrage vom {{ option.creationDate.toDate() | date }}
                  <span *ngIf="option.letterReference">
                    (Referenz: {{ option.letterReference }})</span
                  >
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="request-selection-row">
            <h4>Dokument der Nachfrage vom Finanzamt</h4>
            <div class="divider-100">
              <mat-divider></mat-divider>
            </div>
            <div class="document-container">
              <div class="padding-10">
                <esui-skeleton-placeholder
                  class="skelleton-container"
                  [bigBoxMode]="true"
                  [skeletonBuildInput]="[]"
                  [watchResult]="!!originalDocumentOfActiveRequest"
                >
                  <ng-container
                    *ngIf="
                      originalDocumentOfActiveRequest &&
                        originalDocumentOfActiveRequest?.downloadUrl &&
                        originalDocumentOfActiveRequest?.source &&
                        originalDocumentOfActiveRequest?.contentType;
                      else newDeb
                    "
                  >
                    <esui-document-viewer
                      class="document-viewer-container"
                      [contentType]="
                        originalDocumentOfActiveRequest.contentType
                      "
                      [downloadUrl]="
                        originalDocumentOfActiveRequest.downloadUrl
                      "
                    ></esui-document-viewer>
                  </ng-container>
                  <ng-template #newDeb>
                    keine Nachfrage ausgewählt
                  </ng-template>
                </esui-skeleton-placeholder>
              </div>
            </div>
          </div>
        </esui-skeleton-placeholder>
      </div>
    </div>
  </mat-card-content>

  <mat-card-actions>
    <button
      type="button"
      mat-raised-button
      color="primary"
      (click)="verifyAndSave.next()"
      [disabled]="
        taxofficeLetterStoreStateIndicator?.isSaving === true ||
        taxofficeLetterStoreStateIndicator?.isLoading === true
      "
    >
      verify and save
    </button>
    <button
      type="button"
      mat-raised-button
      color="accent"
      (click)="save.next()"
      [disabled]="
        taxofficeLetterStoreStateIndicator?.hasChanges !== true ||
        taxofficeLetterStoreStateIndicator?.isSaving === true ||
        taxofficeLetterStoreStateIndicator?.isLoading === true
      "
    >
      save
    </button>
    <button
      type="button"
      mat-raised-button
      color="warn"
      (click)="discard.next()"
      [disabled]="
        taxofficeLetterStoreStateIndicator?.hasChanges !== true ||
        taxofficeLetterStoreStateIndicator?.isSaving === true ||
        taxofficeLetterStoreStateIndicator?.isLoading === true
      "
    >
      discard
    </button>
  </mat-card-actions>
</mat-card>
