import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EsuiTouchOnSubmitDirective } from './esui-touch-on-submit.directive';

@NgModule({
  declarations: [EsuiTouchOnSubmitDirective],
  imports: [CommonModule],
  exports: [EsuiTouchOnSubmitDirective],
})
export class EsuiTouchOnSubmitModule {}
