import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientTodo } from '@expresssteuer/client-todos-api-interfaces';

@Component({
  selector: 'esui-client-todos-list',
  templateUrl: './esui-client-todos-list.component.html',
  styleUrls: ['./esui-client-todos-list.component.scss'],
  animations: [
    trigger('animateDestroy', [
      state(
        'void',
        style({ transform: 'scaleY(0)', transformOrigin: 'top', height: 0 })
      ),
      transition('* => void', animate('500ms ease')),
    ]),
  ],
})
export class EsuiClientTodosListComponent {
  @Input() todos?: ClientTodo[] | null = [];

  @Output() ngModelChange = new EventEmitter<ClientTodo[]>();
  @Input() ngModel?: ClientTodo[] | null = [];

  @Output() regenerateClick = new EventEmitter<void>();

  trackById(index: number, { id }: { id: string }) {
    return id;
  }
}
