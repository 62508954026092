import { Injectable } from '@angular/core';
import { TaxCase } from '@expresssteuer/models';
import moment from 'moment';
import { TaxcasesService } from './taxcases.service';

@Injectable({
  providedIn: 'root',
})
export class SepaService {
  constructor(public tcs: TaxcasesService) {}

  public async getExportForTaxId(
    taxcase: TaxCase,
    open: boolean = true
  ): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      const now: Date = new Date();

      const result = moment().format('YYYY-MM-DD');
      console.log(result);

      const tc = taxcase;

      const server =
        'http://ec2-35-157-130-142.eu-central-1.compute.amazonaws.com';
      const path = '/sepa/getLastschrift.php?';
      const firstname = 'firstname=' + tc.client.firstname;
      const lastname = '&lastname=' + tc.client.lastname;
      const iban = '&iban=' + tc.taxform.current.client.bank.IBAN.trim();
      const bic = '&bic=' + tc.taxform.current.client.bank.bankBIC.trim();
      const createDate = '&createDate=' + result;
      const id = '&id=' + tc.id;
      const year =
        '&year=' + tc.taxform.current.taxForm.information.yearOfReimbursement;

      const amount =
        '&amount=' +
        Math.round(
          tc.taxform.current.calculation.offer.gross.customerCost * 100
        ) /
          100;

      const url =
        server +
        path +
        firstname +
        lastname +
        iban +
        createDate +
        id +
        bic +
        amount +
        year;
      console.log(url);

      if (open) {
        window.open(url, '_blank');
      }
      resolve(url);
    });
  }
}
