import { Timestamp } from '@google-cloud/firestore';
import { IQontoTransaction } from '@expresssteuer/qonto';
import { BankProvider } from '../bank-provider';

/**
 * An Entry from a BankTransation
 * Optimized for use with qonto
 */
export class BankTransactionLine implements IQontoTransaction {
  static extractYear(transaction: BankTransactionLine): number | null {
    /** ERSTATT.11/335/17656 EST-VERANL. 17  ----  extract the year 17 **/

    const yearReg = /\s(\d{2,4})[\s|\n|\r]/gim;
    const match = yearReg.exec(transaction.note);
    console.log(match);

    if (match) {
      if (match[1].length === 2) {
        return parseInt('20' + match[1]);
      }
      if (match[1].length === 4) {
        return parseInt(match[1]);
      }
    }
    return null;
  }

  /**
   * The transaction id Unique for a transaction
   */
  transaction_id = '';
  /**
   * If the transaction can be attached to a client, we set it here
   */
  clientId: string | null = null;

  provider: BankProvider = BankProvider.QONTO;
  amount = 0;
  amount_cents = 0;
  local_amount = 0;
  local_amount_cents = 0;

  metadata: BankTransactionMetadata | null = null;

  /**
   * credit or debit transaction
   * INBOUND or OUTBOUND transactions
   */
  side: 'credit' | 'debit' = 'credit';
  operation_type:
    | 'income'
    | 'transfer'
    | 'card'
    | 'direct_debit'
    | 'qonto_fee'
    | 'cheque'
    | 'recall'
    | 'swift_income'
    | string = 'income';
  currency = '';
  local_currency = '';
  label = '';
  settled_at: Timestamp | null = null;
  emitted_at: Timestamp | null = null;
  updated_at: Timestamp | null = null;
  /**
   * The status of the invoice line
   * completed, transaction is finished
   * pending transaction is made but not yet completed
   */
  status: 'pending' | 'reversed' | 'declined' | 'canceled' | 'completed' =
    'completed';
  note = '';
  reference = '';
  vat_amount = 0;
  vat_amount_cents = 0;
  vat_rate = 0;
  initiator_id = '';
  card_last_digits = 0;
  category = '';
  id = '';
  iban = '';
  vIban = '';
}

export class BankTransactionMetadata {
  static getTemplate(): BankTransactionMetadata {
    return {
      invoiceId: null,
      clientId: null,
      taxcaseId: null,
    };
  }

  /**
   * If linked to an invoice, the invoice id is set.
   * If null, this Bank Transaction Line line is open and needs to be linked to an invoice.
   */
  invoiceId: string | null = null;
  /**
   * the client the  Bank Transaction Line is related to
   */
  clientId: string | null = null;
  /**
   * Optional the taxcase the  Bank Transaction Line is related to
   */
  taxcaseId: string | null = null;
  /**
   * flag indicating if whole transaction amount was processed By Payout process
   */
  processedByPayout?: boolean | null = null;
  /**
   * amount of transaction value proccessed by payout process
   */
  amountProcessedByPayout?: number | null = null;
}
