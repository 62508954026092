import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VollmachtRequestInfo } from '@expresssteuer/models';

@Component({
  selector: 'esui-vm-request',
  templateUrl: './esui-vm-request.component.html',
  styleUrls: ['./esui-vm-request.component.scss'],
})
export class EsuiVmRequestComponent {
  @Input() vmRequest?: Partial<VollmachtRequestInfo>;
  @Output() saveVmRequest = new EventEmitter<void>();
  @Output() taxIdChange = new EventEmitter<string>();
  @Output() firstNameChange = new EventEmitter<string>();
  @Output() lastNameChange = new EventEmitter<string>();
}
