/* eslint-disable */

export interface VaSt_KRV {
  VaSt_KRV: VaSt_KRV_CType;
}

export interface VaSt_KRV_CType {
  /**
   * Übermittlungszeitpunkt der Bescheinigung an die Finanzverwaltung
   */
  Eingangsdatum?: string;
  BetroffenerFall?: BetroffenerFallM2062369861_CType;
  Versicherung?: VersicherungM2062369861_CType;
  Vertragsdaten?: VertragsdatenM2062369861_CType;
  version: string;
}

export interface BetroffenerFallM2062369861_CType {
  /**
   * Jahr der Zahlung/Erstattung
   */
  Meldejahr?: string;
  BetroffenePerson?: BetroffenePerson_1957114334_CType;
}
export interface BetroffenePerson_1957114334_CType {
  NatPerson?: NatPersonM1974816481_CType;
  /**
   * @maxItems 99999
   */
  WeiterePerson?:
    | WeiterePersonM1974816481_CType
    | WeiterePersonM1974816481_CType[];
}

export interface NatPersonM1974816481_CType {
  /**
   * Versicherte Person: Identifikationsnummer
   */
  IdNr?: string;
  /**
   * Versicherte Person: Titel
   */
  Titel?: string;
  /**
   * Versicherte Person: Vorname
   */
  Vorname?: string;
  /**
   * Versicherte Person: Namensvorsatz
   */
  NamensVorsatz?: string;
  /**
   * Versicherte Person: Nachname
   */
  Name?: string;
  /**
   * Versicherte Person: Namenszusatz
   */
  NamensZusatz?: string;
  /**
   * Versicherte Person: Name
   */
  Blockname?: string;
  /**
   * Versicherte Person: Geburtsdatum
   */
  GebDat?: string;
}
export interface WeiterePersonM1974816481_CType {
  NatPerson?: NatPersonM1073410707_CType;
  NNPers?: NNPersM1073410707_CType;
}

export interface NatPersonM1073410707_CType {
  /**
   * Versicherungsnehmer: Identifikationsnummer
   */
  IdNr?: string;
  /**
   * Versicherungsnehmer: Titel
   */
  Titel?: string;
  /**
   * Versicherungsnehmer: Vorname
   */
  Vorname?: string;
  /**
   * Versicherungsnehmer: Namensvorsatz
   */
  NamensVorsatz?: string;
  /**
   * Versicherungsnehmer: Nachname
   */
  Name?: string;
  /**
   * Versicherungsnehmer: Namenszusatz
   */
  NamensZusatz?: string;
  /**
   * Versicherungsnehmer: Name
   */
  Blockname?: string;
}

export interface NNPersM1073410707_CType {
  /**
   * Versicherungsnehmer
   */
  Firmenname?: string;
}
/**
 * undefined
 * Übermittelnde Stelle
 */
export interface VersicherungM2062369861_CType {
  /**
   * Übermittelnde Stelle
   */
  Name?: string;
}

export interface VertragsdatenM2062369861_CType {
  /**
   * Einwilligungsdatum (zur Datenübermittlung)
   */
  EinwilligDat?: string;
  /**
   * @maxItems 168
   */
  Beitragsdaten?:
    | BeitragsdatenM1713597431_CType
    | BeitragsdatenM1713597431_CType[];
}

export interface BeitragsdatenM1713597431_CType {
  /**
   * Beitragsart
   */
  BetragArt?:
    | '01'
    | '02'
    | '05'
    | '06'
    | '07'
    | '10'
    | '15'
    | '16'
    | '17'
    | '18'
    | '11'
    | '13'
    | '12'
    | '14';
  /**
   * Einwilligung zur Übermittlung des Gesamtbetrags
   */
  MerkmalGesBeiEinw?: 'true' | 'false';
  /**
   * Merkmal zum Beitrag
   */
  MerkmalBeitragArt?: '1' | '2';
  /**
   * Beitragstragung
   */
  MerkmalBeiTrag?: '3' | '2' | '1';
  /**
   * Beginn des Zeitraums, für den die Zahlungen/Erstattungen erfolgen
   */
  Beginn?: string;
  /**
   * Ende des Zeitraums, für den die Zahlungen/Erstattungen erfolgen
   */
  Ende?: string;
  /**
   * Währung der geleisteten/erstatteten Beiträge/Zuschüsse
   */
  Waehrung?: string;
  /**
   * Höhe der geleisteten/erstatteten Beiträge/Zuschüsse
   */
  Betrag?: string;
}
